import React, {useState} from "react";
import './style.css';
import {ReactComponent as QW} from '../../images/qw.svg';
import {ReactComponent as QE} from '../../images/qe.svg';
import Button from "@mui/material/Button";
import {ReactComponent as QR} from '../../images/qr.svg';
import { useTranslation } from "react-i18next";

function PlansScreen()
{
    const { t } = useTranslation();

    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    return(
        <div className="plan-ss">
            <div className="busi-plan">
                <div className="container">
                    <h2>{t("find_the_best_plan_for_your_business")}</h2>
                    <div className="desk_pla">
                        <div className="plan-tabs">
                            <div className="bttP">
                                <button
                                    className={toggleState === 1 ? "pl-btn purpl active" : "pl-btn purpl"}
                                    onClick={() => toggleTab(1)}
                                >
                                    {t("medium")}
                                </button>
                                <button
                                    className={toggleState === 2 ? "pl-btn green active" : "pl-btn green"}
                                    onClick={() => toggleTab(2)}
                                >
                                    {t("enterprise")}
                                </button>
                            </div>
                        </div>
                        <div className="plans-cont desktop">
                            <div className={toggleState === 1 ? "pl__cont  active" : "pl__cont"}>
                                <div className="tab row desktop">
                                    <div className="col one">
                                        <div className="clr lgt">
                                            <h2>{t("smart")}</h2>
                                            <p className="st">{t("staked_ncxt_required")}</p>
                                            <h3>50,000 NCXT </h3>
                                            <p className="apr">{t("earn_additional")} <span>3%</span> {t("in_staking_rewards")}</p>
                                            <div className="bn">{t("save_on_trading_withdrawal_fees")}</div>
                                            <p className="txt">{t("an_online_business_account")} <span>{t("simplfy_day_finances")}</span></p>
                                        </div>
                                        <div className="bg-dov">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693048810/virtual-card-purple_1_kfjai7.svg" alt="ncx"/>
                                            <p>{t("business_account_cards")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <span>1 {t("select_crypto_debit_card")}</span>
                                                <div className="ttip">
                                                    <p>{t("with_additional_cards_available")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("ncxt_crypto_debit_card")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>20</span> {t("swift_international_bank")}</p>
                                                <div className="ttip">
                                                    <p>{t("transfer_per_month")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("swift_international_bank_transfers")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>10%</span> {t("trading_feed_discount")}</p>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("7_day_a_week_support")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("7_day_a_week_support")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>10</span> {t("accounting_software")}</p>
                                                <div className="ttip">
                                                    <p>{t("integrations")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("accounting_software_integrations")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-dov invoice">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/>
                                            <p>{t("invoicing_bookkeeping")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p>{t("digitized_receipts")}</p>
                                            </div>
                                        </div>
                                        <div className="points disabled">
                                            <div className="pl"><QR /></div>
                                            <div className="tg">
                                                <p>{t("automated_bookkeeping_tools")}</p>
                                            </div>
                                        </div>
                                        <div className="points disabled">
                                            <div className="pl"><QR /></div>
                                            <div className="tg">
                                                <p>{t("integrated_invoicing_tools")}</p>
                                            </div>
                                        </div>
                                        <div className="points disabled">
                                            <div className="pl"><QR /></div>
                                            <div className="tg">
                                                <p>{t("advanced_dashboard")}</p>
                                            </div>
                                        </div>
                                        <div className="bt">
                                            <Button href="#">{t("available_soon")}</Button>
                                        </div>
                                    </div>
                                    <div className="col two">
                                        <div className="clr mdm">
                                            <h2>{t("premium")}</h2>
                                            <p className="st">{t("staked_ncxt_required")}</p>
                                            <h3>100,000 NCXT</h3>
                                            <p className="apr">{t("earn_additional")} <span>4%</span> {t("in_staking_rewards")}</p>
                                            <div className="bn">{t("save_20%_on_trading_withdrawal_fees")}</div>
                                            <p className="txt">{t("advanced_tools_that_get_bookkeeping")}</p>
                                        </div>
                                        <div className="bg-dov">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693048810/virtual-card-purple_1_kfjai7.svg" alt="ncx"/>
                                            <p>{t("business_account_cards")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <span>3 {t("select_crypto_debit_cards")}</span>
                                                <div className="ttip">
                                                    <p>{t("with_additional_cards_available")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("ncxt_crypto_debit_card")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>40</span> {t("swift_international_bank")}</p>
                                                <div className="ttip">
                                                    <p>{t("transfer_per_month")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("swift_international_bank_transfers")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>20%</span> {t("trading_feed_discount")}</p>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("7_day_a_week_support")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("7_day_a_week_support")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>15</span> {t("accounting_software")}</p>
                                                <div className="ttip">
                                                    <p>{t("integrations")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("accounting_software_integrations")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-dov invoice">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/>
                                            <p>{t("invoicing_bookkeeping")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("digitized_receipts")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("digitized_receipts")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("automated_bookkeeping_tools")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("automated_bookkeeping_tools")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("integrated_invoicing_tools")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("integrated_invoicing_tools")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("advanced_dashboard")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("advanced_dashboard")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bt">
                                            <Button href="#">{t("available_soon")}</Button>
                                        </div>

                                    </div>
                                    <div className="col three">
                                        <div className="clr hrd">
                                            <h2>{t("advanced")}</h2>
                                            <p className="st">{t("staked_ncxt_required")}</p>
                                            <h3>250,000 NCXT</h3>
                                            <p className="apr">{t("earn_additional")} <span>6%</span> {t("in_staking_rewards")}</p>
                                            <div className="bn">{t("save_30%_on_trading_withdrawal_fees")}</div>
                                            <p className="txt">{t("automated_on_the_go_finances_with_our_hightlights")}</p>
                                        </div>
                                        <div className="bg-dov">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693048810/virtual-card-purple_1_kfjai7.svg" alt="ncx"/>
                                            <p>{t("business_account_cards")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <span>5 {t("select_crypto_debit_cards")}</span>
                                                <div className="ttip">
                                                    <p>{t("with_additional_cards_available")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("ncxt_crypto_debit_card")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>60</span> {t("swift_international_bank")}</p>
                                                <div className="ttip">
                                                    <p>{t("transfer_per_month")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("swift_international_bank_transfers")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>30%</span> {t("trading_feed_discount")}</p>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("7_day_a_week_support")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("7_day_a_week_support")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>20</span> {t("accounting_software")}</p>
                                                <div className="ttip">
                                                    <p>{t("integrations")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("accounting_software_integrations")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-dov invoice">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/>
                                            <p>{t("invoicing_bookkeeping")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("digitized_receipts")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("digitized_receipts")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("automated_bookkeeping_tools")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("automated_bookkeeping_tools")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("integrated_invoicing_tools")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("integrated_invoicing_tools")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("advanced_dashboard")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("advanced_dashboard")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bt">
                                            <Button href="#">{t("available_soon")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={toggleState === 2 ? "pl__cont  active" : "pl__cont"}>
                                <div className="tab row desktop">
                                    <div className="col one">
                                        <div className="clr green1">
                                            <h2>{t("")}Essential</h2>
                                            <p className="st">{t("staked_ncxt_required")}</p>
                                            <h3>500,000 NCXT</h3>
                                            <p className="apr">{t("earn_additional")} <span>8%</span> {t("in_staking_rewards")}</p>
                                            <div className="bn">{t("save_40%_on_trading_withdrawal_fees")}</div>
                                            <p className="txt">{t("fast_easy_business_banking")}</p>
                                        </div>
                                        <div className="bg-dov">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057733/qt_gkyw8j.svg" alt="ncx"/>
                                            <p>{t("business_account_cards")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <span>10 {t("select_crypto_debit_cards")}</span>
                                                <div className="ttip">
                                                    <p>{t("with_additional_cards_available")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("ncxt_crypto_debit_card")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>100</span> {t("swift_international_bank")}</p>
                                                <div className="ttip">
                                                    <p>{t("transfer_per_month")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("swift_international_bank_transfers")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>40%</span> {t("trading_feed_discount")}</p>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("7_day_a_week_support")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("7_day_a_week_support")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>25</span> {t("accounting_software")}</p>
                                                <div className="ttip">
                                                    <p>{t("integrations")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("accounting_software_integrations")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-dov invoice">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057741/qy_tau829.svg" alt="ncx"/>
                                            <p>{t("invoicing_expense_management")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p>{t("access_for")} <span>2</span> {t("users")} + <span>1</span></p>
                                                <div className="ttip">
                                                    <p>{t("accountant")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("accountant_access")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("automated_bookkeeping_tools")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("automated_bookkeeping_tools")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("integrated_invoicing_tools")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("integrated_invoicing_tools")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points disabled">
                                            <div className="pl"><QR /></div>
                                            <div className="tg">
                                                <p>{t("expense_report")}</p>
                                            </div>
                                        </div>
                                        <div className="points disabled">
                                            <div className="pl"><QR /></div>
                                            <div className="tg">
                                                <p>{t("customizable_payment_permissions")}</p>
                                            </div>
                                        </div>
                                        <div className="points disabled">
                                            <div className="pl"><QR /></div>
                                            <div className="tg">
                                                <p>{t("advanced_dashboard")}</p>
                                            </div>
                                        </div>
                                        <div className="bt">
                                            <Button href="#">{t("available_soon")}</Button>
                                        </div>
                                    </div>
                                    <div className="col two">
                                        <div className="clr green2">
                                            <h2>{t("business")}</h2>
                                            <p className="st">{t("staked_ncxt_required")}</p>
                                            <h3>1,000,000 NCXT </h3>
                                            <p className="apr">{t("earn_additional")} <span>10%</span> {t("in_staking_rewards")}</p>
                                            <div className="bn">{t("save_60%_on_trading_withdrawal_fees")}</div>
                                            <p className="txt">{t("team_spend_expense_management_tools")}</p>
                                        </div>
                                        <div className="bg-dov">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057733/qt_gkyw8j.svg" alt="ncx"/>
                                            <p>{t("business_account_cards")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <span>20 {t("select_crypto_debit_cards")}</span>
                                                <div className="ttip">
                                                    <p>{t("with_additional_cards_available")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("ncxt_crypto_debit_card")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>200</span> {t("swift_international_bank")}</p>
                                                <div className="ttip">
                                                    <p>{t("transfer_per_month")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("swift_international_bank_transfers")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>60%</span> {t("trading_feed_discount")}</p>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg fbgtr">
                                            <p>{t("7_day_week")}</p>
                                                <div className="ttip">
                                                    <span>{t("priority_support")}</span>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("7_day_a_week_support")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>30</span> {t("accounting_software")}</p>
                                                <div className="ttip">
                                                    <p>{t("integrations")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("accounting_software_integrations")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-dov invoice">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057741/qy_tau829.svg" alt="ncx"/>
                                            <p>{t("invoicing_expense_management")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p>{t("access_for")} <span>5</span> {t("users")} + <span>1</span></p>
                                                <div className="ttip">
                                                    <p>{t("accountant")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("accountant_access")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("automated_bookkeeping_tools")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("automated_bookkeeping_tools")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("integrated_invoicing_tools")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("integrated_invoicing_tools")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("expense_report")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("expense_report")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p>{t("customizable_payment_permissions")}</p>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("advanced_dashboard")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("advanced_dashboard")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bt">
                                            <Button href="#">{t("available_soon")}</Button>
                                        </div>
                                    </div>
                                    <div className="col three">
                                        <div className="clr green3">
                                            <h2>{t("enterprise")}</h2>
                                            <p className="st">{t("staked_ncxt_required")}</p>
                                            <h3>2,500,000 NCXT </h3>
                                            <p className="apr">{t("earn_additional")} <span>12%</span> {t("in_staking_rewards")}</p>
                                            <div className="bn">{t("save_80%_on_trading_withdrawal_fees")}</div>
                                            <p className="txt">{t("the_complete_package_for_longer_teams")}</p>
                                        </div>
                                        <div className="bg-dov">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057733/qt_gkyw8j.svg" alt="ncx"/>
                                            <p>{t("business_account_cards")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <span>50 {t("select_crypto_debit_cards")}</span>
                                                <div className="ttip">
                                                    <p>{t("with_additional_cards_available")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("ncxt_crypto_debit_card")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>500</span> {t("swift_international_bank")}</p>
                                                <div className="ttip">
                                                    <p>{t("transfer_per_month")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("swift_international_bank_transfers")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>80%</span> {t("trading_feed_discount")}</p>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg fbgtr">
                                            <p>{t("7_day_week")} </p>
                                                <div className="ttip">
                                                    <span>{t("priority_support")}</span>
                                                    <div className="ttip">
                                                        <div className="topti">
                                                            <QE />
                                                            <div class="top">
                                                                <p>{t("7_day_a_week_support")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p><span>33</span> {t("accounting_software")}</p>
                                                <div className="ttip">
                                                    <p>{t("integrations")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("accounting_software_integrations")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-dov invoice">
                                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057741/qy_tau829.svg" alt="ncx"/>
                                            <p>{t("invoicing_expense_management")}</p>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p>{t("access_for")} <span>10</span> {t("users")} + <span>2</span></p>
                                                <div className="ttip">
                                                    <p>{t("accountants")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("accountant_access")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("automated_bookkeeping_tools")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("automated_bookkeeping_tools")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("integrated_invoicing_tools")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("integrated_invoicing_tools")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("expense_report")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("expense_report")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <p>{t("customizable_payment_permissions")}</p>
                                            </div>
                                        </div>
                                        <div className="points">
                                            <div className="pl"><QW /></div>
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("advanced_dashboard")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("advanced_dashboard")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bt">
                                            <Button href="#">{t("available_soon")}</Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlansScreen;