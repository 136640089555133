import React, {useState} from "react";
import './style.css';
import {ReactComponent as QW} from '../../images/qw.svg';
import {ReactComponent as QE} from '../../images/qe.svg';
import Button from "@mui/material/Button";
import {ReactComponent as BTNArrow} from '../../images/btn_arow.svg';
import {ReactComponent as QR} from '../../images/qr.svg';
import { useTranslation } from "react-i18next";

function PlansScreen2()
{
    const { t } = useTranslation();

    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    const [CardState, setCardState] = useState(1);
    const CardTab = (index) => {
        setCardState(index);
    };

    return(
        <div className="plan-ss">
            <div className="busi-accont">
                <div className="container">
                    <h1>{t("compare_digital_assets_business_account_plans")}</h1>
                    <div className="desk_pla">
                        <div className="plan-tabs">
                            <div className="bttP">
                                <button
                                    className={toggleState === 1 ? "pl-btn purpl active" : "pl-btn purpl"}
                                    onClick={() => toggleTab(1)}
                                >
                                    {t("medium")}
                                </button>
                                <button
                                    className={toggleState === 2 ? "pl-btn green active" : "pl-btn green"}
                                    onClick={() => toggleTab(2)}
                                >
                                    {t("enterprise")}
                                </button>
                            </div>
                        </div>
                        <div className="plans-cont">
                            <div className={toggleState === 1 ? "pl__cont  active" : "pl__cont"}>
                                <div className="bat row">
                                    <div className="col">
                                        <div className="njO">
                                            <div className="nu">{t("business_account_cards")}</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="clr lgt buF">
                                            <h2>{t("smart")}</h2>
                                            <p>{t("staked_ncxt_required")}</p>
                                            <h3>50,000</h3>
                                            <Button href="#">{t("available_soon")}</Button>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="clr mdm buF">
                                            <h2>{t("premium")}</h2>
                                            <p>{t("staked_ncxt_required")}</p>
                                            <h3>100,000</h3>
                                            <Button href="#">{t("available_soon")}</Button>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="clr hrd buF">
                                            <h2>{t("advanced")}</h2>
                                            <p>{t("staked_ncxt_required")}</p>
                                            <h3>{t("")}250,000</h3>
                                            <Button href="#">{t("available_soon")}</Button>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("cards")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("ncx_crypto_debit_cards")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>1</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>3</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>5</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gec">
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("physical_cards")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("ncx_crypto_debit_cards")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Cr_d">
                                                <button
                                                    className={CardState === 1 ? "crd-btn active" : "crd-btn"}
                                                    onClick={() => CardTab(1)}
                                                >
                                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                </button>
                                                <button
                                                    className={CardState === 2 ? "crd-btn active" : "crd-btn"}
                                                    onClick={() => CardTab(2)}
                                                >
                                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                </button>
                                                <button
                                                    className={CardState === 3 ? "crd-btn active" : "crd-btn"}
                                                    onClick={() => CardTab(3)}
                                                >
                                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                </button>
                                                <button
                                                    className={CardState === 4 ? "crd-btn active" : "crd-btn"}
                                                    onClick={() => CardTab(4)}
                                                >
                                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                </button>
                                            </div>
                                            <div className="gtf-P">
                                                <a href="https://ncx.cx/ncx-cards">{t("discover_more")}<BTNArrow /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("select_card")}</h3>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("gold_card")}</h3>
                                                <p>+10,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("platinum_card")}</h3>
                                                <p>+15,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("black_card")}</h3>
                                                <p>+20,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("select_card")}</h3>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("gold_card")}</h3>
                                                <p>+10,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("platinum_card")}</h3>
                                                <p>+15,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("black_card")}</h3>
                                                <p>+20,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("select_card")}</h3>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("gold_card")}</h3>
                                                <p>+10,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("platinum_card")}</h3>
                                                <p>+15,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("black_card")}</h3>
                                                <p>+20,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <p>{t("sub_accounts")} </p>
                                            <div className="erb">
                                                <p>{t("trades_portfolio_managers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("sub_accounts_trades_portfolio_managers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>1</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>3</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>5</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("trading_feed_discount")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("trading_feed_discount")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>10%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>20%</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>30%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("withdrawal_fee_refund")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("withdrawal_fee_refund")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>10%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>20%</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>30%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <p>{t("additional_staking")}</p>
                                            <div className="erb">
                                                <p>{t("rewards_apr")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("additional_staking_rewards_apr")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>3%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>4%</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>6%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <p>{t("use_ncxt_as_leverage")}</p>
                                            <div className="erb">
                                                <p>{t("trading_collateral")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("use_ncxt_as_leverage_trading_collateral")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("incoming_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("incoming_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("$5_fixed_fee_transfer")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>{t("$5_fixed_fee_transfer")}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("$5_fixed_fee_transfer")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("outgoing_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("outgoing_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("1%_fixed_fee_transfer")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>{t("0.9%_fixed_fee_transfer")}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("0.8%_fixed_fee_transfer")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("integrated_invoicing_tools")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("integrated_invoicing_tools")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="njO">
                                            <div className="nu">{t("bookkeeping_reporting")}</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>

                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("digitized_receipts")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("digitized_receipts")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("digitzed")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("receipts_forward")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("receipts_forward")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">

                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>{t("digitized_certified")}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("digitized_certified")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("dashboard")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("dashboard")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                          <p>{t("standard")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>{t("standard")}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("standard")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("accounting_software_integrations")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("accounting_software_integrations")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("")}10</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>{t("")}15</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("")}20</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("fast_api_connectivity")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("fast_api_connectivity")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="njO">
                                            <div className="nu">{t("customer_care")}</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("7_day_a_week_support")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("7_day_a_week_support")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={toggleState === 2 ? "pl__cont  active" : "pl__cont"}>
                                <div className="bat row">
                                    <div className="col">
                                        <div className="njO">
                                            <div className="nu">{t("business_account_cards")}</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="clr green1 buF">
                                            <h2>{t("essential")}</h2>
                                            <p>{t("staked_ncxt_required")}</p>
                                            <h3>500,000</h3>
                                            <Button href="https://ncx.cx/ncx-card">{t("available_soon")}</Button>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="clr green2 buF">
                                            <h2>{t("business")}</h2>
                                            <p>{t("staked_ncxt_required")}</p>
                                            <h3>1,000,000</h3>
                                            <Button href="#">{t("available_soon")}</Button>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="clr green3 buF">
                                            <h2>{t("enterprise")}</h2>
                                            <p>{t("staked_ncxt_required")}</p>
                                            <h3>2,500,000</h3>
                                            <Button href="#">{t("available_soon")}</Button>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("cards")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("ncx_crypto_debit_cards")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>10</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>20</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>50</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gec">
                                            <div className="tg">
                                                <div className="ttip">
                                                    <p>{t("physical_cards")}</p>
                                                    <div className="topti">
                                                        <QE />
                                                        <div class="top">
                                                            <p>{t("ncx_crypto_debit_cards")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Cr_d">
                                                <button
                                                    className={CardState === 1 ? "crd-btn active" : "crd-btn"}
                                                    onClick={() => CardTab(1)}
                                                >
                                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                </button>
                                                <button
                                                    className={CardState === 2 ? "crd-btn active" : "crd-btn"}
                                                    onClick={() => CardTab(2)}
                                                >
                                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                </button>
                                                <button
                                                    className={CardState === 3 ? "crd-btn active" : "crd-btn"}
                                                    onClick={() => CardTab(3)}
                                                >
                                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                </button>
                                                <button
                                                    className={CardState === 4 ? "crd-btn active" : "crd-btn"}
                                                    onClick={() => CardTab(4)}
                                                >
                                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                </button>
                                            </div>
                                            <div className="gtf-P">
                                                <a href="https://ncx.cx/ncx-card">{t("discover_more")}<BTNArrow /></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("select_card")}</h3>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("gold_card")}</h3>
                                                <p>+10,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("platinum_card")}</h3>
                                                <p>+15,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("black_card")}</h3>
                                                <p>+20,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("select_card")}</h3>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("gold_card")}</h3>
                                                <p>+10,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("platinum_card")}</h3>
                                                <p>+15,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("black_card")}</h3>
                                                <p>+20,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("select_card")}</h3>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("gold_card")}</h3>
                                                <p>+10,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("platinum_card")}</h3>
                                                <p>+15,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                        <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                            <div className="gt-P">
                                                <h3>{t("black_card")}</h3>
                                                <p>+20,000 {t("staked_ncxt")}</p>
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <p>{t("sub_accounts")}</p>
                                            <div className="erb">
                                                <p>{t("trades_portfolio_managers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("sub_accounts_trades_portfolio_managers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>10</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>30</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>100</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("trading_feed_discount")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("trading_feed_discount")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>40%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>60%</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>80%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("withdrawal_fee_refund")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("withdrawal_fee_refund")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>40%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>60%</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>80%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <p>{t("additional_staking")}</p>
                                            <div className="erb">
                                                <p>{t("rewards_apr")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("additional_staking_rewards_apr")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>8%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>10%</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>12%</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <p>{t("use_ncxt_as_leverage")}</p>
                                            <div className="erb">
                                                <p>{t("trading_collateral")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("use_ncxt_as_leverage_trading_collateral")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("incoming_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("incoming_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("free")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>{t("free")}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("free")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("outgoing_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("outgoing_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>$20{t("transfer")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>$10{t("transfer")}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>$5{t("transfer")}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="njO">
                                            <div className="nu">{t("expense_spend_management")}</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <p>{t("team_access")}</p>
                                            <div className="erb">
                                                <p>{t("user_included")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("team_access_user_included")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>5</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>15</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>30</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <p>{t("custom_permission")}</p>
                                            <div className="erb">
                                                <p>{t("manager_role")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("custom_permission_manager_role")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("spending_categories")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("spending_categories")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("expense_report")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("expense_report")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("team_budget")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("team_budget")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("bulk_transfer")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("bulk_transfer")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="njO">
                                            <div className="nu">{t("bookkeeping_reporting")}</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("all_in_one_invoicing_tool")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("all_in_one_invoicing_tool")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("digitized_certified_receipts")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("digitized_certified_receipts")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("receipts_forward")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("receipts_forward")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("dashboard")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("dashboard")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("standard")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>{t("advanced")}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("advanced")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("accountant_tools_integrations")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("accountant_tools_integrations")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>25</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>30</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>33</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="njO">
                                            <div className="nu">{t("customer_care")}</div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("7_day_a_week_support")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("7_day_a_week_support")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <p>{t("priority_support")}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <p>{t("priority_support")}</p>
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="bgt--d">
                                            <div className="erb">
                                                <p>{t("key_account_manager")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("key_account_manager")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                        
                                        </div>
                                    </div>
                                    <div className="col grey-c">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="gbd--k">
                                            <QW />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlansScreen2;