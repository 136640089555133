import {React, useState} from "react";
  
// importing material UI components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Logo from '../../images/new_sitelogo.svg';
import './header.css';
import { ReactComponent as NCXMore } from '../../images/ncx-header-more.svg';
import { ReactComponent as AppleIcon } from '../../images/apple-icon.svg';
import { ReactComponent as GooglePlayIcon } from '../../images/play-icon.svg';
import Telegram from '../footer/telegram.svg';
import Twitter from '../footer/twitter.svg';
import Instagram from '../../images/instagram.svg';
import AppBarCode from '../../images/ncx-QRcode.svg';
import {ReactComponent as DropdownICon} from '../../images/menu-dropdown.svg';
import { useTranslation } from "react-i18next";
import ChangeButtons from '../../../selectlanguage';
import { ReactComponent as CloseIcon } from '../../images/clos.svg';
  
export default function Header() {   

  const { t } = useTranslation();
  
  const [isNavExpanded, setIsNavExpanded] = useState(false) 
  
  return (
      <AppBar position="static" id="header-row">
        <Toolbar>
          <div id="main-header" className="desktop">
            <div className="logo-nav">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                className="header-logo"
                sx={{ mr: 2 }}
              >
              <a href="https://ncx.cx"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689405667/new_sitelogo_naeh1u.svg" alt="NCX-Logo" /></a>
              </IconButton>
              <div className="headermore_row">
                <a className="header-more" href="#"><NCXMore /></a>
                <div id="new-headermenu" className="mega_menu"> 
                    <div className="col1">
                      <div className="top_heading connect_link_title"> 
                        <h4>{t("products_and_services")}</h4> 
                        <h4>{t("business_corporate")}</h4>
                        <h4>{t("company")}</h4> 
                      </div>
                      <div className="menu_box">
                        <div className="col">
                          <div className="main_service_wrapper">
                            <div className="community_inner">
                              <div className="main_service">
                                <a href="https://ncx.cx/buy-crypto">{t("buy_crypto")}</a>
                                <a href="https://exchange.ncx.cx/trade">{t("spot_trading")}</a>
                                <a href="https://ncx.cx/derivatives">{t("derivatives_trading")}</a>
                                <a href="https://exchange.ncx.cx/markets">{t("markets")}</a>
                                <a href="https://bsc.ncx.cx/swap">{t("cross_chain_dex")}</a>
                                <a href="https://ncx.cx/wallet">{t("ncx_defi_wallet")}</a>
                                <a href="https://ncx.cx/ncx-card">{t("ncx_card")}</a>
                                <a href="https://ncx.cx/ncx-token">{t("ncx_token")}</a>
                                {/* <a href="https://ncx.cx/market-insights">{t("ncx_insights")}</a> */}
                                <a href="https://play.google.com/store/apps/details?id=com.application.ncx" target="_blank" rel="noreferrer">{t("ncx_mobile_app")}</a>
                              </div>
                            </div>
                            <div className="social_links">
                              <div className="social_link_inner"> 
                                <a href="https://ncx.cx/business-accounts">{t("digital_business_account")}</a>
                                <a href="https://ncx.cx/company-cards">{t("company_crypto_debit_cards")}</a>
                                <a href="https://ncx.cx/plans">{t("business_account_plans")}</a>
                                <a href="https://ncx.cx/accounting">{t("invoicing_bookkeeping")}</a>
                                <a href="https://ncx.cx/institutional">{t("institutional_services")}</a>
                                <a href="https://ncx.cx/ncx-otc">{t("otc")}</a>
                                <h4 className="asdf--g">{t("rewards_affiliate")}</h4>
                                <a href="https://ncx.cx/rewards">{t("rewards_program")}</a>
                                <a href="https://ncx.cx/ncx-ambassador">{t("ncx_ambassador")}</a>
                                <a href="https://ncx.cx/market-makers">{t("market_makers")}</a>
                              </div>
                            </div>
                            <div className="social_links">
                              <div className="social_link_inner">
                                <a href="https://ncx.cx/about-us">{t("about_us")}</a>
                                {/* <a href="https://ncx.cx/market-insights">{t("media_press")}</a> */}
                                <a href="https://ncx.cx/user-agreement">{t("user_agreement")}</a>
                                <a href="https://ncx.cx/kyc-aml-policy">{t("aml_kyc_policy")}</a>
                                <a href="https://ncx.cx/privacy-policy">{t("privacy_policy")}</a>
                                <a href="https://ncx.cx/api-agreement">{t("api_documentation")}</a>
                                <a href="https://ncx.cx/cookie-policy">{t("cookie_policy")}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col2">
                      <div className="right_inner">
                        <h4>{t("ncx_token")}</h4>
                        <div className="connect_links">
                          <div className="box">
                            <div className="boxx-links">
                              <a href="https://exchange.ncx.cx/ieolist">{t("ncxt_private_sale")}</a>
                              <a href="https://exchange.ncx.cx/ieolist">{t("claim_airdrop")}</a>

                              <h4 className="tg--f">{t("support")}</h4>
                              <a href="https://ncx.cx/faq">{t("faq")}</a>
                              <a href="https://exchange.ncx.cx/support" target="_blank" rel="noreferrer">{t("open_support_ticket")}</a>
                            </div>
                            <h4>{t("social_media")}</h4>
                            <div className="social-icons">
                              <div class="icons"><a href="https://t.me/NCX_Official" target="_blank" rel="noreferrer"><img src={Telegram} alt="NCX" title="Telegram"/></a></div>
                              <div class="icons"><a href="https://www.instagram.com/ncx.cx/" target="_blank" rel="noreferrer"><img src={Instagram} alt="NCX" title="Instagram" /></a></div>
                              <div class="icons"><a href="https://twitter.com/NCX_CX" target="_blank" rel="noreferrer"><img src={Twitter} alt="NCX" title="Twitter" /></a></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <ul className="navlink">
                <li className="main_title white_bg">
                  <a href="https://ncx.cx/buy-crypto">{t("buy_crypto")}</a>
                </li>
                <li className="main_title white_bg">
                  <a href="https://exchange.ncx.cx/trade" target="_blank" rel="noreferrer">{t("spot_trading")}</a>
                </li>
                <li className="main_title white_bg">
                  <a href="/derivatives">{t("derivatives")}</a>
                </li>
                <li className="buinicwe">
                  <a className="header-more" href="#">{t("business")}</a>
                  <div className="sub-miv">
                    <a href="https://ncx.cx/business-accounts">{t("business_account")}</a>
                    <a href="https://ncx.cx/company-cards">{t("company_cards")}</a>
                    <a href="https://ncx.cx/plans">{t("business_account_plans")}</a>
                    <a href="https://ncx.cx/accounting">{t("invoicing_bookkeeping")}</a>
                  </div>
                </li>
                <li className="main_title white_bg">
                  <a href="https://exchange.ncx.cx/markets">{t("markets")}</a>
                </li>
                <li className="main_title white_bg">
                  <a href="https://bsc.ncx.cx/swap" target="_blank" rel="noreferrer">{t("dex")}</a>
                </li>
                <li className="main_title white_bg">
                  <a href="https://ncx.cx/ncx-card">{t("ncx_card")}</a>
                </li>
                <li className="main_title white_bg">
                  <a href="/wallet">{t("ncx_wallet")}</a>
                </li>
                <li className="main_title white_bg">
                  <a href="/ncx-token">{t("ncx_token")}</a>
                </li>
                <li className="main_title white_bg">
                  <a href="/rewards">{t("rewards")}</a>
                </li>
              </ul>
            </div>
            <div className="login-buttons">
              <Button href="https://exchange.ncx.cx/login" target="_blank" rel="noreferrer" className="signin">{t("sign_in")}</Button>
              <Button className="launch-dex" href="https://bsc.ncx.cx/swap" target="_blank" rel="noreferrer">{t("defi")}</Button>
              <div className="downloadQRCode">
                <div id="downloadlink" className="storeicons">
                  <AppleIcon />
                  <GooglePlayIcon />
                </div>
                <div id="headerDownloadApp">
                  <div class="download-box">
                    <div class="qr-box">
                      <div class="img-box">
                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693062241/qr-cdeo-_yvvhfn.svg" alt="NCX Mobile App"/>
                      </div>
                    </div>
                    <div class="pc-box">
                      <div class="text">{t("scan_qr_code_to_download_ncx_mobile_app")}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="translate_lng">
                <div id="tran_lang">
                  <h1>{t("english")}</h1>
                  <DropdownICon />
                </div>
                <div id="language_trans">
                  <div class="language-box">
                    {/* <a href="#">English</a>
                    <a href="#">हिन्दी</a>
                    <a href="#">Español</a>
                    <a href="#">中文</a>
                    <a href="#">Bahasa</a>
                    <a href="#">Tiếng Việt</a>
                    <a href="#">Русский</a>
                    <a href="#">Melayu</a> */}
                    <ChangeButtons />
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          
          
          <div id="header-newmobile" className={
                    isNavExpanded ? "header-expanded avtive" : "header-expanded"
                  }>
            <nav className="navigation">
              <div className="navigation-one">
                <div className="mobile-toogle">
                  <button 
                    className="hamburger"
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded)
                    }}>
                    <div className="mplo">
                      <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.875 0.5H18.125V1.49902H0.875V0.5ZM1 7.50098L18.125 7.5V8.49902L1 8.5V7.50098ZM0.875 14.5H18.125V15.499H0.875V14.5Z" fill="white"/>
                      </svg>
                    </div>
                    <div className="clols">
                      <CloseIcon />
                    </div>
                  </button>
                  <div
                    className={
                      isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
                    }>
                    <div className="mki-PP">
                      <div className="mbl--menu">
                        <details>
                            <summary>{t("trade")}
                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                            </svg>
                            </summary>
                            <div className='mn-rr'>
                                <a href="https://exchange.ncx.cx/trade" rel="noreferrer" target="_blank">{t("spot")}</a>
                                <a href="https://exchange.ncx.cx/markets" rel="noreferrer" target="_blank">{t("markets")}</a>
                                <a href="https://bsc.ncx.cx/swap" rel="noreferrer" target="_blank">{t("dex")}</a>
                            </div>
                        </details>
                        <details>
                            <summary>{t("products")}
                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                            </svg>
                            </summary>
                            <div className='mn-rr'>
                                <a href="https://ncx.cx/buy-crypto">{t("buy_crypto")}</a>
                                <a href="https://ncx.cx/derivatives">{t("derivatives_trading")}</a>
                                <a href="https://ncx.cx/ncx-card">{t("ncx_card")}</a>
                                <a href="https://ncx.cx/wallet">{t("ncx_wallet")}</a>
                                <a href="https://ncx.cx/ncx-token">{t("ncx_token")}</a>
                                {/* <a href="https://ncx.cx/market-insights">{t("news_insights")}</a> */}
                            </div>
                        </details>
                        <details>
                            <summary>{t("business_corporate")}
                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                            </svg>
                            </summary>
                            <div className='mn-rr'>
                                <a href="https://ncx.cx/business-accounts">{t("digital_business_account")}</a>
                                <a href="https://ncx.cx/company-cards">{t("company_crypto_debit_cards")}</a>
                                <a href="https://ncx.cx/plans">{t("business_account_plans")}</a>
                                <a href="https://ncx.cx/accounting">{t("invoicing_bookkeeping")}</a>
                                <a href="https://ncx.cx/institutional">{t("institutional_services")}</a>
                                <a href="https://ncx.cx/ncx-otc">{t("otc")}</a>
                            </div>
                        </details>
                        <details>
                            <summary>{t("rewards_affiliate")}
                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                            </svg>
                            </summary>
                            <div className='mn-rr'>
                                <a href="https://ncx.cx/rewards">{t("rewards")}</a>
                                <a href="https://ncx.cx/ncx-ambassador">{t("ambassador_program")}</a>
                                <a href="https://ncx.cx/market-makers">{t("market_makers")}</a>
                            </div>
                        </details>
                        <details>
                            <summary>{t("company")}
                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                            </svg>
                            </summary>
                            <div className='mn-rr'>
                                <a href="https://ncx.cx/about-us">{t("about_us")}</a>
                                {/* <a href="https://ncx.cx/market-insights">{t("news_press")}</a> */}
                            </div>
                        </details>
                        <details>
                            <summary>{t("support")}
                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                            </svg>
                            </summary>
                            <div className='mn-rr'>
                                <a href="https://ncx.cx/faq">{t("faq")}</a>
                                <a href="https://exchange.ncx.cx/support" target="_blank" rel="noreferrer">{t("create_support_ticket")}</a>
                            </div>
                        </details>
                        <details>
                            <summary>{t("social_media")}
                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                            </svg>
                            </summary>
                            <div className='mn-rr'>
                                <a href="https://twitter.com/NCX_CX" target="_blank" rel="noreferrer">{t("twitter")}</a>
                                <a href="https://t.me/NCX_Official" target="_blank" rel="noreferrer">{t("telegram")}</a>
                                <a href="https://www.instagram.com/ncx.cx/" target="_blank" rel="noreferrer">{t("instagram")}</a>
                            </div>
                        </details>
                        <details>
                            <summary>{t("legal")}
                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                            </svg>
                            </summary>
                            <div className='mn-rr'>
                                <a href="https://ncx.cx/user-agreement">{t("user_agreement")}</a>
                                <a href="https://ncx.cx/kyc-aml-policy">{t("aml_kyc_policy")}</a>
                                <a href="https://ncx.cx/privacy-policy">{t("privacy_policy")}</a>
                                <a href="https://ncx.cx/api-agreement">{t("api_documentation")}</a>
                                <a href="https://ncx.cx/cookie-policy">{t("cookie_policy")}</a>
                            </div>
                        </details>
                        <div className="erbr">
                          <a href="https://exchange.ncx.cx/ieolist">{t("claim_airdrop")}</a>
                        </div>
                      </div>
                      <div className="btm-btns">
                        <a className="gin-btn" href="https://exchange.ncx.cx/login" target="_blank" rel="noreferrer">{t("sign_in")}</a>
                        <a className="cret-acnt" href="https://exchange.ncx.cx/register" target="_blank" rel="noreferrer">{t("or_create_account")}</a>
                      </div>
                    </div>
                    {/* <ul>
                      <li>
                        <a href="https://ncx.cx/buy-crypto">{t("buy_crypto")}</a>
                      </li>
                      <li>
                        <a href="https://exchange.ncx.cx/trade" target="_blank" rel="noreferrer">{t("spot_trading")}</a>
                      </li>
                      <li>
                        <a href="/derivatives">{t("derivatives")}</a>
                      </li>
                      <li>
                        <a href="https://exchange.ncx.cx/markets">{t("markets")}</a>
                      </li>
                      <li>
                        <a href="https://bsc.ncx.cx/swap" target="_blank" rel="noreferrer">{t("dex")}</a>
                      </li>
                      <li>
                        <a href="https://ncx.cx/ncx-card">{t("ncx_card")}</a>
                      </li>
                      <li>
                        <a href="https://ncx.cx/wallet">{t("ncx_defi_wallet")}</a>
                      </li>
                      <li>
                        <a href="/ncx-token">{t("ncx_token")}</a>
                      </li>
                      <li>
                        <a href="/rewards">{t("rewards")}</a>
                      </li>
                      <li>
                        <a href="/market-insights">{t("news_insights")}</a>
                      </li>
                      <li>
                        <a className="signin launching-btn" href="https://exchange.ncx.cx/login" target="_blank" rel="noreferrer">{t("sign_in_sign_up")}</a>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <div className="mobile-logo">
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  className="header-logo"
                  sx={{ mr: 2 }}
                >
                <a href="https://ncx.cx/"><img src={Logo} alt="NCX-Logo" /></a>
                </IconButton>
                </div>
              </div>
              {/* <div className="mobile-launch">
                <div class="dropdown">
                  <button class="dropbtn">{t("launch_dex")}</button>
                  <div class="dropdown-content">
                    <a href="https://bsc.ncx.cx/swap" target="_blank" rel="noreferrer">{t("launch_dex")}</a>
                    <a href="https://exchange.ncx.cx/login" target="_blank" rel="noreferrer">{t("sign_in")}</a>
                  </div>
                </div>
              </div> */}
              <div className="translate_lng">
                <div id="tran_lang">
                  <h1>{t("english")}</h1>
                  <DropdownICon />
                </div>
                <div id="language_trans">
                  <div class="language-box">
                    <ChangeButtons />
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </Toolbar>
      </AppBar>
  );
}