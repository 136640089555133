import React from "react";
import './privacy-policy.css';
import MyHeader from '../../components/header/header';
import MyFooter from '../../components/footer/footer';
import { useTranslation } from "react-i18next";

function PrivacyPolicy(){

    const { t } = useTranslation();

    return(
        <div className="privacy-policypage">
            <MyHeader />
            <div className="policy">
                <div className="head container">
                    <h1>Privacy Policy</h1>
                </div>
            </div>
            <div className="policy-body container">
                <h2>Privacy Policy</h2>
                <p>Last updated: December 08,2023</p>
                <p>**NCX Exchange L.L.C.** built the NCX Exchange app as a Free app. This SERVICE is provided by NCX Exchange L.L.C. at no cost and is intended for use as is. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decides to use our Service.</p>
                <p>Please read the complete version before you download, access, or otherwise use the Service. If you do not agree to the terms of the privacy policy, please do not use the Service. If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at NCX Exchange unless otherwise defined in this Privacy Policy.</p>
                <h3>**Information We Collect**</h3>
                <p>When you use the Service, we collect your personal information and other information about you. Sometimes, you will directly provide information. Other times, we will collect it automatically or by a third party. When your express consent is required (e.g., if laws require), we will only collect your information if you expressly agree. By using the Service, you consent to our collection, use, and disclosure of your information. You may refuse or withdraw this consent at any time.</p>
                <h3>**Consent**</h3>
                <p>As permitted by law, consent to the collection of your personal information may be implied. In certain circumstances, express consent may be required, and we will do so if it is determined that we must obtain express consent from you. Otherwise, your acceptance of this Policy indicates your consent to the collection, use, and disclosure of your information.</p>
                <p>Subject to legal and contractual restrictions, you may refuse or withdraw your consent at any time by notifying us in accordance with this Policy. We note that some of the Services require your personal information to function properly, or the Service may only be offered if you provide us with your personal information. In such cases, if you refuse or withdraw your consent, the Service may not be available to you.</p>
                <h3>**Image Information (Photos and Videos)**</h3>
                <p>When you use our website and application, we may collect user images for various purposes, such as profile pictures or content submission. This data is collected and used as described below:</p>  
                <ul>
                    <li>**Data Collected, and for What Purpose:**</li>
                    <li>**Videos (Optional) for App Functionality:**</li>
                    <li>Photos may be collected for: App functionality, Fraud prevention, security, and compliance, Personalization</li>
                    <li>**Photos (Optional) for App Functionality:**</li>
                    <li>Photos may be collected for: App functionality, Fraud prevention, security, and compliance, Personalization</li>
                </ul>
                <h3>**Collection and Use of User Images:**</h3>
                <p>Our website and application may collect user images for various purposes, such as profile pictures or content submission. Please be aware that any images submitted to our platform may be stored on our servers for future use. However, we do not share these images with third parties; they are only used for internal purposes, such as improving our services and user experience. By submitting an image to our platform, you acknowledge and consent to our collection and storage of your image.</p>
                <h3>**Purposes of Collection, Use, and Disclosure**</h3>
                <p>We use the information collected on the Service for the purposes listed below:</p>
                <p>**Data Shared, and For What Purpose:**</p>
                <ul>
                    <li>**Personal Info:**</li>
                    <li>*Other info*</li>
                    <li>*App functionality, Analytics, Developer communications, Fraud prevention, security, and compliance, Advertising or marketing, Personalization, Account management*</li>
                    <li>**Financial Info:**</li>
                    <li>*User payment info, Other financial info*</li>
                    <li>*App functionality, Account management*</li>
                    <li>**Location:**</li>
                    <li>*Approximate location*</li>
                    <li>*App functionality, Account management*</li>
                    <li>**Messages:**</li>
                    <li>*Other in-app messages*</li>
                    <li>*App functionality, Fraud prevention, security, and compliance, Advertising or marketing, Personalization*</li>
                    <li>**Photos and Videos:**</li>
                    <li>*Photos*</li>
                    <li>*App functionality, Personalization, Account management*</li>
                    <li>*Videos*</li>
                    <li>*App functionality, Analytics, Fraud prevention, security, and compliance, Personalization, Account management*</li>
                    <li>**App Info and Performance:**</li>
                    <li>*Crash logs, Diagnostics, Other app performance data*</li>
                    <li>*Analytics, Fraud prevention, security, and compliance*</li>
                    <li>**Files and Docs:**</li>
                    <li>*Files and docs*</li>
                    <li>*App functionality, Analytics, Fraud prevention, security, and compliance, Personalization, Account management*</li>
                    <li>**App Activity:**</li>
                    <li>*Other actions*</li>
                    <li>*App functionality, Analytics, Developer communications, Fraud prevention, security, and compliance, Advertising or marketing, Personalization, Account management*</li>
                    <li>**Device or Other IDs:**</li>
                    <li>*Device or other IDs*</li>
                    <li>*App functionality, Analytics, Fraud prevention, security, and compliance, Advertising or marketing, Personalization, Account management*</li>
                </ul>
                <h3>**Data Deletion**</h3>
                <ul>
                    <li>**Data that can be deleted by users. This will be available within your store listing soon.**</li>
                    <li>*Delete app account:* You can submit a request to delete your account and associated data for this app Link to request : https://exchange.ncx.cx/support?type=account_delete</li>
                    <li>*Manage app data:* You can submit a request to delete certain data that the developer collected through this app, without deleting your entire account. This data can include things like activity history, transaction history, and images and video. Link to request : https://exchange.ncx.cx/support?type=data_delete</li>
                </ul>
                <h3>**Security Practices**</h3>
                <ul>
                    <li>**Data Encryption:**</li>
                    <li>Your data is transferred over a secure connection.</li>
                    <li>**Data Deletion:**</li>
                    <li>The developer provides a way for you to request that your data be deleted.</li>
                </ul>
                <h3>Privacy Policy Changes</h3>
                <p>We reserve the right to modify or update the Privacy Policy at any time, and the continued use of the Services by the User is a sign of acceptance of these policies.</p>
                <p>All content available through any of the NCX Exchange services, such as text, graphics, logos, button icons, images, video images, downloadable and copyable items, data, and all content produced by the NCX Exchange is part of the NCX Exchange property. Right, The use and publication of all available and available materials are the exclusive prerogatives of NCX Exchange, and any use without obtaining written permission reserves the right to prosecute NCX Exchange. In addition, the scripts and names of the services available through each of the services created by NCX Exchange and the registered trademarks are the exclusive property of NCX Exchange, and any use for commercial purposes is subject to legal prosecution.</p>
            </div>
            <MyFooter />
        </div>
    )
}

export default PrivacyPolicy;