import React, {useState} from "react";
import './style.css';
import IntegrateData from "./integration-data";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { ReactComponent as CardLeftArrow } from '../../images/card_leftarrow.svg';
import { ReactComponent as CardRightArrow } from '../../images/card_rightarrow.svg';
import {ReactComponent as BTNArrow} from '../../images/btn_arow.svg';

function BookDesktop()
{
    const { t } = useTranslation();

    return(
        <div className="book__re">
            <div className="qn_f">
                <div className="container">
                    <div className="tir row">
                        <div className="col">
                            <h1>{t("effortless")} <br />{t("bookkeeping")}</h1>
                            <p>{t("a_better_way_to_get_one")} <br />{t("a_better_way_to_get_two")} <br />{t("a_better_way_to_get_three")}</p>
                            <div className="sha-btn">
                                <Button href="https://ncx.cx/plans">{t("compare_plans")}</Button>
                            </div>
                        </div>
                        <div className="col">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692687037/31-min_y9urtu.png" alt="ncx" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="accnt__v tw rtyytr">
                <div className="container">
                    <div className="row xcm">
                        <div className="col frdss">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692687062/49_xvl9p4.png" alt="ncx"/>
                        </div>
                        <div className="col mla gthh">
                            <h2>{t("prepare_your_bookkepping_in_no_time")}</h2>
                            <ul>
                                <li>{t("prepare_your_bookkepping_in_no_time_li_one")}</li>
                                <li>{t("prepare_your_bookkepping_in_no_time_li_two")}</li>
                                <li>{t("prepare_your_bookkepping_in_no_time_li_three")}</li>
                            </ul>
                            <div className="cefv b">
                                <Button href="https://ncx.cx/plans">{t("compare_plans")}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="qwn six">
                <div className="container">
                    <h2>{t("you_re_in_good_hands")}</h2>
                    <div class="werPP row">
                        <div class="col">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692969567/qwe1_sz0cwr.png" alt="global" />
                            <h3>{t("handy")}</h3>
                            <p>{t("handy_content")}</p>
                        </div>
                        <div class="col">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692969567/qwe3_ltbrln.png" alt="global" />
                            <h3>{t("reliable")}</h3>
                            <p>{t("reliable_content")}</p>
                        </div>
                        <div class="col">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692969567/qwe2_rhqwrw.png" alt="global" />
                            <h3>{t("connected")}</h3>
                            <p>{t("connected_content")}</p>
                        </div>
                    </div>
                    <div className="mbl__sec__cr">
                        <CarouselProvider
                            visibleSlides={1}
                            totalSlides={3}
                            step={1}
                            naturalSlideWidth={300}
                            isIntrinsicHeight={true}
                            isPlaying={true}
                            interval={3000}
                            orientation="horizontal"
                            infinite
                            playDirection="forward"
                        >
                            <Slider>
                                <Slide index={0} className="mnl__cx">
                                    <div className="azx">
                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692969567/qwe1_sz0cwr.png" alt="global" />
                                        <h3>{t("handy")}</h3>
                                        <p>{t("handy_content")}</p>
                                    </div>
                                </Slide>
                                <Slide index={1} className="mnl__cx">
                                    <div className="azx">
                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692969567/qwe3_ltbrln.png" alt="global" />
                                        <h3>{t("reliable")}</h3>
                                        <p>{t("reliable_content")}</p>
                                    </div>
                                </Slide>
                                <Slide index={2} className="mnl__cx">
                                    <div className="azx">
                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692969567/qwe2_rhqwrw.png" alt="global" />
                                        <h3>{t("connected")}</h3>
                                        <p>{t("connected_content")}</p>
                                    </div>
                                </Slide>
                            </Slider>
                            <DotGroup />
                        </CarouselProvider>
                    </div>
                </div>
            </div>

            <div className="qwn five mjy-r">
                <div className="container">
                    <div className="row vUU">
                        <div className="col tx">
                            <h2>{t("we_ll_handle_your_receipts")}</h2>
                            <h3>{t("bin_your_paper_receipts")}</h3>
                            <div className="qmR">
                                <p>{t("scan_your_receipts_instead")}</p>
                            </div>
                            <h3>{t("automatic_supplier_invoice_processing")}</h3>
                            <div className="qmR">
                                <p>{t("import_your_supplier_invoices_to_NCX")}</p>
                            </div>
                            <h3>{t("automatic_vat_detection")}</h3>
                            <p>{t("no_more_manual_vat_entry")}</p>
                            <div className="rtD sdfg">
                                <Button href="https://ncx.cx/plans">{t("compare_plans")}</Button>
                            </div>
                        </div>
                        <div className="col im">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692687062/53_bg1ant.png" alt="ncx"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="qwn five vwefbgb">
                <div className="container">
                    <div className="row vUU">
                        <div className="col im">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692971801/54_odwwgm.png" alt="ncx"/>
                        </div>
                        <div className="col tx">
                            <h2>{t("your_time_is_precious")}</h2>
                            <h3>{t("automate_vat_and_labels")}</h3>
                            <div className="qmR">
                                <p>{t("just_enter_your_supplier_data_once")}</p>
                            </div>
                            <h3>{t("no_more_hunting_missing_receipts")}</h3>
                            <div className="qmR">
                                <p>{t("ncx_automatically_follows_up_on_missing")}</p>
                            </div>
                            <h3>{t("your_receipts_are_processed_for_you")}</h3>
                            <p>{t("integrated_accounting_software_automations")}</p>
                            <div className="rtD sdfg">
                                <Button href="https://ncx.cx/plans">{t("compare_plans")}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="qn_f qwPP">
                <div className="container">
                    <div className="tir row">
                        <div className="col">
                            <h1>{t("keep_your_accountant_happy")}<br />{t("ncx_has_33_integrated")} <br />{t("accounting_tools")}</h1>
                            <p>{t("accounting_tools_one")} <br />{t("accounting_tools_two")} <br />{t("accounting_tools_three")}</p>
                        </div>
                        <div className="col">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692687062/55_sxibzq.png" alt="ncx" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="uiPP">
                <div className="container">
                    <h2>{t("accounting_software_integrations")}</h2>
                    <div className="acnt-integ row">
                        {IntegrateData.map((item) => (
                            <div className="col">
                                <div className="tbnm">
                                    <div className="tx-img">
                                        <img className="rcm" src={item.img} alt="ncx"/>
                                        <div className="tc">
                                            <h3>{item.company}</h3>
                                            <p>{t(item.slug)}</p>
                                        </div>
                                    </div>
                                    <div className="tgb">
                                        <p>{t(item.desc)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mbl_acnt">
                        <div className="acnt__intg">
                            {IntegrateData.map((ncx) => (
                                <details>
                                    <summary>
                                        <div className="tx-img">
                                            <img className="rcm" src={ncx.img} alt="ncx"/>
                                            <div className="tc">
                                                <h3>{ncx.company}</h3>
                                                <p>{t(ncx.slug)}</p>
                                            </div>
                                        </div>
                                        <span className='red'>
                                            <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"></path></svg>
                                        </span>
                                    </summary>
                                    <div className='contt'>
                                        <p>{t(ncx.desc)}</p>
                                    </div>
                                </details>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="accnt__v asczz vwefbgb">
                <h2>{t("save_time_for_yourself")}</h2>
                <div id="cardarticles">
                    <div id="NCXCreditCardSlider">
                        <CarouselProvider
                            visibleSlides={3}
                            totalSlides={4}
                            step={1}
                            naturalSlideWidth={300}
                            naturalSlideHeight={500}
                        >
                        <Slider className="slider">
                            <Slide index={0}>
                                <article className='ncxcard01'>
                                    <div className="mkhg"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692891164/RENDER_1_3_ndm2jb.png" alt="ncx"/></div>
                                    <h3>{t("settle_payment_invoices_and_payroll")}</h3>
                                    <p className="ved">{t("settle_payment_invoices_and_payroll_content")}</p>
                                    <a href="https://ncx.cx/plans">{t("compare_business_account_plans")}</a>
                                </article>
                            </Slide>
                            <Slide index={1}>
                                <article className='ncxcard01'>
                                    <div className="mkhg"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693029943/57_lgrrcz.png" alt="ncx"/></div>
                                    <h3>{t("optimised_invoice_management")}</h3>
                                    <p>{t("optimised_invoice_management_content")}</p>
                                    <a href="https://ncx.cx/plans">{t("compare_business_account_plans")}</a>
                                </article>
                            </Slide>
                            <Slide index={2}>
                                <article className='ncxcard01'>
                                    <div className="mkhg"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692954007/29-min_y4b53o.png" alt="ncx"/></div>                                    
                                    <h3>{t("digital_account_business")}</h3>
                                    <p>{t("digital_account_business_content")}</p>
                                    <a href="https://ncx.cx/plans">{t("compare_business_account_plans")}</a>
                                </article>
                            </Slide>
                            <Slide index={3}>
                                <article className='ncxcard01 vb'>
                                    <div className="mkhg"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692891135/7_4_ob426b.png" alt="ncx"/></div>                                    
                                    <h3>{t("simplified_team_spend")}</h3>
                                    <p>{t("simplified_team_spend_content")}</p>
                                    <a href="https://ncx.cx/plans">{t("compare_business_account_plans")}</a>
                                </article>
                            </Slide>
                        </Slider>
                        <ButtonBack className="buttonBack"><CardLeftArrow /></ButtonBack>
                        <ButtonNext className="buttonNext"><CardRightArrow /></ButtonNext>
                        </CarouselProvider>
                    </div>
                    <div id="NCXCreditCardSlider" className="mobile-ss">
                        <CarouselProvider
                            visibleSlides={1}
                            totalSlides={4}
                            step={1}
                            // naturalSlideWidth={300}
                            // naturalSlideHeight={500}
                            // hasMasterSpinner
                            // isPlaying={true}
                            // interval={3000}
                            orientation="horizontal"
                            infinite
                            playDirectio="forward"
                        >
                        <Slider className="slider">
                        <Slide index={0}>
                                <article className='ncxcard01'>
                                    <div className="mkhg"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692891164/RENDER_1_3_ndm2jb.png" alt="ncx"/></div>                                    
                                    <h3>{t("settle_payment_invoices_and_payroll")}</h3>
                                    <p className="ved">{t("settle_payment_invoices_and_payroll_content")}</p>
                                    <a href="https://ncx.cx/plans">{t("compare_business_account_plans")}</a>
                                </article>
                            </Slide>
                            <Slide index={1}>
                                <article className='ncxcard01'>
                                    <div className="mkhg"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693029943/57_lgrrcz.png" alt="ncx"/></div>
                                    <h3>{t("optimised_invoice_management")}</h3>
                                    <p>{t("optimised_invoice_management_content")}</p>
                                    <a href="https://ncx.cx/plans">{t("compare_business_account_plans")}</a>
                                </article>
                            </Slide>
                            <Slide index={2}>
                                <article className='ncxcard01'>
                                    <div className="mkhg"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692954007/29-min_y4b53o.png" alt="ncx"/></div>                                    
                                    <h3>{t("digital_account_business")}</h3>
                                    <p>{t("digital_account_business_content")}</p>
                                    <a href="https://ncx.cx/plans">{t("compare_business_account_plans")}</a>
                                </article>
                            </Slide>
                            <Slide index={3}>
                                <article className='ncxcard01 vb'>
                                    <div className="mkhg"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692891135/7_4_ob426b.png" alt="ncx"/></div>                                    
                                    <h3>{t("simplified_team_spend")}</h3>
                                    <p>{t("simplified_team_spend_content")}</p>
                                    <a href="https://ncx.cx/plans">{t("compare_business_account_plans")}</a>
                                </article>
                            </Slide>
                        </Slider>
                        <div className="bvb">
                            <ButtonBack className="buttonBack"><CardLeftArrow /></ButtonBack>
                            <ButtonNext className="buttonNext"><CardRightArrow /></ButtonNext>
                        </div>
                        </CarouselProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookDesktop;