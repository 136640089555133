import React from "react";
import MyHeader from '../../components/header/header';
import MyFooter from "../../components/footer/footer";
import PlanScreen from '../../components/plans/plans1';
import PlansScreen2 from "../../components/plans/plans2";
import MobilePlan from '../../components/plans/mobile';

function NCXPlans()
{
    return(
        <div className="ncx-plans">

            <MyHeader />

            <div className="plan-dsktp">

                <PlanScreen />

                <PlansScreen2 />

            </div>

            <div className="plan--mbl">

                <MobilePlan />
                
            </div>

            <MyFooter />

        </div>
    )
}
export default NCXPlans;