import React, {useState} from "react";
import './style.css';
import {ReactComponent as QW} from '../../images/qw.svg';
import {ReactComponent as QE} from '../../images/qe.svg';
import Button from "@mui/material/Button";
import {ReactComponent as BTNArrow} from '../../images/btn_arow.svg';
import {ReactComponent as QR} from '../../images/qr.svg';
import { useTranslation } from "react-i18next";

function PlansScreenMbl()
{
    const { t } = useTranslation();

    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    };

    const [CardState, setCardState] = useState(1);
    const CardTab = (index) => {
        setCardState(index);
    };

    return(
        <div className="mbl-screen">
            <div className="plan-ss">
                <div className="busi-plan">
                    <div className="container">
                        <h2>{t("find_the_best_plan_for_your_business")}</h2>
                        <div className="desk_pla">
                            <div className="plan-tabs">
                                <h3>{t("compare_our_plans")}</h3>
                                <div className="bttP">
                                    <button
                                        className={toggleState === 1 ? "pl-btn purpl active" : "pl-btn purpl"}
                                        onClick={() => toggleTab(1)}
                                    >
                                        {t("medium")}
                                    </button>
                                    <button
                                        className={toggleState === 2 ? "pl-btn green active" : "pl-btn green"}
                                        onClick={() => toggleTab(2)}
                                    >
                                        {t("enterprise")}
                                    </button>
                                </div>
                            </div>
                            <div className="plans-cont">
                                <div className={toggleState === 1 ? "pl__cont  active" : "pl__cont"}>
                                    <div className="mbl_pla">
                                        <div class="wetb">
                                            <div className="clr qw lgt">
                                                <h2>{t("smart")}</h2>
                                                <p className="st">{t("staked_ncxt_required")}</p>
                                                <h3>50,000 NCXT </h3>
                                                <p className="apr">{t("earn_additional")} <span>3%</span>{t("in_staking_rewards")}</p>
                                                <div className="bn">{t("save_on_trading_withdrawal_fees")}</div>
                                                <p className="txt">{t("an_online_business_account")} <span>{t("simplfy_day_finances")}</span></p>
                                                <div className="bt">
                                                    <Button href="#">{t("available_soon")}</Button>
                                                </div>
                                            </div>
                                            <div className="bg-dov grey-c">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693048810/virtual-card-purple_1_kfjai7.svg" alt="ncx"/>
                                                <p>{t("business_account_cards")}</p>
                                            </div>
                                            <div className="vstT">
                                                <div className="tg">
                                                    <div className="ttip">
                                                        <p>{t("company_crypto_debit_cards")}</p>
                                                        <div className="topti">
                                                            <QE />
                                                            <div class="top">
                                                                <p>{t("ncx_crypto_debit_cards")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="verb lgt">
                                                    <p>1</p>
                                                </div>
                                                <div className="Cr_d">
                                                    <button
                                                        className={CardState === 1 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(1)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 2 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(2)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 3 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(3)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 4 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(4)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </button>
                                                </div>
                                                <div className="gtf-P">
                                                    <a href="https://ncx.cx/company-cards">{t("discover_more")} <BTNArrow /></a>
                                                </div>
                                            </div>
                                            <div className="comp-card">
                                                <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("select_card")}</h3>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("gold_card")}</h3>
                                                        <p>+10,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("platinum_card")}</h3>
                                                        <p>+15,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("black_card")}</h3>
                                                        <p>+20,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("sub_accounts")} <br />{t("trades_portfolio_managers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("sub_accounts_trades_portfolio_managers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <p>1</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("trading_feed_discount")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("trading_feed_discount")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <p>10%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("withdrawal_fee_refund")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("withdrawal_fee_refund")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <p>10%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("additional_staking_rewards_apr")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("additional_staking_rewards_apr")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <p>3%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("use_ncxt_as_leverage")}<br />{t("trading_collateral")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("Use NCXT as Leverage Trading Collateral")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <QR />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("incoming_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("incoming_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <p>{t("$5_fixed_fee_transfer")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("outgoing_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("outgoing_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <p>{t("1%_fixed_fee_transfer")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("integrated_invoicing_tools")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("integrated_invoicing_tools")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <QR />
                                            </div>
                                            <div className="bg-dov invoice">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/>
                                                <p>{t("invoicing_bookkeeping")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("digitized_receipts")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("digitized_receipts")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <p>{t("digitzed")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("receipts_forward")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("receipts_forward")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <QR />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("dashboard")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("dashboard")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <p>{t("standard")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("accounting_software_integrations")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("accounting_software_integrations")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <p>10</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("fast_api_connectivity")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("fast_api_connectivity")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <QW />
                                            </div>
                                            <div className="bg-dov invoice">
                                                {/* <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/> */}
                                                <p>{t("customer_care")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("7_day_a_week_support")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("7_day_a_week_support")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew lgt">
                                                <QW />
                                            </div>
                                        </div>


                                        <div class="wetb">
                                            <div className="clr qw mdm">
                                                <h2>{t("premium")}</h2>
                                               <p className="st">{t("staked_ncxt_required")}</p>
                                                <h3>100,000 NCXT</h3>
                                                <p className="apr">{t("earn_additional")} <span>4% </span> {t("in_staking_rewards")} </p>
                                                <div className="bn">{t("save_20%_on_trading_withdrawal_fees")}</div>
                                                <p className="txt">{t("advanced_tools_that_get_bookkeeping")}</p>
                                                <div className="bt">
                                                    <Button href="#">{t("available_soon")}</Button>
                                                </div>
                                            </div>
                                            <div className="bg-dov grey-c">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693048810/virtual-card-purple_1_kfjai7.svg" alt="ncx"/>
                                                <p>{t("business_account_cards")}</p>
                                            </div>
                                            <div className="vstT">
                                                <div className="tg">
                                                    <div className="ttip">
                                                        <p>{t("company_crypto_debit_cards")}</p>
                                                        <div className="topti">
                                                            <QE />
                                                            <div class="top">
                                                                <p>{t("ncx_crypto_debit_cards")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="verb mdm">
                                                    <p>3</p>
                                                </div>
                                                <div className="Cr_d">
                                                    <button
                                                        className={CardState === 1 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(1)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 2 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(2)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 3 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(3)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 4 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(4)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </button>
                                                </div>
                                                <div className="gtf-P">
                                                    <a href="https://ncx.cx/company-cards">{t("discover_more")} <BTNArrow /></a>
                                                </div>
                                            </div>
                                            <div className="comp-card">
                                                <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("select_card")}</h3>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("gold_card")}</h3>
                                                        <p>+10,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("platinum_card")}</h3>
                                                        <p>+15,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("black_card")}</h3>
                                                        <p>+20,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("sub_accounts")}<br />{t("trades_portfolio_managers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("sub_accounts_trades_portfolio_managers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <p>3</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("trading_feed_discount")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("trading_feed_discount")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <p>20%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("withdrawal_fee_refund")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("withdrawal_fee_refund")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <p>20%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("additional_staking_rewards_apr")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("additional_staking_rewards_apr")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <p>4%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("use_ncxt_as_leverage")} <br />{t("trading_collateral")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("Use NCXT as Leverage Trading Collateral")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <QR />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("incoming_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("incoming_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <p>{t("$5_fixed_fee_transfer")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("outgoing_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("outgoing_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <p>{t("0.9%_fixed_fee_transfer")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("integrated_invoicing_tools")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("integrated_invoicing_tools")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <QR />
                                            </div>
                                            <div className="bg-dov invoice">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/>
                                                <p>{t("invoicing_bookkeeping")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("digitized_receipts")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("digitized_receipts")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("receipts_forward")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("receipts_forward")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <p>{t("digitized_certified")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("dashboard")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("dashboard")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <p>{t("standard")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("accounting_software_integrations")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("accounting_software_integrations")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <p>15</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("fast_api_connectivity")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("fast_api_connectivity")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <QW />
                                            </div>
                                            <div className="bg-dov invoice">
                                                {/* <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/> */}
                                                <p>{t("customer_care")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("7_day_a_week_support")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("7_day_a_week_support")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew mdm">
                                                <QW />
                                            </div>
                                        </div>


                                        <div class="wetb">
                                            <div className="clr qw hrd">
                                                <h2>{t("advanced")}</h2>
                                               <p className="st">{t("staked_ncxt_required")}</p>
                                                <h3>250,000 NCXT</h3>
                                                <p className="apr">{t("earn_additional")} <span>6%</span> {t("in_staking_rewards")} </p>
                                                <div className="bn">{t("save_30%_on_trading_withdrawal_fees")}</div>
                                                <p className="txt">{t("automated_on_the_go_finances_with_our_hightlights")}</p>
                                                <div className="bt">
                                                    <Button href="#">{t("available_soon")}</Button>
                                                </div>
                                            </div>
                                            <div className="bg-dov grey-c">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693048810/virtual-card-purple_1_kfjai7.svg" alt="ncx"/>
                                                <p>{t("business_account_cards")}</p>
                                            </div>
                                            <div className="vstT">
                                                <div className="tg">
                                                    <div className="ttip">
                                                        <p>{t("company_crypto_debit_cards")}</p>
                                                        <div className="topti">
                                                            <QE />
                                                            <div class="top">
                                                                <p>{t("ncx_crypto_debit_cards")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="verb hrd">
                                                    <p>5</p>
                                                </div>
                                                <div className="Cr_d">
                                                    <button
                                                        className={CardState === 1 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(1)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 2 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(2)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 3 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(3)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 4 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(4)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </button>
                                                </div>
                                                <div className="gtf-P">
                                                    <a href="https://ncx.cx/company-cards">{t("discover_more")} <BTNArrow /></a>
                                                </div>
                                            </div>
                                            <div className="comp-card">
                                                <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("select_card")}</h3>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("gold_card")}</h3>
                                                        <p>+10,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("platinum_card")}</h3>
                                                        <p>+15,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("black_card")}</h3>
                                                        <p>+20,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("sub_accounts")}<br />{t("trades_portfolio_managers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("sub_accounts_trades_portfolio_managers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <p>5</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("trading_feed_discount")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("trading_feed_discount")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <p>30%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("withdrawal_fee_refund")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("withdrawal_fee_refund")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <p>30%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("additional_staking_rewards_apr")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("additional_staking_rewards_apr")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <p>6%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("use_ncxt_as_leverage")}<br />{t("trading_collateral")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("Use NCXT as Leverage Trading Collateral")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <QR />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("incoming_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("incoming_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <p>{t("$5_fixed_fee_transfer")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("outgoing_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("outgoing_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <p>{t("0.8%_fixed_fee_transfer")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("integrated_invoicing_tools")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("integrated_invoicing_tools")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <QW />
                                            </div>
                                            <div className="bg-dov invoice">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/>
                                                <p>{t("invoicing_bookkeeping")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("digitized_receipts")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("digitized_receipts")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("receipts_forward")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("receipts_forward")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <p>{t("digitized_certified")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("dashboard")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("dashboard")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <p>{t("standard")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("accounting_software_integrations")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("accounting_software_integrations")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <p>20</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("fast_api_connectivity")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("fast_api_connectivity")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <QW />
                                            </div>
                                            <div className="bg-dov invoice">
                                                {/* <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/> */}
                                                <p>{t("customer_care")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("7_day_a_week_support")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("7_day_a_week_support")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew hrd">
                                                <QW />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={toggleState === 2 ? "pl__cont  active" : "pl__cont"}>
                                    <div className="mbl_pla">
                                        <div class="wetb">
                                            <div className="clr qw green1">
                                                <h2>{t("essential")}</h2>
                                               <p className="st">{t("staked_ncxt_required")}</p>
                                                <h3>500,000 NCXT</h3>
                                                <p className="apr">{t("earn_additional")} <span>8%</span> {t("in_staking_rewards")} </p>
                                                <div className="bn">{t("save_40%_on_trading_withdrawal_fees")}</div>
                                                <p className="txt">{t("fast_easy_business_banking")}</p>
                                                <div className="bt">
                                                    <Button href="#">{t("available_soon")}</Button>
                                                </div>
                                            </div>
                                            <div className="bg-dov grey-c">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057733/qt_gkyw8j.svg" alt="ncx"/>
                                                <p>{t("business_account_cards")}</p>
                                            </div>
                                            <div className="vstT">
                                                <div className="tg">
                                                    <div className="ttip">
                                                        <p>{t("company_crypto_debit_cards")}</p>
                                                        <div className="topti">
                                                            <QE />
                                                            <div class="top">
                                                                <p>{t("ncx_crypto_debit_cards")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="verb green1">
                                                    <p>10</p>
                                                </div>
                                                <div className="Cr_d">
                                                    <button
                                                        className={CardState === 1 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(1)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 2 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(2)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 3 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(3)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 4 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(4)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </button>
                                                </div>
                                                <div className="gtf-P">
                                                    <a href="https://ncx.cx/company-cards">{t("discover_more")} <BTNArrow /></a>
                                                </div>
                                            </div>
                                            <div className="comp-card">
                                                <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("select_card")}</h3>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("gold_card")}</h3>
                                                        <p>+10,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("platinum_card")}</h3>
                                                        <p>+15,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("black_card")}</h3>
                                                        <p>+20,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("sub_accounts")}<br />{t("trades_portfolio_managers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("sub_accounts_trades_portfolio_managers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <p>10</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("trading_feed_discount")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("trading_feed_discount")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <p>40%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("withdrawal_fee_refund")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("withdrawal_fee_refund")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <p>40%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("additional_staking_rewards_apr")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("additional_staking_rewards_apr")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <p>8%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("use_ncxt_as_leverage")}<br />{t("trading_collateral")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("Use NCXT as Leverage Trading Collateral")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <QR />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("incoming_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("incoming_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <p>{t("free")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("outgoing_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("outgoing_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <p>$20{t("transfer")}</p>
                                            </div>
                                            <div className="bg-dov invoice">
                                                {/* <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/> */}
                                                <p>{t("expense_spend_management")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("team_access")}<br /> {t("user_included")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("team_access_user_included")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <p>5</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("custom_permission_manager_role")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("custom_permission_manager_role")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <QR />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("spending_categories")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("spending_categories")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("expense_report")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("expense_report")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <QR />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("team_budget")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("team_budget")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <QR />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("bulk_transfer")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("bulk_transfer")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <QR />
                                            </div>
                                            <div className="bg-dov invoice">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057741/qy_tau829.svg" alt="ncx"/>
                                                <p>{t("bookkeeping_reporting")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("all_in_one_invoicing_tool")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("all_in_one_invoicing_tool")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("digitized_certified_receipts")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("digitized_certified_receipts")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("receipts_forward")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("receipts_forward")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("dashboard")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("dashboard")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <p>{t("standard")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("accountant_tools_integrations")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("accountant_tools_integrations")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <p>25</p>
                                            </div>
                                            <div className="bg-dov invoice">
                                                {/* <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057741/qy_tau829.svg" alt="ncx"/> */}
                                                <p>{t("customer_care")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("7_day_a_week_support")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("7_day_a_week_support")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("key_account_manager")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("key_account_manager")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green1">
                                                <QR />
                                            </div>
                                        </div>


                                        <div class="wetb">
                                            <div className="clr qw green2">
                                                <h2>{t("business")}</h2>
                                               <p className="st">{t("staked_ncxt_required")}</p>
                                                <h3>1,000,000 NCXT </h3>
                                                <p className="apr">{t("earn_additional")} <span>10%</span> {t("in_staking_rewards")}</p>
                                                <div className="bn">{t("save_60%_on_trading_withdrawal_fees")}</div>
                                                <p className="txt">{t("team_spend_expense_management_tools")}</p>
                                                <div className="bt">
                                                    <Button href="#">{t("available_soon")}</Button>
                                                </div>
                                            </div>
                                            <div className="bg-dov grey-c">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057733/qt_gkyw8j.svg" alt="ncx"/>
                                                <p>{t("business_account_cards")}</p>
                                            </div>
                                            <div className="vstT">
                                                <div className="tg">
                                                    <div className="ttip">
                                                        <p>{t("company_crypto_debit_cards")}</p>
                                                        <div className="topti">
                                                            <QE />
                                                            <div class="top">
                                                                <p>{t("ncx_crypto_debit_cards")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="verb green2">
                                                    <p>20</p>
                                                </div>
                                                <div className="Cr_d">
                                                    <button
                                                        className={CardState === 1 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(1)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 2 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(2)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 3 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(3)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 4 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(4)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </button>
                                                </div>
                                                <div className="gtf-P">
                                                    <a href="https://ncx.cx/company-cards">{t("discover_more")} <BTNArrow /></a>
                                                </div>
                                            </div>
                                            <div className="comp-card">
                                                <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("select_card")}</h3>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("gold_card")}</h3>
                                                        <p>+10,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("platinum_card")}</h3>
                                                        <p>+15,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("black_card")}</h3>
                                                        <p>+20,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("sub_accounts")}<br />{t("trades_portfolio_managers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("sub_accounts_trades_portfolio_managers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <p>30</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("trading_feed_discount")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("trading_feed_discount")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <p>60%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("withdrawal_fee_refund")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("withdrawal_fee_refund")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <p>60%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("additional_staking_rewards_apr")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("additional_staking_rewards_apr")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <p>10%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("use_ncxt_as_leverage")}<br />{t("trading_collateral")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("Use NCXT as Leverage Trading Collateral")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("incoming_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("incoming_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <p>{t("free")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("outgoing_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("outgoing_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <p>$10{t("transfer")}</p>
                                            </div>
                                            <div className="bg-dov invoice">
                                                {/* <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/> */}
                                                <p>{t("expense_spend_management")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("team_access")}<br />{t("user_included")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("team_access_user_included")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <p>15</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("custom_permission_manager_role")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("custom_permission_manager_role")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("spending_categories")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("spending_categories")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("expense_report")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("expense_report")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("team_budget")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("team_budget")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("bulk_transfer")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("bulk_transfer")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <QW />
                                            </div>
                                            <div className="bg-dov invoice">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057741/qy_tau829.svg" alt="ncx"/>
                                                <p>{t("bookkeeping_reporting")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("all_in_one_invoicing_tool")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("all_in_one_invoicing_tool")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("digitized_certified_receipts")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("digitized_certified_receipts")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("receipts_forward")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("receipts_forward")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("dashboard")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("dashboard")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <p>{t("advanced")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("accountant_tools_integrations")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("accountant_tools_integrations")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <p>30</p>
                                            </div>
                                            <div className="bg-dov invoice">
                                                {/* <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057741/qy_tau829.svg" alt="ncx"/> */}
                                                <p>{t("customer_care")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("7_day_a_week_support")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("7_day_a_week_support")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <p>{t("priority_support")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("key_account_manager")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("key_account_manager")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green2">
                                                <QW />
                                            </div>
                                        </div>

                                        <div class="wetb">
                                            <div className="clr qw green3">
                                                <h2>{t("enterprise")}</h2>
                                               <p className="st">{t("staked_ncxt_required")}</p>
                                                <h3>2,500,000 NCXT </h3>
                                                <p className="apr">{t("earn_additional")} <span>12%</span> {t("in_staking_rewards")}</p>
                                                <div className="bn">{t("save_80%_on_trading_withdrawal_fees")}</div>
                                                <p className="txt">{t("the_complete_package_for_longer_teams")}</p>
                                                <div className="bt">
                                                    <Button href="#">{t("available_soon")}</Button>
                                                </div>
                                            </div>
                                            <div className="bg-dov grey-c">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057733/qt_gkyw8j.svg" alt="ncx"/>
                                                <p>{t("business_account_cards")}</p>
                                            </div>
                                            <div className="vstT">
                                                <div className="tg">
                                                    <div className="ttip">
                                                        <p>{t("company_crypto_debit_cards")}</p>
                                                        <div className="topti">
                                                            <QE />
                                                            <div class="top">
                                                                <p>{t("ncx_crypto_debit_cards")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="verb green3">
                                                    <p>50</p>
                                                </div>
                                                <div className="Cr_d">
                                                    <button
                                                        className={CardState === 1 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(1)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 2 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(2)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 3 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(3)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </button>
                                                    <button
                                                        className={CardState === 4 ? "crd-btn active" : "crd-btn"}
                                                        onClick={() => CardTab(4)}
                                                    >
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </button>
                                                </div>
                                                <div className="gtf-P">
                                                    <a href="https://ncx.cx/company-cards">{t("discover_more")} <BTNArrow /></a>
                                                </div>
                                            </div>
                                            <div className="comp-card">
                                                <div className={CardState === 1 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("select_card")}</h3>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 2 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("gold_card")}</h3>
                                                        <p>+10,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 3 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("platinum_card")}</h3>
                                                        <p>+15,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                                <div className={CardState === 4 ? "card__cont  active" : "card__cont"}>
                                                    <div className="gt-P">
                                                        <h3>{t("black_card")}</h3>
                                                        <p>+20,000 {t("staked_ncxt")}</p>
                                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("sub_accounts")}<br />{t("trades_portfolio_managers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("sub_accounts_trades_portfolio_managers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <p>100</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("trading_feed_discount")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("trading_feed_discount")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <p>80%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("withdrawal_fee_refund")} </p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("withdrawal_fee_refund")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <p>80%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("additional_staking_rewards_apr")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("additional_staking_rewards_apr")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <p>12%</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("use_ncxt_as_leverage")}<br />{t("trading_collateral")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("Use NCXT as Leverage Trading Collateral")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("incoming_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("incoming_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <p>{t("free")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("outgoing_swift_transfers")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("outgoing_swift_transfers")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <p>$5{t("transfer")}</p>
                                            </div>
                                            <div className="bg-dov invoice">
                                                {/* <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693054989/reimbursement_nsihsh.svg" alt="ncx"/> */}
                                                <p>{t("expense_spend_management")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("team_access")}<br />{t("user_included")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("team_access_user_included")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <p>30</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("custom_permission_manager_role")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("custom_permission_manager_role")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("spending_categories")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("spending_categories")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("expense_report")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("expense_report")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("team_budget")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("team_budget")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("bulk_transfer")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("bulk_transfer")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <QW />
                                            </div>
                                            <div className="bg-dov invoice">
                                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057741/qy_tau829.svg" alt="ncx"/>
                                                <p>{t("bookkeeping_reporting")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("all_in_one_invoicing_tool")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("all_in_one_invoicing_tool")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("digitized_certified_receipts")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("digitized_certified_receipts")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("receipts_forward")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("receipts_forward")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <QW />
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("dashboard")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("dashboard")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <p>{t("advanced")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("accountant_tools_integrations")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("accountant_tools_integrations")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <p>33</p>
                                            </div>
                                            <div className="bg-dov invoice">
                                                {/* <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693057741/qy_tau829.svg" alt="ncx"/> */}
                                                <p>{t("customer_care")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("7_day_a_week_support")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("7_day_a_week_support")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <p>{t("priority_support")}</p>
                                            </div>
                                            <div className="bt_dd">
                                                <p>{t("key_account_manager")}</p>
                                                <div className="topti">
                                                    <QE />
                                                    <div class="top">
                                                        <p>{t("key_account_manager")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="verb vew green3">
                                                <QW />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PlansScreenMbl;