import React from "react";
import './style.css';
import Button from "@mui/material/Button";
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import Promo1 from '../../images/promo-1.png';
import Promo2 from '../../images/promo-2.png';
import Promo3 from '../../images/promo-3.png';
import Promo4 from '../../images/promo-4.png';
import Promo5 from '../../images/promo-5.png';
import Promo6 from '../../images/promo-6.png';
import Promo7 from '../../images/promo-7.png';
import Promo8 from '../../images/promo-8.png';
import Promo9 from '../../images/promo-9.png';
import Promo10 from '../../images/promo-10.png';
import {ReactComponent as BTNArrow} from '../../images/btn_arow.svg';
import { useTranslation } from "react-i18next";

function DigitalBusinessAcnt()
{
    const { t } = useTranslation();

    return(
        <div className="dig-B">
            <div className="qwm one">
                <div className="container">
                    <div className="row caz">
                        <div className="col">
                            <h2>{t("optimize_your_business")} <br/>{t("with_ncx_assets")}<br/>{t("business_accountver")} </h2>
                            <p>{t("business_par_content")}</p>
                            <Button href="https://ncx.cx/plans">{t("compare_plans")}</Button>
                        </div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className="partner va">
                    <CarouselProvider
                        visibleSlides={6}
                        totalSlides={10}
                        step={1}
                        naturalSlideWidth={300}
                        naturalSlideHeight={150}
                        // hasMasterSpinner
                        isPlaying={true}
                        interval={3000}
                        orientation="horizontal"
                        infinite
                        playDirection="forward"
                    >

                    <Slider>
                        <Slide index={0} className="qsfg">
                            <img src={Promo1} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={1} className="qsfg">
                            <img src={Promo2} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={2} className="qsfg">
                            <img src={Promo3} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={3} className="qsfg forth">
                            <img src={Promo4} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={4} className="qsfg">
                            <img src={Promo5} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={5} className="qsfg">
                            <img src={Promo6} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={6} className="qsfg">
                            <img src={Promo7} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={7} className="qsfg eght">
                            <img src={Promo8} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={8} className="qsfg">
                            <img src={Promo9} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={9} className="qsfg">
                            <img src={Promo10} alt="ncx-partner"/>
                        </Slide>

                    </Slider>

                    </CarouselProvider>
                </div>
            </div>

            <div id="qwPcX" className="partner va">
                    <CarouselProvider
                        visibleSlides={2}
                        totalSlides={10}
                        step={1}
                        naturalSlideWidth={300}
                        naturalSlideHeight={150}
                        // hasMasterSpinner
                        isPlaying={true}
                        interval={3000}
                        orientation="horizontal"
                        infinite
                        playDirection="forward"
                    >

                    <Slider>
                        <Slide index={0} className="qsfg">
                            <img src={Promo1} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={1} className="qsfg">
                            <img src={Promo2} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={2} className="qsfg">
                            <img src={Promo3} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={3} className="qsfg forth">
                            <img src={Promo4} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={4} className="qsfg">
                            <img src={Promo5} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={5} className="qsfg">
                            <img src={Promo6} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={6} className="qsfg">
                            <img src={Promo7} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={7} className="qsfg eght">
                            <img src={Promo8} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={8} className="qsfg">
                            <img src={Promo9} alt="ncx-partner"/>
                        </Slide>

                        <Slide index={9} className="qsfg">
                            <img src={Promo10} alt="ncx-partner"/>
                        </Slide>

                    </Slider>

                    </CarouselProvider>
                </div>

            <div className="qwn tow">
                <div className="container">
                    <h2>{t("ncx_digital_assets_business_account")}</h2>
                    <div className="row asPo">
                        <div className="col">
                            <div className="er">
                                <div className="img-bx">
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692891135/5_3_graoqd.png" alt="ncx"/>
                                </div>
                                <div className="txt-bx">
                                    <h3>{t("company_crypto_debit_cards")}</h3>
                                    <p>{t("give_your_employees")} <br />{t("give_your_employees_2")} <br />{t("give_your_employees_3")}</p>
                                    <div className="vrF">
                                        <a href="https://ncx.cx/plans">{t("discover_more")} <BTNArrow /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="er">
                                <div className="img-bx">
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693225726/12_zx5vk9.png" alt="ncx"/>
                                </div>
                                <div className="txt-bx">
                                    <h3>{t("payment_limits")}</h3>
                                    <p>{t("payment_limit_one")} <br />{t("payment_limit_two")} <br />{t("payment_limit_three")}</p>
                                    <div className="vrF">
                                        <a href="https://ncx.cx/plans">{t("discover_more")} <BTNArrow /></a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row asPo">
                        <div className="col">
                            <div className="er">
                                <div className="img-bx">
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692891164/RENDER_1_3_ndm2jb.png" alt="ncx"/>
                                </div>
                                <div className="txt-bx">
                                    <h3>{t("digital_asset_payment_settlement")}</h3>
                                    <p>{t("digital_asset_payment_settlement_one")} <br />{t("digital_asset_payment_settlement_two")} <br />{t("digital_asset_payment_settlement_three")}</p>
                                    <div className="vrF">
                                        <a href="https://ncx.cx/plans">{t("discover_more")} <BTNArrow /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="er">
                                <div className="img-bx">
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692891135/7_4_ob426b.png" alt="ncx"/>
                                </div>
                                <div className="txt-bx">
                                    <h3>{t("no_more_tracking_down")} <br />{t("missing_receipts")}</h3>
                                    <p>{t("no_more_tracking_down_missing_receipts_one")} <br />{t("no_more_tracking_down_missing_receipts_two")}</p>
                                    <div className="vrF">
                                        <a href="https://ncx.cx/plans">{t("discover_more")} <BTNArrow /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="qwn three">
                <div className="container">
                    <h2>{t("with_ncx_digital_assets_business_account")}</h2>
                    <div className="row asoOp">
                        <div className="col ma">
                            <div className="er">
                                <div className="img-bx">
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692943069/45_s8nnmu.png" alt="ncx"/>
                                </div>
                                <div className="txt-bx">
                                    <h3>{t("ceo_business_owners")}</h3>
                                    <p>{t("ceo_business_owners_content")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col mb">
                            <div className="er">
                                <div className="img-bx">
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692943069/46_wwzulo.png" alt="ncx"/>
                                </div>
                                <div className="txt-bx">
                                    <h3>{t("investor_and_corporate_entities")} </h3>
                                    <p>{t("investor_and_corporate_entities_content")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row asoOp">
                        <div className="col mc">
                            <div className="er">
                                <div className="img-bx">
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692943069/Frame_1410128846_fhrfyy.png" alt="ncx"/>
                                </div>
                                <div className="txt-bx">
                                    <h3>{t("portfolio_manager_and_traders")}</h3>
                                    <p>{t("portfolio_manager_and_traders_content")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col md">
                            <div className="er">
                                <div className="img-bx">
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692943069/48_hymton.png" alt="ncx"/>
                                </div>
                                <div className="txt-bx">
                                    <h3>{t("starts_up_and_vcs")}</h3>
                                    <p>{t("starts_up_and_vcs_content")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="qwn four">
                <div className="container">
                    <h2>{t("boost_your_business_with_pro")} </h2>
                    <div className="row sxRR">
                        <div className="col qw">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692948750/cwq_xtcmgu.png" alt="ncx"/>
                            <h3>{t("assign_budgets_and_track_spending")}</h3>
                            <p>{t("assign_budgets_and_track_spending_content")}</p>
                        </div>
                        <div className="col qr">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692948226/20_cjk7l1.png" alt="ncx"/>
                            <h3>{t("predefined_access_rights_let_you_delegate")}</h3>
                            <p>{t("predefined_access_rights_let_you_delegate_content")}</p>
                        </div>
                        <div className="col qt">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692948226/21_unmpca.png" alt="ncx"/>
                            <h3>{t("boost_your_efficieny_by_empowering_your_team")}</h3>
                            <p>{t("boost_your_efficieny_by_empowering_your_team_content")}</p>
                        </div>
                    </div>
                    <div className="caz cta"><Button href="https://ncx.cx/plans">{t("compare_plans")}</Button></div>
                </div>
            </div>
            
            <div className="qwn five">
                <div className="container">
                    <div className="row vUU">
                        <div className="col im">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692949546/8_3_ytqfvr.png" alt="ncx"/>
                        </div>
                        <div className="col tx">
                            <h2>{t("provide_team_members_with_company")}</h2>
                            <h3>{t("tailor_company_crypto_debit_cards")}</h3>
                            <p>{t("use_physicals_and_vitual_cards_for_business")}</p>
                            <div className="qmR">
                                <a href="https://ncx.cx/company-cards">{t("compare_card")} <BTNArrow /></a>
                            </div>
                            <h3>{t("real_time_control_of_spending")}</h3>
                            <p>{t("set_the_limits_and_permissions")} <span>{t("per_month_per_day_and")}</span></p>
                            <div className="qmRv">
                                <a href="https://ncx.cx/company-cards">{t("compare_card")} <BTNArrow /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="qwn six">
                <div className="container">
                    <h2>{t("simplify_finance_management_with_business_team_accounts")}</h2>
                    <div class="werPP row">
                        <div class="col">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692952423/transparency_salwbw.png" alt="global" />
                            <h3>{t("transparency")}</h3>
                            <p>{t("transparency_content")}</p>
                        </div>
                        <div class="col">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692952423/team_la6rsl.png" alt="global" />
                            <h3>{t("team_spirit")}</h3>
                            <p>{t("team_spirit_content")}</p>
                        </div>
                        <div class="col">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692952423/peace_seus3a.png" alt="global" />
                            <h3>{t("peace_of_mind")}</h3>
                            <p>{t("peace_of_mind_content")}</p>
                        </div>
                    </div>
                    <div className="mbl__sec__cr">
                        <CarouselProvider
                            visibleSlides={1}
                            totalSlides={3}
                            step={1}
                            naturalSlideWidth={300}
                            isIntrinsicHeight={true}
                            isPlaying={true}
                            interval={3000}
                            orientation="horizontal"
                            infinite
                            playDirection="forward"
                        >
                            <Slider>
                                <Slide index={0} className="mnl__cx">
                                    <div className="azx">
                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692952423/transparency_salwbw.png" alt="global" />
                                        <h3>{t("transparency")}</h3>
                                        <p>{t("transparency_content")}</p>
                                    </div>
                                </Slide>
                                <Slide index={1} className="mnl__cx">
                                    <div className="azx">
                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692952423/team_la6rsl.png" alt="global" />
                                        <h3>{t("team_spirit")}</h3>
                                        <p>{t("team_spirit_content")}</p>
                                    </div>
                                </Slide>
                                <Slide index={2} className="mnl__cx">
                                    <div className="azx">
                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692952423/peace_seus3a.png" alt="global" />
                                        <h3>{t("peace_of_mind")}</h3>
                                        <p>{t("peace_of_mind_content")}</p>
                                    </div>
                                </Slide>
                            </Slider>
                            <DotGroup />
                        </CarouselProvider>
                    </div>
                </div>
            </div>

            <div className="qwn seven">
                <div className="container">
                    <h2>{t("ncx_digital_assets_business_account")}</h2>
                    <div className="row sxRR">
                        <div className="col qw">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692954007/28-min_e5kro7.png" alt="ncx"/>
                            <h3>{t("settle_payment")}</h3>
                            <p className="mn--f">{t("settle_payment_content")}</p>
                            <div className="qmRv">
                                <a href="https://ncx.cx/plans">{t("compare_plans")}<BTNArrow /></a>
                            </div>
                        </div>
                        <div className="col qr">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692954007/29-min_y4b53o.png" alt="ncx"/>
                            <h3>{t("a_complete_digital_assets_account")}</h3>
                            <p className="mn--f">{t("a_complete_digital_assets_account_content")}</p>
                            <div className="qmRv">
                                <a href="https://ncx.cx/plans">{t("compare_plans")}<BTNArrow /></a>
                            </div>
                        </div>
                        <div className="col qt">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692954007/30-min_w2zhft.png" alt="ncx"/>
                            <h3>{t("save_10s_of_hours_per_month")}</h3>
                            <p className="mn--f">{t("save_10s_of_hours_per_month_content")}</p>
                            <div className="qmRv">
                                <a href="https://ncx.cx/accounting">{t("simplify_bookkeeping")}<BTNArrow /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DigitalBusinessAcnt;