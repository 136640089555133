import React from "react";
import './style.css';
import '../cardpage/newcardpagedesign/style.css';
import Button from "@mui/material/Button";
import CardBox from '../cardpage/newcardpagedesign/CryptoCardSet';
import { ReactComponent as WhiteTick } from '../../images/white-tick.svg';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { ReactComponent as CardLeftArrow } from '../../images/card_leftarrow.svg';
import { ReactComponent as CardRightArrow } from '../../images/card_rightarrow.svg';
import { useTranslation } from "react-i18next";

function AccountingDesktop(){
    
    const { t } = useTranslation();

    return(
        <div className="accnt__pg">
            <div className="accnt__v one">
                <div className="container">
                    <div className="row">
                        <div className="col sdf">
                            <h2>{t("company_crpto_debit")} <span>{t("cards_for_your_business")}</span></h2>
                            <ul>
                                <li>{t("spending_limits_up_to")}</li>
                                <li>{t("free_corporate_cards")}</li>
                                <li>{t("customer_support_24_hours")}</li>
                            </ul>
                            <Button href="#compare-cards">{t("compare_card")}</Button>
                        </div>
                        <div className="col mgi">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692876501/15_2_orwci6.png" alt="ncx"/>
                        </div>
                    </div>
                </div>
            </div>

            <div id="compare-cards" className="range_cards">
                <div className="container">
                <h1 className="rnag_gg">{t("ncx_company_crypto_debit_cards_range")}</h1>
                    <div className="card_bt">
                        <div className="vwexx">
                            <div className="qwrc"></div>
                            <div className="qwrc">
                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                <p>{t("select_card")}</p>
                            </div>
                            <div className="qwrc">
                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                <p>{t("gold_card")}</p>
                            </div>
                            <div className="qwrc">
                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                <p>{t("plantinum_card")}</p>
                            </div>
                            <div className="qwrc">
                                <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                <p>{t("black_card")}</p>
                            </div>
                        </div>
                        <div className="vwexx">
                            <div className="qwrc fcisc">
                                <p>{t("card_payment_limits")}</p>
                               <span>{t("per_calendar_month")}</span>
                            </div>
                            <div className="qwrc">
                                <p>$15,000</p>
                            </div>
                            <div className="qwrc">
                                <p>$35,000</p>
                            </div>
                            <div className="qwrc">
                                <p>$75,000</p>
                            </div>
                            <div className="qwrc">
                                <p>$150,000</p>
                            </div>
                        </div>
                        <div className="vwexx">
                            <div className="qwrc fcisc">
                                <p>{t("cash_withdrawal_limits")}</p>
                               <span>{t("per_calendar_month")}</span>
                            </div>
                            <div className="qwrc">
                                <p>$2,500</p>
                            </div>
                            <div className="qwrc">
                                <p>$5,000</p>
                            </div>
                            <div className="qwrc">
                                <p>$10,000</p>
                            </div>
                            <div className="qwrc">
                                <p>$20,000</p>
                            </div>
                        </div>
                        <div className="vwexx">
                            <div className="qwrc fcisc">
                            <p>{t("cash_withdrawals_included")}</p>
                                <span>{t("then_$2_excl_vat_per_withdrawal")}</span>
                            </div>
                            <div className="qwrc">
                                <p>0</p>
                            </div>
                            <div className="qwrc">
                                <p>5</p>
                            </div>
                            <div className="qwrc">
                                <p>10</p>
                            </div>
                            <div className="qwrc">
                                <p>{t("unlimited")}</p>
                            </div>
                        </div>
                        <div className="vwexx">
                            <div className="qwrc fcisc">
                                <p>{t("compatible_with_apple_pay_google_pay")}</p>
                            </div>
                            <div className="qwrc">
                                <WhiteTick />
                            </div>
                            <div className="qwrc">
                                <WhiteTick />
                            </div>
                            <div className="qwrc">
                                <WhiteTick />
                            </div>
                            <div className="qwrc">
                                <WhiteTick />
                            </div>
                        </div>
                    </div>

                    <div className="card_rnag_mbl">
                        <CarouselProvider
                            visibleSlides={1}
                            totalSlides={4}
                            step={1}
                            naturalSlideWidth={300}
                            naturalSlideHeight={500}
                            // hasMasterSpinner
                            isPlaying={true}
                            interval={3000}
                            orientation="horizontal"
                            infinite
                            playDirection="forward"
                        >
                            <div className="cscs__bt">
                                <ButtonBack className="mht"><CardLeftArrow /></ButtonBack>
                                <ButtonNext className="ertgb"><CardRightArrow /></ButtonNext>
                            </div>

                            <Slider 
                            className="pjiur"
                            classNameAnimation="scroll"
                            >
                                <Slide index={0} className="yuio">
                                    <div className="qwrc">
                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card4_keteg6.png" alt="ncx-card"/>
                                        <p>{t("select_card")}</p>
                                    </div>
                                    <div className="mjhgf">
                                        <div className="ytrew">
                                            <div className="vyth">
                                            <p>{t("card_payment_limits")}</p>
                                               <span>{t("per_calendar_month")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>$15,000</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                                <p>{t("cash_withdrawal_limits")}</p>
                                               <span>{t("per_calendar_month")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>$2,500</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                            <p>{t("cash_withdrawals_included")}</p>
                                                <span>{t("then_$2_excl_vat_per_withdrawal")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>0</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                                <p>{t("compatible_with_apple_pay_google_pay")}</p>
                                            </div>
                                            <div className="tujhg">
                                                <WhiteTick />
                                            </div>
                                        </div>
                                    </div>
                                </Slide>

                                <Slide index={1} className="yuio">
                                    <div className="qwrc">
                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card5_cmrw2c.png" alt="ncx-card"/>
                                        <p>{t("gold_card")}</p>
                                    </div>
                                    <div className="mjhgf">
                                        <div className="ytrew">
                                            <div className="vyth">
                                            <p>{t("card_payment_limits")}</p>
                                               <span>{t("per_calendar_month")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>$35,000</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                                <p>{t("cash_withdrawal_limits")}</p>
                                               <span>{t("per_calendar_month")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>$5,000</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                            <p>{t("cash_withdrawals_included")}</p>
                                                <span>{t("then_$2_excl_vat_per_withdrawal")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>5</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                                <p>{t("compatible_with_apple_pay_google_pay")}</p>
                                            </div>
                                            <div className="tujhg">
                                                <WhiteTick />
                                            </div>
                                        </div>
                                    </div>
                                </Slide>

                                <Slide index={2} className="yuio">
                                    <div className="qwrc">
                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card3_mibabk.png" alt="ncx-card"/>
                                        <p>{t("plantinum_card")}</p>
                                    </div>
                                    <div className="mjhgf">
                                        <div className="ytrew">
                                            <div className="vyth">
                                            <p>{t("card_payment_limits")}</p>
                                               <span>{t("per_calendar_month")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>$75,000</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                                <p>{t("cash_withdrawal_limits")}</p>
                                               <span>{t("per_calendar_month")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>$10,000</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                            <p>{t("cash_withdrawals_included")}</p>
                                                <span>{t("then_$2_excl_vat_per_withdrawal")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>10</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                                <p>{t("compatible_with_apple_pay_google_pay")}</p>
                                            </div>
                                            <div className="tujhg">
                                                <WhiteTick />
                                            </div>
                                        </div>
                                    </div>
                                </Slide>

                                <Slide index={3} className="yuio">
                                    <div className="qwrc">
                                        <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689414941/ncx-card2_zcdgg0.png" alt="ncx-card"/>
                                        <p>{t("black_card")}</p>
                                    </div>
                                    <div className="mjhgf">
                                        <div className="ytrew">
                                            <div className="vyth">
                                            <p>{t("card_payment_limits")}</p>
                                               <span>{t("per_calendar_month")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>$150,000</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                                <p>{t("cash_withdrawal_limits")}</p>
                                               <span>{t("per_calendar_month")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>$20,000</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                            <p>{t("cash_withdrawals_included")}</p>
                                                <span>{t("then_$2_excl_vat_per_withdrawal")}</span>
                                            </div>
                                            <div className="tujhg">
                                                <p>{t("unlimited")}</p>
                                            </div>
                                        </div>

                                        <div className="ytrew">
                                            <div className="vyth">
                                                <p>{t("compatible_with_apple_pay_google_pay")}</p>
                                            </div>
                                            <div className="tujhg">
                                                <WhiteTick />
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                            </Slider>

                        </CarouselProvider>
                    </div>
                </div>
            </div>

            <div className="accnt__v tw">
                <div className="container">
                    <div className="row xcm">
                        <div className="col mla">
                            <h2>{t("supervise_your_spending")}</h2>
                            <ul>
                                <li>{t("supervise_your_spending_li_one")}</li>
                                <li>{t("supervise_your_spending_li_two")}</li>
                                <li>{t("supervise_your_spending_li_three")}</li>
                            </ul>
                            <div className="rtD cefv b">
                                <Button href="https://ncx.cx/plans">{t("compare_our_plans")}</Button>
                            </div>
                        </div>
                        <div className="col">
                            <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692687037/31-min_y9urtu.png" alt="ncx"/>
                        </div>
                    </div>
                </div>
            </div>

            <CardBox />

            <div className="accnt__v asczz">
                <h2>{t("save_time_for_yourself")}</h2>
                <div id="cardarticles">
                    <div id="NCXCreditCardSlider">
                        <CarouselProvider
                            visibleSlides={3}
                            totalSlides={4}
                            step={1}
                            naturalSlideWidth={300}
                            naturalSlideHeight={500}
                        >
                        <Slider className="slider">
                            <Slide index={0}>
                                <article className='ncxcard01'>
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692857270/06_acmk4i.png" alt="ncx"/>
                                    <h3>{t("digitize_your_receipts")}</h3>
                                    <p>{t("digitize_your_receipts_content")}</p>
                                    <a href="https://ncx.cx/accounting">{t("digitize_your_receipts_btn")}</a>
                                </article>
                            </Slide>
                            <Slide index={1}>
                                <article className='ncxcard01'>
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693228375/07_z5olto.png" alt="ncx"/>
                                    <h3>{t("optimised_invoice_management")}</h3>
                                    <p>{t("optimised_invoice_management_content")}</p>
                                    <a href="https://ncx.cx/accounting">{t("discover_invoice_management")}</a>
                                </article>
                            </Slide>
                            <Slide index={2}>
                                <article className='ncxcard01'>
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692857270/08_dg02dl.png" alt="ncx"/>
                                    <h3>{t("bookkeeping_in_a_flash")}</h3>
                                    <p>{t("bookkeeping_in_a_flash_content")}</p>
                                    <a href="https://ncx.cx/accounting">{t("bookkeeping_in_a_flash_btn")}</a>
                                </article>
                            </Slide>
                            <Slide index={3}>
                                <article className='ncxcard01 vb'>
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692857270/61_qwowq8.png" alt="ncx"/>
                                    <h3>{t("pre_defined_access_rights")}</h3>
                                    <p>{t("pre_defined_access_rights_content")}</p>
                                    <a href="https://ncx.cx/plans">{t("compare_plans")}</a>
                                </article>
                            </Slide>
                        </Slider>
                        <ButtonBack className="buttonBack"><CardLeftArrow /></ButtonBack>
                        <ButtonNext className="buttonNext"><CardRightArrow /></ButtonNext>
                        </CarouselProvider>
                    </div>
                    <div id="NCXCreditCardSlider" className="mobile-ss">
                        <CarouselProvider
                            visibleSlides={1}
                            totalSlides={4}
                            step={1}
                            // naturalSlideWidth={300}
                            // naturalSlideHeight={500}
                            // hasMasterSpinner
                            // isPlaying={true}
                            // interval={3000}
                            orientation="horizontal"
                            infinite
                            playDirectio="forward"
                        >
                        <Slider className="slider">
                            <Slide index={0}>
                                <article className='ncxcard01'>
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692857270/06_acmk4i.png" alt="ncx"/>
                                    <h3>{t("digitize_your_receipts")}</h3>
                                    <p>{t("digitize_your_receipts_content")}</p>
                                    <a href="https://ncx.cx/accounting">{t("digitize_your_receipts_btn")}</a>
                                </article>
                            </Slide>
                            <Slide index={1}>
                                <article className='ncxcard01'>
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693228375/07_z5olto.png" alt="ncx"/>
                                    <h3>{t("optimised_invoice_management")}</h3>
                                    <p>{t("optimised_invoice_management_content")}</p>
                                    <a href="https://ncx.cx/accounting">{t("discover_invoice_management")}</a>
                                </article>
                            </Slide>
                            <Slide index={2}>
                                <article className='ncxcard01'>
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692857270/08_dg02dl.png" alt="ncx"/>
                                    <h3>{t("bookkeeping_in_a_flash")}</h3>
                                    <p>{t("bookkeeping_in_a_flash_content")}</p>
                                    <a href="https://ncx.cx/accounting">{t("bookkeeping_in_a_flash_btn")}</a>
                                </article>
                            </Slide>
                            <Slide index={3}>
                                <article className='ncxcard01 vb'>
                                    <img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692857270/61_qwowq8.png" alt="ncx"/>
                                    <h3>{t("pre_defined_access_rights")}</h3>
                                    <p>{t("pre_defined_access_rights_content")}</p>
                                    <a href="https://ncx.cx/plans">{t("compare_plans")}</a>
                                </article>
                            </Slide>
                        </Slider>
                        <div className="bvb">
                            <ButtonBack className="buttonBack"><CardLeftArrow /></ButtonBack>
                            <ButtonNext className="buttonNext"><CardRightArrow /></ButtonNext>
                        </div>
                        </CarouselProvider>
                    </div>
                </div>
            </div>

            <div className="aerv">
                <div className="container">
                    <div className="row aeewf">
                        <div className="col verhnb">
                        <h1>{t("your_questions_one")} <br/>{t("your_questions_two")} <br/>{t("your_questions_three")}</h1>
                        </div>
                        <div className="col vwev">
                            <div className="accordian">
                                <details open>
                                    <summary>{t("business_card_question_one")}
                                    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                                    </svg>
                                    </summary>
                                    <div className='contt'>
                                        <p>{t("business_card_answer_one")}</p>
                                    </div>
                                </details>
                                <details>
                                    <summary>{t("business_card_question_two")}
                                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                                        </svg>
                                    </summary>
                                    <div className='contt'>
                                        <p>{t("business_card_answer_two")}</p>
                                    </div>
                                </details>
                                <details>
                                    <summary>{t("business_card_question_three")}
                                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                                        </svg>
                                    </summary>
                                    <div className='contt'>
                                        <p>{t("business_card_answer_three")}</p>
                                    </div>
                                </details>
                                <details>
                                    <summary>{t("business_card_question_four")}
                                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                                        </svg>
                                    </summary>
                                    <div className='contt'>
                                        <p>{t("business_card_answer_four")}</p>
                                        <ul className="czP">
                                            <li>{t("business_card_answer_four_li_one")}</li>
                                            <li>{t("business_card_answer_four_li_two")}</li>
                                            <li>{t("business_card_answer_four_li_three")}</li>
                                            <li>{t("business_card_answer_four_li_four")}</li>
                                        </ul>
                                        <p className="bnR"><strong>{t("business_card_answer_four_strong")}</strong></p>
                                        <p>{t("business_card_answer_four_content")}</p>
                                    </div>
                                </details>
                                <details>
                                    <summary>{t("business_card_question_five")}
                                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                                        </svg>
                                    </summary>
                                    <div className='contt'>
                                        <p>{t("business_card_answer_five")}</p>
                                    </div>
                                </details>
                                <details>
                                    <summary>{t("business_card_question_six")}
                                        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.311311 0.418754C0.622234 0.0619783 1.12633 0.0619783 1.43725 0.418754L8.03968 7.99547L14.6421 0.418754C14.953 0.0619783 15.4571 0.0619783 15.768 0.418754C16.0789 0.77553 16.0789 1.35405 15.768 1.71082L8.60264 9.93358C8.29174 10.2904 7.78762 10.2904 7.47672 9.93358L0.311311 1.71082C0.000396326 1.35405 0.000396326 0.77553 0.311311 0.418754Z" fill="#FAFAFA"/>
                                        </svg>
                                    </summary>
                                    <div className='contt'>
                                        <p>{t("business_card_answer_six")}</p>
                                    </div>
                                </details>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AccountingDesktop;