import React from "react";
import './style.css';
import Button from "@mui/material/Button";

function PostListingSection()
{
    return(
        <div className="pst__lstng">
            <div className="container">
                <div className="nsghts row">
                    <div className="col lft_br">
                        <div className="category_se">
                            <h1 className="tile_hed">Latest Insights</h1>
                            <div className="art_grf row">
                            <a href="/post-crypto/719" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696687850/ncx-blog-1336_yyfttm.png"/></div>
                                    <p className="dat">October 7, 2023</p>
                                    <h1>Bitcoin decouples from long-term bonds – Why?</h1>
                                    <p>Bitcoin’s correlation with long-term treasuries has fallen to a 12-month low as general market perception begins to shift.</p>
                                </a>
                            <a href="/post-markets/704" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696753905/ncx-blog-1339_kvt5yo.webp" alt="art_pic"/></div>
                                    <p className="dat">October 7, 2023 - 4:00 PM</p>
                                    <h1>Meta, CME Lead Top Stocks Breaking Out With This Bullish Trait</h1>
                                    <p>CME Group (CME) leads this week's top stocks to watch, while Meta Platforms (META) and Arista Networks (ANET) also make the cut. So do Paccar (PCAR) and Arch Capital (ACGL).</p>
                                </a>
                            </div>
                            <Button href="/post-crypto" className="red__mor">View All</Button>
                        </div>

                        <div className="category_se">
                            <h1 className="tile_hed">Crypto</h1>
                            <div className="art_grf row"> 
                            <a href="/post-crypto/718" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696686791/ncx-blog-1335_pfhlxp.jpg" alt="art_pic"/></div>
                                    <p className="dat"> October 7, 2023</p>
                                    <h1>Ethereum fails to react as exchange outflow hits highest since August</h1>
                                    <p>Ethereum’s accumulation remains slow amongst daily traders, despite the recent surge in the coin’s exchange outflow.</p>
                                </a>
                            <a href="/post-crypto/717" className="col">
                                <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696686404/ncx-blog-1334_fldfo4.png" alt="art_pic"/></div>
                                <p className="dat"> October 6, 2023</p>
                                <h1>Bitcoin briefly touches $28,000 again, but can it rally past?</h1>
                                <p>Bitcoin’s short-term holders have held on to their supply, despite the coin’s brief stint at $28,000. Should this continue, BTC’s price might be able to surge past this new resistance level.</p>
                                </a>
                            </div>
                            <Button href="/post-crypto" className="red__mor">View All</Button>
                        </div>

                        <div className="category_se">
                            <h1 className="tile_hed">Markets</h1>
                            <div className="art_grf row">
                            <a href="/post-markets/703" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696753077/ncx-blog-1338_tn1i7u.webp" alt="art_pic"/></div>
                                    <p className="dat">October 7, 2023 - 12:08 AM</p>
                                    <h1>SoftBank’s Arm Faces Wall Street Scrutiny After Biggest US IPO Since 2021</h1>
                                    <p>(Bloomberg) -- Major Wall Street bank analysts will begin weighing in on one of the most-watched initial public offerings in the US so far this year.</p>
                                </a>
                            <a href="/post-markets/702" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696689670/ncx-blog-1337_jk3vcg.webp" alt="art_pic"/></div>
                                    <p className="dat">October 6, 2023 - 9:28 AM</p>
                                    <h1>The collapse in Treasury bonds now ranks among the worst market crashes in history</h1>
                                    <p>The bond-market sell-off that's sending yields soaring is starting to eclipse some of the most extreme market meltdowns of past eras.</p>
                                </a>
                            </div>
                            <Button href="/post-markets" className="red__mor">View All</Button>
                        </div>
                    </div>

                    <div className="col rght_br"> 
                        <div className="bardd">
                            <h1 className="ma__rere">Trending Articles</h1>
                            <a href="/post-indices/624" className="col">
                                <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696242687/ncx-blog-1333_wq9mou.webp"/></div>
                                <p className="dat"> October 2, 2023 - 6:32 AM</p>
                                <h1>Dow Jones Futures Rise With Government Shutdown Averted; Tesla Deliveries Due</h1>
                                <p>Dow Jones futures rose Sunday night after Congress averted a government shutdown Saturday. Tesla deliveries are due before Monday's open with several China EV rivals releasing results Sunday.</p>
                            </a>
                            <a href="/post-markets/701" className="col">
                                <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696238546/ncx-blog-1330_bfos3h.webp"/></div>
                                <p className="dat">October 1, 2023 - 4:00 PM</p>
                                <h1>The Bull Case for Emerging Markets in 2023 Is Finally Shattering</h1>
                                <p>(Bloomberg) -- The third quarter was a story of dashed hopes in emerging markets, with the unraveling of some of the most profitable trades in the asset class.</p>
                            </a>
                            <a href="/post-markets/700" className="col">
                                <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696238188/ncx-blog-1329_bc64eu.webp" alt="art_pic"/></div>
                                <p className="dat"> October 1, 2023 - 11:00 AM</p>
                                <h1>London on Cusp of Becoming Biggest Stock Market in Europe, Again</h1>
                                <p>(Bloomberg) -- Britain’s stock market is getting back on its feet.</p>
                            </a>
                            <a href="/post-markets/699" className="col">
                                <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696237240/ncx-blog-1328_au8uo6.webp"/></div>
                                <p className="dat">September 30, 2023 - 11:00 PM</p>
                                <h1>US Consumer Spending Is Signaling Pain Ahead: Credit Weekly</h1>
                                <p>(Bloomberg) -- The US consumer is starting to buckle as rising gas prices crimp spending and the delinquency rate on credit cards reaches the highest level in more than a decade. And that’s before student loan payments restart in October.</p>
                            </a>
                            {/* <a href="/post-crypto/441" className="col">
                                <div className="fdd-d"><img src="https://ncx.cx/images/ncx-blog-456.jpg" alt="art_pic"/></div>
                                <p className="dat">Apr 24, 2023 - 9:43 PM</p>
                                <h1>Bitcoin could soar 268% to $100,000 as crypto winter is finally over, Standard Chartered says</h1>
                                <p>The crypto winter has melted away, and bitcoin is set to flourish, a Standard Chartered note forecasted on Monday.</p>
                            </a> */}
                        </div>
                    </div>

                    <div className="full__blog">
                        <div className="category_se">
                            <h1 className="tile_hed">Forex</h1>
                            <div className="art_grf row">
                            <a href="/post-forex/671" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696233960/ncx-blog-1326_anwbgy.webp" alt="art_pic"/></div>
                                    <p className="dat"> September 29, 2023 - 7:59 PM</p>
                                    <h1>GBP/USD Weekly Forecast – British Pound Bounces From Extreme Lows</h1>
                                    <p>The British pound fell significantly during the course of the week but found buyers on both Thursday and Friday to turn things around. Whether or not we can continue to bounce from here remains a completely different question, but a short-term rally would make a certain amount of sense. </p>
                                </a>
                            <a href="/post-forex/670" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696233347/ncx-blog-1325_q6rqdm.webp" alt="art_pic"/></div>
                                    <p className="dat"> September 29, 2023 - 7:53 PM</p>
                                    <h1>USD/JPY Forecast – US Dollar Recovers After Initial Selloff</h1>
                                    <p>The US dollar has initially plunged during the trading session on Friday, but turned around against the Japanese yen as we continue to see a lot of noisy behavior. All things being equal, this is a market that continues to move based on interest rate differential, which of course is quite wide between these 2 currencies.</p>
                                </a>
                            <a href="/post-forex/669" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696232825/ncx-blog-1324_coda6i.webp" alt="art_pic"/></div>
                                    <p className="dat"> September 29, 2023 - 6:24 PM</p>
                                    <h1>EUR/USD Weekly Forecast – Euro Bounces From Big Figure</h1>
                                    <p>The euro fell rather hard during the trading week, but it also has seen quite a bit of a pushback at the 1.05 level. At this point, the market looks as if it is trying to form some type of supportive candlestick, but at the same time,</p>
                                </a>
                            </div>
                            <Button href="/post-forex" className="red__mor">View All</Button>
                        </div>
                    </div> 

                    <div className="full__blog">
                        <div className="category_se">
                            <h1 className="tile_hed">Commodities</h1>
                            <div className="art_grf row">
                            <a href="/post-commodities/655" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696755024/ncx-blog-1341_rj0ett.webp" alt="art_pic"/></div>
                                    <p className="dat"> October 7, 2023 - 12:05 AM</p>
                                    <h1>Gold Price Today Rebounds, Yet Still Might Go Negative In 2023; These Factors May Weigh Further</h1>
                                    <p>The gold price today is perhaps not doing what investors going long in the precious metal are hoping: to act as a hedge against both inflation and the potential deflation of other financial assets. So, what is the best strategy in the short term?</p>
                                </a>   
                            <a href="/post-commodities/654" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696754336/ncx-blog-1340_gzlb3t.webp" alt="art_pic"/></div>
                                    <p className="dat"> October 6, 2023 - 1:36 AM</p>
                                    <h1>Oil prices drop further as the global demand outlook turns murky</h1>
                                    <p>Oil prices declined further on Thursday, following the prior day's roughly 6% plunge for both Brent and West Texas Intermediate crude.</p>
                                </a>   
                            <a href="/post-commodities/653" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696231826/ncx-blog-1323_qms30r.webp" alt="art_pic"/></div>
                                    <p className="dat">September 30, 2023 - 5:08 PM</p>
                                    <h1>Exxon Mobil, AI Play Lead Five Stocks Defying S&P 500 Blues</h1>
                                    <p>Exxon Mobil (XOM) and chip-design software stalwart Synopsys (SNPS) lead this weekend's watch list of five stocks near buy points that just weathered the worst month for the S&P 500 this year. Along with XOM stock and SNPS,</p>
                                </a>                   
                            </div>
                            <Button href="/post-commodities" className="red__mor">View All</Button>
                        </div>
                    </div>

                    <div className="full__blog">
                        <div className="category_se">
                            <h1 className="tile_hed">Indices</h1>
                            <div className="art_grf row">
                            <a href="/post-indices/624" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696242687/ncx-blog-1333_wq9mou.webp" alt="art_pic"/></div>
                                    <p className="dat"> October 2, 2023 - 6:32 AM</p>
                                    <h1>Dow Jones Futures Rise With Government Shutdown Averted; Tesla Deliveries Due</h1>
                                    <p>Dow Jones futures rose Sunday night after Congress averted a government shutdown Saturday. Tesla deliveries are due before Monday's open with several China EV rivals releasing results Sunday.</p>
                                </a>
                            <a href="/post-indices/623" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696241845/ncx-blog-1332_nu9yj5.webp" alt="art_pic"/></div>
                                    <p className="dat">October 2, 2023 - 6:32 AM</p>
                                    <h1>Dow Jones Futures Rise With Government Shutdown Averted; Tesla Deliveries Due</h1>
                                    <p>Top NASDAQ ETFS for Q4 2023 include QQQ, QTEC, and PNQI</p>
                                </a>  
                            <a href="/post-indices/622" className="col">
                                    <div className="fdd-d"><img src="https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696241001/ncx-blog-1331_yywh7h.webp" alt="art_pic"/></div>
                                    <p className="dat"> September 29, 2023 - 6:33 PM</p>
                                    <h1>LIVE: FTSE and US markets rise as inflation data raises green flags</h1>
                                    <p>The FTSE 100 (^FTSE) European, and US markets rose on Friday following a fresh rash of data that showed inflation across the eurozone and US cooling and the UK's economy had grown in Q2.</p>
                                </a>
                            </div> 
                            <Button href="/post-indices" className="red__mor">View All</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PostListingSection;