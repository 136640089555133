const data = [
  {
    id: 719,
    title: 'Bitcoin decouples from long-term bonds – Why?',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'decouples', 'bonds'],
    description: <div className="blog-desc">
      <p>Bitcoin’s correlation with long-term treasuries has fallen to a 12-month low as general market perception begins to shift.</p>
    </div>,
    authorName: 'Abiodun Oladokun',
    authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'October 7, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696687850/ncx-blog-1336_yyfttm.png',
    content: <div className="blog-content">
  	  <h3>In this article:</h3>
	    <p>Bitcoin’s correlation with long-term treasuries has fallen to a 12-month low as general market perception begins to shift.</p>
      <ul>
        <li>The correlation between Bitcoin and long-term bonds has fallen to its lowest in the last year.</li>
        <li>This has been due to the gradual change in the perception of the leading coin.</li>
      </ul>
    	<p>In the last month, Bitcoin’s [BTC] correlation with long-term bonds has dropped to its lowest in the last year. This signals a significant shift in how the leading crypto asset is viewed and valued, IntotheBlock analyst Lucas Outumuro noted in a new report. </p>	
    	<p>In 2022, rising interest rates led to a decline in both long-term bonds and BTC. The value of bonds fell due to a decline in yields, while BTC’s value plummeted to multi-year lows as investors generally avoided risky digital assets.</p>
      <p>As rate hikes slowed this year, the values of both assets climbed.</p>
      <p>However, long-term treasury prices have lagged in the last month while BTC’s price has grown, resulting in a low correlation of -0.74 between them.</p>
      <p>According to data from IntoTheBlock, in the last 30 days, BTC’s price has gone up by 7%, while the value of long-term treasury bond ETF has fallen by 10%.</p>
      <h3>New perception, who dis?</h3>	
    	<p>According to Outumuro, the decline in correlation between the two assets has been due to the evolving perception of BTC. The narrative around the coin has shifted from being viewed as a medium of exchange to a store of value.</p>
      <p>In support of his position, Outumuro assessed BTC’s Network Value to Transactions (NVT) Ratio – a measure of the coin’s market capitalization to its on-chain transaction volume – and found that it hit an all-time high in September.</p>
      <p>The analyst opined that the surge in the coin’s NVT ratio signaled that BTC’s value is no longer solely based on its transactional utility.</p>
      <p>Instead:</p>
      <p><strong>“In 2023, we have seen demand for Bitcoin pick up as the traditional finance system’s cracks become exposed. In March, as Silicon Valley Bank collapsed and the Fed intervened with the BTFP program, Bitcoin’s price rallied by over 20%.”</strong></p>
      <p>According to Outumuro:</p>
      <p><strong>“It may be too soon to begin calling for the bull market, but it is clear that the broader market has changed its tone in regards to Bitcoin.”</strong></p>
      <h3>BTC on a daily chart</h3>
      <p>At press time, BTC traded at $27,924, recording a 2% price uptick in the last 24 hours, according to data from CoinMarketCap.</p>
      <p>While price continues to linger in a narrow range, accumulation outpaces distribution on a daily chart. At press time, the positive directional indicator (green) stood at 27.83, positioned higher than the negative directional indicator (red) at 9.17, suggesting that buyers’ strength exceeded that of the sellers’</p>
    </div>
  },
  {
    id: 718,
    title: 'Ethereum fails to react as exchange outflow hits highest since August',
    category: 'Crypto',
    subCategory: ['Ethereum', 'outflow', 'exchange'],
    description: <div className="blog-desc">
      <p>Ethereum’s accumulation remains slow amongst daily traders, despite the recent surge in the coin’s exchange outflow.</p>
    </div>,
    authorName: 'Abiodun Oladokun',
    authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'October 7, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696686791/ncx-blog-1335_pfhlxp.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Ethereum’s accumulation remains slow amongst daily traders, despite the recent surge in the coin’s exchange outflow.</p>
      <ul>
        <li>Ethereum’s daily exchange outflow reached its highest level since August on 4 October.</li>
        <li>ETH’s accumulation has continued to dawdle. </li>
      </ul>
    	<p>On 4 October, over 110,000 Ethereum [ETH] coins, worth around $177.65 million, were withdrawn from known crypto exchange wallets, marking the highest daily exchange outflow since August.</p>	
    	<p>When an asset’s exchange outflow surges in this manner, it suggests that coin holders are moving their assets off exchanges and into cold storage or other non-custodial wallets, which could signify bullish sentiment.</p>
      <h3>ETH fails to react</h3>	
    	<p>A surge in exchange outflows is typically followed by a rally in an asset’s price, which means a reduction in sell-offs. However, this has yet to manifest in ETH, as the price remains trapped within a narrow range.</p>
      <p>At press time, the leading altcoin exchanged hands at $1,620. Following Bitcoin’s [BTC] brief surge above $28,000 during the intraday trading session on 5 October, ETH’s price touched $1648, after which it shed all its gains, data from CoinMarketCap showed.</p>
      <p>On the daily chart, coin accumulation amongst spot traders has lost momentum. As of this writing, the coin’s Relative Strength Index (RSI) was positioned in a downtrend beneath its 50-neutral line.</p>
      <p>Likewise, the coin’s Chaikin Money Flow (CMF) was below the zero line at -0.11. A negative CMF value indicates that more money is flowing out of an asset than into it.</p>
      <p>A negative CMF value coupled with price consolidation or decline is taken as a bearish signal, suggesting that investors are selling the asset and reducing their exposure.</p>
      <p>An assessment of the coin’s Directional Movement Index (DMI) revealed that ETH’s sellers regained market control after the coin’s price fell from its $1648 peak on 5 October. At press time, the negative directional indicator (red) at 20.63 was positioned above the positive directional indicator (green) at 20.23.</p>
      <p>This crossover showed that the current price trend in the ETH market was a downtrend and that downward price movements outpaced upward price movements.</p>
      <h3>Futures traders tread a different path</h3>
      <p>Despite the recent price action and the significant bearish sentiments in the ETH market, futures traders have remained steadfast.</p>
      <p>The month so far has seen an uptick in ETH’s Open Interest. According to data from Coinglass, the coin’s Open Interest has increased by 3% since 3 October.</p>
      <p>When ETH open interest increases, it means that the total number of ETH futures contracts that have not been settled has increased.</p>
      <p>It is a bullish signal as it suggests that more investors are opening new positions in ETH. And that there is increasing demand for the asset.</p>
      <p>It remains notable that the coin’s funding rates across exchanges have remained positive despite ETH’s headwinds since April.</p>
    </div>
  },
  {
    id: 717,
    title: 'Bitcoin briefly touches $28,000 again, but can it rally past?',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'crypto', 'rise'],
    description: <div className="blog-desc">
      <p>Bitcoin’s short-term holders have held on to their supply, despite the coin’s brief stint at $28,000. Should this continue, BTC’s price might be able to surge past this new resistance level.</p>
    </div>,
    authorName: 'Abiodun Oladokun',
    authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'October 6, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696686404/ncx-blog-1334_fldfo4.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin’s short-term holders have held on to their supply, despite the coin’s brief stint at $28,000. Should this continue, BTC’s price might be able to surge past this new resistance level.</p>
    	<ul>
        <li>Although BTC’s price reclaimed the $28,000 price level recently, short-term holders have refused to sell.</li>
        <li>Accumulation remains steady despite the coin’s return below $27,500. </li>
      </ul>
      <p>Bitcoin’s [BTC] price briefly reclaimed the $28,000 price point during the intraday trading session on 5 October, but it remains to be seen whether or not the coin can rally past this new resistance level.</p>	
    	<p>At press time, the leading coin exchanged hands at $27,491, according to data from CoinMarketCap.</p>	
      <h3>Short-term holders are the ones to watch</h3>
      <p>BTC short-term holders (STH) are investor cohorts who have held their coins for less than 155 days. They are typically more price-sensitive than long-term holders (LTHs), as they have their coins easily accessible and ready to distribute once BTC’s price falls below their cost basis.</p>
      <p>In a new post, pseudonymous CryptoQuant analyst Tarekonchain assessed BTC’s Realized Price – UTXO Age Bands. The metric proves useful in this regard as it depicts a pattern of coin distribution amongst STHs whenever BTC’s price climbs to the level where they purchased their coins.</p>
      <p>According to data from CryptoQuant, the Realized Price for BTC’s STH at press time rested above the coin’s current price, suggesting that these investors were in profit.</p>
      <p>However, as pointed out by Tarekonchain:</p>
      <p><strong>“There has been no significant influx of Bitcoin from these groups into the market till now. This lack of activity suggests that these investors are holding their positions, refraining from mass sell-offs.”</strong></p>
      <p>The analyst added further that if these investors “maintain their Bitcoin holdings without transferring to exchanges, there’s a high chance of prices rising. Conversely, if transfers increase, a price drop might be imminent.”</p>
      <h3>BTC on the daily chart</h3>
      <p>Following BTC’s price descent below $27,500, its Chaikin Money Flow (CMF), which attempted a cross over above the zero line during the trading session on 5 October, resumed its downtrend.</p>
      <p>At press time, the indicator returned a negative value of -0.05, suggesting some weakness in the BTC market. This showed considerable liquidity has exited the market in the last 24 hours.</p>
      <p>Despite this, coin accumulation remained steady. The Relative Strength Index (RSI) and Money Flow Index (MFI), with respective values of 57.13 and 67.65, depicted this.</p>
      <p>Moreover, as gleaned from the coin’s Directional Movement Index (DMI), BTC’s buyers remained in control of the coin’s spot markets.</p>
      <p>At press time, the positive directional indicator (green) at 29.12 rested above the negative directional indicator (red) at 10.22. This signaled that the buyers’ strength exceeded the sellers.</p>
    	<p></p>
    </div>
  },
  {
    id: 716,
    title: 'Valkyrie Backs Off Ether Futures Purchases Until SEC ETF Approval Officially Effective',
    category: 'Crypto',
    subCategory: ['Ether', 'ETF', 'Valkyrie'],
    description: <div className="blog-desc">
      <p>Valkyrie Friday morning said it will not purchase ether (ETH) futures until the U.S. Securities and Exchange Commission’s (SEC) approval of adding that vehicle to its Bitcoin Strategy ETF (BTF) is effective.</p>
    </div>,
    authorName: 'Lyllah Ledesma',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 29, 2023 - 7:24 PM', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696228845/ncx-blog-1320_fcsf1y.webp',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Valkyrie Friday morning said it will not purchase ether (ETH) futures until the U.S. Securities and Exchange Commission’s (SEC) approval of adding that vehicle to its Bitcoin Strategy ETF (BTF) is effective.</p>
    	<p>In an SEC Form 497 filing, the asset manager also said it would unwind any ether futures purchases it had already made.</p>	
    	<p>The quick backtrack comes after the firm yesterday told CoinDesk (and others) it had begun adding ether futures exposure to the BTF after gaining SEC approval.</p>	
    	<p>Bloomberg ETF analyst Eric Balchunas tweeted that the unwinding of ether futures purchases from Valkyrie is an example of the SEC not wanting to be a “kingmaker via their policies and will do whatever they can to prevent someone from launching on their own like BITO [ProShares Bitcoin Strategy ETF] in 2021.”</p>
      <p>Notwithstanding Valkyrie’s actions, it does appear that a number of ether futures ETFs will begin trading in the U.S. on Monday, with ProShares, Bitwise and VanEck among those confirming as much in SEC filings on Friday morning.</p>
    </div>
  },
  {
    id: 715,
    title: 'Opposing Centralization in Ethereum Staking',
    category: 'Crypto',
    subCategory: ['crypto', 'Ethereum', 'Centralization'],
    description: <div className="blog-desc">
      <p>The market has agreed that staking is a really good deal — more so than core developers ever anticipated. There’s not just demand to build on Ethereum, but a wildly unanticipated demand to participate in Ethereum’s consensus, aka staking.</p>
    </div>,
    authorName: 'Justin "Nix" Kalland',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 29, 2023 - 6:23 PM', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696228357/ncx-blog-1319_a219zt.webp',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The market has agreed that staking is a really good deal — more so than core developers ever anticipated. There’s not just demand to build on Ethereum, but a wildly unanticipated demand to participate in Ethereum’s consensus, aka staking.</p>
    	<p>Stakers put down collateral in the form of ETH to validate the network and receive rewards in the form of ETH. Yield is dependent on the size of the validator set, which, in theory, creates a market equilibrium — if yield is too low, people unstake. If the validator set is too small, yield increases and new stakers are incentivized to enter the set.</p>	
    	<p>In the purest form of staking, affectionately called “home staking,”you run a small, unobtrusive, energy-efficient PC with Ethereum software and you control your assets. But there are two cohorts who want to stake but don’t want to “home stake.” The first set is people who don’t have the motivation, time or tech savvy to learn how to set up the necessary PC. The second set is people who don’t want to lock up their ETH for a ~3%-6% yield – they’re interested in more risk and more reward.</p>
      <p>These two cohorts have given rise to a part of the ecosystem that is both a boon and a bane. They allow their stake to be managed by a third party who gives them receipt tokens, which means that this third party doesn’t have anything "at stake" but still retains some influence over the network. It provides a service and takes a cut of the rewards.</p>
      <p>On the one hand, third parties democratize access to staking. The barrier to stake is quite high in terms of initial investment and tech knowledge, and "staking-as-a-service" providers make on-boarding easy. They are are a net positive for the chain.</p>
      <p>On the other hand, third parties, or in other words middlemen, the types of market participants that crypto is meant to eliminate, come with problems.</p>
      <p>Ethereum’s Shanghai hardfork in April enabled people who had been staking for years to finally withdraw. It was a massive derisking event for those interested in staking. Since then, the queue to get into staking has been several weeks to months long. The metaphorical bouncer has not had a moment’s rest.</p>
      <p>Everybody is piling into staking and few are leaving, putting network stability and centralization risks at the forefront of the minds working in core development.</p>
      <p>The boom in staking coupled with centralization issues of third-party providers has created somewhat of a villain narrative in the staking ecosystem. And one third-party staking provider has eclipsed all others: Lido. Lido is a semi-decentralized, non-custodial staking protocol that anybody can use to stake their ETH that is governed by a decentralized autonomous organization (DAO).</p>
      <p>There are two sides to the debate — Lido is a successful business with attractive UX [user experience] and solid business development. It’s acting as an economically rational actor by seeking to control a large portion of all staked ETH and is stress-testing the limits of Ethereum’s protocol design. Plus, it’s not just one entity, it’s 38 operators.</p>
      <p>The other side would say that Lido is detrimental to the decentralization of the chain, because it refuses to artificially cap its staking dominance and that its rapid monopolistic growth is uncooperative with researchers while they figure out a "coded in" way to deal with the design flaw that has allowed a single entity to grow to manage one-third of all staked ETH. Further, those 38 operators are still managed by one entity: the Lido DAO, where decisions are usually made by just two wallets.</p>
      <p>Lido is exploiting a flaw that threatens the core value proposition of Ethereum: its decentralization and inability to bend to any special interests.</p>
      <p>In any case, the move here is to give Ethereum researchers more time to discuss, brainstorm and adapt the system to account for these economically rational actors. Core development and research are accomplished in uncharted territory with hundreds of the best minds in tech who now grapple with this centralization risk and how to mitigate or even actively account for it.</p>
      <p>There are already ideas being discussed and the community is working hard to inform individuals and institutions of risks that might not be apparent when they choose their staking provider. I, for one, expect that the caliber of solution we’ll see will match that which we’ve come to know from Ethereum core development.</p>	
    </div>
  },
  {
    id: 714,
    title: 'Ledn Launches Support For ETH Staking Yields',
    category: 'Crypto',
    subCategory: ['Ledn', 'ETH', 'Yields'],
    description: <div className="blog-desc">
      <p>The Cayman-based crypto lender, Ledn, will launch accounts offering exposure to Ethereum staking from Oct. 12.</p>
    </div>,
    authorName: 'Samuel Haig',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 28, 2023 - 4:30 PM', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1696227667/ncx-blog-1318_beluvy.webp',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The Cayman-based crypto lender, Ledn, will launch accounts offering exposure to Ethereum staking from Oct. 12.</p>
    	<p>Customers can stake via Ledn by transferring ETH to its Growth Account product, with the platform offering annual percentage yields (APYs) of up to 2%. Growth Accounts are not subject to the delays associated with manual staking or unstaking Ether.</p>	
    	<p>“Users have been continuously asking us to add Ether,” said Ledn co-founder, Mauricio Di Bartolomeo. “Looking forward, we’re working towards rolling out ETH support across the entire Ledn suite of products in the coming months.”</p>	
    	<p>Ledn will also launch Growth Accounts offering APYs of up to 8.5% on Tether, the leading stablecoin by market cap. However, the products will not be available to residents of Canada or the United States at launch. Ledn already offers Growth Accounts for BTC and USDC.</p>
      <p>Growth Accounts are subject to a “ring-fencing” mechanism that “shields clients from the risks associated with other company offerings.” Ledn hopes the system will overcome the widespread skepticism of centralized platforms held by many web3 users following failures of large CeFi amid last year’s market downturn.</p>
      <p>“[Ring-fencing] ensures that clients are only exposed to the counterparties that generate their yield, and their assets will not be affected even in the unlikely event of Ledn’s bankruptcy,” the company said.</p>
      <p>While centralized players like Ledn are seeking to streamline the onboarding process for users seeking exposure to staking yields, Ethereum developers recently agreed to introduce measures to slow the rate new stakers can come online as part of Ethereum’s next upgrade.</p>
      <p>More than 21.5% of circulating Ether has been locked up for staking in 12 months following Ethereum’s transition to Proof of Stake, according to Staking Rewards. A recent paper authored by prominent researchers Tim Beiko and Dapplion estimated that 50% of Ether’s supply could be staked within 12 months.</p>
      <p>“We want to slow it down a bit to buy us some time,” said Matt Nelson, product manager at Ethereum software developer, Consensys.</p>
      <p>Dapplion tweeted that “the safety of Ethereum” could be at stake if too much of ETH’s supply is locked up for staking. For example, the excessive dominance of Ethereum’s staking layer could undermine Ethereum’s on-chain liquidity, or impact its availability to pay for transaction fees.</p>
      <p>CORRECTED ON 9/28 @ 10AM EST to reflect Ledn's hq is Cayman Islands.</p>
    </div>
  },
  {
    id: 713,
    title: 'Polygon zkEVM completes Dragonfruit update, but concerns persist ',
    category: 'Crypto',
    subCategory: ['Polygon', 'zKEVM', 'Dragonfruit'],
    description: <div className="blog-desc">
      <p>With the Dragonfruit upgrade, zkEVM has become the only rollup as of now that supports Ethereum’s PUSH0. However, the rollup’s network activity continues to decline.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 22, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1695384429/ncx-blog-1304_s7eczm.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>With the Dragonfruit upgrade, zkEVM has become the only rollup as of now that supports Ethereum’s PUSH0. However, the rollup’s network activity continues to decline.</p>
    	<ul>
        <li>zkEVM’s daily active addresses have declined.</li>
        <li>Uniswap submitted a proposal seeking a friendly hardfork on Polygon zkEVM.</li>
      </ul>
      <p>As reported earlier, Polygon [MATIC] zkEVM was expecting a new network upgrade that would add multiple new features. The latest tweet from Polygon revealed that the rollup successfully completed its Dragonfruit upgrade.</p>	
    	<p>With the update, zkEVM has become the only rollup that supports Ethereum’s PUSH0.</p>
      <h3>Dragonfruit update is successful </h3>	
      <p>Polygon announced that it would push out the Dragonfruit upgrade on 20 September, which has now been successfully released.</p>
    	<p>With the update, Polygon zkEVM Mainnet Beta became up to date with the latest version of Solidity, maintaining the rollup’s equivalence with the EVM by incorporating support for the latest EVM opcode PUSH0.</p>
      <p>For the uninitiated, PUSH0 is the latest EVM opcode included in the Shanghai hard fork. The update could have resulted in a hike in zkEVM’s network activity, but that was not the case.</p>
      <p>Unfortunately, the Dragonfruit update also did not have a positive impact on the rollup as zkEVM’s key metrics remained low. For instance, in the recent past, zkEVM’s daily active addresses took a downturn. A similar trend was also noted in terms of its daily transactions.</p>
      <p>Thanks to this, zkEVM’s daily gas use also declined. Polygonscan’s data revealed that after spiking in late August, the rollup’s daily gas usage has plummeted sharply, suggesting less activity on the network.</p>
      <p>The rollup’s captured value was also declining, which was evident from the dip in its TVL and fees. Nonetheless, the overall ecosystem seemed to have grown over the months as the total number of unique depositors continued to rise.</p>
      <h3>A new hardfork might be around the corner</h3>
      <p>Amidst this, Uniswap [UNI] submitted a proposal for a friendly hardfork. For starters, a friendly fork refers to a situation in which a protocol is duplicated, creating a new version that maintains a positive and collaborative relationship with the original project.</p>
      <p>The newly submitted proposal aims to focus on the integration of Zero Protocol as a friendly fork of Uniswap on the Polygon zkEVM chain.</p>
      <p>As per the official proposal, Uniswap can harness the advantages of Zero Protocol while ensuring that both projects benefit mutually and contribute to the evolving DeFi landscape.</p>
      <p>The proposal suggested a structured proposal timeline spanning three weeks to ensure transparency, community engagement, and thoughtful decision-making.</p>
    </div>
  },
  {
    id: 712,
    title: 'Bitcoin: Traders take short positions as dip below $27,000 looms',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Traders', '$27000'],
    description: <div className="blog-desc">
      <p>Bitcoin drops below the $27,000 mark with traders’ bearish onslaught, but funding rates stay bullish. Meanwhile, RSI hints at a pivotal turn.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 22, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1695384156/ncx-blog-1303_opkwhi.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin drops below the $27,000 mark with traders’ bearish onslaught, but funding rates stay bullish. Meanwhile, RSI hints at a pivotal turn.</p>
    	<ul>
        <li>There has been an aggressive shorting of BTC recently.</li>
        <li>BTC has lost over 1% in value and dropped below the $27,000 price level.</li>
      </ul>
      <p>Despite Bitcoin [BTC] reaching a price of $27,000, certain traders opted for a bearish stance. How well have they performed thus far, and what is the prevailing funding rate?</p>
      <h3>Bitcoin sees aggressive shorts</h3>	
    	<p>As per a report from Santiment, Bitcoin experienced significant bearish activity among traders on specific exchanges. The data chart revealed that traders were engaged in shorting of BTC on Deribit and Binance despite the price surging to the $27,000 range.</p>
      <p>Also, Santiment noted that this aggressive shorting had started a week prior, coinciding with a 4% increase in BTC’s value during that period. It was anticipated that this shorting trend would persist, potentially leading to further liquidations, which could, in turn, drive up the price of Bitcoin.</p>	
    	<h3>Bitcoin’s funding rate and liquidation in the past 24 hours</h3>
      <p>Despite the intensified shorting activity on Deribit and Binance, the overall funding rate for Bitcoin remained in positive territory. According to data from Coinglass, the current funding rate for Bitcoin was approximately 0.0036%.</p>
      <p>While there has been a slight dip in the funding rate, the persistent prevalence of a positive rate indicates that a greater number of traders were still placing their bets on a price increase, even in the face of aggressive short positions.</p>
      <p>However, long positions have been experiencing a higher rate of liquidations lately, primarily due to the modest decline in BTC. The liquidation chart showed that on 20 September, long liquidations were over $16 million, whereas short liquidations were over $6.7 million.</p>
      <p>As of this writing, long liquidations had dropped to slightly over $7 million, while short liquidations were less than $200,000.</p>
      <p>Furthermore, an examination of the funding rate on Deribit and Binance revealed that they were positive as of this writing.</p>
      <h3>BTC close to crossing below the neutral line</h3>
      <p>The downward trajectory in BTC’s price has finally breached the $27,000 price level, as indicated by its daily timeframe chart.</p>
      <p>As of this writing, BTC was trading with a loss exceeding 1%, settling around $26,700. This marked the second consecutive day of losses, with a decrease of less than 1% recorded in the previous trading session.</p>
      <p>Additionally, this price decline has led to a sharp drop in the Relative Strength Index (RSI) line. While the RSI line remained above the neutral threshold, there exists a considerable likelihood of it dipping below this level should the downward price trend persist.</p>
    </div>
  },
  {
    id: 711,
    title: 'PYUSD’s struggle for stardom: A deeper look into PayPal’s stablecoin',
    category: 'Crypto',
    subCategory: ['PYUSD', 'PayPal', 'stablecoin'],
    description: <div className="blog-desc">
      <p>PYUSD, PayPal’s ambitious stablecoin, sought stardom but faces fierce competition from giants like USDT and USDC. Can it bridge the gap and win the trust of crypto users?</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 21, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1695383647/ncx-blog-1302_dijkga.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>PYUSD, PayPal’s ambitious stablecoin, sought stardom but faces fierce competition from giants like USDT and USDC. Can it bridge the gap and win the trust of crypto users?</p>
    	<ul>
        <li>PYUSD volume declines by over 6% in 24 hours.</li>
        <li>Market cap depicts low adoption compared to other stablecoins.</li>
      </ul>
      <p>The PayPal USD, known as PYUSD, was anticipated to gain popularity as a stablecoin. This was primarily because of its support from the renowned payment giant and the extensive customer base it wields. However, recent reports indicated that this expectation didn’t materialize as anticipated.</p>	
    	<h3>Paxos reports show low PYUSD adoption</h3>
      <p>In a recent report released by Paxos, the issuer of the PYUSD stablecoin, it was revealed that the stablecoin’s market capitalization remained below $50 million. This information was based on PayPal’s initial transparency report. The report highlighted that PYUSD didn’t achieve widespread adoption despite its promise to facilitate global payments. The issuer disclosed that PYUSD was backed by assets totaling $45.3 million.</p>
      <p>Out of this amount, approximately $1.5 million was backed by cash deposits. The majority of the reserves, amounting to $43.8 million, were collateralized through US Treasuries via reverse repurchase agreements.</p>	
    	<h3>How PYUSD ranks</h3>
      <p>Based on data provided by CoinMarketCap, PYUSD’s trading volume experienced a notable decrease of more than 6% within the last 24 hours. At the time of this report, the trading volume stood at approximately $2.8 million, positioning it at number 340 in terms of trading volume among all cryptocurrencies.</p>
      <p>Additionally, CoinMarketCap data indicated that PYUSD boasted of a market capitalization of approximately $44.4 million. This led to its ranking at number 380 by market capitalization among all cryptocurrencies.</p>
      <p>In the context of stablecoins specifically, PYUSD held the 19th position in terms of market capitalization as of this writing.</p>
      <h3>How it compares to other stablecoins</h3>
      <p>When comparing PYUSD to leading stablecoins like Tether (USDT) and Circle (USDC), the differences in market capitalization and trading volume were quite significant. As of the current data, USDT had a market capitalization exceeding $83 billion, accompanied by a 24-hour trading volume surpassing $20 billion. Similarly, USDC boasted of a market capitalization of over $26 billion and a 24-hour trading volume of more than $3 billion.</p>
      <h3>Some possible reasons for its slow adoption</h3>
      <p>USDT and USDC stand out as two of the earliest and most widely acknowledged stablecoins in the market. Their early entry into the space allowed them to establish trust and familiarity among users. Consequently, USDT and USDC have amassed a substantial user base and integrated themselves into numerous platforms, rendering them highly attractive options.</p>
      <p>Additionally, trust plays a crucial role in the adoption of stablecoins. Users often favor stablecoins with a demonstrated history of consistently maintaining their value at par with the US dollar. These represent pivotal benchmarks that PYUSD may need to attain before it can witness an increase in its adoption rate.</p>
    </div>
  },
  {
    id: 710,
    title: 'Hedera’s stablecoin launch: Why this can accelerate the network’s growth',
    category: 'Crypto',
    subCategory: ['Hedera', 'stablecoin', 'network'],
    description: <div className="blog-desc">
      <p>The Hedera network made a huge announcement considering the growth trajectory of the network. How this will impact HBAR remains to be seen.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 15, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1694777969/ncx-blog-1282_avsdwj.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The Hedera network made a huge announcement considering the growth trajectory of the network. How this will impact HBAR remains to be seen.</p>
    	<ul>
        <li>Hedera’s stablecoin plans highlight a chance to explore a tested area of growth.</li>
        <li>HBAR bulls are back but current data suggested that their dominance might be shortlived.</li>
      </ul>
      <p>The Hedera blockchain just made possibly one of its most important announcements this year. The network plans to push full speed ahead into the stablecoin segment and this could set precedence for aggressive growth in the next 12 months.</p>	
    	<p>The announcement revealed that Hedera is venturing into the stablecoin industry through its newly launched stablecoin studio. The latter will reportedly be Hedera’s toolkit for the management, issuance, and configuration of stablecoins within its ecosystem.</p>
      <p>In addition, the Hedera network revealed that the new stablecoin toolkit will be a B2B offering. The target clients are payment providers, companies, and institutional stablecoin issuers. The target clientele underscores the potential to tap into multiple industries.</p>
      <h3>Will Hedera’s stablecoin studio unlock exponential network growth?</h3>
      <p>Institutional liquidity is one of the biggest drivers in crypto and beyond. This focus could thus offer a new lifeline for Hedera’s dream of achieving mass adoption and utility. The decision to explore growth through stablecoins is one that has been quite successful for other top networks. The Tron network is perhaps the best example of a network that has achieved immense growth mostly through stablecoins.</p>	
      <p>Hedera could thus be following in Tron’s footsteps with its own unique twist on the stablecoin focus. This could unlock more utility which could in turn facilitate more utility and demand for the HBAR cryptocurrency.</p>
    	<h3>HBAR bulls are out to play</h3>
      <p>The news about Hedera’s new stablecoin studio came at a time when HBAR was experiencing a bullish relief after a 40% discount from August highs. HBAR exchanged hands for roughly $0.051 at press time after a 13% rally from its four-week low.</p>
      <p>HBAR’s pivot occurred after retesting the same support line where the price found support in early July. It was also almost overbought according to the Relative Strength Index (RSI), hence indicating pent-up bullish momentum. This would explain why it did not dip all the way into oversold territory.</p>
      <p>Perhaps the next most important question is whether HBAR can sustain the bullish momentum for a while longer. Well, on-chain data revealed that the ongoing rally was backed by a substantial volume uptick. Daily trading in USD volume was also up considerably.</p>
      <p>While the above observations align with the bullish momentum, it was worth noting that the initial wave of open interest is over. Open interest in USD per exchange bounced sharply in the last 24 hours. However, it also dipped considerably after its peak. This might be a sign that the bullish momentum could be short-lived if the current demand is not sustainable.</p>
    </div>
  },
  {
    id: 709,
    title: 'How Bitcoin’s market dominance signals good news for investors',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'dominance', 'investors'],
    description: <div className="blog-desc">
      <p>Despite price fluctuations, Bitcoin’s market dominance has surged to 50%, overshadowing altcoins and reshaping the crypto landscape.</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 15, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1694777590/ncx-blog-1281_jocuap.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3> 
    	<p>Despite price fluctuations, Bitcoin’s market dominance has surged to 50%, overshadowing altcoins and reshaping the crypto landscape.</p>
    	<ul>
        <li>Bitcoin’s dominance rebounds to 50%, signaling a market shift.</li>
        <li>Rising whale and retail interest, along with increased active addresses, impact Bitcoin’s market dynamics.</li>
      </ul>
      <p>Despite Bitcoin’s [BTC] recent price dip from its $26,000 mark, it has managed to maintain a firm grip of its dominance in the market. This raised questions about the fate of alternative cryptocurrencies.</p>
      <h3>Bitcoin domination continues</h3>	
    	<p>Recent data indicated that Bitcoin’s market dominance soared back to 50%, signaling a loss of market share for alternative cryptocurrencies. In essence, this means that Bitcoin has become a more significant player in the market, overshadowing its competitors.</p>
      <p>Additionally, whale interest in Bitcoin was on the rise. The number of addresses holding significant amounts of the cryptocurrency reached new highs. This trend can have both positive and negative implications for the market.</p>
      <p>On the one hand, it indicated growing confidence among large investors. On the other hand, it raised concerns about the concentration of wealth within the Bitcoin ecosystem.</p>
      <p>Retail interest was also surging, with a record number of addresses holding smaller amounts of BTC. This increased retail participation can impact market dynamics. It suggests that Bitcoin is becoming more accessible and appealing to everyday investors, potentially driving further adoption.</p>
      <p>Active addresses on the network also surged, hitting a four-month high. This uptick in activity can indicate growing interest and engagement within the community. It means more people are actively using Bitcoin, which can be a sign of a healthy and vibrant ecosystem.</p>
      <p>However, some metrics like the MVRV ratio and long-short difference have declined. These indicators suggest that short-term holders may be increasing in number. This could lead to more volatility in the market, as short-term traders tend to react quickly to price fluctuations.</p>
      <h3>What are traders doing?</h3>
      <p>Bitcoin’s open interest fell which could affect trading dynamics and market sentiment. A decrease in open interest can imply reduced speculative activity or uncertainty in the market. Traders may be taking a cautious approach in response to recent price movements.</p>
      <p>In terms of trader sentiment, long positions slightly outnumber short positions, indicating a cautious optimism among market participants. While more traders are betting on the coin’s price to rise, the margin is narrow, suggesting that there is still a degree of uncertainty in the market.</p>	
    </div>
  },
  {
    id: 708,
    title: 'Polygon mulls replacing MATIC with a new token in 2.0 upgrade',
    category: 'Crypto',
    subCategory: ['Polygon', 'Matic', 'token'],
    description: <div className="blog-desc">
      <p>One of the latest proposals released by the development team at Polygon is about replacing MATIC with a new token.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 14, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1694777160/ncx-blog-1280_gtg3jo.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>One of the latest proposals released by the development team at Polygon is about replacing MATIC with a new token.</p>
      <ul>
        <li>Polygon has released three proposals regarding the 2.0 upgrade.</li>
        <li>When the SEC sued Binance in June, it classified MATIC, among other tokens, as a security.</li>
      </ul>
    	<p>Polygon [MATIC] developers on 14 September officially released a set of three Polygon Improvement Proposals (PIPs) on the 2.0 upgrade. One of these proposes replacing the MATIC token with a new token POL.</p>	
    	<p>The proposals also discussed the outline of the upgrade and specifications for the new architecture. Here’s a look at all the three proposals in detail.</p>
      <p>The first proposal, PIP-17, details how the blockchain would adopt the POL token. As the native gas and staking asset of the Polygon ecosystem, it will replace the existing MATIC token.</p>
      <p>The second proposal, PIP-18, talks about Phase 0 of Polygon 2.0. It states that end-users and developers on the existing Polygon PoS and Polygon zkEVM chains need not do anything when the 2.0 upgrade takes place.</p>
      <p>The third proposal, PIP-19, urges that the switch from MATIC to POL token on the blockchain take place in a way that preserves compatibility with smart contracts on Polygon PoS. In this manner, the code properties of POL will remain the same as those of MATIC.</p>
      <p>The team of developers at Polygon has urged the community members to provide feedback on these proposals. The team will implement these proposals as they get approved.</p>
      <h3>But the SEC classified MATIC as security, remember?</h3>
      <p>Polygon is expected to upgrade in 2024.</p>	
    	<p>It was in June that Polygon proposed the 2.0 upgrade about transitioning to a zero-knowledge (ZK) solution. In July, it proposed for the first time replacing native token MATIC with POL. It explained that POL would be a “hyperproductive” token that enables holders to become validators as it would only require a simple smart contract upgrade for MATIC.</p>
      <p>It also underlined at the time that its decision had nothing to do with the U.S. Securities and Exchange Commission’s (SEC) decision to label MATIC as an unregistered security.</p>
      <p>When the SEC sued Binance [BNB] in early June, it classified MATIC, among other tokens, as security. Polygon soon responded to the SEC’s claim, saying it was developed outside the U.S. It said MATIC is an integral part of its operations and it has played a crucial role in ensuring the security of the Polygon network.</p>
      <p>Since the SEC’s enforcement action, MATIC has lost 40% of its value. At press time, it was trading at $0.52109.</p>
    </div>
  },
  {
    id: 707,
    title: 'Bitcoin Cash pumps 13% – a rally in the works?',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'cash', 'pumps'],
    description: <div className="blog-desc">
      <p>Bitcoin Cash’s 13% pump over the past day takes the altcoin above the $200 psychological level after finding support at the $186 price zone.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 14, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1694776695/ncx-blog-1279_ilj3ak.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin Cash’s 13% pump over the past day takes the altcoin above the $200 psychological level after finding support at the $186 price zone.</p>
    	<ul>
        <li>Bulls rallied strongly from a critical support level.</li>
        <li>Longs held a 51.33% advantage in the futures market.</li>
      </ul>
      <p>A 13% pump over the past day took Bitcoin Cash [BCH] above the $200 psychological level as of press time, after finding support at the $186 price zone. With Bitcoin [BTC] also hitting the $26k price level, this could lead to more bullish price action for BCH.</p>	
    	<h3>Bulls halt selling pressure</h3>
      <p>BCH has been on a bearish decline since early July. This saw Bitcoin Cash drop from $325 to $165 before finding initial support at the $186 price level.</p>
      <p>The 12-hour chart showed that bulls had rallied strongly on 29 August but met resistance at the $220 price zone. A retest of the $186 support level on 11 September led to BCH’s recent rally, with bulls looking to build on.</p>
      <p>A long move from the current price level could result in profits for bulls at the $220 – $240 price level. This buying move would be accelerated if Bitcoin continues its bullish rebound.</p>
      <p>However, if the king coin dips back under $26k, a shorting set-up could materialize for sellers with targets at $170 to $185.</p>
      <p>Meanwhile, the Relative Strength Index (RSI) surged to 60 to highlight the strong buying pressure. The On Balance Volume (OBV) also jumped from 7.7 million to 8.1 million, reiterating the good trading volume for BCH.</p>
      <h3>Longs were in the majority in the futures market</h3>
      <p>The short-term bias for BCH in the futures market was bullish. Data from Coinglass showed that bulls held a 51.33% share of the open contracts. This amounted to $166.26 million long positions, which outperformed the $157.63 million short positions.</p>
      <p>This hinted that buyers were looking to press home the bullish advantage with more gains in the coming days.</p>
    </div>
  },
  {
    id: 706,
    title: 'Crypto exchange FTX can now sell its $3.4 billion crypto holdings',
    category: 'Crypto',
    subCategory: ['Crypto', 'FTX', 'exchange'],
    description: <div className="blog-desc">
      <p>On 13 September, Delaware district judge John Dorsey gave crypto exchange FTX a green signal to sell its crypto holdings.</p>
    </div>,
    authorName: 'Priya NV',
    authorurl: 'https://ambcrypto.com/author/priya-nv/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 14, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1694776504/ncx-blog-1278_ad3m6d.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>On 13 September, Delaware district judge John Dorsey gave crypto exchange FTX a green signal to sell its crypto holdings.</p>
      <ul>
        <li>The US court approved FTX’s plan to sell its crypto holdings</li>
        <li>The exchange has $1.6 billion worth of SOL and $560 million worth of BTC</li>
      </ul>
    	<p>Defunct crypto exchange – FTX – plans to sell crypto holdings received a green signal from a Delaware district judge John Dorsey. The latest development in the FTX bankruptcy proceedings could see the firm shedding billions in crypto in the market. As of 13 September, FTX holds $3.4 billion in its crypto holdings. However, a majority of the holdings belong to Solana [SOL].</p>
      <h3>Massive crypto sell incoming?</h3>	
    	<p>According to court documents, the firm could sell a minimum of $50 million in assets in the first week. Following this, it can sell $100 million worth of assets each week. And, with prior approval from the creditors’ committee, the selling limit can be increased to $200 million per week depending on the token. This process will be handled by an investment adviser, who will be required to follow set guidelines.</p>
      <p>Notably, in the case of Bitcoin [BTC], Ethereum [ETH], and some insider-affiliated tokens, FTX will have to notify the US Trustee 10 days in advance. The Trustee is appointed by the US Department of Justice (DoJ).</p>
      <p>Additionally, the firm also has other options than to sell its crypto holdings. The court filing also allowed the exchange to stake and hedge its crypto holdings. The filing read,</p>
      <p><strong>“the Debtors are seeking authorization to hedge the Debtors’ Bitcoin and Ether through an Investment Adviser by, for example, buying or selling call or put options. Hedging Bitcoin and Ether will allow the Debtors to limit potential downside risk prior to the sale of such Bitcoin or Ether.”</strong></p>	
    	<p>According to the documents disclosed this week, the bankrupt crypto exchange has $1.16 billion in SOL, which is 16% of Solana’s outstanding supply. The Bitcoin holding sums up to about $560 million, while its Ether holding is worth $192 million. The exchange also has over $100 million worth of XRP, USDT, and Aptos [APT]. Other crypto holdings include WBTC, WETH, SGT, and BIT.</p>
      <p>Notably, the news of the court approval has not taken a toll on the price of Bitcoin and other cryptocurrencies. According to CoinMarketCap, at press time, BTC was trading at $26,198 and showed no significant price change in the past hour or past day. The same applied to the rest as there were no significant gains or losses for any crypto.</p>
    </div>
  },
  {
    id: 705,
    title: 'Ethereum: Bulls and bears tussle as ETH prices touch $1600',
    category: 'Crypto',
    subCategory: ['Ethereum', 'Bulls', 'ETH'],
    description: <div className="blog-desc">
      <p>Ethereum could fall into another rangebound movement as bulls attempt to reverse recent losses.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 14, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1694775845/ncx-blog-1277_bqctvo.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Ethereum could fall into another rangebound movement as bulls attempt to reverse recent losses.</p>
      <ul>
        <li>ETH dipped by 6% over the past 48 hours with bears flipping the $1,619 level to resistance.</li>
        <li>Selling pressure could exist in the mid to long term due to spikes in exchange inflows.</li>
      </ul>
    	<p>Ethereum’s [ETH] attempt at a bullish uptick after the bearish break was swiftly curtailed at the $1,619 resistance level. Previously ETH had ranged between the $1,619 and $1,718 price levels over a month-long period.</p>
      <p>If Bitcoin [BTC] continues to move sideways between $25.7k and $28.4k, ETH could mirror the king coin’s price action with a range of its own between $1,550 and $1,619.</p>
      <h3>Bullish resolve at $1,619 succumbed to price volatility</h3>	
    	<p>The hack of Vitalik Buterin’s (co-founder of Ethereum) X account on 10 September ushered in a wave of panic selling of ETH. This gave sellers the necessary impulse to break the bullish defense of the $1,619 support level.</p>	
      <p>Despite the 6% drop, bulls quickly rallied at the $1,550 support level and touched the new resistance level of $1,619 within 24 hours. However, the price action on the 12-hour timeframe showed a bearish rejection at the level.</p>
      <p>The Relative Strength Index (RSI) highlighted the bullish recovery with a sharp push out of the oversold zone, although the buying pressure waned close to the neutral 50 mark. The On Balance Volume on the other hand continued to dip lower to underline the overall bearish sentiment.</p>
      <p>Notwithstanding the bullish bounce, a continuation of this price action could see ETH settle into another range between $1,550 and $1,619.</p>
      <h3>On-chain metrics highlighted the possibility of sustained selling pressure</h3>
    	<p>Data from Santiment showed a large drop in the 90d mean coin age. This suggested that a lot of ETH sells were triggered by long-term holders over the past days.</p>
      <p>Similarly, the exchange inflow recorded significant spikes between 6 September to 12 September. This hinted at sustained selling pressure with ETH likely to continue experiencing volatile price movements.</p>
    </div>
  },
  {
    id: 704,
    title: 'Bitcoin: As demand climbs to highest in years, what does it mean for prices',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'demand', 'prices'],
    description: <div className="blog-desc">
      <p>While its price struggles to rally above $26,000, new demand for Bitcoin has increased. However, a general correction in market sentiment may be required to initiate price growth.</p>
    </div>,
    authorName: 'Abiodun Oladokun',
    authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 11, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1694774950/ncx-blog-1276_fxdgrn.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>While its price struggles to rally above $26,000, new demand for Bitcoin has increased. However, a general correction in market sentiment may be required to initiate price growth.</p>
    	<ul>
        <li>A record number of 717,331 new addresses were created on the Bitcoin network on 9 September.</li>
        <li>This suggested a renewed interest in the leading crypto asset.</li>
      </ul>
      <p>A record number of new Bitcoin [BTC] addresses were created on 9 September, with 717,331 addresses being added to the coin’s network. This is the highest number of new addresses created in the past five years, crypto analyst Ali_Charts noted in a recent post on X (formerly Twitter). The previous record was set on 14 December, 2017 when 800,180 new addresses were created.</p>	
    	<p>A surge in new demand for an asset is often a precursor to a price rally, suggesting renewed interest in that asset. However, with BTC’s price facing significant resistance at $26,000 and weighted sentiment now stationed in the negative territory, the possibility of a price uptick in the meantime remains minimal.</p>
      <h3>Buyers and sellers await first mover</h3>	
      <p>At press time, the leading coin exchanged hands at $25,862. With price consolidating in a tight range in the past few weeks, readings from BTC’s Bollinger Bands (BB) indicator observed on a daily chart revealed that volatility in price has reduced.</p>
      <p>August was predominantly marked with price swings, primarily due to the deleveraging event of 17 August. As the market moves on from the aftermath of the liquidity flush and spot traders stay their hands from executing major trades, the gap between the upper and lower bands of BTC’s BB has narrowed. The narrowing of the BB bands suggests that the volatility of BTC has decreased.</p>
      <p>Further, the position of BTC’s key momentum indicators showed that while accumulation dawdled, there have not been any significant sell-offs from spot traders. Although daily distribution has outpaced accumulation, the volumes have not been high enough to initiate a downward price swing.</p>
      <p>The coin’s Relative Strength Index (RSI) and Money Flow Index (MFI) have flattened for most of the month. At press time, the RSI was 46.76, while the MFI was 39.61.</p>
      <p>Despite this, BTC’s Directional Movement Index (DMI) showed that sellers controlled market activity amongst daily traders. This indicator measures the strength and direction of a price movement.</p>
      <p>At press time, the Positive Directional Indicator (green) at 17.97 was positioned below the Negative Directional Indicator (red). This showed that BTC sellers’ strength outweighed its buyers.</p>
    	<p>However, the Average Directional Index (ADX) position offered some respite. At 21.98 as of this writing, the downtrend was weak. And a change in market sentiment could put the bulls back in control.</p>
    </div>
  },
  {
    id: 703,
    title: 'XRP witnesses steady influx of buyers despite being trapped at $0.5',
    category: 'Crypto',
    subCategory: ['XRP', 'Ripple', 'Influx'],
    description: <div className="blog-desc">
      <p>Open Interest has remained flat. The price action also showed no strong short-term trend. This signaled a lack of conviction from speculators.</p>
    </div>,
    authorName: 'Akashnath S',
    authorurl: 'https://ambcrypto.com/author/akashnath-sumukar/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 4, 2023',
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693898602/ncx-blog-1269_oxnqbn.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Open Interest has remained flat. The price action also showed no strong short-term trend. This signaled a lack of conviction from speculators.</p>
    	<ul>
        <li>XRP has a firm bearish structure on the higher timeframe charts.</li>
        <li>It was unclear if the short-term demand could turn the tides for the asset.</li>
      </ul>
      <p>XRP whales could have increased their holdings over the past week as data showed whale transactions had risen. The short-term price action lacked a strong trend, but the higher timeframe charts showed a bearish bias.</p>	
    	<p>The price action hinted at new lows for XRP not seen since March. This was because XRP bulls were unable to defend key areas of interest on the chart in August. Can the buyers drive a recovery, or do the sellers still have the upper hand?</p>	
    	<h3>The Fibonacci levels laid out a strongly bearish scenario for XRP</h3>
      <p>Based on the surge from $0.4648 to $0.938, a set of Fibonacci retracement levels were plotted. The 78.6% level sat at $0.56 and had confluence with a bullish order block from mid-June. The waves of selling pressure in August saw both of them flipping to resistance.</p>
      <p>Therefore, it was now likely that the 23.6% extension level to the south was the target for the XRP bears. The market structure and trend agreed, and the OBV showed consistent sell pressure over the past month.</p>	
    	<p>Therefore, a shorting opportunity for swing traders was present. This idea would be invalidated upon a move above the $0.56-$0.57 area. The target is the $0.353 level, which was also close to the XRP low from March.</p>	
    	<h3>The Open Interest refused to budge but buyers are coming in</h3>
      <p>Since 18 August the Open Interest has remained flat. The price action also showed no strong short-term trend. This signaled a lack of conviction from speculators, but it was important to note that the sentiment wasn’t yet bullish.</p>
      <p>On the other hand, the spot CVD has trended higher since 18 August to signify a steady inflow of capital to the market. It was evidence of buyers entering long-term psychological support- but will these buyers be vindicated?</p>
      <p>An XRP move above $0.57 would be the first step, at which point traders can assess if the conditions were favorable for the bulls.</p>
    </div>
  },
  {
    id: 702,
    title: 'Polkadot: Why you need to watch out for DOT’s move to $4.6',
    category: 'Crypto',
    subCategory: ['Polkadot', 'DOT', 'crypto'],
    description: <div className="blog-desc">
      <p>In the past 48 hours, DOT registered a bounce close to 3% higher. Yet, the spot CVD has been in a downtrend during this time.</p>
    </div>,
    authorName: 'Akashnath S',
    authorurl: 'https://ambcrypto.com/author/akashnath-sumukar/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 4, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693898267/ncx-blog-1268_esfgdz.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>In the past 48 hours, DOT registered a bounce close to 3% higher. Yet, the spot CVD has been in a downtrend during this time.</p>
    	<ul>
        <li>Polkadot has a bearish structure across higher and lower timeframe charts.</li>
        <li>A move to the $4.4-$4.6 region was possible but would be an opportunity for short-sellers to re-enter the market.</li>
      </ul>
      <p>Polkadot [DOT] saw a bullish market structure break on the lower timeframe a few days ago. However, the bounce lost momentum at $4.8 and has since fallen below the $4.3 support. This showed that the temporary respite from selling pressure was a ruse.</p>	
    	<p>The momentum was bearish once more, and the drop below $4.3 was a sign of intent from the sellers. Where is the next higher timeframe support zone that the bulls can look to make a stand at?</p>
      <h3>The breach of the HTF bullish order block meant sellers were in the driving seat</h3>	
    	<p>On the 1-day chart, Polkadot stood at $4.28 at the time of writing. The $4.56 (dotted orange) highlighted a lower high from the 4-hour chart, which was beaten on Monday 28 August. However, the bulls were unable to sustain the move and the bears seized control at the $4.8 mark.</p>
      <p>The $4.27 level represented the low of the bullish order block on the 1-day chart. On Friday, 1 September, DOT closed a daily session below it. This flipped the order block to a bearish breaker, and meant that short-selling opportunities could arise should DOT bounce toward the $4.4-$4.6 region.</p>	
    	<p>The Fibonacci extension levels showed that sub-$4 prices were a strong possibility for Polkadot in Q4 2023. The 23.6%, 50%, and 61.8% extension levels lie at $3.894, $3.495 and $3.317 respectively. Given the downtrend on the 1-day chart and the bearish market structure, it was likely that DOT would fall toward the $3.5 and $3.3 levels in the coming months.</p>
      <h3>The market sentiment was in bearish favor- have things changed?</h3>
      <p>On 31 August, when DOT from $4.45 to $4.26, the Open Interest soared higher. This showed short sellers entering the market in large numbers and signified intense short-term bearishness.</p>	
    	<p>In the past 48 hours, DOT registered a bounce close to 3% higher. Yet, the spot CVD has been in a downtrend during this time.</p>
      <p>It was evidence of continued selling pressure on the token and signaled the absence of strong buyers, which meant Polkadot was likely to drop lower on the price chart.</p>
    </div>
  },
  {
    id: 701,
    title: 'Stellar struggles at the mid-range, will XLM prices dip',
    category: 'Crypto',
    subCategory: ['XLM', 'Prices', 'Dip'],
    description: <div className="blog-desc">
      <p>XLM breached the mid-range level and could tempt sellers to push prices lower.</p>
    </div>,
    authorName: 'Benjamin Njiri',
    authorurl: 'https://ambcrypto.com/author/benjamin-njiri/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 4, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693898101/ncx-blog-1267_nvgtvf.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>XLM breached the mid-range level and could tempt sellers to push prices lower.</p>
      <ul>
        <li>XLM fell back to its Q3 2022 range formation.</li>
        <li>Open Interest rates stagnated as funding rates fluctuated.</li>
      </ul>
    	<p>Stellar’s [XLM] price action has taken a cue from the Q3 2022 range formation. At press time, XLM breached the mid-range of $0.1150. It meant sellers could increasingly gain market control.</p>	
    	<p>In the meantime, Bitcoin [BTC] was stuck in its range-lows of $25k but struggled to reclaim $26k at press time.</p>
      <h3>Will sellers push XLM lower?</h3>	
    	<p>In Q3 2022, XLM oscillated between $0.0991 – $0.1303, yellow parallel channel. The extended pullback in August eased at the mid-range of $0.1150. An attempted recovery was blocked at the range-high near $0.1300, tipping XLM to crack the mid-range.</p>
      <p>Since 1 September, XLM closed daily sessions below the mid-range ($0.1150). It denotes increasing seller leverage. A weekly session close below the same level could confirm sellers’ advancement.</p>
      <p>If so, sellers could push XLM to the range low near $0.1000. The range low has a confluence with a daily bullish OB of $0.0953 – $0.0986 (cyan), making the area a solid bullish zone.</p>
      <p>The RSI fluctuated below the 50 mark, reinforcing the muted buying pressure and bearish bias described above. However, the CMF wavered above zero, meaning capital inflows fluctuated.</p>
      <h3>XLM had negative readings</h3>
      <p>In the last few days, funding rates were negative overall, underscoring the bearish bias witnessed across the crypto market over the same period. The bias was confirmed by the declining Open Interest (OI) rates over the same duration. It demonstrates demand for XLM dropped in August and extended to early September.</p>
      <p>However, the CVD (Cumulative Volume Delta) improved in September. The metric tracks whether sellers or buyers have market control. The improvement suggests buyers regained little market control.</p>
      <p>But the flat OI and a BTC stuck in the range low could favor XLM sellers. Traders should track BTC price movement for a better-optimized set-up.</p>
    </div>
  },
  {
    id: 700,
    title: 'Aptos stalls at $5.6 level – Will sellers benefit?',
    category: 'Crypto',
    subCategory: ['Aptos', 'Stalls', 'Sellers'],
    description: <div className="blog-desc">
      <p>The $5.6 hurdle persisted and could be exploited by APT sellers for further gains.</p>
    </div>,
    authorName: 'Benjamin Njiri',
    authorurl: 'https://ambcrypto.com/author/benjamin-njiri/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'September 3, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693897633/ncx-blog-1266_ox0zj5.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The $5.6 hurdle persisted and could be exploited by APT sellers for further gains.</p>
      <ul>
        <li>APT recovery struggled to reclaim the range low of $5.6 convincingly. </li>
        <li>Funding rates were negative, while Open Interest rates didn’t improve.</li>
      </ul>
    	<p>Aptos’ [APT] range-low of $5.6 prevented further recovery attempts and could be exploited by sellers. APT oscillated between $6.06 and $5.59 in the second half of August but formed a bearish break-out, offering sellers more leverage. It struggled to reclaim the range low in the last few days and could present extra shorting gains. </p>	
    	<h3>Will sellers exploit the range low?</h3>
      <p>The previous range-low of Aptos had a confluence with an invalidated bullish order block (OB) on the 4-hour chart of $5.58 – $5.65 (white). The dynamic 50-EMA (Exponential Moving Average), blue, had inched closer above the range-low.</p>	
    	<p>So, the $5.6 area could be a solid bearish zone if Bitcoin’s [BTC] muted performance persists in the short term. As such, a price rejection in the zone could set APT to grace the recent low of $5.38.</p>
      <p>Such a rejection could offer another shorting opportunity, with an entry at $5.6 and take-profit at $5.38 or $5.4.</p>
      <p>However, a foray above $5.65 and a successful reclaim of the range-low will invalidate the short idea.</p>
      <p>Meanwhile, the RSI fluctuated below the 50-equilibrium level. It denotes muted and wavering buying pressure. The CMF also had a negative reading and struggled to reclaim the zero mark in September – Capital inflows were muted.</p>
      <h3>Negative funding rates persisted</h3>
      <p>According to Coinalyze, APT recorded overwhelmingly negative funding rates in the last few days. The trend illustrates the bearish sentiment in the derivatives market that prevailed in August and spilled into early September.</p>
      <p>The declining Open Interest rates confirmed the bearish bias. Notably, the metric slid lower between August and early September, dropping from $64 million to $56 million as of press time. The drop captures the declining demand for APT in the derivatives market.</p>
      <p>However, a BTC price reversal to the upside could tip APT bulls to reclaim range-low; thus, tracking the king coin movement is crucial.</p>
    </div>
  },
  {
    id: 699,
    title: 'ApeCoin’s tale of accumulation and selling sparks market speculation',
    category: 'Crypto',
    subCategory: ['Apecoin', 'market', 'crypto'],
    description: <div className="blog-desc">
      <p>Amidst ApeCoin’s dance between accumulation and selling, a dedicated supporter’s bold move meets a fading surge in activity. Sentiment ripples, leaving traders speculating on its dynamic fate.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 28, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693289139/ncx-blog-1234_qtlcvm.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Amidst ApeCoin’s dance between accumulation and selling, a dedicated supporter’s bold move meets a fading surge in activity. Sentiment ripples, leaving traders speculating on its dynamic fate.</p>
    	<ul>
        <li>Over 150,000 ApeCoin was recently accumulated by a wallet.</li>
        <li>The APE market sentiment, however, remains negative.</li>
      </ul>
      <p>Over the past few weeks, the story of ApeCoin appears to be revolving around two main activities: dumping and accumulation. According to recent on-chain tracking, there has been a noticeable increase in accumulation during this time, alongside instances of selling. How have these actions influenced the overall sentiment and trajectory of the token?</p>	
    	<h3>ApeCoin sees large accumulation</h3>
      <p>A recent update from Look on-chain highlighted that a wallet associated with Machi Big Brother, a dedicated supporter of the Bored Ape Yacht Club (BAYC) ecosystem, has made a new purchase of ApeCoin. The data from the on-chain tracker revealed that this account acquired more than 154,000 APE tokens, using 135 ETH, with a total value of $222,000.</p>	
    	<p>Furthermore, this fresh accumulation adds to the previous holdings accumulated earlier in the month, resulting in a total APE accumulation of over 3 million for August. These acquisitions were made at an average price of $1.73 per token.</p>
      <p>Additionally, this recent action sharply contrasted with the activities observed in the previous week. During that time, a wallet was noted to have sold off over 180,000 APE tokens, incurring a loss exceeding $1.5 million. While these sales could be attributed to market fatigue, the recent purchase presented a bullish move and reflected a positive sentiment.</p>
      <h3>ApeCoin volume and active addresses flash divergent signals</h3>
      <p>Santiment’s volume metric indicated that the recent sell-off and accumulation activities involving ApeCoin have not had a noticeable impact on its trading volume. The volume trend remained within normal levels, displaying no sudden spikes or significant downward shifts.</p>
      <p>As of this writing, the trade volume was approximately 30 million.</p>
      <p>However, a contrasting situation was evident in the active addresses metric. The seven-day active addresses showed that APE experienced a surge, surpassing 20,000 around 17 August. This elevated level was sustained until roughly 24 August, after which it began to decline.</p>
      <p>As of this writing, the count of active addresses was about 6,500.</p>
      <p>This reduction in active addresses corresponded to a decline in positive sentiments surrounding ApeCoin.</p>
      <h3>APE funding rate stays negative</h3>
      <p>Despite the recent accumulation, the market sentiment for ApeCoin has not shifted into a positive state. This conclusion could be drawn from the funding rate of ApeCoin on Coinglass, which, as of this writing, was at a negative value of -0.008. This negative rate suggested that traders were anticipating a decrease in the token price.</p>
      <p>Observing the daily timeframe chart of APE, it was evident that the token was undergoing a decline, with a loss exceeding 2% as of this writing. The trading price was approximately $1.3.</p>
    </div>
  },
  {
    id: 698,
    title: 'How Litecoin emerged as the popular crypto for payments',
    category: 'Crypto',
    subCategory: ['Litecoin', 'Crypto', 'Popular'],
    description: <div className="blog-desc">
      <p>Litecoin’s share of the global payments jumped from 23% in March to a little over 34% by the end of July.</p>
    </div>,
    authorName: 'Aniket Verma',
    authorurl: 'https://ambcrypto.com/author/aniket-verma/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 28, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693288297/ncx-blog-1233_ytdlr6.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Litecoin’s share of the global payments jumped from 23% in March to a little over 34% by the end of July.</p>
      <ul>
        <li>LTC’s share of global payments jumped from 23% in March to a little over 34% by the end of July.</li>
        <li>LTC shed 30% of its value since the beginning of the month.</li>
      </ul>
    	<p>Litecoin [LTC] was one of the most preferred cryptocurrencies for payments as tracked by the world’s largest crypto payment processor BitPay. LTC’s share of the global payments jumped from 23% in March to a little over 34% by the end of July, representing a nearly 50% jump.</p>	
    	<p>In fact, not so long ago in June, the “Digital Silver” flipped the “Digital Gold” Bitcoin [BTC] to grab the numero uno spot for the first time in history. However, LTC’s growth trajectory has since plateaued, and it ceded the first position to BTC.</p>	
    	<h3>Litecoin’s summer of growth</h3>
      <p>Much of the growth was powered by the parabolic rise in transactions in the May-June period. According to Glassnode, LTC’s transactions count reached levels comparable to Bitcoin’s daily tally on several occasions during this phase.</p>
      <p>It should be recalled that demand for Litecoin swelled after exasperated users sought cheaper alternatives when transaction fees on Bitcoin were touching the skies.</p>
      <h3>LTC’s charts still in the red</h3>
      <p>The month of August, which saw the all-important halving in Litecoin’s block rewards, hasn’t panned out the way most of the token’s fanbase would have liked. LTC was hit hard by two successive bearish phases.</p>
      <p>Initially, the excitement over Litecoin’s scarcity dampened as the token plunged 10% within days of halving. The bigger decline came during the Bitcoin-led market bloodbath, when the token tanked 24%, as shown by CoinMarketCap.  Overall, LTC shed 30% of its value since the beginning of the month.</p>
      <h3>Whales began to accumulate</h3>
      <p>Sensing the impending price drop, Litecoin whales liquidated their holdings just in time. However, as the market began to move sideways, these big investors began to add to their stacks.</p>
      <p>As per data from Santiment, addresses storing between 1,000-10,0000 tokens increased sharply over the past week. The cohort holding between 10,000-100,000 LTCs exhibited a similar behavior. This indicated that whales were optimistic of a bull run in near future.</p>
      <p>However, sentiment around Litecoin continued to be pessimistic. The weighted sentiment, which is a measurement of commentary around the asset, trended in the negative territory.</p>
    </div>
  },
  {
    id: 697,
    title: 'I asked ChatGPT to assess Litecoin’s future, its response assured me',
    category: 'Crypto',
    subCategory: ['ChatGPT', 'Litecoin', 'Future'],
    description: <div className="blog-desc">
      <p>I asked ChatGPT about the future performance of Litecoin as the regulatory environment in the U.S. evolves.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 27, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693235470/ncx-blog-1232_zto0eq.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>I asked ChatGPT about the future performance of Litecoin as the regulatory environment in the U.S. evolves.</p>
    	<p>Litecoin [LTC] has just broken a record in terms of network transactions. The Litecoin network highlighted this year’s impressive performance in terms of transactions, noting that even though the year is only eight months old, 2023 has already eclipsed last year’s record number of 39 million transactions.</p>	
    	<h3>Glass half full</h3>
      <p>On 2 August, the third edition of Litecoin’s halving took place when the network reached a block height of 2,520,000, which reduced miners’ rewards from 12.5 LTC to 6.25 LTC.</p>
      <p>Halving is a significant event programmed into certain cryptocurrency protocols, including Litecoin, that takes place at regular intervals. During this event, the block reward given to miners for validating transactions and securing the network is reduced by half.</p>	
    	<p>The primary purpose of halving is to control the issuance rate of new coins and manage inflation within the cryptocurrency system.</p>
      <p>The impact of halving is two-fold. First, it introduces an element of scarcity into the cryptocurrency ecosystem. With the reduced block rewards, the rate at which new coins are introduced into circulation decreases.</p>
      <p>This creates a sense of scarcity among market participants, potentially leading to an increase in demand for cryptocurrency.</p>
      <p>Secondly, halving affects the mining rewards for miners. As the block rewards are halved, miners receive fewer coins for their mining efforts. This can result in decreased mining profitability, especially for miners with higher operating costs.</p>
      <p>Some miners may be compelled to leave the network if the costs of mining exceed the rewards, potentially leading to a temporary decline in the network’s hash rate and overall security.</p>
      <p>Litecoin, as it completes its third halving since its inception in 2011, will experience a reduction in its supply rate. It will lead to fewer new Litecoins being mined. This scarcity factor could trigger increased demand and speculative activity in the cryptocurrency market.</p>
      <p>Furthermore, miners will need to adjust to the reduced mining rewards, which may impact their profitability and mining operations.</p>
      <h3>ChatGPT makes a comparison</h3>
      <p>It is expected that the halving could help Litecoin gain more market share in the PoW sector. To make a comparison, I consulted ChatGPT to highlight the various differences of multiple PoW cryptocurrencies and their various advantages.</p>
      <p>According to the AI bot, Litecoin’s emphasis on faster transactions and lower fees is likely to attract more users and investors. As transaction volumes increase, network activity for Litecoin could surge, enhancing its appeal as a quick and cost-effective digital currency.</p>
      <p>However, Dogecoin’s inflationary nature stands in contrast to Litecoin’s scarcity induced by halving. While Dogecoin has strong community and viral appeal, concerns about dilution arising from its inflationary supply might favor Litecoin in the short term.</p>
      <p>Meanwhile, users valuing privacy and anonymity are more likely to stick with Monero due to its unique privacy features. However, for those prioritizing speed and lower fees, Litecoin could serve as a practical alternative.</p>
      <p>Regarding Ethereum Classic [ETC], its focus on immutability and code-as-law principles might attract a distinct audience compared to Litecoin’s user base. The short-term advantage for each cryptocurrency would depend on specific use cases and the preferences of users and investors during this period.</p>
      <h3>Does Litecoin have a competitive advantage?</h3>
      <p>Recent regulatory scrutiny against various cryptocurrencies has impacted various altcoins negatively. However, Litecoin was one of the few altcoins that was considered a commodity in the lawsuit filing against Binance [BNB].</p>
      <p>After asking ChatGPT’s opinion on this development, it stated that the classification of Litecoin as a commodity by the CFTC could have several benefits. As a regulated commodity, Litecoin’s value shall skyrocket, and it will emerge as a shining star in the cosmic arena of cryptocurrencies.</p>
      <p>The CFTC’s recognition may also lead to investment, innovation and adoption, supporting Litecoin’s growth.</p>
      <h3>Price tells a different story</h3>
      <p>Despite these factors, LTC’s price did not see much green at press time. It was trading at $64.42 at the time of writing.</p>
      <p>While both LTC’s Relative Strength Index (RSI) and Money Flow Index (MFI) rested above the neutral 50-mark, its On Balance Volume (OBV) reflected a positive metric.</p>
      <p>In conclusion, its on-chain metrics suggest that a possible bullish price action is around the corner.</p>
    </div>
  },
  {
    id: 696,
    title: 'Ethereum retail traders accumulate: Will their efforts drive a bullish reversal?',
    category: 'Crypto',
    subCategory: ['Ethereum', 'trader', 'bullish'],
    description: <div className="blog-desc">
      <p>Retail addresses have been accumulating at the current levels. New data reveals that Ethereum addresses holding at least 10 ETH just reached a 4-week high.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 27, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693235024/ncx-blog-1231_yzwjib.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Retail addresses have been accumulating at the current levels. New data reveals that Ethereum addresses holding at least 10 ETH just reached a 4-week high.</p>
    	<ul>
        <li>ETH flagged multiple signs indicating that it was ripe for a bullish takeover.</li>
        <li>ETH whales could be keeping the sell pressure alive.</li>
      </ul>
      <p>Ethereum [ETH] has been on a bearish streak since the second week of July. Its mid-August dip extended that downside, but now the bulls have been showing signs of exhaustion. Recent findings suggest that accumulation is gaining traction.</p>	
    	<p>ETH delivered sideways price action for the last few days, indicating that the bears are taking a break. According to one of the latest Glassnode alerts, retail addresses have been accumulating at the current levels. The alert reveals that Ethereum addresses holding at least 10 ETH just reached a 4-week high.</p>
      <p>The observation was an indicator that ETH traders were regaining some confidence in the market. This was further backed by the observation that ETH has been flowing out of exchanges. In addition, ETH exchange balances just fell to a multi-year low.</p>
      <p>The last time that ETH exchanges were at the current level was in 2016. This observation was also favorable for a long-term bullish outlook because it confirms a long-term bias.</p>
      <p>Despite these findings, ETH bulls remain subdued. This means that the prevailing levels of accumulation are not strong enough for a sizable rally. This is likely because investors are concerned that the downside might continue, especially considering the prevailing threat of higher interest rates.</p>
      <h3>Analyzing the key reasons why the bulls remain dormant</h3>	
    	<p>The current state of demand in the derivatives market underscores the aforementioned concern. Usually, a substantial price drop such as the one we observed recently attracts significant demand.</p>
      <p>However, that price crash was characterized by heavy liquidations. The threat of more downside and subsequent uncertainty resulted in a dip in ETH options open interest.</p>
      <p>ETH’s estimated leverage ratio is currently hovering at its lowest level in the last 4 weeks. It further emphasizes the point that there is currently a low appetite for the cryptocurrency.</p>
      <p>However, the usual suspects seem to be the ones holding back the possibility of a rally. Addresses in the 1,000 and 10,000 ETH categories have been shedding off some of their coins, thus contributing to sell pressure.</p>
      <h3>Evaluating the possibilities</h3>
      <p>While the threat of more downside is real, traders should note that the next FOMC meeting is scheduled to take place in the second half of September. This means there might be an opportunity for the bulls to gain an edge over the market.</p>
      <p>But with the current whale activity, traders should keep an eye out for when whales start to pivot because it would signal the start of a bullish relief.</p>
    </div>
  },
  {
    id: 695,
    title: 'XLM maintains its downtrend: Will prices dip further?',
    category: 'Crypto',
    subCategory: ['XLM', 'downtrend', 'crypto'],
    description: <div className="blog-desc">
      <p>XLM retested previous range-high of $0.13 as a resistance and could head lower towards the range-low and bullish OB.</p>
    </div>,
    authorName: 'Benjamin Njiri',
    authorurl: 'https://ambcrypto.com/author/benjamin-njiri/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 27, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693234282/ncx-blog-1230_wty1dd.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>XLM retested previous range-high of $0.13 as a resistance and could head lower towards the range-low and bullish OB.</p>
      <ul>
        <li>XLM has been making lower highs since mid-July.</li>
        <li>Open Interest rates dropped and funding rates fluctuated. </li>
      </ul>
    	<p>Stellar [XLM] could be poised for further market control. The altcoin was firmly bearish on the higher time-frame charts. Besides, the futures market didn’t outrightly lean towards bulls as of publication.</p>	
    	<p>In the meantime, Bitcoin [BTC] continued its price range above $25.8k, and further price slump couldn’t be overruled if September’s FOMC Meeting takes a hawkish stance.</p>	
    	<h3>Will sellers target the previous range low</h3>
      <p>The marked areas are weekly bearish and bullish order blocks (OB). The cyan area of $0.113 – $0.130 is a weekly bearish OB and aligns with the Q2 2022 range-high. Between June – October 2022, XLM oscillated between $0.1307 and $0.0989.</p>
      <p>On the other hand, the white area of $0.095 – $0.109 is a weekly bullish OB that has been retested twice as a support in July and August. The area also aligned with the Q2 2022 range-lows.</p>
      <p>At the time of writing, XLM faced rejection at the confluence of the 50-EMA (Exponential Moving Average) and the previous range-high ($0.13). Besides, the daily timeframe was bearish as the price has been making lower highs since July.</p>
      <p>So, sellers could lower XLM prices to the range-low $0.099 area in the next few days. However, bulls are expected to defend the range-lows, especially if BTC doesn’t record sharp losses in the midterm.</p>
      <p>The CMF crossed the zero mark, demonstrating improved capital inflows. However, the RSI recovered but failed to cross above the 50-neutral mark, illustrating that buying pressure waned.</p>
      <h3>Funding rates fluctuated as Open Interest dipped</h3>
      <p>The funding rates have fluctuated in the past few days but have been overall negative, painting a bearish inclination. In addition, the Open Interest rates improved between 18-21 August but declined steadily afterward. It further cements the bearish bias in the futures market.</p>
      <p>However, the CVD (Cumulative Volume Delta), which tracks whether buyers or sellers have market control, maintained a steady but sluggish positive slope. It suggests that buyers had little leverage and calls for tracking of BTC movement for optimized trade set-ups.</p>
    </div>
  },
  {
    id: 694,
    title: 'Is Litecoin overvalued? Exploring demand amid lowered prices',
    category: 'Crypto',
    subCategory: ['Litecoin', 'overvalued', 'ATH'],
    description: <div className="blog-desc">
      <p>BTC is only down by roughly 62% from its ATH, while ETH is at a 675% discount from its historic high. Based on the ATH discounts, we can conclude that LTC is more overvalued than its counterparts.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 27, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693233868/ncx-blog-1229_cvamiq.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>BTC is only down by roughly 62% from its ATH, while ETH is at a 675% discount from its historic high. Based on the ATH discounts, we can conclude that LTC is more overvalued than its counterparts.</p>
    	<ul>
        <li>Litecoin was multiple times oversold compared to Bitcoin and Ethereum.</li>
        <li>LTC saw a surge in retail demand, but whales were still on the sidelines.</li>
      </ul>
      <p>August was perhaps the most anticipated month for Litecoin [LTC] holders due to the halving. However, the month is coming to its conclusion, and LTC’s price continued to favor the bears.</p>	
    	<p>Litecoin’s bearish bias suggested a loss of excitement among holders. However, the cryptocurrency, which is often described as digital silver, has undergone a substantial discount since its July high. So, is it undervalued, or should traders anticipate more downside?</p>	
    	<p>For starters, LTC’s $65.47 press time price represented a 63% premium from its lowest point at its lowest price point during crypto winter. On the other hand, it was at a 43% discount from its highest price point in July.</p>
      <p>While LTC’s discount from July highs represented a healthy discount, it was much steeper if we contemplated its historic high. The cryptocurrency peaked at around $417 during the height of the 2021 bull run. Its press time price represented a 41% discount from its 2021 peak.</p>
      <p>We decided to compare Litecoin’s downside with that of Bitcoin and found something interesting. BTC was only down by roughly 62% from its ATH. Meanwhile, in the same time period, ETH was at a 675% discount from its historic high.</p>
      <p>Based on the ATH discounts, we can conclude that LTC is more overvalued than its counterparts.</p>
      <h3>Is LTC securing significant demand?</h3>
      <p>Note that Litecoin bears seems to be on recess after dominating since July. The price is also in oversold territory and also in a support level previously tested in March. Technically, we should see some accumulation in this region considering the support and oversold conditions as well as the sizable discount.</p>
      <p>LTC’s supply distribution metric revealed something interesting. Retail traders have been buying the dip, as seen by the upside in addresses holding between 10 and 1,000 LTC (denoted in red and orange).</p>
      <p>On the other hand, whale addresses registered net outflows in the last 10 days (addresses denoted in green, pink, and blue).</p>
      <p>The above observation confirmed that whales were still sitting by the sidelines, thus holding back the bulls. Daily active addresses have notably declined in the last few days compared to the levels observed at the start of August.</p>
      <p>At press time, Litecoin’s MVRV ratio was down to its lowest level in the last four weeks. This meant there was less incentive to sell below this level. Nevertheless, it does not guarantee that the price won’t go down further.</p>
    </div>
  },
  {
    id: 693,
    title: 'Bitcoin mining difficulty hits new ATH: What caused it?',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Mining', 'ATH'],
    description: <div className="blog-desc">
      <p>Bitcoin’s mining difficulty jumped to all-time highs over the past week, reflecting miners’ confidence in the profitability of mining.</p>
    </div>,
    authorName: 'Aniket Verma',
    authorurl: 'https://ambcrypto.com/author/aniket-verma/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 27, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693233417/ncx-blog-1228_uatnvr.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin’s mining difficulty jumped to all-time highs over the past week, reflecting miners’ confidence in the profitability of mining.</p>
    	<ul>
        <li>Bitcoin’s network hash rate bumped to a 4-month high.</li>
        <li>Miners delayed selling their holdings due to their belief in the growth potential of BTC.</li>
      </ul>
      <p>Bitcoin’s [BTC] miner activity is closely tracked by experts in the crypto ecosystem, as it provides vital clues into the network’s health and the level of profitability in the mining sector.</p>	
    	<p>As per a post by on-chain analytics firm Glassnode, the network hash rate bumped to a 4-month high on 26 August. The previous 4-month peak was recorded more than a month ago on 7 July.</p>	
    	<h3>Miners switch on their machines</h3>
      <p>An increase in hash rate implied that more computational power was dedicated to validating the transactions and securing the network. The entry of more miners or use of more efficient machines generally leads to a spike in this metric. Overall, the increase is a healthy sign with regard to security and decentralization of the network.</p>
      <p>The jump in hash rate propelled the mining difficulty to all-time highs over the past week. The mining difficulty adjusts automatically roughly every two weeks in order to keep the total block time at 10 minutes.</p>
      <p>In a statement shared by Bitfinex analysts to AMB Crypto, they revealed that the increase in mining difficulty was rooted in miners’ bullish expectations from Bitcoin.</p>
      <p><strong>“Miners could be confident that the price of Bitcoin will eventually rebound as this can be seen as a mere downwards deviation from its real value. Hence investing more resources to mine Bitcoin at these prices could be highly profitable to them.“</strong></p>
      <h3>Are miners bullish on BTC?</h3>
      <p>The analysts further stated that miners delayed selling their assets due to their belief in the growth potential of BTC.</p>
      <p>Glassnode’s data supported the above insights. It revealed that the total supply stored in miner addresses reached a monthly high of 1.83 million as of 26 August.</p>
      <p>Remember that miners frequently cash out to meet their mining and power costs. A delay in liquidations generally means that they are waiting for Bitcoin’s price to gain more strength.</p>
      <h3>Miner earnings decline</h3>
      <p>Miners’ optimism about Bitcoin was a refreshing sign, especially in a phase when earnings have dried up considerably. Apart from the early-May euphoria over BRC-2o, Bitcoin miners’ revenues have stayed muted for the majority of the ongoing crypto winter.</p>
    </div>
  },
  {
    id: 692,
    title: 'Worldcoin tanks by over 50% as data privacy concerns mount',
    category: 'Crypto',
    subCategory: ['Worldcoin', 'Crypto', 'data'],
    description: <div className="blog-desc">
      <p>As regulators worldwide voice their concerns about Worldcoin, WLD holders have had to deal with a decline in the cryptocurrency’s value.</p>
    </div>,
    authorName: 'Abiodun Oladokun',
    authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 26, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693053293/ncx-blog-1214_gkkrul.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>As regulators worldwide voice their concerns about Worldcoin, WLD holders have had to deal with a decline in the cryptocurrency’s value.</p>
    	<ul>
        <li>WLD’s value declined by more than 50% in the first month of the project’s launch.</li>
        <li>This has been a result of the regulatory concerns around the project.</li>
      </ul>
      <p>Since Worldcoin’s [WLD] mainnet launch a month ago, the regulatory concerns that have since plagued the project have resulted in a more than 50% decline in the value of its token.</p>	
    	<p>According to data from CoinMarketCap, trading at $1.27 at press time, the price per WLD token has dropped by 53% since its $2.71 price high on the day the project launched.</p>	
      <h3>Worldcoin was beset with problems after it launched</h3>
    	<p>Since Sam Altman’s Worldcoin launched, data privacy concerns have been raised by regulators in various countries across the world.</p>
      <p>For example, a week after the project went live on the mainnet, the French data protection agency Commission Nationale Informatique & Libertés (CNIL) stated,</p>
      <p><strong>“The legality of this collection seems questionable, as do the conditions for storing biometric data.”</strong></p>
      <p>On 2 August, via a post on Facebook, the Kenyan government announced the suspension of the operations of Worldcoin pending a risk assessment by relevant government agencies.</p>
      <p>These regulatory scrutinies have put downward pressure on the value of its WLD token. Social activity around the token has dwindled in the last month, suggesting that interest in it has dropped significantly.</p>
      <p>According to on-chain data provider Santiment, WLD’s social volume and social dominance have fallen by 95% and 74% in the past 30 days. This indicates a severe drop in the hype around the project.</p>
      <p>With mounting regulatory issues, the daily demand for WLD has also dropped. Since 26 July, the daily account of addresses created to trade WLD has trended downward and has declined by 98%.</p>
      <p>In addition, the count of daily addresses involved in WLD transactions has steadily declined since its price began its descent. For context, as of 25 July, 1673 addresses completed WLD transactions. However, on 25 August, less than 150 addresses transacted the altcoin.</p>
      <h3>Is there a silver lining?</h3>
      <p>Despite the price drop in the last month, a further assessment of WLD’s profitability revealed that its holders have continued to book profits.</p>
      <p>Data from Santiment at press time showed that the ratio of WLD’s on-chain transaction volume profit to loss was 1.998.</p>
      <p>A ratio of 1.998 meant that for every $1.998 of profit made on WLD transactions, there was $1 of loss. This was a positive ratio, which suggested that there were more profits than losses on WLD transactions.</p>
      <p>In other words, the profit generated from daily on-chain transactions was almost twice the number of losses incurred.</p>
      <p>However, while daily traders have found a way to circumvent losses on their daily trades, most WLD holders remain at a loss. According to IntoTheBlock, 98% of WLD token holders were “out of money” at the time of writing.</p>
    </div>
  },
  {
    id: 691,
    title: 'Bitcoin Cash [BCH] could be ready for another pump. Here’s why…',
    category: 'Crypto',
    subCategory: ['BTC', 'BCH', 'Crypto'],
    description: <div className="blog-desc">
      <p>Bitcoin Cash maintained its bullish bias on the higher timeframes with the possibility of another rally on the horizon.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 26, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693052539/ncx-blog-1213_snbphl.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin Cash maintained its bullish bias on the higher timeframes with the possibility of another rally on the horizon.</p>
      <ul>
        <li>BCH hovered over a key support level with the possibility of another rally.</li>
        <li>The rising mean coin age hinted at the network-wide accumulation of BCH.</li>
      </ul>
    	<p>The huge gains enjoyed by Bitcoin Cash [BCH] holders in June almost feel like a distant memory. Since the huge run from $104 to $329, BCH has gone on a corrective dip that has wiped away almost 50% of the June gains.</p>	
    	<p>Despite the deep retracement, the bullish bias remained on the higher timeframes. With the price hovering over a crucial support level, another wild rally could be imminent for BCH.</p>
      <h3>Key support level could unlock massive gains</h3>	
    	<p>Looking back to BCH’s massive pump in June highlighted a key price trend. Price ranged between the $100 – $120 price zone before embarking on the huge rally.</p>
      <p>BCH’s press time price action on the 12-hour timeframe mirrored the June move with the price hovering above the $186 support level. This presents buyers with another opportunity for a significant rally.</p>
      <p>The Moving Average Convergence Divergence (MACD) indicator posted a bullish crossover along with green bars above the zero level to signal bullish intent. Likewise, the Relative Strength Index (RSI) jumped out of the oversold zone with the Chaikin Money Flow (CMF) also pushing strongly to the zero mark after hovering for long in negative territory.</p>
      <p>A bullish push from this level will retain BCH’s bullish sentiment with $250 and $300 attainable price ranges. However, if bears break below this support level, it will signal a shift in momentum with sellers able to target the $130 to $150 price levels.</p>
      <h3>Bulls have been accumulating BCH</h3>
      <p>According to data from Santiment, the 90-day mean coin age has been rising constantly since mid-July. This hinted at a network-wide accumulation of BCH.</p>
      <p>Although the mean coin age was rising swiftly, the funding rate remained largely negative throughout the period. A shift in the funding rate would be essential to bulls flocking into the market and pushing BCH on another price rally.</p>
    </div>
  },
  {
    id: 690,
    title: 'DYDX garners interest from seasoned investors: What’s coming next?',
    category: 'Crypto',
    subCategory: ['DYDX', 'Investors', 'Crypto'],
    description: <div className="blog-desc">
      <p>The native token of decentralized derivative dYdX surged in value, spurred by aggressive accumulation by experienced investors.</p>
    </div>,
    authorName: 'Aniket Verma',
    authorurl: 'https://ambcrypto.com/author/aniket-verma/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 26, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693051664/ncx-blog-1212_cesxx5.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The native token of decentralized derivative dYdX surged in value, spurred by aggressive accumulation by experienced investors.</p>
    	<ul>
        <li>DYDX’s recent rally reversed all losses since the market collapse of last week.</li>
        <li>Withdrawals from exchanges spiked over the last 30 days.</li>
      </ul>
      <p>Decentralized derivative token dYdX [DYDX] was one of the top performers in the last seven days of crypto trading, according to data from CoinMarketCap.  Much of the growth was powered by the overnight rally of 23 August, during which the token jumped 10% to $2.19.</p>	
    	<p>Although the price retreated back to $2.15 at press time, the upsurge reversed all the losses made by the asset since the market collapse of last week.</p>	
    	<h3>Experienced investors grab DYDX</h3>
      <p>The spike in DYDX’s price was led by seasoned investors of the coin. In a statement shared with AMB Crypto, Nansen analyst Martin Lee said that while the count of smart money wallets holding DYDX tokens has stayed flat over the last two months, they have been steadily adding to their stacks.</p>
      <p>For the uninitiated, “smart money” is a term generally used for well-informed and experienced investors, having a better understanding of the market vis à vis retail investors. This cohort has a proven track record of profitability.</p>
      <p>Moreover, the analyst stated that withdrawals from exchanges have spiked over the last 30 days. This could imply that bullish traders were HODLing tokens in anticipation of a price increase.</p>
      <h3>What’s behind the rise?</h3>
      <p>The demand for DYDX was spurred by hype around the impending mainnet launch of the ecosystem’s standalone blockchain dYdX V4. The chain, being developed using the Cosmos [ATOM] framework, was in the testing phase at the time of publication.</p>
      <p>The dYdX foundation recently discussed the prospect of making DYDX the native L1 asset on the new chain. The increased accumulation, therefore, was most likely rooted in this expectation.</p>
      <p>Recall that in the current version V3, trades are settled in an L2 (layer-2) system, which publishes zero-knowledge (ZK) proofs periodically to a base layer Ethereum [ETH]. However, going forward, the network won’t depend on any external blockchain or system.</p>
      <p>The new network also intends to fill the gaps in full decentralization that the existing system has. Every activity, from order book matching to facilitating trades, was expected to take place on-chain.</p>
      <p>At the time of writing, dYdX was the largest perpetual futures protocol, clocking trading volumes of more than $476 milion in the last 24 hours, per data from DeFiLlama.</p>
    </div>
  },
  {
    id: 689,
    title: 'DOT: Recovery elusive after hitting key demand zone',
    category: 'Crypto',
    subCategory: ['DOT', 'Demand', 'Crypto'],
    description: <div className="blog-desc">
      <p>DOT was at a key interest level for bulls. But BTC muted movement could delay a solid recovery for a while.</p>
    </div>,
    authorName: 'Benjamin Njiri',
    authorurl: 'https://ambcrypto.com/author/benjamin-njiri/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 26, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693051366/ncx-blog-1211_mnvcxd.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>DOT was at a key interest level for bulls. But BTC muted movement could delay a solid recovery for a while.</p>
      <ul>
        <li>DOT’s price action graced a crucial demand zone near $4.5.</li>
        <li>Liquidation data discouraged longs, but shorts suffered, too.</li>
      </ul>
    	<p>Polkadot [DOT] was at a significant discount and long-term support level for buying opportunities. But a corrective rebound remained elusive, which could unnerve impatient bulls.</p>
      <p>DOT traded at $4.43 at the time of writing. The level was an extension of its range formation ($4.0 – $4.55) since 18 August. However, the range formation coincided with a crucial long-term support and weekly bullish order block (OB).</p>
      <h3>What’s next after the consolidation?</h3>	
    	<p>The demand zone is a weekly bullish OB of $4.22 – $4.59 (cyan) and doubled up as January and December lows. The Q1 rally bounced from the above level, and a similar trend was observed during the June recovery.</p>
      <p>But another August retest was yet to record a solid corrective rebound from the demand zone. A price consolidation has extended for over a week without signs of a price reversal.</p>
      <p>However, the CMF fluctuated above zero, underscoring weak but positive capital inflows. The RSI also attempted to retreat from the oversold zone, demonstrating mild buying pressure, yet sellers had overall control.</p>
      <p>So, DOT could shoot to $5 or $5.2 in the next few days/weeks, especially if Bitcoin [BTC] surges above $26.8k and $27k. But the bulls must clear the $4.8 to gain an extra edge.</p>
      <p>Conversely, a breach below the demand zone will expose DOT to possible depreciation to the $4.0 level.</p>
      <h3>Longs discouraged</h3>	
    	<p>At the time of writing, long positions were discouraged. According to Coinglass’s liquidation data, over $55k longs were wrecked compared to $20k shorts within 12 hours before publication time.</p>
      <p>The difference between short and long liquidations wasn’t too large, suggesting likely extended range formation. But BTC’s muted movement could delay a solid corrective rebound, so it’s worth tracking its price action.</p>
    </div>
  },
  {
    id: 688,
    title: 'MakerDAO and Uniswap lead on the DeFi front; will UNI and MKR comply?',
    category: 'Crypto',
    subCategory: ['DAO', 'Uniswap', 'MKR'],
    description: <div className="blog-desc">
      <p>In a sea of losses, MakerDAO and Uniswap shine bright. Their resilient holders defy trends, showcasing DeFi’s potential amidst market turmoil.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 26, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693050976/ncx-blog-1210_xzw2eh.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>In a sea of losses, MakerDAO and Uniswap shine bright. Their resilient holders defy trends, showcasing DeFi’s potential amidst market turmoil.</p>
    	<ul>
        <li>Just 52% of MKR holders were holding at a loss, while it is around 72% for UNI.</li>
        <li>Maker and Uniswap are currently trending below the neutral lines on their RSIs.</li>
      </ul>
      <p>Uniswap [UNI] and MakerDAO [MKR] have distinguished themselves from other DeFi tokens by having fewer holders holding at a loss. In a period when most DeFi tokens and other cryptocurrencies are seeing most of their holders incurring losses, this achievement sets these tokens apart.</p>	
    	<h3>Less Maker and Uniswap holders at a loss</h3>
      <p>The crypto market experienced an extended period of decline, leading to a decrease in the value of various assets, including DeFi tokens. This downturn also contributed to a rise in the number of holders experiencing losses, as reported by Into The Block.</p>	
    	<p>However, amidst these circumstances, MakerDAO and Uniswap have emerged as standout performers among DeFi tokens.</p>
      <p>According to Into The Block’s report, MakerDAO [MKR] displayed a figure of approximately 52.3% of its holders in a loss position. Similarly, Uniswap [UNI] secured the second position, with roughly 72% of its holders facing losses.</p>
      <p>To provide context, Compound [COMP] and Ox exhibited higher numbers, with around 93% and 86% of their respective holders incurring losses. On the other hand, Aave recorded 76.3% of its holders dealing with losses.</p>
      <h3>MKR and UNI flash bearish signal</h3>
      <p>Examining MakerDAO on a daily timeframe revealed that it was not exempt from the broader market’s downward price movement. However, the impact appeared relatively moderate; the chart illustrated a trend with a steadier ascent rather than a decline.</p>
      <p>As of this writing, it was exchanging hands within the range of approximately $990 to $1,000. Nevertheless, the trend exhibited a distinct bearish nature, as evidenced by its Relative Strength Index (RSI).</p>
      <p>In contrast, Uniswap exhibited greater price volatility, evident from its daily chart. Its press time trading value hovered around $4.5, declining over 1%. The RSI also pointed toward a bearish trajectory for Uniswap, with its signal being more pronounced since it had slipped below the 30 mark.</p>
      <h3>Maker and Uniswap’s TVL declines</h3>
      <p>A broader perspective on the Total Value Locked (TVL) for MakerDAO and Uniswap revealed a noticeable decline. At the time of this assessment, Maker’s TVL hovered around $5 billion, according to data from DefiLlama. Comparatively, just before 12 August, the TVL exceeded $5.9 billion. However, a steep reduction became evident, leading to the present TVL level.</p>
      <p>Similarly, Uniswap encountered a significant drop in its TVL on 22 August, causing it to plummet to approximately $1 billion, per DefiLlama. Nonetheless, at the present moment, the TVL has rebounded and stabilized at around $3.4 billion.</p>
    </div>
  },
  {
    id: 687,
    title: 'Ethereum staking continues to rise, but an opposing trend emerges',
    category: 'Crypto',
    subCategory: ['Ethereum', 'Staking', 'rise'],
    description: <div className="blog-desc">
      <p>As an increasing portion of Ethereum’s circulating supply finds its home in staking protocols, a silent shift is unfurling, redefining the very essence of ownership.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 25, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693050748/ncx-blog-1209_p67xyj.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>As an increasing portion of Ethereum’s circulating supply finds its home in staking protocols, a silent shift is unfurling, redefining the very essence of ownership.</p>
    	<ul>
        <li>The total staked ETH was almost 28 million.</li>
        <li>Staked ETH currently makes up 21.8% of the total ETH supply.</li>
      </ul>
      <p>Ethereum [ETH] staking has developed a distinct trajectory, separate from the price trends. As more of the circulating supply gets locked up in staking, one particular metric is experiencing a decline.</p>	
      <h3>Ethereum staking on the rise</h3>
      <p>Recent data from CryptoQuant indicated a steady upward trajectory in Ethereum’s total staked value. As of this writing, nearly 28 million ETH were staked, in sharp contrast to the approximately 16 million ETH staked in January. This signified a substantial increase in staking volume.</p>
      <p>According to CryptoMarketCap, the total circulating supply of ETH had surpassed 120 million as of this writing. Additionally, data from Dune Analytics revealed that the staked portion constituted more than 21.8% of the total supply, implying that nearly 22% of the entire ETH supply was staked at press time.</p>
      <p>Considering the daily staking rate, this proportion is anticipated to rise further. However, it’s worth noting that while staking potential was on the rise, there was an opposing trend in the supply of ETH on exchanges.</p>
      <h3>Exchanges see less and less Ethereum</h3>
      <p>In January, the proportion of the entire Ethereum supply held on exchanges exceeded 11%. Nonetheless, this metric has undergone a substantial reduction in recent months.</p>
    	<p>As of this writing, the percentage of the circulating supply residing on exchanges hovered around 8.5%. Also, this shift signified a decrease from the January figure of over 13 million to approximately 10.7 million on exchanges.</p>	
    	<p>Additionally, this reflected a scenario where an increasing number of holders were inclined to stake their ETH. Furthermore, it demonstrated that a smaller proportion opted to retain their holdings on exchanges.</p>
      <h3>ETH volume sees a slight decline</h3>
      <p>The decline in Ethereum’s supply on exchanges has had repercussions on its trading volume, as highlighted by Santiment. The volume chart revealed that while ETH was still experiencing a respectable trading volume, it paled compared to the volume recorded at the start of the year.</p>
      <p>As of this writing, the trading volume was approximately 5 billion. Furthermore, this figure falls several billion short of the average volume exceeding 7 billion observed around January.</p>
    </div>
  },
  {
    id: 686,
    title: 'Solana’s DeFi space shows potential, but what has investors worried?',
    category: 'Crypto',
    subCategory: ['Solana', 'DeFi', 'Investors'],
    description: <div className="blog-desc">
      <p>Solana’s TVL helped the overall DeFi market’s TVL to somewhat hold its ground. SOL, however, was down by more than 5% in the last 24 hours.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 25, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693050401/ncx-blog-1208_nzavpb.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Solana’s TVL helped the overall DeFi market’s TVL to somewhat hold its ground. SOL, however, was down by more than 5% in the last 24 hours.</p>
    	<ul>
        <li>Solana recently announced multiple launches and partnerships.</li>
        <li>Though SOL’s chart was red, a few of the metrics suggested a trend reversal.</li>
      </ul>
      <p>Solana [SOL] DeFi’s Total Value Locked (TVL) has fallen sharply over the past year since being at its lowest since February 2021. However, Solana’s relatively stable TVL has provided some relief to the overall DeFi market.</p>	
    	<h3>Solana’s contribution in the DeFi market</h3>
      <p>The DeFi market TVL has recently fallen to a historic low of $37.78 billion, surpassing even the FTX and Terra episodes. The remaining value likely stemmed from Solana’s TVL post-FTX tumble, combined with retail investors. At press time, SOL had a TVL of over $1.08 billion.</p>
      <p>However, Solana’s DeFi can help move things along with its latest partnerships and launches. The most notable partnership was with Shopify.</p>	
    	<p>Solana Pay’s Shopify integration will allow merchants and entrepreneurs to leverage Web3 payments. This will also allow them to bypass transaction fees and third-party payment processing.</p>
      <p>Another promising launch was the Solana ChatGPT plugin. The newly launched plugin makes it easier to monitor wallet balances, understand transactions, or even explore NFTs.</p>
      <p>On top of that, Membrane Finance brought the first euro stablecoin to Solana. This enables new use cases such as foreign exchange, euro-denominated debit card payments, and blockchain-native value storage.</p>
      <p>All these developments add much value to the offerings and capabilities of the blockchain, which can reflect on paper in the months to follow.</p>
      <h3>Solana’s investors have concerns</h3>
      <p>Though the blockchain boasted potential, SOL’s performance was underwhelming. According to CoinMarketCap, SOL was down by more than 5% in just the last 24 hours. At press time, it was trading at $20.62 with a market capitalization of over $8.4 billion, making it the ninth-largest crypto.</p>
      <p>However, sentiment around the token was high, which was evident from its weighted sentiment and social volume.</p>
      <p>Moreover, a few of the metrics looked bullish on SOL, which could initiate a trend reversal soon. For instance, Solana’s latest price drop was accompanied by a decline in its Open Interest.</p>
      <p>A drop in the metric is generally followed by a change in price trends. Therefore, there is a chance of SOL’s price chart turning green in the short term.</p>
    </div>
  },
  {
    id: 685,
    title: 'ARK Invest and 21Shares apply for Ethereum ETFs',
    category: 'Crypto',
    subCategory: ['ARK', 'Ethereum', 'ETFs'],
    description: <div className="blog-desc">
      <p>ARK Invest and 21Shares have submitted joint applications for Ethereum futures ETFs, coinciding with reports of potential SEC approval.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 25, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693049852/ncx-blog-1207_jyyz28.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
	    <p>ARK Invest and 21Shares have submitted joint applications for Ethereum futures ETFs, coinciding with reports of potential SEC approval.</p>
	    <ul>
        <li>The collaboration showcases an effort to diversify investment options for potential investors in the cryptocurrency space.</li>
        <li>The SEC’s previous rejections have underscored the stringent regulatory environment surrounding crypto-based financial products.</li>
      </ul>
      <p>ARK Invest and 21Shares, prominent investment firms, have submitted joint applications for two Ethereum [ETH] futures exchange-traded funds (ETFs). This move coincided with recent reports indicating that the U.S. Securities and Exchange Commission (SEC) might be on the verge of approving Ethereum ETF applications.</p>	
      <p>The proposed ETFs were detailed in a filing made to the SEC on 24 August. ARKZ is planned to allocate a minimum of 25% of its total assets to cash-settled Ethereum futures contracts. These include those available on the Chicago Mercantile Exchange (CME).</p>
    	<p>Conversely, ARKY will feature both Bitcoin [BTC] and Ethereum futures contracts in its composition. Empowered Funds will serve as the investment advisor for these Ethereum products.</p>
      <p>Notably, ARK Invest and 21Shares had previously collaborated to launch a spot Bitcoin ETF in the year 2021.</p>
      <p>The SEC had previously rejected ARK Invest’s initial two attempts for a Bitcoin spot ETF. The first request was in March 2022, and the other in January. Subsequently, they filed their latest Bitcoin spot ETF application in April, a couple of months ahead of the $10 trillion firm BlackRock’s own application.</p>
      <h3>Ethereum ETF application filed amid…</h3>
      <p>The SEC’s scrutiny led to a significant development wherein several Bitcoin spot ETF applications were deemed inadequate. In response, ARK 21Shares and other applicants incorporated surveillance sharing agreements into their applications as a strategic measure.</p>
      <p>In addition to their recent Ethereum ETF filings, these investment firms are also eagerly awaiting the outcomes of their applications for the “Ark 21Shares Active Bitcoin Futures ETF” (ARKA) and the “Ark 21Shares Active On-Chain Bitcoin Strategy ETF” (ARKC).</p>
      <p>This move is indicative of growing interest within the investment community to tap into the evolving cryptocurrency market. Ethereum has garnered significant attention due to its smart contract capabilities and its role in the decentralized finance (DeFi) ecosystem.</p>
      <p>The partnership comes at a time when financial institutions are increasingly exploring opportunities in the cryptocurrency market.</p>	
    	<p>While the SEC’s previous rejections underscore the stringent regulatory environment, the recent developments signal a potential shift in attitude. The anticipation of Ethereum ETF approvals indicates that regulators are acknowledging the maturation of the cryptocurrency market and its integration into traditional financial frameworks.</p>
    </div>
  },
  {
    id: 684,
    title: 'Did Elon Musk’s latest move influence DOGE, SHIB, and FLOKI? ',
    category: 'Crypto',
    subCategory: ['Elon Musk', 'Doge', 'SHIB'],
    description: <div className="blog-desc">
      <p>Unraveling Musk’s cryptic signals as Dogecoin’s captain, exploring intertwined Shiba Inu and Floki voyages. Did his recent post steer their fates?</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 25, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1693049409/ncx-blog-1206_zipjkz.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Unraveling Musk’s cryptic signals as Dogecoin’s captain, exploring intertwined Shiba Inu and Floki voyages. Did his recent post steer their fates?</p>
    	<ul>
        <li>Elon Musk’s post has, in the past, affected DOGE, SHIB, and FLOKI</li>
        <li>However, despite the recent post, DOGE, SHIB, and FLOKI followed regular market trends.</li>
      </ul>
      <p>Elon Musk’s posts related to Dogecoin [DOGE] are familiar territory, and typically, his remarks influence the trajectory of dog-themed cryptocurrencies. Yet, this influence doesn’t stop at DOGE; it also stretches to similar tokens, such as Shiba Inu [SHIB] and Floki.</p>	
    	<p>Can his recent post about DOGE be credited with initiating any notable shifts in the value of these tokens?</p>
      <h3>Musk on Dogecoin</h3>
      <p>In a recent social media update, Elon Musk, the X, formerly Twitter head, mentioned that X was a DOGE-friendly place, responding to another user’s post. Given Musk’s history of DOGE-related communications, it’s challenging to ascertain whether this was a veiled reference to the DOGE cryptocurrency or simply a regular statement.</p>
      <p>Musk’s considerable influence on DOGE has led to legal actions by some DOGE holders who allege his undue control and manipulation of the token’s price. Additionally, similar dog-themed tokens like Shiba Inu and Floki typically experience the impact of DOGE’s market movements. Was that the case this time?</p>
      <h3>Analyzing the DogeCoin, Shiba Inu, and Floki price trend</h3>
      <p>Examining the daily timeframe charts of Dogecoin, Shiba Inu, and Floki on 23 August revealed a common trend of value appreciation at the close of trading. However, attributing this solely to the “Musk effect” seems implausible, given that most cryptocurrencies ended the day with gains.</p>
      <p>Specifically, Dogecoin rose over 1.6% by the day’s end. Nevertheless, the current scenario indicated a decrease of nearly 1%, with the token’s value hovering around $0.06. Notably, its Relative Strength Index (RSI) plummeted significantly below the neutral threshold of 50, standing at less than 40.</p>
      <p>Turning to SHIB, it demonstrated a more substantial surge on the same day, marking a growth of over 4% in its value. As of this writing, however, it has experienced a decline of approximately 1%. Unlike DOGE, SHIB’s RSI was also positioned beneath the neutral line, indicating its prevailing downtrend.</p>
      <p>FLOKI, too, enjoyed an increase exceeding 2% on 23 August, yet its current state reflected a loss. Also, its RSI underscored a solid bearish trend by residing below 35.</p>
      <h3>Did the DOGE, SHIB, and FLOKI volumes react?</h3>
      <p>Similarly, when examining the trading volume of Dogecoin, Shiba Inu, and Floki, it became evident that there were no notable spikes in activity. According to the volume data available on Santiment, these tokens exhibited consistent volume patterns on 23 August.</p>
      <p>As of this writing, the trading volumes were approximately 233 million, 169 million, and 9 million for DOGE, SHIB, and FLOKI, respectively.</p>	
    </div>
  },
  {
    id: 683,
    title: 'SOL could regain its lost confidence thanks to Solana’s latest integration',
    category: 'Crypto',
    subCategory: ['SOL', 'Solana', 'Crypto'],
    description: <div className="blog-desc">
      <p>Solana Pay’s Shopify integration will allow merchants and entrepreneurs to leverage WEB3 payments. However, SOL’s price action didn’t exactly react in the best way to the news.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 24, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692866878/ncx-blog-1160_y3laqo.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Solana Pay’s Shopify integration will allow merchants and entrepreneurs to leverage WEB3 payments. However, SOL’s price action didn’t exactly react in the best way to the news.</p>
    	<ul>
        <li>Solana Pay finally rolls out on Shopify and it may change payment processing.</li>
        <li>SOL maintains relative strength despite the recent sell pressure.</li>
      </ul>
      <p>Imagine a future where blockchain and cryptocurrencies facilitate payments while shopping. Solana Pay aims to achieve that and it recently made an important stride through its integration with Shopify.</p>	
    	<p>According to Solana’s latest announcement, Solana Pay’s Shopify integration will allow merchants and entrepreneurs to leverage WEB3 payments. This will allow them to bypass transaction fees and third-party payment processing.</p>	
    	<p>Note that any WEB2-based third-party payment processors usually add an extra cost to transactions. The deployment of WEB3-based solutions could thus be a game changer not only for merchants but also for shoppers.</p>
      <p>This acts as an important milestone for the Solana blockchain because it could mark the start of more such integrations to come. If successful, Solana could unlock robust network utility. This could also increase the chances of securing more demand around SOL.</p>
      <h3>Will the development trigger a favorable investor sentiment?</h3>
      <p>News of Solana Pay’s integration with Shopify has the potential to influence market sentiment. This is because the development ushers in the potential for real-world use cases.</p>
      <p>We observed a surge in Solana’s social dominance in the last 24 hours at press time. This suggested that the market reacted to the news.</p>
      <p>The social dominance was still low compared to its largest spikes. However, the weighted sentiment achieved a more noteworthy uptick in the last three days. In other words, investors received a confidence boost which correlates with recent market lows.</p>
      <p>Solana’s native cryptocurrency SOL recently dipped to a $20.31 low during Tuesday’s (22 August) trading session. It rallied by almost 5% in the last 24 hours which suggested that the market reacted favorably to the Solana Pay and Shopify integration.</p>
      <p>Most of the top cryptocurrencies by marketcap were deeply oversold after the recent crash. However, SOL maintained some relative strength which helped it avoid the oversold zone.</p>
      <p>We also observed that SOL maintained a healthy premium from its June lows. In contrast, some of the top cryptos such as Bitcoin came close to their June lows while ETH fell below its lowest price in June.</p>
      <p>SOL was partly able to maintain some relative strength courtesy of healthy demand activity. Its volume metric registered a net gain since 21 August.</p>
      <p>While the volume spike suggested the return of demand in line with the recent price action, SOL’s future remains uncertain. An erosion of the low market confidence could send prices back on a bearish trajectory.</p>
    </div>
  },
  {
    id: 682,
    title: 'BNB: All fingers point to CZ as Binance stays atop market minds',
    category: 'Crypto',
    subCategory: ['BNB', 'CZ', 'Binance'],
    description: <div className="blog-desc">
      <p>The silence of CZ has caused the crypto community to keep pondering if anything was wrong with the exchange and its effect on BNB. What’s the latest?</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 24, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692866701/ncx-blog-1159_bm29gf.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The silence of CZ has caused the crypto community to keep pondering if anything was wrong with the exchange and its effect on BNB. What’s the latest?</p>
    	<ul>
        <li>BNB and Binance continue to be among the top discussions around the crypto community.</li>
        <li>CZ has not yet addressed recent allegations, as more criticism trooped in.</li>
      </ul>
      <p>Both Binance and its native coin Binance Coin [BNB] have rarely had a week off without controversy. While the exchange and coin remain at the forefront of the crypto market’s attention, the spotlight isn’t exclusively positive.</p>	
    	<h3>On the wrong side of the divide</h3>
      <p>Due to these issues, Binance and BNB became the first and third fastest-rising subjects in the market. According to Santiment, one cogent reason BNB was in most of the discussion was because of the concerns about support for its price action.</p>
      <p>Lately, Binance has come under intense scrutiny for defending the BNB value by selling its Bitcoin [BTC] holdings. And in turn, creating demand for the exchange coin. Also, the recent suspension of SEPA payouts by Binance has raised questions about regulatory compliance and its reputation.</p>
      <p>But all hands from major players in the ecosystem point to one person— Chanpeng Zhao (CZ), the outspoken CEO of Binance. AMBCrypto had previously reported how a number of analysts have accused CZ of manipulating the market all in his bid to “save BNB.”</p>
      <p>While there has been no concrete proof of the allegations, CZ, in an unusual fashion, has not responded publicly to the matter. The Binance CEO is an active user of X (formerly Twitter).</p>
      <p>But an assessment of his posts and replies on the social media platform revealed that CZ has done all he can to evade responding to the accusations. However, this lack of clarity has affected BNB in other places.</p>
      <h3>The bashing hasn’t stopped</h3>
      <p>For instance, BNB’s weighted sentiment tanked on 18 August such that it reached lows that had never been experienced before. The weighted sentiment measures the unique social volume around an asset while indicating the acumen around the cryptocurrency.</p>
      <p>At press time, BNB’s weighted sentiment was -3.578. This suggests that the broader market was wary of interacting or transacting the coin.</p>
      <p>While CZ continues to remain quiet despite the criticism, other analysts have not ceased condemning the Binance CEO. The latest fault-finding has come from renowned trader Peter Brandt.</p>
      <p>Brandt, who was responding to a Mike Alfred lambast of CZ, said Binance and its CEO were a “global scam.” He also did not leave BNB out of the picture, calling the coin a “cheap fiat.”</p>
      <p>Meanwhile, the Open Interest in BNB has been increasing. Open interest is the total number of outstanding derivative contracts for an asset that has not been settled.</p>	
    	<p>Therefore, the hike in the metric suggests that traders were pumping liquidity into BNB contracts.</p>
    </div>
  },
  {
    id: 681,
    title: 'Base’s TPS surpasses Ethereum, Optimism and Arbitrum lag',
    category: 'Crypto',
    subCategory: ['TPS', 'Ethereum', 'Arbitrum'],
    description: <div className="blog-desc">
      <p>Base’s Transactions Per Second surpassed that of Ethereum on 22 August. Arbitrum and Optimism also had to give way for the Coinbase L2. What drove this flip?</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 23, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692866109/ncx-blog-1158_jqv4wm.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Base’s Transactions Per Second surpassed that of Ethereum on 22 August. Arbitrum and Optimism also had to give way for the Coinbase L2. What drove this flip?</p>
    	<ul>
        <li>friend.tech, a new application on Base, ensured that the L2 transaction outpaced others in the Ethereum ecosystem.</li>
        <li>Base’s TVL surged, as the amount bridged exceeded $253 million.</li>
      </ul>
      <p>As it stands, the reign of many Ethereum [ETH] L2s has been put on hold, making way for Coinbase’s L2, Base, to take the mantle. And it’s not just that. The Ethereum Mainnet may have also stepped back for Base to take the crown of increased activity and traction.</p>	
    	<p>How has this come to be?</p>
      <h3>Friends help Base trump the king</h3>	
    	<p>Not to beat around the bush, Base’s Transactions Per Second (TPS) surpassed that of Ethereum on 22 August, according to L2 Beat. As per the chart below, Ethereum’s TPS was 12.05 while Base was 15.88.</p>
      <p>The data revealed that Base’s TPS had increased by 73.56% in the last 24 hours. Ethereum, on the other hand, decreased by 1.47% within the same timeframe. However, there was one particular reason that Base was able to achieve the milestone and its name— friend.tech.</p>
      <p>friend.tech is a mobile application built on Coinbase L2. While it prides itself as the “marketplace for your friends,” friend.tech provides an avenue for users to tokenize their identity. And, in turn, can sell shares of themselves to their followers or anyone interested in their community.</p>
      <p>Despite being launched only recently, friend.tech has experienced rapid growth and adoption. According to Dune Analytics, the application has amassed 97,316 unique traders. These traders have been involved in over one million transactions, valued at 39.195 ETH.</p>
      <p>But besides Ethereum, Base has also pegged back the dominance of Arbitrum [ARB] and Optimism [OP]. Previously, AMBCrypto had reported how Base’s emergence had disrupted the L2 flow.</p>
      <p>The daily transactions reflect the usage rate of the network on a day-to-day basis. Therefore, the hike in transactions suggested optimism around Base. If it stays the same or increases further, the project might completely overtake its predecessors.</p>
      <p>Moreover, the integration of projects like Compound [COMP] and Aave [AAVE] also ensured that the “on-chain summer” leader attracted more users. Now, the shift has extended to transactions.</p>
      <h3>L2 standings have changed</h3>
      <p>According to Delphi Digital, daily transactions on Base have outstripped those of Optimism and Arbitrum combined.</p>
      <p>At the time of writing, over $253 million had been bridged on Base. Based on Dune’s data, ETH led the cohort of assets that have appeared on the network. This was followed by stablecoins Tether [USDT] and DAI.</p>
      <p>Also, Base’s Total Value Locked (TVL) has risen to $185.32 million, DefiLlama revealed. The surge in TVL implies that participants have come to trust Base despite a series of scams appearing on the network before its public Mainnet launch.</p>
    </div>
  },
  {
    id: 680,
    title: 'This is how RUNE reacted to THORChain’s latest launch ',
    category: 'Crypto',
    subCategory: ['RUNE', 'THORChain', 'launch'],
    description: <div className="blog-desc">
      <p>THORChain’s lending protocol went live on 21 August while RUNE’s price continued its bull rally. RUNE’s RSI was in an overbought zone, which was bearish.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 23, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692864149/ncx-blog-1157_fjn5ix.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>THORChain’s lending protocol went live on 21 August while RUNE’s price continued its bull rally. RUNE’s RSI was in an overbought zone, which was bearish.</p>
    	<ul>
        <li>THORChain’s launch allows users to lend their native Layer 1 assets, like BTC and ETH, to borrow TOR.</li>
        <li>After a short decline, RUNE’s price regained upward momentum.</li>
      </ul>
      <p>THORChain [RUNE] recently decoupled from the market as it enjoyed a comfortable bull rally over the past two weeks. Investors were actually expecting the token to rally further, as it was awaiting a major launch.</p>	
    	<p>However, that was not the case, as the price witnessed a correction.</p>	
      <h3>THORChain’s lending protocol is live</h3>
      <p>On 21 August, THORChain announced that its lending protocol went live. With the launch, users could lend their native Layer-1 assets, like Bitcoin [BTC] and Ethereum [ETH], to THORChain and borrow a USD-denominated debt.</p>
      <p>The new debt denominator is named TOR, and debt can be repaid in any THORChain-supported asset, including stablecoins.</p>
      <p>The release made THORChain a hot topic of discussion, as evident from the rise in its social volume. However, its weighted sentiment dropped during that period.</p>
      <h3>RUNE’s response was surprising</h3>
    	<p>Though the launch had the potential to fuel RUNE’s ongoing rally, the ground reality turned out to be different. As reported earlier, there was a chance of a price correction. This turned out to be true, as on 21 August, RUNE’s price declined for a couple of hours.</p>
      <p>Nonetheless, the token later stabilized and again gained upward momentum.</p>
      <p>According to CoinMarketCap, RUNE’s price has increased by more than 5% in the last 24 hours. At press time, it was trading at $1.68 with a market capitalization of over $572 million.</p>
      <p>In another spot of good news, RUNE’s Open Interest increased while its price went up. A hike in this metric suggested that the press time price trend might continue longer.</p>
      <p>However, CryptoQuant’s data revealed that THORChain’s Relative Strength Index (RSI) was overbought, which was bearish. On top of that, both the token’s Chaikin Money Flow (CMF) and On Balance Volume (OBV) registered downticks. This could prevent the token’s price from going up any further.</p>
      <p>But, the Exponential Moving Average (EMA) Ribbon revealed a clear bullish upper hand in the market, as the 20-day EMA was above the 55-day EMA.</p>
    </div>
  },
  {
    id: 679,
    title: 'Cardano’s development activity impresses despite…',
    category: 'Crypto',
    subCategory: ['Cardano', 'Development', 'crypto'],
    description: <div className="blog-desc">
      <p>Despite ADA’s vibrant on-chain vitality, price struggles persist, painting a complex portrait of innovation and challenge.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 23, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692863808/ncx-blog-1156_hopddf.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Despite ADA’s vibrant on-chain vitality, price struggles persist, painting a complex portrait of innovation and challenge.</p>
      <ul>
        <li>Cardano ranked as the top chain with the most development activity.</li>
        <li>Cardano’s TVL and stablecoin market cap were also on the rise.</li>
      </ul>
    	<p>In 2023, Cardano [ADA] has consistently been ranked as the second-highest blockchain in development activity. However, it has overtaken the leading position in the past 30 days, becoming the blockchain with the most significant development activity.</p>	
    	<h3>Cardano leads in dev activity</h3>
      <p>Recent data from Santiment revealed that Cardano has successfully surpassed Polkadot [DOT] and Kusama, taking the lead as the blockchain with the highest development activity. Historically, Cardano had held the second position, but in the last 30 days, it showcased an impressive count of more than 500 development activities.</p>	
    	<p>Polkadot and Kusama recorded around 493 combined during the same period.</p>
      <p>Moreover, as of this writing, the pace of development activity on Cardano has not shown signs of slowing down. According to Santiment’s data, the activity score exceeded 84 at press time, with a noticeable upward trend on the chart.</p>
      <p>While there was a decline in development activity during the previous week, it rebounded and regained momentum by the time of this report.</p>
      <h3>Other Cardano metrics worth noting</h3>
      <p>Cardano’s positive momentum was not confined to its development activity; its Total Value Locked (TVL) and stablecoin market cap also demonstrated growth, as evidenced by DefiLlama.</p>
      <p>After reaching its peak in 2022, the TVL experienced a gradual decline throughout that year. However, the current year presented a different picture, as the chain’s TVL was on an upward trajectory. As of this writing, the TVL had surpassed $161 million.</p>
      <p>In addition to the TVL increase, the stablecoin market cap on the Cardano chain has been experiencing noteworthy growth. The DefiLlama chart indicated that the stablecoin market cap’s uptrend had been more pronounced than the TVL.</p>
      <p>As of this writing, the stablecoin market cap has exceeded $15 million. This growth in the stablecoin market cap indicated the organic expansion that the Cardano chain was undergoing.</p>
      <h3>ADA yet to catch up on-chain positives</h3>
      <p>Examining ADA on a daily timeframe revealed a disparity between its on-chain activities and its price performance. As of this writing, ADA’s price had retreated to the $0.2 range, following an earlier surge to the $0.4 range earlier in the year.</p>
      <p>Trading at approximately $0.26 at press time, it experienced a decline of over 1%. Also, the price trend appeared lackluster, as evident from its position below its long and short Moving Averages (blue and yellow lines).</p>
      <p>Furthermore, ADA’s price trajectory had positioned it near the oversold zone, a consequence of its trend, as indicated by the Relative Strength Index (RSI). This confluence of factors suggested a less-than-favorable outlook for ADA’s price movement.</p>
    </div>
  },
  {
    id: 678,
    title: 'Shiba Inu’s burn rate spikes to over 1000%; will SHIB switch sides?',
    category: 'Crypto',
    subCategory: ['Shiba', 'SHIB', 'Crypto'],
    description: <div className="blog-desc">
      <p>SHIB’s recent journey resembles a roller coaster, plunging since 13 August. A flicker of recovery is seen but overshadowed by earlier losses as the bearish RSI at 40 lingers.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 23, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692863450/ncx-blog-1155_o7mkvd.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>SHIB’s recent journey resembles a roller coaster, plunging since 13 August. A flicker of recovery is seen but overshadowed by earlier losses as the bearish RSI at 40 lingers.</p>
    	<ul>
        <li>Over 1 billion SHIB has been burned in the last 24 hours.</li>
        <li>SHIB has been on a downtrend, and its bearish trend has continued.</li>
      </ul>
      <p>Shiba Inu recently experienced an unusually high burn rate, which hadn’t been observed for some time. This burn rate coincided with ongoing discussions and excitement about the Shibarium, driven by recent developments.</p>	
	    <p>The question arises: Did SHIB’s trade volume suddenly increase, or was the burn rate an independent occurrence?</p>
      <h3>Shiba Inu’s burn rate soars</h3>
      <p>Based on the latest information from Shiburn, the burn rate of Shiba Inu has noticeably escalated beyond its recent patterns. The data indicates that over 1 billion tokens were burned within the last 24 hours alone.</p>
      <p>Furthermore, the chart illustrated a staggering surge of over 1,000% in the burn rate during this same 24-hour period. Also, this recent increase in burn rate has contributed to a cumulative burn of over 2 billion tokens in the past week. This was a substantial and noteworthy development.</p>
      <h3>SHIB volume remains the same</h3>	
      <p>Examining the trading volume using data from Santiment revealed that, despite the heightened burn rate, there was either minimal or no notable change in the trading volume. The most substantial trading volume Shiba Inu experienced was back in July, with a peak of over 23 billion.</p>
      <p>However, trading activities have remained subdued since then, hovering around 108 million. This suggested that a corresponding increase in SHIB trading did not instigate the recent surge in burn rate.</p>
      <h3>Shiba Inu Shibarium’s relaunch date is yet to be known</h3>
	    <p>As of 19 August, Shytoshi Kusama, the lead developer of Shiba Inu, communicated to the Shiba community that efforts were actively continuing on the Shibarium project. Additionally, this update followed the initial launch, which encountered difficulties leading to a delay in block production for several hours.</p>
      <p>Subsequently, on 22 August, another blog post from the lead developer reported the successful optimization of Shibarium. The blog post highlighted other advancements and noted that testing was ongoing for the L2 platform.</p>
      <p>While no specific relaunch date has been provided, the absence of such information doesn’t appear to have dampened the enthusiasm within the community.</p>
      <h3>SHIB remains bearish</h3>
      <p>Over the recent days, Shiba Inu [SHIB] experienced a notable downward trajectory, clearly illustrated by its daily timeframe chart. Starting from 13 August, the chart has depicted a consistent decline, punctuated by occasional pauses.</p>
      <p>As of this writing, SHIB was trading with a marginal gain, albeit under 1%, which appeared more as a slight rebound from the substantial losses it had encountered. Furthermore, the Relative Strength Index (RSI) for SHIB stood at 40, underscoring an ongoing bearish trend.</p>
    </div>
  },
  {
    id: 677,
    title: 'Starknet releases new update; will it drive adoption? ',
    category: 'Crypto',
    subCategory: ['Starknet', 'Crypto', 'Update'],
    description: <div className="blog-desc">
      <p>As a part of Starknet Quantum Leap, the rollup launched v0.12.1 on Mainnet, adding new features to improve efficiency </p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 22, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692862501/ncx-blog-1154_stzjko.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>As a part of Starknet Quantum Leap, the rollup launched v0.12.1 on Mainnet, adding new features to improve efficiency </p>
      <ul>
        <li>Starknet, in one instance, outperformed zkSync Era in terms of active addresses.</li>
        <li>The rollup was faring well against popular L2s like Optimism and Arbitrum.</li>
      </ul>
    	<p>Like most rollups and L2s, Starknet’s popularity has also been on the rise for several weeks. It launched its latest network update, Starknet v0.12.1, on 21 August, which added new features to the rollup.</p>	
    	<p>As revealed earlier, the upgrade improves the efficiency of the sequencer capacity by addressing the issue of failed transactions. While Starknet pushed its network update, other rollups like Polygon zkEVM and zkSync Era also continued to grow.</p>	
    	<h3>Starknet gets a network update</h3>
      <p>As a part of the Starknet Quantum Leap, the rollup launched v0.12.1 on Mainnet. The update aims to improve transaction efficiency by making quite a few changes. The upgrade adds a validation stage before sequencing to filter out invalid transactions earlier.</p>
      <p>Failed transactions will have a reverted status and be included in the block, paying fees. Other notable features include mempool validation and Keccak built-in.</p>
      <p>While the update was pushed, Starknet’s TVL, after a decline, gained upward momentum. However, after registering spikes on 19 August, both its active addresses and daily transactions sank.</p>
      <h3>Other rollups are buckling up</h3>
      <p>Not only Starknet, but zkEVM also reached a new milestone. The rollup’s total deposits exceeded the $115 million mark. Not only that, but Polygonscan’s data revealed that zkEVM’s total unique addresses also crossed 400k a few days after the rollup completed five months of its deployment.</p>
      <p>zkSync Era’s performance was no less, as it outperformed Ethereum [ETH], Arbitrum One, Optimism [OP], and other rollups in a key metric. The rollup recently mentioned in a tweet that its transactions per second reached a new all-time high of 13.44 on 20 August 2023.</p>
      <p>Considering the rollups’ updates, it was important to check how they were faring against each other. As per Artemis, zkSync Era continued to dominate zkEVM and Starknet in all key metrics. However, Starknet managed to outperform zkSync in terms of active addresses on 28 July 2023.</p>
      <p>Recently, the rollup also flipped zkEVM in terms of TVL, reflecting Starknet’s growth. Interestingly, Starknet fared well against top L2s like Optimism and Arbitrum. This was evident from the daily active addresses graph, as they were closely knit.</p>
    </div>
  },
  {
    id: 676,
    title: 'What to expect as Avalanche prepares for another token unlock',
    category: 'Crypto',
    subCategory: ['Avalanche', 'Token', 'crypto'],
    description: <div className="blog-desc">
      <p>Amidst price challenges, Avalanche readies for a significant unlock event. Despite recent declines, the unlock introduces intrigue and potential impact.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 22, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692862011/ncx-blog-1153_yrp2uz.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Amidst price challenges, Avalanche readies for a significant unlock event. Despite recent declines, the unlock introduces intrigue and potential impact.</p>
    	<ul>
        <li>More AVAX, representing over 2% of the current supply, will be unlocked on 26 August.</li>
        <li>AVAX has continued to decline alongside its market cap.</li>
      </ul>
      <p>Avalanche [AVAX] has faced a challenging period recently, with its price experiencing a noteworthy decrease. Despite this decline, the planned unlock event is still on track and is set to occur later this week. This event will result in the release of additional AVAX into circulation.</p>	
    	<h3>The Avalanche unlocks</h3>
      <p>Based on information collected from Token Unlocks, Avalanche is scheduled to undergo an unlock event later this week. The data indicated that a total of 9.54 million AVAX, equivalent to a value exceeding $100 million, would be unlocked on 26 August.</p>	
    	<p>The details from Token Unlocks revealed that these unlocked AVAX tokens would be allocated among the Foundation, partners, and the team, with a portion also designated for airdrops.</p>
      <p>About 53% of the maximum supply has been unlocked, and the forthcoming unlock event accounts for 2.77% of the circulating supply. However, it was worth noting that there has been a recent decrease in the market capitalization of the circulating supply.</p>
      <h3>Avalanche market cap declines as unlock approaches</h3>
      <p>As of this writing, Avalanche’s circulating supply was more than 344 million tokens, with a market capitalization surpassing $3.6 billion, as reported by CoinMarketCap.</p>
      <p>Nevertheless, the visual representation illustrated a nearly $1 billion decline in market capitalization for AVAX during August alone. As of this writing, the token had experienced an almost 2% decrease in value.</p>
      <p>This decline could be attributed to the continuous drop in price that Avalanche has encountered over the preceding weeks.</p>
      <h3>AVAX maintains a downward trend</h3>
      <p>Examining the daily timeframe chart of Avalanche revealed that the decline in its price was initiated in July, although the most substantial drop occurred during August. As of this writing, the token was experiencing an almost 3% decrease, with a trading value hovering around $10.5.</p>
      <p>This downward price trend was evident in the Relative Strength Index (RSI) and the Moving Average (MA). The RSI was recorded below 30. This indicates an oversold condition.</p>
      <p>Additionally, the price was below its short-term and long-term Moving Averages, represented by the yellow and blue lines.</p>
      <p>While the impending unlock event might raise concerns given Avalanche’s current trajectory, its impact should not be significantly pronounced. However, if any effects are to manifest, they are unlikely to be immediate.</p>
    </div>
  },
  {
    id: 675,
    title: 'What Monero’s northbound momentum tells you',
    category: 'Crypto',
    subCategory: ['Monero', 'northbound', 'momentum'],
    description: <div className="blog-desc">
      <p>Monero’s performance on the social front was commendable, while its price rallied by 2%, suggesting a continued bull run.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 21, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692622722/ncx-blog-1133_aocfqf.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Monero’s performance on the social front was commendable, while its price rallied by 2%, suggesting a continued bull run.</p>
      <ul>
        <li>Monero’s price moved northward one week after the blockchain released its latest network update.</li>
        <li>XMR’s trading volume also increased, and a few market indicators looked bullish.</li>
      </ul>
    	<p>Monero [XMR] witnessed a considerable increase in its social activity in the last 24 hours. The uptrend was not only limited to its performance on the social front, but it was also reflected on the coin’s price chart. This happened a week after the blockchain released its last network upgrade, making Monero more stable. However, will a hike in social activity be enough for the blockchain to maintain its price trajectory?</p>	
    	<h3>Monero is getting back on track</h3>
      <p>CryptoDep, a popular X account that posts updates related to cryptos, recently revealed in a tweet that XMR’s social engagement reached 625 thousand in the last 24 hours on 20 August. Not only that, but the coin also had an Altrank of 12, which was a bullish signal.</p>
      <p>The growth in popularity was reflected well on the coin’s price chart, as its value increased by more than 2% in the last 24 hours. According to CoinMarketCap, at press time, Monero was trading at $147.63 with a market capitalization of $2.7 billion. Additionally, its one-day trading volume also shot up by 30%, acting as a foundation for the price hike.</p>
      <p>Apart from popularity, another possible reason behind this uptrend could be the last network update that the blockchain pushed on 13 August 2023. The update brought along several new features, such as an upgrade to its Bulletproof algorithm to make it Bulletproof+.</p>	
    	<p>This reduced typical transaction size by nearly 5-7 % and also made transactions lighter and faster while improving typical verification performance by 5-7%.</p>
      <p>However, despite the recent hike in price, the blockchain’s mining sector witnessed a decline. This was evident from the drop in XMR’s hashrate, suggesting an outflow of miners since July 2023.</p>
      <h3>Should Monero investors have high hopes?</h3>
      <p>Though the price trend looked promising, a few of the metrics were concerning. For instance, XMR’s funding rate turned red. This meant that derivatives investors were reluctant to buy XMR at a higher price. Additionally, its open interest also dropped slightly, which was a negative signal.</p>
      <p>Nonetheless, a few of the market indicators remained bullish. For instance, after entering the oversold zone, the Relative Strength Index (RSI) gained upward momentum, which can help push XMR’s price further up.</p>
      <p>Additionally, its MACD also displayed the possibility of a bullish crossover in the days to follow. Unfortunately, the Chaikin Money Flow (CMF) continued to favor the bears as it remained considerably below the neutral mark.</p>
    </div>
  },
  {
    id: 674,
    title: 'Why now is the best time to invest in altcoins',
    category: 'Crypto',
    subCategory: ['altcoin', 'invest', 'crypto'],
    description: <div className="blog-desc">
      <p>While fear among investors was dominant in the market, a few of the altcoins’ metrics turned bullish, suggesting a possible bull rally.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 21, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692622376/ncx-blog-1132_wsjtww.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>While fear among investors was dominant in the market, a few of the altcoins’ metrics turned bullish, suggesting a possible bull rally.</p>
    	<ul>
        <li>Investors continued to accumulate more ETH as its supply outside of exchanges increased.</li>
        <li>XRP was up by more than 4% in the last 24 hours, and a few metrics were bullish.</li>
      </ul>
      <p>Over the last few days, the crypto market has witnessed a massive downfall. This caused most cryptos to shed their values by nearly double digits, including Ripple [XRP] and Ethereum [ETH]. While the market remained under bears’ influence, it has opened the right opportunity for investors to increase their accumulation.</p>	
    	<p>Interestingly, the latest data suggested that most altcoins were in the underbought zone, which can cause an uptick in buying pressure, in turn, changing the market condition around.</p>
      <h3>A massive price correction</h3>	
      <p>Though there might have been multiple factors at play that caused the entire market to turn red, a major reason might have been SpaceX’s dump of Bitcoin [BTC] worth millions of dollars. Thanks to this, Ethereum went under the $1,600 mark for a short while.</p>
      <p>However, Santiment’s latest tweet revealed that several altcoins went into the underbought zone over the last few days. Not only were altcoins underbought, but the overall trading sentiment remained low.</p>
      <p>As per CoinMarketCap, the 24-hour trading volume dropped by more than 30%, reflecting investors’ reluctance to actively trade. However, while the market remains sluggish, it might be the right opportunity for investors to accumulate more before the market turns bullish. In fact, investors were already accumulating the king of altcoins.</p>
      <p>As per Santiment, ETH’s supply on exchanges dropped substantially while its supply outside of exchanges increased. Additionally, whales’ confidence in the king of altcoins was high as the supply held by top addresses went up.</p>
      <h3>Are Altcoins entering a bull rally?</h3>
      <p>Surprisingly, while ETH’s price was still slow-moving, XRP, on the other hand, showed high volatility. According to CoinMarketCap, the altcoin was up by more than 4% in the last 24 hours, which was remarkable, considering the current market condition.</p>
      <p>At press time, it was trading at $0.5288 with a market cap of over $27.9 billion. The recent price uptick helped XRP increase its MVRV Ratio slightly. Additionally, positive sentiment around the token was relatively high.</p>
      <p>Not only that, but ETH’s Relative Strength Index (RSI) was in an oversold zone, which is a bullish development. Moreover, CryptoQuant’s data revealed that ETH’s exchange reserve was declining, suggesting that buying pressure was increasing.</p>
      <p>This happened while the market’s Fear and Greed index had a value of 34. When the metric’s value remains low, the chances of a price uptick increase. Therefore, investors might consider this a good opportunity to increase their accumulation to enjoy profits during the next bull run.</p>
    	<p>However, taking into account the unpredictable nature of the crypto market, the possibility of a further downtrend can’t be ruled out.</p>
    </div>
  },
  {
    id: 673,
    title: 'Solana vs Dogecoin: What to expect as the race heats up',
    category: 'Crypto',
    subCategory: ['Solana', 'Dogecoin', 'Race'],
    description: <div className="blog-desc">
      <p>Solana and Dogecoin’s market caps remained close to each other with only a difference of a few thousand dollars.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 21, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692621947/ncx-blog-1131_ndmccb.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Solana and Dogecoin’s market caps remained close to each other with only a difference of a few thousand dollars.</p>
      <ul>
        <li>Dogecoin flipped Solana on 18 August to reclaim its 8th spot on the list of top cryptos.</li>
        <li>Though a few metrics were bullish on SOL, market indicators suggested otherwise.</li>
      </ul>
    	<p>Over the last week,the market capitalizations of Dogecoin [DOGE] and Solana [SOL] were closely knit. This allowed both tokens to flip each other on multiple occasions. A few days ago, SOL flipped DOGE to become the eighth largest crypto. However, the good days did not last long, as DOGE quickly reclaimed its spot. The reason behind this episode was revealed upon a look at their metrics and daily charts.</p>	
    	<h3>Solana and Dogecoin are neck-to-neck</h3>
      <p>A look at both the cryptos’ market caps revealed that there were several instances when both flipped each other over the last week. Though the difference in their prices was massive, their market caps remained close to each other, with only a slight difference of a few thousand dollars.</p>
      <p>According to CoinMakretCap, at the time of writing, Solana had a market cap of $8.828 billion, while Dogecoin’s market cap was $9 billion, giving it the edge.</p>
      <p>A look at the market sentiment around both tokens clearly pointed out that DOGE was the leader. This was evident from the drop in SOL’s weighted sentiment, while the same metric for DOGE remained high.</p>
      <p>Dogecoin last flipped Solana on 18 August to claim its spot. The 4-hour chart of DOGE showed what helped the memecoin.</p>
      <p>During that time frame, DOGE’s MACD displayed a bullish crossover. Its Money Flow Index (MFI) also registered a sharp uptick, helping the coin to marginally increase its value.</p>
      <h3>Will Solana be able to beat DOGE?</h3>	
    	<p>While DOGE’s price went up by more than 1% in the last 24 hours, SOL only managed to push its price up by 0.18%. Nonetheless, a few of the metrics were bullish on SOL, suggesting a further price uptick in the days to follow.</p>
      <p>As per Santiment’s chart, SOL’s open interest dropped substantially. A decline in the metric usually means that the on-going price trend might end soon. Moreover, Solana’s DyDx and Binance funding rates were red, further increasing the chances of a trend reversal.</p>
      <p>However, a look at SOL’s daily chart painted a bearish picture. The distance between the 20-day Exponential Moving Average (EMA) and the 55-day EMA was shrinking, indicating a possible bearish crossover soon.</p>
      <p>The Relative Strength Index (RSI) was also resting below the neutral mark. This meant that the chances of SOL going ahead of DOGE in the near term were unlikely. Nonetheless, the Chaikin Money Flow (CMF) registered an uptick, which was a bullish development. </p>
    </div>
  },
  {
    id: 672,
    title: 'Bitcoin: Analyzing how the next halving might affect BTC',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'BTC', 'halving'],
    description: <div className="blog-desc">
      <p>Bitcoin’s value was worth only about 0.2 of what it should ideally be as per the stock-to-flow model, suggesting room for exponential growth.</p>
    </div>,
    authorName: 'Aniket Verma',
    authorurl: 'https://ambcrypto.com/author/aniket-verma/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 21, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692621094/ncx-blog-1130_ndybef.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin’s value was worth only about 0.2 of what it should ideally be as per the stock-to-flow model, suggesting room for exponential growth.</p>
    	<ul>
        <li>As per stock-to-flow deflection, Bitcoin was undervalued in relation to its scarcity.</li>
        <li>Long-term holders had access to 75% of Bitcoin’s circulating supply.</li>
      </ul>
      <p>The impact of Bitcoin’s [BTC] scarcity on its long-term value has been one of the most widely discussed and analyzed topics within the crypto community. Over the years, experts have developed several models that provide insights into the relation between the two and help investors make informed decisions.</p>	
    	<p>According to a recent post by on-chain analytics firm Glassnode, BTC’s stock-to-flow (S/F) deflection dipped to a 1-month low, suggesting more room for bull cycles in the near future.</p>	
    	<h3>Bitcoin’s scarcity to drive its value</h3>
      <p>In layman terms, the S/F deflection determines whether an asset is overvalued or undervalued in relation to its scarcity. In the current situation, the deflection was less than 1 and as indicated above, deeper in the green undervalued territory.</p>
      <p>The S/F deflection is derived by dividing Bitcoin’s price by one of its most popular models, the S/F ratio. Created by anonymous analyst PlanB, the S/F ratio compares the current stock of Bitcoin to the number of new Bitcoins mined each year.</p>
      <p>The narrative which underpins this model is that the value of an asset is directly proportional to its scarcity. The higher the ratio, the scarcer the asset becomes, and in turn drives the price.</p>
      <p>The model states that the halving events that occur roughly every four years — when the rate of new coins mined is halved— directly affect the price of Bitcoin. Data from Glassnode further proved this. Note how BTC’s price remained subdued in the days leading to the halving. However, on completion, it exploded to new highs.</p>
      <p>At the time of writing, BTC was worth only about 0.2 of what it should ideally be as per the S/F model. With the next halving event scheduled for April 2024, there was a chance that BTC would reach its full potential.</p>
      <h3>Diamond hands stocking up</h3>
      <p>The halving-induced bullish expectations spurred long-term Bitcoin holders (LTH) to stock up for the big day. At the time of writing, seasoned investors of the king coin accounted for 75% of all tokens in circulation.</p>
      <p>BTC crawled back above the $26,000-mark on 19 August, as the stormy week drew to a close. It exchanged hands at $26,108 at the time of writing, per CoinMarketCap.</p>
    </div>
  },
  {
    id: 671,
    title: 'A look at Bitcoin in light of the just concluded bearish week',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'bearish', 'week'],
    description: <div className="blog-desc">
      <p>The whale selloff has leveled out for now. As such, Bitcoin may enjoy some relief from more downside. On the other hand, the same whale category did not register a sharp bounce.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 20, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692620949/ncx-blog-1129_myidnq.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The whale selloff has leveled out for now. As such, Bitcoin may enjoy some relief from more downside. On the other hand, the same whale category did not register a sharp bounce.</p>
    	<ul>
        <li>A look at what recent on-chain findings could signal in terms of Bitcoin’s next move.</li>
        <li>Bitcoin sell pressure slowed down paving the way for some accumulation but it might not be out of the woods yet.</li>
      </ul>
      <p>It would be wise to look into the state of Bitcoin [BTC] especially after concluding a bearish week. The metrics may offer deeper insights into how recent events have affected BTC holdings’ demand.</p>
      <p>The number of addresses in profit or at a loss is an important metric for Bitcoin analysts. It gives a rough estimate of how investors have been affected by the recent bearish conditions. It can also be used to evaluate the impact of sell pressure.</p>
      <p>According to a recent Glassnode alert, the number of Bitcoin addresses at a loss just reached a new monthly high.</p>
      <p>The above means that Bitcoin’s breakeven point is above the current level and confirms the short-term focus. Meanwhile, the BTC amount of supply last active over 10 years just soared to a new ATH. This suggests that long-term holders have not shifted their perspective despite the harsh crash.</p>
      <h3>Can Bitcoin secure a bullish robust bullish demand?</h3>
      <p>Diving deeper we found that the number of addresses that are in loss just soared to a new monthly high. These findings do a great job of identifying the magnitude of the bearish outcome on BTC holders. But those findings do not highlight the subsequent outcome after the crash.</p>	
    	<p>Perhaps an assessment of whale activity on Glassnode may offer insights into whether the market’s next move. Will it bounce back slow or fast, and is more downside likely?  Well, addresses holding over 1,000 and over 10,000 BTC dropped off considerably. This confirms that Bitcoin whales have been contributing to sell pressure from 14 to 17 August.</p>
      <p>Bitcoin traders should note that the latest findings indicate that the whale selloff has leveled out for now. As such, Bitcoin may enjoy some relief from more downside. On the other hand, the same whale category did not register a sharp bounce.</p>
      <p>As far as accumulation is concerned, Bitcoin’s exchange flow data shows that the amount of BTC flowing out of exchanges outweighed inflows. A net flow of coins from exchanges into private wallets is a sign of accumulation.</p>	
    	<p>Bitcoin options open interest declined considerably since mid-August. However, it has also not bounced back in a manner to suggest that the bulls might be about to make a strong comeback. This could signal that the market is not yet clear in terms of recovery.</p>
    </div>
  },
  {
    id: 670,
    title: 'Litecoin: The state of LTC miners post-halving',
    category: 'Crypto',
    subCategory: ['Litecoin', 'LTC', 'miners'],
    description: <div className="blog-desc">
      <p>Since the last halving, Litecoin’s hashrate has remained stable, and its difficulty reached an all-time high on 2 August.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 20, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692620460/ncx-blog-1128_srspak.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Since the last halving, Litecoin’s hashrate has remained stable, and its difficulty reached an all-time high on 2 August.</p>
      <ul>
        <li>Litecoin’s miner fees have been declining for weeks.</li>
        <li>LTC’s weekly chart was painted red, but metrics suggested a trend reversal.</li>
      </ul>
    	<p>Litecoin [LTC] mining industry has been able to maintain its growth trajectory after the blockchain’s latest halving on 2 August, 2023. Not only was the blockchain’s hashrate stable, but its mining difficulty recently touched an all-time high, reflecting stable growth in the ecosystem.</p>	
    	<p>However, since halving LTC’s performance on the price front has not been up to par. Considering LTC’s bearish price action and the decline in mining reward, will the sector be able to maintain its growth?</p>	
    	<h3>A closer look at Litecoin’s mining sector</h3>
      <p>Since the blockchain’s third halving, Litecoin’s hashrate has remained stable. Rather, if past weeks are to be considered, the sector was showing consistent growth. At press time, Litecoin’s hashrate was 772.99 TH/s.</p>
      <p>Additionally, as hashrate increased, mining difficulty also went up, even touching an ATH on 5 August 2023. These metrics clearly reflect the growing popularity of Litecoin mining.</p>
      <p>It was interesting to note that a possible reason behind this surge could be Dogecoin [DOGE].</p>
      <p>Shan Belew, a popular crypto influencer, mentioned in a recent tweet that high hashrate might be the result of Dogecoin being merged-mined with Litecoin.</p>
      <p>However, the growth rate might halt soon as a few of the metrics revealed the possibility of miners exiting the network. For instance, Glassnode’s chart pointed out that LTC’s mining fees have been declining for months.</p>
      <p>LTC’s performance on the price front was also concerning. According to CoinMarketCap, LTC was down by more than 22% over the last week. At press time, it was trading at $64.40 with a market capitalization of over $4.7 billion.</p>
      <p>The latest halving had already reduced miners’ rewards to half. That, combined with a drop in miners’ fees and a substantially low coin price, can motivate miners to exit the network in order to look for more profitable mining options.</p>
      <h3>Can Litecoin’s price be the savior?</h3>
      <p>LTC’s price can play a crucial role in sustaining the growth of its mining ecosystem, as it can help miners increase their profit margins. Thankfully, a few of the metrics looked positive for LTC. For example, after a sharp drop, Litecoin’s MVRV Ratio recovered slightly.</p>
      <p>Additionally, its Binance funding rate was red, meaning the derivatives buyers were not purchasing the token at its current price. This increased the chances of a trend reversal.</p>
      <p>Therefore, whether LTC’s price chooses to follow a northbound route in the days to come, will be interesting to watch.</p>
    </div>
  },
  {
    id: 669,
    title: 'Will Polygon’s gaming sector help MATIC stabilize? ',
    category: 'Crypto',
    subCategory: ['Polygon', 'Gaming', 'Matic'],
    description: <div className="blog-desc">
      <p>Among the top five Polygon dApps over the last 24 hours, three were from the gaming space, showcasing the popularity of games on the blockchain.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 20, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692619939/ncx-blog-1127_sbh1iq.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Among the top five Polygon dApps over the last 24 hours, three were from the gaming space, showcasing the popularity of games on the blockchain.</p>
    	<ul>
        <li>The Immutable zkEVM testnet went live, offering new features and capabilities.</li>
        <li>MATIC’s chart remained red, and metrics were bearish.</li>
      </ul>
      <p>Over the last few months, Polygon [MATIC] has made considerable developments in its gaming ecosystem. Its efforts reflected well on the blockchain’s metrics, as it suggested consistent gaming activity.</p>	
    	<p>The future of blockchain in gaming has huge potential to grow, but its effects on MATIC remain to be seen.</p>	
      <h3>A deep dive into Polygon’s gaming ecosystem</h3>
    	<p>Dune Analytics’ data suggested that Polygon’s gaming ecosystem was witnessing steady growth at press time. Several metrics supported this notion, including unique addresses, transactions, and unique addresses in gaming dApps.</p>
      <p>The above trend remained true with the number of transactions in gaming dApps as well.</p>
      <p>DappRadar also revealed that the gaming space contributed a lot to Polygon’s growth. This was evident from the fact that among the top five dApps in the Polygon ecosystem, three of them were from the gaming space in the last 24 hours. The gaming apps were Pandra War, Sunflower Land, and Planet IX.</p>
      <p>Interestingly, MATIC also announced a partnership in the recent past that could fuel further growth of the gaming ecosystem over the coming months. Immutable zkEVM testnet recently went live, adding new functionalities.</p>
      <p>Users who choose Immutable zkEVM will receive all the advantages of  zkEVMs in addition to Immutable’s vertically integrated gaming-specific features designed to make building web3 games simple.</p>
      <p>Sandeep Nailwal, co-founder of Polygon, also tweeted about how this partnership was important for Polygon 2.0. As per the tweet, these testnets mark a huge step forward for Polygon 2.0’s vision.</p>
      <h3>MATIC continues its struggle</h3>
      <p>While the blockchain’s gaming ecosystem flourished, its native token, MATIC, couldn’t turn its chart green. As per CoinMarketCap, MATIC was down by more than 14% in the last seven days. At press time, it was trading at $0.5808 with a market capitalization of over $5.4 billion.</p>
      <p>A look at Polygon’s metrics suggested that the downtrend might continue. The token’s exchange reserve was increasing. An uptick in the metric means that selling pressure on MATIC remained high. Its active addresses and transfer volume also dropped, which looked bearish.</p>
    </div>
  },
  {
    id: 668,
    title: 'Elon Musk, Bitcoin, and the possibility of volatility',
    category: 'Crypto',
    subCategory: ['Elon Musk', 'Bitcoin', 'Volatility'],
    description: <div className="blog-desc">
      <p>The crypto market turned red after SpaceX sold BTC worth millions of dollars. However, BTC was showing signs of recovery.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 19, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692619444/ncx-blog-1126_bgchuy.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
	    <p>The crypto market turned red after SpaceX sold BTC worth millions of dollars. However, BTC was showing signs of recovery.</p>
      <ul>
        <li>The percentage of BTC supply in profit fell from 71% to 61%.</li>
        <li>BTC was still under $26,000, but a few metrics were bullish.</li>
      </ul>
    	<p>Over the last few days, the crypto market witnessed a sudden downtrend, causing top coins like Bitcoin [BTC] and Ethereum’s [ETH] price to plummet. Not only was the effect limited to the market leaders, but altcoins with lower market caps also felt the heat.</p>	  
    	<h3>Why did Bitcoin’s price sink?</h3>
      <p>Over the past few weeks, BTC remained comfortable under the $30,000 mark. However, investors were shocked on 17 August as the king coin witnessed a major drop, pushing its price below $26,000.</p>
      <p>According to CoinMarketCap, BTC was down by more than 11% in the last seven days. At press time, it was trading at $25,897.22 with a market capitalization of over $503 billion. Additionally, its trading volume also sank by over 38% in the last 24 hours.</p>
      <p>Though there might have been multiple factors at play that caused the entire market to turn red, Lookonchain pointed out what could have been the most potent trigger. Unsurprisingly, it was Elon Musk who ignited this episode.</p>
      <p>Musk’s spacecraft engineering company, SpaceX, had sold $373 million worth of Bitcoin. This dump from SpaceX might have sparked fear among investors, resulting in a price drop.</p>	
    	<p>The price decline had a larger impact on BTC’s on-chain metrics. Glassnode’s recent analysis revealed that the percentage of BTC supply in profit has just fallen from 71% to 61%. This reflects the ‘top-heavy’ market, which has become increasingly price sensitive.</p>
      <p>Moreover, Bitcoin’s number of UTXOs in loss just reached a one-month high of 41,944,655.958. Not only that, but whale activity around the coin dropped as its number of whales just reached a 1-month low of 1,599.</p>
      <h3>Bitcoin showing signs of recovery</h3>
      <p>However, the market decline came to a halt, thanks to the Securities and Exchange Commission (SEC). This happened after the regulatory body revealed its plans to approve Ethereum futures ETFs.</p>
      <p>According to a 17 August Bloomberg report, the SEC is unlikely to block the product, which has seen a lot of applications from numerous firms. Since then, BTC’s on-chain metrics have shown signs of recovery, giving hope that the king of crytpos’ value might witness an uptick.</p>
      <p>Notably, as per CryptoQuant, Bitcoin’s exchange reserve was decreasing, suggesting that the coin was not under selling pressure. The coin’s aSORP was green, which meant that more investors were selling at a loss, signaling a possible market bottom.</p>
      <p>Moreover, BTC’s Relative Strength Index (RSI) was in an oversold position. This could increase buying pressure on the coin and, in turn, increase its price.</p>
    </div>
  },
  {
    id: 667,
    title: 'Crypto now more stable than oil: Decoding this turn of events',
    category: 'Crypto',
    subCategory: ['Crypto', 'Stable', 'OIL'],
    description: <div className="blog-desc">
      <p>The 90-day annualized volatility for Bitcoin and Ethereum plummeted to multi-year lows, making them less volatile than crude oil.</p>
    </div>,
    authorName: 'Aniket Verma',
    authorurl: 'https://ambcrypto.com/author/aniket-verma/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 18, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692367538/ncx-blog-1118_v3rv3s.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
	    <p>The 90-day annualized volatility for Bitcoin and Ethereum plummeted to multi-year lows, making them less volatile than crude oil.</p>
    	<ul>
        <li>The lack of external catalysts forced top cryptos to stay glued to their narrow ranges.</li>
        <li>Experts said that the silent phase could be utilized for innovations in the industry.</li>
      </ul>
      <p>The fiercest critics of cryptocurrencies’ high volatility may be finding it hard to stomach the ongoing dynamics of the market. For the uninitiated, the crypto market was exhibiting more stability than the oil market as of 16 August.</p>	
    	<p>According to digital assets data provider Kaiko, the 90-day annualized volatility for Bitcoin [BTC] and Ethereum [ETH] plummeted to multi-year lows of 35% and 37%, respectively. This made them less volatile than the ‘Black Gold,’ which was at 41%.</p>	
    	<h3>Digital Gold vs Black Gold</h3>
      <p>The two largest cryptos by market cap have remained glued to narrow trading ranges, with no directional breakout from the gains earned through the last meaningful rally in June, per Glassnode.</p>
      <p>The June rally was built on the hype around TradFi interest in digital assets. However, things haven’t moved faster since then. The U.S. Securities and Exchange Commission (SEC) pushed back the deadlines for several spot ETF approvals to 2024, as the regulator subjects the crypto instruments to stringent examination.</p>
      <p>The delay triggered anxieties among participants with both BTC and ETH recording week-to-date (WTD) losses of more than 3%.</p>
      <p>On the other hand, continuous supply curbs have sent crude oil benchmark indices like the Brent Crude and West Texas Intermediate (WTI) soaring. Since mid-June, Brent Crude has shot up by more than 12% until the press time value of $83.61, according to Investing.com.</p>
      <p>WTI was up 15% in the same time period.</p>
      <p>The dip in crypto assets’ volatility could be attributed to shrinking liquid supply, i.e. the number of tokens available for buying and selling. BTC and ETH reserves on exchanges hit multi-year lows at the time of writing.</p>
      <h3>What does low volatility mean for the market?</h3>
      <p>Gracy Chen, Managing Director at crypto exchange Bitget, said that the drop in volatility could have severe ramifications for the crypto industry, saying:</p>
      <p><strong>“Lower user demand leads the entire industry to tighten profit margins, resulting in layoffs of employees and blockchain workers’ transition to other industries. The inflow of off-site capital slows down significantly, and the industry enters a period of decline.”</strong></p>
      <p>However, she added that the calm phase presents an opportunity for further innovation in the industry as developers could better focus on building products as per market needs.</p>
      <p>Her views were echoed by Iakov Levin, co-founder of decentralized asset management platform Locus Finance. He stated that the market was in a sort of rebuilding phase after the bloodbath of 2022, acknowledging:</p>
      <p><strong>“Right now is the time when the industry focuses on building and laying the foundation for the next few years while enjoying silent times. It is not an interesting period, but the most important in the development of the industry.”</strong></p>
    </div>
  },
  {
    id: 666,
    title: 'USDT’s dominance on Tron jumps over 48%; does it mean good news for TRX?',
    category: 'Crypto',
    subCategory: ['USDT', 'Tron', 'TRX'],
    description: <div className="blog-desc">
      <p>USDT secured dominance as far as usage on the Tron network is concerned. It even managed to outshine Ethereum which currently accounts for 43% of usage on Tron. The USDT stablecoin is the most dominant asset on the network at 48.6%.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 18, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692367137/ncx-blog-1117_ccba2v.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>USDT secured dominance as far as usage on the Tron network is concerned. It even managed to outshine Ethereum which currently accounts for 43% of usage on Tron. The USDT stablecoin is the most dominant asset on the network at 48.6%.</p>
    	<ul>
        <li>USDT becomes the most dominant cryptocurrency by demand and activity in the Tron ecosystem.</li>
        <li>Tension rises among traders as USDT pushes closer to the key support level but will it hold?</li>
      </ul>
      <p>Demand for cryptocurrencies has fueled robust utility for most top blockchains, stablecoins account for most of the on-chain transactions. Tron has been at the forefront taping into the market’s huge stablecoin appetite.</p>	
    	<p>USDT on the Tron is like a match made in heaven. This is because the Tether-issued stablecoin accounts for the bulk of stablecoin volumes on the Tron network. According to a Pundi X Labs analysis, TRX transactions going through Tron grew by 47% in the last 4 months.</p>	
    	<p>The same analysis revealed that USDT managed to secure dominance as far as usage on the Tron network was concerned. It even managed to outshine Ethereum [ETH] which currently accounts for 43% of usage on Tron. The USDT stablecoin is the most dominant asset on the network at 48.6%.</p>
      <p>Pundi X Labs mentioned lower fees and faster transactions as some of the key reasons for Tron’s stablecoin adoption. But just how much has this impacted Tron network fees?</p>
      <p>Well, fees on the network have been averaging over $1 million for the last few months since February.</p>
      <p>For reference, the Tron network fees only briefly managed to push above the $500,000 mark at the height of the 2021 bull market. Note that this represents daily fees collected on the network.</p>
      <h3>TRX goes for a support line retest</h3>
      <p>Capitulation is the name of the game and that is exactly what transpired with TRX in the last few days. The bears regained control after TRX failed to rescue the price from the consolidation phase observed in the first half of August.</p>
      <p>The price inched closer to its long-term support line after pushing as low as $0.075. Interaction with the support line will likely take place near the $0.073 price level.</p>
      <p>Will TRX bounce back after interacting with support or will it extend its bearish trajectory? Investor sentiment has reverted to indicate bearish expectations.</p>
      <p>However, traders should take note of whale activity. The supply held by top addresses remained flat, hence whales are not contributing to sell or buy pressure.</p>
      <p>As the world ponders upon TRX’s fate, perhaps we can look into its counterpart Bitcoin [BTC] which recently broke below its support.</p>
      <p>TRX could follow a similar path but it is also rapidly approaching oversold territory. Traders should thus, keep an eye out for signs of strong accumulation which could put TRX back on a bullish trajectory.</p>
    </div>
  },
  {
    id: 665,
    title: 'Solana [SOL] bounces from range-low support, what’s next?',
    category: 'Crypto',
    subCategory: ['Solana', 'SOL', 'crypto'],
    description: <div className="blog-desc">
      <p>Solana’s [SOL] range-bound price movement continued after another price rejection at the range-high.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 18, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692366702/ncx-blog-1116_sd8vwj.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Solana’s [SOL] range-bound price movement continued after another price rejection at the range-high.</p>
      <ul>
        <li>Range-bound formation persisted with neither buyers nor sellers ready to give up price levels.</li>
        <li>Traders adopted a cautious approach in the futures market.</li>
      </ul>
    	<p>Successive attempts by Solana [SOL] bulls to break out of the compact price range have not yielded the expected outcome. The latest bullish rejection at the range-high kept SOL in its sideways movement between $22.4 to $25.3.</p>	
    	<p>Following Bitcoin’s [BTC] descent below $29k, SOL dipped rapidly from the range high. However, SOL’s price recovered immediately through a combination of the bullish order block (OB) and support level.</p>
      <p>If SOL’s recent price precedent is to be followed, bulls will make another attempt for the range high ($25.3). Nevertheless, bears could take advantage of the bearish market sentiment to flip the $22.4 support level.</p>
      <h3>Bulls and bears refuse to give up their respective price levels</h3>	
    	<p>The recent price rejection at the $25.3 resistance saw the Relative Strength Index (RSI) dip below the neutral 50. However, it was recovering, as of press time to highlight rising buying pressure for Solana. On the other hand, the On Balance Volume (OBV) fell off over the past 48 hours and didn’t show any signs of recovery yet.</p>
      <p>A look at the price chart on the 12-hour timeframe showed the price tussle between buyers and sellers has ranged on for close to a month. After sellers successfully flipped the $25.3 level to resistance on 22 July, bulls have staunchly defended the $22.3 support level.</p>
      <p>While bulls have refused to give up the support level, bears have also made the $25.3 price level a stronghold, rebuffing multiple bullish breakout attempts. This oscillating price action has limited SOL’s price progress on the higher timeframes with traders waiting for a clear market trend.</p>
      <p>If bulls successfully rally to the range high again, a close above the level will see further upside available at the $29-$32 price level. Alternatively, bears flipping the $22.3 support level could see sellers aim for the $18-$20 price zone.</p>
      <h3>Tight margins in the futures market</h3>
      <p>The exchange long/short ratio typically serves as a good indicator of the direction speculators think the market will swing next. In the case of Solana, the long/short ratio remained firmly neutral with neither longs nor shorts holding a significant advantage.</p>
      <p>This hinted at speculators adopting a cautious approach in anticipation of a breakout from the price range.</p>
    </div>
  },
  {
    id: 664,
    title: 'Avalanche network activity plummets: Can this development offer respite?',
    category: 'Crypto',
    subCategory: ['Avalanche', 'Network', 'Plummets'],
    description: <div className="blog-desc">
      <p>AVAX staking will reportedly be among the services offered through AvaCloud. In other words, Avalache just unlocked a new corridor that will facilitate AVAX demand for staking which may encourage a long-term bias.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 18, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692365993/ncx-blog-1115_y0zz9w.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>AVAX staking will reportedly be among the services offered through AvaCloud. In other words, Avalache just unlocked a new corridor that will facilitate AVAX demand for staking which may encourage a long-term bias.</p>
    	<ul>
        <li>Avalanche explores more growth opportunities in Japan to revive slowing network activity.</li>
        <li>AVAX enters a possible accumulation zone after its recent bearish performance.</li>
      </ul>
      <p>As the market extends the period of low volatility, it is important to look into how it has impacted some of the top blockchains. A look at Avalanche and its current performance hammers down this point but the network has been making efforts aimed toward growth.</p>	
    	<p>Avalanche’s performance took a hit from sluggish market conditions recently. Its address activity put the impact into perspective. Daily active addresses on Avalanched achieved a six-month peak in mid-June at over 130,000 addresses. It has since cooled down to roughly 50,000 daily active addresses.</p>
      <p>The number of daily transactions on the Avalanche network unsurprisingly dipped during the same period. However, it is worth noting that daily transactions dipped even before address activity slowed down.</p>	
    	<p>Many networks tend to deploy measures aimed at sustaining growth or activity. Avalanche’s latest efforts revealed that the chain is exploring growth opportunities in the Asian region. Furthermore, it recently revealed that the Japanese firm SBI VC will adopt its AvaCloud WEB3 launchpad to roll out its services.</p>
      <p>The aforementioned development not only underscores Avalanche’s continued growth but also the potential for onboarding more organic utility. AVAX staking will reportedly be among the services offered through AvaCloud. In other words, Avalanche just unlocked a new corridor that will facilitate AVAX demand for staking which may encourage a long-term bias.</p>
      <h3>Are the tides about to change for AVAX?</h3>
      <p>AVAX received a 30%+ discount from its highest price point in mid-June. Notably around the same time that daily active addresses started tapering off. It recently bottomed out near the $11 price level and exchanged hands at $11.38 at press time.</p>
      <p>AVAX’s recent lows could be considered noteworthy for two main reasons. The price retested a previous accumulation range where it bounced off in June. The other observation is that the Relative Strength Index (RSI) recently entered the oversold zone.</p>
      <p>These findings underscore a high probability of a pivot in the next few days. However, that will depend on whether AVAX can secure enough bullish demand to yield a sizable rally.</p>
      <p>AVAX sentiment peaked in favor of the bull in mid-August. However, the price extended the downside, leading to a sentiment dip. Despite this, most of the analysts and investors submitting their expectations are still in favor of a bullish outcome.</p>
      <p>Avalanche’s recent development regarding Japan and the oversold price, may offer substantial reasons for a bullish sentiment.</p>
    </div>
  },
  {
    id: 663,
    title: 'Shiba Inu’s much awaited Shibarium gets off to a rocky start',
    category: 'Crypto',
    subCategory: ['Shiba', 'Shibarium', 'Rocky'],
    description: <div className="blog-desc">
      <p>Shiba Inu’s Shibarium launch promised to elevate SHIB and BONE beyond memes, but challenges surfaced. Amid hopes and hurdles, the community awaits Shibarium’s evolution.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 18, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692365450/ncx-blog-1114_v6nzik.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Shiba Inu’s Shibarium launch promised to elevate SHIB and BONE beyond memes, but challenges surfaced. Amid hopes and hurdles, the community awaits Shibarium’s evolution.</p>
    	<ul>
        <li>The Shibarium scanner showed a stall in transactions as of this writing.</li>
        <li>SHIB and BONE have been on a decline as negative feedback persists.</li>
      </ul>
      <p>On 16 August, the Shiba Inu [SHIB] community received a response regarding the question of “when Shibarium.” The eagerly anticipated Shibarium platform was finally introduced. This launch brought crucial utility to SHIB and BONE tokens, attempting to elevate them beyond meme coins. Nevertheless, the launch didn’t proceed as smoothly as some users had hoped, as few challenges surfaced.</p>	
    	<h3>Introducing the Shiba Inu Shibarium</h3>
      <p>On 16 August, the Shiba Inu community was informed about the launch of Shibarium. This Ethereum Layer 2 (L2) solution was introduced several months after initiating the testnet. Upon its launch, the testnet garnered immense activity with millions of transactions and registered wallets. The high transaction volume and wallet count on the testnet indicated the platform’s popularity.</p>	
    	<p>Also, the recently launched Shibarium has already begun witnessing wallet addresses and transactions. As of this writing, the number of wallets has surpassed 2,000, while over 190,000 blocks have been generated, along with more than 200 transactions.</p>
      <p>Shibarium employs a novel consensus mechanism called proof-of-participation (PoP), which selects validators based on their holdings in the relevant cryptocurrency. This approach aims to avoid the computational expenses associated with proof-of-work systems.</p>
      <p>The new L2 blockchain solution, Shibarium, is designed to interact seamlessly with the primary Ethereum Layer-1 blockchain. This interaction enhances scalability and cost-effectiveness in the transaction processes.</p>
      <h3>Concerns arise about the Shiba Inu L2</h3>
      <p>Examining the Shibarium scanner revealed a potentially challenging beginning for the Shiba Inu Layer 2 (L2) network. As of this writing, the most recent recorded transaction on the scanner occurred five hours ago. Before this observed transaction, there was a seven-hour gap in recorded transactions. This situation has given rise to various speculations within the community.</p>
      <p>Numerous reports suggested that the Ethereum bridged onto the platform might have been irretrievably lost. A screenshot purportedly from Shytoshi Kusama, the lead developer, implied that the bridged ETH may be unrecoverable.</p>
      <p>However, a post by Lucie, the Shiba Inu marketing strategist, disputed the authenticity of the screenshot, asserting that the developers were actively working to address the issue.</p>
      <p>Despite these efforts, the ecosystem tokens, SHIB and BONE, continued to experience a decline in value, which the situation has done little to assuage.</p>
      <h3>SHIB and BONE decline further</h3>
      <p>Shibarium’s launch adversely affected Shiba Inu’s performance, as observed in the daily timeframe. The chart indicated a 7% drop in value on 16 August, coinciding with the Shibarium launch.</p>
      <p>Additionally, at the time of writing, Shiba Inu had incurred an additional loss of more than 2%, extending its downward trend. Despite this decline, the Relative Strength Index (RSI) signaled only a slight bearish sentiment.</p>
      <p>Likewise, BONE didn’t fare any better on the daily timeframe. It suffered a decline exceeding 5% on 16 August and continued its downward trajectory, experiencing a nearly 9% loss as of this writing. The RSI for BONE indicated proximity to the oversold zone due to this decline.</p>
      <p>Although this may not have been the favorable start anticipated by the Shiba Inu community, there remains potential for improvement. It’s worth noting that even the testnet launch encountered challenges initially before gaining momentum.</p>
      <p>The crucial factor now lies in how the development team addresses the existing challenges in the upcoming days, as this will heavily influence the trajectory of Shibarium.</p>
    </div>
  },
  {
    id: 662,
    title: 'ATOM: As sell pressure wanes near year low, is a recovery in sight?',
    category: 'Crypto',
    subCategory: ['ATOM', 'Wanes', 'crypto'],
    description: <div className="blog-desc">
      <p>ATOM has been on a significant bearish downtrend but bulls could take advantage of nearby support and recovery by BTC to rally.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 18, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692365103/ncx-blog-1113_h3rhpr.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>ATOM has been on a significant bearish downtrend but bulls could take advantage of nearby support and recovery by BTC to rally.</p>
    	<ul>
        <li>ATOM’s bearish swing looked to bottom out close to key support level.</li>
        <li>Longs bid aggressively in the futures market with the hopes of initiating a rebound.</li>
      </ul>
      <p>Bitcoin’s [BTC] dip to $28.5k over the past day rippled across the market with altcoins experiencing significant drawbacks. Cosmos [ATOM] was on a bearish downtrend and an 11% dip in the past 48 hours extended this trend.</p>	
    	<p>With prevailing market conditions extremely bearish, ATOM sellers would be looking to extend their leverage. However, bulls could take advantage of nearby support and recovery by BTC to rally.</p>	
    	<h3>Can waning bearish momentum yield a price reversal?</h3>
      <p>The bullish defense of the $8.98 support level crumbled to intense selling pressure in early August. Bullish attempts to rally from the support level found the $10.25 resistance hard to surmount. This kept ATOM in a range-bound formation until the break below the critical support level.</p>
      <p>The bearish breakout accelerated gains by sellers, as bulls struggled to mount a fightback. As of press time, ATOM was trading close to the $7 year low achieved on 10 June.</p>
      <p>With the bearish momentum easing, bulls can attempt to use the $7.2 – $7.5 support level to rebound in the short term. However, this would be based on a rise in trading volume. As of press time, the On Balance Volume registered an uptick, highlighting the building interest in demand for ATOM.</p>
      <p>Another positive sign for bulls was the Relative Strength Index (RSI) climbing out of the oversold zone. An influx of buying pressure could ramp up the demand for ATOM in the short term, leading to a price reversal.</p>
      <p>In the meantime, sellers could look to actively drive prices toward the $7 – $7.3 price zone, if the bullish rebound falters.</p>
      <h3>Bulls look to make a comeback</h3>
      <p>The exchange long/short ratio showed rallying attempts by bulls in the short term. Buyers held positions worth $30.29m which amounted to a 54.2% market share of all open contracts.</p>
      <p>The bullish dominance on the long/short ratio along with positive signs by the on-chart indicators hinted at a bullish reversal in the short term.</p>
    </div>
  },
  {
    id: 661,
    title: 'Stablecoins stare at another disappointing month – why?',
    category: 'Crypto',
    subCategory: ['Stablecoin', 'Stare', 'Crypto'],
    description: <div className="blog-desc">
      <p>The stablecoin market cap recorded the 17th straight month of decline as of 14 August, falling to $124 billion.</p>
    </div>,
    authorName: 'Aniket Verma',
    authorurl: 'https://ambcrypto.com/author/aniket-verma/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 17, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692364319/ncx-blog-1112_v6phbf.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The stablecoin market cap recorded the 17th straight month of decline as of 14 August, falling to $124 billion.</p>
      <ul>
        <li>USDT’s market cap dropped by 0.62% to $83.3 billion in August.</li>
        <li>The progress made by countries on CBDC posed another headache for stablecoins.</li>
      </ul>
    	<p>Stablecoins are the backbone of the digital asset market, and are widely seen as having the best possibility of mimicking or perhaps replacing traditional currencies. By combining the benefits of cryptocurrencies while maintaining a fixed value, these unique assets serve as the primary way for traders on non-fiat crypto exchanges to enter and exit trades.</p>	
    	<p>However, ever since the sensational collapse of TerraUSD [UST] in 2022, the sentiment around stablecoins has become one of increased caution and scrutiny.</p>	
    	<p>According to the latest report by digital assets market data provider CCData, the total stablecoin marketcap recorded the 17th straight month of downfall as of 14 August. The market cap fell to $124 billion, the lowest since August 2021.</p>
      <p>As major cryptos like Bitcoin [BTC] witnessed historic lows in volatility, active trading declined considerably, leading to lower demand for stablecoins. As of 14 August, stablecoin trading volume was just $194 billion. At the prevailing rate, August was on track to record another dry spell, as per the report. Recall that July logged the second-lowest monthly stablecoin trading volume of the year at the time of publication.</p>
      <h3>USDT’s depeg unnerves market</h3>
      <p>Being the largest stablecoin, Tether [USDT] is considered as a bellwether for stablecoin sentiment in the market. As a result, headwinds affecting USDT usually have a rippling effect on the broader ecosystem.</p>
      <p>USDT’s market cap dropped by 0.62% to $83.3 billion in August, marking the first loss in the end-of-month market cap since October 2022. The USD-pegged crypto had a tough time last week after it suffered the deepest depeg since the collapse of FTX exchange in 2022.</p>
      <p>Due to the depeg, USDT witnessed a significant rise in redemptions. However, the issuing company Tether managed to process the redemptions with relative ease, instilling trust in USDT’s reserves.</p>
      <p>Having said that, the incident had a far lesser impact on the stablecoin market cap when compared to some of the other crypto crises. At the time of writing, USDT almost regained its value, trading at $0.9987, per CoinMarketCap data.</p>
      <p>Meanwhile, crisis-hit USD Coin [USDC] extended its losing streak. The second-largest stablecoin’s market cap declined for the eight consecutive months, falling 1.82% to $25.8 billion.</p>
      <h3>FDUSD’s ascent shakes up the dynamics</h3>
      <p>The report also drew attention to the emergence of another Binance-backed stablecoin First Digital USD [FDUSD].</p>
      <p>After getting listed on the crypto behemoth in the last week of July, FDUSD’s market witnessed a whopping 1410% surge in August. The impressive growth catapulted the dollar-pegged asset to become the tenth-largest stablecoin in the market, with the valuation above $305 million.</p>
      <p>The coin was listed as part of Binance’s popular zero fee trading program, which slashed maker and taker fees for practically all spot trading pairs.</p>
      <p>Interestingly, FDUSD scooped a big portion of the market share from other Binance-related stablecoins. Binance USD [BUSD] and TrueUSD [TUSD] registered sizable declines of 9.35% and 9.15% to $3.37 billion and $2.75 billion, respectively, per the report.</p>
      <p>Notably, BUSD was in a phasing-out stage. Binance has put its focus on promoting other stablecoins like TUSD and the recently-launched FDUSD.</p>
      <h3>PayPal’s stablecoin push</h3>
      <p>The month of August also saw big moves from the TradFi giants in the sphere of stablecoins. Online payments company PayPal debuted its stablecoin PayPal USD [PYUSD], in a bid to improve the payment experience in virtual environment.</p>
      <p>The market cap of the Ethereum [ETH]-based stablecoin was just about $27 million as per the report. However, given the magnitude and size of PayPal’s ecosystem, the coin stood to gain significantly in the future.</p>
      <p>To put things into perspective, even if PYUSD was used for 1% of PayPal’s payment volume in Q2 2023, the transaction volume would be almost $3.77 billion.</p>
      <h3>Stablecoins brace for CBDC threat</h3>
      <p>Most experts would agree that in today’s world, stablecoins would face the biggest challenge from Central bank digital currencies (CBDCs). Many emerging markets have been doubling down on their CBDC efforts lately.</p>
      <p>As the name suggests, these assets will be issued by the country’s central bank. And each CBDC will be pegged to the national currency. The motive behind backing these assets is to extend the benefits of stable digital currencies without losing regulatory control over them.</p>
      <p>Russia started its digital Ruble trials recently in the hopes of shielding its monetary system from Western sanctions. Furthermore, South Korea divulged more details about its upcoming CBDC pilot phase.</p>
    </div>
  },
  {
    id: 660,
    title: 'Solana eclipses XRP & DOGE in this metric, but it’s not all good news',
    category: 'Crypto',
    subCategory: ['Solana', 'XRP', 'DOGE'],
    description: <div className="blog-desc">
      <p>Beneath this remarkable feat, SOL has faced its fair share of challenges, as its price experienced a recent downturn.</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 17, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692362935/ncx-blog-1111_sdrzw8.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Beneath this remarkable feat, SOL has faced its fair share of challenges, as its price experienced a recent downturn.</p>
      <ul>
        <li>SOL liquidity increased but its price has not been stable.</li>
        <li>Traders’ bullish sentiment could not stop a $3.90 million liquidation.</li>
      </ul>
    	<p>Solana [SOL] has become one of the altcoins with really high liquidity on Coinbase, according to market research platform Kaiko. Having high liquidity sometimes enables price stability and less volatility.</p>
      <h3>SOL’s health isn’t wealth</h3>	
    	<p>And as a measure of the demand for and supply of an asset, SOL’s high liquidity is an indication of a healthy market on its side. According to Kaiko, the hike in liquidity ensured that SOL outpaced Ripple [XRP], Dogecoin [DOGE], and Polygon [MATIC] as per the metric.</p>	
    	<p>Leading altcoins in terms of liquidity may not be surprising considering how SOL outperformed most assets in the market lately. However, beneath this remarkable feat, SOL has faced its fair share of challenges, as its price has experienced a recent downturn.</p>
       <p>Based on CoinMarketCap’s data, SOL lost 4.22% of its value in the last seven days. From the four-hour SOL/USD chart, the altcoin formed a bearish market structure.</p>
       <p>As of 15 August, SOL tried to hold support at $23.96. But unfortunately, there was little to no buying pressure to curtail the increasing sell-off. Thus, this led the support down to $22.90.</p>
       <p>While SOL has risen mildly above the $22 region, volatility has become really extreme, as indicated by the Bollinger Bands (BB). The lower band of the BB also touched SOL’s price previously, indicating that the altcoin was oversold.</p>
       <p>Although there has been a slight reversal in the trend, the Moving Average Convergence Divergence (MACD) showed that the momentum remained largely in bearish control. Negative MACD values indicate that the 12-day EMA is below the 26-day EMA.</p>
       <p>And at press time, the MACD was -0.13. Unless demand for SOL outweighs the selling pressure, the altcoin price could decrease as low as $20.</p>
       <h3>Optimism gives opposing result </h3>
       <p>Despite SOL’s downtrend, the funding rate was positive, based on Santiment’s data. Typically, a negative funding rate means that short positions outpaced long positions. However, the positive SOL funding rate implies that the average trader’s sentiment toward SOL was bullish.</p>
       <p>Like the funding rate, the weighted sentiment was also in the positive region. The weighted sentiment considers the unique social volume with respect to opinions around an asset. </p>
       <p>When the metric is positive, it implies that the average perception of an asset is optimistic. Conversely, a negative weighted sentiment suggests the opposite. And SOL supported the former.</p>
       <p>Meanwhile, information from derivatives tracking portal Coinglass showed that about $3.90 million SOL positions were wiped out in the last 24 hours.</p>
       <p>Also, longs accounted for $2.94 million out of those liquidated. Shorts positions liquidated, on the other hand, were worth $952,670.</p>
    </div>
  },
  {
    id: 659,
    title: 'Uniswap [UNI] prices continued to dive lower, where will it see some respite?',
    category: 'Crypto',
    subCategory: ['Uniswap', 'UNI', 'Prices'],
    description: <div className="blog-desc">
      <p>The strong selling pressure forced UNI to nosedive below the $6.2 and $6 levels. At the time of writing, UNI was trading at $5.84 and within a bullish order block on the daily timeframe</p>
    </div>,
    authorName: 'Akashnath S',
    authorurl: 'https://ambcrypto.com/author/akashnath-sumukar/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 17, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692362518/ncx-blog-1110_ceh8zn.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The strong selling pressure forced UNI to nosedive below the $6.2 and $6 levels. At the time of writing, UNI was trading at $5.84 and within a bullish order block on the daily timeframe</p>
    	<ul>
        <li>Uniswap had a strong bearish outlook in the lower timeframes</li>
        <li>The confluence of a higher timeframe demand zone and range lows highlighted a critical area on the price charts</li>
      </ul>
      <p>Uniswap [UNI] has been in an uptrend on the daily timeframe since early July. August saw the bulls face rejection at $6.7, marking a potential range that the token would trade within in the coming weeks.</p>	
    	<p>Bitcoin [BTC] dropped from $29.6k on 14 August to trade at $29.1k at press time. Although this was only a 1.66% drop, it has affected the sentiment in the altcoin market heavily. Uniswap faced intense selling pressure over the past 24 hours.</p>	
      <h3>The swift recent losses showed the range lows might not be defensible</h3>
    	<p>The range formation (green) for UNI extended from $5.7 to $6.7. Over the past week, the mid-range mark at $6.2 saw some skirmishes between buyers and sellers, but a conclusive winner did not emerge until 15 August.</p>
      <p>The strong selling pressure forced UNI to nosedive below the $6.2 and $6 levels. At the time of writing, UNI was trading at $5.84 and within a bullish order block on the daily timeframe. This demand zone had confluence with the range lows as well.</p>
      <p>Therefore, if a reversal were to occur, this would be the ideal spot. Yet, the OBV jumped below a short-term support level as selling volume rocketed higher. If BTC losses continued, it looked very likely that UNI would drop to the next zone of support at $5.2. A drop below $5.7 and a retest of the same could offer a shorting opportunity.</p>
      <h3>The spot CVD began to reverse its uptrend as bearish sentiment intensified</h3>
      <p>Over the past week the spot CVD was in a healthy uptrend. But the shift in sentiment over the past two days meant the bears were back in control. The sharp price drop was accompanied by a slide in the Open Interest. It also saw a large number of long liquidations.</p>
      <p>This showed long positions were forced to sell as prices hit liquidation levels. It was also accompanied by a drop in the spot CVD, showing sellers were wholly dominant in the short term.</p>
    </div>
  },
  {
    id: 658,
    title: 'Can sustained selling pressure flip XLM bearish?',
    category: 'Crypto',
    subCategory: ['XLM', 'Bearish', 'crypto'],
    description: <div className="blog-desc">
      <p>Bears looked to flip XLM bearish, as selling pressure extended to bullish order block.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 16, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692361744/ncx-blog-1109_o0wsf0.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bears looked to flip XLM bearish, as selling pressure extended to bullish order block.</p>
      <ul>
        <li>Bears extended selling leverage with XLM trading at a key price zone.</li>
        <li>Shorts primed to capitalize on the sustained selling pressure in the futures market.</li>
      </ul>
    	<p>The multiple rejections of Stellar Lumens’ [XLM] bullish rally at a critical price level has left buyers frustrated. With Bitcoin [BTC] and major altcoins posting losses over the past 24 hours, XLM sunk to a key bullish order block (OB).</p>	
    	<p>The price action at this order block will be super critical to XLM’s next move. Will bears break below the bullish OB and push prices lower or can buyers reverse the selling trend?</p>
      <h3>Bulls can’t scale key price hurdle</h3>
      <p>XLM reached a yearly high of $0.198 on 13 July following a breakout from its compact trading range. However, a keen price tussle ensued between bulls and bears with sellers leveraging the $0.17 resistance to halt the bullish advances.</p>
      <p>Bullish attempts to extend the gains after the massive breakout have yielded little results. The most recent attempt to break the $0.17 resistance on 28 July led to bears breaking the previous higher low, as sellers looked to flip XLM bearish on the 12-hour timeframe.</p>
      <p>The current selling pressure along with price resting at the bullish OB presents two distinct possibilities. A bearish candle close below the bullish OB could trigger an extended downward move with targets at $0.1 to $0.11.</p>
      <p>On the other hand, the bullish OB could offer bulls new buying opportunities, as evidenced on 18 July. This could see another price rally from the $0.12 level, especially if Bitcoin mounts a significant price recovery above $29.5k.</p>
      <p>In the meantime, the Relative Strength Index (RSI) descended into the oversold zone. This highlighted the strong bearish momentum for XLM. The Chaikin Money Flow (CMF) hovered just under the zero mark with a reading of -0.03, hinting at capital preservation moves by traders.</p>
      <h3>Sellers backed by strong bearish conviction in futures market</h3>
      <p>The exchange long/short ratio data from Coinglass revealed a strong leaning by market speculators toward further price dips for XLM. Shorts held a 52.72% share of all open XLM contracts with a $9.48 million margin over longs, as of press time.</p>	
    	<p>This highlighted the upper hand of sellers with a strong possibility of the bullish OB caving to the selling pressure.</p>
    </div>
  },
  {
    id: 657,
    title: 'Chainlink plummets below $7 as sell pressure overwhelms bulls',
    category: 'Crypto',
    subCategory: ['Chainlink', 'plummets', 'bulls'],
    description: <div className="blog-desc">
      <p>To the south, traders can watch out for $6.58 and $6.06. The bulls could drive a bounce in prices at those places.</p>
    </div>,
    authorName: 'Akashnath S',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 16, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692361323/ncx-blog-1108_gzujud.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>To the south, traders can watch out for $6.58 and $6.06. The bulls could drive a bounce in prices at those places.</p>
      <ul>
        <li>The sudden drop to $6.8 saw large quantities of long positions liquidated.</li>
        <li>The price action showed that a retest of $7 could be followed by another move downward.</li>
      </ul>
    	<p>Chainlink [LINK] had an optimistic outlook on the price chart last week. The bulls were knocking on the doors of the $7.7 resistance zone with strength. Yet, the sellers managed to prevail. A retest of the same resistance zone saw LINK rejected, yielding losses of 10% within 36 hours that began on 14 August.</p>	
    	<p>The technical indicators showed that the next few days were likely to see LINK descend further. The RSI showed strong bearish momentum and the OBV noted increased selling volume over the past two days.</p>	
    	<h3>The Chainlink bullish order block breached with ferocity</h3>
      <p>The H4 bullish order block (cyan) extended from $7-$7.2. It was the region where LINK began its earlier rally that faced rejection at the $7.7 level. A retest of this zone might provide a bounce in prices.</p>
      <p>This was not to be. The recent wave of selling saw a large candle with massive trading volume close the H4 trading session below the order block. Hence this region can be expected to serve as resistance as it was changed to a bearish breaker block.</p>
      <p>To the south the $6.58 and $6.06 levels were ones to scrutinize. The bulls could drive a bounce in prices at those places. Short-sellers can look to enter positions targeting these levels with invalidation above the breaker block.</p>
      <h3>Long liquidations painted a not-so-pretty picture</h3>
      <p>As Chainlink prices breached the $7 mark to sink as low as $6.8, it triggered a cascade of long liquidations. These positions were forced to sell and added to the bearish sentiment in the market. Over the past few hours, the long liquidations slowly ticked higher again.</p>
      <p>The OI has been relatively stable after the plunge. If the OI rose alongside falling prices it would indicate an acceleration in short positions being opened. This would compound the bearish sentiment in the market.</p>
    </div>
  },
  {
    id: 656,
    title: 'Ethereum: Traders looking for a green signal should read this',
    category: 'Crypto',
    subCategory: ['Ethereum', 'Traders', 'Crypto'],
    description: <div className="blog-desc">
      <p>Although Ethereum’s exchange outflow and inflow reached a five-year and seven-year low, other metrics displayed that ETH could witness bearish price action only in the near future.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 16, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692360926/ncx-blog-1107_s2eoap.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Although Ethereum’s exchange outflow and inflow reached a five-year and seven-year low, other metrics displayed that ETH could witness bearish price action only in the near future.</p>
    	<ul>
        <li>ETH’s price remained above its realized price, which hovered between $1,500 and $1,600.</li>
        <li>Ethereum’s supply on exchanges declined while its supply outside of exchanges shot up.</li>
      </ul>
      <p>As the market is witnessing less activity, Ethereum’s [ETH] price has continued to stay under the $1,900 mark. Therefore, investors might want to accumulate more ETH before the token enters its next bull rally.</p>	
    	<p>Interestingly, CryptoQuant’s latest analysis sheds light on when investors should consider accumulating the altcoin. However, a look at a few metrics suggested that buying pressure around ETH was relatively weak.</p>	
    	<h3>This indicates good buying opportunities</h3>
      <p>A CryproQuant analyst and author recently posted an analysis that highlighted a metric to mention when investors should stockpile ETH. As per the analysis, Ethereum’s realized price hovered between $1.500 and $1,600 from January to August 2023. This is the average “break-even” price at which Ethereum holders make no money or lose money.</p>
      <p>When ETH‘s price falls below the realized price and quickly recovers, it indicates that the market believes Ethereum is undervalued. Thus, there were three buying opportunities year-to-date, after which the token’s price rallied.</p>
      <p>According to CoinMarketCap, at press time, ETH was trading at $1,840.24 with a market capitalization of over $221 billion. This meant that at press time, ETH’s price was above its realized price, suggesting that it was not undervalued.</p>
      <p>Interestingly, Glassnode Alerts’ tweet pointed out that ETH’s exchange outflow reached a five-year low of 6,045.499 ETH.</p>
      <p>At first glance, this looked bearish, but upon a thorough check, a different story was revealed. Not only did exchange outflow, but ETH’s exchange inflow also reached a seven-month low. A drop in both metrics clearly indicated that investors were reluctant to trade ETH.</p>
      <h3>Should investors start accumulating ETH?</h3>
      <p>Though the overall market remained dormant, a look at ETH’s metrics suggested that investors might have already started accumulating. The token’s supply on exchanges dropped over the last month, while its supply outside of exchanges increased. Moreover, ETH’s supply held by top addresses also shot up, reflecting whales’ confidence in the token.</p>
      <p>Considering that ETH has become comfortable under $1,900, investors might as well think about increasing their accumulation ahead of a bull run to enjoy profits. This was because the chances of ETH continuing its sluggish price action seemed likely but only in the short term.</p>
      <p>Additionally, Coinglass’ data revealed that ETH’s open interest was relatively high. Moreover, its funding rate was also green.</p>
      <p>A high funding rate means that derivatives buyers were purchasing ETH at its current price. This increases the chances of a continued price trend.</p>
    </div>
  },
  {
    id: 655,
    title: 'THOR surges over 300%: Exploring reasons behind the supercharged bulls',
    category: 'Crypto',
    subCategory: ['THOR', 'Supercharged', 'Bulls'],
    description: <div className="blog-desc">
      <p>Thorswap has been ramping up its deflationary characteristics through token burns. It recently revealed that its August volume burn surpassed the 300% mark. The DEX plans to burn as much as 7.5 million THOR tokens.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '', 
    createdAt: 'August 16, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692359365/ncx-blog-1106_lttylt.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Thorswap has been ramping up its deflationary characteristics through token burns. It recently revealed that its August volume burn surpassed the 300% mark. The DEX plans to burn as much as 7.5 million THOR tokens.</p> 
    	<ul>
        <li>THOR delivers an impressive bullish performance.</li>
        <li>Thorswap’s token burns and incentives to attract whales are the main catalysts.</li>
      </ul>
      <p>We’re halfway through August and the market is already starting to show some excitement, albeit in an isolated few coins. THOR, not to be confused with Thorchain, is among the digital currencies that have performed exceptionally well recently.</p>	
    	<p>THOR is the native token on the Thorswap DEX. It has lately been commanding a lot of attention, especially after its impressive performance. THOR traded as low as $0.076 on 6 August which happens to be its lowest price in roughly six weeks. It peaked at $0.87 on Monday (14 August) after an impressive 1017% rally from its monthly low.</p>	
    	<h3>THOR and its thunder</h3>
      <p>THOR exchanged hands at $0.38 at press time, after pulling back from its peak of 14 August. Some selling pressure was expected since the price was already deep in overbought territory. Despite that, the press price still represented a 400%+ gain from its recent monthly low.</p>
      <p>Now that we have observed THOR’s robust rally, it is time to look into the reasons why. A bit of research revealed that the network offers incentives.</p>
      <p>For example, most of the network’s recent activity involved a lot of whales. This is not surprising considering that the Thorswap DEX recently introduced a 50% whale tier discount.</p>
      <h3>Of whales and token burns</h3>
      <p>Most DEXes tend to be pricier than their centralized counterparts. Nevertheless, whales need and use DEXes to bypass transaction size restrictions. Such a hefty discount was bound to attract a significant amount of whales and as such, fueling demand for the THOR token. However, this was not the only reason for the rally.</p>
      <p>Thorswap has been ramping up its deflationary characteristics through token burns. It recently revealed that its August volume burn surpassed the 300% mark. The DEX plans to burn as much as 7.5 million THOR tokens as part of a governance-approved proposal.</p>
      <p>The burnt THOR volume is enough to have an impact on both the sentiment as well as the price. But can these factors support more upside or is the token headed for more upside? Well, Thorswap’s real volume has already pivoted after a double top with a lower high.</p>
      <p>Note that the same volume metric was previously dormant in the last four weeks before the excitement observed in the last few weeks. Heavy profit-taking may curtail the upside but not necessarily by much given that the token burns will still continue in the second half of August.</p>
    </div>
  },
  {
    id: 654,
    title: 'Tron’s latest achievement suggests this about the network',
    category: 'Crypto',
    subCategory: ['Tron', 'TRX', 'Network'],
    description: <div className="blog-desc">
      <p>Tron adoption reached a new milestone as the total number of accounts surpassed 178 billion. TRX’s price action remained dormant.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 15, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692359035/ncx-blog-1105_fyv9we.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Tron adoption reached a new milestone as the total number of accounts surpassed 178 billion. TRX’s price action remained dormant.</p>
    	<ul>
        <li>Tron witnessed growth in its staking ecosystem.</li>
        <li>Metrics suggested that TRX’s popularity declined last week.</li>
      </ul>
      <p>Tron [TRX] adoption continues to rise at a commendable pace as the blockchain reaches a new milestone. The blockchain has recently announced that its total number of accounts has exceeded 178 million.</p>	
    	<p>The blockchain’s staking ecosystem also witnessed growth over the past few weeks. However, despite a promising uptick in metrics, the blockchain performance in the DeFi space has somewhat plateaued.</p>
      <h3>Is Tron witnessing wholesome growth?</h3>
      <p>Not only did Tron’s total addresses go up, but its total transactions also climbed to over 6.2 billion. The metric clearly indicated increased usage and activity on the network.</p>
      <p>On top of that, the blockchain also recently passed its committee proposal 89, which can help in further increasing its adoption. The proposal was aimed at activating the PUSH0 instruction in the TRON virtual machine (TVM).</p>
      <p>Tron Spark, a popular X handle that posts updates related to the Tron ecosystem, pointed out in a recent tweet a few factors that might have been playing a role in boosting adoption.</p>
      <p>As per the tweet, the non-fungible token (NFT) market has blossomed over recent years, and the blockchain’s ecosystem provides a suitable platform for its creation and trade.</p>
      <p>In fact, if metrics are to be considered, TRX witnessed a massive boom in its NFT space during the last NFT summer. This was evident from the steep rise in the number of NFT trade counts and trade volume in USD.</p>
      <p>Apart from NFTs, the tweet also mentioned that the blockchain has seen a surge in DeFi projects, which offer a range of financial services without intermediaries.</p>
      <p>Thanks to that, Tron’s TVL has remained relatively high. However, it must also be noted that the metric’s growth rate remained low as the TVL graph somewhat plateaued. At press time, TRX’s TVL stood at $5.678 billion, as per DeFiLlama.</p>
      <p>The blockchain’s staking ecosystem also grew over the last few weeks. As per Staking Rewards’ data, the total TRX staked went up in the last seven days. Not only that, but the number of TRX stakers also increased slightly.</p>
      <p>At press time, Tron had a staking ratio of 47.69% and a staking market capitalization of more than $3 billion.</p>
      <h3>TRX is reluctant to move</h3>
      <p>While the blockchain’s health seemed up to par, its native token’s price action remained slow. As per CoinMarketCap, TRX’s price only went up by 0.8% in the last week. At the time of writing, it was trading at $0.07743 with a market cap of nearly $7 billion.</p>	
    	<p>The token’s popularity took a blow last week as its social volume dropped. The weighted sentiment revealed that negative sentiment was dominant in the market. However, thanks to the efforts made by developers, the blockchain’s development activity remained high.</p>
    </div>
  },
  {
    id: 653,
    title: 'A look at Arbitrum as L2 competition rises',
    category: 'Crypto',
    subCategory: ['Arbitrum', 'L2', 'rises'],
    description: <div className="blog-desc">
      <p>Base’s surge in transactions per second and revenue marks a significant shift in the competitive dynamics of the L2 sector, threatening Arbitrum’s dominance.</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 14, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692358219/ncx-blog-1104_dgghe0.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Base’s surge in transactions per second and revenue marks a significant shift in the competitive dynamics of the L2 sector, threatening Arbitrum’s dominance.</p>
    	<ul>
        <li>Arbitrum’s L2 dominance was challenged by the rise of Base and Optimism, altering the L2 landscape.</li>
        <li>Base surged in TPS and revenue, signaling growing competition and the potential shift in hierarchy.</li>
      </ul>
      <p>Throughout the year, Arbitrum[ARB] held a firm grip on the Layer 2 (L2) landscape. However, as Base and Optimism make significant strides, Arbitrum’s position as the L2 leader seems to be wavering.</p>	
    	<h3>Can Arbitrum keep up?</h3>
      <p>In a surprising turn of events, Base surged ahead, claiming the third position in transactions per second (TPS). This feat outperformed both Arbitrum and Optimism, garnering attention for Base’s growing influence.</p>	
    	<p>Notably, BuildOnBase’s revenue generation in August also exceeded its competitors, with Base generating $2.4 million, Arbitrum $1.05 million, and Optimism $1.1 million.</p>
      <p>The emergence of Base as a serious contender highlights the evolving nature of the L2 landscape, where new players can swiftly challenge established giants.</p>
      <p>Artemis’ data provided a sobering view of Arbitrum’s recent performance, revealing a decline in overall network activity. Moreover, the number of transactions processed on the network witnessed a noticeable drop during this period.</p>
      <p>This decline in activity raises questions about Arbitrum’s ability to maintain its momentum in the face of emerging competition.</p>
      <p>Despite these challenges, Arbitrum has managed to retain a strong foothold in the decentralized finance (DeFi) sector. The protocol’s Total Value Locked (TVL) remained substantial, outpacing many other protocols by a significant margin. Additionally, Arbitrum’s performance in terms of Decentralized Exchange (DEX) volumes showcased its continued relevance and influence.</p>
      <p>However, even stalwarts like Arbitrum are not immune to the ebbs and flows of the market. Over the past month, the protocol experienced a decline in both revenue and activity. Revenue dipped by 22.4%, while daily active users witnessed a notable decline of 20.2%.</p>
      <p>This downturn in revenue and activity raises concerns about Arbitrum’s resilience in the midst of increasing competition.</p>
      <p>On the price front, ARB tokens were trading at $1.14 at press time. This value, while stable, prompts a closer look at other metrics that shed light on the protocol’s health.</p>
      <p>Network growth data indicated a decline, hinting at a potential waning interest from new participants. Additionally, the velocity of ARB trades registered a decrease, implying that trading frequency had decreased over the period.</p>
    </div>
  },
  {
    id: 652,
    title: 'What a major approval means for Tron, TRX investors',
    category: 'Crypto',
    subCategory: ['Tron', 'TRX', 'Investors'],
    description: <div className="blog-desc">
      <p>Last week, Tron approved its committee proposal no. 89, activating the PUSH0 instruction in the TRON virtual machine.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 14, 2023', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692358004/ncx-blog-1103_mtwsoi.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Last week, Tron approved its committee proposal no. 89, activating the PUSH0 instruction in the TRON virtual machine.</p>
      <ul>
        <li>Tron’s social engagement and mentions dropped over the last seven days</li>
        <li>TRX’s price was slow-moving, and the trend might continue</li>
      </ul>
    	<p>Tron [TRX] recently shared its weekly report, highlighting the developments that happened in its ecosystem. Among them, the most notable update was related to a newly passed proposal. While the proposal was being passed, TRX’s price action remained dormant, however, as it failed to move up over the last seven days. Not only that, but the token’s performance on the social front was also not at par.</p>	
    	<h3>Tron approves new committee proposal </h3>
      <p>Tron’s weekly roundup report pointed out that the blockchain approved its committee proposal 89. The proposal was aimed at activating the PUSH0 instruction in the TRON virtual machine (TVM). For starters, it was suggested to implement and enable the PUSH0 instruction in TVM in order to keep it compatible with Ethereum Virtual Machine (EVM) and to share the same advantages with TRON developers and users.</p>	
      <p>Mentioning the changes, the PUSH0 instruction in TVM brings both technical and economic benefits. A few of the advantages of the update include a reduction in contract code size, a reduction in the risk of contracts using various instructions as an optimization measure, etc.</p>
    	<p>However, the approval did not receive much traction in the crypto-community as TRX’s social metrics dropped last week. LunarCrush’s chart revealed that while TRX’s social engagement dropped by 48%, its social mentions also sank by 30%. On top of that, investors’ confidence in TRX was also low, as its bullish sentiments declined by more than 24%.</p>
      <h3>Not a lot of volatility?</h3>
      <p>Not only was Tron’s performance on the social front not at par, but its price action also remained less volatile last week.</p>
      <p>According to CoinMarketCap, TRX’s price has moved by less than 1% over the last seven days. At press time, it was trading at $0.07732 with a market capitalization of more than $6.9 billion, making it the 10th largest crypto by market cap. The sluggish price action caused the token’s 1-week price volatility to remain low through the week.</p>
      <p>Investors’ interest in trading the token also dropped, as is evident from the slight decline in its trading volume over the last few days. However, thanks to the efforts made by developers, the blockchain’s development activity remained high, which by and large is a positive sign.</p>
      <p>If derivatives metrics are to be believed, Tron’s price action might remain underwhelming. Coinglass’ chart revealed that TRX’s open interest remained stable too.</p>
      <p>Additionally, its OI-weighted funding rate was also found to be green. This suggested that investors have been buying TRX at its current price in the Futures market, increasing the chances of TRX continuing its prevailing price trend.</p>
    </div>
  },
  {
    id: 651,
    title: 'Ethereum: Are institutional investors in the U.S shunning ETH?',
    category: 'Crypto',
    subCategory: ['Ethereum', 'ETH', 'US'],
    description: <div className="blog-desc">
      <p>As Ethereum trades below $2000 and in a tight range, institutional investors in the USA look towards other altcoins.</p>
    </div>,
    authorName: 'Abiodun Oladokun',
    authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 14, 2023 ',  
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692003885/ncx-blog-1064_lgslyy.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>As Ethereum trades below $2000 and in a tight range, institutional investors in the USA look towards other altcoins.</p>
      <ul>
        <li>Ethereum’s CPI revealed that institutional investors in the USA have reduced their exposure to the altcoin</li>
        <li>On the daily chart, daily traders were spotted distributing their ETH holdings</li>
      </ul>
    	<p>Ethereum’s [ETH] Coinbase Premium Index (CPI) recently slipped into negative territory, suggesting that institutional investors in the USA have reduced their accumulation of the altcoin on Coinbase. This, according to findings shared by CryptoQuant’s pseudonymous analyst ‘Greatest Trader.’</p>	
    	<p>The CPI is a metric that measures the difference between the price of an asset on Coinbase and its price on Binance. When an asset’s CPI value is positive, it indicates strong buying pressure among institutional investors on Coinbase. Conversely, when the CPI metric returns a negative value, less accumulation activity takes place on Coinbase.</p>	
    	<p>Greatest Trader analyzed ETH’s CPI on a 30-day moving average and found that “there has been a substantial shift in sentiment within the U.S. market, as evidenced by the noticeable downward trend in the premium index.”</p>
      <p>At the time of writing, ETH’s CPI was negative for the first time over the past month with a reading of -0.0027.</p>
      <p>According to the analyst, this shift in sentiment is noteworthy because “numerous U.S. investors are wealthy individuals or institutional entities” and are, therefore, capable of influencing the asset’s price movements.</p>
      <p>Commenting on the impact of the CPI decline on the ETH market, the analyst added,</p>
      <p><strong>“This behavior might cast a pessimistic light on ETH’s prospects. It implies that these investors might not be inclined to accumulate ETH within this price range, possibly signifying a bearish sentiment prevailing in the market.”</strong></p>
      <h3>Not just America…</h3>
      <p>An assessment of fund flows into digital asset investment products revealed that the year so far has been marked by significant outflows from ETH.</p>
      <p>Although the altcoin touched the psychological $2000-price mark in April, it has since trended south to linger in a narrow range since. This, in a way, has eroded investors’ confidence and has caused negative sentiment to return to the market.</p>
      <p>As of 4 August, the year-to-date outflows from ETH totaled $76 million.</p>
      <h3>Traders continue to look away</h3>
      <p>At press time, ETH was changing hands at $1,848. Facing strong resistance at $2000, the alt’s price has lingered in a tight range since April. This, due to its statistically positive correlation with Bitcoin [BTC], whose price has lingered between $29,000 and $30,000 since April.</p>
      <p>With waning accumulation among daily traders, key momentum indicators lay below their neutral points at press time. The Relative Strength Index (RSI) was 47.36, while the Money Flow Index (MFI) inched closer to the oversold zone at 26.31.</p>
      <p>Signalling increased liquidity exit from the ETH market, its Chaikin Money Flow (CMF) was negative at press time.</p>
    </div>
  },
  {
    id: 650,
    title: 'Uniswap: Founder Hayden not here to make ‘FRENS’ with latest update',
    category: 'Crypto',
    subCategory: ['Uniswap', 'Hayden', 'FRENS'],
    description: <div className="blog-desc">
      <p>Uniswap faces market uncertainty due to a developer’s controversial actions around the FRENS token. While Uniswap’s activity declined post-controversy…</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 14, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692003636/ncx-blog-1063_ymd4zb.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Uniswap faces market uncertainty due to a developer’s controversial actions around the FRENS token. While Uniswap’s activity declined post-controversy…</p>
    	<ul>
        <li>Threat to Uniswap’s market sentiment arises due to FRENS token developer controversy</li>
        <li>UNI has, however, exhibited resilience amidst setbacks</li>
      </ul>
      <p>Uniswap [UNI], a pioneering DEX within the DeFi sector, has consistently maintained its position as a dominant player in the space. However, recent developments involving the developer of the FRENS token has introduced an element of uncertainty in Uniswap’s market sentiment.</p>	
    	<h3>We didn’t start the “Fire”</h3>
      <p>In response to the negative exposure, Uniswap’s founder took to Twitter to address the situation, revealing that the individual responsible for the FRENS token had been dismissed from their role.</p>	
    	<p>The individual in question, known as AzFlin, had been identified as the developer behind the meme token FrensTech (FRENS). Shortly after deploying the FRENS token, AzFlin executed a transfer of 14 WETH (equivalent to approximately $25,800) from the Base network and proceeded to liquidate the tokens acquired from the liquidity pool.</p>
      <p>Allegations emerged that AzFlin engaged in a practice commonly referred to as “rug-pulling,” where liquidity is withdrawn from a project immediately after its launch. Investigations revealed that AzFlin accessed the funds through the HOP protocol, effectively removing the ETH liquidity, a sum of 14 ETH, that had been contributed as fees.</p>
      <p>AzFlin, however, disputed the accusation of rug-pulling, acknowledging only the removal of 1 ETH worth of liquidity from the project.</p>
      <p>While AzFlin denied a deliberate rug pull, he confirmed the sale of a portion of FRENS tokens sourced from the liquidity pool. He justified this action by asserting that the tokens had originally been acquired using personal funds.</p>
      <p>Interestingly, the FRENS token incident is not an isolated occurrence within the Base network. In a similar fashion, the BALD memecoin saw significant interest and hit a market cap of $85 million, before falling victim to a rug pull executed by its deployer in July.</p>
      <h3>A slowdown in activity</h3>
      <p>Despite Founder Hayden’s efforts to address concerns and dispel fears surrounding Uniswap, the protocol experienced setbacks. Within a 24-hour timeframe, Uniswap’s activity plummeted by 27.3%, accompanied by a decline of 13.5% in the fees collected.</p>
      <p>Conversely, UNI exhibited signs of growth as its price ascended from $5.793 to $6.15. Here, the surge in price can be attributed to a hike in the number of UNI holders over the past week.</p>
    </div>
  },
  {
    id: 649,
    title: 'Solana vs Polygon: How is 2023 turning out to be for both?',
    category: 'Crypto',
    subCategory: ['Solana', 'Polygon', 'Crypto'],
    description: <div className="blog-desc">
      <p>Polygon had more daily active addresses than Solana for three consecutive months, but SOL outshined MATIC in a few areas.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 14, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692003307/ncx-blog-1062_ow9qei.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Polygon had more daily active addresses than Solana for three consecutive months, but SOL outshined MATIC in a few areas.</p>
      <ul>
        <li>Solana’s TVL gained and hit its highest level since the beginning of 2023</li>
        <li>MATIC’s market cap plummeted in June, while SOL registered gains</li>
      </ul>
    	<p>Over the last few months, Polygon [MATIC] has witnessed a surge in its network activity, with the same higher than that of Solana [SOL]. However, Solana still dominated Polygon by a significant margin in terms of transactions. Not only that, but SOL’s performance in the DeFi space also remains promising, as its TVL hit this year’s highest level recently. In fact, SOL also outshined MATIC by its price action, as the former overtook the latter in market capitalization.</p>
      <h3>Is Polygon actually ahead of Solana?</h3>
      <p>Artemis’ data pointed out that Solana’s daily active addresses declined over the last three months. This allowed Polygon to overtake Solana in terms of that metric. Moreover, MATIC has consistently managed to have more users on the network to date. However, looking at the whole picture revealed that MATIC is still way behind SOL in the number of daily transactions processed by the networks.</p>	
    	<p>Not only that, Solana is also closing in on Polygon in terms of their captured value. As is evident from Artemis’ chart, Solana’s fees and revenue have gained since mid-July, even flipping Polygon on two occasions.</p>	
    	<p>However, at press time, Polygon’s fees and revenue remained slightly higher than those of SOL. Nonetheless, SOL recently touched a new milestone. DeFiLlama pointed out that SOL’s TVL was moving up and, at press time, was sitting at the highest level since the beginning of the year.</p>
      <h3>A wider look at both blockchains</h3>
      <p>Apart from network activity, both blockchains’ staking ecosystems are also interesting to observe. SOL is way ahead of MATIC in terms of staking market cap, as the former’s figures stood at $9.77 billion, while the latter had a staking market cap of $2.4 billion at press time. While MATIC had a staking ratio of 38.91%, SOL posted a reading of 70.84%. Moreover, the number of staking wallets was also higher on Solana than on Polygon.</p>
      <p>Nonetheless, Santiment’s chart revealed both blockchains have been performing neck to neck in their respective NFT ecosystems. Both MATIC and SOL had similar numbers of NFT trade counts and trade volume in USD, which proves the competition.</p>
      <h3>Bullish price action for Solana?</h3>
      <p>Towards the beginning of May, MATIC’s market cap was well above that of SOL. However, things started to turn sour for MATIC as it bled considerably over the next few weeks. This allowed Solana to take over, as the token flipped MATIC on 6 July. Since then, the latter has been able to reclaim its lead in terms of market cap.</p>
      <p>In fact, as per CoinMarketCap, SOL’s price appreciated by over 7% over the past week. On the contrary, MATIC only managed to hike by 1%. At press time, SOL was trading at $24.77 while MATIC’s value was $0.6794 on the charts.</p>
    </div>
  },
  {
    id: 648,
    title: 'What a major approval means for Tron, TRX investors',
    category: 'Crypto',
    subCategory: ['Tron', 'TRX', 'Investors'],
    description: <div className="blog-desc">
      <p>Last week, Tron approved its committee proposal no. 89, activating the PUSH0 instruction in the TRON virtual machine.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 14, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692003001/ncx-blog-1061_dywf26.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Last week, Tron approved its committee proposal no. 89, activating the PUSH0 instruction in the TRON virtual machine.</p>
      <ul>
        <li>Tron’s social engagement and mentions dropped over the last seven days</li>
        <li>TRX’s price was slow-moving, and the trend might continue</li>
      </ul>
    	<p>Tron [TRX] recently shared its weekly report, highlighting the developments that happened in its ecosystem. Among them, the most notable update was related to a newly passed proposal. While the proposal was being passed, TRX’s price action remained dormant, however, as it failed to move up over the last seven days. Not only that, but the token’s performance on the social front was also not at par. </p>	
    	<h3>Tron approves new committee proposal </h3>
      <p>Tron’s weekly roundup report pointed out that the blockchain approved its committee proposal 89. The proposal was aimed at activating the PUSH0 instruction in the TRON virtual machine (TVM). For starters, it was suggested to implement and enable the PUSH0 instruction in TVM in order to keep it compatible with Ethereum Virtual Machine (EVM) and to share the same advantages with TRON developers and users.</p>
      <p>Mentioning the changes, the PUSH0 instruction in TVM brings both technical and economic benefits. A few of the advantages of the update include a reduction in contract code size, a reduction in the risk of contracts using various instructions as an optimization measure, etc.</p>
      <p>However, the approval did not receive much traction in the crypto-community as TRX’s social metrics dropped last week. LunarCrush’s chart revealed that while TRX’s social engagement dropped by 48%, its social mentions also sank by 30%. On top of that, investors’ confidence in TRX was also low, as its bullish sentiments declined by more than 24%.</p>
      <h3>Not a lot of volatility?</h3>	
      <p>Not only was Tron’s performance on the social front not at par, but its price action also remained less volatile last week.</p>
    	<p>According to CoinMarketCap, TRX’s price has moved by less than 1% over the last seven days. At press time, it was trading at $0.07732 with a market capitalization of more than $6.9 billion, making it the 10th largest crypto by market cap. The sluggish price action caused the token’s 1-week price volatility to remain low through the week.</p>
      <p>Investors’ interest in trading the token also dropped, as is evident from the slight decline in its trading volume over the last few days. However, thanks to the efforts made by developers, the blockchain’s development activity remained high, which by and large is a positive sign.</p>
      <p>If derivatives metrics are to be believed, Tron’s price action might remain underwhelming. Coinglass’ chart revealed that TRX’s open interest remained stable too.</p>
      <p>Additionally, its OI-weighted funding rate was also found to be green. This suggested that investors have been buying TRX at its current price in the Futures market, increasing the chances of TRX continuing its prevailing price trend.</p>
    </div>
  },
  {
    id: 647,
    title: 'Dogecoin: Assessing if the weekend’s hype had any impact',
    category: 'Crypto',
    subCategory: ['Dogecoin', 'Hype', 'Crypto'],
    description: <div className="blog-desc">
      <p>DOGE’s price failed to react despite the uptick in social engagements during the weekend’s trading session.</p>
    </div>,
    authorName: 'Abiodun Oladokun',
    authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: ' August 14, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692002653/ncx-blog-1060_seygtr.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>DOGE’s price failed to react despite the uptick in social engagements during the weekend’s trading session.</p>
      <ul>
        <li>DOGE’s social activity rallied over the weekend’s trading session</li>
        <li>Its price, however, failed to react accordingly</li>
      </ul>
    	<p>One of the market’s leading memecoins, Dogecoin [DOGE] registered high social activity over the weekend. Even so, its price failed to record any significant rally on the charts.</p>	
    	<p>In fact, according to data from LunarCrush, DOGE’s social activity hiked by over 73% on 12 August, with total social engagements of 65 million.</p>	
    	<p>The surge in an asset’s social activity often indicates increased interest in that asset. Depending on market sentiment, it determines the next direction of that asset’s price.</p>
      <p>With a mere 2% jump in price during the weekend’s trading session, the market’s negative sentiment put pressure on DOGE’s price.</p>
      <h3>DOGE’s troubles did not start today</h3>
      <p>Although its price has rallied by 18% over the past month, DOGE’s on-chain data revealed a gradual decline in network activity. According to data from IntoTheBlock, the count of transactions involving DOGE has fallen steeply since 27 July.</p>
      <p>With 229,290 DOGE transactions completed on 12 August, figures for the same have dropped by 56% since the month began.</p>
      <p>As the daily transaction count dwindles, the number of transactions that qualify as whale transactions has also decreased. In fact, IntoTheBlock found that the number of daily DOGE transactions worth between $1 million and $10 million has dropped by 66% over the past month alone.</p>
      <p>While its price has appreciated in the last month, DOGE holders are yet to be incentivized to trade more because the coin remains undervalued, as shown by its Market Value to Realized Value (MVRV) ratio.</p>
      <p>A high and positive MVRV ratio indicates that the market is overvalued, as investors pay more for coins than they are worth. Conversely, when it is low and negative, the market is undervalued, as investors pay less for coins than they are worth.</p>
      <p>DOGE’s MVRV slipped into the negative territory on 20 April and has since remained below the zero line, data from Santiment revealed. With a reading of -10.21% at press time, if all DOGE holders sold their coins at its current price, most traders would realize losses.</p>
      <h3>In DOGE we trust</h3>
      <p>While the memecoin remains a significantly unprofitable venture for most holders, Open Interest continues to climb. In fact, it has trended upwards over the last two months.</p>
      <p>According to Coinglass, DOGE’s Open Interest has hiked by 99% since 12 June.</p>
      <p>Finally, a look at funding rates across exchanges showed that most trade positions occupied since then have been long positions.</p>
      <p>This was a sign that more traders had placed bets in favor of a hike in DOGE’s value. This is a bullish signal, which may help the memecoin’s price rally.</p>
    </div>
  },
  {
    id: 646,
    title: 'Aptos’ TVL growth shades Ethereum, BNB Chain, and Cardano',
    category: 'Crypto',
    subCategory: ['Aptos', 'TVL', 'BNB'],
    description: <div className="blog-desc">
      <p>Aptos defied contagion fears to log TVL growth over the past month, all while other leading L1 chain saw a drop in user activity.</p>
    </div>,
    authorName: 'Abiodun Oladokun',
    authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 13, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1692002265/ncx-blog-1059_emuy9h.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Aptos defied contagion fears to log TVL growth over the past month, all while other leading L1 chain saw a drop in user activity.</p>
      <ul>
        <li>Aptos’ TVL has risen by 29% in the last 30 days</li>
        <li>Following a brief price uptick on 9 August, APT accumulation has flattened</li>
      </ul>
    	<p>In the last month, the decentralized finance (DeFi) vertical of Layer 1 (L1) blockchain network Aptos [APT] has seen significant growth in its total value locked (TVL). </p>	
    	<p>In fact, according to data from Artemis, Aptos’ TVL has grown by over 25% in the last 30 days, while the TVLs of leading L1 chains such as Ethereum [ETH], BNB Chain [BNB], and Cardano [ADA] have all slowed.</p>	
    	<p>Curve’s hack episode of 30 July  triggered fears of a contagion effect across the DeFi ecosystem. This, for example, impacted leading network Ethereum, which suffered a sharp fall in TVL two days after the re-entrancy exploit of the decentralized exchange.</p>
      <p>While other chains have grappled with liquidity exit and decreased user activity as liquidity providers hold onto their funds, protocols on Aptos appear to have been shielded from the aftermath of the hack.</p>
      <p>For example, according to data from DefiLlama, Aptos’ leading protocol in terms of TVL – Thala – has seen an 82% growth in TVL over the last 30 days.</p>
      <h3>It is not all smiles on Aptos</h3>
      <p>Amid the growth in TVL, an assessment of network activity on the chain revealed a corresponding uptick in the daily count of distinct addresses with signed transactions. In fact, according to Aptos Explorer, this has climbed by over 100% in the last month.</p>
      <p>However, while there has been a surge in the number of transaction-executing addresses, the overall count of transactions executed on the chain in the past month has fallen.</p>
      <p>As of 12 August, 606,501 transactions were completed on the L1 network. This represented a 69% drop from the 1.94 million transactions completed on 19 July.</p>
      <p>Moreover, despite the influx of new protocols to the chain over the past few weeks, it is yet to attract new demand. Also, as per Aptos Explorer, the daily count of new addresses created on the chain has declined steadily over the past month.</p>
      <h3>Microsoft juice no more?</h3>
      <p>On 9 August, Aptos announced its partnership with tech giant Microsoft. This resulted in a 10% intraday rally in APT’s price.</p>
      <p>The altcoin, however, has shed most of these gains o verthe past four days. Exchanging hands at $7.16 at press time, the token’s value has since declined by 8%.</p>
      <p>The fall in value has been due to a drop in accumulation following the brief rally on 9 August. Especially as many daily traders sought to profit. On the D1 chart, key momentum indicators have since trended south, suggesting a drop in APT accumulation among these traders.</p>
      <p>However, sell-offs have also been kept at bay, with these indicators still positioned above their respective neutral points. For example, the token’s Relative Strength Index (RSI) and Money Flow Index (MFI) were 51.97 and 59.16, respectively, at press time.</p>
      <p>Likewise, APT’s Chaikin Money Flow returned a positive value of 0.03, suggesting that the market had enough liquidity to prevent any sharp price declines.</p>
    </div>
  },
  {
    id: 645,
    title: 'SHIB surges to new highs amid speculation around the launch of…',
    category: 'Crypto',
    subCategory: ['SHIB', 'Speculation', 'Crypto'],
    description: <div className="blog-desc">
      <p>Anticipation builds for the Shibarium launch as soaring prices mark a vibrant August while transaction dynamics reshape the landscape.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 11, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691743371/ncx-blog-1039_htuwha.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Anticipation builds for the Shibarium launch as soaring prices mark a vibrant August while transaction dynamics reshape the landscape.</p>
    	<ul>
        <li>SHIB has seen an over 40% increase in price since its decline in June.</li>
        <li>The lead developer hints at the potential launch of the Shibarium beta.</li>
      </ul>
      <p>The launch of Shiba Inu’s Shibarium has been a topic of discussion for several months, but no actual launch has occurred. Nevertheless, a recent announcement by the main developer hinted at another upcoming launch.</p>	
    	<p>As a result, the value of SHIB has experienced a more lucrative upward trend since the beginning of August.</p>
      <h3>Shiba Inu surges</h3>	
    	<p>A recent post by Kaiko highlighted Shiba Inu’s positive price trend over the past few weeks. The data indicated a 25% increase in its value since 1 July. Analyzing the SHIB daily chart revealed a noteworthy shift — despite a 12.9% drop on 10 June, the price rebounded by more than 40%.</p>
      <p>August stood out as a particularly strong month, with an almost 15% surge in value noticed in one trading period. The surge marked the highest increase in nearly five months. SHIB demonstrated an additional 2% uptick in value as of this writing. The bullish momentum was evident, supported by the Relative Strength Index (RSI).</p>
      <p>The price movement also attempted to surpass its long Moving Average (blue line), which acted as a resistance point. Conversely, the short Moving Average (yellow line) was the current support.</p>
      <p>This recent bullish trend can be attributed to a post from the lead developer, Shytoshi Kusama, on 31 July. In this post, he indicated that the Shibarium launch was approaching, prompting the need to “mint the remaining supply of BONE and renounce the BONE contract.”</p>
      <h3>The state of the Shiba Inu L2 testnet</h3>
      <p>Examining the Shiba Inu Shibarium using Puppy Scan revealed a cessation in tracking transactions on the testnet. Before this halt, the L2 testnet registered an average of 250,000 daily transactions.</p>
      <p>In light of these daily transaction volumes, the cumulative total reached over 35 million transactions per the latest available record. Additionally, the platform showcased more than 17 million wallets.</p>
      <p>While the precise cause for the scanner’s discontinuation of transaction tracking on the testnet remains uncertain, one plausible explanation could be the upcoming launch of the beta version.</p>
      <h3>SHIB adds more transaction volume as active addresses decline</h3>
      <p>SHIB’s transaction volume experienced modest surges in August. Although these spikes may seem minor, they marked a threshold not attained in over a month. Specifically, on 5 and 7 August, the transaction volume surged past $160 million. Presently, the transaction volume hovered around $32 million.</p>
      <p>On the contrary, the 30-day count of active addresses has not demonstrated the same robustness as other metrics. A decrease in active addresses has been evident since the beginning of the month. At the time of writing, the count of active addresses stood at 114,000.</p>
    </div>
  },
  {
    id: 644,
    title: 'L1 vs L2: Will Ethereum emerge unscathed?',
    category: 'Crypto',
    subCategory: ['L1', 'L2', 'Ethereum'],
    description: <div className="blog-desc">
      <p>L2s are beginning to surge in dominance as L1 cryptocurrencies lag behind. The market cap and sentiment of most tokens remain unaffected.</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 11, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691743088/ncx-blog-1038_dqfiqr.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>L2s are beginning to surge in dominance as L1 cryptocurrencies lag behind. The market cap and sentiment of most tokens remain unaffected.</p>
      <ul>
        <li>L2 solutions have challenged L1 dominance.</li>
        <li>Surge in L2 dominance impacted NFT activity and fees generated.</li>
      </ul>
    	<p>The ever-evolving landscape of the cryptocurrency sector continues to captivate enthusiasts worldwide. One intriguing development is the burgeoning rivalry between Layer 1 (L1) networks and Layer 2 (L2) solutions.</p>	
    	<h3>Unveiling L2’s ascent amidst L1 dominance</h3>
      <p>In a sector notorious for its fierce competition, the recent surge in L2 solutions stirred speculation about its potential to disrupt the reigning L1 networks.</p>
      <p>Despite the established dominance of L1 networks and cryptocurrencies, the spotlight shifted onto the rising prominence of L2s, casting a shadow over the market share of their predecessors.</p>
      <p>Crypto analyst Jamie Coutts’ data indicated that L2 adoption was growing at a faster rate than expected. This growth could prove to be beneficial for the Ethereum [ETH] network. However, it could harm L1s cryptocurrencies in the short term.</p>
      <h3>Taking a deeper look</h3>	
      <p>Within an intricate web of cryptocurrency dynamics, the value beneath the surface is often masked by price fluctuations. The resurgence of growth in L1 networks owes credit to the burgeoning influence of L2 solutions, coupled with a more lenient monetary tightening environment.</p>
      <p>Surprisingly, the sector managed to remain deflationary, even during the bear market, with a pre-Merge inflation rate of 4%. Remarkably, despite cooling market activity, Ethereum staking witnessed a noteworthy 38% upsurge over a mere three-month period.</p>
      <p>Ethereum’s resolute ambition to amplify its scalability via L2 rollups became evident through on-chain insights. Amidst a decline in L1 activity over the past year, utilization of the network surged, propelling its financials into a phase of unprecedented prosperity.</p>
      <p>The past year witnessed a migration of activity to L2 solutions, reshaping the Ethereum landscape remarkably. With a staggering increase of 245% in active addresses, L2 adoption introduced hundreds of thousands of new users to Web3.</p>
      <h3>Fees and NFTs</h3>
      <p>As L2 adoption gathered momentum, Ethereum’s economic landscape underwent a transformation in terms of fees as well. The surge in L2 fees, averaging $600,000 daily, has not only mitigated cannibalized L1 revenue but has also catalyzed the amplification of network effects.</p>
      <p>L1 fees, underpinned by rollups, were projected to represent a substantial chunk of total fees, reaching 20% by the end of the year and a staggering 50% within the next three years, according to the data. However, this growth could be hindered by the progress of L2s.</p>
      <p>The ascendancy of L2s brought consequential shifts in the realm of NFTs as well. The lower fee costs of L2 NFT marketplaces prompted an 80% reduction in total NFT activity on L1 networks, causing a decline in sales volumes for the same.</p>
      <h3>Analyzing the tokenomics</h3>
      <p>In terms of market cap, both L1 and L2 tokens witnessed similar levels of growth. However, Optimism [OP] stood out in this regard and witnessed the most month-on-month activity according to Santiment’s data.</p>
      <p>On the social front, it was seen that the weighted sentiment for both protocols was improving. However, the only exception was Arbitrum, which was the only protocol that witnessed a decline in this area.</p>
      <h3>L2’s impact on Ethereum</h3>
      <p>Beyond the borders of the L2 domain, the Ethereum ecosystem stands to benefit from the burgeoning growth of L2 solutions, heralding a potential era of prosperity.</p>
      <p>Due to the progress made by layer 2 solutions, the volume of activity that they could bring to the Ethereum network could be extremely large. Because of this, gas usage and revenue accrued by Ethereum will remain consistent.</p>
      <p>At press time, ETH was trading at $1850. The number of addresses holding ETH has increased over the past week, signaling increased interest in cryptocurrency.</p>
      <p>The MVRV ratio for Ethereum during this period declined, showcasing that most addresses holding ETH were not profitable at press time.</p>
      <p>The falling MVRV ratio of ETH suggested that the likelihood of ETH being sold was relatively much lower due to lack of an incentive to sell.</p>
    </div>
  },
  {
    id: 643,
    title: 'Cardano’s TVL surges thanks to stablecoin adoption but there’s a catch',
    category: 'Crypto',
    subCategory: ['Cardano', 'TVL', 'Stablecoin'],
    description: <div className="blog-desc">
      <p>Cardano’s TVL embarked on its rally after the launch of the DJED and iUSD stablecoins. Furthermore, ADA’s TVL has been steadily growing since then and it just reached a 12-month high. However, concerns may still loom around ADA’s future.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 11, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691742400/ncx-blog-1037_bgcbwe.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Cardano’s TVL embarked on its rally after the launch of the DJED and iUSD stablecoins. Furthermore, ADA’s TVL has been steadily growing since then and it just reached a 12-month high. However, concerns may still loom around ADA’s future.</p>
    	<ul>
        <li>Cardano’s native stablecoins made significant contributions to its TVL.</li>
        <li>Cardano network activity slows down, influencing ADA demand.</li>
      </ul>
      <p>What are the factors that have been driving Cardano’s growth? Recent research revealed that the network’s TVL grew by 199% in the first half of 2023. It also suggested that stablecoins have been behind this growth.</p>	
    	<p>It is not just any stablecoins that contributed to Cardano’s TVL growth. The latest data suggests that the TVL embarked on its rally after the launch of the DJED and iUSD stablecoins. Interestingly, ADA’s TVL has been steadily growing since then and it just reached a 12-month high.</p>	
    	<p>The fact that the launch of those two stablecoins pegged to the U.S. dollar triggered TVL growth is not surprising. This is because incentives around the two stablecoins encouraged the use of ADA as collateral. For example, DJED is minted when ADA is added to the smart contract reserve pool while iUSD is minted when ADA is added to a CDP.</p>
      <p>The incentive here is that ADA holders that bought the token at discounted prices could use their holdings to access the aforementioned stablecoins. They could then use those stablecoins for other purposes within the Cardano ecosystem.</p>
      <p>Cardano TVL maintained its steady upside in the last few months. It recently peaked at 601.92 million coins. This represented roughly $179.59 million at present market value.</p>
      <h3>Cardano’s metrics reveal this about its performance</h3>
      <p>We explored Cardano’s on-chain metrics and here’s what we found. The blockchain network’s development activity has been on the rise for the last 30 days. Healthy development activity usually goes hand in hand with boosting investor sentiment. Interestingly, Cardano’s weighted sentiment has also been rising in the last four weeks.</p>
      <p>The surge in weighted sentiment confirmed that many analysts anticipate a bullish outcome. However, ADA’s price action has been moving in the opposite direction judging by the selling pressure we observed since mid-July.</p>
      <p>ADA exchanged hands at $0.29 at press time. It struggled to push back above the 5-day moving average due to low momentum. ADA’s price remains relatively subdued and with barely any interesting findings on its price chart. This reflected the low volume that prevailed in the last three weeks or so.</p>
      <p>The low volume also aligned with the declining network activity. The number of daily active addresses has been waning in the last 30 days. In other words, the lack of excitement in the market underscores the low demand for ADA.</p>
    </div>
  },
  {
    id: 642,
    title: 'Aptos stalls at range-high: Can sellers gain more ground? ',
    category: 'Crypto',
    subCategory: ['Aptos', 'Stalls', 'Gain'],
    description: <div className="blog-desc">
      <p>APT was holding steady at the mid-range but could present a shorting opportunity if BTC drops below the $29.5k range-low.</p>
    </div>,
    authorName: 'Benjamin Njiri',
    authorurl: 'https://ambcrypto.com/author/benjamin-njiri/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 11, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691742049/ncx-blog-1036_ztcxtd.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>APT was holding steady at the mid-range but could present a shorting opportunity if BTC drops below the $29.5k range-low.</p>
      <ul>
        <li>APT has been range-bound since 18 July.</li>
        <li>Aptos partnered with Microsoft, setting APT to rally 10%.</li>
      </ul>
    	<p>Aptos Labs has reportedly partnered with Microsoft to facilitate, integrate, and scale Web3 solutions. The native token, Aptos [APT], reacted positively to the news and rallied over 10% on 9 August.</p>	
    	<p>However, Bitcoin [BTC] recorded a sharp retracement ahead of July’s CPI data, denting APT’s recent gains. At the time of writing, BTC bears and bulls tussled for the $29.5k range-low, and any more losses could embolden APT sellers.</p>	
    	<h3>APT bulls and bears tussle for mid-range</h3>
      <p>APT’s Relative Strength Index climbed above the 50 mark, reinforcing a surge in demand and buying pressure on 9 August. Similarly, the Chaikin Money Flow also crossed above the zero mark – a positive reading illustrating improved capital inflows into APT’s market.</p>
      <p>However, price action forayed into the resistance area after Wednesday’s pump. APT has been range-bound since July 18, oscillating between $6.67 – $7.97. The pump faced rejection at the range-high ($7.97).</p>
      <p>Above the range-high lies a weekly bearish order block (OB) of $8.2 – $9.4 (red). In most cases, bearish OBs lead to negative price reactions.</p>
      <p>In this case, the weekly OB is on a higher timeframe and aligns with the previous range-high in May 2023. That makes the $7.97 – $9.5 a crucial bearish zone. Another likely price rejection in the area could set APT to head lower, presenting two possible short set-ups.</p>
      <p>The first set-up is shorting upon a retest of the range-high ($7.97), with take-profit at mid-range ($7.3). Secondly, shorting at the mid-range ($7.3), with take-profit at the range-low ($6.67) – a likely +8% profit. Stop losses will be placed at $8.4 and $7.6, respectively.</p>
      <p>A daily candlestick session close above weekly bearish OBs will invalidate the bearish thesis.</p>
      <h3>Open Interest rates surge</h3>
      <p>The Futures market data suggested the short ideas above could be far-fetched at the time of writing. Notably, Open Interest rates surged from $75 million on 9 August to over $150 million – more than double demand.</p>
      <p>Additionally, the Cumulative Delta Volume (CVD), which tracks sellers vs buyers control, showed a massive positive slop after a little dump. It illustrates that bulls were in control and renders the above short ideas risky.</p>
      <p>However, BTC’s price action will dictate APT’s price direction. A drop below or rally above the $29.5k range-low will confirm or invalidate the short ideas.</p>
    </div>
  },
  {
    id: 641,
    title: 'Slowing US inflation data inspires hope for a crypto price rally',
    category: 'Crypto',
    subCategory: ['US', 'Inflation', 'crypto'],
    description: <div className="blog-desc">
      <p>Bitcoin dipped on Friday morning in Asia, falling below the US$29,500 threshold. Ether also edged down as other top 10 non-stablecoin cryptocurrencies traded mixed.</p>
    </div>,
    authorName: 'Danny Park',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 11, 2023 - 5:15 AM', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691741523/ncx-blog-1035_t5qac6.webp',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin dipped on Friday morning in Asia, falling below the US$29,500 threshold. Ether also edged down as other top 10 non-stablecoin cryptocurrencies traded mixed. Market experts expect Bitcoin and Ether to remain steady in the short term ahead of a potential rally backed by easing macroeconomic conditions. The Forkast 500 NFT Index was up, while in the U.S., core inflation data came in lower than expected. That strengthened bets the Federal Reserve will pause interest rate hikes, while global equities were mixed.</p>
      <h3>Cryptos steady</h3>
    	<p>Bitcoin dropped 0.39% in the last 24 hours to US$29,439.41 as of 6:30 a.m. in Hong Kong but gained 0.95% for the week, according to CoinMarketCap data. The world’s largest cryptocurrency by market capitalization hovered around the US$29,500 line after reaching a high of US$29,688 overnight.</p>	
    	<p>Ether also edged down 0.09% to US$1,851.14 but posted a 1.08% weekly increase.</p>	
    	<p>“As we digest CPI numbers, the price of BTC and ETH has barely moved,” said Lucy Hu, senior trader at Hong Kong-headquartered digital asset management platform Metalpha. “We expect the major pairs BTC and ETH may continue to trade in a tight range, and likely trend higher.”</p>
      <p>The U.S. headline Consumer Price Index (CPI) released by the Labor Department on Thursday gained 0.2% in July, raising the inflation indicator to 3.2%. That is lower than the expected 3.3% reported by Reuters.</p>
      <p>“The CPI headline is an important one, which signals that we’re most likely at the end of the hiking curve from the Fed,” said Michaël van de Poppe, the chief executive officer of Amsterdam-based crypto trading company MN Trading.</p>
      <p>“Crypto native people are always eagerly looking at this event for forecasts and therefore, given that the outline is lower than expected, having a case where price starts to rally is a likely case,” said van de Poppe. He added that investors should now examine the U.S. Producer Price Index data released Friday as another key measure of wholesale inflation.</p>
      <p>Analysts have also circled Aug. 13 — the next deadline for ARK Investment Management’s spot Bitcoin exchange traded fund (ETF) application in the U.S. — as another key date on the market calendar.</p>
      <p>ARK initially made the application in April, and then filed an additional amendment in July.</p>
      <p>ARK Invest CEO Cathie Wood told Bloomberg Monday that the U.S. Securities and Exchange Commission will likely approve multiple spot crypto ETF applications at once, if they do decide to give them the green light.</p>
      <p>Other top 10 non-stablecoin cryptocurrencies traded mixed on Friday. Solana led the winners, adding 1.59% to US$24.70 for a bullish weekly gain of 9.56%. Tron also gained 1.15% to US$0.07717, adding 0.52% in the last seven days.</p>
      <p>The total crypto market capitalization dipped 0.24% in the past 24 hours to US$1.17 trillion, while trading volume also declined 26.24% to US$26.47 billion.</p>
      <h3>DeGods NFT collection surges 740% with new update</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>The main Forkast 500 NFT index rose 0.57% in the past 24 hours to 2,501.74 as of 09:15 a.m. in Hong Kong. It recorded a 1.24% gain for the week. Meanwhile, Forkast’s Ethereum, Polygon and Cardano NFT indexes logged losses, while the index measuring Solana NFTs gained.</p>
      <p>Total NFT trading volume gained 21.42% in the past 24 hours to over US$18.87 million, according to data from CryptoSlam.</p>
      <p>Sales volume on Ethereum, the largest NFT network, jumped 53.44% to US$12.37 million. Polygon and Solana, which placed second and third on Cryptoslam’s NFT blockchain ranking, both logged losses.</p>
      <p>In terms of NFT collections, trade volume of  Ethereum-based DeGods surged 740.14% to US$3.33 million. The digital art collection announced new updates by the name “Season III,” where one DeGod NFT holder receives four generative artworks and other perks.</p>
      <p>However, Yehudah Petscher, NFT Strategist at Forkast Labs, said the DeGods update was very poorly received by the community.</p>
      <p>“Instead of offering a free mint, or even affordable mint, the cost to upgrade your existing NFT to the new Season III art is 333 $DUST, which is around .4 ETH,” wrote Petscher.</p>
      <p>As of Friday morning in Asia, 0.4 ETH amounts to US$739.35.</p>
      <p>“When projects like the CyberKongz fail to sell out at .25 ETH and are told that’s too expensive in this market, it’s hard to imagine who would think .4 ETH would work right now,” Petscher added.</p>
      <p>Also, Opepen and Checks NFT collection maker Jack Butcher’s digital art collection, Infinity, was hacked Thursday, Butcher’s collaborator Jalil.eth posted on Twitter.</p>
      <p>The hack drained 38.56 ETH (US$71,273.15) from the wallet.</p>
      <h3>US inflation not yet conquered</h3>
      <p>U.S. stock futures traded mixed to flat as of 11:30 a.m. in Hong Kong, after the stock market closed lower on Wednesday.</p>
      <p>Main stock indexes in Asia were also mixed on Friday — China’s Shanghai Composite, Hong Kong’s Hang Seng logged losses, while Japan’s Nikkei 225 and South Korea’s Kospi gained.</p>
      <p>July’s U.S. consumer price index (CPI), released Thursday, rose to 3.2%, lower than the expected 3.3%. That strengthened bets the U.S. Federal Reserve will pause its current cycle of interest rate hikes.</p>
      <p>San Francisco Fed president Mary Daly said Thursday in a Yahoo! finance interview that the latest CPI data does not mean the central bank has conquered inflation. She added that the Fed remains fully committed to reducing inflation to its 2% target.</p>
      <p>The Fed meets on Sep. 19 to make its next move on interest rates, which are now between 5.25% to 5.50%, the highest level in the past 22 years. Analysts at the CME FedWatch Tool predict a 89.0% chance there will be no interest rate hike in September, up from 87.0% on Thursday.</p>
      <p>Investors now await July’s Producer Price Index (PPI) — a key inflation indicator that monitors selling prices received by domestic producers of goods and services — which is set to be announced later on Friday.</p>
      <p>Stocks in China and Hong Kong fell amid mounting Sino-U.S. tension. U.S. President Joe Biden called China a “ticking time bomb in many cases” on Thursday at a political fundraiser in Utah, pointing to its weak economic growth.</p>
      <p>On Wednesday, Biden signed an executive order to regulate U.S. investment in Chinese entities engaged in three sectors: semiconductors and microelectronics, quantum information technologies, and artificial intelligence.</p>
    </div>
  },
  {
    id: 640,
    title: 'Bitcoin, Ether rise near key levels, Sol leads gains across top 10 cryptos',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Ether', 'Sol'],
    description: <div className="blog-desc">
      <p>Bitcoin and Ether rose during Wednesday afternoon trading in Hong Kong, along with all other top 10 non-stablecoin cryptocurrencies by market capitalization. With over 69% of Bitcoin supply inactive for over a year, long-term investors remain bullish on the world’s first cryptocurrency, industry experts told Forkast.</p>
    </div>,
    authorName: 'Zoltan Vardai',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 9, 2023 - 1:50 PM', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691743881/ncx-blog-1040_ngypsx.webp',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin and Ether rose during Wednesday afternoon trading in Hong Kong, along with all other top 10 non-stablecoin cryptocurrencies by market capitalization. With over 69% of Bitcoin supply inactive for over a year, long-term investors remain bullish on the world’s first cryptocurrency, industry experts told Forkast.</p>
    	<h3>Bitcoin, Ether near significant support levels as Solana leads winners</h3>
      <p>Bitcoin was little changed during afternoon trading in Asia, trading at US$29,779 as of 4:30 p.m. in Hong Kong after it fell below the US$30,000 support level on July 24.</p>
      <p>“Recent data reveals that a staggering 69.2% of the total Bitcoin supply has remained untouched for over a year. This trend showcases a robust belief in Bitcoin’s long-term value, even amidst the notorious downturns that have characterized the cryptocurrency market over the past year,” according to a Bitfinex research report, shared with Forkast.</p>	
    	<p>“With a significant chunk of Bitcoin’s supply remaining inactive, the available supply in the market diminishes. This inherent scarcity, juxtaposed with burgeoning demand, has the potential to drive prices upward. The unwavering faith in Bitcoin paints a bullish picture for the cryptocurrency’s future.”</p>	
    	<p>Lifted by Bitcoin, Ether rose 0.26% during afternoon trading in Asia to US$1,860 from US$1,869 earlier today.</p>
      <p>Solana’s SOL token was the day’s biggest winner in the top 10, gaining 5.83% in the past 24 hours to US$24.60, followed by Polygon’s Matic, up 3.64% to US$0.6931.</p>
      <p>Total crypto market capitalization over the past 24 hours rose 1.86% to US$1.18 trillion while market volume increased 24.90% to US$40.37 billion, according to CoinMarketCap data.</p>
      <h3>DraftKings is day’s largest NFT collection by sales volume</h3>
      <p>The Forkast 500 NFT index fell 0.36% to 2,473.76 points in the 24 hours to 4:30 p.m. in Hong Kong but fell 1.22% during the week.</p>
      <p>Bitcoin’s 24-hour non-fungible token sales declined for a third consecutive day, falling 23.81% to US$286,819 with the network slipping to the ninth largest blockchain by 24-hour NFT sales volume, according to CryptoSlam.</p>
      <p>Polygon-native NFT collection DraftKings became the largest NFT collection by 24-hour sales volume across all chains, rising 23.84% to US$4.5 million, lifting Polygon to the second-largest network by 24-hour sales volume, as NFT sales on the network rose 500% to US$5.29 million. The sales increase lifted the Forkast Pol NFT Composite up 2.97% in the past 24 hours.</p>
      <p>“We’re really beginning to see the type of NFTs that do well in this market, which is cheaper NFTs with high volume. DMarket, Gods Unchained, Sorare and DraftKings all represent a form of gaming, all are pretty affordable, and all have thousands of traders. This is what the future of NFTs will really look like,”  said Yehudah Petscher, NFT strategist for Forkast Labs.</p>
      <p>Ethereum’s 24-hour NFT sales continued yesterday’s recovery, rising 34.58% to US$9.75 million, while sales for the largest Ethereum-native NFT collection, the Bored Ape Yacht Club, rose 1.06% to US$834,460. Its twin collection, the Mutant Ape Yacht Club, rose 20.19% to US$6541,420.</p>
      <p>Among Forkast Labs NFT indexes, the Forkast SOL NFT Composite and the Forkast CAR NFT Composite were the only ones in the red for the day.</p>
      <h3>Asian equities fall, U.S. stock futures rise, despite concerns around the banking sector</h3>
      <p>Major Asian equities declined as of 4:30 p.m. in Hong Kong except for Hong Kong’s Hang Seng Index that rose for the first time this week. Japan’s Nikkei 225, the Shenzhen Component and the Shanghai Composite all posted declines.</p>
      <p>Investor sentiment took a hit after Moody’s Investor Service lowered the credit rating of 10 small and midsize U.S. banks citing funding risks and weaker profitability. The ratings agency warned that it could downgrade another six major U.S. banks.</p>
      <p>Despite concerns around the banking sector, U.S. stock futures recovered during Wednesday afternoon trading in Hong Kong, with the Dow Jones Industrial Average futures, the S&P 500 futures index and the tech-heavy Nasdaq-100 futures all posting gains.</p>
      <p>Traders now look forward to the release of U.S. consumer price index data for July, scheduled for tomorrow, to gauge the Fed’s future monetary policy decisions. Bloomberg analysts expect the core CPI to rise 0.2% in July, which would be the smallest monthly increase in the past two and a half years.</p>
      <p>On the corporate front, investors are now anticipating earnings from companies like Walt Disney Company, Sony, Honda, and Roblox Corporation.</p>
      <p>Over in Europe, equities rose on Wednesday, with the DAX 40 gaining 1.17% and the pan-European Stoxx 600 index rising 0.92%, recovering from yesterday’s slump.</p>
    </div>
  },
  {
    id: 639,
    title: 'Cardano: Why did users decline in Q2?',
    category: 'Crypto',
    subCategory: ['Cardano', 'ADA', 'Crypto'],
    description: <div className="blog-desc">
      <p>Network activity on Cardano trickled downwards in Q2, while transactions count and protocol revenue surged.</p>
    </div>,
    authorName: 'Abiodun Oladokun',
    authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 6, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691319331/ncx-blog-1015_flwptp.jpg',
    content: <div className="blog-content">
    	<h3></h3>
    	<p>Network activity on Cardano trickled downwards in Q2, while transactions count and protocol revenue surged.</p>
      <ul>
        <li>Network activity suffered a decline on Cardano in Q2.</li>
        <li>Meanwhile, DeFi TVL and network revenue rallied.</li>
      </ul>
    	<p>Layer-1 smart contract network Cardano [ADA] saw a slight decline in network activity during the second quarter of 2023, according to a new report by Messari.</p>	
    	<p>However, other key ecosystem growth metrics rallied, including the number of daily on-chain transactions, the Total Value Locked (TVL) in Cardano DeFi protocols, and the chain’s revenue.</p>	
    	<h3>Cardano failed to attract new users in Q2 because…</h3>
      <p>Despite the growth in the number of decentralized finance (DeFi) protocols that launched on Cardano between 1 April and 30 June, the Proof-of-Stake (PoS) network experienced a decline in the count of daily active addresses and the number of new addresses created daily.</p>
      <p>According to the report, in Q2, the average daily active address count on Cardano was 57,821. This represented a 4% decrease from the 60,208 recorded as the average daily active address count in Q1. Messari acknowledged:</p>
      <p><strong>“This marks the fourth QoQ decrease in address activity in the past five quarters.”  </strong></p>
      <p>While many expected the new DeFi protocols to bring in new users to the Cardano blockchain, the reverse was the case. Per Messari, the average daily new addresses fell by 103% during the period under review.</p>
      <p>However, in spite of all this, the number of transactions completed daily increased. As confirmed in the report:</p>
      <p><strong>“Transaction activity, on the other hand, did increase in Q2. Average daily transactions were up 1.9% QoQ from 67,500 to 68,800.”</strong></p>
      <p>Although a network’s transaction count and its active addresses count are typically correlated and often trend in the same direction, Messari found that for Cardano, they “have deviated slightly in recent quarters.” While many may naturally think this concerning, Messari added:</p>
      <p><strong>“The ratio of transactions to active addresses has been growing steadily over the past five quarters, suggesting that the average user is more active now than they previously were.”</strong></p>
      <p>This suggests that the decline in active addresses suffered in Q2 was not a result of a decrease in user activity but instead due to a reduction in the number of users who are simply holding ADA and not transacting with it.</p>
      <h3>Shout out to the stablecoins</h3>
      <p>In the quarter under review, the TVL held across the DeFi protocols housed with Cardano increased by 7%. The TVL growth occurred despite a persistent decline in ADA’s value in Q2.</p>
      <p>According to Messari:</p>
      <p><strong>“Stablecoins, arguably the most important piece of infrastructure that is fueling this TVL run, also grew in both absolute and relative terms. The total stablecoin market cap grew 34.9% QoQ from $10.0 million to $13.5 million. Relative to other networks, Cardano’s stablecoin market cap moved up from 54th to 37th in 2023.”</strong></p>
      <p>Regarding the chain’s revenue, Cardano’s quarterly revenue increased by double digits. In Q2, Cardano’s revenue totaled $788,290. </p>
    </div>
  },
  {
    id: 638,
    title: 'Ethereum’s ‘quiet time’ should not be taken for weakness: Analysts',
    category: 'Crypto',
    subCategory: ['Ethereum', 'crypto', 'Analysts'],
    description: <div className="blog-desc">
      <p>These on-chain analysts opined that an opportunity lies in the ETH market from a technical perspective.</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 5, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691318624/ncx-blog-1014_bit7uz.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>These on-chain analysts opined that an opportunity lies in the ETH market from a technical perspective.</p>
      <ul>
        <li>Although ETH displayed signs of weakness, it had the potential to surge.</li>
        <li>The altcoin’s volatility was low as the price continued to drop.</li>
      </ul>
    	<p>For some time, Ethereum [ETH] has been experiencing a relatively subdued period in the market, with concerns arising about its price action. However, analysts are cautioning against interpreting this phase as a weakness in the altcoin.</p>
      <p>Some of those backing ETH to thrive include Glassnode co-founders Jan Hapell and Yann Allemann. The duo, who operate on Twitter under the “Negentropic” username, noted that Bitcoin [BTC] might shine going forward.</p>
      <h3>The calm before ETH storms</h3>
      <p>The analysts argued that ETH’s apparent calmness should not be underestimated, as there could be a” golden mid-term opportunity.” Hapell and Allemann, in their tweet, also shared an image of the ETH/USD chart, indicating that the altcoin could be set for a breakout.</p>
      <p>Over the last seven days, ETH has been hovering around $1,835 and has been unable to hit $1,900 within the said timeframe. But do metrics agree that an ETH breakout is on the horizon?</p>
      <p>Well, from Glassnode’s data, ETH’s seller exhaustion constant was down to $0.097. The seller exhaustion constant is the product of the 30-day price volatility and the coin supply in profit.</p>
      <p>Originally created by ARK Invest and David Puell, the metric also checks for capitulation and bottoms. So the drop indicates low volatility and high losses. Thus, ETH could be close to its lowest value in the current market cycle.</p>
      <h3>Is ETH at a fair price?</h3>
      <p>Additionally, the Network Value to Transaction (NVT) signal rose mildly after a recent fall to 82.92. As a modified version of the NVT Ratio, the NVT signal checks if a blockchain network is overvalued or not by using the 90-day Moving Average (MA).</p>
      <p>If the metric jumps extremely high, it means that the asset is overvalued. But ETH’s NVT increase was not exactly significant. So, the coin can be said to be at a fair value.</p>
      <p>As per the price action, the ETH/USD 4-hour chart showed that the altcoin held support at $1,831. Despite numerous attempts to push the price upwards, selling pressure has ensured that ETH gets rejected at $1,857.</p>	
    	<p>The Exponential Moving Average (EMA) also gave an insight into what to expect from ETH in the short term. At press time, the 20 EMA (blue) was below the 50 EMA (yellow).</p>
      <p>If the 20 EMA is above the 50 EMA, the trend is bullish. But if the 50 EMA is above 20 EMA, the trend is bearish. Therefore, the odds are on ETH to decrease before any run up the charts.</p>	
    </div>
  },
  {
    id: 637,
    title: 'Shiba Inu decouples from the market; Is this catalyst why?',
    category: 'Crypto',
    subCategory: ['Shiba', 'decouples', 'market'],
    description: <div className="blog-desc">
      <p>Shiba Inu and Bone ShibaSwap have registered massive growth in terms of price over the last seven days as the Shibarium launch draws closer.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 5, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691318143/ncx-blog-1013_k2ncqj.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Shiba Inu and Bone ShibaSwap have registered massive growth in terms of price over the last seven days as the Shibarium launch draws closer.</p>
      <ul>
        <li>SHIB’s market indicators and on-chain metrics revealed what was working in its favor.</li>
        <li>Shibarium’s hype was also responsible for turning things bullish.</li>
      </ul>
    	<p>Shiba Inu [SHIB] has decoupled from the crypto market, as its daily and weekly charts were painted green. Unlike most cryptos, SHIB managed to push its price up in a bearish market. A catalyst for this growth could be the upcoming ETH Toronto event, which will include announcements regarding its much-hyped Shibarium.</p>
      <h3>Shiba Inu’s graph is on fire</h3>	
    	<p>While most of the crypto market remained under the bears’ influence, Shiba Inu decoupled. As per CoinMarketCap, SHIB’s price rose by more than 5% and 10% in the last 24 hours and the past week, respectively.</p>
      <p>At the time of writing, it was trading at $0.000008651 with a market capitalization of over $5.1 billion, making it the 14th largest crypto.</p>
      <p>Along with the price, SHIB’s 24-hour trading volume also shot up by 34%, which was bullish. A look at SHIB’s daily chart gave a better understanding of what supported this surge.</p>
      <p>The Exponential Moving Average (EMA) Ribbon displayed a bullish crossover a few days ago. Moreover, Shiba Inu’s Relative Strength Index (RSI) gained upward momentum.</p>
      <p>SHIB’s Chaikin Money Flow (CMF) also spiked and went above the neutral mark.</p>
      <h3>Shiba Inu remain whales’ favorite </h3>
      <p>Interestingly, whale activity around SHIB also increased, as evident from the spike in its whale transaction count. Moreover, Whalestats’ data revealed that the memecoin was also the top traded token among the top 100 Ethereum whales in the last 24 hours.</p>
      <p>Thanks to the price uptick, the memecoin’s MVRV Ratio also increased. However, it was surprising to see a dip in SHIB’s social volume, considering its immense popularity. The metric registered a dip over the last few days.</p>
      <p>Therefore, there is a possibility of SHIB’s price action turning bearish in the coming days.</p>	
    	<p>While SHIB enjoyed price gains, BONE ShibaSwap [BONE] also had its share of price upticks. Notably, BONE outperformed SHIB on the weekly chart. CoinMarketCap’s data further pointed out that BONE was up by more than 23% over the last week. At press time, it was valued at $1.65 with a market cap of over $379 million.</p>
      <h3>The Shibarium hype</h3>
      <p>Though SHIB’s latest uptrend can be attributed to bullish market indicators and metrics, due credit should also be given to the upcoming event. On 15 and 16 August, the Ethereum Toronto event will take place, where major announcements are expected to be made for Shibarium.</p>
      <p>As the event date draws closer, Shiba Inu’s marketing team is leaving no stone unturned in building up the hype. LUCIE recently posted a tweet highlighting Shibarium’s aim and how the blockchain plans to integrate Self-Sovereign Identity (SSI) into its protocols.</p>
      <p>As per the tweet, the integration of SSI is aimed at enhancing the security of the Shibarium. Users will have more control over their digital identities thanks to SSI, which enables them to securely manage and share their personal data.</p>
      <p>As the hype continues to grow, it will be interesting to see how SHIB and other tokens in its ecosystem react over the days to follow.</p>
    </div>
  },
  {
    id: 636,
    title: 'Bitcoin may be ready for a rebound – Here’s why',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'crypto', 'rebound'],
    description: <div className="blog-desc">
      <p>Key indicators suggest that Bitcoin could be coming out of its shell, which has been enclosed in a decline thus far.</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 4, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691317501/ncx-blog-1012_ef3kpo.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Key indicators suggest that Bitcoin could be coming out of its shell, which has been enclosed in a decline thus far.</p> 
      <ul>
        <li>Bitcoin’s utility has increased amid recent losses, signaling a potential increase in value.</li>
        <li>Liquidity decreased, but BTC may not drop near $20,000 anytime soon.</li>
      </ul>
    	<p>As the flagship digital asset, Bitcoin [BTC] may be showing signs of a potential rebound after facing a period of consolidation and downward pressure. The recent dip, which led BTC below $28,000, has caused a change in market sentiment, leading some to question the presence of an early bull market.</p>	
    	<h3>Sights on recovery</h3>
      <p>However, Santiment revealed that there has been an increase in active addresses, such that the metric as of 3 August surpassed the heights of the last three and a half months. The rise in active addresses suggests a surge in utilizing BTC in transactions.</p>
      <p>But it wasn’t just that. The increase coincided with negative sentiment and major transactions in losses. Historically, this is evidence that the period of consolidation could soon come to an end. In turn, BTC might bounce back in the upward trajectory.</p>
      <p>From the chart shared above by the on-chain analytics platform, on-chain volume in profit to loss was down to -0.155. As a key indicator of profitability in the market, this decline implies that loss-taking volume was overwhelming profits made.</p>
      <p>Furthermore, the 30-day Market Value to Realized Value (MVRV) ratio was also negative. The MVRV ratio measures the relationship between the market and realized capitalizations. Values in the positive region indicate an advancement toward the market top.</p>
      <p>Conversely, when the metric is in negative territory, it implies that BTC could be near the bottom. So, in its press time state, BTC had more tendency to recover than slide again. Another metric to consider in this regard is volume.</p>
      <h3>The pace is being established</h3>	
    	<p>As of this writing, the volume was 12.32 billion. While this metric decreased, it has previously risen to 20.05 billion on 2 August, indicating increased liquidity. Therefore, if the volume experiences another hike, it could set the tone for BTC’s bounce.</p>
      <p>Meanwhile, Bitcoin’s realized cap was $396.98 billion, according to CryptoQuant. This metric attempts to measure the value of a coin by comparing the network value and the on-chain volume-weighted price.</p>
      <p>When compared with the market cap, on-chain data showed that the market value was far ahead at $567.11 billion. Typically, when the market cap and realized cap are similar in value or the latter is a little higher, it could signal closeness to the market bottom.</p>
      <p>But in this case, BTC may have the potential to rebound. However, considering the press time price as the bottom may not be valid. Hence, if BTC decreases again, it might still be around the $25,000 region.</p>
    </div>
  },
  {
    id: 635,
    title: 'Ripple gives two cents on SEC ruling, locks new set of XRP',
    category: 'Crypto',
    subCategory: ['Ripple', 'XRP', 'SEC'],
    description: <div className="blog-desc">
      <p>Ripple’s official response to the partial win against the SEC called the regulatory’s authority’s lawsuit nothing but a propaganda. Days after the win, XRP didn’t seem in the best of spirits.</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 3, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691075606/ncx-blog-1006_oowiap.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Ripple’s official response to the partial win against the SEC called the regulatory’s authority’s lawsuit nothing but a propaganda. Days after the win, XRP didn’t seem in the best of spirits.</p>
    	<ul>
        <li>The blockchain firm, Ripple, pointed out that both the judgment protects institutions and retailers.</li>
        <li>Ripple sent 800 million XRP to its escrow wallet.</li>
      </ul>
      <p>Ripple [XRP] finally gave an in-depth response to the partial win it had against the U.S. SEC on 13 July. Although the firm expressed its gladness shortly after the ruling, its latest Q2 market report provides a lot of clarity on certain misconceptions about the case.</p>	
    	<p>While Ripple published the report on 31 July, it only went public on 2 August. According to Ripple, SEC’s lawsuit against it was misguided and a “quest for political power.”</p>
      <h3>Clarifying misconceptions</h3>	
    	<p>However, Ripple also addressed some misinterpretations of the judgment. According to the firm, XRP is not just security in some corners but in all areas. Accusing the SEC of being a propaganda merchant, Ripple said,</p>
      <p><strong>“The statement that the same “thing” can be sold to buyer “A” as part of a simple buy/sell transaction and also sold to buyer “B” as part of an investment contract is a non-controversial statement of the law. What finds no basis in the law is the SEC’s propaganda to the contrary.”</strong></p>
      <p>Ripple also addressed some comments that pitched their tent with the notion that the judgment favored institutional investors over the retail cohort. The blockchain company explained that the sentiment was nowhere true saying that,</p>
      <p><strong>“Consumers need protection, but not all roads lead to the SEC. If the SEC’s overreach has exposed a regulatory gap, that gap is not for a court to fill (and certainly not for the SEC to fill without legal authority).”</strong></p>
      <p>Meanwhile, the crypto wallet sleuth Whale Alert noted that Ripple once again locked 800 million XRP by sending it into an escrow wallet.</p>
      <h3>XPR locked again</h3>
      <p>As a rule of thumb, Ripple locks XRP occasionally to ensure that market there is a balance between supply and demand in the market. In the report, Ripple also mentioned its XRP balance on the ledger, and in escrow. The report shed light on its holdings:</p>
      <p><strong>“XRP that it currently has available in its wallets, and XRP that is subject to on-ledger escrow lockups that will be released each month over the next 42 months.”</strong></p>
      <p>Ripple CEO Brad Garlinghouse also commented on the report. He noted that the SEC had once used the firm’s transparency against it. But this time, the regulatory agency cannot do that.</p>
      <p>Lately, another judge handling the Terraforms Labs matter opined that the Ripple ruling was wrong. As of this writing, the sentiment around XRP was no more as optimistic as it was before.</p>
      <p>As of this writing, XRP’s 30-day performance was a 43.16% hike— a drawdown from the incredible rise when Judge Torres declared XRP a partial winner.</p>
    </div>
  },
  {
    id: 634,
    title: 'Why LTC’s post halving price action may leave investors feeling bitter',
    category: 'Crypto',
    subCategory: ['LTC', 'havling', 'Investors'],
    description: <div className="blog-desc">
      <p>A trip down memory lane reveals that Litecoin’s previous halving events in 2015 and 2019 did not yield much of an impact on its price action. In 2019 the price continued dipping days after the halving, A confirmation that the halving barely influenced the price</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 3, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691075190/ncx-blog-1005_gvl8lt.png',
    content: <div className="blog-content">
	    <h3>In this article:</h3>
	    <p>A trip down memory lane reveals that Litecoin’s previous halving events in 2015 and 2019 did not yield much of an impact on its price action. In 2019 the price continued dipping days after the halving, A confirmation that the halving barely influenced the price</p>
	    <ul>
        <li>Litecoin successfully goes through its third halving event.</li>
        <li>LTC extends its dip contrary to the bullish expectations around the halving.</li>
      </ul>
      <p>The highly anticipated Litcoin halving is finally completed and it is rather anticlimactic. This is because there were heavy expectations regarding the event based on what it does for LTC’s scarcity.</p>	
	    <p>Before we get into the details, it is important to understand the core concept behind halvings. It happens when the block rewards issued for transaction validation in a proof-of-work network are slashed by half.</p>
      <p>In Litecoin’s case, the halving will reduce the amount of LTC released in circulation after every four years. Doing so will lower the impact of inflation on LTC while maintaining a low supply.</p>
      <h3>Litecoin’s price action during previous halvings reveals something interesting</h3>	
    	<p>The aforementioned changes underscore one of the main reasons for the bullish expectations that have prevailed recently. Unfortunately, LTC’s actual performance in the days leading up to Litecoin’s latest halving has been rather underwhelming. However, this is not surprising, especially from a historic perspective.</p>
      <p>A trip down memory lane revealed that Litecoin’s previous halving events in 2015 and 2019 did not yield much of an impact on its price action. In 2019 the price continued dipping days after the halving, A confirmation that the halving barely influenced the price.</p>
      <p>Meanwhile, IntoTheBlock just released a comparison of some of the key metrics comparing the state of Litecoin in 2019 versus 2023.</p>
      <h3>LTC bears prevail amid a successful halving</h3>
      <p>Interestingly, LTC’s price action maintained a similar outcome. One would anticipate a bullish outcome given the importance of the halving and the hype around it but that was not the case.</p>
      <p>Instead, LTC extended the bearish performance that has prevailed since the start of July. LTC exchanged hands at $87.59 after dipping by 6.7% in the last 24 hours.</p>
      <p>As far as on-chain observations were concerned, we saw a bit of an uptick in the total open interest in USD. But the most notable change was the surge in social volume especially in the last 24 hours, soaring to a monthly high.</p>
      <p>Despite this social volume surge, on-chain volume only managed a slight uptick.</p>
      <p>Assessing LTC’s buy or sell pressure revealed that there was a slight uptick in realized cap in the last two days. This reflected the prevailing sell pressure as observed in the last 24 hours, which means short-term profit-taking was still active.</p>
      <p>On the other hand, the mean coin age has been ascending steadily for the last four weeks.</p>
      <p>The ascending mean coin age confirmed that there was a healthy amount of hodling going on in the LTC community despite the unfavorable price action.</p>
    </div>
  },
  {
    id: 633,
    title: 'ETH tumbles despite the cheer around Ethereum’s ETF license. Assessing…',
    category: 'Crypto',
    subCategory: ['ETH', 'ETF', 'Despite'],
    description: <div className="blog-desc">
      <p>Ethereum faces a bearish storm amid shifting tides, but an influx of new traders through its ETF could change its direction.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 3, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691074517/ncx-blog-1004_gm2sig.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Ethereum faces a bearish storm amid shifting tides, but an influx of new traders through its ETF could change its direction.</p>
     <ul>
        <li>Six institutions have filed applications for Ethereum ETF.</li>
       <li>ETH and S&P 500 prices trend separately as stocks gain more value.</li>
     </ul>
    	<p>A recent report showed that certain financial institutions have begun applying for an Ethereum ETF. As ETH gains recognition as an asset class in traditional finance, its price trend could be affected.</p>	
    	<h3>Ethereum ETF on the cards</h3>
     <p>According to Eric Balchunas, a senior ETF analyst at Bloomberg, six companies are applying for Ethereum futures ETFs. On 1 August, Volatility Shares, Bitwise, Roundhill, VanEck, Proshares, and Grayscale filed applications for these ETFs.</p>
     <p>An Ethereum ETF is an exchange-traded fund that includes ETH or assets linked to its price. Unlike cryptocurrencies that are traded on dedicated cryptocurrency exchanges, an ETH ETF is traded on a traditional exchange.</p>
     <p>The functioning of an ETH ETF is similar to any other ETF. Investors can purchase shares in the ETF through their brokerage, just like they buy stocks, and can trade these shares in the same manner.</p>
     <p>The advantage of an Ethereum ETF is that investors don’t need to be concerned about private keys, storage, or security related to holding cryptocurrencies. Instead, they own shares in the ETF, providing them exposure to the cryptocurrency market without the complexity of purchasing and holding actual crypto assets.</p>
     <h3>Current Ethereum and S&P 500 correlation</h3>
     <p>The crypto-S&P 500 correlation chart revealed that Ethereum exhibited a strong correlation with stocks from early June to late July. During this period, their price trends moved in sync. However, the correlation weakened over time, and since then, ETH’s price trend has diverged from that of the S&P 500 and other stocks.</p>
     <p>As of the current writing, the S&P 500 and other stocks have experienced notable increases, rising by over 10%. In contrast, the price of ETH declined, though it has been relatively small, remaining below 1%.</p>
     <h3>ETH on a daily timeframe</h3>
     <p>As of this writing, Ethereum was experiencing a loss on a daily timeframe, being valued at approximately $1,830, representing a 2% decline from previous levels. The Moving Average Convergence Divergence (MACD) indicator indicated a bearish sentiment as of this writing.</p>
     <p>Additionally, the Relative Strength Index (RSI) line was nearing 40, which suggested the continuation of a significant bearish trend for Ethereum.</p>
    </div>
  },
  {
    id: 632,
    title: 'wBTC transaction volume spikes. This is what it means for BTC…',
    category: 'Crypto',
    subCategory: ['wBTC', 'BTC', 'Transaction'],
    description: <div className="blog-desc">
      <p>Whales make waves as wBTC surges, stirring BTC trend questions. Positive exchange flow meets bearish resistance.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 3, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691074168/ncx-blog-1003_kflgds.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Whales make waves as wBTC surges, stirring BTC trend questions. Positive exchange flow meets bearish resistance.</p>
       <ul>
       <li>wBTC saw a couple of 2,000 transfers for the first time in over a month.</li>
       <li>Inflow has dominated Bitcoin flow as the price remained bearish.</li>
      </ul>
	    <p>The wrapped version of Bitcoin [wBTC] recently experienced a significant surge. This move raised questions about whether holders were redeeming their BTCs and if it impacted the BTC trend.</p>	
      <h3>wBTC transaction volume spikes</h3>
      <p>On 1 July, the transaction volume of wrapped wBTC surged to over 7,000, marking the second occurrence of such a spike in over two months. However, the most notable activity on that day was related to top token transactions per Santiment.</p>
      <p>There were two significant movements of 2,000 wBTC each, which decreased the supply of wBTC on exchanges. Notably, this whale transaction was the first in over a month.</p>
      <p>As of this writing, the transaction volume exceeded 3,000, and the supply of wBTC on exchanges was approximately 3.4%. It’s important to understand that wrapped Bitcoin (wBTC) is a tokenized version of Bitcoin [BTC] that operates on a different blockchain network, commonly on the Ethereum blockchain. Its purpose is to provide the liquidity and functionality of BTC to decentralized applications (dApps) and smart contracts on the Ethereum platform.</p>	
      <p>Wrapping Bitcoin involves depositing a specific amount with a trusted custodian or third party. Once the BTC is deposited, an equivalent amount of wBTC is minted and issued to the user. Each wBTC token is designed to maintain a 1:1 peg with the underlying BTC, ensuring that it is backed by an equal amount of BTC held in reserve. This mechanism allows wBTC to represent and track the value of Bitcoin while being usable in the Ethereum ecosystem.</p>
      <h3>Tracking the trend of Bitcoin’s flow</h3>
      <p>As per CryptoQuant’s data, while not highly significant, BTC did experience a positive flow on exchanges in the past few days. As of this writing, the exchange flow recorded more inflow, with over 1,200 BTC entering the exchanges.</p>
      <p>This observation indicated that more BTC was being deposited into exchanges rather than withdrawn from them during this period.</p>
      <h3>BTC stays trapped in the bear trend</h3>
	    <p>Following a modest 1% price increase in the previous trading session on the daily timeframe, Bitcoin, once again, resumed its decline. At the time of writing, the price of BTC was approximately $29,500, reflecting a loss of nearly 1%.</p>
      <p>Additionally, the Relative Strength Index (RSI) suggests that Bitcoin was still within a bearish trend, as it has been unable to break free from this downward trajectory.</p>
    </div>
  },
  {
    id: 631,
    title: 'ADA stuck at key support, can bulls break through?',
    category: 'Crypto',
    subCategory: ['ADA', 'Stuck', 'bulls'],
    description: <div className="blog-desc">
      <p>ADA remained stuck at a critical price level, with Bollinger Bands suggesting a breakout move was imminent.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 3, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691073703/ncx-blog-1002_d3hkqu.png',
    content: <div className="blog-content">
      <h3>In this article:</h3>
      <p>Disclaimer: The information presented does not constitute financial, investment, trading, or other types of advice and is solely the writer’s opinion.</p>
	    <p>ADA remained stuck at a critical price level, with Bollinger Bands suggesting a breakout move was imminent.</p>
      <ul>
        <li>Buyers have propped up prices at a key support level.</li>
        <li>Funding rates remained positive.</li>
      </ul>
	    <p>A recent price report on Cardano [ADA] highlighted the feasibility of the $0.3 support being a good buy level. However, bulls haven’t seized the opportunity, as prices remain stuck at key support levels.</p>	
    	<p>While bulls and bears tussled for dominance at the critical price level, Bitcoin [BTC] rallied above $29k. Further gains for the king coin could provide the needed impulse for ADA bulls.</p>
      <h3>A price breakout is looming, but which way will it go?</h3>
      <p>ADA was on a gentle uptrend before the price rejection at the $0.376 price level. This led to a sharp reversal, which took ADA to the critical $0.3 support level. Despite the pullback, bulls have been able to hold on to the support level for over a week.</p>
      <p>A look at the ADA’s price action on the 12-hour timeframe showed a tightening of the Bollinger Bands. This suggested that a price breakout was imminent. With prices lying at a critical level, the breakout could go either way.</p>
	    <p>A bullish breakout will see ADA head toward the $0.35-$0.37 price level, with the $0.376 resistance a key hurdle to cross for more gains. On the flip side, a drop below the $0.3 support will see bears target the $0.25-$0.26 price region.</p>
      <p>Meanwhile, the Relative Strength Index (RSI) hovered around the neutral 50, while the Chaikin Money Flow (CMF) also lingered around the zero mark. Both indicators revealed the neutrality of buyers and sellers while waiting for a clear trend to emerge from the press time price level.</p>
      <h3>Market speculators can’t pick a side</h3>
      <p>Speculators in the futures market adopted a cautious approach, due to the possibility of a price breakout. The Open Interest data on the four-hour timeframe fluctuated between $110 million and $120 million, per Coinalyze.</p>
      <p>Another jump in BTC’s price could serve as the catalyst for the bullish breakout. Traders should however remain cautious and closely track Bitcoin’s price action.</p>
    </div>
  },
  {
    id: 630,
    title: 'Can Ethereum NFTs return from the brink of a downfall',
    category: 'Crypto',
    subCategory: ['Ethereum', 'NFT', 'downfall'],
    description: <div className="blog-desc">
      <p>While traders may have hoped for respite, the NFT market keeps hinting at new lows.</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'August 2, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1691073344/ncx-blog-1001_gz345p.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>While traders may have hoped for respite, the NFT market keeps hinting at new lows.</p>
      <ul>
        <li>The NFT volume on the blockchain reached its lowest since November 2022.</li>
        <li>Blur dominated OpenSea but trading activity on both marketplaces decreased.</li>
      </ul>
    	<p>Once recognized as a thriving landscape of digital art and collectibles, the Ethereum [ETH] NFT market was now experiencing a period of turbulence as activity and volume took a notable downturn. According to Dune Analytics, the transaction volume across all Ethereum marketplaces plunged to the lowest since November 2022.</p>	
    	<p>Based on the data from the blockchain analytics platform, Blur stamped its dominance over OpenSea, as it recorded a $407.67 million volume all through the month. OpenSea and OpenSea Pro combined, on the other hand, fell short with a volume of $120.52 million.</p>
      <h3>Addresses fall out of engagement</h3>
      <p>However, the fall of NFTs in the Ethereum ecosystem has been impeding for a while. Before July, the market was in an ebb-and-flow state, with a decline commanding the flow for most of the year.</p>
      <p>Besides the fall in volume, trading activity on these marketplaces has also decreased. According to Dune’s dashboard, the percentage of unique users on each on OpenSea, Blur, and the rest of the marketplace fell compared to previous months.</p>
      <p>As a result of this decline, the number of independent addresses involved in Ethereum NFT transactions reached a two-year low.</p>	
    	<p>A look at DappRadar’s data showed that almost all marketplaces in the Ethereum ecosystem registered a decline in traders in the last 30 days. At press time, traders on Blur had decreased by 33.25%</p>
      <p>Furthermore, traders on the OpenSea marketplace fell by 28.24%. However, other marketplaces including Rarible and the OKX Marketplace registered upticks in sales and number of traders respectively.</p>
      <h3>Finding respite in the “Otherside”</h3>
      <p>Despite the drop in volume and activity, Bored Ape Yacht Club [BAYC] maintained the top position in sales. According to CryptoSlam, the Yuga Labs-backed collection recorded $25.71 million in sales.</p>
      <p>Also, Yuga Labs had recently shown that it was not giving up on the NFT space in spite of the hawkish conditions.</p>
      <p>On 31 July, the creator of BAYC and MAYC revealed that it was expanding its mission for the OthersideMeta collection. In doing this Yuga Labs noted that it was acquiring Roar Studios. It said,</p>
      <p><strong>“We are proud to announce Yuga Labs is acquiring Roar Studios, a music and metaverse startup led by long-time entertainment Executive, Eric Reid, to execute our expansive vision for OthersideMeta.”</strong></p>
      <p>Meanwhile, Ethereum NFT wash trading also fell. And according to crypto slam, the 30-day sales in this aspect were $279.10 million— a 61.79% decrease within the period.</p>
    </div>
  },
  {
    id: 629,
    title: 'BNB network remains resilient despite market uncertainty',
    category: 'Crypto',
    subCategory: ['BNB', 'Market', 'Network'],
    description: <div className="blog-desc">
      <p>Activity on the BNBchain remains consistent. However, risky dApps and bearish sentiment may act as hurdles to BNB’s growth.</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 31, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1690797639/ncx-blog-970_v2goh7.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Activity on the BNBchain remains consistent. However, risky dApps and bearish sentiment may act as hurdles to BNB’s growth.</p>
      <ul>
        <li>Active addresses on the BNBchain remain high despite market volatility.</li>
        <li>The price of BNB recovered as volume increased.</li>
      </ul>
    	<p>Binance’s [BNB] native BNB token was among the many that was impacted severely by the SEC lawsuit against the Binance exchange. However, despite all the volatility and uncertainty, the activity on the BNB chain remained high.</p>	
    	<h3>BNB remains strong</h3>
      <p>According to BNB’s recent data, the number of active users on the network was 4.02 million. These active users made transactions adding up to 3.67 million over the last week. One of the reasons how BNB was able to sustain users on its protocol was through its low gas fees of 0.00037 BNB.</p>
      <p>However, despite the positive momentum on the BNBchain network, the progress of the protocol could be hindered by risky dApps.</p>	
      <h3>Some suspicious activity</h3>
    	<p>This week, DappBay’s Red Alarm flagged over 35 risky dApps for BNB. Unsafe dApps like -Staker Cafe, Okxo, Pepe Girl, Shiba Reward, and Maker Lending were among those identified, posing potential risks for users.</p>
      <p>The identification of over 35 risky dApps on the BNB network by DappBay’s Red Alarm has the potential to impact BNB in several ways. Firstly, users’ trust and confidence in the BNB ecosystem may be undermined due to the presence of unsafe dApps. This could result in users being more cautious when using dApps, affecting overall adoption rates.</p>
      <p>Moreover, the negative publicity surrounding risky dApps might influence investor sentiment, leading to fluctuations in BNB’s token value. Additionally, increased attention to these dApps could attract regulatory scrutiny, potentially impacting BNB’s standing in the market.</p>
      <p>Despite the increase in risky dApps on the BNB chain, the protocol witnessed some positives last quarter. BNB’s data revealed a significant rise in market share, climbing from 38% at the start of Q2 to 45% by quarter-end. This performance emphasized the dominance of BNB Chain and the strong trust developers and users placed in its infrastructure.</p>
      <h3>How is BNB doing?</h3>
      <p>Along with the protocol, the price of the BNB token also witnessed some recovery. The volume of BNB grew in tandem with its price as well. At press time, the token was trading at $242.33.</p>
    </div>
  },
  {
    id: 628,
    title: 'Optimism: How Worldcoin, Coinbase helped to lure in users to the protocol',
    category: 'Crypto',
    subCategory: ['Worldcoin', 'Coinbase', 'Crypto'],
    description: <div className="blog-desc">
      <p>Interest in Optimism soared as TVL neared $1 billion. However, DEX volumes declined, signaling room for more growth in the DeFi sector.</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 31, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1690793490/ncx-blog-969_plqpzt.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Interest in Optimism soared as TVL neared $1 billion. However, DEX volumes declined, signaling room for more growth in the DeFi sector.</p>
      <ul>
        <li>Optimism’s TVL surged and was approaching the $1 billion mark.</li>
        <li>DEX volumes on Optimism declined, indicating the need for improvement.</li>
      </ul>
    	<p>Optimism[OP] has been slowly gaining momentum as activity on its protocol spiked. One reason for this could be the interest in Worldcoin, which led many investors and users to flock to the network.</p>	
    	<h3>Adding the BASE</h3>
      <p>However, it wasn’t just Worldcoin that was bringing activity to Optimism. Coinbase also played a great role. Earlier this week, Coinbase’s newly developed chain called BASE, which is built on top of the OP Stack, experienced a significant surge in deployed contracts as their development network (devnet) went live.</p>
      <p>During the week of its launch, there was a spike in the number of newly deployed contracts. Interestingly, this figure increased by an impressive 400% in a week. This surge in deployed contracts showcased the growing activity and adoption of Coinbase’s BASE chain following its recent launch on the devnet.</p>
      <p>Due to the high activity on Optimims, other dApps on the protocol also witnessed significant growth.</p>
      <p>The renewed interest in Optimims over the last month has directly benefited Velodrome Finance, a central trading and liquidity marketplace on Optimism. During this period, Velodrome Finance experienced a remarkable increase in market capitalization, rising by 63.8%. Additionally, the fees generated on the platform surged impressively by 364.7%.</p>
      <p>Despite these significant gains, VelodromeFi was still approximately $100 million away from reaching its all-time high (ATH). Remarkably, the platform was currently generating even higher fees, showcasing its potential for further growth and profitability in this market environment.</p>
      <h3>Progress made on the protocol</h3>	
      <p>As a result of these factors, Optimism’s Total Value Locked (TVL) experienced significant growth. Over the past few weeks, Optimism’s TVL surpassed the $900 million milestone and was gradually approaching the $1 billion mark. However, despite the upward trend in TVL, the volumes of decentralized exchange (DEX) on Optimism have been declining.</p>
      <p>This decline in DEX volumes suggests that the protocol still has room for improvement in the DeFi sector. While Optimism’s TVL has been on the rise, addressing the challenges faced by DEX volumes will be crucial to further enhancing its position in the decentralized finance space.</p>
    	<p>Coming to the OP token, it was trading at $1.58 and had appreciated by 5.83% in the last 24 hours at press time.</p>
    </div>
  },
  {
    id: 627,
    title: 'Uniswap: How high can the bullish streak go?',
    category: 'Crypto',
    subCategory: ['Uniswap', 'bullish', 'Crypto'],
    description: <div className="blog-desc">
      <p>Uniswap’s recovery hit Q2 price ceiling but overall price action was bullish – Which way will price sway?</p>
    </div>,
    authorName: 'Benjamin Njiri',
    authorurl: 'https://ambcrypto.com/author/benjamin-njiri/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 30, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1690792884/ncx-blog-968_z56hvu.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Uniswap’s recovery hit Q2 price ceiling but overall price action was bullish – Which way will price sway?</p>
      <ul>
        <li>The trendline support was respected and validated.</li>
        <li>Open Interest rates surged; short positions discouraged.</li>
      </ul>
    	<p>Uniswap [UNI] has maintained its recovery despite the recent muted Bitcoin [BTC] price performance. The native DEX altcoin has rallied over 60% from a mid-June low of $3.8 to over $6.0 at the time of writing. But the price action inched closer to its Q2 2023 price ceiling of $6.5. </p>	
    	<p>The DEX platform recently received financial backing to improve its v4 protocol to remain dominant.</p>	
      <h3>Will the trendline support hold?</h3>
    	<p>So far, the trendline support has been respected and validated. It has acted as a crucial support since mid-June. The retest of the confluence of the trendline support and the bullish order block of $5.68 – $5.98 (cyan) offered buying opportunities, with a target of $6.45.</p>
      <p>Although a successful retest of $6.5 could offer over 10% gains, the target ($6.5) is a crucial resistance level and Q2 price ceiling.</p>
      <p>Given the weak BTC, 29.5k, a price rejection at the price ceiling could set UNI to head to the trendline support again. The trendline support could be a bid level for late bulls again. Above the Q2 price ceiling, the next resistance level is $6.9.</p>
      <p>Conversely, an extended pullback below the trendline support and the bullish OB will flip the bias to bearish.</p>
      <p>The RSI and CMF were bullish, reinforcing strong buying pressure and capital inflows to the UNI market.</p>
      <h3>Open Interest rates surged; shorts discouraged</h3>
      <p>According to Coinglass, there was a surge in Open Interest (OI) rates from $50 million to $60 million as UNI bounced from the trendline support. The metric confirms the bullish future market bias over the same period.</p>
      <p>In addition, more short positions suffered liquidations, as illustrated by the long red bar graphs on UNI Total Liquidation Chart. It further confirms the bullish bias in the futures market.</p>
    </div>
  },
  {
    id: 626,
    title: 'Will DOGE outplay SHIB, PEPE, and FLOKI on this front?',
    category: 'Crypto',
    subCategory: ['Doge', 'SHIB', 'PEPE'],
    description: <div className="blog-desc">
      <p>Dogecoin observes a rally as hype around X and Twitter begins to rise. Other memecoins such as SHIBA, FLOKI and PEPE fail to see the same growth.</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 27, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1690473227/ncx-blog-957_xlctxu.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Dogecoin observes a rally as hype around X and Twitter begins to rise. Other memecoins such as SHIBA, FLOKI and PEPE fail to see the same growth.</p>
    	<ul>
        <li>Dogecoin’s price rallies materially. Elon Musk plays a role.</li>
        <li>Other memecoins fail to see similar growth, traders turn pessimistic.</li>
      </ul>
      <p>Despite the market’s instability and regulatory scrutiny faced by altcoins, Dogecoin [DOGE] was able to maintain a rally for the past few weeks. Traders and investors of this meme-based cryptocurrency have persisted in accumulating significant amounts of DOGE despite the unstable market conditions.</p>	
    	<h3>Who let the DOGE out?</h3>
      <p>According to Santiment’s data, DOGE’s price appreciated by 25% in the past two weeks. There was a surge in the number of traders showing interest in DOGE, which resulted in this spike in price.</p>	
    	<p>Due to this recent rally, several DOGE holders have started to get profitable as showcased by the increasing Market Value to Realized Value (MVRV) ratio.</p>
      <h3>He Musk be stopped</h3>
      <p>One of the reasons for the surge in interest in DOGE could be attributed to Elon Musk’s recent behavior with regard to X.com, formerly known as Twitter. The rebranding and renaming of the website led many speculators to believe that DOGE may serve a larger purpose for the website in the future.</p>
      <p>Elon Musk did little to curb the speculation around DOGE and X. Instead, he further implied that the memecoin will have a pivotal role in the functioning of the website.</p>
      <p>This was showcased by Musk’s recent location on his profile, which features both the letters, “X” and “D”. The latter could be presumed to be stand symbolizing DOGE. Furthermore, Elon’s behavior can lead to more market speculation and may impact DOGE’s price in the future.</p>
      <h3>What’s cooking on the social front?</h3>
      <p>Due to the aforementioned reasons, activity on DOGE’s social front shot up significantly. According to LunarCrush’s data, the number of social mentions for DOGE grew by 810% over the last week. Similarly, the number of social engagements for DOGE surged by 819% during the same period.</p>
      <p>Moreover, the weighted sentiment around DOGE also started to move up. This indicated that the number of negative comments made against DOGE on social networks had started to decline.</p>
      <p>At press time, Dogecoin was trading at $0.07856. After testing the $0.063 support level on 13 July, DOGE’s price showcased multiple high highs and high lows, indicating a bullish trend. However, the price observed a correction after testing the $0.083 resistance levels. This decline in price, however, wasn’t significant enough to establish a trend.</p>
      <p>Additionally, the Relative Strength Index (RSI) also suggested a positive outlook for DOGE. At press time, it was at 58.65, implying that the momentum for DOGE was with the buyers. The Chaikin Money Flow (CMF) was also relatively high, suggesting that money flow favored the bulls at the time of writing.</p>
      <h3>Memecoin see, memecoin do?</h3>
      <p>In the past, DOGE’s rally has preceded the surge in the price of other memecoins, such as SHIBA and FLOKI. Due to the recent spike in DOGE prices, many have started to speculate the possibility of an uptick in interest in the memecoin market.</p>
      <p>Over the last few weeks, the price of SHIB has soared. The price of SHIB went from $0.000007 to $0.000008. However, the same couldn’t be said about the other memecoins, especially, FLOKI and PEPE. Both of the tokens witnesses a significant depreciation in price during this period.</p>
      <p>The dominance of Shiba Inu in this sector could be attributed to its growing community and its emerging metaverse. Shiba Inu’s constant focus on developing its ecosystem in both the DeFi and NFT sector can play an important role in providing the meme coin an edge against its competitors.</p>
      <h3>Low cloud, High doubt</h3>
      <p>However, on a macro level, the price and activity around the memecoins depend largely on each memecoin’s performance on the social front and the hype surrounding each token.</p>
      <p>In this sector as well, SHIBA took the lead and outperformed both FLOKI and PEPE by a huge margin. According to Santiment’s data, FLOKI and PEPE were not able to have the same presence on the social front. Additionally, these coins exhibited lesser social dominance than SHIBA.</p>
      <p>Due to this disparity, there was a massive difference between the market caps of each of the memecoins. DOGE topped the list, boasting a market cap of $11.05 billion.</p>
      <p>Followed by SHIBA which had a market cap of $4.61 billion. PEPE and FLOKI failed to cross the billion-dollar market cap mark and currently had a market cap of $488 million and $207 million respectively.</p>
      <h3>Traders stay bearish</h3>
      <p>Despite the recent interest showcased by traders in DOGE and other memecoins, traders were extremely skeptical about the current surge in the price of memecoins.</p>
      <p>Coinglass’ data indicated that the majority of the trades that were being made against DOGE, SHIB, FLOKI, and PEPE were shorts.</p>
    </div>
  },
  {
    id: 625,
    title: 'MATIC falls to key 50% Fib support level – Is a recovery likely?',
    category: 'Crypto',
    subCategory: ['Matic', 'Fib', 'Crypto'],
    description: <div className="blog-desc">
      <p>Polygon [MATIC] failed in its attempt to flip its price action bullish on the higher timeframes. A 21% drop from the $0.8923 price level took MATIC to the 50% Fib level. The extension of the retracement saw MATIC erase its gains from the price pump on 13 July.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 27, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1690472647/ncx-blog-956_rlptvy.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
      <p>MATIC bulls could find buying opportunities hard to come by, except…</p>
      <ul>
        <li>Price retracement saw MATIC drop to a critical price level.</li>
        <li>Short-term gains could be in the works for bulls, due to bulls’ advantage on long/short ratio.</li>
      </ul>
    	<p>Polygon [MATIC] failed in its attempt to flip its price action bullish on the higher timeframes. A 21% drop from the $0.8923 price level took MATIC to the 50% Fib level. The extension of the retracement saw MATIC erase its gains from the price pump on 13 July.</p>
    	<p>With Bitcoin [BTC] extending its range-bound formation between $29.2k to $29.5k, MATIC bulls could find buying opportunities hard to come by, unless the current level is defended successfully.</p>	
    	<h3>Can bulls rebound from the 50% Fib level?</h3>
      <p>Exploring MATIC’s price action considering the 12-hour timeframe revealed key price levels. The retracement from the $0.8923 level formed a higher low at the 23.6% Fib level ($0.8024) leading to a further price drop to the 50% Fib level ($0.7017).</p>	
    	<p>A look southward showed that the 50% Fib level corresponded to a critical support level for bulls. Buyers rallied strongly from the level on 13 July with a 25% price pump. A retest of the level could produce similar results with the retracement easing at the 50% Fib level, as of press time.</p>
      <p>While the Relative Strength Index (RSI) remained under the neutral 50, it registered a slight uptick to signal building demand. Also, the On Balance Volume (OBV) remained on an uptrend, despite a slight dip.</p>
      <p>A strong bounce from the 50% Fib level would see bulls target take-profit levels at $0.8024 and $0.8923. However, if bulls can’t defend the level, sellers can break the higher low and target the $0.6568 (61.8% Fib) and $0.5927 (76.8% Fib) price levels.</p>
      <h3>Bulls capture a large portion of open contracts</h3>
      <p>A look at the exchange long/short data from Coinglass on the four-hour timeframe revealed a 56.58% swing in favor of buyers. Such a large share of the open contracts for longs is likely to translate into short-term gains for MATIC. Thus, buyers can ride on the bullish sentiment in the futures market to register a strong rebound from the $0.7017 price level.</p>
     </div>
  },
  {
    id: 624,
    title: 'Has USDT managed to restore investor confidence? Data suggests…',
    category: 'Crypto',
    subCategory: ['USDT', 'Investor', 'Crypto'],
    description: <div className="blog-desc">
      <p>USDT extended its dominance in the stablecoin market. But as Binance faced BUSD’s extinction, it agreed to offer another option.</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 27, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1690472079/ncx-blog-955_qqz63g.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>USDT extended its dominance in the stablecoin market. But as Binance faced BUSD’s extinction, it agreed to offer another option.</p>
    	<ul>
        <li>USDT’s market cap reached a new ATH, leaving TUSD and USDC behind.</li>
        <li>Holders confirmed preference by holding USDT, but a new stablecoin is in town.</li>
      </ul>
      <p>Amid regulatory scrutiny and uncertainty surrounding stablecoins, Tether [USDT] has recently achieved a new milestone as its market cap reached an All-Time High (ATH). According to IntoTheBlock, USDT’s market cap reached $83.76 billion.</p>	
    	<p>This development comes after the world’s largest stablecoin slightly fell from its previous high hit on 1 June.</p>
      <h3>As USDT reigns, others fall apart</h3>
      <p>The milestone signals renewed confidence in USDT over other stablecoins increased. This signals a spike in circulation and use of the coin. However, it has not been an easy ride for USDT, especially since TrueUSD [TUSD] came into the spotlight.</p>
      <p>Holders’ appeal for stablecoins bring a sense of confidence that they can redeem their assets reliably. Furthermore, this gives them a lower chance of losing their value compared to other cryptocurrencies.</p>
      <p>Between January and the time of writing, TUSD’s market cap went from $943 million to $2.8 billion. And this soaring market cap could be linked to lingering regulatory concerns market participants have around Circle [USDC]. It also links to the increasing phasing-out stage of Binance USD [BUSD].</p>
      <p>While TUSD continues to make its mark known, USDC has lost hold of its market cap position. One reason for this is the way Ripple [XRP] surpassed it since the project partially won its SEC case.</p>
      <p>In March, USDC recorded as much as $10 billion in outflows after Circle owned up to being affected by the chaos that hit the traditional finance industry.</p>
      <h3>HODLing season as a new candidate appears</h3>	
    	<p>With reference to its volume, on-chain data showed that USDT pumped to $25 billion on 24 July. However, the volume had decreased to $18.3 billion at press time.</p>
      <p>As a yardstick used to measure an asset’s health and liquidity, a stablecoin’s volume shows the rate of market strength. Therefore, USDT’s volume reflects a relative improvement in liquidity gained.</p>
      <p>As per the 7-day Market Value to Realized Value (MVRV) ratio, Santiment showed that USDT had fallen into the negative ratio. The MVRV ratio divides the market capitalization by the realized cap.</p>
      <p>The more the ratio increases, the more participants are willing to sell for potential profits. Thus, at -4.338%, the USDT’s MVRV ratio means that the accumulation of the coin has resulted in HODLing rather than exchanging for other assets.</p>
      <p>Meanwhile, Binance, who was partially in charge of BUSD has revealed that it would list a First Digital [FDUSD] stablecoin.</p>
      <p>Developed by Hong Kong-based technology-driven firm, Binance noted that the First Digital stablecoin would operate like others with a 1:1 USD peg.</p>
    </div>
  },
  {
    id: 623,
    title: 'Worldcoin: One day after launch, WLD FUD thrives',
    category: 'Crypto',
    subCategory: ['Worldcoin', 'Launch', 'FUD'],
    description: <div className="blog-desc">
      <p>The excitement around the project has been toned down. And while some issues were raised, there could still be potential for…</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 26, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1690471689/ncx-blog-954_wqkgop.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The excitement around the project has been toned down. And while some issues were raised, there could still be potential for…</p>
    	<ul>
        <li>After a brief decrease, WLD gained another 22.35% in the last 24 hours.</li>
        <li>Ethereum’s co-founder discussed some pros and cons as positive sentiment fell.</li>
      </ul>
      <p>The much-anticipated launch of Worldcoin [WLD] was met with great fanfare. However, its initial hours of entering the market were not without controversy. As a large part of the market expected, the WLD token, backed by a number of Venture Capitalists (VCs), increased as high as 85% within hours of the introduction.</p>	
    	<p>However, the same price fell by 22.28% momentarily before its latest race back to $2.48 at press time. But that’s not all that has happened with the project.</p>
      <h3>FUD in the building</h3>	
    	<p>Just one day after its launch, FUD (Fear, Uncertainty, and Doubt) seemed to surround the project. This was because potential investors raised questions and some on-chain analysts dived deep into possible cons.</p>
      <p>As a result of the spreading discussions and comments about WLD, Santiment tweeted that WLD was one of the top subjects being analyzed on several social platforms. However, the on-chain analytic platform also noted that most of the conversions tilted towards negativity.</p>
      <p>Previously, Ethereum’s [ETH] co-founder Vitalik Buterin had made his opinion about the project known to the public on 24 July. Dissecting Worldcoin’s Proof of Humanity idea, Buterin noted that the privacy concerns and ethical issues raised in some corners could be valid.</p>
      <p>At the same time, he opined that the proof of personhood developed by the project was important. He gave reasons for this opinion explaining that,</p>
      <p><strong>Proof of personhood is valuable because it solves a lot of anti-spam and anti-concentration-of-power problems that many people have, in a way that avoids dependence on centralized authorities and reveals the minimal information possible</strong></p>
      <p>On another end, pseudonymous on-chain sleuth ZachXBT raised some issues about the Worldcoin project. According to him, the project’s verification method could lead to the emergence of black-market accounts.</p>
      <h3>A quick change in the narrative</h3>
      <p>ZachXBT also accused Wolrdcoin of exploiting people from developing countries. But he also mentioned that the project’s future depends on the general narrative it would get around the crypto community.</p>
      <p>Meanwhile, the weighted sentiment around WLD has been declining. At press time, the metric was down to 4.588. Intuitively, this metric is a social-volume weighted balance of the perception of an asset.</p>
      <p>When it spikes, it implies that a vast majority of messages around an asset are positive. But when it falls, it means the average comment about an asset is negative, like in WLD’s case. This reinforced Santiment’s aforementioned opinion that FUD existed.</p>
      <p>On the social volume front, the on-chain analytic platform showed that it had decreased. This means that the search for the WLD token has reduced compared to the period of launch.</p>
    </div>
  },
  {
    id: 622,
    title: 'Bitcoin whales in a predicament as prices hover near $30k: To buy or to sell?',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Prices', 'Whales'],
    description: <div className="blog-desc">
      <p>As BTC struggles to cross over the $30,000 price mark, some whales have intensified accumulation, while others have reduced their risk exposure.</p>
    </div>,
    authorName: 'Abiodun Oladokun',
    authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 26, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1690470911/ncx-blog-953_xoc8ix.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
	    <p>As BTC struggles to cross over the $30,000 price mark, some whales have intensified accumulation, while others have reduced their risk exposure.</p>
      <ul>
        <li>BTC whales have traded divergently as price oscillates within $28,000 to $32,000 ranges.</li>
        <li>With a surge in BTC profits in the past few months, whales have increasingly sent their holdings to exchanges.</li>
      </ul>
      <p>For the past four months, Bitcoin [BTC] has been consistently trading within the range of $28,000 to $32,000. As the king coin lingers within a narrow price range, various cohorts of BTC whales have been making distinct trading moves, Glassnode found in its new report.</p>	
	    <h3>Some sowed while others slept</h3>
      <p>Glassnode found that in the last month, the different sub-groups of BTC whales exhibited varied behaviors as the coin’s price faced resistance at the $30,000 psychological price mark.</p>
      <p>Whales that held between 1,000 to 10,000 BTC adopted a bullish approach as they increased their balance by about 33,800 BTC. Likewise, whales with over 100,000 BTC increased their holdings by 6,600 BTC.</p>
      <p>However, holders of 10,000 to 100,000 BTC adopted a bearish approach, reducing their balance by 49,000 BTC. Due to this, the period under review was marked by a “net reduction of just -8.7k BTC,” Glassnode found.</p>
      <p>However, while aggregate balance change remained relatively flat, the report noted that whale entities might be moving funds amongst themselves on crypto exchanges as “there are significant changes taking place both internally and via exchange flows.”</p>
      <p>To test its theory, Glassnode considered BTC’s Whale Reshuffling metric on a 30-day moving average for two whale subdivisions: those with over 10,000 BTC and those with 1,000 to 10,000 BTC.</p>
      <p>It found periods of strong inverse correlations of -0.55 or less, indicating instances of balance shifts between the two groups. These periods coincided with the times when BTC’s price approached the $30,000 range.</p>
      <p>This led Glassnode to conclude:</p>	
      <p><strong>“This suggests that whales have indeed exhibited a relatively neutral balance change of late, with much of their recent activity being reshuffling via exchanges.”</strong></p>
      <p>With BTC’s MVRV ratio still in the profitable region, all cohorts of BTC whales have sent varying amounts of their holdings to crypto exchanges for onward sales. Glassnode found that the coin’s recent rally caused whale inflow volumes to surge “quite significantly” to over 16,300 BTC daily.</p>
      <p><strong>“This is a whale dominance of 41% of all exchange inflows, which is comparable to both the LUNA crash (39%) and the failure of FTX (33%).”</strong></p>
	    <p>Over the past five years, whale-to-exchange net flows have generally remained around ±5,000 BTC daily. However, in June and July of this year, there has been a sustained increase in whale inflows to exchanges, with a bias towards inflows ranging from 4,000 to 6,500 BTC per day.</p>
    </div>
  },
  {
    id: 621,
    title: 'Bitcoin reclaims US$30,000, Ether gains, Cardano leads winners as top 10 crypto rise',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Ether', 'rise'],
    description: <div className="blog-desc">
      <p>Bitcoin rose in early Monday morning trading in Asia to reclaim the US$30,000 support level, but later retreated. Ether moved higher to near US$1,900, while most other top 10 non-stablecoin cryptocurrencies logged gains.</p>
    </div>,
    authorName: 'Tom Zuo',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 24, 2023 - 4:58 AM', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1690202508/ncx-blog-936_a8b3wu.webp',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin rose in early Monday morning trading in Asia to reclaim the US$30,000 support level, but later retreated. Ether moved higher to near US$1,900, while most other top 10 non-stablecoin cryptocurrencies logged gains.</p>
    	<p>Cardano’s ADA token led the winners. In other markets, NFTs traded flat, while U.S. equity futures did the same. The week ahead brings lots more earnings in the U.S. for investors to get a picture of the state of the economy, while the Federal Reserve has a two-day meeting where it’s expected to raise interest rates.</p>	
    	<h>Crypto</h>
      <p>Bitcoin rose 1.33% over the 24 hours to US$30,059 as of 07:30 a.m. in Hong Kong, according to data from CoinMarketCap. Ether gained 1.73% to US$1,887.</p>
      <p>However, both tokens have yet to make up losses for the past seven days, with Bitcoin off 0.37% for the week, while Ether is sitting on a weekly loss of 1.73%. Bitcoin fell back later in the morning.</p>
      <p>Trading in Bitcoin and Ether will remain rangebound until the Fed decision on rates on July 27, Mark Wong, senior trader at Hong Kong-based digital asset platform Hashkey Group, said in a Friday newsletter. “A rate hike is all but priced in with an implied probability of 99.8% from the futures market,” Wong said.</p>
      <p>Most other top 10 non-stablecoin cryptocurrencies also traded higher Monday, with Cardano’s ADA token leading the winners with a gain of 3.01% to US$0.3169 and up 0.59% for the week.</p>
      <p>Input Output Global, one of the developers behind the Cardano blockchain, last week said Mithril – a Cardano stake-based signature scheme – was nearing its mainnet launch to improve the node syncing and security of the Cardano network.</p>
      <p>On the regulatory front, a portion of the court’s recent verdict in the Ripple case was “wrongly decided,” said the U.S. Securities and Exchange Commission (SEC) on Friday in documents filed in its on-going lawsuit against Singapore-based Terraform Labs, hinting that the SEC would challenge the Ripple ruling.</p>
      <p>The SEC in February charged Singapore-based Terraform Labs Pte and its co-founder Do Hyeong Kwon with orchestrating a multi-billion dollar crypto asset securities fraud involving an algorithmic stablecoin and other crypto asset securities.</p>
      <p>The total crypto market capitalization rose 1.14% in the past 24 hours to US$1.20 trillion, while trading volume rose 13.56% to US$24.5 billion.</p>
      <h3>Forkast 500 flat, OpenSea starts NFT swapping</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>The main Forkast 500 NFT index edged up 0.08% in the past 24 hours to 2,674.69 as of 10:10 a.m. in Hong Kong. Forkast’s Ethereum, Solana and Polygon NFT market indices moved lower, while the Cardano index logged gains.</p>
      <p>Total NFT sales volume rose 1.30% in the past 24 hours to US$17.24 million, according to data from CryptoSlam, with the volume on Ethereum totaling US$12.02 million or more than two thirds of the total.</p>
      <p>Among all NFT collections, Ethereum-based Bored Ape Yacht Club topped the 24-hour sales volume at US$1.60 million, edging up 1.19%. Volumes traded mixed in the top five NFT collections.</p>
      <p>OpenSea, the world’s second-largest NFT marketplace by trading volume, launched its “Deals” function on Friday, which allows its NFT holders to directly swap their collections with each other and include wrapped Ether (WETH) in their offers to sweeten up the transactions.</p>
      <p>Elsewhere, the slump in value of Twitter Co-Founder Jack Dorsey’s first NFT caught the attention of mainstream media including CNBC and the Economic Times on Sunday. The NFT, based on Dorsey’s first Twitter post, sold at over US$2.1 million in 2021, but the highest offer for the NFT is now 1 ETH (US$1,875), according to OpenSea.</p>
      <p>“The NFT market is looking rather flat in the Forkast 500 index, (which) reflects that continued downtrend we’ve been watching most of the year,” Yehudah Petscher, NFT Strategist at Forkast Labs, said on Friday. The Forkast 500 NFT index has dropped over 35% since the start of the year.</p>
      <p>“With the exception of January, the market almost completely slants right,” said Petscher.</p>
      <h3>US equities await Fed, earnings</h3>
      <p>U.S. stock futures traded mixed as of 12:30 p.m. in Hong Kong, with Dow Jones Industrial Average and S&P 500 futures dipping and Nasdaq futures edging higher. The three major U.S. indexes closed mixed in regular trading on Friday.</p>
      <p>Asia’s main stock indexes also traded mixed on Monday morning. China’s Shanghai Composite, Japan’s Nikkei and South Korea’s Kospi all gained, while Hong Kong’s Hang Seng fell.</p>
      <p>The U.S. Federal Reserve meets Wednesday and Thursday this week on interest rates and analysts overwhelmingly expect another 25-basis-point rate hike. The European Central Bank (ECB) is also expected to raise its rates later this week.</p>
      <p>The CME FedWatch Tool predicts a 99.8% chance the Fed will raise rates to the range of 5.25% to 5.5% on July 26, the highest since January 2001.</p>
      <p>With a rate hike priced into many markets, the focus on the Fed meeting will be forward guidance on monetary policies. Both Federal Reserve Chair Jerome Powell and ECB President Christine Lagarde have flagged stubborn inflation as a concern, according to Bloomberg on Saturday.</p>
      <p>Along with the Fed and ECB, Japan’s central bank will also meet this week, but there is little likelihood of any change to its ultra-loose monetary policy.</p>
      <p>In the U.S. this week, more than 150 U.S. corporations, including Microsoft, Alphabet and Meta Platforms, will release second-quarter results.</p>	
    </div>
  },
  {
    id: 620,
    title: 'A bitcoin positive-feedback loop could more than quadruple the price beyond $120,000, analyst says',
    category: 'Crypto',
    subCategory: ['bitcoin', 'crypto', 'analyst'],
    description: <div className="blog-desc">
      <p>Bitcoin miners are creating a virtuous circle that could propel the token's price beyond already-bullish forecasts, Standard Chartered analyst Geoff Kendrick told Insider.</p>
    </div>,
    authorName: 'Filip De Mott',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 23, 2023 - 4:30 PM', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1690201694/ncx-blog-935_kx7ks3.webp',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
      <ul>
        <li>Bitcoin could soar above $120,000 if miners keep holding onto tokens, Standard Chartered analyst Geoff Kendrick said.</li>
        <li>In an interview with Insider, he acknowledged bitcoin's price gains help fuel a positive-feedback loop.</li>
        <li>"And so your point about this becoming self-fulfilling, I suspect is actually a very important driver."</li>
      </ul>
    	<p>Bitcoin miners are creating a virtuous circle that could propel the token's price beyond already-bullish forecasts, Standard Chartered analyst Geoff Kendrick told Insider.</p>
    	<p>That's as miners often sell fewer tokens when the price climbs, a bullish tendency that led him say earlier this month that $120,000 is possible next year, representing 300% upside from current levels.</p>	
    	<p>In a follow-up interview, Insider asked Kendrick: if higher prices cause miners to sell fewer tokens, further boosting prices, then would even higher prices fuel a positive-feedback loop that takes bitcoin past $120,000?</p>	
    	<p>Yes.</p>
      <p>"And so your point about this becoming self-fulfilling, I suspect is actually a very important driver," he said.</p>
      <p>Miners predominantly sell bitcoin to cover costs. But as the price rises and previous debt struggles in the industry ebb away, miners are letting go of less bitcoin.</p>
      <p>Another tailwind is the coin's upcoming halving in 2024, which is when the reward given to miners is cut in half. This is to cap the supply of bitcoins, and has historically led to price increases.</p>
      <p>"Then if you add in some of the other things, like getting close to the halving cycle, et cetera, then that sort of adds," Kendrick said. "And then yeah, the cycle in theory just keeps going."</p>
      <p>Miners also have little reason outside of bitcoin's price moves to sell the token, he said. Some of this is on account of the culture surrounding the industry, with many miners holding on in the anticipation that bitcoin eventually reaches stellar highs.</p>
      <p>"So they basically want to have a company that starts out when prices are cheap, get cheaper electricity and stuff, and then hold on. It's like a super-leveraged play," he said.</p>
      <p>Kendrick has been bullish on bitcoin this year, and in April he said it would hit $100,000 in 2024, citing a number of other factors beyond miner profitability. Examples included bitcoin's safe-haven reputation, regulatory adaptations, institutional interest, alternative currency fall-off, and less volatility.</p>
      <p>Now, he told Insider that a few of these factors also suggest more upside.</p>
      <p>For instance, BlackRock's interest in creating a bitcoin spot ETF signals growing demand from the investment firm's clients, while the Federal Reserve's expected end on its rate-hiking cycle would marginally help bitcoin.</p>
      <p>The one factor that Kendrick would reassess was his idea that bitcoin would gain from disinvestment in other crypto, or so-called altcoins.</p>
      <p>For this, he noted the recent courtroom victory of Ripple over the Securities and Exchange Commission, which sued the firm in 2020 over its cryptocurrency XRP.</p>
      <p>However, a judge recently ruled in favor of Ripple, saying XRP is not a security, meaning it's outside the SEC's regulatory reach. The news sparked a rally in altcoins.</p>
      <p>"So the assumption is, well, if Ripple is not [a security], then these are also not," he said. "So that's why you had big bounces in like Solana, as an example."</p>
      <p>How long this tailwind for altcoins will last is uncertain, Kendrick said, adding that any institutional success in establishing bitcoin ETFs would prompt a renewed focus on apex tokens.</p>
    </div>
  },
  {
    id: 619,
    title: 'Final countdown to Shibarium Mainnet means SHIB can expect to…',
    category: 'Crypto',
    subCategory: ['SHIB', 'Mainnet', 'Crypto'],
    description: <div className="blog-desc">
      <p>Usage of SHIB has increased since the Shibarium development team provided an update. Here’s what else you can expect now…</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 22, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1690200959/ncx-blog-934_hswx8o.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Usage of SHIB has increased since the Shibarium development team provided an update. Here’s what else you can expect now…</p>
      <ul>
        <li>Shibarium Mainnet has reached the third stage of its roadmap</li>
        <li>Shiba Inu’s active addresses, open interest, and network growth increased</li>
      </ul>
    	<p>After months of anticipation, Shibarium, the upcoming Layer Two (L2) blockchain of Shiba Inu [SHIB], might soon be launched on the Mainnet. This information was disclosed by NOWNodes, one of the development arms involved in the project.</p>
      <h3>Only moments left</h3>	
    	<p>According to NOWNodes, the Mainnet was already in the third phase of the Shibarium Roadmap. The tweet also revealed that once some of the conditions are met, it would announce the integration of the Shibarium RPC nodes.</p>
      <p>RPC nodes is an acronym for Remote Procedure Call nodes. They are used to enable users to read data on blockchains and send transactions on different networks.</p>
      <p>Following the disclosure, on-chain data showed that SHIB’s network growth spiked. At press time, the network growth had increased to 5,365. Here, network growth measures the number of new addresses interacting with a network.</p>
      <p>When the metric rises, it means that a lot of new addresses transacted on the network. But when it falls, it means new cohorts refrained from exchanging between wallets.</p>
      <p>Ergo, the hike in SHIB’s network revealed that adoption has risen. This was also reflected on the active addresses front.</p>
      <h3>Increasing traction and interest</h3>	
    	<p>Active addresses serve as a good indicator of daily users transacting on a particular blockchain. At the time of writing, SHIB’s 24-hour active addresses had increased to 10,500. This cites an increase in participation in successful transactions via the Shiba Inu network.</p>
      <p>Despite the traction SHIB had, social volume remained in an uninspired state. At press time, Santiment revealed that the social volume was down to 38.</p>
      <p>Social volume shows the total number of texts or search terms connected to an asset. When the metric is low, it means that there has been less relevant content linked to the asset.</p>
      <p>However, when the social volume is high, it means unique mentions of the token are high. SHIB’s case was the former since the metric fell.</p>
      <p>Furthermore, SHIB’s Open Interest (OI) also hiked. As an indicator used to track activities in the derivatives market, the OI is the total number of Futures contracts held by market participants.</p>
      <p>The indicator is used to measure the market sentiment and strength behind the price. A decrease in Open Interest means that liquidity coming into the market has fallen.</p>
      <p>On the contrary, when the indicator rises as SHIB did, then it suggests higher liquidity. Thus, if, the OI sustains the hike, then SHIB’s price might be positively affected.</p>
    </div>
  },
  {
    id: 618,
    title: 'Bitcoin loses grip on US$30,000 as profit-taking kicks in; XRP gains as other top 10 crypto fall',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'XRP', 'Crypto'],
    description: <div className="blog-desc">
      <p>Bitcoin slipped under US$30,000 in Wednesday morning trading in Asia on the back of profit-taking and liquidation of long positions after the recent run up on optimism spot exchange-traded funds for the token may win approval and attract institutional investment.</p>
    </div>,
    authorName: 'Danny Park',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 19, 2023 - 6:01 PM', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689848973/ncx-blog-902_yvoscf.webp',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin slipped under US$30,000 in Wednesday morning trading in Asia on the back of profit-taking and liquidation of long positions after the recent run up on optimism spot exchange-traded funds for the token may win approval and attract institutional investment.</p>
    	<p>Ether and most other top 10 non-stablecoin cryptocurrencies also declined in lower trading volume. Ripple’s XRP token was the exception, gaining almost 4% in early Asia trade as the tailwind continued from the company’s partial victory last week in the lawsuit brought by the U.S. securities regulator.</p>	
    	<h3>Bitcoin, Ether retreat</h3>
      <p>Bitcoin fell below the US$30,000 line that has offered floor support since late June and lost 2.46% for the week, according to data from CryptoMarketCap.  As of 06:40 a.m. in Hong Kong, the world’s biggest cryptocurrency traded at US$29,784.42, down 1.22% over the last 24 hours.</p>
      <p>The token continued to fluctuate around the US$30,000 line later in the Asia morning as overall trading volume in cryptocurrencies fell more than 9% on the day.</p>	
    	<p>Data from Coinglass showed large liquidations in long positions in Bitcoin, or bets that prices for the token would rise further.</p>
      <p>Ether dropped 0.76% to US$1,898.40, though it is still logging a 1.15% gain for the week.</p>
      <p>Investors seemed unimpressed by the U.S. Securities and Exchange Commission (SEC) agreeing on Monday to review the application by digital asset manager Valkyrie Investments Inc. to launch a Bitcoin Spot exchange-traded fund (ETF).</p>
      <p>The SEC agreed the same for the application by BlackRock on July 13, which along with several other Bitcoin ETF applications set off a bullish run in crypto for the past month. BlackRock is the world’s largest asset manager with US$9.4 trillion in assets. Tennessee-based Valkyrie had more than US$1 billion in assets under management in 2022.</p>
      <p>In more background noise for crypto markets later today, Brian Armstrong – the chief executive officer of Coinbase, the largest crypto exchange in the U.S. – is expected to sit down with Democratic members of Congress to discuss regulation of digital assets, according to Bloomberg.</p>
      <p>The SEC sued Coinbase on June 6, claiming that the exchange breached federal securities laws by offering unregistered financial securities to investors. Armstrong and other Coinbase executives have said they will fight the SEC allegations in court.</p>
      <p>The SEC on July 13 received a setback in another lawsuit, filed more than two years ago, against Ripple Labs, when a U.S. district court ruled that Ripple Labs’ programmatic sales of its XRP cryptocurrency did not qualify as offerings of financial securities.</p>
      <p>The Ripple ruling is a “potential watershed” for the broader crypto market, wrote Robert Quartly-Janeiro, the chief strategy officer of Singapore-based crypto exchange Bitrue, in an email statement shared with Forkast Tuesday.</p>
      <p>XRP was the only top 10 cryptocurrency to gain on Wednesday morning, adding 3.89% to US$0.7678, further extending its weekly surge of 61.26%.</p>
      <p>Losses were led by Polygon’s Matic and Solana. Polygon dipped 5.81% to US$0.7363, falling 0.36% in the past seven days.</p>
      <p>Solana lost 5.24% to US$25.33, but it’s holding gains of 15.01% for the week.</p>
      <p>The total crypto market capitalization fell 0.50% in the past 24 hours to US$1.2 trillion, with trading volume dipping 9.49% to US$32.17 billion.</p>
      <h3>NFT trade sinks toward yearly low</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index fell 0.37% in the past 24 hours to 2,717.68 as of 07:45 a.m. in Hong Kong.</p>
      <p>Total NFT trading volume fell 14.05% to US$17.83 million in the past 24 hours, according to data from CryptoSlam. Forkast NFT market indexes measuring the performance on Ethereum, Polygon and Cardano blockchains all dropped, while the Solana index rose.</p>
      <p>“We’re on pace for another yearly low for total global transactions today, and in general, sales are pretty unremarkable across blockchains,” said Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News. “The top collections are struggling to even reach $500k in daily secondary sales, which is unusual to see. The index reflects a flat market.”</p>
      <p>Ethereum-based Azuki NFT collection topped trading volume, rising 3.26% to US$972,691. Uncategorized Bitcoin Ordinals — unique digital assets on the Bitcoin network that are not identified as part of one established collection — ranked second in volume, gaining 5.05% to US$698,982.</p>
      <p>Luxury brand Louis Vuitton launched new digital collectibles on Monday celebrating its Speedy 40 VIA bag, which was designed in collaboration with American singer-songwriter Pharrell.</p>
      <p>“Each [owner] will be given access to exclusive digital products, each accompanied by its physical twin,” tweeted Gaspard Lézin, the NFT collection manager for Louis Vuitton, on Monday.</p>
      <h3>U.S. futures flat; CME predicts 99.8% chance of Fed rate hike</h3>
      <p>U.S. stock futures traded flat as of 10:20 a.m. in Hong Kong after all three major U.S. indexes gained during regular trading on Tuesday.</p>
      <p>In Asia, Japan’s Nikkei 225 gained on the bullish U.S. market session that followed upbeat earnings reports from major corporations, according to Trading Economics.</p>
      <p>However, most other Asian stock markets continued a downtrend after China reported lower-than-expected economic growth of 6.3% for Q2 on Monday. The Shanghai Composite fell for the third consecutive session. Hong Kong’s Hang Seng, and South Korea’s Kospi also posted losses as of 10:40 a.m. in Hong Kong.</p>
      <p>U.S. financial heavyweights Bank of America Corp. and Morgan Stanley & Co. both beat analyst estimates in Q2 earnings, lifting investor sentiment. Later on Wednesday, Tesla, Netflix, United Airlines and Goldman Sachs Group all report earnings for Q2.</p>
      <p>The CME FedWatch Tool predicts a 99.8% chance the Fed will raise interest rates by 25 basis points at the next meeting on July 26. U.S. interest rates are now at 5% to 5.25%, the highest since 2006.</p>
    </div>
  },
  {
    id: 617,
    title: 'XRP’s 60% Weekly Gain Defies Broader Crypto Slump as Bitcoin Stalls Below $30K',
    category: 'Crypto',
    subCategory: ['XRP', 'Bitcoin', 'crypto'],
    description: <div className="blog-desc">
      <p>Ripple’s XRP outperformed most cryptocurrencies Tuesday as the initial market-wide rally following last Thursday’s lawsuit ruling has seemingly fizzled out.</p>
    </div>,
    authorName: 'Krisztian Sandor',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 19, 2023 - 1:48 AM', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689849825/ncx-blog-903_w95j9r.webp',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Ripple’s XRP outperformed most cryptocurrencies Tuesday as the initial market-wide rally following last Thursday’s lawsuit ruling has seemingly fizzled out.</p>
    	<p>The native token of the Ripple payment system was the best performer among the top 25 crypto assets over the last 24 hours, gaining 3.6%. XRP retook its place as the fourth largest digital asset by market value, toppling the embattled exchange giant Binance’s BNB token.</p>	
    	<p>Bitcoin (BTC) and ether (ETH), the two largest crypto assets by market capitalization, are now each lower than their levels from last Thursday, both stuck below their psychologically important price levels at $30,000 and $1,900.</p>
      <p>“The Ripple vs. SEC lawsuit did not generate a broader lasting rally in crypto,” David Lawant, head of research at institutional crypto exchange FalconX.</p>
      <p>Though it’s given back some of last Thursday's gains, XRP was still up roughly 60% since the ruling. The CoinDesk Crypto Market Index (CMI), which tracks the performance of the broader digital asset market, meanwhile, gave up all of its Thursday advance, declining 0.5% over the same period.</p>
      <p>Top altcoins such as Polygon’s MATIC, Solana’s SOL and Cardano’s ADA, which enjoyed sizable rallies last Thursday, completely reversed to the levels where they traded prior to the XRP ruling. MATIC and SOL are now down 5% over the week, while ADA dropped 2%.</p>
      <p>While the rush to purchase XRP could well be mostly behind us, Lawant said, digital asset funds adding the token to their holdings could represent some buying pressure boosting its price.</p>	
    	<p>“Index funds are one potential source of additional institutional flow I’m still watching,” he said.</p>
      <p>“XRP has a market capitalization of more than 3.5x of ADA and SOL, typically the largest constituents outside of BTC and ETH, with a weight of little over 1%,” said Lawant. “If XRP is included in the underlying indexes, the asset could represent 4-5% of the composition of the top broad crypto indexes.”</p>
    </div>
  },
  {
    id: 616,
    title: 'Ethereum’s YTD turns profits despite price fluctuations',
    category: 'Crypto',
    subCategory: ['Ethereum', 'YTD', 'Price'],
    description: <div className="blog-desc">
      <p>From exhilarating surges to fleeting declines, Ethereum’s journey throughout the year has been a rollercoaster, yet holding strong with profitability and growth potential.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 16, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689510118/ncx-blog-886_urekx2.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
	    <p>From exhilarating surges to fleeting declines, Ethereum’s journey throughout the year has been a rollercoaster, yet holding strong with profitability and growth potential.</p>
	    <ul>
        <li>ETH has witnessed over 7% rise in price only three times YTD.</li>
        <li>YTD, ETH has gained over 66% in value.</li>
      </ul>
      <p>Ethereum [ETH] and other alternative cryptocurrencies experienced a thrilling surge not too long ago, driven by the uplifting victory of Ripple. The surge proved transient, as a decline has now set in.</p>	
	    <p>But how has Ethereum performed throughout the year amidst the ebbs and flows of its price trends?</p>	
      <h3>Ethereum value YTD increases</h3>
      <p>The Ethereum price has witnessed a rollercoaster of fluctuations, and amidst recent ups and downs, it’s easy to lose sight of its overall trajectory. However, according to a recent post by Artemis, ETH has achieved a noteworthy 66.7% year-to-date (YTD) increase while not appearing overwhelmingly impressive.</p>
      <p>This statistic revealed that those who acquired the asset at the beginning of the year and held onto it have managed to secure profitable positions.</p>
      <h3>Examining Ethereum’s price trend</h3>
      <p>Ethereum embarked on an upward trajectory on the daily timeframe chart, beginning around the $1,200 price level. Remarkably, it sustained this upward momentum for a remarkable stretch of nine consecutive days.</p>
      <p>Its price surged from around $1,200 throughout this bullish phase to approximately $1,600. Notably, during this uptrend, a significant event known as a golden cross occurred, symbolized by the yellow line crossing over the blue line.</p>
      <p>Since then, the yellow line has remained consistently above the blue line.</p>
      <p>As of this writing, Ethereum was trading at around $1,940, displaying a slight price increase. However, on 13 July, it experienced a notable gain of over 7%, only to lose over 3%.</p>
      <p>A broader analysis of the chart revealed that this rise on 13 July was only the third instance this year where Ethereum witnessed a surge of over 7% in value. Additionally, the Relative Strength Index indicated that Ethereum was bullish at press time.</p>
      <h3>Long-term holders stay profitable</h3>
	    <p>The 180-day Market Value to Realized Value (MVRV) ratio of Ethereum revealed that long-term holders have generally remained profitable. This suggested that despite the volatility witnessed by the asset year-to-date, holders have avoided being at a loss.</p>
      <p>As of this writing, the MVRV ratio was 7.3%, indicating profitability and the potential for more growth.</p>
      <p>Furthermore, the chart demonstrated that back in April, the MVRV ratio exceeded 39%, affirming the possibility of an increase in the value of holdings.</p>
    </div>
  },
  {
    id: 615,
    title: 'Uniswap: Another resistance level claimed – how high can rally go?',
    category: 'Crypto',
    subCategory: ['UNIswap', 'Resistance', 'level'],
    description: <div className="blog-desc">
      <p>Uniswap continued its bullish advance by flipping another resistance level to support.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 16, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689509768/ncx-blog-885_w0keua.png',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Uniswap continued its bullish advance by flipping another resistance level to support.</p>
    	<p>Disclaimer: The information presented does not constitute financial, investment, trading, or other types of advice and is solely the writer’s opinion.</p>	
    	<ul>
        <li>Uniswap jumped by 14% to flip the $5.72 price level to support.</li>
        <li>Buyers’ dominance of long/short ratio highlighted market sentiment.</li>
      </ul>
      <p>A recent price report highlighted the buying opportunity available for Uniswap [UNI] bulls on the higher timeframes. This buying opportunity was utilized to the maximum by bulls, as UNI flipped the $5.72 resistance level to support.</p>
      <p>Despite Bitcoin [BTC] experiencing a sharp correction that dropped it to $30.3k, the overall sentiment in the market remained bullish. This could be significant for altcoins such as UNI, as buyers look to achieve more gains in the long term.</p>
      <h3>Buying pressure sees $5.72 resistance cave in</h3>
      <p>Uniswap has been on an aggressive bullish rebound after it touched the 2023 low of $3.64 on 10 June. Since then, UNI has recorded gains of 68.2% over a month-long period. These gains have seen it flip resistance levels at $4.34, $4.87, and $5.21.</p>
      <p>Building on this bullish resurgence, UNI claimed another resistance level over the past 48 hours with a strong bullish close on the 12-hour timeframe. While the general market correction limited any further gains after flipping the level, the signals hinted at more bullish yields.</p>
      <p>The Relative Strength Index (RSI) remained just below the overbought zone, denoting heightened buying pressure. Similarly, the On Balance Volume (OBV) moved northwards, reinforcing an increase in demand for Uniswap.</p>
      <p>Ambitious UNI buyers can look to ride the buying wave to $7 from the $5.72 support level. However, a potential area to watch will be the minor resistance at the $6.37 price level, as this was a key level for bearish activity in mid-April.</p>
      <h3>Longs hold upper hand in futures market</h3>	
    	<p>According to data from Coinglass exchange long/short ratio, UNI longs held a notable 52.5% share of the open positions. This translated to $25.02M worth of long positions which was $2.42M more than short positions.</p>
      <p>The trend in the futures market reinforced near-term bullish sentiment, with Uniswap investors primed to take advantage of a bullish Bitcoin to rally again.</p>
    </div>
  },
  {
    id: 614,
    title: 'SEC Formally Accepts BlackRock Spot Bitcoin ETF Application for Review',
    category: 'Crypto',
    subCategory: ['SEC', 'BlackRock', 'ETF'],
    description: <div className="blog-desc">
      <p>BlackRock's application to offer a spot Bitcoin exchange-traded fund (ETF) has been added to the official docket of the Securities and Exchange Commission as part of its proposed rule change process.</p>
    </div>,
    authorName: 'Ryan Ozawa',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 16, 2023 - 12:39 AM', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689508909/ncx-blog-883_oev0lq.webp',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>BlackRock's application to offer a spot Bitcoin exchange-traded fund (ETF) has been added to the official docket of the Securities and Exchange Commission as part of its proposed rule change process. The move, recorded late Thursday, advances the most closely-watched Bitcoin-related proposal to the SEC to date.</p>
    	<p>The application for the iShares Bitcoin Trust was filed nearly a month ago, prompting a new wave of optimism across the crypto market, and additional filings for spot Bitcoin ETFs from a number of other prominent players, including from firms like Invesco, Wisdom Tree, Bitwise, and Fidelity—which saw its previous application rejected last year along with those from other contenders.</p>	
    	<p>When the SEC signaled last month that BlackRock's application was lacking, the company filed a revised application, adding a "surveillance sharing" clause that would involve the Coinbase crypto exchange monitoring and reporting possible illegal activity.</p>	
    	<p>Soon enough, Valkyrie updated its application with the same provision, as did Fidelity and ARK Invest.</p>
      <p>A spot Bitcoin ETF would track the value of Bitcoin without requiring the asset to be held directly. It would also be tradable on a traditional stock exchange.</p>
      <p>BlackRock Bitcoin ETF Prospects Boost Institutional Investor Sentiment</p>
      <p>While a Bitcoin ETF based on Bitcoin futures launched to great fanfare earlier this year, a spot ETF—which is tied to the current price of the cryptocurrency—has been described as a "holy grail" for the industry. The SEC's continued resistance to approving one over the past several years has been described as a "complete and utter disaster."</p>
      <p>With BlackRock's application now on the SEC's official calendar, it will be published in the Federal Register and trigger a 21-day public comment period.</p>
      <p>Meanwhile, four other applications were docketed on Friday related to the Cboe BZX Exchange, which was the first entity to apply for a Bitcoin ETF in March 2021, They include the Wise Origin Bitcoin Trust, WisdomTree Bitcoin Trust, VanEck Bitcoin Trust, and Invesco Galaxy Bitcoin ETF, which will also enter a three-week comment period.</p>
    </div>
  },
  {
    id: 613,
    title: 'What Does The XRP Not A Security Ruling Mean For Other Cryptocurrencies?',
    category: 'Crypto',
    subCategory: ['XRP', 'Security', 'Cryptocurrencies'],
    description: <div className="blog-desc">
      <p>The recent ruling in the SEC-Ripple case doesn't quite end the lack of clarity around crypto regulation</p>
    </div>,
    authorName: 'Kyle Torpey',
    authorurl: 'https://www.investopedia.com/kyle-torpey-7368504',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 15, 2023 ', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689509151/ncx-blog-884_bmhq4d.webp',
    content: <div className="blog-content">
    	<h3>The recent ruling in the SEC-Ripple case doesn't quite end the lack of clarity around crypto regulation</h3>
      <p>The crypto industry celebrated Ripple's partial win in court on Thursday, as a judge has ruled XRP (XRPUSD) is not a security. While many crypto proponents see this as a sign these sorts of assets will be allowed to flourish in the U.S., some legal experts point out that there is still much more regulatory clarity needed.</p>
      <h3>KEY TAKEAWAYS</h3>
      <ul>
        <li>A Thursday court ruling in Ripple's case with the SEC indicated that the digital asset XRP is not a security.</li>
        <li>The crypto industry has responded positively to the news, with crypto exchanges relisting XRP and many hoping this will lead to an end to the perceived war on crypto waged by the SEC.</li>
        <li>The ruling will undoubtedly have an impact on the SEC's cases against Binance and Coinbase, and the fact that many U.S.-based exchanges have decided to relist XRP is seen as positive development for Binance and Coinbase's legal standing.</li>
        <li>While there is reason for crypto enthusiasts to rejoice over the short term, the reality is the Ripple ruling may not be the end-all-be-all method by which crypto assets are classified.</li>
      </ul>
      <h3>What Does the XRP Ruling Mean for Crypto Assets?</h3>
      <p>Bitcoin is a commodity, and that brings clarity on how it is regulated and by which agency. But that is not the case for most cryptocurrencies.</p>
    	<p>The U.S. Securities and Exchange Commission (SEC) contends that most cryptocurrency assets are securities and therefore must fall under the purview of securities laws.</p>
	    <p>To that effect, the regulator has brought enforcement actions against issuers of the crypto tokens and as well as crypto exchanges such as Binance and Coinbase (COIN) that provided a platform to trade those tokens.</p>	
	    <p>With the latest court ruling that XRP is not an investment contract itself, some members of the crypto industry are hopeful that the SEC will be forced to take a more hands off approach going forward. In addition to XRP, a number of other crypto assets that had previously been deemed securities, such as Solana (SOLUSD) and Polygon (MATIC), rallied on the news of the Ripple court order.</p>
      <p>According to Morrison Cohen Partner Jason Gottlieb, Thursday's ruling is a departure from what had previously been found in the SEC's cases against Telegram and Kik, where all initial coin offerings were part of one investment scheme. Now, it's possible that crypto assets could be traded as "unrestricted securities" in a situation where they are sufficiently decentralized or not sold directly to retail. Gottlieb also says it would be difficult to conclude that staking rewards are securities in the context of the Ripple ruling.</p>	
	    <p>"All in all, while lots of thorny questions remain, the initial jubilation over the decision is justified," said Gottlieb. "This new judicial guidance is a boon to token creators and markets, giving them new non-violative ways to issue and trade tokens."</p>
      <h3>Implications for SEC Cases Against Binance and Coinbase</h3>
      <p>In addition to offering more clarity for crypto token issuers, Thursday's Ripple court order also opens up more flexibility in terms of what can be listed on exchanges, at least in the eyes of those operating the exchanges.</p>
      <p>The XRP token "is not in and of itself a “contract, transaction[,] or scheme” that embodies the Howey requirements of an investment contract," a federal judge ruled Thursday. The order also said that XRP sold to institutional investors broke securities laws while XRP traded on exchanges by retail investors did not.</p>
      <p>That could have some implications for the lawsuits the SEC has filed against Binance and Coinbase, which were both sued for operating unlicensed securities exchanges.</p>
      <p>Coinbase Chief Legal Officer Paul Grewal told CNBC that the crypto exchange's legal standing has been improved by the court order in the Ripple case. "I thought we would win before this decision," said Grewal. "We think this decision has only further strengthened the case."</p>
      <p>In an episode of The Chopping Block, Brown Rudnick Partner Stephen Palley brought up the question of whether the SEC may go after the crypto exchanges that have now decided to relist XRP. "If so, the exchanges are willing to fight," Palley said.</p>
      <p>Palley added that he's confident Coinbase would be raising this new legal opinion in their case against the SEC to illustrate that the transactions that occur on their platform are not securities transactions. "The most important sign, at least short term, is that Coinbase and Kraken have relisted [XRP]," Palley added. "That, obviously, means something."</p>
      <h3>This is Not a Final Decision</h3>
      <p>While the crypto industry can take the Ripple court order as a win for now, the reality is this ruling may not end up being the final say on the matter for two key reasons.</p>
      <p>For one, the SEC still has the ability to appeal the decision made by the judge in the Ripple case. Secondly, the final word on crypto assets' regulatory classification may come from Congress by way of new legislation, as SEC Commissioner Hester Peirce has been advocating.</p>
      <p>In his own post on the matter, Brown Rudnick Partner Preston Byrne shared his belief that the SEC is likely to appeal this decision and see the ruling overturned.</p>
      <p>Gottlieb also added his own caveat that this is simply one decision from one district court that other courts could adopt or not follow.</p>
      <p>Specifically, Byrne says the court made an error when it concluded "programmatic" sales of XRP on exchanges were not investment contracts. In his view, it's "obvious to anyone active in the industry" that Ripple is the principal promoter of XRP, no matter whether the purchasers of the digital asset on exchanges were aware they were purchasing from Ripple.</p>
      <p>"My hope is that Congress will get its act together and decide that it’s time for cryptocurrency tokens and cryptocurrency exchanges to receive their own purpose-built disclosure and supervisory frameworks which will take cryptocurrency regulation out of the slow and contradictory hands of our courts, and the politically motivated hands of the SEC," wrote Byrne. "My expectations of Congress are, however, quite low."</p>
    </div>
  },
  {
    id: 613,
    title: 'Weekly Market Wrap: Bitcoin rises past US$31,000, cryptos rally after Ripple’s win against U.S. SEC',
    category: 'Crypto',
    subCategory: ['Botcoin', 'Ripple', 'SEC'],
    description: <div className="blog-desc">
      <p>Bitcoin rose 3.46% from July 7 to July 14 to US$31,222 at 7:30 p.m. Friday in Hong Kong. The world’s largest cryptocurrency by market capitalization has been trading above the US$30,000 mark for the whole week. Ether rose 6.88% over the week to US$1,992.</p>
    </div>,
    authorName: 'Zoltan Vardai',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 14, 2023 - 5:28 PM', 
    cover: 'https://res.cloudinary.com/dqbcnwbvy/image/upload/v1689850151/ncx-blog-904_zjzcqq.webp',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin rose 3.46% from July 7 to July 14 to US$31,222 at 7:30 p.m. Friday in Hong Kong. The world’s largest cryptocurrency by market capitalization has been trading above the US$30,000 mark for the whole week. Ether rose 6.88% over the week to US$1,992.</p>
    	<p>In a major win for the crypto industry, U.S. District Court Judge Analisa Torres ruled on Thursday that Ripple Labs’ programmatic sales of its XRP cryptocurrency did not qualify as financial securities.</p>	
    	<p>The decision followed a 30-month legal battle, offering crypto investors a boost of optimism, which pushed up Bitcoin to US$31,736 on Friday and Ether to US$2,022, a high for Ether since May 2022.</p>
      <p>“Thursday’s landmark ruling that Ripple’s XRP tokens sold on exchanges weren’t securities has in part contributed to the boost in the price of Bitcoin, pushing it past the long-awaited US$31,000,” Lucas Kiely, the chief investment officer of digital asset platform Yield App, told Forkast, adding that further positive news from institutions will boost Bitcoin prices.</p>
      <p>In other positive news, Europe’s first Bitcoin exchange-traded fund (ETF) is expected to be publicly listed this month, following a 12-month delay. The news came after a flurry of spot Bitcoin ETF applications in the U.S. from financial heavyweights such as BlackRock, WisdomTree and Fidelity Investments.</p>
      <p>Similar positive catalysts will be needed to boost investor sentiment and maintain Bitcoin above US$31,000, according to Jonas Betz, crypto market analyst and founder of consultancy firm Betz Crypto.</p>
      <p>“The approval of a Bitcoin ETF would be a major step towards mainstream acceptance of Bitcoin, signaling regulatory confidence and potentially paving the way for significant institutional investment. News like this could trigger a bullish market reaction, pushing Bitcoin above US$31,000,” wrote Betz, in a statement shared with Forkast.</p>
      <p>Boston-based Circle Internet Financial Limited, the issuer of the USDC stablecoin, on Wednesday announced workforce reductions and investment in non-core activities to refocus its business. The exact number of layoffs is unknown.</p>
      <p>Adding to investor concerns, market talk suggested that the Bank of America was closing customer accounts related to Coinbase. The exchange’s chief executive officer, Brian Armstrong, created a Twitter poll on Wednesday asking if any customers had their Bank of America accounts closed due to transactions with Coinbase. Some 8.9% of the respondents said yes, while 19.3% said no and the other 71.9% simply chose “show results.”</p>
      <p>The next day, Barclays downgraded Coinbase’s stock, ahead of the exchange’s earnings release scheduled for August 3, according to the bank’s research report. On the upside, the bank raised its price target for Coinbase stock to US$70, from US$61.</p>
      <p>In the macroeconomy, U.S. inflation fell to 3% in June, down from 4% in May, representing the slowest rate of inflation since March 2021.</p>
      <p>Max Breus, the co-founder and chief executive officer of Ubix Network, a protocol that connects blockchains with different consensus mechanisms, said that macroeconomic developments are just as important for the crypto market as the actions of regulatory agencies.</p>
      <p>“The situation in the main global markets, the stock market, the bond market, the Fed’s rate policy is very important for investor sentiment. If there is a downtrend in global markets, then the cryptocurrency market will also be under pressure. Spot Bitcoin ETF is an important factor, but it is only a financial instrument. Global factors are more important for investor sentiment,” wrote Breus, in a statement shared with Forkast.</p>
      <p>The global crypto market capitalization stood at US$1.25 trillion on Friday at 7:30 p.m. in Hong Kong, up 6.83% from US$1.17 trillion a week ago, according to CoinMarketCap data. With a market cap of US$606 billion, Bitcoin represented 48.5% of the market while Ether, valued at US$239 billion, accounted for 19.2%.</p>
      <h3>Notable Movers: XRP, XLM</h3>	
      <p>The XRP token was this week’s biggest gainer among the top 100 coins, rising 72.16% to US$0.8036. XRP started its rally on Thursday, boosted by Ripple’s court win over the Securities and Exchange Commission.</p>
      <p>The XLM token, the native crypto of the Stellar blockchain, was the week’s second-biggest gainer, up 57.57% to US$0.1515. The token started its rally on Thursday, in parallel with the XRP token, boosted by market-wide euphoria from the court ruling.</p>
      <h3>Next week: Bitcoin needs to close above US$31,000 to pave way to US$32,500</h3>
      <p>Next week, investors will attend the sixth edition of the Ethereum Community Conference (EthCC) in Paris, France, which is the largest Ethereum-themed convention in Europe.</p>
      <p>On the macroeconomic front, the Federal Reserve is set to release its latest balance sheet on Thursday, showing the Fed’s assets and liabilities. Investors will also anticipate a Tuesday speech from the vice-chairman of the U.S. Federal Reserve, Michael Barr, for clues on the Fed’s upcoming monetary policy decisions.</p>
      <p>Yield App’s Kiely said that Bitcoin needs to hold above US$30,500 to maintain its bullish momentum.</p>
      <p>“July began with a steady upward trend. Bitcoin retook recent resistance levels of US$30,500 earlier this week. Staying above that level will be key if Bitcoin is to continue the bullish trend,” Kiely wrote.</p>
      <p>Ubix Network’s Max Breus said that US$31,000 is a key level for Bitcoin investors to watch.</p>
    	<p>“This is the high level of consolidation since June 20. If the weekly price closes above US$31,000, then next week has a high chance of moving to US$32,500,” wrote Breus.</p>
    </div>
  },
  {
    id: 612,
    title: 'Ether Pushes Past $2K as Ripple Partial Win Against SEC Invigorates Market',
    category: 'Crypto',
    subCategory: ['Ether', 'Ripple', 'SEC'],
    description: <div className="blog-desc">
      <p>Ether (ETH) is trading hands above $2,000, a three-month high, as the market picked up renewed optimism after a U.S. court found that sales of Ripple’s XRP tokens on exchanges and through algorithms did not constitute investment contracts.</p>
    </div>,
    authorName: 'Sam Reynolds',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 14, 2023 - 7:04 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-863.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Ether (ETH) is trading hands above $2,000, a three-month high, as the market picked up renewed optimism after a U.S. court found that sales of Ripple’s XRP tokens on exchanges and through algorithms did not constitute investment contracts.</p>
    	<p>The second largest cryptocurrency by market cap was up 7.4% at $2,010 during the Asian morning hours. Various layer-1 tokens, such as Solana’s SOL, which has been accused by the SEC of being a security, also recorded double digit gains following the Ripple-SEC ruling on Thursday.</p>	
    	<p>At the time of writing, SOL jumped 33.8%, MATIC gained 19.5%, Cardano's ADA was up 25%, and Stellar’s XLM soared 52%, in the last 24 hours.</p>	
	    <p>The governance token of Lido DAO (LDO) was also up 25% in the last 24 hours. The CoinDesk Market Index (CMI), a measure of crypto markets performance, was up 7.2%.</p>
      <p>Bitcoin (BTC) also passed its one-year high, trading at $31,424. Ripple's XRP nearly doubled after the ruling and was trading 66% at 78 cents at the time of writing.</p>
      <p>In the last 12 hours, $203 million worth of short positions by crypto traders was liquidated, according to Coinglass data.</p>
    </div>
  },
  {
    id: 611,
    title: 'Bitcoin, Ether surge after Ripple notched partial win against SEC; Ripple’s XRP leads crypto rally',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'XRP', 'Ether'],
    description: <div className="blog-desc">
      <p>Bitcoin surged on Friday morning in Asia to breach the US$31,000 resistance level, after Ripple Labs achieved a partial victory in its three-year lawsuit against the U.S. Securities and Exchange Commission (SEC).</p>
    </div>,
    authorName: 'Tom Zuo',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 14, 2023 - 6:04 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-862.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin surged on Friday morning in Asia to breach the US$31,000 resistance level, after Ripple Labs achieved a partial victory in its three-year lawsuit against the U.S. Securities and Exchange Commission (SEC).</p>
    	<p>The regulator  accused Ripple of offering XRP as an unregistered security. Along with Bitcoin, Ether and all other top 10 non-stablecoin cryptocurrencies logged considerable gains, with XRP spearheading the rally with a rise of over 70%. U.S. equity futures traded flat in the Asia time zone on Friday as investors await earnings reports from major U.S. banks. Thursday’s U.S. producer price index (PPI) has added to a sense that inflation is slowing in the country.</p>
      <h3>XRP ruling provides crypto market boost</h3>	
    	<p>Bitcoin climbed 3.45% over the last 24 hours to US$31,463 as of 07:50 a.m. in Hong Kong for a weekly gain of 4.93%, according to data from CoinMarketCap. The world’s largest cryptocurrency reached a high of US$31,814 early Friday morning, the highest price since June 2022.</p>	
    	<p>In a summary judgement on Thursday,  New York Southern District Court Judge Analisa Torres ruled that Ripple’s programmatic sales of the XRP token on public exchanges do not constitute securities, marking a partial victory for Ripple with the SEC. The regulator first sued the San Francisco-based financial technology firm on the grounds that XRP constitutes a security in 2020.</p>
      <p>The verdict came with a critical caveat, however.  The court ruled that Ripple’s XRP sales to institutional investors did violate securities laws.</p>
      <p>Nonetheless, Ripple’s Chief Executive Officer Stuart Alderoty called the judgement “a huge win” in a Friday tweet.</p>
      <p>A huge win today – as a matter of law - XRP is not a security. Also a matter of law - sales on exchanges are not securities. Sales by executives are not securities. Other XRP distributions – to developers, to charities, to employees are not securities.</p>
      <p>Alderoty said that “the only thing the Court found constitutes an investment contract is past direct XRP sales to institutional clients.” Those transactions will now be subject to further court proceedings.</p>
      <p>Despite the overall optimism in the crypto community evidenced by the upswing in the market, there are also a number of industry voices sounding a note of caution.</p>
      <p>In an emailed comment, CoinShares’ Head of Product Townsend Lansing said that the aspect of the ruling that identifies Ripple’s sales to institutional customers as a violation of securities law marks “a considerable victory for the SEC.”</p>
      <p>The ruling will now set a precedent for legal action against other cryptocurrencies by regulators, he said.</p>
      <p>“It is important to note that institutional investors who purchased directly from Ripple may find themselves subject to class-action litigation as potential underwriters,” Lansing added. “This is an area to watch closely, especially if big-name venture capitalists were involved.”</p>
      <p>But, while some sense of jeopardy remained for those involved, Justin d’Anethan, head of APAC business development at Belgium-based crypto market maker Keyrock, believes that the ruling had at least “shook investors in the best of ways.”</p>
      <p>Elsewhere, Europe’s first Bitcoin exchange-traded fund (ETF) is set for a public listing this month. That follows a 12-month delay from the initially planned date, the Financial Times reported on Thursday.</p>
      <p>Experts have identified the listing as another potential catalyst for the crypto market.</p>
      <p>“With the talks of ETFs in the background, the idea that we could see products tracking many cryptocurrencies is not implausible and traders are naturally positioning themselves accordingly,” said Keyrock’s d’Anethan.</p>
      <p>Like Bitcoin, Ether surged 6.99% to US$2,004, moving up 7.69% for the week. The token is trading above US$2,000 for the first time since April.</p>
      <p>Boosted by the ruling on the SEC-Ripple case, all other top 10 non-stablecoin cryptocurrencies rose in the past 24 hours. Cardano’s ADA, Solana’s SOL and Polygon’s MATIC each logged two-digit gains.</p>
      <p>XRP, subject of the SEC’s lawsuit against issuer Ripple, jumped 72.75% to US$0.8142 and added 74.89% for the week. In doing so, it replaced Binance’s BNB as the third largest non-stablecoin cryptocurrency by market cap.</p>
      <p>The total crypto market capitalization rose 6.19% in the past 24 hours to US$1.26 trillion, while trading volume jumped 95.72% to US$60.3 billion.</p>
      <h3>Forkast 500 up, Dapper Labs announces layoffs</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>The main Forkast 500 NFT index edged up 0.14% in the past 24 hours to 2,699.39 as of 09:55 a.m. in Hong Kong. Forkast’s Solana and Cardano NFT market indices also moved higher, while the Etheum and Polygon indices dropped.</p>
      <p>Despite the overnight rise in cryptocurrency prices associated with the Ripple ruling, total NFT sales volume dipped 1.72% in the past 24 hours to US$24.45 million, according to data from CryptoSlam.</p>
      <p>Among all NFT collections, Bitcoin-based $FRAM BRC-20 NFTs topped the 24-hour sales volume, edging up 0.31% to US$2.14 million. In doing so, it recorded over two thirds of the total volume on the Bitcoin network. Ethereum-based collections Bored Ape Yacht Club (BAYC) and the Captainz ranked second and third respectively.</p>
      <p>BAYC #1734, part of the BAYC collection, topped collectible sales in the past 24 hours. It was sold at 600 Ether (US$1.2 million) early Friday. As exciting as this big sale is, BAYC #1734 was previously sold at 800 Ether in January 2022.</p>
      <p>“This grail Bored Ape was sold at a huge loss, but the takeaway is that there is still a market for very high-priced NFTs, even in a bear market,” said Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News.</p>
      <p>As the bear market continues, Dapper Labs, the developer of hit NFT collections such as CryptoKitties and NBA Top Shot,  announced the layoff of 51 staff on Thursday — roughly 12% of its workforce.</p>
      <p>“The decision was incredibly difficult” but necessary to “ensure a lean and efficient” company, said Dapper Labs Chief Executive Officer Roham Gharegozlou in a letter to employees. It is the third round of layoffs at the company in the past 12 months. Those layoffs saw its workforce cut by 22% in November 2022 and by a further 20% in February this year.</p>
      <p>Cool Cats Group, the Web3 company behind NFT collection Cool Cats, announced a partnership with game developer nWayPlay,  a subsidiary of Hong Kong-based software and investment giant Animoca Brands. Together, the two companies will develop a Web3 game set to be released between late 2023 and early 2024.</p>
      <h3>US equities flat amid data releases</h3>
      <p>U.S. stock futures traded flat as of 11:50 a.m. in Hong Kong.  All three major U.S. indexes closed higher in regular Thursday trading, with the Nasdaq leading the gains with a 1.58% rise.</p>
      <p>The U.S. producer price index (PPI) released Thursday recorded a 0.1% year-on-year rise for the month of June, down from 0.9% in May. The June figure is the smallest increase since August 2020.</p>
      <p>The data is also well below analyst expectations of a 0.4% annual increase in PPI. Combined with weak consumer price index data released on Wednesday, the data points to a slowdown in U.S. inflation.</p>
      <p>“The better-than-expected inflation figures for June 2023 would suggest that policy is working to ease price pressures as higher interest rates reduce demand. Key payroll figures announced in early July also came in below expectations with the economy generating less jobs than expected in June,” said Keith Wade, chief economist & strategist at asset management Schroders.</p>
      <p>However, given lingering inflation worries, Wade still expects the Federal Reserve to make another interest rate hike in July. The tight labor market and the potential for a reversal in the Fed’s monetary policy could, Wade said, trigger a rally in the bond market that would “loosen financial conditions.”</p>
      <p>“Given the efforts the Fed has made to convince markets they were serious about inflation and not about to pivot, this would seem to be too early,” said Wade.</p>
      <p>Federal Reserve Governor Christopher Waller said in a Thursday speech that the Fed would need to “keep policy restrictive for some time” in order to reduce inflation below its 2% target. He said that he anticipates two more rate hikes by the end of the year.</p>
      <p>“I am more confident that the banking turmoil is not going to result in a significant problem for the economy, and I see no reason why the first of those two hikes should not occur at our meeting later this month,” said Waller.</p>
      <p>Meanwhile, a Wednesday report by financial comparison service company Bankrate showed that economists believe there is a 59% chance the U.S. economy will enter a recession inside the next twelve months. The report cited concern about the long-term economic impact of this year’s extended series of rate hikes.</p>
      <p>The 59% figure is, however, a decrease on the 64% chance given in April, and the lowest level in the past 12 months.</p>
      <p>The Federal Reserve meets on July 26 to discuss its next move on rates, which now sit between 5% and 5.25%. The CME FedWatch Tool predicts a 92.4% chance the Fed will enact a 25-basis-point rate increase this month, and a 7.6% chance it will leave the rate unchanged.</p>
      <p>Investors are now awaiting the release of second-quarter earnings reports from major financial institutions on Friday. The releases will include reports from JPMorgan Chase, Wells Fargo, Citigroup, BlackRock and more.</p>
      <p>Elsewhere, main stock indexes across Asia traded higher on Friday morning. China’s Shanghai Composite, Hong Kong’s Hang Seng, Japan’s Nikkei and South Korea’s Kospi all posted rises as of 12:30 p.m. in Hong Kong.</p>
    </div>
  },
  {
    id: 610,
    title: 'XRP soars 61% after a judge says the cryptocurrency is not a security in a big win over the SEC',
    category: 'Crypto',
    subCategory: ['XRP', 'SEC', 'Cryptocurrency'],
    description: <div className="blog-desc">
      <p>XRP soared as much as 61% on Thursday after a US judge ruled that certain trades of Ripple's crypto token did not constitute the sale of a security.</p>
    </div>,
    authorName: 'Matthew Fox',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 13, 2023 - 10:36 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-864.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
      <ul>
        <li><strong>XRP soared as much as 61% on Thursday after a US judge said certain aspects of the sale of the token did not break federal securities laws.</strong></li>
        <li><strong>The SEC had sued Ripple for the sale of its XRP token in 2020 for the failure to register XRP as a security.</strong></li>
        <li><strong>Crypto related stocks like Coinbase and Riot Blockchain soared more than 10% following the news.</strong></li>
      </ul>
    	<p>XRP soared as much as 61% on Thursday after a US judge ruled that certain trades of Ripple's crypto token did not constitute the sale of a security.</p>
    	<p>Ripple, its CEO Brad Garlinghouse, and its co-founder Christian Larsen, were sued by the SEC in 2020 for the alleged failure to register its sale of $1.4 billion of XRP tokens as securities. Since then, the company has been entangled in a drawn out lawsuit that could have a big impact on the broader crypto industry going forward.</p>	
    	<p>US District Judge for the Southern District of New York Analisa Torres said the XRP token is "not necessarily a security on its face."</p>	
    	<p>"XRP, as a digital token, is not in and of itself a 'contract, transaction, or scheme' that embodies the Howey requirements of an investment contract," Torres said.</p>
      <p>But there are multiple facets of the ruling and it gets complicated depending on the type of sale of XRP.</p>
      <p>The key differentiator in the ruling is whether the sale of the XRP token was directly from Ripple to institutional investors, or if it was a secondary sale on a crypto exchange.</p>
      <p>The judgement said that programmatic sales through algorithms on various crypto exchanges were not investment contracts, which was a big win for Ripple.</p>
      <p>"There is no evidence that a reasonable Programmatic Buyer, who was generally less sophisticated as an investor, shared similar 'understandings and expectations' and could parse through the multiple documents and statements that the SEC highlights," Torres said.</p>
      <p>A motion for summary judgement filed by the SEC in its "aiding and abetting" allegations against Garlinghouse and Larsen was denied. The court said it "is not clear whether Larsen and Garlinghouse knew or recklessly disregarded that securities laws, rather than laws under other regulatory regimes, applied to XRP."</p>
      <p>Garlinghouse reacted to the ruling on Twitter.</p>
      <p>"We said in Dec 2020 that we were on the right side of the law, and will be on the right side of history. Thankful to everyone who helped us get to today's decision – one that is for all crypto innovation in the US. More to come," Garlinghouse tweeted.</p>
      <p>However, the judgement also said that the institutional sale of the tokens did break federal securities laws as the buyers likely expected to profit off of their purchase.</p>
      <p>Ripple had sold nearly $730 million of XRP tokens directly to hedge funds and other institutional investors, and the company used the funds from the sale to develop its crypto ecosystem.</p>
      <p>Investors are no doubt excited about the court developments. Aside from XRP, crypto-related stocks like Coinbase and Riot Blockchain soared more than 10%, while ether jumped more than 5% and bitcoin jumped about 2%.</p>
    </div>
  },
  {
    id: 609,
    title: 'Digital assets report: Inflows rise even as crypto market stagnates',
    category: 'Crypto',
    subCategory: ['Digital', 'Assets', 'market'],
    description: <div className="blog-desc">
      <p>Inflows from digital asset investment products totaled $136 million last week, bringing the third consecutive week of inflows to $470 million, CoinShares found in a new report.</p>
    </div>,
    authorName: 'Abiodun Oladokun',
    authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 11, 2023 ', 
    cover: 'https://ncx.cx/images/ncx-blog-856.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
      <ul>
        <li>Leading coins BTC and ETH logged inflows last week.</li>
        <li>Short BTC and Short ETH, on the other hand, posted outflows.</li>
      </ul>
    	<p>Inflows from digital asset investment products totaled $136 million last week, bringing the third consecutive week of inflows to $470 million, CoinShares found in a new report.</p>
    	<p>According to the digital assets investment firm, the past three weeks of consecutive inflows successfully corrected the preceding nine weeks of outflows, which totaled $423 million. This brought the year-to-date flows to a net positive $231 million.</p>	
    	<p>Interestingly, despite the inflows logged last week, the period was marked by a decrease in trading turnover. CoinShares found:</p>	
    	<p><strong>“Trading turnover has slowed though, with investment products totaling US$1bn for the week compared to US$2.5bn average in the prior 2 weeks. These lower volumes may be due to the seasonal effects, where lower volumes are typically seen during July and August.”</strong></p>
      <h3>Bitcoin takes the front seat</h3>
      <p>For the third consecutive week, Bitcoin [BTC] remained “the focus amongst investors,” as the king coin logged inflows, totaling $133 million, last week. This accounted for 98% of the total inflows recorded during that period.</p>
      <p>Although BTC lingered within a narrow price range during that period, an 8% uptick was seen in inflows into digital assets last week.</p>
      <p>The additional $133 million in inflows brought the leading coin’s YTD net inflows to $290 million, with its assets under management (AuM) valued at $25 billion.</p>
      <p>This represented the second week of BTC logging a net inflow YTD, having been in a net outflow position of $171 million three weeks ago.</p>
      <p>On the other hand, short-Bitcoin investment products saw an outflow of $1.18 million last week, representing 11 weeks of consecutive outflows. However, despite this recent bearishness for short-Bitcoin, it remained the second best-performing asset in terms of inflows YTD at $58 million, data from the report showed.</p>
      <h3>Ethereum recorded inflows, but at what cost?</h3>
      <p>While leading altcoin Ethereum [ETH] posted inflows that totaled $2.9 million, CoinShares noted that the coin “has only marginally benefitted from improved investor sentiment.” The report stated:</p>
      <p><stong>“The last 3 weeks of inflows represent just 0.2% of total assets under management (AuM) compared to Bitcoin’s 1.9%, and remains in a negative net flows position year-to-date of US$63m.”</stong></p>
      <p>As for other altcoins, Solana [SOL], Ripple [XRP], Polygon [MATIC], and Litecoin [LTC] recorded inflows of $1.2 million, $900,000, $800,000, $500,000, respectively, while Cosmos [ATOM] and Cardano [ADA] saw minor outflows.</p>
    </div>
  },
  {
    id: 608,
    title: 'Cardano: Sinking development activity, price dips and more',
    category: 'Crypto',
    subCategory: ['Cardano', 'development', 'Price'],
    description: <div className="blog-desc">
      <p>Though Cardano’s development activity went down, its weekly report highlighted the efforts made by the developers in the last seven days.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 11, 2023 ', 
    cover: 'https://ncx.cx/images/ncx-blog-855.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Though Cardano’s development activity went down, its weekly report highlighted the efforts made by the developers in the last seven days.</p>
	    <ul>
        <li>Cardano’s total number of transactions exceeded 70 million, while native tokens reached 8.51 million.</li>
        <li>ADA was down by over 3% in the last 24 hours, but a key metric suggested a trend reversal.</li>
      </ul>
      <p>On-chain metrics revealed that Cardano [ADA] witnessed a substantial decline in development activity last week. However, Cardano’s weekly development report told a different story. While this happened, ADA’s price action continued to support the bears. However, a look at the derivatives market suggested that the scenario might soon turn in the bulls’ favor. </p>	
	    <h3>This is what Cardano’s last week looked like</h3>
      <p>As per Santiment’s chart, ADA’s development activity dropped considerably over the last week. This is a negative signal for a blockchain, as it means fewer efforts were made by developers to improve the network.</p>	
    	<p>However, Cardano’s weekly development report told a different story, as it mentioned the work done by all of its different teams last week along with updated network stats. As per the report, the Hydra team implemented end-to-end functionality for external commits, and tested it on the preview environment. The Mithril team created a new distribution pre-release, 2327.0-pre, and initiated testing of the new production signer deployment model with the pioneer SPOs.</p>
      <p>On the other hand, the Voltaire team continued conversations on CIP-1694 to establish an initial Minimum Viable Governance (MVG) framework. To clear the air, this framework is crucial for the advancement of participatory governance within the Cardano ecosystem.</p>
      <p>Apart from these developments, the weekly report also mentioned updated statistics on the Cardano blockchain. As per the report, the blockchain’s total number of transactions reached 70.6 million. Not only that, but the number of native tokens exceeded 8.5 million. It was also mentioned that to date, 134 projects have been launched on Cardano, while over 1,200 are being built on the blockchain.</p>
      <h3>ADA continues to struggle</h3>
      <p>Though the development seemed robust, it could not lend a helping hand to ADA’s price performance. According to CoinMarketCap, ADA was down by more than 1.4% and 3.5% in the last 24 hours and in the previous week, respectively. At the time of writing, ADA was trading at $0.2825 with a market capitalization of over $9.8 billion.</p>
      <p>Over the last week, ADA’s 1-week price volatility has also fallen sharply. Interestingly, despite the price decline, the token’s social dominance and transaction volume remained relatively high. ADA’s velocity also increased, meaning that ADA was used in transactions more often within a set time frame.</p>
      <p>Investors might have a reason to rejoice as a major metric gave hope for a price uptick in the coming days. As per Coinglass, ADA’s open interest declined sharply. A drop in the metric usually meant that the on-going price trend can come to an end soon.</p>
    </div>
  },
  {
    id: 607,
    title: 'Why SHIB could be skating on thin ice as Voyager reopens withdrawals',
    category: 'Crypto',
    subCategory: ['SHIB', 'Voyager', 'withdrawals'],
    description: <div className="blog-desc">
      <p>Huge amounts of funds get taken out by users as Voyager reactivates withdrawals on its network. Large amounts of cryptocurrencies still remain on the platform, with SHIB coming in fourth place after BTC, ETH, and USDC.</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 11, 2023 ', 
    cover: 'https://ncx.cx/images/ncx-blog-854.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Huge amounts of funds get taken out by users as Voyager reactivates withdrawals on its network. Large amounts of cryptocurrencies still remain on the platform, with SHIB coming in fourth place after BTC, ETH, and USDC.</p>
    	<ul>
        <li>Large amounts of withdrawals take place as Voyager activates them on its network.</li>
        <li>After BTC, ETH, and USDC, SHIB becomes the largest cryptocurrency on the protocol.</li>
      </ul>
      <p>Voyager, a centralized exchange that failed due to the collapse of Luna and Terra left many of its depositors and users stranded. However, on 23 June, Voyager reopened withdrawals of various cryptocurrencies on its platform.</p>	
    	<h3>On a voyage here…</h3>
      <p>According to data provided by Dune Analytics, over $229 million has already been transferred out from the platform. At press time, Voyager’s tagged wallets still held a substantial amount of $196 million.</p>	
    	<p>These wallets contained holdings that included 74 million BTC, $52 million worth of ETH, $32 million in USDC, $15 million of $SHIB, and 7 million of VGX.</p>
      <p>The access gained by Voyager addresses could have varied impacts on various cryptocurrencies. Users may be more keen on HODLing blue chip coins such as ETH and BTC as sentiment and prices of both these coins have been on the rise over the last few months.</p>
      <p>However, one may also need to consider the possibility that memecoins like SHIB may not face a smiliar intact. Memecoins are known for their highly volatile behavior and predicting the future of their price may be difficult. Users who have just gained access to their holdings may want to avoid risky holdings such as SHIB and may try to sell and invest their holdings elsewhere.</p>
      <p>Other popular memecoins such as PEPE that took the crypto market by storm could also look appealing to these holders who may want to diversify their memecoin portfolio.</p>
      <h3>Word on the street</h3>
      <p>At press time,  it was observed that SHIB’s social mentions and engagements had declined significantly over the past week. According to LunarCrush’s data, the number of social mentions and engagements for SHIBA fell by 78% and 49% respectively.</p>
      <p>Additionally, the sentiment around SHIB over the last three months had also turned significantly negative. In tandem with its falling sentiment, SHIBA’s price also dropped and traded at a press time price of $0.0000075.</p>
    </div>
  },
  {
    id: 606,
    title: 'Bitcoin steady above US$30,000, all top 10 cryptos rise on market optimism',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Cryptos', 'Market'],
    description: <div className="blog-desc">
      <p>Bitcoin prices gained in Tuesday afternoon trade in Asia to remain above US$30,000. Ether and all other top 10 non-stablecoin cryptocurrencies strengthened in the past 24 hours on market expectations of a rally in Bitcoin</p>
    </div>,
    authorName: 'Pradipta Mukherjee',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 11, 2023 - 1:56 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-853.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin prices gained in Tuesday afternoon trade in Asia to remain above US$30,000. Ether and all other top 10 non-stablecoin cryptocurrencies strengthened in the past 24 hours on market expectations of a rally in Bitcoin prices by end of this year. Polygon, Solana and BNB were the biggest gainers.</p>
    	<h3>Bitcoin rises past US$31,000 before slipping</h3>
      <p>Bitcoin rose 1.42% to US$30,540 in 24 hours to 4 p.m. in Hong Kong, but has lost 1.19% over the past seven days, according to CoinMarketCap data. The world’s biggest cryptocurrency by market capitalization fell after rising as high as US$31,026 in the last 24 hours. In the same period, Bitcoin’s market capitalization has risen 1.48% to about US$593 billion.</p>	
    	<p>Bitcoin prices gained after Standard Chartered bank said the token is likely to reach US$50,000 by the end of 2023 and US$120,000 at the end of 2024, according to Reuters on Tuesday.</p>	
    	<p>Optimism in Bitcoin also strengthened after several traditional finance (TradFi) companies, including BlackRock, WisdomTree, Invesco and Bitwise, filed Bitcoin exchange-traded fund (ETF) applications in the U.S. last month, indicating faith in the token and in the digital assets sector.</p>
      <p>“Going by the historical price patterns of Bitcoin, the bull run could come in 2025 or late 2024 after the next halving in 2024,” Rajagopal Menon, Vice President of WazirX, India’s largest crypto exchange by volume, told Forkast in an emailed response.</p>
      <p>“TradFi institutions have entered crypto in large numbers and even though they haven’t improved spot trading volume on exchanges, futures and options trading has seen an uptick,” Menon added.</p>
      <p>According to European cryptocurrency investment firm CoinShares, cryptocurrency-linked investment products saw net inflows of US$136 million in the week ending July 7, marking the third consecutive week of inflows and fully correcting the prior nine weeks of outflows.</p>
      <p>Ether, the second-biggest cryptocurrency, gained 1.45% to US$1,883, but lost 3.41% on the week.</p>
      <p>“It really was about as bad as it could’ve been for the crypto market last year. And 2023 has, so, far been characterized by the U.S. Securities and Exchange Commission ramping up oversight in the digital asset space,” Nigel Green, chief executive of financial advisory firm deVere Group, said in an emailed statement on Tuesday.</p>
      <p>“The fact, then, Bitcoin has gained 80% already in 2023, putting it on track for its best annual performance since 2020, and that Ethereum prices are also up 52% so far this year, is truly impressive,” Green added.</p>
      <p>Polygon’s Matic token gained the most among the top 10 non-stablecoin cryptos, rising 9.43% to US$0.742 in the last 24 hours, bringing its weekly gains to 5.11%. The higher prices come as Polygon Labs announced its Polygon 2.0 roadmap on June 30, vowing to become the “Value Layer of the Internet.”</p>
      <p>Solana and BNB also led gains, both rising over 6% in the last 24 hours. Solana rose 6.8% to US$22.11, and 15.66% on the week.</p>
      <p>BNB, the native token of world’s largest crypto exchange Binance, added 6.22% to US$247.20, and 0.83% in the last seven days.</p>
      <p>The total crypto market capitalization strengthened 1.64% to US$1.19 trillion, while crypto market volume gained 44.7% to US$33.02 billion in the past 24 hours.</p>
      <h3>NFT volumes drop on Ether, Bitcoin</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index dropped 0.37% to 2,735.75 in 24 hours to 6 p.m. in Hong Kong, after losing 0.49% in the past seven days.</p>
      <p>In the same period, the Forkast ETH NFT Composite lost 0.09% to 930.53, bringing its weekly losses to 0.98%.</p>
      <p>Total sales volume on Ethereum dropped 1.18% to US$15.45 million, while that on the Bitcoin network fell 36.63% to US$4.53 million, according to CryptoSlam data.</p>
      <p>“Traders seem to be out of liquidity and most are accepting the idea that the NFT market will continue to decline,” said Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News.</p>
      <p>By collection, trading volume in Ethereum-based Bored Ape Yacht Club (BAYC) NFT collection topped the list, edging up 5.41% to US$1.91 million in the past 24 hours. The Bitcoin-based $FRAM BRC-20 NFTs and uncategorized Ordinals inscriptions ranked the second and the third.</p>
      <p>Among use cases for NFTs, a new loan was taken out on a luxury watch on Monday. A lender gave out a US$35,000 loan at 12% interest rate using a Patek Phillipe as collateral.</p>
      <p>“For those asking ‘why does this need an NFT?’ It’s because it gives access to more liquidity across the globe,” Petscher explained.</p>
      <p>Elsewhere, SuperRare, an NFT marketplace for digital artworks, has announced an exhibition  – A Digital Transcendence – The Intersection of Art and Tech – to be held from July 13. The exhibition will host work of digital artists Botto, Camibus, Emily Xie, Jack Kaido, Matt Kane, Osinachi, and William Mapen x Christiane Lemieux.</p>
      <p>Founded in 2018, SuperRare NFT marketplace has clocked over US$300 million in total sales while artists have earned over US$180 million to date, the company said in an emailed statement on Monday.</p>
      <h3>U.S. stock futures, Asian, European equities rise</h3>
      <p>All Asian equity markets strengthened on Tuesday on hopes for a fresh round of stimulus in China. The People’s Bank of China and National Financial Regulatory Administration said in a joint statement on Monday that the nation would take measures to improve the ailing property market. These measures include encouraging financial institutions to extend property firms’ outstanding loans through negotiations.</p>
      <p>The Shanghai Composite, Shenzhen Component, Hong Kong’s Hang Seng Index and Japan’s Nikkei 225 strengthened by the end of the trading day on Tuesday.</p>
      <p>U.S. stock futures increased as of 7 p.m. in Hong Kong, with the Dow Jones Industrial Average futures, the S&P 500 futures, and the Nasdaq 100 Futures gaining.</p>
      <p>Investors are looking forward to the latest U.S. consumer price index data, scheduled for release on Wednesday, as well as the June producer price index due on Thursday. The indicators will point to the current inflation situation in the world’s biggest economy as well as provide insight on what could be the Federal Reserve’s future direction on interest rates.</p>
      <p>The U.S. central bank will meet on July 26 to decide on its next move on interest rates, which are currently between 5% and 5.25%.</p>
      <p>PepsiCo, Delta Air, JPMorgan Chase, Wells Fargo, Citigroup, and BlackRock are set to release second quarter earnings later this week.</p>
      <p>European bourses gained on Tuesday on hopes the Federal Reserve is coming to the end of its interest rate hiking cycle. The benchmark STOXX 600 and Germany’s DAX 40 strengthened during Tuesday afternoon trading hours in Europe.</p>
      <p>The U.K. announced on Tuesday that its unemployment rate rose to 4% in the three months to May, while the economy struggles with persistent inflation.</p>
      <p>The U.K. Minister for Employment, Guy Opperman, told FXStreet, “It’s encouraging to see inactivity falling, vacancies dropping, and employment on the up. To get prices down and help make mortgages manageable, we must halve inflation and grow our economy.”</p>
    </div>
  },
  {
    id: 605,
    title: 'Why Ethereum saw 580,000 new addresses in one week',
    category: 'Crypto',
    subCategory: ['Ethereum', 'ETH', 'Crypto'],
    description: <div className="blog-desc">
      <p>Ethereum’s groundbreaking merge introduced staking, but the quiet surge in new addresses and declining ETH on exchanges hint at a promising and confident future.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 8, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-827.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Ethereum’s groundbreaking merge introduced staking, but the quiet surge in new addresses and declining ETH on exchanges hint at a promising and confident future.</p>
    	<ul>
        <li>Ethereum has seen over 580,000 new addresses in the past week.</li>
        <li>ETH price recovered with less than a 1% increase in price.</li>
      </ul>
      <p>The Ethereum [ETH] merge revolutionized the platform by introducing staking, sparking intense discussions throughout the chain. While this groundbreaking event garnered much attention, there’s another metric steadily ascending in the background, silently shaping the future trajectory of ETH with great potential impact.</p>	
    	<h3>Ethereum new addresses surge</h3>
      <p>Amidst the focus on Ethereum’s staking and price trends, it’s easy to overlook other crucial metrics. However, recent Santiment data sheds light on a quietly surging aspect: the network’s remarkable growth.</p>	
    	<p>The data indicated a consistent influx of new users joining the network, signaling potential market cap expansion.</p>
      <p>A closer examination of the chart revealed that over 580,000 new addresses were created between 1 July and 7 July alone. This remarkable surge further highlighted the underlying strength and promising trajectory of ETH.</p>
      <h3>ETH on exchanges decline</h3>
      <p>According to the latest data from Coin Market Cap, Ethereum held an impressive 19% of the total cryptocurrency market cap. With this, it secured its position as the second-largest cryptocurrency, surpassed only by Bitcoin [BTC], which claimed over 49%. Despite ETH’s market dominance, ETH holders actively withdrew their holdings from exchanges.</p>
      <p>Santiment’s chart revealed that the supply of Ethereum on exchanges, relative to the total supply, stood at a mere 9.1%. This suggested that less than 10% of all ETH remained on exchanges, while the majority had been moved to other storage methods.</p>
      <p>Further analysis of the chart revealed that approximately 11.3 million ETH was being held on exchanges at press time, indicating a visible decline. This withdrawal trend underscored the growing preference among Ethereum holders for alternative storage solutions.</p>
      <p>Also, it reflected their confidence in the long-term value of their holdings.</p>
      <h3>Ethereum price declines as TVL dominates</h3>
      <p>According to data from DefiLlama, Ethereum maintained its position as the leading blockchain in terms of Total Value Locked (TVL), compared to other chains. As of this writing, the total TVL across all chains was $44 billion, with Ethereum commanding over $26 billion.</p>
      <p>This meant that Ethereum held more than half of the entire TVL in the market, showcasing its dominant presence in decentralized finance.</p>
      <p>However, ETH has experienced a recent decline. Nevertheless, there were signs of a potential recovery as of this writing. Ethereum was trading at approximately $1,864, showing a modest increase of almost 1% from its previous value.</p>
    </div>
  },
  {
    id: 604,
    title: 'Litecoin overtakes Bitcoin: Is there a new sheriff in town?',
    category: 'Crypto',
    subCategory: ['Litecoin', 'Bitcoin', 'crypto'],
    description: <div className="blog-desc">
      <p>Litecoin surpassed Bitcoin to emerge as the most used digital asset for payments in the month of June.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 8, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-826.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Litecoin surpassed Bitcoin to emerge as the most used digital asset for payments in the month of June.</p>
      <ul>
        <li>LTC’s share of the global payments surged to 34.9% in June, up from 28.78% in May.</li>
        <li>Riding on the positive sentiment, LTC stormed to a 14-month high earlier in the week.</li>
      </ul>
    	<p>In a massive boost for the Litecoin [LTC] ecosystem, LTC became the most used crypto for payments for the first time in June, outperforming all major assets, including Bitcoin [BTC].</p>	
    	<p>As tracked by the world’s largest payment processor BitPay, LTC’s share of the global payments surged to 34.9% in June, a sharp uptick from 28.78% during May. In the process, the “Silver” marginally outpaced the “Gold,” as Bitcoin’s share dropped from 36.39% to 34.06%.</p>
      <p>Reacting to this significant development, the creator of Litecoin, Charlie Lee, remarked,</p>
      <p><strong>“Tbh, I wasn’t sure we’d see this day, but it happened! Litecoin payments on BitPay surpassed even Bitcoin.”</strong></p>	
      <h3>LTC flexing muscles</h3>
    	<p>LTC has been building strong momentum as its widely anticipated halving event is just round the corner. It was one of the top gainers in the last month among assets, with a market valuation of more than $2 billion. Riding on the positive sentiment, the coin stormed to a 14-month high of $113 earlier in the week, as per CoinMarketCap.</p>
      <p>It was worth noting that LTC’s total transaction count increased significantly in June, reaching levels comparable to Bitcoin’s daily tally in several cases, as per Glassnode.</p>
      <p>Litecoin’s demand for facilitating transactions has grown sharply since May when users who were bogged down by huge transaction fees on Bitcoin chose to shift towards less expensive alternatives.</p>
      <h3>Big addresses offload en masse</h3>
      <p>LTC whales succumbed to the urge to lock profits and sold a big chunk of their holdings. According to Santiment, the number of addresses holding between 1,000-10,000 coins, categorized as small whales, dropped significantly over the past week.</p>
      <p>A similar case was encountered for the cohort holding between 100,000-1 million BTCs.</p>
      <p>This was also demonstrated by the drop-offs in the Mean Coin Age indicator. Thus, there was increased movement of LTC between addresses.</p>
      <p>However, with the upcoming halving event less than a month away, whales would be looking to rethink their marketing strategies. Historically, LTC prices have soared both before and in the aftermath of the halving.</p>
      <p>Hence, it won’t be surprising if these addresses start piling up LTC coins in the run-up to the event.</p>
    </div>
  },
  {
    id: 603,
    title: 'Cosmos’ Interchain embraces this token’s issuance through KAVA',
    category: 'Crypto',
    subCategory: ['Cosmos', 'Interchain', 'KAVA'],
    description: <div className="blog-desc">
      <p>Kava blockchain will facilitate USDT’s launch on interchain. This development will not only make it easier for DeFi users to transfer funds from one chain to another, but also make it more cost efficient.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 8, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-825.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Kava blockchain will facilitate USDT’s launch on interchain. This development will not only make it easier for DeFi users to transfer funds from one chain to another, but also make it more cost efficient.</p>
    	<ul>
        <li>Cosmos enlists Kava blockchain for USDT’s rollout on interchain.</li>
        <li>USDT interchain rollout fails to yield a significant impact on KAVA.</li>
      </ul>
      <p>Cosmos [ATOM] is one of the blockchain networks that have held strong to the idea of an internet of blockchains. However, that vision has been met with challenges regarding interconnectedness. The network’s latest announcement underscores a move aimed at overcoming one of those challenges.</p>
      <p>Cross-chain transfers usually require bridging and that can involve expensive swaps. But what if DeFi users could transfer one type of asset from one network to another without requiring swaps?</p>
      <p>That is what Cosmos aims to achieve with the latest development. The network just announced that USDT is now available on Interchain.</p>
      <p>According to the announcement, the Kava blockchain will facilitate USDT’s launch on interchain. This development will not only make it easier for DeFi users to transfer funds from one chain to another but also make it more cost-efficient.</p>
      <p>What does this mean for USDT? From a utility point of view, having USDT easily available and transferable in the interchain ecosystem might have some advantages. For example, it translates to easier access to liquidity on more DeFi platforms. Users may also find it easier to use DeFi with native USDT easily transferable within the interchain ecosystem.</p>	
    	<h3>Assessing KAVA’s performance</h3>
      <p>As noted earlier, the Kava blockhain which is a Cosmos SDK chain will facilitate the USDT rollout on the interchain. But what does this mean for the Kava network and its native cryptocurrency? Perhaps this major development may unlock more visibility for the network.</p>	
    	<p>So far Kava has seen a significant surge in market cap in the last 30 days. Kava’s marketcap bottomed out at slightly over $421 million in mid-June but peaked above $704 million on 25 June. It hovered around $578.7 million in the last 24 hours.</p>
      <p>Kava’s volume also achieved its highest level in the last four weeks on 25 June. It has since receded back to its monthly lows. One would think that Kava would register strong development activity considering the network’s central role in the USDT’s rollout on interchain. However, that has not been the case since the development activity has been declining for the last four weeks.</p>
      <p>But what about KAVA’s price action? It has so far been one of the most volatile cryptocurrencies. This is evident by the up-and-down movement delivered in the last few months. One KAVA token traded for $0.93 at press time which represented a healthy premium from its lowest price point on a YTD basis.</p>
    </div>
  },
  {
    id: 602,
    title: 'LUNC faces declining volume, sloppy price action: What’s next?',
    category: 'Crypto',
    subCategory: ['LUNC', 'Declining', 'Sloopy price'],
    description: <div className="blog-desc">
      <p>Luna Classic dipped below another key support level, as all-time low trend continued.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 8, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-824.jpg',
    content: <div className="blog-content">
      <p>Disclaimer: The information presented does not constitute financial, investment, trading, or other types of advice and is solely the writer’s opinion.</p>
    	<h3>In this article:</h3>
    	<p>Luna Classic dipped below another key support level, as all-time low trend continued.</p>
      <ul>
        <li>Luna Classic continued to register a series of all-time lows.</li>
        <li>Declining Spot CVD highlighted weakening demand.</li>
      </ul>
    	<p>Luna Classic’s [LUNC] weakening support levels have seen prices continue to sink to all-time lows (ATLs). With bulls unable to stem the bearish slide, Luna Classic dipped below the $0.00008115 support level as of press time.</p>
      <p>With LUNC’s price action going contrary to the bullish sentiment in the market, the selling pressure could persist for the foreseeable future.</p>
      <h3>Can Luna Classic halt the trend of ATLs?</h3>	
    	<p>Luna Classic has been making a series of all-time lows (ATLs) throughout June. After price rallied briefly on 4 June, the rejection at the $0.0001152 resistance led to LUNC hitting its early-May low again.</p>	
      <p>A look at the Visible Range Volume Profile (VRVP) highlighted $0.0001234 (Value Area High), $0.0000900 (Point of Control), and $0.00008020 (Value Area Low) as key price action levels.</p>
      <p>With price dipping below the POC on 28 June, the VAL, along with the support level at $0.00008115, has propped up LUNC in the short term. However, a continuation of the selling pressure could also see these levels cave in.</p>
      <p>The On Balance Volume (OBV) highlighted the loss of trading volume, with a sharp decline since early June. The Relative Strength Indicator (RSI) also remained firmly under the neutral 50 and edged closer to the oversold zone.</p>
    	<p>A bullish reversal for Luna Classic will hinge on an uptick in its trading volume. Else, the bearish momentum could last for the mid to long term.</p>
      <h3>Steep decline of Spot CVD showed weakening demand</h3>
      <p>A look at the Open Interest (OI) from Coinalyze on the 12-hour timeframe showed a considerable decline on 5 June and 14 June. It showed the hesitation by market speculators to open new positions, due to the ever-increasing selling pressure.</p>
      <p>Similarly, the Spot CVD, which tracks trading volumes over time, maintained a steep decline. This hinted that the selling volume far outweighed the buying one, highlighting the lack of demand for LUNC in the futures market.</p>
    </div>
  },
  {
    id: 601,
    title: 'Avalanche from the lens of subnets: AVAX traders should know that…',
    category: 'Crypto',
    subCategory: ['Avalanche', 'AVAX', 'Traders'],
    description: <div className="blog-desc">
      <p>Avalanche transactions fell sharply in 2022 but have been recovering on a year to date basis. They have particularly been on the rise in the last 4 months.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 7, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-829.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Avalanche transactions fell sharply in 2022 but have been recovering on a year to date basis. They have particularly been on the rise in the last 4 months.</p>
    	<ul>
          <li>Avalanche charges through the 500 million transaction milestone.</li>
          <li>Avalanche subnets have been a critical aid to this achievement.</li>
      </ul>
      <p>Avalanche recently celebrated a significant transaction milestone. But perhaps what was even more interesting was the reason behind this transaction growth and what it means for the future of the network.</p>	
    	<p>Avalanche subnets have been pivotal in securing the network’s growth and offering an advantage over its competition. According to recent data, Avalanche has so far achieved over 500 million transactions.</p>	
    	<p>Subnets played an important role in achieving this milestone. This is because of the advantages they provide, such as security, scalability, and fast speeds.</p>
      <p>While subnets have been pivotal to Avalanche’s growth, they are subject to risks, especially those associated with smart contracts. The network has been looking into overcoming such challenges and a recent announcement highlighted its efforts.</p>
      <p>Avalanche was currently exploring verified smart contracts as a solution. These will reportedly offer more security since they will go through comprehensive verification tests and checks to assess potential vulnerabilities. The verified smart contracts could further boost user confidence and support more utility and transactions.</p>
      <p>Avalanche transactions fell sharply in 2022 but have been recovering on a year-to-date basis. They have particularly been on the rise in the last four months. Avalanche transaction count is yet to recover to 2021 and 2022 highs despite the recent growth.</p>
      <h3>Will the growing Avalanche transactions support AVAX recovery?</h3>
      <p>Avalanche’s native cryptocurrency AVAX traded at $12.67 at press time. This price tag represented a 27% upside from its lowest price point in June. However, it is still near the bottom range but recently ran out of bullish momentum.</p>
      <p>AVAX’s Money Flow Index (MFI) indicator revealed that the cryptocurrency has been experiencing some outflows since the start of July. On-chain data revealed a dip in both development activity and social dominance.</p>
      <p>These observations suggested that we may see more price weakness in the coming weeks unless market confidence makes a comeback.</p>
      <p>However, Avalanche’s development activity metric maintained healthy activity in June. A dip in the last week of the month followed by a rapid recovery was observed. However, the development activity recently dipped again at the start of July, possibly contributing to lower confidence.</p>
      <p>AVAX’s price action has mostly been moving in tandem with the rest of the crypto market due to a strong correlation. This means that reports of subnets contributing to growth may not necessarily have an impact on trader sentiment in the short term.</p>
    </div>
  },
  {
    id: 600,
    title: 'Maker [MKR] delivers a 50% weekly upside fueled by these events',
    category: 'Crypto',
    subCategory: ['MKR', 'Maker', 'Crypto'],
    description: <div className="blog-desc">
      <p>MKR could be considered as one of the best performing cryptocurrencies especially considering its performance in the last seven days. Additionally, whales were seen buying the token too, which negated any chances of a price correction in the near future.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 7, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-828.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>MKR could be considered as one of the best performing cryptocurrencies especially considering its performance in the last seven days. Additionally, whales were seen buying the token too, which negated any chances of a price correction in the near future.</p>
    	<ul>
        <li>Spark protocol coming to Gnosis chain fuels positive sentiment among MKR holders.</li>
        <li>MKR bulls demonstrate strong momentum and resilience but will sell pressure be imminent?</li>
      </ul>
      <p>Maker DAO’s native token MKR was seen making it to the headlines after rallying by more than 50% in the last seven days. This makes it one of the best-performing cryptocurrencies during the aforementioned period.</p>	
    	<p>MKR owes this performance to a series of favorable events that took place in the last few days. Among those events include the expansion of the Spark protocol to the Gnosis chain. A move that could lead to more utility and demand for the DAI stablecoin. Key benefits include Gnosis chain’s high level of decentralization and lower transaction costs.</p>	
    	<p>MKR also owes its robust bullish performance to other factors such as MKR repurchase contract deployment and fee capture growth. But just how impressive was its growth? Well, a look at its price action revealed that it traded as low as $870 in the last seven days. It bounced off its 200-day moving average and soared as high as $1080.</p>
      <h3>Will MKR lend itself to the mercy of the bears?</h3>
      <p>While we may not know for sure whether MKR will continue rallying or give up recent gains, some metrics offered useful insights. For example, its on-chain volume just peaked at a new monthly high in the last 24 hours. Perhaps a sign that retail buyers have been buying near the latest highs.</p>
      <p>One of the main reasons why the volume observed is important is because of exit liquidity. Maker’s network growth also peaked at a new monthly high on 3 July. However, it has since dropped and was seen approaching the lower monthly range.</p>
      <p>While the network growth may look bearish, it is worth noting that MKR is so far holding on to most of its recent gains. This is partly because the whales have also been buying. The latest data on the supply held by top addresses revealed a slight uptick.</p>
      <p>The supply held by top addresses confirmed that whales were still buying at higher price levels. A sign that the short-term bullish outlook remains strong. It could also mean that strong sell pressure wasn’t anticipated. As such, the MVRV ratio pivoted back up.</p>
      <p>MKR’s ability to resist the downside will largely depend on whether investors will be incentivized to hodl. The easiest way that can happen is if the crypto market continues with its bullish bias.</p>
    </div>
  },
  {
    id: 599,
    title: 'ETH derivatives demand suggests this about its performance in Q3 and Q4',
    category: 'Crypto',
    subCategory: ['ETH', 'Demand', 'Crypto'],
    description: <div className="blog-desc">
      <p>According to a recent CCData report, both spot and derivatives demand on centralized exchanges surged by 14.2% to $2.71 trillion. The derivatives segment contributed most of that demand at $2.13 trillion which is equivalent to a 13.7% upside.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 6, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-818.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
      <ul>
        <li>Ethereum demand in the derivatives segment outweighed spot demand in June.</li>
        <li>ETH’s funding rate also witnessed a rise, especially in the last week of June.</li>
      </ul>
    	<p>According to a recent CCData report, both spot and derivatives demand on centralized exchanges surged by 14.2% to $2.71 trillion. The derivatives segment contributed most of that demand at $2.13 trillion which is equivalent to a 13.7% upside.</p>
    	<p>Recent data analyzing the state of demand for crypto reveals that the derivatives segment grew significantly in June. Ethereum [ETH] was among the cryptocurrencies that have been tapping into that demand.</p>	
    	<p>June turned out to be quite an interesting month not only for ETH but the crypto market in general. This is because there was a significant surge in demand during the month.</p>	
    	<p>According to a recent CCData report, both spot and derivatives demand on centralized exchanges surged by 14.2% to $2.71 trillion. The derivatives segment contributed most of that demand at $2.13 trillion which is equivalent to a 13.7% upside.</p>
      <p>ETH was one of the cryptocurrencies that benefited from the surge in derivatives demand. For perspective, this was reflected in its open interest metric which registered a significant upside from its lowest point in the last 2 months (in May). It recently peaked at a new high at the start of July, confirming strong activity in the derivatives market.</p>
      <p>Ethereum’s funding rate has also been on the rise, especially in the last week of June. This further confirmed the influx of liquidity into ETH derivatives. But why has derivatives demand been higher than spot demand? Perhaps one of the reasons was the ease of investing in the derivatives segment. But one of the major reasons could also be that it offers leverage opportunities.</p>
      <h3>Is the prevailing leverage enough for a substantial impact?</h3>
      <p>The level of confidence in the market tends to impact the demand for leverage. As such, the last week of June attracted a surge in the demand for leverage as many traders anticipated higher prices.</p>
      <p>Higher leverage often confirms some directional confidence in the market. However, it also lends the underlying asset to potential liquidations which may trigger a pivot.</p>
      <p>ETH’s latest upside failed to rally back above the $2,000 price range despite an attempt. It exchanged hands at $1,913 at press time. Furthermore, the level of liquidations surged slightly in the last two days.</p>
      <p>ETH long liquidations peaked at $8.44 million in the last 24 hours compared to $522,000 short liquidations. However, these liquidations are too low to have an impact on the price.</p>
      <p>Nevertheless, the observed growth in derivatives demand and appetite for leverage is already a healthy sign. It suggests that the market recovery that was seen in the first half of 2023 might continue into the second half of the year.</p>
    </div>
  },
  {
    id: 598,
    title: 'Bitcoin ETF could democratize crypto: BlackRock CEO',
    category: 'Crypto',
    subCategory: ['BTC', 'ETF', 'BlackRock'],
    description: <div className="blog-desc">
      <p>BlackRock CEO Larry Fink believes that Bitcoin is an international asset. The asset management company recently filed for a Bitcoin ETF.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 6, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-817.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
      <ul>
        <li>Larry Fink is of the belief that BTC ETFs could make crypto democratized.</li>
        <li>Spot ETFs have led to a rise in BTC’s prices.</li>
      </ul>
	    <p>BlackRock CEO Larry Fink believes that Bitcoin is an international asset. The asset management company recently filed for a Bitcoin ETF.</p>
	    <p>BlackRock CEO Larry Fink says that its spot Bitcoin [BTC] exchange-traded fund (ETFs) will make crypto more democratized. He said that the role of cryptocurrency was largely “digitizing gold.”</p>
      <p>Fink made these remarks while speaking on Fox Business on 5 July.</p>
	    <p>The BlackRock CEO suggested that U.S. regulators should consider how an ETF directly linked to BTC could democratize finance. He believed that Bitcoin is an international asset, saying:</p>	
    	<p><strong>“It’s not based on any one currency, and so it can represent an asset that people can play as an alternative.”</strong></p>
      <p>However, Gabor Gurbacs, Director, Digital Assets Strategy of VanEck, was quick to remind the Twitter community about Fink’s earlier views regarding Bitcoin. Back in 2017, Fink had referred to Bitcoin as an “index of money laundering.”</p>
      <p>Microstrategy founder Michael Saylor approvingly quoted Link’s recent remarks on Twitter.</p>
      <p>Fink also proposed that investors may use Bitcoin as a hedge against inflation or currency depreciation.</p>
      <p>The CEO had earlier batted for the decentralized nature of the crypto industry. When FTX collapsed in November 2022, he reasoned that FTX failed because it created its native centralized token. This, according to him, was in complete contrast with the “whole foundation of what crypto is.”</p>
      <p>He had made these remarks at the New York Times’ 2022 Dealbook Summit.</p>
      <h3>Will the SEC approve BlackRock’s Bitcoin ETF application?</h3>
      <p>Under Fink’s leadership, BlackRock tried to begin a spot Bitcoin ETF with cryptocurrency exchange Coinbase acting as a surveillance partner. We do not know if the U.S. Securities and Exchange Commission (SEC) will approve it as of the time of writing, given that the regulatory body has rejected all previously filed applications so far.</p>
      <p>BlackRock is the world’s leading asset management company. It had more than $9 trillion in assets under management, Bloomberg reported in April.</p>
      <p>BlackRock and other asset management firms filing for spot Bitcoin ETFs have led to a BTC price rally. At press time, BTC was trading above $31K, reflecting a modest rise of 1.5% in the previous 24 hours.</p>
    </div>
  },
  {
    id: 597,
    title: 'Bitcoin trades flat after losing US$31,000 peg; Solana gains; Polygon, BNB lead losers',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Solana', 'Polygon'],
    description: <div className="blog-desc">
      <p>Bitcoin and Ether traded little changed on Wednesday morning in Asia, while the rest of the top 10 non-stablecoin cryptocurrencies were mixed. Bitcoin fell back under US$31,000,</p>
    </div>,
    authorName: 'Danny Park',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 5, 2023 - 5:03 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-816.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin and Ether traded little changed on Wednesday morning in Asia, while the rest of the top 10 non-stablecoin cryptocurrencies were mixed. Bitcoin fell back under US$31,000, but its dominance in total crypto market cap is gaining, a sign of market recovery.</p>
    	<p>Solana added to its gains this week, while Polygon’s Matic and the BNB tokens led the daily losers list. Bitcoin Cash has taken the number 12 spot in the list of top tokens by market cap after its listing on the new EDX Markets exchange.</p>	
    	<h3>Crypto price pause</h3>
      <p>Bitcoin fell 1.0% over the last 24 hours to US$30,801 at 07:20 a.m. in Hong Kong, shaving its weekly gain down to 0.39%, according to data from CoinMarketCap. On Tuesday, the price of the world’s largest cryptocurrency reached US$31,371, its highest since June 2022, on the back of optimism about spot Bitcoin exchange-traded fund applications by a raft of Wall Street heavyweights, including Blackrock.</p>
      <p>The level of Bitcoin dominance sits near 50%, which is the highest since April 2021. Alex Kuptsikevich, senior market analyst at FxPro, says this price uptrend in Bitcoin is a typical market recovery pattern in which the most significant asset in the sector attracts buyers first.</p>
    	<p>“It will probably take about a year of Bitcoin price recovery before crypto enthusiasts start looking for one with higher risk, pushing altcoins higher and leading to a new wave of capitalisation swell,” said Kuptsikevich, in an email statement. He said he doesn’t see altcoins rallying before November.</p>
      <p>Bitcoin’s recent price recovery was sparked by Blackrock, the world’s biggest asset manager, filing an application last month to launch a Bitcoin ETF and refiling it again after the U.S. Securities and Exchange Commission (SEC) indicated it lacked details.</p>
      <p>Several other U.S.-based asset managers, such as Fidelity Investments and WisdomTree, have filed their own Bitcoin ETFs, suggesting mainstream finance recognizes the Bitcoin opportunity and wants to offer clients the access to its price movements without actually owning the token.</p>
      <p>Ether, the second-largest cryptocurrency by market cap, looked to be on a run to break US$2,000 this week, but fell back 0.81% to US$1,941 today after a gain of 2.64% for the past seven days.</p>
      <p>The other top 10 cryptocurrencies by market capitalization traded mixed to flat, with Solana leading gainers with a 2.0% rise to US$19.45. The token has surged 15.35% in the past week.</p>
      <p>Polygon’s Matic and Binance’s BNB token were amongst the losers — Matic lost 1.99% to US$0.6981, though it’s still up 4.29% for the week. BNB lost 1.68% to US$242.83, cutting its weekly gain to 1.81%.</p>
      <p>Altcoins have suffered from the SEC’s crackdown on tokens that they deem financial securities. Solana, Cardano, Polygon and BNB were among those that the regulator named as unregistered securities in its lawsuits filed against the Coinbase and Binance.US exchanges in early June.</p>
      <p>While these altcoins have partially recovered after an initial plunge following the SEC suits, several trading platforms including California-based Robinhood Markets Inc., Israel-based eToro and UK’s Revolut ceased support or limited access to some of these tokens for their U.S.-based customers.</p>
      <p>Bitcoin Cash climbed to become the twelfth largest non-stablecoin cryptocurrency by market capitalization on CoinMarketCap at US$53.8 billion. The Bitcoin spin-off is one of the four cryptocurrencies listed on EDX Markets, the Wall Street-backed, compliance-focused crypto exchange that launched on June 20 in the U.S.</p>
      <p>Bitcoin Cash had a market capitalization of US$20.6 billion and was valued at US$106.34 on June 19. It has since surged 160% to US$276.87. It dipped 1.70% in the last 24 hours.</p>
      <p>The total cryptocurrency market cap fell 0.80% to US$1.21 trillion in the last 24 hours, while crypto trading volume fell 22.22% to US$31.14 billion, according to CoinMarketCap data.</p>
      <h3>Azuki dents NFT sentiment</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>The Forkast 500 NFT index rose 1.28% in the 24 hours to 07:45 a.m. in Hong Kong to 2,783.58, but logged a loss of 3.17% for the week. The two Forkast indexes measuring the performance of Ethereum and Polygon NFT markets rose.</p>
      <p>However, despite moderate gains in the indexes on Wednesday morning, the NFT market overall remains in a downtrend, said Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News.</p>
      <p>“The Forkast 500 NFT Index reflects declining sales prices across collections on all chains, but it’s Ethereum that’s really dragging the market down with it,” said Petscher, pointing to the recent problems with the Azuki NFT collection.</p>
      <p>“Azuki’s struggles exasperated the already struggling market … Azuki’s new Elementals mint was supposed to be a boom for NFTs and instead was a bust, becoming a catalyst that drove NFT collections down to all-time lows.”</p>
      <p>Azuki NFT creator Chiru Labs debuted its latest Elementals collection last Tuesday, to much negative response from the community, who deemed it almost identical to the original Azuki collection.</p>
      <p>While the team behind Azuki posted an public apology to buyers on Twitter, dissatisfied Azuki holders formed the Azuki DAO last week and passed a vote on Monday to launch a lawsuit against Chiru Labs, claiming the 20,000 ETH (US$39 million) the company holds should be returned to the DAO.</p>
      <p>LegalDAO, the decentralized autonomous organization formed in 2020 by a group of legal professionals, said Tuesday that it will help Azuki DAO in the effort to claim the 20,000 ETH.</p>
      <p>By NFT collections, Bored Ape Yacht Club topped the trading volume rankings on Cryptoslam with US$3 million, but the volume was down 18.97% on the day. Mutant Ape Yacht Club, another Yuga Labs’ Ethereum-based collection, saw its volume grow 11.94% to US$2.39 million to place second in the rankings.</p>
      <p>Total NFT trading volume dropped 9.24% to US$31.5 million. Volume on Ethereum fell 9.17% to US$22.7 million according to Cryptoslam data. Volume on the Bitcoin network gained 3.73% to US$3.19 million, while volumes on Polygon, Solana and BNB all plunged.</p>
      <h3>U.S. equity futures drop during holiday</h3>
      <p>U.S. stock futures dipped as of 10:45 a.m. in Hong Kong ahead of the restart of trading in the U.S. following the July 4 public holiday.</p>
      <p>Futures on all three indexes – Dow Jones Industrial Average, S&P 500 futures and Nasdaq – declined but by less than 1%.</p>
      <p>Asian equities opened lower Wednesday morning. The Shanghai Composite fell 0.5%, Hong Kong’s Hang Seng dipped 1.3%, Japan’s Nikkei 225 index edged down 0.3%. South Korea’s Kospi also lost 0.08%.</p>
      <p>The Caixin China General Composite purchasing managers’ index (PMI) — a measure of the performance of both the manufacturing and services sectors in China — dropped to 52.5 in June from 55.6 the previous month. This marks the sixth consecutive month of expansion for China’s private sector. However, the growth rate was the slowest since January.</p>
      <p>Employment in China also returned to growth, as the service sector created more jobs following a drop in manufacturing payrolls, according to Trading Economics.</p>
      <p>In the U.S., New York Federal Reserve President John Williams’ speaks on Wednesday and may provide an update on the Fed’s view of inflation. The Fed will also release its June meeting minutes on Wednesday, where it decided to leave interest rates unchanged between 5% and 5.25%.</p>
      <p>The Federal Reserve’s next meeting on rates is July 26. The CME FedWatch Tool predicts a 87.4% chance for a 25-basis-point rate increase, down from 89.9% on Tuesday. The likelihood the Fed won’t increase is now at just 12.6%.</p>
    </div>
  },
  {
    id: 596,
    title: 'Bitcoin breaches US$31,000 on BlackRock’s updated ETF; Ether gains, Polygon leads winners',
    category: 'Crypto',
    subCategory: ['BTC', 'ETF', 'Polygon'],
    description: <div className="blog-desc">
      <p>Bitcoin climbed above US$31,000 in Tuesday morning trading in Asia after Blackrock refiled a Bitcoin exchange-traded fund (ETF) application in the U.S. after the regulator criticised an earlier filing,</p>
    </div>,
    authorName: 'Tom Zuo',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 4, 2023 - 5:28 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-796.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin climbed above US$31,000 in Tuesday morning trading in Asia after Blackrock refiled a Bitcoin exchange-traded fund (ETF) application in the U.S. after the regulator criticised an earlier filing, suggesting the world’s biggest money manager is determined to take a lead in cryptocurrency markets.</p>
    	<p>Ethereum also logged gains amid institutional interest, while other top 10 non-stablecoin cryptocurrencies traded mixed. Polygon led the winners and Litecoin the losers.</p>	
    	<h3>Crypto</h3>
      <p>Bitcoin rose 1.83% over the last 24 hours to US$31,142 at 07:50 a.m. in Hong Kong, moving up 2.85% for the past seven days, according to data from CoinMarketCap. The world’s largest cryptocurrency reached a high of US$31,371 early Tuesday morning, the highest since June 2022.</p>	
    	<p>BlackRock submitted an amended application for a spot Bitcoin ETF on Monday, after the U.S. Securities and Exchange Commission (SEC) said BlackRock’s original filing was inadequate. BlackRock indicated in the new filing that Coinbase, the custodian of the proposed ETF’s Bitcoin holdings, will provide market surveillance to support the ETF, according to a Bloomberg report on Tuesday.</p>
      <p>Several other financial institutions have filed Bitcoin ETF applications despite a crackdown last month by the SEC, which filed lawsuits against Binance.US and Coinbase last month for allegedly violating securities laws in trading cryptocurrencies.</p>
      <p>In emailed comments, Jeff Mei, chief operating officer of digital asset exchange BTSE, said the SEC criticism of the application caused some selling last week, but “investors remain quietly optimistic about future approvals.”</p>
      <p>Ether gained 1.18% to US$1,954.24, and added 5.23% for the week.</p>
      <p>Bitcoin and Ether have both seen increased interest from financial institutions in the past week, according to a Monday report from European cryptocurrency investment firm CoinShares.</p>
      <p>Bitcoin-linked digital asset investment products saw net inflows of around US$123 million in the week ending June 30, while Ethereum-linked products had net inflows of around US$2.7 million. Digital asset investment products have seen two weeks of net inflows since June 19, after logging 9 weeks of consecutive outflows.</p>
      <p>Most other top 10 non-stablecoin tokens traded higher in the past 24 hours, with Polygon’s Matic token spearheading the rally with a gain of 4.60% to US$0.7119. It’s up 10.18% for the week despite the U.K.-based fintech neobank and crypto exchange Revolut plans to delist Polygon, Solana and Cardano tokens for its U.S. users in September, according to a report by the Block on Monday.</p>
      <p>Litecoin led the losers in a potential price correction, falling 5.83% to US$106.69. It still logged weekly gains of 22.47% driven higher by the token’s upcoming third halving event on August 2, which will make the token scarcer.</p>
      <p>Litecoin investors also received a boost after the token was selected to trade on the newly established EDX Markets exchange, which opened on June 20 and is backed by Wall Street heavyweights, including Citadel Securities, Fidelity Investments and Charles Schwab.</p>
      <p>The total cryptocurrency market cap rose 1.28% to US$1.22 trillion in the last 24 hours, while crypto trading volume rose 26.34% to US$37.86 billion, according to CoinMarketCap data.</p>
      <h3>NFT volumes gain</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the main Forkast 500 NFT index fell 1.08% in the 24 hours to 09:40 a.m. in Hong Kong to 2,750, down 5.06% for the week. The two Forkast indexes measuring the performance of Ethereum and Polygon NFT markets logged moderate increases.</p>
      <p>NFT trading volume on Ethereum rose 18.72% in the past 24 hours to US$27.29 million, according to CryptoSlam data, while Polygon’s volumes surged 121.63% to more than US$1.89 million. Trading volumes also rose on the Bitcoin, Solana and BNB Chain networks.</p>
      <p>Bored Ape Yacht Club (BAYC), the second largest NFT collection by market cap, saw trading volume jump 52.56% to US$4.98 million, with Ethereum-based NFT collections Azuki and Mutant Ape Yacht Club (MAYC) ranking the second and the third.</p>
      <p>“A bit of a rebound today on the ETH side, which is in the green for the first time in days. Top collections like Bean, Azuki, and MAYC have had increases in their floor prices 7-20% and even BAYC has slowed its decline,” said Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News.</p>
      <p>However, the floor prices of some major NFT collections are still holding considerable losses for the past week, with BAYC floor price falling over 20% in the past seven days and Azuki sliding more than 50% over the same period, according to data from CoinGecko.</p>
      <p>Azuki Elementals are a collection of 20,000 NFTs launched on June 27 as a spin-off of the original Azuki collection. While the Elementals sold out within 15 minutes, collectors started to complain that the supposed new NFTs looked almost identical to the original Azuki collection.</p>
      <p>Azuki DAO, a community of dissatisfied Azuki holders, was formed last week and passed a vote on Monday to launch a lawsuit against Azuki creator Chiru Labs, claiming the 20,000 ETH (US$39 million) the company holds should be returned to the DAO to “promote the growth of the entire Azuki community.”</p>
      <p>“NFT platforms have experienced an increase in trading volume in recent days, while the prices of blue-chip NFTs have declined,” said Gracy Chen, managing director at digital asset trading platform Bitget, in an emailed comment.</p>
      <p>“The NFT market is undergoing a major adjustment, but a decline does not necessarily indicate the decline of the entire industry,” said Chen. “As NFT derivatives, including lending, indexes, perpetual contracts, and other products, are gaining traction, price fluctuations become more pronounced in the short term.”</p>
      <h3>U.S. futures dip</h3>
      <p>U.S. stock futures traded flat to lower as of 11:20 a.m. in Hong Kong. Dow Jones Industrial Average futures inched down 0.01%, S&P 500 futures dipped 0.04%, and Nasdaq futures fell 0.12%. The three major U.S. indexes closed higher in the regular Monday session, shortened ahead of the July 4 public holiday when the market is closed.</p>
      <p>In Asia, mainland China’s Shanghai Composite Index closed 1.31% higher on Monday, despite the country’s softening manufacturing recovery. South Korea’s Kospi rose 1.49%, while Japan’s Nikkei led the gainers in the region with a 1.7% increase, according to CNBC on Monday.</p>
      <p>The U.S. manufacturing purchasing managers’ index (PMI) dropped to 46.0 in June, the lowest since May 2020, according to a Monday report by the Institute of Supply Management (ISM). A reading above 50 indicates an expansion in manufacturing and below 50 a contraction.</p>
      <p>“Companies reduced production and began using layoffs to manage head counts, to a greater extent than in prior months, amid mixed sentiment about when significant growth will return,” wrote ISM Manufacturing Business Survey Committee Chair Timothy Fiore in the report.</p>
      <p>The June PMI is the eighth consecutive month of contraction and is lower than the projection of 47.0, raising concerns about a recession, according to Reuters on Tuesday.</p>
      <p>New York Federal Reserve President John Williams’ speaks on Wednesday and may provide an update on the Fed’s view of inflation. The Fed releases its June meeting minutes on Wednesday, where it decided to leave interest rates unchanged between 5% and 5.25%.</p>
      <p>The Federal Reserve’s next meeting on rates is July 26. The CME FedWatch Tool predicts a 89.9% chance for a 25-basis-point rate increase, up from 87.4% on Monday. The likelihood the Fed won’t increase is now at just 10.1%.  </p>
    </div>
  },
  {
    id: 595,
    title: 'Poly Network Hacker Mints Billions Of Tokens From Thin Air',
    category: 'Crypto',
    subCategory: ['Poly', 'Network', 'Tokens'],
    description: <div className="blog-desc">
      <p>Poly Network, a cross-chain bridging protocol notorious for suffering the second-largest DeFi exploit on record, was hacked again on Sunday.</p>
    </div>,
    authorName: 'Samuel Haig',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 3, 2023 - 10:01 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-797.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Poly Network, a cross-chain bridging protocol notorious for suffering the second-largest DeFi exploit on record, was hacked again on Sunday.</p>
    	<p>A hacker identified a flaw in Poly Network’s contract allowing them to issue a seemingly unlimited number of tokens into existence, including 10M BUSD and nearly 10M BNB tokens on Metis, almost 100T SHIB on Heco, and various tokens on Polygon, Avalanche, and BNB Chain.</p>	
    	<p>The hacker’s gains may be sharply limited by low liquidity, preventing most of the tokens from being tradable. Metis tweeted there is “no sell liquidity available” for the tokens minted on its network.</p>	
    	<p>Yet blockchain security firms Beosin Alert and SlowMist both estimate the hacker has realized 10.1M in ill-gotten gains so far.</p>
      <h3>Bridging Risks</h3>
      <p>The incident is a reminder of the security risks associated with cross-chain bridging protocols. According to Rekt, four of the five largest DeFi exploits targeted bridges, with Ronin, Poly Network, BNB Bridge, and Wormhole losing more than $2.1B in assets combined.</p>
      <p>Poly Network lost $611M to hackers in August 2021, comprising the largest DeFi hack on record at the time. The hacker later returned the majority of the stolen funds.</p>
      <h3>Multisig Compromised</h3>
      <p>Poly Network suspended its services and said it was in communication with both law enforcement and the centralized exchanges used by the hacker to cash out funds. “We hope that the attacker will cooperate and return the user assets to avoid any potential legal consequences,” the team said.</p>
      <p>Poly Network also urged liquidity providers and project teams for all affected tokens to withdraw liquidity from decentralized exchanges.Dedaub, a web3 security team, attributed the latest incident to a compromised 3 of 4 multisig wallet. The team chastised Poly Network for maintaining poor security practices and taking seven hours to pause the protocol after the attack.</p>
      <h3>Exploits Proliferate</h3>
      <p>According to a report from De.Fi, hackers have made off with $667M in 2023 so far, $204M of which was lost in Q2. Last quarter hosted 117 exploits or rug-pulls, 11 times that of Q1 2022 and a 150% increase compared to the previous quarter.</p>
      <p>BNB Chain hosted more than half of the incidents with 65 hacks totaling $57.8M in losses, followed by Ethereum with 25 exploits worth $82.5M, and Arbitrum, with 10 cases amounting to $21m.</p>
    </div>
  },
  {
    id: 594,
    title: 'Crypto Gains More Than Double Traditional Assets’ in First Half of 2023',
    category: 'Crypto',
    subCategory: ['crypto', 'gains', 'Traditional'],
    description: <div className="blog-desc">
      <p>The two largest cryptocurrencies gained by more than twice as much in the first half of the year versus traditional assets, spurring hope that the worst of the bear market is over.</p>
    </div>,
    authorName: 'Samuel Haig',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 3, 2023 - 7:21 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-798.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The two largest cryptocurrencies gained by more than twice as much in the first half of the year versus traditional assets, spurring hope that the worst of the bear market is over.</p>
    	<p>Bitcoin and Ethereum posted year-to-date gains of 85% and 63% respectively, compared with gains of 32% for the Nasdaq, 16% for the S&P 500 and a loss of 4% for the Hang Seng Index. Commodities also underperformed digital assets, with raw sugar up 14%, gold climbing 5%, and gasoline rising 1.5%.</p>	
    	<p>The combined market capitalization for crypto now sits at $1.2T after gaining 24% since the start of January, according to CoinMarketCap. Crypto’s outperformance so far in 2023 follows the 2022 bear market, which wiped $2T from the combined cryptocurrency market cap in 12 months.</p>	
    	<p>The 2022 downturn was accelerated by the abrupt collapse of Terra network and its algorithmic UST stablecoin in May 2022, which sent contagion risk reverberating across the web3 industry and deleted billions of dollars in value from the DeFi sector.</p>
      <p>The collapse of Three Arrows Capital followed. Failures of centralized crypto lenders like Celsius and BlockFi came next, and the unraveling of FTX ended what may have been the worst year for crypto’s history.</p>
      <h3>Halving Hype Propels Bitcoin</h3>
      <p>BTC now boasts a nearly $596M market cap and last traded hands for $30,700. The bullish price action propelled Bitcoin’s market dominance to its highest level since April 2021.</p>
      <p>Surging on-chain activity and early anticipation for Bitcoin’s next halving have helped propel BTC as the second-best performing asset among the top 30 cryptocurrencies by market cap for the year. The next halving event will take place around early May next year, reducing the rate of new Bitcoin issuance by 50% to 3.125 BTC per block.</p>
      <p>The creation of the ordinals inscription method and BRC-20 token standard pushed Bitcoin’s on-chain activity to new all-time highs. The inscription methods introduced fungible and nonfungible tokens on Bitcoin for the first time, giving rise to new use cases for the network.</p>
      <h3>LSTfi Drives Gains For DeFi</h3>
      <p>The DeFi sector produced modest gains in 2023 so far, with the total value locked in DeFi protocols growing 20% from $37.9B to $45.5B since January, according to DeFi Llama.</p>
      <p>The majority of growth took place on Ethereum and its Layer 2 ecosystem, with Ethereum’s TVL up 23% at $27M and Layer 2s up 140% to $10B, according to L2beat. Tron’s TVL also increased 36% to $5.6B.Mixin emerged as a top 10 chain after its TVL grew 73% to $445. Solana also hosted a notable rebound, gaining 32% to $271M from $205M.</p>
      <p>However, with Ethereum’s Layer 2 ecosystem undermining the value proposition of low-cost alternative Layer 1 networks by reducing the costs of transacting on Ethereum, many networks have stagnated or shed TVL this year.</p>
      <p>BNB Chain hosted the largest TVL decline, currently sitting at $3.4B after starting the year with $4.7B in locked assets. Fantom also suffered a significant loss of 42%, with its TVL sliding to $203M from $351M.The market cap of DeFi assets also showed strength YTD, increasing by almost 50% to $50B from $34B, according to CoinGecko.</p>
      <p>Liquid staking projects drove the lion’s share of DeFi’s market cap growth, with Lido’s stETH staking derivative up $9B, Lido’s governance token LDO gaining $1.1B, and Rocket Pool’s RPL governance token adding $400M to the sector’s capitalization this year.</p>
      <h3>Deflationary Ethereum</h3>
      <p>Ethereum realized its deflationary promise this year, with ETH’s circulating supply falling by 286,827 coins YTD worth $559.3M at current prices.Half of all Ether destroyed since Ethereum switched to Proof of Stake last September were burned during May amid a sharp resurgence in memecoin trading, with the network’s burn rate remaining relatively low during other months.</p>
      <p>The combined transaction volume on Layer 2 extended its lead over Ethereum this year, with L2s beating mainnet by 335% with nearly 36 transactions per second.Arbitrum is the most popular L2 with 25M transactions over the past 30 days, followed by zkSync Era with 22.3M, and Optimism with 14.6M. For comparison, Ethereum processed 31.4M transactions over the same period.</p>
    </div>
  },
  {
    id: 593,
    title: 'South Korea approves crypto bill to protect investors, goes into effect in one year',
    category: 'Crypto',
    subCategory: ['south Korea', 'Crypto', 'Investors'],
    description: <div className="blog-desc">
      <p>South Korea’s National Assembly on Friday approved a bill that focuses on protecting the interests of cryptocurrency investors in the country’s first step to build a legal framework</p>
    </div>,
    authorName: 'Danny Park',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 1, 2023 - 7:47 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-794.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>South Korea’s National Assembly on Friday approved a bill that focuses on protecting the interests of cryptocurrency investors in the country’s first step to build a legal framework devoted to such digital assets, according to the Assembly’s official website. The legislation, which translates as the Virtual Asset User Protection Act, is expected to come into law in a year.</p>
    	<h3>Fast facts</h3>
      <ul>
        <li>The bill, an amalgamation of 19 proposals from lawmakers, requires crypto service providers to ring-fence user’s assets and deposits, to have insurance, to hold a portion of reserves in offline cold wallets in case of hacks or system failures, and to maintain records of all transactions.</li>
        <li>The legislation includes penalties for price manipulation, false promotion of crypto assets, and failing to provide required information on investors. Penalties for those convicted include a minimum of one year in prison, or a fine of three to five times the amount of profits earned from such violations.</li>
        <li>The bill defines “virtual assets” as an “electronic representation of an economic value that can be traded or transferred electronically.” The bill excludes the central bank digital currency (CBDC) under the Bank of Korea, the country’s central bank.</li>
        <li>The legislation, however, does give the Bank of Korea the right to request data from cryptocurrency platforms, a right it had been arguing for with the country’s financial regulators. The bank has stated the cryptocurrency market could have a significant impact on financial and monetary stability and hence it needs some oversight.</li>
        <li>In an interview with Forkast in May, Hwang Suk-jin, a Ruling People Power Party’s Digital Asset Special Committee member said the proposed bill will establish the legal rights for virtual asset users and create a safer and more reliable market for cryptocurrencies.</li>
        <li>As of 2020, South Korea had one of the most active cryptocurrency economies in the world, ranking 7th worldwide on the Global Crypto Adoption Index compiled by  blockchain data platform Chainalysis.</li>
        <li>However, the country fell to 23 on the index in 2022, the same year as the US$40 billion collapse of the Terra-Luna cryptocurrency and stablecoin that was launched in the country and caused massive losses to hundreds of thousands of investors. Regardless, the Upbit crypto exchange in South Korea remains the world’s third largest by trading volume, according to CoinMarketCap data.</li>
        <li>The Terra-Luna debacle helped drive legislation in South Korea to establish a legal framework to cover cryptocurrencies, focusing first on investor protection. The next stage of crypto legislation is expected to focus on rules for local companies in token issuance and information disclosure.</li>
      </ul>
    </div>
  },
  {
    id: 592,
    title: 'Cryptocurrency Prices And News: Bitcoin Price Holds $30,000 Level For The Week; Cryptos Rise',
    category: 'Crypto',
    subCategory: ['Cryptocurrency', 'Bitcoin', 'Prices'],
    description: <div className="blog-desc">
      <p>Cryptocurrency News: Cryptocurrency prices rise Friday. Bitcoin price holds $30,000 level this past week on institutional ETF applications.</p>
    </div>,
    authorName: 'HARRISON MILLER',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'July 1, 2023 - 12:30 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-795.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Cryptocurrency News: Cryptocurrency prices rise Friday. Bitcoin price holds $30,000 level this past week on institutional ETF applications.</p>
    	<p>Cryptocurrency News: Cryptocurrency prices were largely in the green late Friday while bitcoin eased after eclipsing its $31,000 level.</p>	
    	<h3>Cryptocurrency Price Action</h3>
      <p>Bitcoin climbed as high as $31,258 Friday and traded around $30,500 in the evening. Bitcoin rocketed to $31,411 June 23 — its highest level since last May. BTC held above its $30,000 level the past week after diving below $25,000 on June 15 for the first time since mid-March. The world's largest cryptocurrency bolted 84% so far this year.</p>	
    	<p>Ethereum surged near $1,930 Friday and peaked at $1,945 intraday to mark a new month high. ETH tumbled below $1,700 on June 15 for the first time since March and traded around $1,900 prior to the recent SEC lawsuits against Binance and Coinbase (COIN).</p>
      <p>Ethereum peaked at $2,139 on April 16 -  its highest level since last May, and shot above $2,000 for the first time in nearly a year on April 13 following its Shanghai upgrade to a proof-of-stake network. The No. 2 crypto soared 61% year-to-date.</p>
      <h3>Cryptocurrency Prices Heatmap:</h3>
      <p>Digital asset investments are extremely volatile. While cryptocurrency's fundamentals and technical indicators may differ, investors should focus on the same key objectives. First, stay protected by learning when it's time to sell, cut losses or capture profits. Second, prepare to profit if the cryptocurrency starts to rebound.</p>
      <p>Despite their original promise, cryptocurrencies haven't acted as hedges against inflation. Instead, they've trended with the broader indexes. Read The Big Picture and Market Pulse to track daily market trends.</p>
      <p>View IBD's Best Cryptocurrencies And Crypto Stocks To Buy And Watch page to help navigate the world of digital asset investments.</p>
    </div>
  },
  {
    id: 591,
    title: 'Bitcoin, Ether rise along with all other top 10 cryptos',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Ether', 'cryptos'],
    description: <div className="blog-desc">
      <p>Bitcoin, Ether, and all other top 10 non-stablecoin cryptocurrencies gained in Friday afternoon trading in Asia, on general market positivity as a number of traditional</p>
    </div>,
    authorName: 'Pradipta Mukherjee',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 30, 2023 - 2:26 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-758.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin, Ether, and all other top 10 non-stablecoin cryptocurrencies gained in Friday afternoon trading in Asia, on general market positivity as a number of traditional financial institutions recently filed spot Bitcoin exchange-traded fund (ETF) applications, indicating demand and institutions’ confidence in the profit potential of digital assets.</p>
    	<p>Markets are also reacting positively to data showing U.S. gross domestic product grew faster than expected in the first quarter and initial jobless claims came in at less than expected.</p>	
    	<h3>Solana, Litecoin biggest gainers</h3>
      <p>Bitcoin rose 1.34% to US$30,756 in 24 hours to 4 p.m. in Hong Kong, according to CoinMarketCap data. The world’s biggest cryptocurrency by market capitalization has risen 2.66% in the past seven days after a number of traditional financial institutions showed interest in spot Bitcoin ETFs.</p>	
    	<p>“Bitcoin prices tend to rally during the month of July,” Markus Thielen, head of research and strategy at digital asset service platform Matrixport, said in an emailed statement on Friday.</p>
      <p>Thielen has set a year-end target of US$45,000 for Bitcoin prices, supported by macroeconomic tailwinds that are likely to continue. “Interesting to note that Bitcoin experiences its strongest rallies during U.S. trading hours, a sign that U.S. institutions are buying Bitcoin while other regions are less active,” Thielen said.</p>
      <p>Despite Federal Reserve Chair Jerome Powell’s stating earlier this week that the central bank could raise interest rates further, Thielen believes that inflation will continue to decline and the macro tailwind for risk assets would continue, supporting higher prices for stocks and crypto.</p>
      <p>Asset managers, including BlackRock, WisdomTree, Invesco and Bitwise, have filed for spot Bitcoin ETF applications this month, with Fidelity refiling an application for a spot Bitcoin ETF. The Thursday filing with the Securities and Exchange Commission shows that the Cboe exchange has proposed to list and trade shares of the Wise Origin Bitcoin Trust.</p>
      <p>Ether, the second biggest cryptocurrency, rose 2.07% to US$1,887 in 24 hours, and has risen 0.28% on the week.</p>
      <p>Solana and Litecoin posted the day’s biggest gains. Solana jumped 15.44% in the past 24 hours to US$18.78, bringing its weekly gains to 11.91%. Litecoin climbed 13.34% to US$95.22, and has risen 10.14% in the past seven days.</p>
      <p>“Litecoin’s halving event, expected August 2, 2023, historically serves as a bullish price catalyst,” Thielen said. “Given that the halving event is just five weeks away, we might see a more substantial rally into the event.</p>
      <p>At current prices, Litecoin still appears a good bet for a trade into the halving event and has high odds to participate in any future crypto bull market(s),” he added.</p>
      <p>Litecoin is a cryptocurrency based on Bitcoin’s source code. Like its predecessor, it uses a proof-of-work consensus mechanism and its mining rewards are halved roughly every four years.</p>
      <p>The total crypto market capitalization rose 1.89% to US$1.19 trillion, while market volume gained 31.82% to US$39.73 billion.</p>
      <h3>NFT trading on Ethereum, Bitcoin rise</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index gained 0.42% to 2,859.38 in 24 hours to 5.55 p.m. in Hong Kong, but is down 2.18% % on the week.</p>
      <p>At the same time, the Forkast ETH NFT Composite lost 0.61% to 968.12, losing 2.78% in the past seven days.</p>
      <p>NFT trading volume on Ethereum rose 22% in 24 hours to 21.80 million, while that on the Bitcoin network gained 390.70% to US$13.80 million, according to CryptoSlam data.</p>
      <p>The rise in sales volume on Bitcoin blockchain was supported by Uncategorized Ordinals – CryptoSlam’s category of Bitcoin Ordinals that are not part of one established collection – which rose 7.06% to US$9.80 million. This was followed by BTC Survival Gear collection which rose 585.06% to US$3.04 million.</p>
      <p>Sales volume of the original Azuki collection on Ethereum rose 27.46% to US$3 million while that of Azuki Elementals dropped 18.15% to US$2.5 million.</p>
      <p>While the new Azuki Elementals sold out its 20,000 NFTs within 15 minutes when it began minting on Tuesday, collectors were “angry” as the new NFTs looked almost identical to the original Azuki collection, according to Yehudah Petscher, NFT strategist at Forkast Labs, the parent company of Forkast.News. That could potentially devalue the NFT collection as a whole, he said.</p>
      <h3>Asian markets mixed; U.S. stock futures, European bourses rise</h3>
      <p>Asian equity markets were mixed on the last day of June as investors assessed economic data from the U.S. and China.</p>
      <p>China’s factory activity declined in June for a third straight month, as the manufacturing purchasing managers’ index moved up to 49.0, compared to 48.8 in May. A reading below 50 indicates contraction. Chinese Premier Li Qiang said on Tuesday that China was still on track to meet its 5% annual growth target.</p>
      <p>The Shanghai Composite gained 0.62% and the Shenzhen Component rose 1.02%. Hong Kong’s Hang Seng Index dropped 0.09% and Japan’s Nikkei 225 lost 0.14%.</p>
      <p>“Inflation, a slowing global economy, and high stock valuations present the three major challenges for investors in the second half of 2023,” Nigel Green, chief executive of financial advisory firm deVere Group, said in an emailed statement on Thursday.</p>
      <p>“By focusing on quality stocks and implementing a diversified investment strategy, investors can position themselves to navigate through uncertainty and capitalize on the inevitable rewards that lie ahead,” Green added.</p>
      <p>European bourses strengthened on Friday after data showed that inflationary pressures in the Euro Zone had cooled in June. The latest consumer price index revealed that headline inflation eased to a more than expected 5.5% in June, and lowest since January 2022, before the war in Ukraine started. However, core inflation – excluding energy and food – rose to 5.4% in June.</p>
      <p>The benchmark STOXX 600 gained 1.07% and Germany’s DAX 40 rose 1.18% during Tuesday afternoon trading hours in Europe.</p>
      <p>U.S. stock futures strengthened as of 7 p.m. in Hong Kong, as the Dow Jones Industrial Average futures gained 0.32%, and the S&P 500 futures rose 0.41%. The Nasdaq 100 Futures climbed 0.50%.</p>
      <p>U.S. economic growth expanded faster than expected in the first quarter, as the country’s gross domestic product climbed by an annualized rate of 2%, up from a previous estimate of 1.3%.</p>
      <p>The amount of initial jobless claims in the U.S. dropped by 26,000 to 239,000 for the week ended June 24, the largest decrease since October 2021.</p>
      <p>India’s Sensex index at the Bombay Stock Exchange gained 1.26% at the close of trading hours on Friday.</p>
      <p>Earlier this week, credit rating agency S&P Global kept India’s economic growth outlook unchanged at 6% for the current financial year, and has called the world’s most populous nation the fastest growing economy in the Asia Pacific.</p>
    </div>
  },
  {
    id: 590,
    title: 'Polygon 2.0 Roadmap Calls for ‘Unified Liquidity,’ Restaking, New Chains on Demand',
    category: 'Crypto',
    subCategory: ['Polygon', '2.0', 'Chains'],
    description: <div className="blog-desc">
      <p>Polygon, a scaling solution to the Ethereum blockchain, aims to “unify liquidity” of the various networks in its ecosystem as part of a new architecture under its rebrand as Polygon 2.0.</p>
    </div>,
    authorName: 'Margaux Nijkerk',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 29, 2023 - 8:00 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-760.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Polygon, a scaling solution to the Ethereum blockchain, aims to “unify liquidity” of the various networks in its ecosystem as part of a new architecture under its rebrand as Polygon 2.0.</p>
    	<p>The plan also provide for restaking tokens, allowing investors to simultaneously stake the same tokens on multiple projects. And Polygon plans to give developers the ability to "add new decentralized chains on demand," according to a statement – joining competitors including Arbitrum, Optimism and zkSync's Matter Labs that have made their networks easier to copy, in the pursuit of fostering broader ecosystems of specialized but compatible blockchains.</p>	
    	<p>Under the new tech stack, Polygon says it will link the various Polygon chains through a shared crypto bridge powered by zero-knowledge (ZK) proofs, one of this year’s hottest blockchain technologies. CoinDesk reported exclusively earlier this year that Polygon would emphasize ZK technology in its future project roadmap.</p>	
    	<p>"Unified liquidity is the key to everything in Polygon 2.0,” said Brendan Farmer, the co-founder of Polygon in a press release. “We need to support unlimited scalability, but the entire Polygon 2.0 ecosystem must still feel like using a single chain. The validity of cross-chain transactions is guaranteed by ZK proofs posted to Ethereum, but we want bridging to feel seamless. We can’t make users wait for a chain to generate a proof or settle on Ethereum.”</p>
      <p>With the new architecture, a coordination layer would come to exist to confirm cross-chain transactions, while the shared bridge will be powered by ZK proofs.</p>
      <p>“With zero knowledge proofs, we can basically bridge or initiate cross-chain transactions in a way that's safe, and in a way that is instant,” Farmer told CoinDesk in an interview.</p>
      <p>“And so what we want is this coordination layer for the shared bridge that allows for like an unlimited number of chains, but for those chains to sort of behave in a unified way,” Farmer said. ”For a user, it feels like you're using a single chain.”</p>
      <p>Polygon provided this technical description of how it works: "Native Ethereum tokens will be deposited into a single contract on Ethereum, so when a user transacts across Polygon chains, the corresponding assets will be mapped to the tokens deposited on Ethereum. No need for wrapped tokens and the corresponding UX difficulties." UX stands for user experience.</p>
      <p>The proposal also calls for an emphasis on restaking, which allows users to repurpose their staked crypto to ensure the security of other applications on a blockchain. Many protocols, like Eigenlayer, have recently embraced restaking.</p>
      <p>Vitalik Buterin, the co-founder behind the Ethereum blockchain, has expressed reservations about restaking, fearing that it could create systemic risks for blockchains.</p>
      <p>“I think restaking is a really nice answer to that question where there will be validators that stake the token in order to validate chains on Polygon,” Farmer said. “They'll be able to not only validate one chain, but they'll be able to restake their tokens to actually serve as decentralized validators.”</p>
      <p>The announcement about Polygon’s new architecture and tech stack comes just a few days after it shared a proposal to upgrade its Polygon PoS chain to a zkEVM validium. Polygon has shared that it will also be releasing announcements over the next few weeks on its token, $MATIC, and its governance process.</p>
      <p>“I think one of the guiding principles behind Polygon 2.0 is we want to build this sort of foundational piece of the internet, the value layer for the internet,” Farmer said.</p>
      <p>Despite Polygon’s series of announcements, its MATIC token is down 30% over the past 30 days, the second worst performance among digital tokens tracked by the analysis firm Messari with a reported market cap of at least $500 million.</p>
      <p>A major overhang is the U.S. Securities and Exchange Commission’s labeling the MATIC token earlier this month as security – a designation that could bring added regulatory scrutiny. Tokens from other layer 2 projects are also down over the past 30 days, but not as much: Optimism has lost 13%, and Arbitrum is down 6%.</p>
    </div>
  },
  {
    id: 589,
    title: 'Crypto gets a boost following reports of Fidelity’s imminent Bitcoin spot ETF filing',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Fiedelity', 'ETF'],
    description: <div className="blog-desc">
      <p>There’s a saying in gambling circles that the house always wins. It’s not an unreasonable supposition, given the fact that if casino operators didn’t bring in more cash than they paid out, they’d be forced to shut up shop.</p>
    </div>,
    authorName: 'Forkast.News',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 29, 2023 - 3:30 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-759.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
      <h3>In this issue</h3>
      <ol>
        <li>Fidelity: Close to filing its own Bitcoin ETF</li>
        <li>Forkast 500 NFT Index: Azuki disappoints</li>
        <li>Asset tokenization in Singapore: One step closer</li>
      </ol>
      <h3>From the Editor’s Desk</h3>
    	<p>Dear Reader,</p>
    	<p>There’s a saying in gambling circles that the house always wins. It’s not an unreasonable supposition, given the fact that if casino operators didn’t bring in more cash than they paid out, they’d be forced to shut up shop.</p>	
    	<p>Some in the crypto community might be applying that same saying to the bastions of traditional finance this week as Wall Street appears to be putting another move on cryptocurrencies in which the ultimate winner seems set to be itself.</p>	
	    <p>Fidelity, one of the world’s biggest asset managers, is reportedly preparing to file an application to establish a spot Bitcoin exchange-traded fund. That would be a big bet on crypto — or at least on the coin that started it all.</p>
      <p>Almost by definition, it would also be a bet against the hostility to crypto shown by the U.S. Securities and Exchange Commission since the collapse of the FTX exchange last year.</p>
      <p>The regulator’s apparent distaste for crypto has made casualties of many companies in the sector that have bet against it in recent times. Binance, Coinbase, Kraken, Genesis and Gemini have all been on the receiving end of enforcement action, and the commission has set its sights on cryptocurrencies.</p>
      <p>Traditional finance firms have also bet against the SEC and lost, although only in terms of commercial opportunities that have gone untapped rather than actual penalties, losses and business closures.</p>
      <p>As we note in this week’s edition of The Current Forkast, Fidelity’s application for a spot Bitcoin ETF isn’t the company’s first. Indeed, the SEC has turned down every application for such a fund to date.</p>
      <p>But traditional finance sector players such as Fidelity and BlackRock, which lodged a spot Bitcoin ETF application a fortnight ago, are now showing increased confidence about their prospects of gaining the regulator’s favor.</p>
      <p>Perhaps it’s because they’re buoyed by judicial skepticism about the SEC’s reasoning in denying a spot Bitcoin ETF license to crypto asset manager Grayscale. Perhaps it’s also because they sense the pendulum might have swung too far against crypto and that the regulator may be minded to offer the industry a concession in the form of a vanilla financial product.</p>
      <p>Overall, the house needs to win to keep the tables open. Yet the original question posed by crypto was: Who runs the house? Given that the SEC appears unilaterally to have made up its mind, it feels like high time others started asking that question once again.</p>
      <p>Until the next time,</p>
      <p>Angie Lau,</p>
      <p>Founder and Editor-in-Chief</p>
      <p>Forkast.News</p>
      <h3>1. Bull run</h3>
      <p>Cryptocurrencies are receiving a significant push as Fidelity Investments is reportedly nearing the filing of its own spot Bitcoin exchange-traded fund (ETF), the Block reported Tuesday, citing people familiar with the matter.</p>
      <ul>
        <li>The move would not be Fidelity’s first attempt to file for a Bitcoin-backed ETF. In March 2021, the Wall Street giant filed for a spot Bitcoin ETF named the Wise Origin Bitcoin Trust, which was rejected by the U.S. Securities and Exchange Commission in January 2022. But it launched the Fidelity Advantage Bitcoin ETF in Canada in December 2021, which has risen 75% since the beginning of this year.</li>
        <li>According to the Block, Fidelity’s application will join the list of financial institutions that have recently submitted or updated their applications to issue spot Bitcoin ETFs, including BlackRock, WisdomTree, Invesco, Valkyrie and Bitwise.</li>
        <li>A spot Bitcoin ETF is a publicly traded investment vehicle tracking Bitcoin price, which allows investors to have Bitcoin exposure without directly handling the underlying cryptocurrency.</li>
        <li>“Bitcoin ETF applications have supported investors’ sentiment as institutional and retail investors could show increasing interest in the market through such investment vehicles,” said Denys Peleshok, head of Asia at Belize-based financial brokerage CPT Markets, in email comments.</li>
        <li>“If the SEC approves new applications, the market could record a surge in demand and in the number of buyers,” said Peleshok. “Due to the SEC’s legal stance over cryptocurrencies, the market could further concentrate on Bitcoin and Ethereum, the two largest assets in the market.”</li>
        <li>Since BlackRock filed for its Bitcoin ETF on June 15, Bitcoin is up by more than 22%, peaking at US$31,389 on June 23, the highest since June 2022. Bitcoin traded at US$30,433, at press time, according to data from CoinMarketCap.</li>
      </ul>
      <h3>Forkast.Insights | What does it mean?</h3>
      <p>The potential foray of Fidelity, globally recognized as the third largest asset manager, into the ETF domain hardly raises eyebrows among those tuned in to the vibrant buzz of Twitter’s rumor universe.</p>
      <p>In the wake of the ETF application of the world’s largest asset manager, BlackRock, industry watchers projected that Fidelity, the Bostonian investment giant with US$4.2 trillion in assets under management, would be the subsequent contender to lodge and apply for a spot Bitcoin ETF.</p>
      <p>This topic also fuelled speculation that Fidelity might put in a bid for Grayscale, the digital asset manager responsible for the US$13.5 billion Grayscale Bitcoin Trust, the world’s biggest Bitcoin fund. So far, there has been no substantial evidence for the buyout.</p>
      <p>Fidelity has taken a proactive stance in embracing Bitcoin and other digital assets, eclipsing many of its traditional finance contemporaries. The firm claims that its research on the asset class dates back to 2014.</p>
      <p>In 2018, the firm launched an institutional trading platform for Bitcoin and Ethereum. A year later, in 2019, it expanded its venture into the Bitcoin mining business through Bitcoin technology firm Blockstream’s institutional mining service.</p>
      <p>Last year, it even joined the lengthy list of firms whose Bitcoin ETF applications got rejected by the SEC. And last week, EDX Markets, a non-custodial crypto exchange backed by Fidelity and other Wall Street titans, went live.</p>
      <p>In addition, Fidelity has shown an assertive drive in obtaining indirect exposure to Bitcoin via Microstrategy, the preeminent corporate Bitcoin holder globally. Fidelity has secured its position as Microstrategy’s third largest shareholder, possessing 6.7% of the stock, valued at over US$225 million. BlackRock follows as the fourth largest owner with 5.58%, approximating around US$185 million.</p>
      <p>Despite the entrance of these behemoth asset managers into the U.S. ETF race, the waiting list is long. ARK Invest Chief Executive Officer Cathy Wood claims that her firm is in position to launch the first spot-Bitcoin ETF in the world’s largest economy.</p>
      <h3>2. Liquid dreams</h3>
      <p>The NFT markets saw another week of decline, and again, Ethereum’s NFT ecosystem is dragging the broader market down. The Forkast 500 NFT Index is down 1.32%, as Ethereum’s decline of 1.51% stands in stark contrast to Solana, Polygon, and Cardano’s gains in value of 1.7%, 1.23% and 5.75%, respectively.</p>
      <ul>
        <li>Ethereum sales volume rose 318% on Thursday following Azuki’s Elementals mint, which brought in US$38 million to the Azuki team in just 15 minutes.</li>
        <li>Azuki’s floor price decreased 45% to 9 Ether following the Elementals mint, and the average sales price declined to US$23,000, down from US$39,000 the previous seven days.</li>
        <li>Bored Ape Yacht Club’s average sales price is up in the past seven days to US$79,214. However large BAYC holders like Machi Big Brother and Franklin have combined to sell over 60 Bored Apes, applying downward pressure on the collection.</li>
        <li>Solana’s 98,917 transactions on June 25 were its single highest daily total of NFT transactions in over eight months. September 2022 was the last time the blockchain had this level of activity, when the Y00ts were still surging on Solana.</li>
        <li>Seven-day sales volume was up across several blockchains with Ethereum up 105%, Bitcoin 22%, Solana 24%, and Polygon 10%. Mythos Chain, BNB, and Flow were down 18%, 20% and 23%, respectively.</li>
        <li>DeGods is now the third most expensive PFP based on floor price following Azuki’s falling prices. Azuki’s cheapest NFTs are selling for as low as 8.5 ETH, while DeGods is nearly 1 ETH higher at 9.5 ETH.</li>
      </ul>
      <h3>Forkast.Insights | What does it mean?</h3>
      <p>This year’s biggest new NFT mint took place on Tuesday, as one of the top NFT projects, Azuki, delivered a new collection called Elementals. While some were hopeful the new collection would breathe life into the NFT markets, it instead drained liquidity from traders who sold assets across projects to afford the hefty 2 ETH mint price. The impact was visible on the Forkast 500 NFT Index even in the midst of Ethereum NFTs having its single highest day of sales volume this year with US$58 million.</p>
      <p>Azuki’s Elementals did provide some lucky traders with rare Elementals NFTs that were flipped for over 30 ETH, but most secondary sales are taking place well below the collection’s 2 ETH mint price. Instead of Azuki’s Elementals helping the broader NFT ecosystem, it instead sucked liquidity from holders, and almost across the board, brought other collections’ sales prices down with it.</p>
      <p>What went wrong with Azuki’s mint is a familiar story, and shows that the NFT space may not be as mature as some would like to believe. Starting from the initial sale that left many Azuki and Beanz holders stuck on a loading page, and ultimately unable to mint, to the massively underwhelming art reveal that almost looks like duplicates of the main Azuki collection, NFT projects talk about innovation, yet time after time, fail to deliver it.</p>
      <p>For all the talk of innovation, we have witnessed few examples of it, and other than functioning as ‘meme coins’, traders shouldn’t expect another NFT boom until we see actual innovation.</p>
      <p>If the Azuki team can turn around the current negative sentiment in their project and help foster the higher floor prices that their holders have come to expect, we may then see sales with profits that roll into existing collections floors. We’ll be watching.</p>
      <h3>3. Singapore calling</h3>
      <p>The Monetary Authority of Singapore (MAS), the city state’s central bank, has proposed a framework to design open and interoperable networks for digital assets, as the country seeks to harness the power of asset tokenization to improve its traditional financial services.</p>
      <ul>
        <li>“The real value in the digital asset ecosystem” lies in the tokenization of real-world and financial assets through distributed ledger technologies and smart contracts,  which could “enhance the efficiency, accessibility, and affordability of financial services,” MAS wrote in its report.</li>
        <li>“Unless digital asset networks are interoperable, both with each other and with traditional financial market infrastructures, fragmentation would reduce the network benefits and can create frictions such as inaccessibility, increased liquidity requirements due to separation of liquidity pools, and pricing arbitrage,” noted MAS.</li>
        <li>MAS’ proposal – jointly developed with the Bank for International Settlements and other financial institutions – is part of Singapore’s Project Guardian initiatives announced in May 2022 to explore the adoption of asset tokenization and decentralized finance in the city state’s financial industry.</li>
        <li>MAS also announced an expansion of Project Guardian to test the potential of asset tokenization across more financial asset classes, enlisting 11 financial institutions — including banking giants Citi, HSBC, DBS and Standard Chartered — to form the Project Guardian Industry Group to lead industry pilots in asset and wealth management, foreign exchange and fixed income.</li>
        <li>Japan Financial Services Agency, the first overseas financial regulator to join Project Guardian, will work with MAS on digital asset innovation while safeguarding against risks to financial stability and integrity.</li>
        <li>MAS’ move comes as Singapore seeks to utilize the power of blockchains in its financial system, while minimizing risks of crypto assets. Last Thursday, The Asia-Pacific branch of technology firm Ripple Labs said it would start offering crypto-enabled digital payment services in Singapore after receiving in-principle approval from the MAS.</li>
        <li>Also on Thursday, MAS released a whitepaper – jointly developed by the International Monetary Fund, Amazon, DBS Bank, and multiple other industry players.– for “purpose-bound” money, detailing a technical overview of digital currencies designed for specific purposes.</li>
      </ul>
      <h3>Forkast.Insights | What does it mean?</h3>
      <p>The Monetary Authority of Singapore’s announcement of a framework for interoperable digital asset networks stands out as an example of Singapore doing what it does best: high-tech, innovative efficiency — shepherded, of course, by the state.</p>
      <p>The fact that the country’s de facto central bank is developing its framework jointly with the Bank for International Settlements and the International Organization of Securities Commissions simply emphasizes the establishment credentials of the initiative.</p>
      <p>Coming less than a month after Hong Kong unveiled its virtual asset licensing framework — a cornerstone of city authorities’ plan to welcome crypto back after imposing tough restrictions on it — Singapore’s framework may have the feel of a riposte to Hong Kong’s recent punt on digital assets, even though it has long been in the works. But to view it in such a way would be mistaken.</p>
      <p>On the contrary, Hong Kong’s rollout of digital asset licensing rules might be interpreted by cynics as a roll of the dice by the rulers of a city suffering hard times. After all, Hong Kong’s star has waned in recent years following the authorities’ crushing of its once-celebrated first-world freedoms and their handling of the Covid pandemic, both of which have prompted an exodus of talent and a collapse of foreign business confidence.</p>
      <p>Hong Kong’s attitude to crypto has see-sawed over the years, as has Singapore’s, to a lesser extent. But overall, the Southeast Asian nation has been honing its approach to digital assets fairly steadily and for a long time, and its latest plan to develop the industry marks merely one more milestone along that road.</p>
      <p>The MAS’s initiative has less of an air of opportunism about it than Hong Kong’s recent embrace of digital assets. In fact, the authority’s plan is so measured, technocratic, centralizing and heavy on the involvement of TradFi heavyweights that it feels more like fintech than frontier finance of the sort imagined by crypto and digital asset purists.</p>
      <p>As a development spawned by the disruptive phenomenon of digital assets, Project Guardian, as the initiative is named — and as its name suggests —  appears to represent the very opposite of disruption. It will likely be seamless, safe and secure — and not a little dull. Right on brand for Singapore. What’s more interesting, however, is whether it will join previous initiatives implemented by the city-state as another of its successful exports.</p>
    </div>
  },
  {
    id: 588,
    title: 'Michael Saylor touts a new massive bitcoin haul as MicroStrategy now hodls $4.6 billion worth of tokens',
    category: 'Crypto',
    subCategory: ['Michael Saylor', 'bitcoin', 'tokens'],
    description: <div className="blog-desc">
      <p>MicroStrategy just added 12,333 bitcoins to its coffers at an average purchase price of $28,136 per token, according to a new regulatory filing.</p>
    </div>,
    authorName: 'Phil Rosen',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 28, 2023 - 5:09 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-761.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
      <ul>
        <li>Michael Saylor's MicroStrategy bought an additional 12,333 bitcoin, for about $347 million.</li>
        <li>That brings the company's total holdings to 152,333 bitcoin, worth roughly $4.6 billion at current prices.</li>
        <li>Bitcoin's strong rally in 2023 helped MicroStrategy notch its first profitable quarter in over two years.</li>
      </ul>
    	<p>MicroStrategy just added 12,333 bitcoins to its coffers at an average purchase price of $28,136 per token, according to a new regulatory filing.</p>
    	<p>Founder and Executive Chairman Michael Saylor, a prominent bitcoin bull, tweeted the announcement Wednesday, adding that the company now "hodls" — an acronym for "hold on for dear life" — 152,333 bitcoins in total, acquired for roughly $4.52 billion and purchased at an average price of $29,668 per token.</p>	
    	<p>At current bitcoin prices, the enterprise software company's bitcoin stockpile is worth about $4.6 billion.</p>	
    	<p>MicroStrategy's latest bitcoin purchase comes after Saylor predicted earlier this month that the US regulatory crackdown on cryptocurrencies will help bitcoin nearly double its market share.</p>
      <p>In this year alone, bitcoin's share of the crypto industry's overall market capitalization has gone from 40% to 48%, he told Bloomberg TV. But with regulators targeting other tokens, bitcoin's dominance will to grow to 80%, he added.</p>
      <p>"Now I think that the public is beginning to realize that bitcoin is the next bitcoin," Saylor said. "The next logical step is for bitcoin to 10x from here and then 10x again."</p>
      <p>In 2023, the world's largest cryptocurrency has rallied about 82%. Thanks to its strong performance, MicroStrategy in the first three months of the year notched its first profitable quarter in more than two years.</p>
      <p>"The conviction in our bitcoin strategy remains strong as the digital asset environment continues to mature," CFO Andrew Kang said in a statement in May, after the company had snapped up 7,500 tokens that quarter.</p>
      <p>Saylor, an active market commentator via Twitter, has made other predictions recently, including how the next president will be pro-bitcoin, how the token is more attractive than the traditional safe haven asset gold, and even calling the crypto "immortal."</p>
    </div>
  },
  {
    id: 587,
    title: 'Binance market share at 1-year low: Kaiko',
    category: 'Crypto',
    subCategory: ['Binance', 'Market', 'Kaiko'],
    description: <div className="blog-desc">
      <p>Binance’s market share hits near a one-year low as it reels under the pressure of regulatory scrutiny across countries. </p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 26, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-749.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Binance’s market share hits near a one-year low as it reels under the pressure of regulatory scrutiny across countries. </p>
      <ul>
        <li>Binance’s spot trading market share stood at 55.9% in June 2023, hitting a nearly one-year low.</li>
        <li>The exchange was facing regulatory action not only in the U.S. but in countries such as France and Belgium as well.</li>
      </ul>
    	<p>The market share of the world’s leading cryptocurrency platform, Binance [BNB], was reeling under the pressure of increased regulatory scrutiny not only in the United States, but across several countries.</p>	
    	<p>According to data from research firm Kaiko, Binance’s spot trading market share stood at 55.9% in June 2023, hitting near a one-year low. The figure is nearly the same as the last two months.</p>	
    	<p>But the slight dip this month made it hit the lowest point since August 2022, when it was at 53.7%.</p>
      <p>The trading platform was facing enforcement action at the hands of the U.S. Securities and Exchange Commission (SEC) as the agency filed a lawsuit against the firm and its founder Changpeng “CZ” Zhao early this month.</p>
      <p>In late March, another U.S. agency, the Commodity Futures Trading Commission (CFTC), also filed a lawsuit against the firm.</p>
      <p>Binance’s U.S. division lost almost all its market share after the lawsuits from the CFTC and the SEC, Kaiko’s data shows.</p>
      <h3>Regulatory troubles across countries</h3>
      <p>Binance is also facing regulatory scrutiny in other countries, such as France and Belgium. While the former is investigating the exchange, the latter has asked Binance to stop its operations in the jurisdiction.</p>
      <p>Meanwhile, established TradFi institutions such as BlackRock and Wisdomtree have also applied to offer spot Bitcoin exchange-traded funds (BTC ETFs).</p>
      <p>Binance’s market share hit low also when it stopped a zero-fee promotion in March. It has recently launched a new promotion offer for stablecoins, including True USD [TUSD], Binance USD [BUSD], Tether [USDT] and USD Coin [USDC], beginning 30 June.</p>
      <p>Despite its declining market share, Binance remained larger than all other cryptocurrency exchanges combined. It was the largest holder of customer tokens at press time, with $59.2 billion in reserves.</p>
      <p>Another major exchange Coinbase, against which the SEC filed a lawsuit just after it charged Binance, has seen its market share fall to 6.8% in June from 7.6% in January.</p>
    </div>
  },
  {
    id: 586,
    title: 'Bitcoin treads water above US$30,000; SEC approves first leveraged Bitcoin futures ETF',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'SEC', 'ETF'],
    description: <div className="blog-desc">
      <p>Bitcoin dipped but held above the US$30,000 threshold on Monday morning in Asia, with nearly all other top 10 non-stablecoin cryptocurrencies rising.</p>
    </div>,
    authorName: 'Timmy Shen',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 26, 2023 - 4:33 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-748.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
	    <p>Bitcoin dipped but held above the US$30,000 threshold on Monday morning in Asia, with nearly all other top 10 non-stablecoin cryptocurrencies rising.</p>
	    <p>The gains follow a number of traditional financial institutions showing interest in launching crypto-related exchange-traded funds (ETFs), and the U.S. securities regulator approved the first leveraged Bitcoin futures ETFs in the country on Friday. In the NFT market, a whale has been selling off Bored Ape Yacht Clubs.</p>	
	    <h3>Bitcoin little changed</h3>
      <p>Bitcoin edged down 0.35% to US$30,444 over the last 24 hours to 7:30 a.m. in Hong Kong, according to data from CoinMarketCap. The world’s largest cryptocurrency by market capitalization rose 15.44% in the past seven days.</p>
      <p>Ether gained 1.18% to US$1,898, with a 10.32% weekly gain.</p>	
      <p>Polkadot led gains among the top 10 non-stablecoin cryptocurrencies with a 3.28% 24-hour rise to post a weekly gain of 14.28%. Beside Bitcoin, Litecoin was the only token on the list edging lower, dropping 1.48% over the past 24 hours but up 14.24% for the week.</p>
	    <p>On Friday, the U.S. Securities and Exchange Commission (SEC) approved the nation’s first leveraged Bitcoin futures ETF offered by Florida-based Volatility Shares, CoinDesk reported. The “2x Bitcoin Strategy ETF” is set to start trading on June 27 on CBOE BZX Exchange, according to the fund’s website.</p>
      <p>The ETF will not invest directly in Bitcoin but seek to benefit from increases in the price of Bitcoin futures contracts for a single day, according to its prospectus. The fund will correspond to two times the return of the S&P CME Bitcoin Futures Daily Roll index.</p>
      <p>This comes after BlackRock and WisdomTree recently filed applications with the SEC to launch spot Bitcoin ETFs. The regulator has given a greenlight to a number of futures-based crypto ETFs but has not approved spot products.</p>
      <p>“The turbulences of 2022 have led to a decline in confidence in crypto-native institutions, presenting traditional financial institutions with a prime opportunity to enter the crypto market,” Jonas Betz, crypto market analyst and founder of consultancy firm Betz Crypto, told Forkast last week.</p>
      <p>Additionally, New York-headquartered investment bank JPMorgan Chase & Co. launched euro-denominated transactions on its blockchain-based payment system, JPM Coin, Bloomberg reported on Friday.</p>
      <p>JPM Coin allows clients to transfer euros or dollars held with their JPMorgan accounts immediately and at any time of the day, unlike traditional banking transactions that usually operate during business hours.</p>
      <h3>Bored Ape Yacht Club dump</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index dipped 0.25% to 2,905.28 in the 24 hours to 9:40 a.m. in Hong Kong. The index, however, is up 1.08% for the week.</p>
      <p>NFT transactions on Ethereum fell 2.49% over the past 24 hours to US$18.38 million, while transactions on the Bitcoin network surged 138% to US$3.6 million, according to CryptoSlam data.</p>
      <p>“Ethereum is still struggling with tumbling prices, especially in Bored Ape Yacht Club (BAYC) whose average sales price remains in the low- to mid-US$70,000 range,” said Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News.</p>
      <p>Over the past few days, NFT whale Jeffrey Huang, also known as Machi, has dumped dozens of his BAYC holdings. On Saturday, Machi sold 19 BAYC NFTs in one transaction on marketplace Blur for 651.56 Ether, or roughly US$1.22 million.</p>
      <p>The floor price of BAYC stood at 37.4 Ether on Blur on Monday morning in Asia, gaining 4.18% over the past day but losing 13.52% over the past week.</p>
      <p>Sales of BAYC jumped 26.11% over the past 24 hours to US$3.43 million, adding to weekly sales of US$13.11 million. BAYC was the most-sold collection for the past day and week in terms of transaction value.</p>
      <p>Ethereum-based Azuki NFT transactions rose 25.68% in the past 24 hours to US$3.07 million, the highest after BAYC.</p>
      <p>Azuki’s floor price on Blur rose 1.78% in the past day but was down 9.21% for the week.</p>
      <h3>U.S. stock futures rise</h3>
      <p>U.S. stock futures edged higher as of 10:40 a.m. in Hong Kong. Dow Jones Industrial Average futures rose 0.19%, and S&P 500 futures added 0.24%. Nasdaq Futures rose 0.32%.</p>
      <p>Equity markets across Asia were mostly mixed with investors unsettled by events in Russia over the weekend when Wagner Group mercenaries marched toward Moscow in what appeared to be a rebellion against the military leadership.</p>
      <p>The group later halted the advance, but it raised concern about instability in Russia as it may undermine the leadership of President Vladimir Putin. Oil prices rose on concern about supply disruptions from Russia, the world’s third-largest producer of crude oil.</p>
      <p>In macroeconomic developments, Japan’s services producer price index rose 1.6% year-on-year in May, according to official data released Monday.</p>
      <p>“Japan’s economy, despite being affected by factors such as past high commodity prices, has picked up as private consumption, for example, has increased moderately,” the Bank of Japan said Monday in the summary of opinions of June 15-16 monetary policy meetings.</p>
      <p>On U.S. inflation and interest rates, Federal Reserve Chairman Jerome Powell said last week that the central bank is in consensus on the need for more interest rate hikes this year.</p>
      <p>U.S. interest rates are now between 5% and 5.25%, the highest since 2006. The CME FedWatch Tool predicts a 71.9% chance for another 25-basis-point rate hike, and a 28.1% chance the Fed will leave rates unchanged at the next meeting on July 26.</p>
    </div>
  },
  {
    id: 585,
    title: 'Ethereum: Here’s the silver lining to its declining trends',
    category: 'Crypto',
    subCategory: ['Ethereum', 'Silver', 'Trends'],
    description: <div className="blog-desc">
      <p>Ethereum’s triumphant ascent breathes new life into the market, yet the dwindling exchange supply adds intrigue. Long-term holders seek profitability amidst challenges.</p>
    </div>,
    authorName: 'Adewale Olarinde',
    authorurl: 'https://ambcrypto.com/author/adewale-olarinde/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 25, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-751.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Ethereum’s triumphant ascent breathes new life into the market, yet the dwindling exchange supply adds intrigue. Long-term holders seek profitability amidst challenges.</p>
    	<ul>
        <li>ETH price hovered around $1,890, inching closer to the $1,900 price zone.</li>
        <li>ETH supply on exchanges continued to decline, reaching a record low.</li>
      </ul>
      <p>Ethereum [ETH] trended sideways for weeks and eventually slid. However, it has embarked on an upward trajectory, breathing new life into the market.</p>	
    	<p>Nevertheless, amidst this surge, the available supply of Ethereum on exchanges has been steadily dwindling, adding an intriguing twist to the unfolding narrative.</p>	
      <h3>Ethereum maintains a bull trend</h3>
    	<p>Looking at the daily timeframe chart of Ethereum revealed an intriguing journey that began around 15 June when it initiated its upward surge. Since then, it has accumulated growth of over 16% in value.</p>
      <p>As of this writing, Ethereum hovered around the $1,890 mark, exhibiting a slight dip in value.</p>
      <p>However, on 23 June, it concluded the trading session with a noteworthy addition of 1.10%. While it has yet to reclaim the $2,000 price range, an exciting development unfolded as it successfully shattered the resistance posed by its short Moving Average (yellow line) around the $1,820 price zone.</p>
      <p>This breakthrough indicated a positive shift in the prevailing trend.</p>
      <p>The press time price trajectory has also propelled Ethereum into a bull run, as evidenced by its Relative Strength Index (RSI). As of this writing, the RSI line stood above 60, signifying a strong bullish trend.</p>
      <h3>Ethereum on exchanges declines</h3>
      <p>Despite Ethereum’s valiant efforts toward recovery, a fascinating phenomenon persists—the dwindling supply of ETH on exchanges. Santiment’s data revealed that the supply on exchanges, as a percentage of the total supply, had reached an all-time low.</p>
      <p>As of this writing, this supply had declined to approximately 9.1%, marking its lowest level since Ethereum’s inception.</p>
      <p>The decline in supply commenced back in January, but it was during May that a sharp downturn became apparent. This downward trajectory has persisted until now, further deepening the scarcity of Ethereum available on exchanges.</p>
      <h3>ETH profitability for long-term holders</h3>
      <p>Examining the profitability of Ethereum for long-term holders revealed that, despite the recent uptrends, they have yet to translate into profitability for these holders.</p>
      <p>As of this writing, the two-year Market Value to Realized Value ratio (MVRV) had dipped below zero, standing at -10% at press time. This indicated that individuals who have held onto ETH during this specific held at a loss.</p>
      <p>However, there remained a glimmer of hope. A sustained increase in value could potentially propel these holders into a more profitable zone, alleviating their loss position at press time.</p>
      <p>Furthermore, traders, as per data provided by CoinGlass, held optimistic expectations for an impending price surge. As of this writing, the funding rate on CoinGlass stood in positive territory.</p>
      <p>This was a significant indicator of positive sentiment among traders actively taking long positions.</p>
      <p>It is worth noting that a price rise could further enhance the positivity of the funding rate, potentially attracting more traders to take long positions. Conversely, a price decline would cause a substantial number of positions to be liquidated.</p>
    </div>
  },
  {
    id: 584,
    title: 'Polygon zkEVM fails to impress, what does the future hold?',
    category: 'Crypto',
    subCategory: ['Polygon', 'zkEVM', 'future'],
    description: <div className="blog-desc">
      <p>Competition intensifies as zkSync Era outperforms Polygon zkEVM. MATIC surges, but network growth decline suggests challenges in retaining interest.</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 25, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-750.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Competition intensifies as zkSync Era outperforms Polygon zkEVM. MATIC surges, but network growth decline suggests challenges in retaining interest.</p>
    	<ul>
        <li>Transaction activity on zkSync Era surpassed Polygon zkEVM.</li>
        <li>MATIC surged, but network growth declined, pointing to challenges in sustaining interest.</li>
      </ul>
      <p>The zkEVM sector has continued to get crowded as competition increased between Polygon [MATIC], StarkNet, and zkSync Era. Notably, transaction activity on these protocols has surged since May for all three protocols.</p>	
    	<p>However, Polygon has not been able to make a significant mark in this sector.</p>	
      <h3>The zkEVM race intensifies</h3>
    	<p>According to Dune Analytics’ data, transactions on zkSync Era have far exceeded those on Polygon zkEVM, indicating a higher level of adoption and usage for zkSync.</p>
      <p>Due to these factors, zkSync has achieved a Total Value Locked (TVL) of $602 million in assets locked in the bridge. Alongside, there was an impressive milestone of 1 million unique addresses, highlighting growing popularity and user engagement.</p>
      <p>A breakdown of the TVL revealed that 75.7% of the locked value consisted of assets in the form of Ethereum [ETH], indicating a strong presence of Ethereum-based assets within zkSync.</p>
      <p>Among the total number of depositing addresses, 36% have not utilized Arbitrum [ARB] or Optimism [OP], indicating that a substantial portion of users have yet to leverage these specific Layer 2 scaling solutions.</p>
      <h3>Looking at Polygon</h3>
      <p>In contrast, when considering Polygon DeFi’s zkEVM, a different set of statistics emerged, which suggested lower adoption and engagement compared to the zkSync Era.</p>
      <p>In terms of depositing addresses, 11.9% have not utilized Arbitrum or Optimism, suggesting a portion of users who have not yet engaged with these specific Layer 2 scaling solutions.</p>
      <p>Additionally, 31.9% of the depositing addresses were created after the launch of the zkSync Era, demonstrating a notable increase in user activity following its introduction.</p>
      <p>Compared to the zkSync era, 42.3% of these addresses on Polygon’s zkEVM do not actively participate in DEX’s or NFT activities, indicating a diverse user base with varying interests within this ecosystem.</p>
      <p>Despite Polygon’s zkEVM not being able to perform as well as its competitors. MATIC had a good week in terms of price, trading at $0.673 at press time. This can be attributed to an increase in the number of holders of MATIC.</p>
      <p>However, during the same period, the network growth for MATIC declined. This implied that new addresses were losing interest in MATIC over time.</p>
    </div>
  },
  {
    id: 583,
    title: 'Brazilian Central Bank Adds Crypto Exchange Mercado Bitcoin in CBDC Pilot Including Mastercard',
    category: 'Crypto',
    subCategory: ['Brazilian', 'CBDC', 'Master card'],
    description: <div className="blog-desc">
      <p>Brazil’s central bank authorized on Thursday the largest local crypto exchange, Mercado Bitcoin, to participate in the pilot of the digital real, the South American country’s central bank digital currency (CBDC).</p>
    </div>,
    authorName: 'Andrés Engler',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 24, 2023 - 12:27 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-737.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Brazil’s central bank authorized on Thursday the largest local crypto exchange, Mercado Bitcoin, to participate in the pilot of the digital real, the South American country’s central bank digital currency (CBDC).</p>
    	<p>The consortium led by Mercado Bitcoin counts Mastercard, broker Genial, registrar Cerc and financial software fintech Sinqia as partners, local newspaper Valor reported.</p>	
    	<p>Also on Thursday, the central bank gave its green light to state-owned Brazilian bank Caixa to participate in its CBDC pilot, along with card provider Elo and Microsoft.</p>	
    	<p>Mercado Bitcoin had been left out of the central bank’s choice of 14 participants in late May because the consortium lacked at least one regulated financial or payment institution with direct access to Brazil's national financial network. That changed after Mercado Bitcoin received the payment institution license from the central bank, on 2 June.</p>
      <p>“Our participation validates the Central Bank's intention to bring innovation to the financial system with those who have already been working with this technology and not just with the incumbents who have appropriated it,” said Fabricio Tota, director of new business at Mercado Bitcoin, Valor added.</p>
      <p>“We are pleased to be part of the consortium selected to join the Real Digital pilot in Brazil. Mastercard continues to engage in a series of partnerships to build trust and compliance in the digital assets ecosystem, while also solving real-world problems,” Walter Pimenta, executive vice president of Products and Engineering at Mastercard Latin America and the Caribbean, told CoinDesk in a statement.</p>
      <p>The central bank will begin incorporating participants into the Real Digital Pilot platform in mid-June 2023. According to Glovo, the consortium intends to participate in testing the issuance of the CBDC and treasury bills, and will seek to test technical aspects of the network and the governance model of the distributed ledger technology.</p>
      <p>The CBDC is slated to be rolled out by 2024 by the central bank, which sees a digital currency as a way of increasing participation in the financial system.</p>
    </div>
  },
  {
    id: 582,
    title: 'Bitcoin Hits One-Year High, Soars Past $31.3K',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'high', 'soars'],
    description: <div className="blog-desc">
      <p>Bitcoin continued its mid-June surge Friday morning (ET), soaring to a one-year high above $31,000, before retreating below this threshold later in the day.</p>
    </div>,
    authorName: 'James Rubin',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 23, 2023 - 8:24 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-736.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
	    <p>Bitcoin continued its mid-June surge Friday morning (ET), soaring to a one-year high above $31,000, before retreating below this threshold later in the day.</p>
	    <p>The largest cryptocurrency by market capitalization was recently trading at $30,894, up about 2.1% over the past 24 hours, according to CoinDesk Indices. It is up roughly 20% over the past week.</p>	
	    <p>BTC last changed hands over $31,050 in June 2022. It crossed $31,000 briefly on April 10.</p>	
	    <p>Bitcoin began spurting earlier this week, a few days after BlackRock, the world's largest asset manager, applied for a spot ETF, and as two other financial services powerhouses, Invesco and WisdomTree, refiled their applications for similar products.</p>
      <p>Investors who were shaken earlier this month by Securities and Exchange Commission (SEC) lawsuits against crypto exchanges Binance and Coinbase, were heartened by the ETF filings that underscored the ongoing commitment of large institutional investors to enlarge their presence in the digital asset space.</p>
      <p>"A sustained move above USD $31,000 would signal us entering a new phase where market participants are starting to reprice some of the previous regulatory anxiety," Strahinja Savic, head of data and analytics at Toronto-based crypto platform FRNT Financial, wrote in a Telegram note. "Despite what comes across as antagonistic rhetoric from some regulators, the bitcoin spot ETF applications have amplified the view that institutions remain interested in crypto."</p>
      <p>Savic added: "Despite regulatory complexity, there are serious actors looking at growing their involvement in the digital asset space, and bitcoin’s move above USD 30,000 seems to be acknowledging that."</p>
      <p>In its weekly newsletter on Friday, K33 Research noted similarly that "Blackrock's Bitcoin spot ETF filing has raised expectations of an institutionally driven price rally being on the horizon."</p>
      <p>Most other major cryptos joined bitcoin's upswing. Ether, the second largest crypto in market value, was recently up 0.5% from Thursday, same time, to change hands at $1,901. Earlier this week, ETH regained the $1,900 threshold for the first time since early June. Litecoin and DOT, the token of smart contracts platform Polkadot, were recently up more than 5% and 6%, respectively. AVAX, the native crypto of the Avalanche blockchain, rose nearly 4%.</p>
      <p>The CoinDesk Market Index, a measure of crypto markets performance, recently jumped more than 2%.</p>
      <p>Cryptos continued their decoupling ways from equity markets, which sagged on Friday and suffered their first losing week after more than a month of gains. The tech-heavy Nasdaq Composite, S&P 500 and Dow Jones Industrial Average (DJIA) declined 0.9%, 0.6% and 0.5%, amid investor concerns about additional interest rate hikes later in 2023 and the prospect of a global recession.</p>
      <p>Earlier in the week, the Bank of England raised its rate a hawkish 50 basis points (bps) in its ongoing effort to tame inflation, which remains a stubbornly high 8.7% in the U.K. The U.S. Federal Reserve and other central bank have taken more dovish turns in recent weeks, although Fed Chair Jerome Powell and other bank officials have reiterated their intent to resume rate increases, which have tended to weigh heavily on digital assets.</p>
      <p>In a text message to CoinDesk, Mark Connors, head of research at Canadian digital asset manager 3iQ, struck a cautiously optimistic note about the potential, long-term impact of the ETF applications.</p>
      <p>"Sentiment has clearly shifted in the digital space, as markets look through the Binance and SEC complaints, instead, betting the slew of BTC ETF filings drives future demand….sooner than later," Connors wrote.</p>
      <p>Connors wrote that BTC's "punch through $31K" to a year-long high underscored cryptos' exit from "2022's fire swamp that burned many," but that BlackRock and the other firms' ETF initiatives would not drive "a sustained bid for BTC."</p>
      <p>"Mounting cross currents that limit near term visibility, not the least is the entrenched battle by the SEC against the digital asset universe of players and protocols," Connors wrote, reiterating a theory that BlackRock timed its filing on June 8, just two days after the SEC complaint against Coinbase, "to support their partner in a strategic effort."</p>
      <p>BlackRock named Coinbase (COIN) as custodian of assets – primarily bitcoin – in the proposed ETF, called the iShares Bitcoin Trust.</p>
    </div>
  },
  {
    id: 581,
    title: 'Bitcoin: Why $34k is the next target for bulls',
    category: 'Crypto',
    subCategory: ['bitcoin', 'target', 'bulls'],
    description: <div className="blog-desc">
      <p>The mean coin age of Bitcoin has been rising since late May to suggest network wide accumulation.</p>
    </div>,
    authorName: 'Akashnath S',
    authorurl: 'https://ambcrypto.com/author/akashnath-sumukar/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 23, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-720.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The mean coin age of Bitcoin has been rising since late May to suggest network wide accumulation.</p>
    	<p>Disclaimer: The information presented does not constitute financial, investment, trading, or other types of advice and is solely the writer’s opinion.</p>
      <ul>
        <li>The market structure shifted firmly in bullish favor amidst rising demand.</li>
        <li>The $30k area was an enormously important region stretching back to January 2021.</li>
      </ul>
     	<p>The price action of Bitcoin [BTC] from earlier this year showed that Bitcoin could climb to $34k later this year. Trading volume and market sentiment could impede progress, but the tides have already begun to shift.</p>	
    	<p>The US Federal Reserve’s decision to pause interest rate hikes was received well in traditional markets. Furthermore, Bitcoin spot ETF applications boosted sentiment, although the SEC’s lawsuits against some of the largest players in the market gave investors some cause for concern.</p>
      <h3>Are we in for another Bitcoin bull run?</h3>
      <p>The 2020/2021 bull run saw BTC prices rise by more than 500%. A 13% move higher from $30k to $34k can’t be termed a bull run, but it would certainly establish the $16k area as a long-term bottom.</p>
      <p>Before we get ahead of ourselves it must be noted that trading volume has been underwhelming since April. The $30k area is a significant psychological and technical resistance.</p>
      <p>But, if the recent strength continues, such a move upward might be possible. The daily timeframe structure break was seen on 20 June. Bitcoin exploded higher on 21 June to break past another lower high at $28.5k.</p>
      <p>This established a strong bullish structure, and a higher low in the coming weeks would indicate an uptrend. This higher low could happen at the bullish breaker block in the $27k-$28k area after a pullback. To the north, the 100% Fibonacci extension level (yellow) at $34.2k was the next target.</p>
      <p>The Fibonacci extension levels from the early 2023 rally climbed just past the 61.8% extension level at $30.7k before a drop. The current rally retested the $25k region before pushing higher. To the north, $32.8k, $34.5k, and $37.8k are the important resistance levels to watch out for.</p>
      <h3>The rise in MVRV meant selling pressure could mount soon</h3>
      <p>The 90-day MVRV ratio climbed the April highs and bulls would be concerned that profit taking could hamper the progress that BTC has made. However, this could present a buying opportunity upon a pullback as well. The dormant circulation also posted the highs for June so far, which meant selling pressure could follow.</p>
      <p>The mean coin age has been rising since late May to suggest network wide accumulation. Combined with the price action, the overall picture showed bullish strength.</p>
      <p>A pullback and another move back above $30k in the next week or two would be a hopeful development for BTC bulls.</p>
    </div>
  },
  {
    id: 580,
    title: 'Bitcoin gains to hold above US$30,000; Ether little changed with top 10 crypto mixed',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Gain', 'Ether'],
    description: <div className="blog-desc">
      <p>Bitcoin rose and held above US$30,000 in Friday morning trading in Asia, holding onto the strong gains made this week on the back of growing institutional interest in the world’s largest cryptocurrency by market capitalization.</p>
    </div>,
    authorName: 'Danny Park',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 23, 2023 - 4:36 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-718.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin rose and held above US$30,000 in Friday morning trading in Asia, holding onto the strong gains made this week on the back of growing institutional interest in the world’s largest cryptocurrency by market capitalization.</p>
    	<p>Ether was little changed. Other top 10 non-stablecoin cryptocurrencies traded mixed, with Cardano’s ADA token gaining and Binance’s BNB losing ground. Overall, most tokens were holding weekly gains spurred by Bitcoin’s rally.</p>	
    	<h3>Crypto</h3>
      <p>Bitcoin rose 0.33% over the last 24 hours to US$30,056.27 at 6:30 a.m. in Hong Kong, up 17.52% for the past week, according to CoinMarketCap data. The world’s largest cryptocurrency broke through resistance at US$30,000 early morning on Thursday for the first time since April, reaching a high of US$30,737.33.</p>	
    	<p>Ether was little changed, rising 0.02% to US$1,879.66 to gain 12.87% for the week.</p>
      <p>BlackRock Inc.,the world’s largest asset manager with US$8.59 trillion under management at the end of 2022, filed an application on June 15 for a Bitcoin exchange-traded fund (ETF). Two more U.S.-based asset managers — WisdomTree and Invesco — also filed Bitcoin ETF applications this week.</p>
      <p>“Major cryptocurrencies were seeing strong performances this week — Traders reacted positively to the possibility of seeing a Bitcoin ETF come to market,” Denys Peleshok, head of Asia at Belize-based financial brokerage CPT Markets, in email comments.</p>
      <p>“A successful launch of a bitcoin ETF could open the way for more inflows from individuals and professional investors alike,” Peleshok added. “The investment vehicle could lower the barrier of entry into the market and attract significant volumes of investment into bitcoin in particular.”</p>
      <p>Boston-based Fidelity Investments, another major financial services company that managed US$4.2 trillion as of the first quarter of 2023, is also said to be preparing to file a Bitcoin ETF application.</p>
      <p>In another encouraging move for institutional adoption of crypto, EDX Markets opened for business on Tuesday as a New Jersey-based digital asset exchange backed by Wall Street firms such as Citadel Securities, Fidelity Investments and Charles Schwab Corp. EDX said U.S. regulatory compliance is a core feature of its operations.</p>
      <p>These moves by traditional finance follow the crackdown on the U.S. crypto sector this month by the Securities and Exchange Commission (SEC), which filed lawsuits against the Binance.US and Coinbase exchanges. The SEC alleges they illegally traded cryptocurrencies that should have been registered as financial securities, among other charges.</p>
      <p>“[Bitcoin could] continue benefiting from its status as a quasi-commodity when most other digital assets are considered securities by regulators. As a result, Bitcoin could emerge as a winner from the SEC’s legal efforts,” said Peleshok of CPT Markets.</p>
      <p>U.S. Federal Reserve Chairman Jerome Powell commented on Bitcoin during a monetary policy hearing on Capitol Hill this week, describing the token as having “staying power” as an asset class.</p>
      <p>Meanwhile, Mastercard Inc., the world’s second largest payment processor, announced Thursday that it will expand its Engage network to embrace more blockchain and digital asset partners. Mastercard Engage connects businesses with qualified technology partners.</p>
      <p>Other top 10 non-stablecoin cryptocurrencies were mixed, although making weekly gains spearheaded by Bitcoin. Binance’s BNB token lost 2.53% to US$242.49 while gaining 2.36% in the past seven days.</p>
      <p>Cardano’s ADA rose 2.21% to US$0.2922, adding 10.90% in the last week.</p>
      <p>The total cryptocurrency market capitalization rose 0.14% to US$1.17 trillion in the last 24 hours, while trading volume fell 25.24% to US$45.06 billion, according to CoinMarketCap data.</p>
      <h3>NFT looking up</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index rose 0.78% to 2,930.17 in the 24 hours to 8:30 a.m. in Hong Kong. The index gained 1.3% for the week.</p>
      <p>Total NFT transactions rose 0.64% to US$23.9 million, while transactions on Ethereum dipped 3.70% to US$15.56 million. Bitcoin was the second largest blockchain by NFT transactions, rising 8.56% to US$2.74 million.</p>
      <p>Yuga Labs’ Bored and Mutant Ape Yacht Club NFTs were the top selling collections in the last 24 hours. Bored Ape trades rose 1.12% to US$3.07 million, while Mutant Ape surged 23.92% to US$1.05 million.</p>
      <p>“NFT traders are optimistic about the future, and clearly willing to spend as we see from multiple $145k Bored Ape Yacht Club sales, along with a $286k sale, but still the index is just above its all-time low point,” said Yehudah Petscher, NFT strategist at Forkast Labs, the parent company of Forkast.News.</p>
      <p>That indicates investors remain hesitant as a whole, apart from some whales, said Petscher, adding that may be because of a lack of a track record for some projects holding value over time.</p>
      <p>The Gods Unchained Cards NFT collection from ImmutableX was the third most traded collection, even as transactions fell 8.75% to US$754,805. Immutable announced Wednesday that its NFT trading card game Gods Unchained is now listed on game distribution platform Epic Games Store, which has over 230 million customers.</p>
      <p>“Lots of eyes on Azuki tomorrow as they have a big event in Las Vegas,” Petscher said. Azuki, the Japanese anime-inspired NFT collection of 10,000 generative avatars, became one of the most popular collections since its debut in January 2022 and still holds an active community.</p>
      <p>Azuki is the eighth most traded collection according to Cryptoslam data, with US$537,366 worth of transactions over the past day. “We’re watching to see what big announcements they may have – a new token, or possibly a game? Azuki has a track record of delivering on hype,” Petscher said.</p>
      <p>Elsewhere, Swiss-based multinational food manufacturer Nestlé S.A. and U.S.-based snack brand Slim Jim both announced the launch of NFT collections, signaling continued mainstream interest in the blockchain-powered technology.</p>
      <h3>Equity futures edge down</h3>
      <p>U.S. stock futures traded lower as of 9:30 a.m. in Hong Kong, following a mixed regular trading session on Thursday.</p>
      <p>Dow Jones Industrial Average futures slipped 0.06%, S&P 500 futures lost 0.11%, while Nasdaq futures inched down 0.084%.</p>
      <p>Tech stocks gained in the Thursday session performed well with Apple Inc. setting a new record high of US$187, while Amazon.com Inc. and Tesla Inc. also rising.</p>
      <p>On the U.S. battlefront against inflation, Federal Reserve Chairman Jerome Powell told the U.S. Congress on Tuesday and Thursday this week that the central bank is in consensus that more interest rate increases are likely this year to control high price pressure. The U.S. inflation rate is currently at around 4.05%, down from last month’s 4.93% but still far from Fed’s target of 2%.</p>
      <p>U.S. interest rates are now between 5% and 5.25%, the highest since 2006. The Fed paused rate hikes at the last meeting on June 14, but indicated two more hikes this year to bring rates to around 5.6%.</p>
      <p>The Federal Reserve’s next meeting on interest rates is July 26 and the majority view is they will raise. The CME FedWatch Tool predicts a 76.9% chance for another 25-basis-point rate hike, up from 71.9% on Thursday, and a 23.1% chance the Fed will leave rates unchanged.</p>
    </div>
  },
  {
    id: 579,
    title: 'Pepecoin Becomes Biggest Meme Coin Gainer With 70% Weekly Jump',
    category: 'Crypto',
    subCategory: ['Pepecoin', 'Meme coin', 'Gainer'],
    description: <div className="blog-desc">
      <p>Pepecoin (PEPE) has seen a huge gain this week amid a broader crypto rally, outperforming other meme coins like SHIB, FLOKI and DOGE.</p>
    </div>,
    authorName: 'Shaurya Malwa',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'june 22, 2023 - 8:25 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-719.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p><strong>Takeaways:</strong></p>
      <ul>
        <li>Pepecoin (PEPE) has seen a huge gain this week amid a broader crypto rally, outperforming other meme coins like SHIB, FLOKI and DOGE.</li>
        <li>Futures products tied to PEPE saw unusually large liquidations, suggesting short covering – or traders exiting bearish bets on the meme coin's price – might be exaggerating the size of the gain.</li>
      </ul>
    	<p>Pepecoin (PEPE) surged as much as 40% in the past 24 hours to extend weekly gains to over 70% amid a market-wide rally led by bitcoin (BTC).</p>	
    	<p>Trading volumes for the frog-themed tokens jumped to over $800 million, CoinGecko data shows, as a risk-on environment likely fueled outsized bets on riskier assets, such as altcoins and meme coins.</p>	
    	<p>PEPE gains were significantly higher than meme tokens shiba inu (SHIB), floki (FLOKI) and dogecoin (DOGE) – even as developers of some of these tokens introduced ecosystem upgrades – suggesting high buying interest for pepecoin.</p>
      <p>Futures contracts tracking PEPE had more than $13 million of liquidations. Potentially, this may have contributed to the price spike as short positions – or bets that the price of PEPE would fall – were settled. Only bitcoin (BTC) and ether (ETH) futures liquidations have been greater.</p>
      <p>Liquidation refers to when an exchange forcefully closes a trader’s leveraged position due to a partial or total loss of the trader’s initial margin. It happens when a trader is unable to meet the margin requirements for a leveraged position (fails to have sufficient funds to keep the trade open).</p>
      <p>Large liquidations can signal a top or bottom of a steep price move, which may allow traders to position themselves accordingly.</p>
      <p>As such, funding rates in perpetual futures tied to PEPE futures remain negative, indicating the dominance of bearish positions in the derivatives market. A negative funding rate indicates that shorts are dominant and are willing to pay longs to keep their bearish bets open.</p>
      <p>Bitcoin crossed the $30,000 mark on Wednesday amid a flurry of Bitcoin ETF filings by traditional finance players in the U.S., which likely spurred bullish sentiment among traders and led to a market-wide rally.</p>
    </div>
  },
  {
    id: 578,
    title: 'Bitcoin gains ground, Ether rises, Solana leads winners, BNB little-changed to lower',
    category: 'Crypto',
    subCategory: ['BNB', 'Solana', 'Ether'],
    description: <div className="blog-desc">
      <p>Bitcoin rose in Tuesday morning trading in Asia to hold above support at US$26,000. Ether also logged gains along with most other top 10 non-stablecoin cryptocurrencies.</p>
    </div>,
    authorName: 'Tom Zuo',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 20, 2023 - 5:02 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-694.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin rose in Tuesday morning trading in Asia to hold above support at US$26,000. Ether also logged gains along with most other top 10 non-stablecoin cryptocurrencies.</p>
    	<p>Binance’s BNB token was little changed to lower on signs the world’s largest crypto exchange may be winding down its expansion in Europe amid widespread regulatory woes.</p>	
    	<p>Equity futures in the U.S. edged lower after the long weekend break, recent mixed signals on the state of inflation, and speculation about more interest rate hikes by the Federal Reserve.</p>
      <h3>Crypto</h3>	
    	<p>Bitcoin rose 1.41% over the last 24 hours to US$26,765 at 07:00 a.m. in Hong Kong, moving up 3.21% for the past seven days, according to data from CoinMarketCap. The world’s largest cryptocurrency reached a weekly high of US$26,984 early Tuesday to test resistance at US$27,000.</p>
      <p>Ether gained 0.86% to US$1,734, but has flatlined over the past seven days to log a weekly loss of 0.48%. Solana led the winners in the top 10, rising 3.76% to US$15.95 and up 4.95% for the week.</p>
      <p>Most other top 10 non-stablecoin tokens moved higher in early morning trade. However, Binance’s BNB posted a dip of 0.25% to US$242.87, though the token is still holding onto a weekly gain of 5.38%. The Binance exchange seems to be reining in its global expansion plans amid growing regulation challenges.</p>
      <p>Binance Markets Ltd, the U.K. subsidiary of Binance, can no longer “provide regulated activities and products” for non crypto-related activities in the country, according to a Monday posting on the website of the country’s Financial Conduct Authority (FCA). According to reports, Binance had permission to provide such services in the U.K. but had requested to the FCA it be withdrawn as they were unlikely to be used.</p>
      <p>Apart from the ongoing lawsuit with U.S. regulators, Binance is also facing challenges in Europe. The company on Friday said it will withdraw from the Netherlands after being unable to secure a virtual asset service provider registration in the country. The crypto exchange is also reportedly under an anti-money laundering probe in France.</p>
      <p>Digital asset investment products saw an outflow of US$5.1 million last week, according to data tracked by Europe-based cryptocurrency investment firm CoinShares on Monday. The data marks the ninth consecutive week of outflows, while the number is smaller than US$88 million in the previous week after the world’s biggest asset manager BlackRock filed for a spot Bitcoin ETF.</p>
      <p>“(BlackRock’s) move also conveys a compelling message to the SEC, implying a firm stand on the importance and role of cryptocurrencies in future financial innovation in the U.S,” Rachel Lin, the co-founder and chief executive officer of crypto derivatives exchange SynFutures, said in an emailed comment.</p>
      <p>BlackRock’s Bitcoin ETF efforts could be a sign of a major crypto trader migration, according to Markus Thielen, Head of Crypto Research & Strategy at digital asset service platform Matrixport.</p>
      <p>“There are now 420 million cryptocurrency holders; migrating them to regulated entities might be the most significant task in 2023 mainly because fighting those crypto companies with deep pockets is also very time-consuming for regulators,” said Thielen in an emailed comment. “Ripple Labs has spent $200m on its lawsuit with the SEC, while Coinbase, especially Binance, could spend billions. So why not approve the Blackrock ETF and let nature play is part?”</p>
      <p>CoinShares indicated the slump in prices of many tokens had brought in some bargain-hunting. “The prior week crash in altcoin prices prompted investors to add to positions (in altcoin-linked investment products),” wrote CoinShares in its Monday report, “with XRP, Cardano and Polygon being the focus.”</p>
      <p>The total cryptocurrency market cap rose 0.86% to US$1.07 trillion in the last 24 hours, while crypto trading volume rose 35.69% to US$26.74 billion, according to CoinMarketCap data.</p>
      <h3>NFT transactions rise</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index dipped 0.17% to 2,868.69 in the 24 hours to 09:20 a.m. in Hong Kong. The index is down 3.36% for the seven days.</p>
      <p>NFT transactions on Ethereum rose 6.74% in the past 24 hours to US$13.16 million, according to CryptoSlam data. Transactions on the Bitcoin blockchain surged 135.38% to US$3.43 million, while the Polygon and Cardano networks also logged two-digit gains in NFT transactions.</p>
      <p>“Forkast 500 NFT Index along with Ethereum, Solana, Polygon, and Cardano are all close to even, which may be a sign that the market has finished reacting to the SEC’s lawsuits against Binance and Coinbase,” said Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News.</p>
      <p>“Sneakerhunt”, a teased collaboration between Nike’s NFT platform .SWOOSH and online video game Fortnite is set to be unveiled on Tuesday, which might bring more public exposure to NFTs.</p>
      <p>“This may be a huge week for NFTs after Nike’s tease about an incoming in-game sneaker event in Fortnite,” said Petscher. “Many speculate that it’s Nike’s .SWOOSH shoes that may be the prize at the end of the hunt, titled Airphoria. With Fornite’s 242+ million strong userbase, this could end up being another mass onboarding event for NFTs.”</p>
      <p>Elsewhere, CryptoPunk #8611 of the Ethereum-based CryptoPunk NFT collection was sold for around US$95,000 on Saturday and immediately burned and “revived” as an Ordinal inscription on the Bitcoin network, with the process directed by NFT enthusiast NatanStein.</p>
      <p>“(The action) may be a vote of confidence in the value of assets existing on Bitcoin, but this asset now loses any potential benefits it would have received from Yuga Labs if they were to incorporate it in their Otherside Universe,” said Petscher. “Yuga Labs famously had thousands of $APE Coin claimable for each BAYC NFT, along with land in their Otherside game, and some have speculated that CryptoPunks may one day see their own rewards.”</p>
      <h3>U.S. futures lower</h3>
      <p>U.S. stock futures traded lower as of 11.00 a.m. in Hong Kong. Dow Jones Industrial Average futures fell 0.37%, while the S&P 500 and Nasdaq futures both lost 0.22%.</p>
      <p>All the three major U.S. stock indexes logged gains last week as the Federal Reserve left interest rates unchanged at the June 14 meeting, but this was tempered by Fed Chair Jerome Powell, noting “nearly all Committee participants expect that it will be appropriate to raise interest rates somewhat further by the end of the year.”</p>
      <p>The Fed projects the interest rate to reach 5.6% in 2023, indicating two more rate hikes within the year. New York Fed President John Williams will be speaking on Tuesday in the U.S. and may make further comments on the Fed’s fight with inflation.</p>
      <p>On the economic data front, the U.S. housing market index, a measurement of homebuilder sentiment, reached an 11-month high of 55 in June to beat analysts’ expectations, according to Bloomberg on Monday. Readings above 50 indicate more housebuilders see the market condition good than bad.</p>
      <p>U.S. interest rates are now between 5% and 5.25%, the highest since 2006, and the Federal Reserve’s next meeting on rates is July 26. The CME FedWatch Tool predicts a 25.6% chance the Fed will keep rates unchanged in July, and a 74.4% chance for another 25-basis-point rate hike, up from 71.9% on Monday.</p>
      <p>China’s central bank lowered its one-year and five-year loan prime rates to 3.55% and 4.2% on Tuesday as economists expected, as the country seeks to boost its slow post-Covid recovery, according to the Financial Times on Tuesday.</p>
    </div>
  },
  {
    id: 577,
    title: 'Grayscale Bitcoin Trust Gets a Bullish Bump After BlackRock ETF Filing',
    category: 'Crypto',
    subCategory: ['Grayscale', 'Bitcoin', 'ETF'],
    description: <div className="blog-desc">
      <p>Last week, $9.5 trillion asset manager BlackRock made a seismic move by applying to the U.S. Securities and Exchange Commission (SEC) for a Bitcoin exchange-traded fund (ETF).</p>
    </div>,
    authorName: 'Pedro Solimano',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 20, 2023 - 12:06 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-699.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Last week, $9.5 trillion asset manager BlackRock made a seismic move by applying to the U.S. Securities and Exchange Commission (SEC) for a Bitcoin exchange-traded fund (ETF). The ripple effects of the traditional finance titan have, apparently, propped up investor sentiment and sent Grayscale’s Bitcoin Trust (GBTC) flying.</p>
    	<p>GBTC jumped more than 12% on the news before markets closed on Friday, with share prices reaching $15.12, according to Yahoo Finance. U.S. markets were closed on Monday in observance of Juneteenth, a federal holiday commemorating the day word reached Texas that enslaved people had been emancipated.</p>	
    	<p>Grayscale’s flagship product, GBTC, enables investors to trade shares in trusts that hold pools of Bitcoin, with each share meant to track the current price of Bitcoin; the idea is that investors can gain exposure to Bitcoin without having to actually buy and hold the asset itself.</p>	
    	<p>The so-called “GBTC Discount,” hit a monthly low of -36.61% according to YCharts, with bitcoin trading at $26,436, as per information from Coingecko. The discount refers to the difference between the price fo the shares and the net asset value (NAV) of GBTC’s Bitcoin holdings.</p>
      <p>Grayscale, a subsidiary of Digital Currency Group (DCG), has been filing for a spot Bitcoin ETF for years–as have several other companies. The company first filed an application back in 2016, withdrawing it a year later.</p>
      <p>“We believed the regulatory environment for digital assets had not advanced to the point where such a product could successfully be brought to market,” Grayscale said at the time.</p>
      <p>They applied again in 2021, only to be turned down by regulators due to what authorities deemed “a market prone to manipulation.” The repeated rejection of its ETF application led Grayscale to initiate legal actions against the SEC last year. The court case reached a pivotal moment in March this year, after judges questioned the agency’s decision-making.</p>
      <p>Investors seem to be feeling bullish today, however. Although the long and extenuating legal battle with the SEC seems endless, BlackRock’s impeccable track record for ETF applications (currently at 575-1) has injected a breath of fresh air into GBTC.</p>
      <p>The SEC has been relentless in its “anti-crypto” stance, suing several big name industry players in the past weeks. SEC Chair Gary Gensler, known for having taught a Bitcoin and Blockchain course at MIT, has been a staunch adversary for the cryptocurrency space.</p>
    </div>
  },
  {
    id: 576,
    title: 'Curve Looks To Expand crvUSD Collateral Assets',
    category: 'Crypto',
    subCategory: ['Curve', 'Expand', 'USD'],
    description: <div className="blog-desc">
      <p>Curve, a popular stablecoin-focused decentralized exchange (DEX), is seeking to expand the set of collateral assets supported by its crvUSD stablecoin</p>
    </div>,
    authorName: 'Samuel Haig',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 19, 2023 - 10:46 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-695.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Curve, a popular stablecoin-focused decentralized exchange (DEX), is seeking to expand the set of collateral assets supported by its crvUSD stablecoin beyond Ethereum liquid staking tokens, starting with wrapped Bitcoin.</p>
    	<p>Should the proposal pass, Curve would launch a wBTC vault with a $200M debt ceiling and a maximum loan-to-value ratio of 89%. Voting began yesterday and will end on June 25.</p>	
    	<p>On Sunday, Curve also launched a proposal to onboard native Ether as crvUSD collateral with a $200M debt limit on Sunday. Voting is currently underway. Curve is DeFi’s second-largest DEX with nearly $4B in total value locked. Its CRV token has shed a quarter of its value in the past month.</p>	
    	<p>Curve launched crvUSD on mainnet in early May. Users could initially mint the stablecoin against Frax’s liquid staking token (LST), sfrxETH. Curve then rolled out support for the leading LST, Lido’s stETH, two weeks ago.</p>
      <p>However, it remains to be seen whether crvUSD will be able to compete with other money market protocols. crvUSD borrowers are currently paying interest rates of more than 5% (which can be offset by the staking rewards accrued by LSTs), twice the current interest rate for wBTC on Aave.</p>
      <h3>Stablecoin Shakeup</h3>
      <p>The proposals come as Curve sees opportunities in the stablecoin sector.</p>
      <p>The centralized stablecoin landscape was shaken up in February when the New York Department of Financial Services ordered Paxos to cease issuing new Binance USD tokens.</p>
      <p>The following month, USDC, the second largest stablecoin, depegged amid fears regarding its exposure to the failure of Silvergate and Silicon Valley Bank. USDC’s market capitalization is down 36% since the start of the year.</p>
      <p>MakerDAO’s DAI stablecoin also depegged due to its heavy reliance on USDC.</p>
      <p> MakerDAO is currently restructuring per its Endgame roadmap, with founder Rune Christensen recently advocating for DAI to freely float against the dollar, creating possible openings in the decentralized stablecoin sector.</p>
      <h3>Peg Maintenance</h3>
      <p>CrvUSD maintains its peg by combining overcollateralization requirements with Stability Pools and PegKeepers.</p>
      <p>Stability Pools take inspiration from MakerDAO'sPeg Stability Module, comprising liquidity pools that enable low-slippage swaps between crvUSD and top centralized stablecoins USDC, USDT, USDP, and TUSD.</p>
      <p>Curve ties each Stability Pool to a PegKeepers smart contract, which mints or burns crvUSD when the token trades above or below its peg to push the stablecoin’s price back toward $1. This mechanism underpins many algorithmic stablecoins.</p>
      <p>The system has been very effective at maintaining the stablecoin’s price so far, despite crvUSD’s collateral comprising volatile crypto assets. On Saturday, Curve shared data indicating the token has traded within a 0.3% price range since mid-May.</p>
      <h3>Aave Nixes Proposal To Freeze CRV Market</h3>
      <p>In other Curve news, the Aave community has unanimously voted down a proposal from Gauntlet, a risk management firm, to freeze the CRV lending market on Aave V2.</p>
      <p>Gauntlet had flagged the potential risks posed by Curve founder Michael Egorov’s outsized CRV position on Aave, which is too large to be efficiently liquidated on-chain should the need arise.</p>
    </div>
  },
  {
    id: 575,
    title: 'Ethereum Scanner Etherscan Adds OpenAI-Based Tool to Analyze Smart Contract Source Code',
    category: 'Crypto',
    subCategory: ['Ethereum', 'Etherscan', 'AI'],
    description: <div className="blog-desc">
      <p>Etherscan, one of the most commonly used Ethereum blockchain scanning websites, introduced a tool that uses artificial intelligence (AI) to help users</p>
    </div>,
    authorName: 'Eliza Gkritsi',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 19, 2023 - 7:34 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-696.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Etherscan, one of the most commonly used Ethereum blockchain scanning websites, introduced a tool that uses artificial intelligence (AI) to help users interpret the source code of smart contracts, according to a post on its website.</p>
    	<p>The tool, based on AI technology developed by OpenAI, allows users to ask for an explanation of the entirety or parts of the source code of a smart contract, Etherscan said.</p>	
	    <p>Users can also retrieve the "read" and "write" functions of a smart contract such that they can "make informed decisions" on how to interact with them as well as explore possible ways of using them in decentralized applications, the post said.</p>	
	    <p>Following the explosion of interest in AI spurred by the popularity of OpenAI's ChatGPT chatbot, blockchain and crypto companies have rushed to support traders and developers with tools based on the technology. Last week, crypto exchange Bybit integrated ChatGPT into its trading platform.</p>
      <p>Etherscan said the tool is meant for informational purposes only and encouraged users to verify its answers instead of relying on them solely for evidence or bug bounty submissions.</p>
      <p>In order to use Code Reader, as the Etherscan tool is called, users need to connect to OpenAI's API and have sufficient usage limits. An API, or application programming interface, allows two computer programs to communicate and share information.</p>
      <p>Etherscan's tool currently doesn't allow for conversation threads with the chatbot and can be queried only through one-off prompts.</p>
    </div>
  },
  {
    id: 574,
    title: 'Ethereum Developers Propose Raising Validator Limit to 2,048 Ether From 32 Ether',
    category: 'Crypto',
    subCategory: ['Ethereum', 'Developers', 'ether'],
    description: <div className="blog-desc">
      <p>A bump in waiting times and the sheer amount of interest in spinning up Ethereum validator nodes is making developers consider increasing the current limits drastically.</p>
    </div>,
    authorName: 'Shaurya Malwa',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 19, 2023 - 4:19 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-697.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>A bump in waiting times and the sheer amount of interest in spinning up Ethereum validator nodes is making developers consider increasing the current limits drastically.</p>
    	<p>Developers have proposed raising the validator limit from 32 ether (ETH) to 2,048 ether – a 6,300% rise. This has forced large entities, such as Lido or staking services offered by crypto exchanges, to spin up multiple validator nodes to offer staking yield services to users.</p>	
    	<p>Developers said on an Ethereum core developer call on Thursday that the current limit led to a brisk expansion of the network’s validator set, albeit with a large increase in the number of validators running the network.</p>	
    	<p>The proposal was first floated in early June by Ethereum developers Mike Neuder, Francesco D’Amato, Aditya Asgaonkar and Justin Drake. The proposal is still under debate and isn’t actively being worked on as of Monday.</p>
      <p>Validators are entities in a proof-of-stake blockchain, such as Ethereum, that process transactions and help maintain the overall security of such networks.</p>
      <p>Data shows the current waiting time for a user to run a validator node on Ethereum is 44 days, up from nearly a month in May. Exiting the network is possible within a few minutes, and no entity is in the “exit queue” as of Monday, the data shows.</p>
      <p>The data indicates the demand for validators to enter the network and earn a nearly 5% annual yield. Such strong demand is likely stemming from large ether holders, who do not want to cash out and instead just want to earn some passive income on their holdings.</p>
    </div>
  },
  {
    id: 573 ,
    title: 'Ethereum Layer 2 Network zkSync Eras Locked Value Surpasses $500M',
    category: 'Crypto',
    subCategory: ['Ethereum', 'Network', 'surpasses'],
    description: <div className="blog-desc">
      <p>Matter Labs' zkSync Era, a zero-knowledge (ZK) rollup aimed at scaling Ethereum, continues to attract capital at a brisk pace.</p>
    </div>,
    authorName: 'Omkar Godbole',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 19, 2023 - 11:54 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-698.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Matter Labs' zkSync Era, a zero-knowledge (ZK) rollup aimed at scaling Ethereum, continues to attract capital at a brisk pace.</p>
    	<p>The total value locked (TVL) on zkSync Era rose above $500 million early Monday, marking a 12% increase in one week, according to data source L2Beat. The TVL is a metric widely used to track the total value of digital assets locked or staked on a decentralized finance platform.</p>	
    	<p>The scaling solution, which retains Ethereum Virtual Machine (EVM) compatibility while ensuring native account abstraction, is already the third-largest rollup by total value locked next to Arbitrum and Optimism.</p>	
    	<p>At press time, more than 220,000 ether (ETH), or around $378.3 million, about 121 million USDC (a dollar-pegged stablecoin) and 14.43 million MUTE, worth $7.53 million, were locked on zkSync. MUTE is the native cryptocurrency of a zkRollup-based decentralized exchange, Mute.</p>
      <p>The daily active addresses have steadily increased since May, averaging 175,000 in the past four weeks. zkSync was launched in March 2023.</p>
      <p>Recently, liquid staking solution Rocketpool went live on zkSync Era, joining the list of decentralized applications moving to layer 2 platforms. The increased user demand for rollups is likely driven by Ethereum core developers' focus on implementing the ethereum Improvement Proposal (EIP) 4844, according to Galaxy Digital.</p>
      <p>The EIP 4844 will introduce a new transaction type to Ethereum, which accepts "blobs" of data and reduce transaction fees on rollups.</p>
      <p>"The prioritization of EIP 4844 as the next major code change in Ethereum’s forthcoming Cancun/Deneb upgrade slated to activate sometime this Fall or Winter next year affirms the importance of rollups for the long-term scalability of Ethereum and the need for dapps built on Ethereum to eventually migrate the majority of their operations to a more cost-effective rollup," Alex Thorn, head of research at Galaxy Digital, said in a newsletter dated June 2.</p>
      <p>Note that activity on other ZK rollups like Starknet and Polygon zkEVM also remains brisk. That has some observers calling a Zk season ahead – a crypto market slang for a period where ZK rollups outperform other market sectors by a significant margin.</p>
    </div>
  },
  {
    id: 572,
    title: 'BNB Chain’s NFT landscape witnesses a rebound in June aided by…',
    category: 'Crypto',
    subCategory: ['BNB', 'NFT', 'crypto'],
    description: <div className="blog-desc">
      <p>After stagnating in May, BNB Chain’s NFT sales volume and trade count witnessed an impressive rebound in June.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 18, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-693.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>After stagnating in May, BNB Chain’s NFT sales volume and trade count witnessed an impressive rebound in June.</p>
      <ul>
        <li>BNB Chain’s daily NFT sales volume touched the $1 million mark on 14 June after nearly two months.</li>
        <li>BNB was trading green at the time of publication, showing signs of recovery.</li>
      </ul>
    	<p>The BNB Chain [BNB] added yet another non-fungible token (NFT) collection to its arsenal, solidifying its position as one of the largest NFT ecosystems.</p>	
    	<p>BNB Chain took to Twitter to announce the release of Hyena Warriors, the sequel collection to Tiger Warriors and the second in a series of hand drawn NFT collections from the Planet ZUUD brand.</p>	
    	<p>According to data from CryptoSlam, the collection recorded a total sales volume of $65,296 until press time, with 378 users already owning several of its collectibles.</p>
      <h3>A rebound in NFT activity</h3>
      <p>Pretty much like the rangebound price action of several top crypto assets in the market, BNB’s NFT trading activity stagnated in May.</p>
      <p>However, activity increased in June, with the daily sales volume breaking the $1 million mark for the first time in nearly two months on 14 June. This also marked a 26% increase in weekly sales volume.</p>
      <p>The weekly trade count also jumped by 48%. This was a clear indicator that the trading activity was driven by an increase in overall transactions rather than a few high-value transactions driving up the volume.</p>
      <p>At the time of writing, BNB Chain was the fourth-largest layer-1 (L1) blockchain in terms of NFT sales volume, trailing by market leader Ethereum [ETH], Bitcoin [BTC] and Solana [SOL].</p>
      <p>The Binance ecosystem has been taking proactive steps in making the network appealing to NFT trading. It recently announced support for Bitcoin NFT on its dedicated NFT marketplace platform.</p>
      <h3>Network traffic slows down</h3>
      <p>The jump in NFT sales volume came despite a marked fall in the BNB Chain’s user base. As per Token Terminal, the weekly active user count dropped by more than 5% from the previous week.</p>
      <p>As users stayed away, the network traffic declined considerably. The network’s weekly transaction fees have been progressively declining since the last week of May, and registered a negative growth rate of nearly 17% from the previous period.</p>
      <h3>BNB sees hope</h3>
      <p>The native token of the ecosystem, BNB, was trading the green at the time of publication, gaining 4.88% in the 24-hour period, according to Santiment. The beleaguered token was trying to gain some ground after the U.S. Securities and Exchange Commission’s (SEC) legal salvo fired towards parent company Binance saw its value plummet 18%.</p>
      <p>The coin’s weighted sentiment dipped in the negative territory, reflecting the pessimism of investors.</p>
    </div>
  },
  {
    id: 571,
    title: 'Solana brings some good news to investors as…',
    category: 'Crypto',
    subCategory: ['Solana', 'Investors', 'news'],
    description: <div className="blog-desc">
      <p>Solana’s activity surged with an increase in new contracts on the network.</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 18, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-692.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Solana’s activity surged with an increase in new contracts on the network.</p>
      <ul>
        <li>Solana experiences surge in new contracts, indicating growing adoption and utilization of the network.</li>
        <li>NFT trading volume decreases, but stakers show continued interest as Solana’s price sees recovery.</li>
      </ul>
    	<p>Despite facing multiple network outages and witnessing prominent NFTs bridging to other networks, Solana has demonstrated remarkable resilience and managed to attract a significant number of users to its platform.</p>
      <h3>Solana continues to show growth</h3>
    	<p>According to the latest data from Artemis, new contracts deployed to Solana have reached a year-to-date high.</p>	
    	<p>This signifies substantial growth and heightened activity within the Solana ecosystem, showcasing the increased adoption and utilization of the Solana blockchain for various decentralized applications (dApps) and smart contract development. Such a trend suggests a vibrant developer community and a growing interest in building on the Solana network.</p>
      <p>However, while the number of contracts being deployed on Solana is on the rise, the DeFi sector has not witnessed equivalent growth. Artemis’ data highlights a noticeable decline in the Total Value Locked (TVL) of Solana over the past few days.</p>
      <p>Additionally, the volumes of decentralized exchanges (DEXs) on Solana have also experienced a significant decrease, indicating a waning interest in Solana’s DEXs.</p>
      <p>In the NFT sector, the volume of Solana NFTs being traded has seen a drastic fall. Despite this decline, the total floor value of Solana NFTs has remained relatively stable, with no substantial depreciation observed.</p>
      <h3>Court of the law and public opinion</h3>
      <p>The reduction in NFT trading volume could be attributed to the prevailing negative sentiment surrounding Solana within the crypto community. Santiment’s data reveals a significant decrease in the weighted sentiment, suggesting that negative comments currently outweigh positive ones.</p>
      <p>One potential factor contributing to the declining sentiment might be the SEC lawsuit against Binance. Furthermore, the recent delisting of SOL from the Bakkt exchange has added to the negative sentiment, potentially leading to further price impact if more exchanges follow suit.</p>
      <p>Despite these challenges, stakers have continued to show interest in SOL.</p>
      <p>According to data provided by Staking Rewards, over the last month, the number of users staking SOL has increased by 2.84%. Nevertheless, Solana’s price showed signs of recovery.</p>
      <p>At present, SOL is trading at $15.89, representing a 24-hour price appreciation of 7.89% according to CoinMarketCap.</p>
    </div>
  },
  {
    id: 570,
    title: 'Fall in Shiba Inu’s burn rate fuels these burning Qs',
    category: 'Crypto',
    subCategory: ['Shiba', 'Inus', 'Fules'],
    description: <div className="blog-desc">
      <p>Shiba Inu’s burn rate dwindles as trading volume declines, raising concerns. However, amidst it all, active addresses soar, and prices climb.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 17, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-691.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Shiba Inu’s burn rate dwindles as trading volume declines, raising concerns. However, amidst it all, active addresses soar, and prices climb.</p>
    	<ul>
        <li>Shiba Inu’s 7-day burn rate declined to -84%.</li>
        <li>SHIB’s price has seen consecutive increases in value in the past three days.</li>
      </ul>
      <p>Shiba Inu (SHIB) has been experiencing notable progress in various important areas, especially its price. As the price of SHIB has been on the rise, it reflected positive movement in several key metrics. However, amidst these upward trends, one particular metric has witnessed negative returns over the past few days. A cause for worry?</p>	
    	<h3>Shiba Inu’s burn rate declines</h3>
      <p>Recent data revealed a noticeable decline in the burn rate of Shiba Inu. Information shared by Shibburn, a SHIB burn rate tracker, indicated that the burn rate decreased by more than 85% within the past seven days.</p>	
    	<p>Additionally, according to the Shibburn site, only 360,222 SHIB tokens were burned in the past 24 hours, resulting in an almost -84% burn rate. Also, over 22 hours have lapsed since the last burn. This current burn rate departs from the consistent millions of SHIB tokens burned in previous instances.</p>
      <p>Furthermore, a closer examination of the volume metric on Santiment sheds light on a potential cause of the burn rate decline. Analysis of the volume chart indicated a decline in SHIB trading volume.</p>
      <p>As of this writing, the volume stood at approximately 64.6 million. It represented a significant decrease from the over 100 million volumes recorded around 11 June. This decline in volume implied a decrease in the number of SHIB transactions, thus contributing to the decrease in the burn rate.</p>
      <h3>Active addresses surge</h3>
      <p>Despite the decline in burn rate and trading volume, Shiba Inu experienced a notable increase in active addresses. According to the seven-day active addresses metric, SHIB active addresses began trending upward around 6 June, reaching the highest level in the past two months. As of this writing, there were over 77,000 active addresses.</p>
      <p>Furthermore, there was a significant surge in daily active addresses on 15 June, surpassing 42,000. Before that, the daily active addresses hovered around 3,000. As of this writing, it decreased to approximately 8,200, which still exceeded the levels observed in April and May.</p>
      <h3>Shiba Inu sees consecutive price rise</h3>
      <p>Shiba Inu has recently experienced a series of positive price movements on its daily timeframe chart. As of this writing, it gained nearly 1% in value, marking the third consecutive day of price increase.</p>
      <p>However, it was still in a bearish trend, as indicated by the Moving Average Convergence Divergence (MACD). As of this writing, the MACD remained below 0, signaling a persistent bearish trend despite the ongoing uptrend in price.</p>
    </div>
  },
  {
    id: 569,
    title: 'What does the future of Bitcoin depend on',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Future', 'crypto'],
    description: <div className="blog-desc">
      <p>Bitcoin’s sluggish journey and bearish trend might give way to a bullish surge, depending on the actions of key holders and critical metrics, says a CryptoQuant report.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 17, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-689.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin’s sluggish journey and bearish trend might give way to a bullish surge, depending on the actions of key holders and critical metrics, says a CryptoQuant report.</p>
    	<ul>
        <li>Investors holding 1,000-10,000 BTC and US institutional holders declined in recent months.</li>
        <li>BTC’s price remained below SMA as the bear trend persisted.</li>
      </ul>
      <p>Bitcoin’s [BTC] price has been crawling at a snail’s pace, and the dominant direction remained bearish over an extended period. Nevertheless, a potential surge towards a bullish trend could potentially materialize, but it hinges on the actions of two distinct groups of holders, as highlighted in a recent report by CryptoQuant.</p>
      <h3>Bitcoin holders stall the bull trend</h3>	
    	<p>A recent report by CryptoQuant suggested that the current Bitcoin trend could potentially transition into a bullish phase, contingent upon the upturn of two key metrics.</p>	
    	<p>The first metric, known as the UTXO value bands, indicated a decrease in BTC holdings for certain groups of investors. These bands provide insights into the distribution of BTC holdings among different categories.</p>
      <p>Historically, BTC prices have surged when large investors, often called whales, have accumulated substantial amounts of BTC.</p>
      <p>Notably, the rise in the 1,000–10,000 BTC holding group has significantly triggered price increases. However, the latest data reveals a deviation from the previous pattern, as the quantity of BTC held by this group has not been increasing as of this writing.</p>
      <p>Furthermore, another metric, the Geographical Supply Distribution by Entities, sheds light on the holdings of Bitcoin by institutions in the United States.</p>
      <p>Since April 2021, there has been a decline in BTC holdings among US institutions. Interestingly, this decrease persists despite the 100% rise in BTC prices this year, implying a lack of capital inflow from US institutions.</p>
      <h3>Bitcoin’s poor run continues</h3>
      <p>Examining the daily timeframe chart of Bitcoin indicated that the press time downtrend would continue. As of this writing, Bitcoin was trading at approximately $25,600, experiencing a marginal loss of less than 1%.</p>
      <p>The price had descended below its short Moving Average (yellow line), which acted as a resistance level at around $27,000.</p>
      <p>Additionally, the Relative Strength Index (RSI) indicated a persistent bearish trend. The RSI stood at 40, indicating a move away from the oversold zone but still maintaining a strong bearish momentum.</p>
      <h3>MVRV presents mixed opportunities</h3>
      <p>Following a period of profitability observed in March and April, Bitcoin holders have encountered a phase of undervaluation concerning their holdings. This is reflected in the 30-day BTC Market Value to Realized Value (MVRV) ratio, which indicated that the asset was undervalued.</p>
      <p>As of this writing, the MVRV ratio stood at -2.87%, signifying the extent of loss experienced by BTC holders. However, this metric also suggested that there existed an opportunity for accumulation and the potential for a subsequent price increase.</p>
    </div>
  },
  {
    id: 568,
    title: 'Newly Released Documents Detail Tether’s Exposure to Chinese Securities',
    category: 'Crypto',
    subCategory: ['Tether', 'Chinese', 'Securities'],
    description: <div className="blog-desc">
      <p>Financial documents released by New York’s Attorney General show that Tether was backing its USDT stablecoin in part with Chinese commercial paper as of 2021.</p>
    </div>,
    authorName: 'Samuel Haig',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 17, 2023 - 3:09 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-670.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Financial documents released by New York’s Attorney General show that Tether was backing its USDT stablecoin in part with Chinese commercial paper as of 2021.</p>
    	<p>The documents, which were obtained by a Freedom of Information Law request made by CoinDesk and Bloomberg, also provide details on Tether’s banking partners and on a $5.1B loan program.</p>	
    	<p>The disclosed information helps understand the composition of reserves backing the world’s largest stablecoin. Concerns that USDT, which is pegged at $1 to the dollar, isn’t fully backed have long loomed over Tether.</p>	
    	<p>In February 2021 Tether reached a settlement with the NY AG over allegations that it didn’t hold enough reserves to back USDT at one-to-one, while stating it did, It reported its financials as part of that settlement.</p>
      <p>Tether dropped its initial opposition to news organizations’ FOIL request.</p>
      <p>Investigations by Bloomberg and CoinDesk found that Tether’s 2021 reserves included securities issued by Deutsche Bank, Barclays Bank, and several prominent state-owned Chinese companies, including China Construction Bank Corp. and Agricultural Bank of China Ltd. It also held billions in BTC and USD on its sister exchange Bitfinex and in cold storage.</p>
      <p>The documents also show Tether operated a $5.1B USDT lending program as of March 31, 2021.</p>
      <h3>Different Position</h3>
      <p>“Tether is in a completely different position compared to 2 years ago,” the company said in a blog post. “It demonstrated, leading the industry during the biggest black swan events in 2020 and 2022, that its reserves are extremely liquid, of high quality and ready to be made available to support any size of redemption.”</p>
      <p>Tether also said it reduced commercial paper from its reserves in mid-2022, and cut its secured loans portfolio. The company said it aims to eliminate loans from its balance sheet in the coming months, and that its commercial papers always maintained a rating of at least A2.</p>
      <p>The company added that its attestation report from last quarter showed a record net profit of $1.5B and surplus reserves of $2.4B. The report also said $53B or 64% of its reserves comprised U.S. treasury holdings at the end of Q1 2023.</p>
      <h3>Tether Backstory</h3>
      <p>Tether emerged in November 2014 after rebranding from Realcoin, which launched four months earlier.</p>
      <p>Realcoin was initially launched by Brock Pierce, but Giancarlo Devasini and J.L Van Der Velde, the CFO and CEO of the centralized exchange Bitfinex respectively, took over as the directors of Tether Limited in September.</p>
      <p>Bitfinex became mired in controversy after losing $72M worth of BTC to hackers in 2016 and socializing losses in the form of a 36% haircut to all user balances. Bitfinex issued BFX tokens representing the balance of users’ losses, which Bitfinex progressively refunded for USD at random intervals.</p>
      <p>In 2017, Bitfinex, then the leading crypto exchange by trade volume, was cut off from its U.S. banking partners after Wells Fargo stopped processing transfers from Taiwanese partners on behalf of the exchange. The events left Bitfinex and Tether scrambling for banking partners, reportedly working with small banks in Europe and Panama.</p>
      <p>In April 2018, local media reported that Polish law enforcement seized 400M EUR from a small bank associated with Bitfinex. The funds were seized from Crypto Capital, a mysterious firm providing shadow banking services to several centralized exchanges including Bitfinex, Binance, and QuadrigaCX.</p>
      <p>Bitfinex went on to suffer persistent liquidity issues through 2018 and 2019, attributing its woes to Crypto Capital’s mishandling of its funds.</p>
      <p>In April 2019, New York Attorney General Letitia James obtained a court order against IFinex, the parent company of Bitfinex and Tether. The order alleged Bitfinex commingled Tether’s and its assets to cover the $850M shortfall caused by law enforcement freezing Crypto Capital’s assets.</p>
    </div>
  },
  {
    id: 567,
    title: 'Bitcoin remains above US$25,000; NFT sales down; Asian, European markets gain with U.S. futures mixed',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'NFT', 'Markets'],
    description: <div className="blog-desc">
      <p>Bitcoin rose in Friday afternoon trading in Asia, a day after asset management firm BlackRock provided a timely fillip to the digital asset industry by filing to launch an exchange-traded fund (ETF) for the cryptocurrency.</p>
    </div>,
    authorName: 'Pradipta Mukherjee',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 16, 2023 - 3:21 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-671.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin rose in Friday afternoon trading in Asia, a day after asset management firm BlackRock provided a timely fillip to the digital asset industry by filing to launch an exchange-traded fund (ETF) for the cryptocurrency.</p>
    	<p>Ether and most other top 10 non-stablecoin cryptos rose, with the exception of Polygon and Tron. Meanwhile, global markets are seeing some upside after the U.S. Federal Reserve’s decision to pause interest rate hikes.</p>
      <h3>Bitcoin receives timely boost</h3>	
    	<p>Bitcoin rose 2.43% to US$25,515 in the 24 hours to 4 p.m. in Hong Kong, but lost 3.70% over the last seven days, according to CoinMarketCap data. The world’s biggest cryptocurrency also saw its market capitalization rise 2.40% to US$495.71 billion in the last 24 hours.</p>	
    	<p>Some of that positive movement came on the back of the announcement late Thursday that the world’s biggest asset manager, BlackRock, intends to launch the first publicly traded U.S.-based Bitcoin exchange-traded fund (ETF). Coinbase Custody Trust Co. will act as the custodian for the company’s Bitcoin.</p>
      <p>The ETF, if approved, will be listed on the Nasdaq stock exchange.</p>
      <p>Bitcoin dropped to US$24,909 on Thursday afternoon in Asia for the first time since March 17 this year. It then rallied to a high of US$25,700 in the hour after the BlackRrock announcement, before reverting back to the US$25,500 mark.</p>
      <p>Ether, the second-biggest cryptocurrency by market capitalization, also rose 2.12% to US$1,669 over the past 24 hours, but is down 9.09% for the week.</p>
      <p>Litecoin was the biggest gainer in the last 24 hours, rising 3.62% to US$75.22, although it is down 14.09% on the week.</p>
      <p>Polygon and Tron were the only losers among top 10 non-stablecoin cryptos. Polygon’s Matic token dropped 4.07% to US$0.5896, bringing its weekly losses to 23.85%.</p>
      <p>Tron lost 1.95% to US$0.06986 in 24 hours and 9.81% over the last week.</p>
      <p>BNB, the native token of world’s biggest crypto exchange Binance, rose 1.43% to US$239.19,  but its weekly losses stood at 7.36%.</p>
      <p>“Binance Coin (BNB) is testing the critical US$220/200 level and IF this level breaks, there could be severe consequences on sentiment,” said Markus Thielen, Head of Research & Strategy at digital asset service platform Matrixport, in an emailed statement.</p>
      <p>The U.S. SEC filed lawsuits against crypto exchanges Binance.US and Coinbase in the week of June 5. Doing so, it named several altcoins as financial securities, including Solana, Cardano, Polygon and BNB.</p>
      <p>“There is now a real possibility that the altcoins from the 2020/21 bull market will never reach new all-time highs again,” Thielen said.</p>
      <p>“Unless the investor is a trader with risk management skills, parking their crypto exposure in Bitcoin might be the only sensible option until a new bull market emerges that carries more weight than simply favorable macro tailwinds, and until the regulatory overhang diminishes,” he added.</p>
      <p>U.S.-based trading platform Robinhood will delist Cardano, Polygon and Solana from June 27, after the SEC labeled them as unregistered securities. This has caused their prices and market capitalization to take a beating.</p>
      <p>The three tokens combined had a market capitalization of US$20.6 billion as of Friday afternoon in Asia. That figure is a drop of 29.4% from the US$29.18 billion prior to the SEC’s lawsuits. The severe drop is a sign of  liquidity leaving the industry.</p>
      <p>“Early in the week, there was hope that the back-to-back U.S. CPI (consumer price index) and Fed rate pause would boost the markets, but that did not happen,” said Le Shi, Head of Trading at crypto trading firm Auros.</p>
      <p>“A clear divergence in the fortunes of U.S. equities compared to the crypto market after these events made it clear that traders were more concerned with regulatory risk than any expected macro tailwinds arising from the Fed rate pause or softening CPI,” Shi added.</p>
      <p>The global crypto market capitalization rose 1.96% to US$1.04 trillion, while total market volume dropped 14.98% to US$32.52 billion in the last 24 hours.</p>
      <h3>NFTs stay down</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index dropped 0.84% to 2,883.08 in the past 24 hours, bringing its weekly losses to 10.48%.</p>
      <p>The Forkast ETH NFT Composite also dropped 0.96% to 1,019.86 in 24 hours, and lost 7.30% over the last week. Transactions on Ethereum, the leading NFT blockchain, dropped 10.10% to US$13.25 million in the last 24 hours. In the same period, transactions on the Bitcoin network, the second largest NFT blockchain, plunged 10.52% to US$1.42 million.</p>
      <p>On Thursday, global auction house Sotheby’s finished its second round of sales of NFTs from the “Grails” collection. The auction saw US$10.9 million in sales, over half of which was from Dmitri Cherniak’s Ringers #879 – often referred to as “The Goose” – which sold for US$6.2 million.</p>
      <p>“Sentiment is rather high following the success of Sotheby’s auction of historic NFTs yesterday, and combined with news that BlackRock is filing for a Bitcoin ETF, there’s hope this can inject life back into NFTs,” said Yehudah Petscher, NFT Strategist at Forkast Labs.</p>
      <p>“It’s worth noting that the supposed buyer was already a major NFT trader, so this auction didn’t seem to draw attention from the traditional art world,” Petscher said about the auction.</p>
      <p>“Is it bullish still for NFT art? Yes, but there’s still always concern that the NFT ecosystem is largely an echochamber,” he added.</p>
      <p>Elsewhere, despite the latest round of legal charges leveled against former U.S. President Donald Trump, the Trump Digital Trading Cards project is gaining momentum. It has begun redeeming prize NFTs in exchange for physical prizes.</p>
      <p>The prizes include signed books, gift cards, golf tickets, zoom calls with the former president and even a gala dinner at Trump’s Florida home Mar-a-Lago.</p>
      <h3>Asian, European equities rise; U.S. stock futures mixed</h3>
      <p>Asian equity markets rallied on Friday with sense that U.S. interest rates could be reaching their peak. Markets were also optimistic after China’s central bank lowered medium term lending rates on Thursday.</p>
      <p>The Bank of Japan (BOJ) on Friday decided to maintain its ultra-loose monetary policy. As widely anticipated, the central bank will leave its short-term interest rate unchanged at -0.1% and 10-year bond yields at around 0%.</p>
      <p>“With extremely high uncertainties surrounding economies and financial markets at home and abroad, the Bank will patiently continue with monetary easing while nimbly responding to developments in economic activity and prices as well as financial conditions,” the BOJ said in its statement on monetary policy released Friday.</p>
      <p>By the close of afternoon trading, the Shanghai Composite had gained 0.63% while the Shenzhen Component Index rose 1.11%. Hong Kong’s Hang Seng Index climbed 1.07% and Japan’s Nikkei 225 strengthened 0.66%.</p>
      <p>European equity markets gained on Friday as investors digested the recent interest rate decisions from major central banks. The European Central Bank (ECB) raised interest rates by 25 basis points on Thursday to 3.5%, while both the ECB and the U.S. central bank suggested more rate hikes are on the way later in the year.</p>
      <p>The benchmark STOXX 600 gained 0.45% and Germany’s DAX 40 climbed 0.16% during afternoon trading hours in Europe.</p>
      <p>U.S. stock futures were trading flat as of 6.30 p.m. in Hong Kong. The Dow Jones Industrial Average futures gained 0.01%, and S&P 500 futures dropped 0.01%. Nasdaq 100 Futures gained 0.03%.</p>
      <p>Interest rates in the world’s biggest economy remain at 5% to 5.25% after the U.S. Federal Reserve decided against a further raise at its June meeting earlier this week. The rate is at its highest since 2006 after ten consecutive hikes that started in March 2022. The hikes were made to combat persistent inflation, which had pushed to a 40-year high at one point last year.</p>
      <p>“The Fed’s primary goal is price stability. With that goal in mind and inflation where it currently stands, a rate hike would have been the more appropriate choice,” Michiel Janssen, trader and analyst at crypto hedge fund AltTab Capital, said in an email statement.</p>
      <p>“But the Fed is also taking into account the time the market needs to adjust to the higher rates. The banking failures are proof that the fastest ever increase in the Fed rates are causing stresses in the financial system,” Janssen said.</p>
      <p>The next Federal Open Market Committee meeting is scheduled for July 25 to 26. The U.S. central bank has signaled two more rate hikes by the end of 2023, to bring inflation down to its target level of 2%.</p>
      <p>“The Fed will indeed raise rates in July, with the obvious caveat that a deterioration in inflation or employment data could make the Fed more inclined to hold rates steady for another meeting,” said Frances Donald, Chief Economist at wealth management firm Manulife Investment Management, in an emailed statement.</p>
      <p>“We have fairly high conviction that U.S. headline inflation will fall to around 3% by year end… we think the more important question for investors is how and when the central bank will respond to the expected slowdown in U.S. growth,” Donald added.</p>
    </div>
  },
  {
    id: 566,
    title: 'BlackRock files for spot Bitcoin ETF, taps Coinbase as custodian',
    category: 'Crypto',
    subCategory: ['BlackRock', 'Coinbase', 'Bitcoin'],
    description: <div className="blog-desc">
      <p>BlackRock, which manages about US$10 trillion as the world’s biggest asset manager, has filed to launch the first publicly traded spot Bitcoin exchange-traded fund (ETF) in the U.S.,</p>
    </div>,
    authorName: 'Timmy Shen',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 16, 2023 - 9:24 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-673.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>BlackRock, which manages about US$10 trillion as the world’s biggest asset manager, has filed to launch the first publicly traded spot Bitcoin exchange-traded fund (ETF) in the U.S., even as regulators in the country file lawsuits against cryptocurrency platforms for alleged violations of securities laws.</p>
    	<p>BlackRock said in a Thursday filing to the Securities and Exchange Commission (SEC) it intends to launch the “iShares Bitcoin Trust” with Coinbase Custody Trust Co. as the custodian for the Bitcoin. The ETF, if approved, will be listed on Nasdaq.</p>	
    	<p>The application comes after the SEC last week sued Coinbase and Binance.US., two of the industry’s biggest crypto exchanges, for allegedly breaching securities rules. That adds to uncertainties the SEC will approve the ETF, given that a Coinbase unit would act as custodian for the product.</p>	
    	<p>Eric Balchunas, a senior ETF analyst at Bloomberg, tweeted Thursday that the BlackRock filing is a “shocker.”</p>
      <p>“There’s been no signs at all SEC willing to approve, but BlackRock is very connected so maybe they know something?” Balchunas said.</p>
      <p>Approval of a spot-traded Bitcoin ETF in the world’s biggest economy and largest financial market is seen by many investors as a stamp of approval for the digital currency and would likely unlock significant amounts of institutional investment in Bitcoin.</p>
      <h3>Chokepoint?</h3>
      <p>However, some in the crypto industry reacted negatively to the BlackRock development, raising “Operation Chokepoint” theories that suggest governments and traditional finance aim to derail the industry because it poses a threat to their interests.</p>
      <p>“If BlackRock’s spot ETF application gets approved, it is undeniable that Operation Chokepoint 2.0 was orchestrated to drive out crypto native companies and bring in large traditional firms that are buddy-buddy with the U.S. [government] to try and control Bitcoin [and] crypto,” Will Clemente, co-founder of digital asset research firm Reflexivity Research, said Thursday on Twitter to his 683,700 followers.</p>
      <p>In the SEC filing, BlackRock said: “The Shares are intended to constitute a simple means of making an investment similar to an investment in bitcoin rather than by acquiring, holding and trading bitcoin directly on a peer-to-peer or other basis or via a digital asset exchange,”</p>
      <p>The SEC has previously rejected similar applications for spot Bitcoin ETFs filed by Grayscale, WisdomTree and VanEck. In June 2022, Grayscale sued the SEC for rejecting its application, saying the regulator failed to apply consistent treatment to similar investment vehicles.</p>
      <p>The securities regulator, however, has approved a number of Bitcoin futures-based ETFs.</p>
      <h3>Complex</h3>
      <p>Traditional retail exchanges and brokers, such as Robinhood and eToro, are “navigating complex terrain when venturing into crypto,” Jeff Feng, co-founder of Sei Labs, the company behind the Sei trading blockchain, told Forkast via email.</p>
      <p>“The absence of explicit regulatory guidelines has caused setbacks, leading to some tokens being delisted resulting in crypto market volatility,” said Feng, a former Goldman Sachs analyst. “Nonetheless, these teething issues are part of the broader industry’s evolutionary process.”</p>
      <p>Balchunas at Bloomberg added that the BlackRock filing lists the factors that could adversely affect the price of Bitcoin, and “they list the exchanges, which ‘are largely unregulated and may be subject to manipulation’ which is the SEC’s issue with approving ETF.”</p>
      <p>Bitcoin gained 1.89% over the past 24 hours to trade at US$25,541 at 11:50 a.m. in Hong Kong, according to data from CoinMarketCap. The world’s largest cryptocurrency by market capitalization has risen 54.5% so far this year, but is still far below its peak at around US$64,000 recorded in November 2021.</p>
    </div>
  },
  {
    id: 565,
    title: 'Bitcoin edges up after slump, Ether little-changed, other top 10 tokens continue bumpy ride',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Ether', 'tokens'],
    description: <div className="blog-desc">
      <p>Bitcoin rose in Friday morning trading in Asia, after falling below US$25,000 overnight and overall posting a losing week, a trend mirrored by Ether.</p>
    </div>,
    authorName: 'Danny Park',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 16, 2023 - 5:24 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-672.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin rose in Friday morning trading in Asia, after falling below US$25,000 overnight and overall posting a losing week, a trend mirrored by Ether. The rest of the top 10 non-stablecoin cryptocurrencies were mixed after finding little impetus from the U.S. Fed decision in the week to leave interest rates unchanged. Polygon’s Matic token had the biggest decline in the top 10 amid sharp falls in altcoins since the U.S. regulator last week labeled many of them illegally issued financial securities.</p>
      <h3>Bumpy week</h3>
    	<p>Bitcoin added 2.05% over the last 24 hours to US$25,573.56 at 6:30 a.m. in Hong Kong, but lost 3.83% in the last seven days, according to CoinMarketCap data. The world’s largest cryptocurrency, with a market capitalization of about US$497 billion, slipped to US$24,797.17 at around 8:00 p.m. on Thursday, the lowest since March 16.</p>	
    	<p>Ether traded little changed at US$1,665.73, up a slight 0.78%, but posting a weekly loss of 10.06%.</p>	
    	<p>Solana led gains among the top 10 tokens by market capitalization, rising 3% to US$14.88, but still down 21.58% for the past seven days. The Solana community on Twitter is throwing around the idea of a hard fork to avoid further scrutiny from U.S. regulators, but such an idea is not being discussed among Solana developers, according to Decrypt.</p>
      <p>Polygon’s Matic was the biggest loser out of the top 10, dropping 4.06% to US$0.5952 to bring the week’s loss to 24.06%.</p>
      <p>Most of the top 10 have been on a roller-coaster ride since the U.S. Securities and Exchange Commission’s (SEC) filed lawsuits against crypto exchanges Binance.US and Coinbase in the week of June 5. The SEC also named dozens of altcoins as financial securities, including Solana, Cardano, Polygon and BNB.</p>
       <p>Those four tokens combined had a market capitalization of US$57.5 billion as of Friday morning in Asia, which is a sharp 26.5% slump from the US$78.27 billion prior to the SEC’s lawsuits.</p>
       <p>“Many notable altcoins, such as Matic, were listed as securities in recent SEC lawsuits, so venture capital firms have been selling their altcoin holdings to capture the little liquidity left and lessen the blow to their portfolios,” said Nick Ruck, the chief operating officer of blockchain venture studio ContentFi Lab, in a Telegram message to Forkast.</p>
       <p>“Institutions are managing portfolio risk and preparing for a continued downtrend amid less market liquidity and tighter regulations,” he said.</p>
       <p>In related moves, U.S.-based trading platform Robinhood delisted Cardano, Polygon and Solana, while the eToro platform halted user purchases of Polygon, Decentraland, Algorand and Dash — which were all named in the SEC’s legal filings.</p>
       <p>The crypto market seemed to find little relief in the move this week by the Federal Reserve to pause interest rate increases.</p>
       <p>“This pause, or skip seems to be a more tactical move, but the fight against inflation is not over yet,” said Michiel Janssen, trader and analyst at crypto hedge fund AltTab Capital, in an email statement shared with Forkast.</p>
       <p>“For crypto this means in the short term that there is less money flowing into the crypto markets. In the longer run, higher inflation and further problems in the banking sector can be a positive for crypto.”</p>
       <p>The total cryptocurrency market cap increased 1.21% to US$1.04 trillion in the last 24 hours, while crypto trading volume fell 18.55% to US$37.82 billion, according to CoinMarketCap data.</p>
       <h3>“Grails” sales hit US$17 million</h3>
       <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
       <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index lost 1.39% to 2,884.87 in the 24 hours to 7:30 a.m. in Hong Kong. The index is down 10.46% for the week.</p>
       <p>Total NFT transactions fell 25.96% to US$18.58 million. Transaction on Ethereum, the leading NFT blockchain, dropped 10.50% to US$13.2 million.</p>
       <p>Transactions on the Bitcoin network, the second largest NFT blockchain, plunged 72.91% to US$1.12 million.</p>
       <p>On Thursday, global auction house Sotheby’s finished its second round of sales of NFTs from the “Grails” collection. “Grails” consists of non-fungible token (NFT) artwork that belongs to the bankrupt crypto hedge fund Three Arrows Capital and its NFT-purchasing fund Starry Night Capital.</p>
       <p>Singapore-based Three Arrows Capital, a crypto hedge fund that once managed as much as US$10 billion, filed for Chapter 15 bankruptcy in July 2022, which protects assets of insolvent foreign debtors from U.S. creditors.</p>
       <p>In February this year, Three Arrows Capital’s U.S.-based liquidator Teneo said that it plans to sell NFTs owned by the company with an estimated value of US$22 million at the time of the bankruptcy filing.</p>
       <p>The second auction brought in nearly US$11 million in sales, including Dmitri Cherniak’s “The Goose” or Ringers #879, which was sold for US$6.2 million alone. The collection has raised a running total of US$17 million in Sotheby’s sales.</p>
       <p>“While the NFT community is excited by today’s major art sales from the Sotheby’s auction, that isn’t money that will flow back into NFTs,” said Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News. “These were seized NFTs from 3AC and that money will go to recouping losses.”</p>
       <p>In other transactions, the NFT staple Bored Ape Yacht Club topped sales by collection, selling US$1.11 million in the last 24 hours.</p>
       <p>Mythos chain-powered DMarket game NFTs came second with US$899,694 in sales, with over 51,000 transactions in the past day.</p>
       <h3>U.S. stock futures drop after Thursday rally</h3>
       <p>U.S. stock futures fell as of 10:00 a.m. in Hong Kong after rallying during the regular trading day on Thursday. Dow Jones Industrial Average futures fell 0.15%, S&P 500 futures edged 0.21% lower and Nasdaq Futures lost 0.33%.</p>
       <p>The Dow rose 1.26% on Thursday, S&P gained 1.22% and the Nasdaq Composite added 1.15% as optimism grew that the Federal Reserve’s interest rate hikes are near the peak, according to Trading Economics.</p>
       <p>The U.S. central bank left interest rates unchanged at 5% to 5.25% after its meeting on Wednesday. The rate is at its highest since 2006 after ten consecutive hikes that started in March 2022 to slow the pace of inflation that surged to 40-year highs at one stage last year. The central bank warned of two more rate hikes by the end of 2023.</p>
       <p>The European Central Bank raised its interest rates for the eighth consecutive time on Thursday, adding 25 basis points.</p>
       <p>The next Federal Open Market Committee meeting is scheduled for July 25 to 26.</p>
       <p>The CME FedWatch Tool predicts a 28.1% chance the Fed will leave rates as is at July’s meeting, while 71.9% says the Fed will raise by 25 basis points.</p>
    </div>
  },
  {
    id: 564,
    title: 'Bitcoin: Expecting BTC to rally after FOMC’s latest update? Well…',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'BTC', 'FOMC'],
    description: <div className="blog-desc">
      <p>Bitcoin [BTC] didn’t exactly see any significant price pump after the FOMC meeting. In fact, BTC’s fear and greed index indicated a sentiment of fear around the cryptocurrency which could delay a rally in the coming days.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 15, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-664.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin [BTC] didn’t exactly see any significant price pump after the FOMC meeting. In fact, BTC’s fear and greed index indicated a sentiment of fear around the cryptocurrency which could delay a rally in the coming days.</p>
    	<ul>
        <li>Bitcoin was up by 0.37% before the FOMC meeting results.</li>
        <li>The U.S. Federal Reserve held its federal funds interest rate at 5.25%, following an extended period of consecutive rate hikes.</li>
      </ul>
      <p>The eyes of the market were on the Fed rate decision announcement from the Federal Open Market Committee (FOMC) meeting. The cryptocurrency market on the whole has experienced a turbulent few weeks. As such all eyes were fixed on the decision that would lead to price pump or dump.</p>	
    	<p>Bitcoin [BTC] was seen to top the charts in social engagement ahead of the looming FOMC decision. A look at the 24-hour price action prior to the announcement revealed a price surge of 0.37%. </p>	
    	<p>In the aftermath of the FOMC meeting with no hike in interest rates, BTC didn’t stay in the green for long. The king of cryptocurrencies slipped to trading in the red and was exchanging hands 3.34% lower in the last hour of the time of writing.</p>
      <h3>Bitcoin price reaction: Before meets after</h3>
      <p>Following the announcement, BTC didn’t exactly witness a significant price pump or dump. The cryptocurrency hovered between $25,800 and $26,000. It traded for $26,000 minutes before the Fed’s announcement, before falling to $25,756 moments afterward. BTC exchanged hands at $25,909 at press time according to TradingView.</p>
      <p>The Relative Strength Index (RSI) oscillated below the neutral 50 level. This was a sign of limited buying pressure and pointed towards a slightly bearish momentum. However, it could also be taken as a sign that buyers and sellers were battling at a key area. Furthermore, a look at the Moving Average Convergence Divergence (MACD) highlighted a bearish stance for BTC.</p>
      <p>At press time, the signal line (red) was above the MACD line (line), an indication of the ongoing bearish bias. Furthermore, the price move did nothing to recover the losses incurred last week. Recall that the SEC sued two of the crypto industry’s largest players (Binance and Coinbase) for securities law violations.</p>
      <p>Furthermore, a look at the Bitcoin Fear and Greed Index didn’t exactly favor the sentiment around the cryptocurrency. As of 14 June, the BTC fear and greed index stood at 46, which indicated fear. This meant that investors witnessed some market skepticism at the time of writing.</p>
      <h3>Time to buy?</h3>
      <p>The news from the FOMC meeting was met with more questions than answers, as some attested to the ongoing price instability in the market. The lingering question remained: to buy or sell?</p>
      <p>To which crypto educator, Budhil Vyas tweeted that the outcome of the FOMC meeting could be bullish and may favor market growth. However, the same may happen over a period of time and sudden movements may not be on the cards.</p>
      <p><strong>It should be a Positive as #FOMC didn’t raise it. NO HIKE But due to market negative situation it might take some time to show a MOVE💯 https://t.co/mgTgVV1Wa</strong></p>
      <p>Budhil Vyas (@BudhilVyas) June 14, 2023</p>
    </div>
  },
  {
    id: 563,
    title: 'Bitcoin mining difficulty rises 2.18% to record amid increasing competition among miners',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'mining', 'rises'],
    description: <div className="blog-desc">
      <p>Bitcoin’s mining difficulty level rose 2.18% on Wednesday to an all-time high. The level changes every two weeks and measures the additional computing power</p>
    </div>,
    authorName: 'Timmy Shen',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 15, 2023 - 7:57 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-665.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin’s mining difficulty level rose 2.18% on Wednesday to an all-time high. The level changes every two weeks and measures the additional computing power a miner has to use to verify transactions on a block. A higher difficulty indicates it is more competitive to mine Bitcoin, reducing profit. The network’s hashrate also increased to a record high.</p>
      <h3>Fast facts</h3>
      <ul>
        <li>The mining difficulty reading came in at 52.35 trillion at block height 794,304 in Wednesday’s adjustment. That follows a 3.4% rise in the previous adjustment on June 1, according to data from BTC.com.</li>
        <li>The difficulty of mining Bitcoin typically rises when more miners are active, which increases the competition for rewards in Bitcoin in return for validating transactions on the network. The higher the difficulty, the less chance a miner has to secure an entire block on the chain, which in turn can reduce a miner’s profitability.</li>
        <li>Mining difficulty adjustments are correlated to changes in hashrate, the level of computing power used for mining.</li>
        <li>“In short, difficulty follows hashrate up or down, and hashrate is driven by the overall profitability of building and operating bitcoin data centers,” Andrew Webber, chief of Digital Power Optimization that works with power producers to deploy Bitcoin mining operations, told Forkast in an emailed response earlier this month. “If Bitcoin spot price goes higher, it means larger profits for miners.”</li>
        <li>Bitcoin’s hashrate was at around 392.6 exahashes per second on Wednesday, an increase from the 375.4 exahashes recorded during the previous adjustment on June 1, data from Blockchain.com shows.</li>
        <li>Bitcoin fell 3.63% to US$25,045 in the last 24 hours to 11:10 a.m. in Hong Kong, but it was up about 51% so far this year, according to data from CoinMarketCap. The world’s largest cryptocurrency by market capitalization lost 5.01% in value over the past seven days.</li>
      </ul>
    </div>
  },
  {
    id: 562,
    title: 'Ethereum Survives AWS Outage Unscathed But Analysts Warn Of Future Incidents',
    category: 'Crypto',
    subCategory: ['Ethereum', 'AWS', 'future'],
    description: <div className="blog-desc">
      <p>Ethereum’s reliance on centralized infrastructure is under scrutiny after some U.S. users of Amazon Web Services, the world’s largest cloud hosting provider, suffered a roughly three-hour outage on Tuesday.</p>
    </div>,
    authorName: 'Samuel Haig',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 15, 2023 - 7:47 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-662.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Ethereum’s reliance on centralized infrastructure is under scrutiny after some U.S. users of Amazon Web Services, the world’s largest cloud hosting provider, suffered a roughly three-hour outage on Tuesday.</p>
    	<p>While Ethereum continued to operate normally despite nearly two-thirds of Ethereum validators relying on cloud hosting services, researchers fear that a similar incident in Europe could have a far greater impact on the network, as many Lido validators could have been affected. Lido is the top liquid staking provider, having amassed more than 35% of staked Ethereum.</p>	
    	<p>“I imagine there would [be] some effect if AWS went down in Europe given how much Lido is in the cloud,” tweeted Evan Van Ness, an Ethereum developer. “Don’t stake with pools who run in the cloud!”</p>	
    	<p>Van Ness said the outage had “zero effect” on Ethereum as it was limited to its US-EAST-1 region. The websites and point-of-sale systems of many traditional businesses were impacted, including United Airlines, Associated Press, and Toast.</p>
      <h3>Centralized Points Of Failure</h3>
      <p>The news comes as concerns regarding the centralization risks posed by some of the infrastructure underpinning the Ethereum network and its staking ecosystem have reached a fever pitch.</p>
      <p>Researchers are desperately calling on Ethereum stakers to either solo stake or use alternative providers after Lido’s share of staked ETH surpassed one-third in recent weeks. Vitalik Buterin, Ethereum’s co-founder and chief scientist, recently prescribed that no single staking pool should control more than 15% of staked Ether.</p>
      <p>Infura, the centralized Ethereum node provider, has also copped flak for emerging as a possible point of failure for the network. Infura responded last September by announcing plans to develop a decentralized version of its service this year. The company said its infrastructure has frequently processed more than 50% of daily Ethereum transactions.</p>
      <p>While Ethereum proved resilient, the outage disrupted the operations of many web3 dApps and services.</p>
      <p>Phantom, a popular Solana wallet, assured its users their assets were safe despite being unable to load the wallet. Players of the web3 game, Illuvium, complained they could not log in amid the outage. Users of Manifold, an NFT minting platform, said they could no longer access its website.</p>
      <p>In December 2021, an AWS outage affecting the same region disrupted the services of top centralized exchanges Binance and Coinbase, and even the decentralized exchange dYdX.</p>
    </div>
  },
  {
    id: 561,
    title: 'Hong Kong Lawmaker Invites Coinbase to Apply to Operate in Region Amid U.S. SEC Crackdown',
    category: 'Crypto',
    subCategory: ['HongKong', 'Coinbase', 'SEC'],
    description: <div className="blog-desc">
      <p>A Hong Kong lawmaker invited global virtual asset trading operators, including crypto exchange Coinbase, to come and register in the region.</p>
    </div>,
    authorName: 'Camomile Shumba',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 12, 2023 - 9:08 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-641.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>A Hong Kong lawmaker invited global virtual asset trading operators, including crypto exchange Coinbase, to come and register in the region.</p>
    	<p>Hong Kong's Securities and Futures Commission (SFC) began accepting applications for crypto trading platform licenses on June 1. Crypto firms which get registered under this license will be able to serve retail customers in the country and will need to comply with rules that prohibit crypto incentives like airdrops.</p>	
    	<p>“I hereby offer an invitation to welcome all global virtual asset trading operators including @coinbase to come to HK for application of official trading platforms and further development plans,” Hong Kong legislative council member Johnny Ng said in a tweet on Saturday.</p>	
    	<p>“Coinbase is dedicated to partnering with high-bar regulators across U.S. and non-U.S. jurisdictions,” a Coinbase spokesperson told CoinDesk, responding to the tweet.</p>
      <p>Ng’s invite comes amid a U.S. crackdown on crypto exchanges like Binance and Coinbase, which have both been sued recently by the U.S. Securities and Exchange Commission (SEC) for allegedly violating federal securities laws.</p>
      <p>Brian Armstrong, Coinbase’s CEO, said in April that his company would consider moving out of the U.S. if there continues to be no regulatory clarity around how crypto is treated, something which the company has been pushing for.</p>
      <p>Coinbase is now looking to expand globally, Tom Duff-Gordon, Vice President of International Policy at Coinbase, said in an interview.</p>
      <p>“Coinbase is really excited to be expanding overseas,” Duff-Gordon said.</p>
      <p>The company is trying to build in Abu Dhabi, Canada and is waiting for approval to register in Singapore, Duff-Gordon added.</p>
      <p>While the company has been operating in the U.K. through CB Payments Limited, which has been authorized and regulated by the Financial Conduct Authority as an electronic money institution, the company hopes to apply for authorization under the U.K.’s new crypto regime once that is finalized, Duff-Gordon said.</p>
      <p>While international expansion is a key focus, Coinbase is not ready to give up on the U.S., according to Duff-Gordon.</p>
      <p>“The easiest thing for us to do would be to cut and run but that's not what we're doing,” Duff-Gordon said. “We want to represent the industry to stand up and to fight for regulatory clarity in the U.S.”</p>
    </div>
  },
  {
    id: 560,
    title: 'Binance plans to swap 750 million of token pairs to ensure liquidity',
    category: 'Crypto',
    subCategory: ['Binance', 'Token', 'liquidity'],
    description: <div className="blog-desc">
      <p>(Reuters) - Binance said in a tweet on Monday it would swap 750 million tether-tron token pairs for tether-ether in an attempt to maintain the stablecoin's liquidity.</p>
    </div>,
    authorName: 'Reuters',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 12, 2023 - 5:30 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-642.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>(Reuters) - Binance said in a tweet on Monday it would swap 750 million tether-tron token pairs for tether-ether in an attempt to maintain the stablecoin's liquidity.</p>
    	<p>The move comes amid renewed regulatory scrutiny at the world's largest cryptocurrency exchange and will help cushion some of the fallout from recent blows, including Binance being sued by the U.S. Securities and Exchange Commission.</p>	
    	<p>The swap, set to start after 12 p.m. UTC on Monday, will be held directly with the tether team, the tweet said.</p>	
    	<p>Tether is the largest stablecoin that aims to maintain a 1:1 peg with the U.S. dollar. Tron is the ninth largest cryptocurrency with a $6.3 billion market value, while ether is the largest after bitcoin, with its $210 billion market capitalization.</p>
      <p>Last week, the SEC sued Binance, its CEO and founder Changpeng Zhao, and Binance.US's operation alleging in 13 charges that Binance had engaged in a "web of deception," artificially inflated trading volumes and diverted customer funds, and that Binance and Zhao were secretly controlling the U.S. entity while publicly claiming that it was independent.</p>
      <p>It will also halt dollar withdrawal channels as early as June 13 after the SEC asked a court to freeze its assets. Binance said on Thursday.</p>
      <p>Trading platform Robinhood Markets said the next day that it was delisting three cryptocurrency tokens from its platform that the SEC identified as securities in its lawsuit against Binance.</p>
      <p>The SEC charged the Tron Foundation and its founder Justin Sun with fraud in March. A month later the U.S. arm of Binance said it would remove digital asset token tron from its trading platform.</p>
      <p>(Reporting by Mehnaz Yasmin and Medha Singh in Bengaluru)</p>
    </div>
  },
  {
    id: 559,
    title: 'Bitcoin stays below US$26,000 in mixed market, Binance’s BNB biggest loser in 24 hours',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Binance', 'market'],
    description: <div className="blog-desc">
      <p>Bitcoin inched up but continued to trade below US$26,000 on Monday afternoon in Asia, while all other top 10 non-stablecoin cryptocurrencies traded mixed</p>
    </div>,
    authorName: 'Pradipta Mukherjee',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 12, 2023 - 2:37 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-643.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin inched up but continued to trade below US$26,000 on Monday afternoon in Asia, while all other top 10 non-stablecoin cryptocurrencies traded mixed, as investors brace for the U.S. Securities and Exchange Commission’s (SEC) clampdown on cryptocurrency exchanges.</p>
    	<h3>Cryptos trade mixed</h3>
      <p>Bitcoin rose 0.21% to US$25,812 in 24 hours to 4 p.m. in Hong Kong, according to CoinMarketCap data. The world’s largest cryptocurrency by market capitalization has lost 3.82% in the last seven days following the U.S. SEC’s lawsuits against two of the biggest crypto exchanges, Binance and Coinbase.</p>	
    	<p>Ether, the second biggest cryptocurrency, fell 0.97% to US$1,736, after losing 7.31% over the past week.</p>	
    	<p>BNB, the native token of world’s biggest crypto exchange Binance, was the biggest loser in the last 24 hours, dropping 5.5% to US$224, and losing 25.59% on the week. Last week, the SEC filed a lawsuit against Binance, its U.S. branch, and Zhao with charges related to securities violations. Binance faces 13 charges in the lawsuit, including the unregistered offer and sale of the BNB and BUSD tokens and unregistered activity related to its Simple Earn and BNB Vault products and its staking program.</p>
      <p>“The SEC appears to have given no consideration to the drastic and cascading negative impact its actions may have on users and the industry,” a Binance spokesperson said last week. “We now join a number of other crypto projects facing similarly misguided actions from the SEC and we will vigorously defend our business and the industry.”</p>
      <p>The SEC has also sued Coinbase, the largest crypto exchange in the U.S., on similar charges.</p>
      <p>“We are in this environment of regulation by enforcement. I don’t feel like there is a clear rulebook. The only high-level statement they have made is that everything other than Bitcoin is a security, [and] that’s just not what it says in the law, and by the way, that would also kind of mean like the end of the crypto industry in the U.S…we have to go to court to challenge that and we are proud to do it for the industry and for America,” Coinbase’s co-founder and chief executive Brian Armstrong told The Wall Street Journal.</p>
      <p>Cardano’s ADA and Polygon’s Matic were the biggest gainers in the last 24 hours. Cardano rose 6.11% to US$0.2788, although it lost 25.72% on the week. Polygon increased 4.59% to US$0.6292 but declined 29.13% in the last seven days.</p>
      <p>The total crypto market capitalization dropped 0.25% to US$1.05 trillion and the total market volume fell 21.12% to US$27.29 billion in the last 24 hours.</p>
      <h3>NFT sales on Bitcoin network surge</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index dropped 1.71% to 2,999.94 in the 24 hours to 6.40 p.m. in Hong Kong, bringing its weekly losses to 9.16%.</p>
      <p>The Forkast ETH NFT Composite lost 1.34% to 1,056.57 and declined 5.19% on the week.</p>
      <p>Ethereum NFT sales dropped 11.75% to US$13.04 million in 24 hours, according to CryptoSlam data. Bored Ape Yacht Club recorded the highest sales volume among NFTs in the Ethereum blockchain, rising 3.57% to US$1.46 million in 24 hours.</p>
      <p>“This has been an extremely rough week for crypto and NFTs due to the U.S. SEC filing lawsuits against Binance and Coinbase,” according to Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News. “During this new phase of scrutiny on, well, everything on the blockchain, the NFT community has really banded together to support the builders.”</p>
      <p>NFT sales on the Bitcoin network gained 42.14% to US$6.73 million. The Derps gained the most sales volume among NFTs on the Bitcoin blockchain, climbing 52.67% to US$4.61 million in 24 hours.</p>
      <p>“Bitcoin still holds onto number two and seems to be on auto-pilot. More traders are being onboarded into the Ordinals ecosystem as the appeal of taking part in the early days of the historic chain’s NFT scene becomes too big to miss,” Petscher added.</p>
      <h3>Asian, European markets gain; U.S. futures rise</h3>
      <p>Asian equity markets mostly gained on Monday as investors await policy decisions this week from major central banks, including the U.S. Federal Reserve, the European Central Bank and the Bank of Japan.</p>
      <p>The Shanghai Composite dropped 0.08% and the Shenzhen Component Index rose 0.74%. Hong Kong’s Hang Seng Index gained 0.07% and Japan’s Nikkei 225 strengthened 0.52%.</p>
      <p>U.S. stock futures rose as of 7.30 p.m. in Hong Kong. The Dow Jones Industrial Average futures added 0.09% and S&P 500 futures rose 0.28%. Nasdaq 100 Futures also gained 0.52%.</p>
      <p>The U.S. is scheduled to release its latest consumer price index (CPI) data on Tuesday, a key inflation indicator. Annual inflation in the world’s biggest economy is expected to fall to 4.1% in May, from 4.9% in April and 5% in March, mainly due to lower energy prices, according to Trading Economics.</p>
      <p>The U.S. central bank is expected to hold any increase in interest rates at its next meeting on June 13 and 14. Current interest rates in the U.S. are between 5% and 5.25%, the highest since 2006, after 10 consecutive hikes that started in March 2022.</p>
      <p>European bourses also strengthened on Monday. The benchmark STOXX 600 rose 0.31% and Germany’s DAX 40 gained 0.73%.</p>
      <p>The European Central Bank is expected to raise its key interest rates by 25 basis points on June 15 and again in July, due to lingering inflation, according to a poll of 59 economists by Reuters.</p>
      <p>“Fixed income investors have experienced a period of very low interest rates over the past decade. Going forward, we are likely to see higher interest rates kept in place by central banks to control lingering inflation. In this new environment, corporate bonds can play a useful role in portfolios because different types of credit can be used at different stages of the economic cycle,” Elizabeth Murphy, investment director of credit at Schroders, said in a note on Monday.</p>
    </div>
  },
  {
    id: 556,
    title: 'Why Bitcoin’s transactions are soaring today',
    category: 'Crypto',
    subCategory: ['bitcoin', 'transactions', 'crypto'],
    description: <div className="blog-desc">
      <p>Bitcoin transactions hit the highest point in almost two months amid regulatory heat. Yet, money flowed out of the market.</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 9, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-626.jpg',
    content: <div className="blog-content">
    	<h3>In this article</h3>
    	<p>Bitcoin transactions hit the highest point in almost two months amid regulatory heat. Yet, money flowed out of the market.</p>
    	<ul>
        <li>Active addresses hit the one million mark on 6 and 7 June.</li>
        <li>Traders’ positions suggested optimism for BTC’s price.</li>
      </ul>
      <p>The number of active addresses transacting with Bitcoin [BTC] reached one million for two consecutive days, on 6 June and 7 June, according to Santiment.</p>	
    	<h3>Skepticism drives the act</h3>
      <p>Although active addresses showed the number of unique senders and receivers, the hike did not entirely imply that these participants were buying BTC.</p>	
    	<p>While this suggested significant activity for Bitcoin, it tilted more toward investors’ action to move assets away from exchanges and into self-custody.</p>
      <p>Lately, Binance and Coinbase, two of the world’s largest exchanges with high trading volumes, were served court papers by the U.S. SEC. This resulted in a high rate of outflows from both platforms.</p>
      <p>Despite the challenges and an initial downturn, BTC did not fall below the $25,000 mark. Also, the regulatory issues and hike in transactions propelled an increase in Bitcoin’s social dominance.</p>
      <p>Social dominance shows the share of discussions in the crypto community that is connected to an asset. So, the increase means that attention toward the BTC spiked. While the metric had fallen at press time, the weighted sentiment also followed the same route.</p>
      <p>Used as a measure of the investors’ perception toward an asset, the Bitcoin-weighted sentiment increased to 1.45 on 6 June. However, it had decreased to -0.91 at the time of this writing, insinuating a backpedal in optimism around the coin.</p>
      <h3>Open Interest falls</h3>
      <p>In the derivatives arm of the market, Bitcoin’s Open Interest (OI) decreased. The OI serves as an indicator of the interest in opening futures or options contracts for an underlying asset.</p>
      <p>If the OI increases over time, then it means that traders are opening more positions. But since its decrease, it indicated how traders were exiting postins and taking liquidity out of the market.</p>
      <p>Regardless of the exits, most traders still in search of potential gains have opted to go long. This inference was indicated by the funding rate. Usually, a positive funding rate indicates that long-positioned traders are willing to keep open their contracts by paying short.</p>
      <p>But when the funding rate is negative, it means that more short positions were open, and outpacing their long counterparts.</p>
      <p>In another development, ArkInvest CEO Cathie Wood maintained her optimistic BTC position in a recent Bloomberg interview. This was after her company accumulated more of the Coinbase stock after the SEC served the company.</p>
      <p>While she said the crackdown on the firms is healthy for competition, she also answered another question about her $1 million prediction. In backing her stance, Wood said:</p>
      <p><strong>“The more uncertainty and volatility there is in the global economies, the more confidence increases in Bitcoin.”</strong></p>
    </div>
  },
  {
    id: 555,
    title: 'Weekly Market Wrap: SEC crackdown on Binance, Coinbase ‘can’t stop’ Bitcoin as it trades above US$26,000',
    category: 'Crypto',
    subCategory: ['SEC', 'Binance', 'Coinbase'],
    description: <div className="blog-desc">
      <p>Bitcoin fell 2% from June 2 to June 9 to trade at US$26,598 at 7:45 p.m. Friday in Hong Kong.</p>
    </div>,
    authorName: 'Zoltan Vardai',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 9, 2023 - 5:14 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-627.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin fell 2% from June 2 to June 9 to trade at US$26,598 at 7:45 p.m. Friday in Hong Kong. The world’s largest cryptocurrency by market capitalization has traded below US$30,000 since April 19. Ether fell 2.83% over the week to US$1,842.</p>
    	<p>It was a tumultuous week for the crypto industry after the U.S. Securities and Exchange Commission sued Binance’s U.S. wing for 13 charges related to securities violations, including the unregistered offers and sales of the BNB and BUSD tokens and activity related to its Simple Earn and staking programs.</p>	
    	<p>The SEC sued Coinbase exchange on Tuesday, a day after initiating its lawsuit against Binance. The regulator alleged that Coinbase made billions of dollars unlawfully facilitating the buying and selling of crypto asset securities since at least 2019.</p>	
    	<p>“It is obvious that the SEC is currently on the hunt for crypto companies. This goes back to the collapse of FTX in 2022, as U.S. regulators would embarrass themselves if a similar scenario were to repeat itself without intervention,” Jonas Betz, crypto market analyst and founder of consultancy firm Betz Crypto, told Forkast in a LinkedIn response.</p>
      <p>“It is very likely that more exchanges will be targeted in the coming months. However, going for the big dogs like Binance and Coinbase first seems strategically clever as smaller companies will automatically adapt their activities to the new rules indicated by the outcome of the lawsuits.”</p>
      <p>Following the lawsuits, Bitcoin briefly dipped to a weekly low of US$25,493 on Tuesday but recovered above US$26,000 the same day.</p>
      <p>“The rapid recovery of crypto prices is due to two reasons. First, lawsuits can’t stop decentralized systems like Bitcoin and Ethereum and therefore do not pose a direct threat to cryptocurrencies. Second, even though the U.S. is an influential global player, these lawsuits should have little impact on other jurisdictions around the world. While holders of U.S. cryptocurrencies sold their shares in a short-lived market panic, many took the opportunity to buy cryptocurrencies at a discount, thus the market quickly recovered,” wrote Betz.</p>
      <p>James Wo, the founder and chief executive officer of crypto investment firm DFG, said that market confidence did not take a significant hit from the lawsuits, unlike the panic selling caused by FTX’s collapse.</p>
      <p>“If it falls below US$26,000 again this week, it could be considered a short-term downtrend. Another price to watch is the previous high of US$28,000-28,500. Its break-through can be seen as a short-term bullish signal,” wrote Wo to Forkast.</p>
      <p>Lucas Kiely, the chief investment officer of digital asset platform Yield App, said that the SEC’s crackdown occurred given its failure to prevent the FTX collapse and warned of the potential of a more aggressive approach by the regulator.</p>
      <p>“It remains unclear if more exchanges will be targeted in this crackdown. However, it is evident that the SEC is taking a more aggressive approach towards regulating the crypto industry, which may result in more legal actions against other exchanges that fail to comply with securities laws,” Kiely told Forkast.</p>
      <p>Kiely added that the market’s quick recovery occurred as buyers saw an opportunity to buy the dip.</p>
      <p>“Some analysts believe that this is a sign of growing maturity in the cryptocurrency market. In previous years, negative news from regulatory bodies would have caused more significant price drops.”</p>
      <p>Adding to global investor concerns, the Eurozone economy officially entered recession as it shrank 0.1% in the first quarter of 2023, compared to preliminary estimates of a 0.1% growth, pressured by high inflation and borrowing costs.</p>
      <p>Mike Ermolaev, founder of blockchain PR firm Outset PR and the author of the interview series ‘Crypto Opinion with Mike Ermolaev,’ said that a bull run could occur if the SEC loses the lawsuits.</p>
      <p>“[The SEC’s actions] appear to be an attempt to cripple the sector, especially in light of the new lawsuit against Coinbase. While cryptocurrency will undoubtedly recover, the road to redemption will be more challenging than ever. Moreover, we must not discount the possibility that unfair regulations could drive crypto into the shadows, taking a significant step backward,” wrote Ermolaev to Forkast.</p>
      <p>The global crypto market capitalization stood at US$1.1 trillion on Friday at 7:45 p.m. in Hong Kong, down 3.5% from US$1.14 trillion a week ago, according to CoinMarketCap data. With a market cap of US$515 billion, Bitcoin represented 46.7% of the market while Ether, valued at US$221 billion, accounted for 20%.</p>
      <h3>Notable Movers: LUNC, XRP</h3>
      <p>Terra’s Luna Classic (LUNC) token was this week’s second-biggest gainer in the top 100. LUNC rose 15.27% to US$0.00009811. The token started picking up momentum on Sunday, a day before proposal #11548 passed, which will provide a test environment for the price re-pegging of USTC.</p>
      <p>XRP, the cryptocurrency powering the XRP Ledger’s real-time gross settlements, was the week’s second-biggest gainer. XRP rose 2.3% to US$0.5274, as investors became less concerned with Ripple’s ongoing lawsuit with the SEC after the regulator filed two similar lawsuits against Binance and Coinbase.</p>
      <h3>Next Week: Can Bitcoin stay above US$25,350?</h3>
      <p>Investors await the release of May’s core Consumer Price Index in the U.S., scheduled for next Tuesday. The inflation data will be published a day before the Federal Reserve’s next interest rate decision on Wednesday, where the Fed is expected to pause its interest rate hikes for the first time in over a year, according to over 90% of economists polled by Reuters.</p>
      <p>European investors are anticipating the European Central Bank’s next monetary decision, set for next Thursday. Following seven consecutive rate hikes, the bank is expected to hike its key lending rate by 25 basis points, even as higher borrowing costs start to take a toll on the European economy.</p>
      <p>A weekly close above US$27,600 in Bitcoin would signal more uptrend, while a close below US$25,350 means more bearish momentum, Johnny Louey, a crypto research analyst at trading platform LiquidityTech Protocol told Forkast in a Telegram message.</p>
      <p>Yield App’s Kiely said that he only expects significant upward momentum after a close above US$30,500.</p>
      <p>“Multiple closes above this number and we could be at the onset of a new bull market. It could trade in the mid-term as low as US$24,000. If it breaks that level, it could continue to trade downwards,“ wrote Kiely.</p>
    </div>
  },
  {
    id: 554,
    title: 'Bitcoin rises, Ether slips, most top 10 cryptos begin to pick up after SEC crackdown',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Ether', 'SEC'],
    description: <div className="blog-desc">
      <p>Bitcoin rose and Ether fell during Friday afternoon trading hours in Hong Kong, while most of the top 10 non-stablecoin cryptocurrencies</p>
    </div>,
    authorName: 'Zoltan Vardai',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 9, 2023 - 1:35 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-624.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin rose and Ether fell during Friday afternoon trading hours in Hong Kong, while most of the top 10 non-stablecoin cryptocurrencies by market capitalization saw a small rally following the U.S. Securities and Exchange Commission’s (SEC) lawsuits against Binance and Coinbase.</p>
    	<h3>Bitcoin inches up, SEC crackdown weighs on top cryptos</h3>
      <p>Bitcoin rose 0.15% from 7 a.m. to 4:30 p.m. in Hong Kong to US$26,593. Ether inched down 0.48% in the same timeframe, to US$1,840.</p>	
    	<p>Cardano’s ADA token was the day’s biggest loser, falling 3.21% in the past 24 hours to US$0.3155, followed by the BNB token that fell 0.97% to US$258. BNB has traded below US$300 since Monday when the lawsuit against Binance broke the headlines.</p>	
    	<p>Despite a small market recovery, crypto investor sentiment remains cautious after the SEC initiated legal action against Binance and Coinbase within 24 hours.</p>
      <p>“In what seems like a premeditated, doubling down on regulation, the SEC’s actions have once again brought liquidity concerns to light, encouraging users to withdraw their funds from two of the largest platforms in the world. But it’s not all doom and gloom,”  Lucas Kiely, the chief investment officer of digital asset platform Yield App, told Forkast.</p>	
    	<p>“Both Binance and Coinbase have solid track records, which should be just enough to overcome this. So, despite prices getting a bit of an initial hit, the long-term fundamentals of BTC and ETH still appear bright.”</p>	
    	<p>The total crypto market capitalization over the past 24 hours rose 0.19% to US$1.1 trillion and market volumes decreased 30.18% to US$25.81 billion, according to CoinMarketCap.</p>
      <h3>Ethereum NFT sales fall for second consecutive day, while Polygon NFT sales gain</h3>
      <p>The Forkast 500 NFT index fell 0.95% to 3,223.02 points in the 24 hours to 4:30 p.m. in Hong Kong and declined 4.39% during the week.</p>	
    	<p>Ethereum’s 24-hour NFT sales fell 12.30% to US$13.85 million, boosted by sales for the Captains collection, which rose 91.01% to US$1.1 million. Sales of the Mutant Ape Yacht Club rose 23.70% to US$628,637 while sales of Bored Ape Yacht Club NFTs inched up 1.48% to US$517,208.</p>	
    	<p>Polygon’s 24-hour NFT sales strengthened 17.32% to over US$2.7 million, for a second consecutive day, as sales for Weee Did It Palz NFTs rose 40.10% to US$392,025. The Forkast POL NFT Composite fell 0.8% to 978.75 points, along with all other Forkast Labs indexes.</p>
      <h3>Asian equities up; U.S. equities fall; S&P 500 futures near highest levels year to date</h3>
      <p>Asian equities strengthened during Friday afternoon trading, as investors became cautiously optimistic amid signs that China’s central bank may cut interest rates over a weakening economy. May’s inflation data showed that the world’s second-largest economy slowed further, adding to concerns around China’s post-pandemic recovery.</p>	
    	<p>Japan’s Nikkei 225 rose 1.97% and Hong Kong’s Hang Seng Index rose for the third consecutive day, up by 0.47%. The Shanghai Composite increased 0.55% and the Shenzhen Component Index inched up 0.66%.</p>	
    	<p>Upside momentum was limited by concerns that the U.S. Federal Reserve could continue its monetary tightening next week after several central banks raised interest rates this week.</p>
      <p>Most U.S. stock futures fell on the day, as of 4:30 p.m. in Hong Kong, except the tech-heavy Nasdaq-100 futures that rose 0.081%. The Dow Jones Industrial Average futures fell 0.11% and the S&P 500 futures index inched down 0.029% to remain near its highest level for 2023.</p>	
    	<p>European bourses were muted as investors await the European Central Bank’s monetary decision next week, expecting the eighth consecutive interest rate hike. The pan-European STOXX 600 inched down 0.033% and Germany’s DAX 40 fell 0.074%.</p>	
    </div>
  },
  {
    id: 558,
    title: 'Kraken NFT Marketplace Launches With Support for Ethereum, Solana and Polygon Collections',
    category: 'Crypto',
    subCategory: ['NFT', 'Ethereum', 'Solana'],
    description: <div className="blog-desc">
      <p>Cryptocurrency exchange Kraken has officially launched its NFT marketplace out of beta testing, supporting over 250 non-fungible tokens (NFTs) across the Ethereum, Solana and Polygon blockchains.</p>
    </div>,
    authorName: 'Rosie Perper',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 8, 2023 - 9:26 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-629.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Cryptocurrency exchange Kraken has officially launched its NFT marketplace out of beta testing, supporting over 250 non-fungible tokens (NFTs) across the Ethereum, Solana and Polygon blockchains.</p>
    	<p>The company launched a public beta version of its NFT platform in November. Initially, it offered support for a curated selection of 70 NFT collections on Ethereum and Solana.</p>	
    	<p>As part of its public launch, the platform has pledged to include not only blue-chip projects like CryptoPunks and Bored Ape Yacht Club, but also more affordable collections that can be bought for under $100.</p>	
    	<p>Kraken NFT marketplace says it will not charge gas fees to customers who buy and sell NFTs on the platform. However, it specifies that "gas fees will be incurred when transferring NFTs and other cryptoassets on and off the Kraken platform."</p>
      <p>Users have the choice to pay in fiat or cryptocurrency and listings will have built-in rarity rankings. Kraken says MetaMask and Solana's Phantom wallet are currently supported and WalletConnect will be added in the future.</p>
      <p>Kraken's latest release comes amid a broader regulatory crackdown against the crypto industry. While U.S. exchanges are facing lawsuits from the U.S. Securities and Exchange Commission (SEC), Canada recently tightened its regulatory framework for digital asset trading.</p>
      <p>Kraken has remained in Canada despite its crypto crackdown while competitors like Binance and OKX have announced their departures, allowing it to usurp the market and grow its customer deposits by 25% in the weeks following the exits. Kraken remains available to U.S. customers with some state-by-state trading restrictions.</p>    
    </div>
  },
  {
    id: 553,
    title: 'Binance Moved Billions Through US Bank Accounts Controlled by CZ, Says SEC',
    category: 'Crypto',
    subCategory: ['Binance', 'US', 'SEC'],
    description: <div className="blog-desc">
      <p>Billions of dollars of Binance-related funds routinely flowed through Signature Bank and Silvergate Bank for years, according to court documents filed Wednesday on behalf of the Securities and Exchange Commission (SEC).</p>
    </div>,
    authorName: 'André Beganski',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 8, 2023 - 5:44 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-625.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Billions of dollars of Binance-related funds routinely flowed through Signature Bank and Silvergate Bank for years, according to court documents filed Wednesday on behalf of the Securities and Exchange Commission (SEC).</p>
    	<p>The two institutions catered heavily to crypto clients before they shuttered in March. And each bank operated its own round-the-clock payments network, which allowed customers to settle digital asset transactions.</p>	
    	<p>An SEC review of financial records, including bank statements and wire transfers, found Binance, CEO Changpeng Zhao, and BAM Trading Services—the operator of Binance.US—held numerous accounts at Signature and Silvergate banks.</p>	
	    <p>While Binance has said that it and Binance.US are separate entities, the SEC’s lawsuit unveiled on Monday calls that into question. And to bolster its emergency motion seeking to freeze Binance.US’s corporate assets, the SEC’s account of financial records indicates BAM Trading Services and Binance entities deposited billions of dollars into a single account that belonged to Merit Peak, of which Zhao is the beneficial owner.</p>
      <h3>Binance’s Silvergate Bank Accounts</h3>
      <p>Attached to an email address identified as “Solutions@Binance.com,” Merit Peak’s Silvergate Bank accounts received $22.2 billion in deposits between 2019 and 2021, the SEC claims. Of that, $21.9 billion was paid to a foreign affiliate of Paxos, the former issuer of BUSD. The New York firm stopped minting the Binance-branded stablecoin in February at the direction of regulators.</p>
    	<p>Merit Peak is described as an over-the-counter trading desk and a proprietary trader of digital assets, according to the filing, which notes Silvergate closed the company’s accounts in mid-2022. But the accounts contained commingled assets, the SEC claims.</p>	
    	<p>“Millions of dollars from Binance-related accounts were commingled in Merit Peak’s accounts,” the documents state. “Merit Peak transferred all of that money as part of its transfers of almost $20 billion to a foreign affiliate of Paxos in 2021.”</p>	
	    <p>Another company beneficially owned by Zhao called Sigma Chain received funds from Binance and BAM Trading Services, the SEC claims. Between 2019 and 2023, Sigma Chain received $184 million and $145 million from Binance and BAM Trading Services, respectively.</p>
      <p>The company’s business was described as a “market maker providing liquidity to fiat exchanges,” according to the filing. The SEC claims that Sigma Chain “engaged in manipulative trading that artificially inflated [...] trading volume” on Binance.US.</p>
    	<p>BAM Trading Services received deposits from other players in the digital assets industry between 2019 and 2013: Cryptocurrency exchange Coinbase ($424 million), the trading firm Wintermute ($2.4 billion), and Alameda Research ($318 million), the trading firm of FTX founder and former CEO Sam Bankman-Fried.</p>	
    	<p>BAM Trading Services sent $1.5 billion to Alameda Research and $3.3 billion to Wintermute during that period, the SEC claims.</p>	
	    <p>Neither Wintermute nor Coinbase immediately responded to a request for comment from Decrypt.</p>
      <p>In total, eight Binance-affiliated companies held accounts at Silvergate Bank, which were incorporated in Seychelles, the British Virgin Islands, Switzerland, and the state of Delaware. According to the filing, Guangying Chen, a senior executive at Binance, controlled accounts belonging to both BAM Trading Services and Binance.</p>	
    	<p>As of March 31, all of the bank accounts tied to Binance, BAM Trading Services, and Zhao were empty, the SEC allegedly found. The bank had signaled it would voluntarily wind down operations earlier that month.</p>	
	    <h3>Binance’s Signature Bank Accounts</h3>
      <p>Numerous bank accounts associated with the aforementioned entities were also opened at Signature Bank, the crypto-friendly bank that was seized by regulators in March alongside Silicon Valley Bank. Other foreign companies where Zhao was also a beneficial owner had accounts as well, the documents state.</p>
      <p>“Zhao was also the beneficial owner of numerous other foreign companies with accounts at Signature Bank,” the court documents state. “These companies are foreign domiciled, including in Canada, UAE, Seychelles, Singapore, Lithuania, Kazakhstan, among others.”</p>	
    	<p>Between Binance-linked bank accounts at Silvergate Bank and Signature Bank, “large amounts of money [flowed] in and out,” where incoming funds were followed by debits within days, the SEC claims. “At times the amounts being credited and debited during a single month amounts to the movement of more than a billion dollars,” it adds.</p>	
	    <p>When this year began, the SEC found that eight companies owned by Binance and Zhao had a total of $58 million on deposit. The filing states, “During that same time frame, $840 million was deposited into, and $899 million was withdrawn, from those accounts.”</p>
      <p>By the end of March, the eight accounts were empty, except for one that had $180,000. And as of May 27, long after Signature and Silvergate had closed up shop, the SEC claims all of the accounts were either empty or closed.</p>	
    	<p>On Tuesday, the SEC filed an emergency motion, seeking a temporary restraining order against Binance.US that would freeze the company’s corporate assets. It also requested that funds, both crypto and cash, be repatriated to the United States.</p>	
	    <p>A Binance spokesperson told Decrypt the measure is “unwarranted and based more on the SEC Staff obtaining an advantage in litigation versus genuine concern about the safety of customer assets.”</p>
      <p>“While we are disappointed by this action, we look forward to defending ourselves in court,” the spokesperson added.</p>	
    </div>
  },
  {
    id: 557,
    title: 'USDC stablecoin issuer Circle obtains Singapore license for digital payments, others',
    category: 'Crypto',
    subCategory: ['USDC', 'Circle', 'Singapore'],
    description: <div className="blog-desc">
      <p>Circle Internet Singapore Pte. Ltd. said Wednesday it has received a Major Payment Institution license from the Monetary Authority of Singapore</p>
    </div>,
    authorName: 'Danny Park',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 7, 2023 - 8:22 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-628.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Circle Internet Singapore Pte. Ltd. said Wednesday it has received a Major Payment Institution license from the Monetary Authority of Singapore, which allows it to offer digital payment token services, cross-border money transfer services, and domestic money transfer services in the city-state.</p>
    	<h3>Fast facts</h3>
      <ul>
        <li>Circle Singapore is an affiliate of Circle Internet Financial, LLC, the Boston-based issuer of the world’s second largest stablecoin, USD Coin (USDC).</li>
        <li>“This is a significant step forward for Circle and the future of financial infrastructure and dollar digital currencies in the region,” Dante Disparte, Circle’s Chief Strategy Officer and Head of Global Policy, said in the press release.</li>
        <li>Circle Singapore officially opened its office last month, after receiving an in-principle approval as a major payment institution from local authorities in November 2022.</li>
        <li>In February, the Singapore entity collaborated with the region’s first government-supported blockchain ecosystem builder Tribe to launch a training program for blockchain developers.</li>
        <li>The dollar-pegged USDC currently holds a market capitalization of US$28.6 billion, placing it fifth largest on CoinMarketCap’s list of all cryptocurrencies.</li>
      </ul>
    </div>
  },
  {
    id: 552,
    title: 'Bitcoin, Ether most other top crypto bounce back even as Coinbase joins Binance as SEC lawsuit target',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Coinbase', 'Binance'],
    description: <div className="blog-desc">
      <p>Bitcoin recovered to above US$27,000 on Wednesday morning in Asia, with nearly all other top 10 non-stablecoin cryptocurrencies rebounding even as U.S.</p>
    </div>,
    authorName: 'Timmy Shen',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 7, 2023 - 4:32 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-618.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin recovered to above US$27,000 on Wednesday morning in Asia, with nearly all other top 10 non-stablecoin cryptocurrencies rebounding even as U.S. regulators this week launched a series of lawsuits against Binance and Coinbase, two of the world’s biggest crypto exchanges, alleging most tokens are unregistered securities. Polygon’s Matic token was the exception, dipping 1.7% in the past 24 hours.</p>
    	<h3>Bitcoin, Ether bounce</h3>
      <p>Bitcoin rose 5.36% over the last 24 hours to US$27,118 at 6:50 a.m. in Hong Kong, bouncing back to the same price range before the U.S. Securities and Exchange Commission (SEC) filed the lawsuits this week. The world’s largest crypto by market capitalization was still down 2.32% over the past seven days, according to data from CoinMarketCap.</p>	
    	<p>Ether climbed 4.01% in the past 24 hours to US$1,881, but logged a 1.32% weekly loss.</p>	
    	<p>The meme coin Dogecoin led the winners this morning with a gain of 6.43%, edging out Bitcoin for the top spot. Polygon’s Matic missed out on the rebound, sliding 1.7% in the last 24 hours, and lost 9.39% over the past week.</p>
      <p>Nick Ruck, chief operating officer of non-fungible token (NFT) intellectual property licensing firm ContentFi, told Forkast that while the flurry of lawsuits by SEC chair Gary Gensler hit hard, the crypto market has largely held steady.</p>	
    	<p>“The initial panic quickly wore off as many traders not only expected the lawsuits, but apparently dismissed them entirely,” Ruck said. “A lawsuit may work against the SEC’s expectations when these cases are determined by court judges rather than Gary Gensler’s opinion.”</p>	
    	<h3>Bloodbath</h3>
      <p>While prices came back among leading tokens, Jeff Mei, the chief operating officer of the BTSE crypto exchange in the British Virgin Islands, said “we’ve seen something of a bloodbath for altcoins.”</p>
      <p>He said this is likely due to the SEC lawsuits naming a “basket of altcoins as securities, while not categorizing BTC and ETH in the same class.”</p>	
    	<p>This creates volatility opportunities for savvy investors, but requires care to navigate, he added.</p>	
    	<p>On Tuesday, just a day after the SEC sued Binance for alleged securities violations, the same regulator filed suit against Coinbase, the largest crypto exchange in the U.S., for allegedly breaching securities rules.</p>
      <p>The SEC alleges San Francisco-based Coinbase made billions of dollars unlawfully facilitating the buying and selling of crypto asset securities since at least 2019, according to its statement released Tuesday.</p>	
    	<p>SEC Chair Gary Gensler tweeted Tuesday that Coinbase’s alleged failures “deprive investors of critical protections, including rulebooks that prevent fraud and manipulation, proper disclosure, safeguards against conflicts of interest, and routine inspection by the SEC.”</p>	
    	<p>In a related development showing coordination among financial regulators across the U.S., the Alabama Securities Commission on Tuesday filed a so-called show cause order to Coinbase, backed by regulators in many other states.</p>
      <p>“The Order gives Coinbase 28 days to show cause why they should not be directed to cease and desist from selling unregistered securities in Alabama,” according to the notice. It adds that nine other state regulators, including California where Coinbase is based, were part of a multi-state task force involved in the order.</p>	
    	<h3>Back and forth</h3>
      <p>In response, Brian Armstrong, chief executive officer of Coinbase, tweeted that the company was “proud to represent the industry in court to finally get some clarity around crypto rules.”</p>	
    	<p>Armstrong noted that the SEC reviewed Coinbase’s business and approved it to sell shares to the public in 2021. He added that the SEC and the Commodity Futures Trading Commission have made conflicting statements on crypto assets don’t agree on what is a security and what is a commodity.</p>
      <p>Coinbase had argued back in April that the SEC’s attacks against Coinbase appeared to be undermining its own role as the approver of companies registering to sell shares to the public.</p>	
    	<p>Coinbase shares dropped 12.09% at the close of U.S. trading on Tuesday, but rose 2.48% in after-hours trading.</p>	
    	<p>In an additional action against Binance, the SEC on Tuesday asked a federal judge to freeze the assets of Binance.US, the crypto trading platform set up by Binance chief Changpeng Zhao to serve U.S. clients.</p>
      <p>In response, Binance.US tweeted on Tuesday that user assets “remain safe and secure” and “the platform continues to be fully operational with deposits and withdrawals functioning as normal.”</p>	
    	<p>Denys Peleshok, head of Asia at London-based multi-asset brokerage CPT Markets, said: “The SEC has had a very aggressive approach toward crypto companies for some time now and could be increasingly creating an unwelcoming environment for such firms and for the crypto industry as a whole in the country.”</p>	
    	<p>Republican Senator Cynthia Lummis followed up on this theme in criticism of the SEC actions in a tweet on Tuesday.</p>
      <p>“Real consumer protection requires creating a robust legal framework that exchanges can comply with, not pushing the industry offshore into the shadows.”</p>	
    	<h3>Different view</h3>
      <p>“Tech start-ups are encouraged to ‘run fast and break things’ and to ‘ask for forgiveness, not permission,’ however when the sector the start-up is operating in is finance and investments, there can be real consequences to this approach,” said Bradley Duke, founder and Co-CEO at ETC Group that specializes in exchange traded crypto products.</p>	
    	<p>“This is especially true in the US, where there is an absence of a regulatory framework for crypto, coupled with a regulator that has taken a very aggressive stance towards companies operating in the space,” Duke said in email comments.</p>
      <p>Not everyone in the blockchain world agrees that the SEC is out of order.</p>	
    	<p>“What we’ve seen with crypto exchanges is they have created a market where they act as the Exchange, Broker Dealer, Depository, the Transfer Agent, and even as the Regulator in some cases,” said Jai Waterman, the chief executive officer of Canada-based Blockstation.</p>	
    	<p>“In this case, Binance operates as all of the above. It is no surprise to us that regulatory action would be taken, and that the SEC is going after Binance and Coinbase, we expected it to happen sooner,” said Waterman at Blockstation, which describes itself as a blockchain-driven platform for tokenizing, listing, trading, clearing and settlement of digital assets and securities.</p>
      <p>“A lot of what’s listed on Binance and Coinbase, I would argue, are securities and whereas in the traditional securities market you have decentralization of duties, crypto exchanges are adding significant risks by playing all parts,” he said.</p>	
    	<h3>NFT sales rise</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>	
    	<p>In the NFT market, the Forkast 500 NFT index edged down 0.08% to 3,284.07 in the 24 hours to 9:40 a.m. in Hong Kong.</p>
      <p>NFT sales on Ethereum rose 22.81% over the last 24 hours to US$30.06 million, and sales on the Bitcoin network jumped 159.38% to US$8.5 million, according to CryptoSlam data.</p>	
    	<p>“NFT traders are rather bullish still on the space in the short term, though watching carefully,” Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News, said on Wednesday.</p>	
    	<p>Petscher added that NFTs generally trade in cycles between art and profile picture (PFP) NFTs. “Though, with news about the SEC filing lawsuits against Coinbase and Binance, all bets are off.”</p>
      <p>The NFT market saw a large art sale on Tuesday. Digital artist Tyler Hobbs’ Fidenza #545 was sold for around US$1.1 million in a reserved sale, according to NFT marketplace OpenSea.</p>	
    	<p>“High art sales are an indicator that NFTs may be primed for some action, but the SEC trying to freeze Binance’s U.S. assets and potentially Coinbase’s does have people considering what their best moves are,” Petscher added.</p>	
    	<p>Ruck of Singapore-based ContentFi said that Binance is “placing bets on their involvement in the future of the NFT industry,” as the world’s largest crypto exchange launched support for Bitcoin NFTs on Tuesday even after being sued by the SEC.</p>
      <p>Bored Ape Yacht Club had the biggest sales in the past 24 hours with a 1.36% rise to US$14.27 million.</p>	
    	<p>Uncategorized Ordinals – recently popular Bitcoin Ordinals that are not part of an established collection – recorded a 5.11% increase to US$6.49 million in sales over the past 24 hours.</p>	
    	<h3>Coinbase shares drop amid broader stock rally</h3>
      <p>Shares of Coinbase dropped 12.09% at the close on Tuesday following a 9.05% fall on Monday, while shares of Microstrategy, the world’s largest corporate holder of Bitcoin, rose 8.13% on Tuesday, recovering from its 8.53% decline on Monday.</p>
      <p>Mei at BTSE said the sharp difference in stock performance shows the outsize impact of the SEC’s actions on market sentiment for altcoins as opposed to Bitcoin.</p>	
    	<p>The S&P 500 closed up 12.02% on Tuesday, reaching its highest level since August 2022.</p>	
    	<p>U.S. stock futures were mixed as of 11:50 a.m. in Hong Kong. Dow Jones Industrial Average futures edged up 0.02%, and S&P 500 futures gained 0.08%. Nasdaq Futures fell 0.03%.</p>
      <p>In macroeconomic developments, the U.S. Federal Reserve will meet on June 14 to make its next move on interest rates, which are now between 5% and 5.25%, the highest since 2006.</p>	
    	<p>The CME FedWatch Tool now predicts a 80.6% chance the Fed will leave rates unchanged in June, and a 19.4% chance for another 25-basis-point rate hike.</p>
      <p>(Updates with comment from ETC group under Different View section.)</p>	
    </div>
  },
  {
    id: 551,
    title: 'Crypto industry reacts to SEC’s lawsuit against Binance',
    category: 'Crypto',
    subCategory: ['Crypto', 'SEC', 'Binance'],
    description: <div className="blog-desc">
      <p>The cryptocurrency industry faced another jolt as the U.S. Securities and Exchange Commission (SEC) launched its latest enforcement action against Binance, the world’s largest cryptocurrency exchange.</p>
    </div>,
    authorName: 'Zoltan Vardai',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 6, 2023 - 5:07 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-619.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>The cryptocurrency industry faced another jolt as the U.S. Securities and Exchange Commission (SEC) launched its latest enforcement action against Binance, the world’s largest cryptocurrency exchange.</p>
    	<p>On Monday, the SEC filed a lawsuit in the District Court for the District of Columbia targeting Binance, its U.S. branch, and Chief Executive Officer Changpeng Zhao with charges related to securities violations. Forkast asked industry experts to weigh in on what this means for the crypto industry.</p>	
    	<p>Binance faces 13 charges in the lawsuit, including the unregistered offer and sale of the BNB and BUSD tokens and unregistered activity related to its Simple Earn and BNB Vault products and its staking program. The SEC also alleged that Binance failed to register Binance.com and BAM Trading, the operator of Binance.US, as an exchange or a broker-dealer clearing agency.</p>	
    	<p>Binance has denied the SEC’s allegations.</p>
      <p>“The SEC’s actions here appear to be part of a rushed effort to claim jurisdictional ground from other regulators — and investors do not appear to be the SEC’s priority,” said a Binance spokesperson.</p>	
    	<p>The lawsuit comes amidst increased scrutiny for crypto exchanges in the U.S., three months after the Commodity Futures Trading Commission sued Binance and Zhao for allegedly violating derivatives rules.</p>	
    	<h3>SEC’s bear call</h3>
      <p><strong>Ben Caselin, Vice President, MaskEX:</strong> “The effects on the markets can be severe and result in a decrease in trade volume and added unpredictably. U.S. institutions such as the SEC are bearish on the crypto sector; as such, this sector is bearish on the USA. While investor protection is important and oft-cited as the primary motivation driving the SEC, the ongoing refusal to provide clarity coupled with regulation-through-enforcement actions are arguably attempts to maintain order.”</p>	
    	<p><strong>Rajagopal Menon, Vice President, WazirX:</strong> “These regulatory actions can have both short-term challenges and long-term effects on the industry. While they could create uncertainty and potentially impact market sentiment in the short term, they are aimed at protecting investors and ensuring compliance with existing financial regulations. The ultimate impact on the crypto sector in the U.S. will depend on the specific outcomes of the lawsuit and any subsequent regulatory actions.”</p>	
    	<p><strong>Mikkel Morch, Chairman and Non-Executive Director, ARK36:</strong> “The SEC is giving an extremely clear signal of strict financial regulatory oversight in the cryptocurrency industry similar to what is happening in the European Union. It is very clear that the SEC insists that all actors in the U.S. market ensure proper investor protection. In this case, Binance is accused of misleading investors, engaging in manipulative trading, and commingling customer assets. The consequence, if Binance is found guilty, may be further erosion of investor confidence in the market, potentially leading to a decrease in participation and investments.”</p>
      <h3>The American conundrum</h3>
      <p><strong>Vincent Chok, Chief Executive Officer, First Digital Trust:</strong> “[The SEC’s lawsuit] seems like the next in a series of steps to implement Operation Chokepoint 2.0… They have decided that concepts like self-sovereign identity, and owning your own economic agency should not be given to the masses. This will absolutely set the crypto sector in the U.S. back by many years, the migration of capital and innovation is already filtering out and pivoting to other jurisdictions like Hong Kong and UAE and will continue to do so. The pendulum has swung back the other way.”</p>	
    	<p><strong>Alex Reinhardt, Founder, Smart Blockchain:</strong> “I don’t believe the SEC’s objective is to destroy the crypto market or Binance. Rather, the goal of the U.S. regulator is to establish control over the crypto industry. And what better way to demonstrate authority than by gaining control over the world’s largest crypto exchange? Ultimately, the litigation is unlikely to result in the collapse of Binance, and that is not the intention. Let’s consider the investigations against BitFinex and Tether as examples. Their purpose was to compel these companies to comply with U.S. legal requirements. Similarly, I anticipate a similar outcome in this case: both parties will reach some form of agreement. Binance will likely pay a significant fine, discontinue certain services such as staking, and demonstrate a greater willingness to cooperate with law enforcement from the United States, among other measures.”</p>	
    	<p><strong>Urszula McCormack, Partner, King & Wood Mallesons:</strong> “In fairness to the U.S., any market with multiple policymakers and regulators, federal and state levels of government, and a complex legislative environment would struggle to build a cohesive strategy for crypto and Web3, because the overlap between policy areas is colossal. Enforcement and de-banking are the dominant U.S. themes. For other markets, enforcement is arguably often lackluster, but they are forging regulatory pathways focussing on market certainty and scaling regulation grounded in the risk-based approach.”</p>
      <h3>Greener grass in Hong Kong and UAE</h3>     
      <p><strong>Mikkel Morch, Chairman and Non-Executive Director, ARK36</strong></p>	
    	<p>“[The charges against Binance] may encourage other regulatory bodies worldwide to review and strengthen their oversight of cryptocurrency platforms and activities. However, other more so-called crypto-friendly jurisdictions with less stringent or more ‘pro crypto’ regulations will certainly also gain a lot of business as is already visibly seen in the UAE, Hong Kong or Singapore. Long-term, if the EU and U.S. are perceived as hostile to the cryptocurrency asset class then most likely more innovation and business will transition to those jurisdictions.”</p>	
    	<p><strong>Vincent Chok, Chief Executive Officer, First Digital Trust:</strong>  “Hong Kong and Dubai are already looking to foster a collaborative relationship to bridge the Middle East with Asia in this respect. This comes after the HKMA allowed retail participation in trading crypto, which democratizes access to the crypto marketplace, a progressive approach,” wrote Chok.</p>
      <p><strong>Urszula McCormack, Partner, King & Wood Mallesons:</strong> “Markets like Hong Kong and Dubai are pulling away (in a positive way) for two key reasons – first, they are sending strong signals from all levels that they support Web3 innovation; secondly, they are crafting sensible regulation across the strategic policy areas that matter – AML/CTF, consumer protection and market integrity.  However, they are at risk – expectations of a positive regulatory environment have been dashed before and some markets have suffered where they failed to meet those expectations by industry participants simply leaving.”</p>	
    	<p><strong>Ben Caselin, Vice President, MaskEX:</strong> “Amidst the ongoing regulatory stalemate in the US, both [Hong Kong and Dubai] will be capable of capturing additional market share and attract more foreign investment and talent to their shores. Most overseas platforms do not service the US and while the SEC’s actions might negatively impact global crypto markets, the SEC’s power over these markets is limited. This opens up more opportunity for other jurisdictions and regulatory bodies to lead the way.”</p>	
    	<p><strong>Denys Peleshok, Head of Asia, CPT Markets:</strong> “The SEC has had a very aggressive approach toward crypto companies for some time now and could be increasingly creating an unwelcoming environment for such firms and for the crypto industry as a whole in the country. Lately, some notable crypto firms have expressed their desire to move part of their operations away from the U.S. and to more crypto-friendly jurisdictions. This trend could be beneficial for other countries such as the UAE or Hong Kong which are putting in the efforts to attract crypto companies and to grow the industry locally. These destinations are becoming a magnet for companies operating in and around crypto, thanks to the crypto-friendly legal frameworks they have put in place and the warmer attitude of regulators. As a result, the U.S. could see an exodus of companies in the field of crypto and could sideline itself from the rapid developments of the crypto and Web3 industries.”</p>
    </div>
  },
  {
    id: 550,
    title: 'Investors race to withdraw deposits from Binance amid web of deception claims - latest updates',
    category: 'Crypto',
    subCategory: ['deposits', 'withdraw', 'Binance'],
    description: <div className="blog-desc">
      <p>Investors have pulled around $780m (£628.4m) from crypto exchange Binance in the last 24 hours, data company Nansen has said, as US regulators accused the trading platform and its founder of a “web of deception”.</p>
    </div>,
    authorName: 'Chris Price',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 6, 2023 - 1:58 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-607.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Investors have pulled around $780m (£628.4m) from crypto exchange Binance in the last 24 hours, data company Nansen has said, as US regulators accused the trading platform and its founder of a “web of deception”.</p>
    	<p>The price of bitcoin has plunged by about 5pc since Monday to fall below £21,000 as the world’s largest crypto exchange and its chief executive Changpeng Zhao faced allegations of misusing investor funds, operating as an unregistered exchange and violating a slew of US securities laws.</p>	
    	<p>The lawsuit filed by the SEC lists thirteen charges against the crypto trading platform — including mingling and diverting customer assets to an entity Mr Zhao owned called Sigma Chain.</p>	
    	<p>The charges echo accusations levelled at the second largest cryptocurrency exchange, FTX, and its founder Sam Bankman-Fried after its collapse last year.</p>
      <p>SEC chairman Gary Gensler in a written statement that Zhao and Binance “engaged in an extensive web of deception, conflicts of interest, lack of disclosure, and calculated evasion of the law”.</p>
    	<p>He added: “The public should beware of investing any of their hard-earned assets with or on these unlawful platforms.”</p>	
    	<p>In a social media post, Binance said that it has been cooperating with the SEC’s investigation but said that the agency “chose to act unilaterally and litigate.”</p>	
    	<p>12:06 PM</p>
      <h3>US markets on track to edge downward</h3>
      <p>Wall Street is expected to slip at the opening bell as investors assess chances of the Federal Reserve holding interest rates at its meeting next week.</p>
    	<p>The US services sector barely grew in May as new orders slowed, data on Monday showed, clouding the outlook for the Fed’s next move after strong jobs data on Friday.</p>	
    	<p>Investors are now focused on inflation data due next week ahead of the Fed meet. Consumer prices are likely to have cooled slightly on a month-over-month basis in May but core prices are expected to have remained sticky.</p>	
    	<p>US stocks have advanced in recent weeks, with a rally in megacap stocks, a stronger-than-expected earnings season and hopes of a pause in interest rate hikes pushing the benchmark S&P 500 and the tech-heavy Nasdaq to fresh 2023 highs on Friday.</p>
      <p>In pre-market trading, the Dow Jones Industrial Average, S&P 500 and Nasdaq 100 were all down 0.1pc.</p>
    	<p>11:47 AM</p>
      <h3>Shell to sell UK home retail energy business</h3>
    	<p>Shell has decided to exit its home retail energy businesses in Britain, Germany and the Netherlands due to their poor returns.</p>	
    	<p>Shell launched a strategic review of its European retail businesses in January amid “tough market conditions,” shortly after chief executive Wael Sawan took office.</p>
      <p>The company said:</p>
    	<p>That review has now concluded and as a consequence, we intend to exit those businesses.</p>	
    	<p>A sales process is already underway, with the intent to reach an agreement with a potential buyer in the coming months.</p>	
    	<p>Three of Britain’s largest power providers have expressed interest in acquiring Shell’s UK retail business, Reuters reported last month.</p>
      <p>11:20 AM</p>
      <h3>Ryanair cancels 400 flights amid French strikes</h3>
    	<p>Ryanair has blamed the cancellation of 400 flights today on strikes at French air traffic control, as it called for laws to protect flights which are travelling over countries.</p>	
    	<p>It tweeted that the vast majority of services affected were to or from Ireland, the UK, Germany, Italy and Spain - not ones landing in France.</p>	
    	<p>Last week, it submitted a petition signed by over 1.1m passengers “who are sick and tired of having their holidays destroyed because overflights are being unnecessarily cancelled” during French strikes.</p>
      <p>The company said:</p>
    	<p>While we respect the right of the French ATC unions to strike, it should be French domestic flights that get cancelled, not EU overflights.</p>	
    	<p>France (and all other EU states) should use minimum service laws to protect overflights during air traffic control strikes as they already do in Greece, Italy and Spain.</p>	
    	<p>We are calling on the EU Commission today to take action and protect overflights and stop the scandal of families having their holidays destroyed because their overflights are being unnecessarily cancelled at short notice due to repeated French ATC strikes.</p>
      <p>11:05 AM</p>
      <h3>CBI 'determined to learn from this crisis,' says new boss</h3>
    	<p>The CBI’s new director-general has said the business group is “determined to learn from the crisis that has unfolded in our organisation” ahead of a crunch vote today for remaining members.</p>	
    	<p>Rain Newton-Smith told BBC Radio 4’s Today programme:</p>	
    	<p>Even throughout this crisis, the vast majority of the CBI members have stayed with us.</p>
      <p>They’ve been very generous with their help, support and advice about the programme of change that we need to do, which is partly about our people and culture and putting that front and centre of the work we do.</p>
    	<p>Ms Newton-Smith said the CBI has already implemented three-quarters of recommendations following a series of investigations.</p>	
    	<p>I am coming back into this organisation as a female leader because I really believe in the power of having workplaces that are very inclusive and I’ve seen how the CBI has been able to do that in the past, but we clearly have work to do to improve our governance to also think about how we make it easy for people to speak up.</p>	
    	<p>We could have just gone behind closed doors and not shared some of the learnings that we’re making.</p>
      <p>So we’re being open about the journey we’re on, and I think that is what establishes trust from business, from government and from policymakers.</p>
    	<p>10:48 AM</p>	
      <h3>Pound loses momentum as dollar steadies</h3>
    	<p>The pound has slipped 0.2pc against the dollar, trading at around $1.24, as a rebound stalled overnight.</p>	
    	<p>The dollar has steadied after unexpectedly soft US services data on Monday firmed up expectations for a pause at the Federal Reserve’s meeting next week, but clouded the policy outlook for the months ahead.</p>
      <p>Sterling has fallen 0.1pc against the euro, which is worth 86p.</p>
    	<p>Meanwhile, gilts were up slightly, following gains on German bunds after a European Central Bank survey showed consumer expectations for euro-zone inflation decreased “significantly”.</p>	
    	<p>Yields on 10-year UK gilts have slipped three basis points, while two-year bonds have dropped two points.</p>	
    	<p>10:24 AM</p>
      <h3>Investors 'pull £628m from crypto exchange Binance'</h3>
      <p>Investors have pulled around $780m (£628.4m) from crypto exchange Binance in the last 24 hours, data company Nansen has said.</p>
    	<p>The withdrawals come a day after the world’s biggest crypto trading platform and its founder Changpeng Zhao were sued by the US Securities and Exchange Commission.</p>	
    	<p>Binance’s US affiliate exchange registered net outflows of $13m in the same period, Nansen said.</p>	
    	<p>10:10 AM</p>
      <h3>Oil prices slump despite Saudi Arabia's planned cuts</h3>
      <p>Oil prices have declined as traders weighed up the outlook for demand after Saudi Arabia’s surprise pledge for extra supply cuts.</p>
      <p>International benchmark Brent crude has fallen 2pc toward $75 a barrel, while US-produced West Texas Intermediate slumped 2.2pc below $71 a barrel.</p>
    	<p>Prices had surged early on Monday following the Saudi announcement after a tense Opec+ meeting, before giving up most of the gains during the session. The kingdom also raised its crude prices to Asia for July.</p>	
    	<p>Saudi Arabia pledged to do “whatever is necessary” to stabilise the market, revealing plans to cut production by a million barrels a day in July as concerns over the demand outlook, especially from China, weighed on prices.</p>	
    	<p>Oil tumbled 11pc last month, in part due to resilient Russian output, despite the Opec+ producer saying earlier this year it would reduce supply.</p>
      <p>09:56 AM</p>
      <h3>HS2 and other public projects 'provide stability' to construction sector</h3>
    	<p>Lloyds Bank’s infrastructure and construction team director Max Jones is optimistic about the UK construction industry, after the latest survey data showed the sector expanded in May. He said:</p>	
    	<p>Contractors are generally optimistic as HS2 and other publicly funded projects provide stability to order books.</p>	
    	<p>The industry has also been buoyed by demand for commercial projects in city centres, with London reaching a 20-year high.</p>
      <p>Welcome regulatory changes post-Grenfell mean contractors will be making substantial investments in the coming months to ensure their safety measures meet new requirements.</p>
      <p>While inflation has eased for most of the industry from last year’s highs, contractors will be hoping the latest official figures mean prices for materials will continue to fall and provide businesses with confidence to invest in projects.</p>
    	<p>09:39 AM</p>	
      <h3>Weaker housebuilding fails to dampen construction growth</h3>
    	<p>The construction industry was boosted by the fastest increase in activity in three months in May, even as house building declined at its steepest pace for three years, according to a closely-watched survey.</p>	
    	<p>The S&P Global/CIPS UK Construction Purchasing Managers’ Index picked up slightly to 51.6 in May, up from 51.1 in April and above the neutral 50 mark separating growth from contraction for the fourth successive month.</p>
      <p>Commercial building was the best-performing segment but worries about the impact of higher interest rates and subdued market conditions continued to dampen housing activity.</p>
    	<p>Residential work underperformed the rest of the construction sector by the greatest margin since October 2008.</p>	
    	<p>Tim Moore, economics director at S&P Global Market Intelligence, said:</p>	
    	<p>May data highlighted a mixed picture across the UK construction sector as solid growth rates in commercial and civil engineering activity contrasted with a steeper downturn in house building.</p>
      <p>Rising demand among corporate clients and contract awards on infrastructure projects meanwhile underpinned the fastest rise in new orders since April 2022.</p>
      <p>09:21 AM</p>
      <h3>Russian-linked cyber gang claims responsibility for massive data theft</h3>
    	<p>A Russian-speaking ransomware gang has claimed responsibility for the cyber attack in which thousands of British Airways, BBC and Boots employees may have had data stolen.</p>	
    	<p>Cybercrime group Clop said it was behind the theft of information including bank account details and national security numbers.</p>	
    	<p>The attack focused on Zellis, a company used to process payroll payments by a large number of major organisations including the NHS and Jaguar Land Rover. The hack is understood to have affected eight Zellis customers.</p>
      <p>Hackers exploited a backdoor in a piece of software used by Zellis called MOVEit, which is used to transfer files.</p>
    	<p>Progress Software, the maker of MOVEit, first identified the vulnerability last week. It told customers to “take immediate action” and delete any unauthorised user accounts added by hackers.</p>	
    	<p>The Clop gang told BleepingComputer they started exploiting the vulnerability on May 27, during the long US Memorial Day holiday.</p>	
    	<p>The ransomware gang added that they had deleted any data stolen from governments, the military, and children’s hospitals during these attacks.</p>
      <p>09:04 AM</p>
      <h3>China 'asks banks to cut deposit rates'</h3>
    	<p>Chinese authorities have reportedly asked the nation’s biggest banks to lower their deposit rates for at least the second time in less than a year, marking an escalated effort to boost the world’s second-largest economy.</p>	
    	<p>State-owned lenders including Bank of China, Industrial & Commercial Bank of China and Bank of Communications were last week advised to cut rates on a range of products, according to Bloomberg.</p>	
    	<p>This included cutting on-demand deposits by five basis points and three-year and five-year time deposits by at least 10 basis points.</p>
      <p>Banks are thought to be assessing the request and may adjust rates as early as this week.</p>
    	<p>Beijing has rolled out a raft of measures to prop up the economy after a series of crackdowns on multiple industries and lengthy lockdowns due to its zero-Covid approach.</p>	
    	<p>The authorities are seeking to boost lending to bolster a recovery after recent data showed a slowdown.</p>	
    	<p>08:49 AM</p>
      <h3>FTSE 100 dragged down by energy stocks</h3>
      <p>Shares in London were subdued in early trading as markets were dragged down by energy stocks, while investors awaited a crucial inflation data as they assessed the prospect of a global economic slowdown.</p>
      <p>The resource-heavy FTSE 100 slipped 0.2pc with energy stocks shedding 0.6pc tracking lower crude oil prices.</p>
    	<p>Domestically-focused FTSE 250 midcap index was flat.</p>	
    	<p>Markets are focused on the Purchasing Managers Index (PMI) for May, due out within the hour, after British retail sales growth slowed to a seven-month low during the same month.</p>	
    	<p>Car and personal goods stocks were the top gainers, while shares of beverages were the worst hit.</p>
      <p>British American Tobacco led gains on the FTSE 100, up 1.1pc, after the company maintained its annual revenue and profit forecasts.</p>
    	<p>08:43 AM</p>	
      <h3>CBI faces crunch vote on future</h3>
    	<p>Scandal-hit business lobby group the CBI faces a vote crucial on its survival later today with members urged to back reform under new leadership following allegations of sexual harassment by staff.</p>	
    	<p>The future of the Confederation of British Industry is at stake after claims that more than a dozen women were sexually harassed at the organisation and two others had been raped.</p>
      <p>Police have launched an investigation following the allegations reported this year by the Guardian, triggering a shake-up at the organisation and an extraordinary vote on its future.</p>
      <p>The allegations, described as “absolutely devastating” by new CBI director general Rain Newton-Smith, caused an exodus of member companies - and the launch of a rival body by the British Chambers of Commerce (BCC) on Monday.</p>
    	<p>Ms Rain-Smith, who has described the situation as a “really deep and painful crisis” for the CBI, will tell delegates she is “confident and determined this will be a turning point for us, the start of a new chapter, for a renewed CBI”.</p>	
    	<p>The resolution at the extraordinary general meeting, which begins at noon, calls on remaining member companies to put their confidence in CBI proposals to reform its “governance, culture, and purpose”.</p>	
    	<p>The result of the vote is expected at around 4pm.</p>
      <p>08:22 AM</p>
      <h3>Crypto miner Argo Blockchain's losses widen</h3>
    	<p>The UK’s only listed cryptocurrency miner has slumped to a first quarter loss even as the value of bitcoin surged and it reduced its operating expenses by 70pc.</p>	
    	<p>Revenues at Argo Blockchain plunged by 41pc to $11.4m (£9.2m) during a volatile time for crypto amid global regulatory crackdowns.</p>	
    	<p>The company mined a total of 491 bitcoin during the quarter, or 5.3 bitcoin per day, during a period when the value of the world’s largest cryptocurrency increased by 40pc.</p>
      <p>Mining is the process of creating new bitcoins by solving extremely complicated math problems that verify transactions in the currency. It requires vast levels of computing power and energy costs.</p>
      <p>Interim chief executive Seif El-Bakly said:</p>
    	<p>The Argo team is moving ahead with a focus on financial discipline, operational excellence, and growth and strategic partnerships.</p>	
    	<p>To support these initiatives, we recently strengthened our finance team and appointed Jim MacCallum as chief financial officer.</p>	
    	<p>In terms of financial discipline, we are taking a much more critical view of all operating expenses, and we’ve implemented a robust internal process aimed at reducing non-mining operating expenses.</p>
      <p>Compared to 2022, we’ve reduced our expenses by 70pc. We are also evaluating options to strengthen our balance sheet.</p>
    	<p>08:05 AM</p>	
      <h3>Markets fall at the open</h3>
    	<p>The FTSE 100 has nudged lower as it still reels from poor data on US services growth last month, raising concerns about the state of the world’s largest economy.</p>	
    	<p>The internationally-focused index slipped 0.2pc after the open to 7,595.60 while the midcap FTSE 250 lost 0.1pc to 19,100.88.</p>
      <p>07:56 AM</p>	
      <h3>SSE to pay £9.8m fine over excessive payments from National Grid</h3>
    	<p>Energy giant SSE’s power generation arm will pay a £9.8m penalty after breaching its licence, the industry regulator has announced.</p>	
    	<p>Ofgem said a detailed investigation found that SSE Generation secured excessive payments from the National Grid Electricity System Operator during a time of so-called transmission constraint.</p>
      <p>Cathryn Scott, director of enforcement and emerging issues at Ofgem, said:</p>	
    	<p>Protecting consumers is a priority for Ofgem, and we will continue to monitor the wholesale energy markets in Great Britain and ensure their integrity on behalf of energy users.</p>	
    	<p>This enforcement action sends another strong signal to all generators that they must put in place controls to ensure that their bid prices are set in a way that ensures that they do not obtain excessive benefits during transmission constraint periods.</p>
      <p>If they fail to do so, they will face significant consequences.</p>	
    	<p>07:48 AM</p>	
      <h3>Vapes and e-cigarettes lure customers to British American Tobacco</h3>
    	<p>British American Tobacco has said it gained 900,000 customers smoking new tobacco products like vapes and e-cigarettes in the first financial quarter, amid plans to encourage smokers away from traditional cigarettes.</p>
      <p>The tobacco giant said it is on track for tobacco alternatives to hit £5bn in revenue by 2025 and to be profitable by 2024.</p>	
    	<p>It comes as new chief executive Tadeu Marroco said “smokers must have access to better choices”, as he pledged to reduce the health impact of the business.</p>	
    	<p>The group expects organic revenue growth of between 3pc and 5pc in the year ahead, with sales impacted by the sale of its Russian and Belarusian businesses set to close in 2023.</p>
      <p>It stuck by its guidance for the full financial year.</p>	
    	<p>07:42 AM</p>
      <h3>Soaring number of public sector workers on benefits, says GMB</h3>	
    	<p>The number of public sector workers on Universal Credit has increased by more than 100,000 over the past few years, new research suggests.</p>
      <p>A study by the GMB union found that, between the end of 2019 and the end of 2022, the number of public sector workers in receipt of Universal Credit jumped by 119,000.</p>	
    	<p>The union said its analysis of official figures showed that, in the fourth quarter of 2019, 76,803 public sector workers were in receipt of Universal Credit.</p>	
    	<p>By the fourth quarter of 2022, that number had shot up to 195,772, said the union in a report to be published at its annual conference in Brighton today.</p>
      <p>GMB national secretary Rachel Harrison said: “It’s a stain on the honour of our nation so many public sector staff have to rely on working benefits to get by - NHS staff, care workers, school staff, and the council workers who keep our towns and cities alive.”</p>	
    	<p>07:37 AM</p>	
      <h3>The 33 articles Prince Harry claims Mirror newspapers obtained unlawfully</h3>
    	<p>The Duke of Sussex is due to give evidence at the High Court today in his case against the Daily Mirror’s publisher over alleged unlawful information gathering.</p>
      <p>On Monday, Harry’s individual case against Mirror Group Newspapers (MGN) got under way.</p>	
    	<p>Barrister David Sherborne claimed the Duke was subjected to unlawful information-gathering activity “right from when he was a young boy at school” into adulthood, adding: “Nothing was sacrosanct or out of bounds.”</p>	
    	<p>Harry is suing the publisher, claiming journalists at its titles - which also include the Sunday Mirror and Sunday People - were linked to methods including phone hacking, so-called “blagging” or gaining information by deception, and use of private investigators for unlawful activities.</p>
      <p>MGN is contesting the claims and has either denied or not admitted each of them. The publisher also argues that some of the claimants have brought their legal action too late.</p>	
    	<p>07:30 AM</p>	
      <h3>Bank Holidays fail to boost shop sales</h3>
    	<p>May’s trio of bank holidays failed to get shoppers spending as sales growth slowed to its lowest level in six months, latest figures show.</p>
      <p>Total UK retail sales increased by 3.9pc last month, against a decline of 1.1pc in May 2022 and below the three-month average growth of 4.7pc, according to the BRC-KPMG Retail Sales Monitor.</p>	
    	<p>Food sales were up 9.6pc on a year ago, boosted by the Coronation but still not sustained across the month.</p>	
    	<p>Meanwhile, growth in discretionary spending continued to tumble as the high cost of living squeezed households.</p>
      <p>The gloom continued for online retailers with just four categories registering positive sales figures and total sales down by 3pc.</p>	
    	<p>There was cause for some optimism, however, as brighter weather at the end of the month led to a much-needed pick-up in summer fashion sales, as well as gardening and DIY products.</p>	
    	<p>British Retail Consortium chief executive Helen Dickinson said: “With consumer confidence still recovering from record depths, and continued tightening of household incomes, we are unlikely to see substantial sales growth in the coming months.”</p>
      <p>07:23 AM</p>
      <h3>Primark owner buys dairy data business</h3>
      <p>Primark owner Associated British Foods has revealed it will buy a diary data company which helps farmers get maximum milk production from their cows.</p>
      <p>AB Foods will pay 215p a share in cash for National Milk Records (NMR), representing an 87pc premium on the company’s closing price on Monday.</p>
      <p>The £48m deal, which will take effect during the third quarter of this year, will support its AB Agri business, which supplies a wide range of animal feed, supplements and specialist ingredients in more than 80 countries, employing over 3,000 people internationally.</p>
      <p>José Nobre, chief executive of AB Agri, said:</p>
      <p>NMR is a high-quality business which is extremely complementary and additive to our dairy strategy and offering to the dairy industry.</p>
      <p>We have supported dairy farmers for more than 30 years with nutrition and specialty feed products, and more recently with data and technology platforms which deliver insights that create continuous improvement in agricultural supply chains.</p>
      <p>Acquiring NMR is an extension of this strategy and will enable us to service the industry better, offering products that deliver increased value, efficiency and ultimately profitability for dairy farmers.</p>
      <p>07:06 AM</p>
      <h3>US regulator takes aim at £92bn of crypto</h3>
      <p>The list of digital tokens deemed as unregistered securities by the Securities and Exchange Commission (SEC) now spans over $115bn (£92.4bn) of crypto after the US agency’s lawsuit against Binance.</p>
      <p>The regulator in the complaint on Monday cited a dozen coins as assets that fall under its purview.</p>
      <p>Such a designation comes with strict investor protection rules and could make the tokens harder to trade if exchanges shy away from listing them for fear of falling foul of the SEC.</p>
      <p>Binance’s BNB — which has a market value of $44bn — stablecoin BUSD, Cardano’s ADA, Solana’s SOL, Polygon’s MATIC, Filecoin’s FIL and Algorand’s ALGO were among those mentioned in the lawsuit.</p>
      <p>When added to other tokens like XRP separately targeted by the SEC, the agency has now categorised over $115 billion of coins specifically as unregistered securities.</p>
      <p>SEC Chair Gary Gensler has long said most tokens are subject to the agency’s investor-protection laws and that trading platforms should register with the regulator.</p>
      <p>However, labelling specific tokens represents a tougher approach. US officials have cracked down on digital assets this year following a rout in 2022 and a series of collapses, including the bankruptcy of the FTX exchange.</p>
      <p>07:00 AM</p>
      <h3>Good morning</h3>
      <p>Bitcoin plunged 3pc to below £21,000 as US regulators filed a lawsuit against the world’s largest cryptocurrency exchange and its founder.</p>
      <p>The SEC accused Binance and Changpeng Zhao of misusing investor funds, operating as an unregistered exchange and violating a slew of US securities laws.</p>
      <p>SEC chairman Gary Gensler in a written statement that Zhao and Binance “engaged in an extensive web of deception, conflicts of interest, lack of disclosure, and calculated evasion of the law”.</p>
      <h3>5 things to start your day</h3>
      <ol>
        <li>Russian hackers raid British Airways and BBC in cyber attack | Personal details of staff stolen following payroll provider hack</li>
        <li>Tim Cook unveils $3,500 Vision Pro headset in Apple’s most significant launch since iPhone | Apple said the Vision Pro, which will cost $3,499 (£2,814), will be “the most advanced personal electronics device ever” when it is released next year.</li>
        <li>Hunt plans biggest shake-up of employee ownership schemes in 23 years | Government aims to make it easier for workers to own shares in companies they work for</li>
        <li>House selling times soar to pandemic levels as mortgage rates climb | Four-bedroom homes worst hit as buyers opt for smaller properties</li>
        <li>BCC wins the ear of government in a blow to scandal-hit CBI | Lobbying group faces uncertain future as crucial vote looms</li>
      </ol>   
      <h3>What happened overnight</h3>  
      <p>Asian stock markets rose after Wall Street fell on concern the US economy may be weakening following a report that showed growth in service industries slowing.</p>
      <p>The Shanghai Composite Index rose 0.2pc to 3,237.89 and the Hang Seng in Hong Kong advanced 1.1pc to 19,310.53.</p>
      <p>The Nikkei 225 in Tokyo gained 0.4pc to 32,350.58 after government data showed Japanese wages rose 1pc over a year earlier in April but growth decelerated from the previous month’s 1.3pc.</p>
      <p>The S&P ASX 200 in Sydney shed 0.5pc to 7,181.90. South Korean markets were closed for a holiday.</p>
      <p>Wall Street stocks dipped Monday after the Institute for Supply Management’s closely-watched overall gauge of services unexpectedly fell to the lowest level of the year, conflicting with optimistic readings of the US economy.</p>
      <p>The Dow Jones Industrial Average dropped 0.6pc to 33,562.86. The broad-based S&P 500 shed 0.2pc to 4,273.79, while the tech-rich Nasdaq Composite Index declined 0.1pc to 13,229.43.</p>
      <p>The yield on 10-year Treasuries declined two basis points to 3.67pc amid speculation the Federal Reserve plans to keep interest rates steady in June with the possibility of increasing in later months.</p>
    </div>
  },
  {
    id: 549,
    title: 'Bitcoin, Ether skid; BNB drops more than 10% after U.S. securities regulator sues Binance',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Ether', 'BNB'],
    description: <div className="blog-desc">
      <p>Bitcoin fell almost 6% and Ether lost 5% in a broad market fall by all top 10 non-stablecoin cryptocurrencies on Tuesday morning in Asia after the U.S. Securities and Exchange Commission (SEC) sued cryptocurrency exchange Binance and its founder Changpeng Zhao on charges of securities violations.</p>
    </div>,
    authorName: 'Timmy Shen',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 6, 2023 - 4:23 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-606.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin fell almost 6% and Ether lost 5% in a broad market fall by all top 10 non-stablecoin cryptocurrencies on Tuesday morning in Asia after the U.S. Securities and Exchange Commission (SEC) sued cryptocurrency exchange Binance and its founder Changpeng Zhao on charges of securities violations. The BNB token issued by Binance was the biggest loser with a drop of more than 10% in the past 24 hours.</p>
    	<h3>Top 10 cryptos slide</h3>
      <p>Bitcoin dropped 5.66% over the last 24 hours to US$25,730 at 7:10 a.m. in Hong Kong, bringing losses over the past seven days to 7.23%, according to data from CoinMarketCap.</p>	
    	<p>Ether fell 4.91% in the same period to US$1,808, and logged a 4.56% weekly loss.</p>	
    	<p>BNB skidded 10.21% to US$275.17 in the last 24 hours, losing 11.61% over the past week.</p>
      <p>The SEC on Monday filed 13 charges against Binance, alleging the exchange made unregistered and unlawful offers and sales of its BNB and BUSD tokens. The SEC also alleged that Binance did not register Binance.com as an exchange or a broker-dealer clearing agency.</p>
    	<p>In response to the SEC lawsuit, Binance said in a Tuesday statement that it intends to defend its platform “vigorously.”</p>	
    	<p>“The SEC’s refusal to productively engage with us is just another example of the Commission’s misguided and conscious refusal to provide much-needed clarity and guidance to the digital asset industry,” Binance said.</p>	
    	<p>Cryptocurrencies traded on the Binance exchange, including BNB, BUSD, Solana, Cardano’s ADA, Polygon’s MATIC, are securities and require registration and regulation, according to the SEC suit.</p>
      <p>Solana dropped 9.52% over the past 24 hours, with ADA falling 7.73% and MATIC losing 7.64%.</p>
    	<p>“The total crypto market cap is down,” Justin d’Anethan, head of APAC business development at Belgium-based crypto market maker Keyrock, told Forkast on Tuesday morning in Asia. “The fact that the news doesn’t come as a shock doesn’t mean that it was priced in, apparently.”</p>	
    	<p>In a Monday tweet, SEC Chair Gary Gensler said that Zhao and Binance entities engaged in “an extensive web of deception, conflicts of interest, lack of disclosure and calculated evasion of the law.”</p>	
    	<p>In response, Zhao tweeted: “Wonder if he ever reads the comments under his post, from the consumers he is [supposed] to protect.”</p>
      <p>According to d’Anethan, the SEC’s legal action is not a surprise considering the U.S. Commodity Futures Trading Commission had earlier filed a lawsuit against Binance for alleged violations of trading rules for derivatives.</p>
    	<p>However, traders see the lawsuit as extremely bearish and it’s shaking up the market, he said.</p>	
    	<p>The total cryptocurrency market cap fell 4.6% to US$1.09 trillion in the 24 hours through to 8:30am Hong Kong, while trading volume surged 118% to more than US$48 billion.</p>	
    	<h3>Apple headset bumps Binance in NFT market</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index edged down 0.17% to 3,295.91 in the 24 hours to 9:20 a.m. in Hong Kong.</p>
    	<p>The SEC’s lawsuit against Binance “hasn’t had an impact on NFTs yet, but it likely will,” Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News, said on Tuesday. “It has already impacted crypto, and usually NFTs lag behind just a bit.”</p>	
    	<p>In the U.S. overnight, Apple Inc. unveiled its highly-anticipated mixed-reality headset Vision Pro. While priced at a hefty US$3,499, the headset has got the NFT industry excited about its potential application in metaverse developments.</p>	
    	<p>“Apple’s headset is really all the NFT spaces was talking about today and rightfully so,” Petscher said. “Really, it’s the single most important thing to happen to NFTs since CryptoPunks, even though Apple didn’t mention NFTs or the metaverse once in their reveal of the Vision Pro.”</p>
      <p>Sébastien Borget, co-founder and chief operating officer of decentralized gaming giant The Sandbox, said in a Tuesday interview with Forkast that Apple’s headset is “opening a big field of opportunity” for metaverse developments.</p>
    	<p>“This has the highest chance of becoming a game changer versus everything we’ve seen so far over the past five years in this field … allowing people to interact with their virtual assets in the physical world.”</p>	
    	<p>NFT sales on Ethereum rose 5.64% over the last 24 hours to US$25.75 million, and gained 90.84% in the past week. Sales on the Bitcoin network climbed 4.17% to US$3.16 million, but recorded a 11.87% weekly decline.</p>	
    	<p>Bored Ape Yacht Club (BAYC) and Azuki remained the two most-sold Ethereum collections. BAYC had the biggest sales in the past 24 hours of US$7.26 million, followed by Azuki’s US$2.65 million.</p>
      <p>DMarket, a Mythos blockchain-based collection of gaming NFTs, was the third most-sold collection over the past 24 hours with an 11.49% rise in sales to US$1.52 million.</p>
    	<h3>Coinbase shares hit by Binance</h3>
      <p>Shares of Coinbase, the largest crypto trading platform in the U.S., dropped 9.05% on Monday following the SEC’s lawsuit against Binance. Shares of MicroStrategy, the world’s largest corporate holder of Bitcoin, fell 8.53%.</p>	
    	<p>U.S. stock futures also moved marginally lower as of 10:00 a.m. in Hong Kong. Dow Jones Industrial Average futures dipped 0.05%, S&P 500 futures fell 0.04%. Nasdaq Futures fell 0.09%.</p>	
    	<p>Coinbase has its own legal battle with the SEC. In March, the SEC alleged Coinbase violated investor protection laws and threatened enforcement action against the exchange. Coinbase argued back in April that the SEC’s move appeared to be undermining its own role as the monitor of companies registering to sell shares to the public.</p>
      <p>Coinbase has also requested a court to compel the SEC to publicly respond to the exchange’s July 2022 petition asking for clearer crypto regulation guidelines.</p>	
    	<p>On the regulation front, Paul Grewal, chief legal officer of Coinbase, tweeted on Monday that he will testify on Tuesday before the House Committee on Agriculture on the need for clear crypto rules and on the Digital Asset Market Structure discussion draft released Friday.</p>	
    	<p>“The US is falling behind,” Grewal tweeted. “We cannot afford to ignore crypto while other markets take advantage of our absence, developing rules and regulations that enable the industry to thrive and risk sending jobs, investment, and technological leadership overseas.”</p>
    </div>
  },
  {
    id: 548,
    title: 'Bitcoin little-changed around US$27,000, Ether treads water; Solana, XRP gain',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Solana', 'XRP'],
    description: <div className="blog-desc">
      <p>Bitcoin traded little changed on Monday morning in Asia to hold above the US$27,000 threshold. Ether also traded flat, along with most other top 10 non-stablecoin cryptocurrencies.</p>
    </div>,
    authorName: 'Timmy Shen',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 5, 2023 - 4:28 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-603.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin traded little changed on Monday morning in Asia to hold above the US$27,000 threshold. Ether also traded flat, along with most other top 10 non-stablecoin cryptocurrencies.</p>
    	<p>However, Solana and XRP both gained more than 4%. U.S. stocks jumped Friday as a deal was approved to raise the debt ceiling, with the bill signed by U.S. President Joe Biden on Saturday to avoid a government default. U.S. Stock futures were trading flat on Monday morning in Asia. Investor focus has shifted to what will be the next move on interest rates by the Federal Reserve when it meets on June 14.</p>	
    	<h3>Bitcoin, Ether trade flat; Solana, XRP gain</h3>
      <p>Bitcoin edged up 0.87% over the last 24 hours to US$27,309 at 7:00 a.m. in Hong Kong, but is down 2.84% in the past seven days, according to data from CoinMarketCap. The world’s largest cryptocurrency fell to as low as $26,574 on Friday.</p>	
    	<p>Ether traded 0.67% higher in the past 24 hours to US$1,904, little changed for the week.</p>
      <p>Most other top 10 non-stablecoin cryptocurrencies traded flat. The exceptions were a 4.69% price jump for Solana, and a 4.19% gain for XRP.</p>	
    	<p>Solana’s move comes just as non-fungible token (NFT) secondary sales on the Solana network rose 21.62% over the last 24 hours, according to data from CryptoSlam.</p>
      <p>XRP is up 12.06% over the past week, gaining momentum after the XRP community reacted to the draft bill Digital Asset Market Structure Proposal released Friday by Republicans that seeks clarity in regulating cryptocurrencies as securities or commodities. Pro-XRP lawyer Jeremy Hogan tweeted that the discussion draft could be known as an “XRP holder protecting” act.</p>	
    	<p>“Investor sentiment across both digital assets as well as traditional finance appears to be in a holding pattern waiting for further indications from the U.S. Federal Reserve and other central banks as to anticipated interest rate movements in the third quarter of this year,” Nathan Simmons, chief compliance officer of Hong Kong-based digital asset platform VDX, said late Friday.</p>
      <h3>NFT 500 index falls, May transaction volume surges</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index dropped 1.58% to 3,281.22 in the 24 hours to 9:30 a.m. in Hong Kong, adding to a 2.38% weekly loss.</p>	
    	<p>The Forkast ETH NFT Composite index was down 1.17% in the same period, logging a 1.38% weekly decline.</p>
      <p>NFT sales on Ethereum fell 10% over the last 24 hours to US$23.65 million, but gained 83.97% in the past week. Sales on the Bitcoin network dropped 31.9% to US$3.82 million, but still recorded a 30% weekly increase.</p>	
    	<p>Bored Ape Yacht Club (BAYC) and Azuki remained the two most-sold Ethereum collections. BAYC had the biggest sales in the past 24 hours of US$5.1 million, followed by Azuki’s US$3.4 million and Mutant Ape Yacht Club’s US$1.7 million.</p>
      <p>Uncategorized Ordinals – recently popular Bitcoin Ordinals that are not part of an established collection – recorded a 45.24% drop to US$1.4 million in sales over the past 24 hours, but its seven-day sales gained 7.85%.</p>	
    	<p>DMarket, a Mythos blockchain-based collection of gaming NFTs, continued gaining momentum with a 18.34% rise in sales to US$1.35 million in the last 24 hours.</p>
      <p>Notably, the number of NFT transactions reached 8.9 million in May, the largest since February 2022, according to CryptoSlam data.</p>	
    	<p>“Before we get too excited we need to understand that the NFT landscape is vastly different from last year,” Yehudah Petscher, NFT Strategist at Forkast Labs, the parent company of Forkast.News, wrote in a commentary on Sunday.</p>
      <p>“Today, we have projects like DMarket’s gaming skins on Mythos Chain, which did over 2 million transactions in the past 30 days alone. Still, growth isn’t measured in just sales, and the May turnaround just might indicate that mass adoption is happening right under our noses,” Petscher added.</p>	
    	<h3>Biden signs debt bill; China, Japan services sectors expand in May</h3>
      <p>U.S. stock futures traded mixed as of 10:20 a.m. in Hong Kong. Dow Jones Industrial Average futures edged up 0.02%, while S&P 500 futures dipped 0.1%. Nasdaq Futures fell 0.32%.</p>
      <p>Crude oil prices rose after the OPEC+ group of oil-producing countries on Sunday decided to extend output cuts through next year, which could be an inflationary factor.</p>	
    	<p>On Saturday, U.S. President Joe Biden signed into law a bill to avoid a government default. The bill, passed by the U.S. Senate on Thursday, would suspend the U.S.’ debt limit through January 1, 2025.</p>
      <p>“I just signed into law a bipartisan budget agreement that prevents a first-ever default while reducing the deficit, safeguarding Social Security, Medicare, and Medicaid,” Biden tweeted on Saturday.</p>	
    	<p>In Asia, China’s services sector extended its recovery in May, with Caixin China General Services Purchasing Managers’ index (PMI) climbing to 57.1 from April’s 56.4.</p>
      <p>“Both services supply and demand expanded further in May,” Wang Zhe, senior economist of Caixin Insight Group, said in the PMI report. “Services activity continued to rebound after China scrapped its ‘zero-Covid’ policy in December.”</p>	
    	<p>Japan’s service sector also expanded in May, according to surveys by au Jibun Bank. Its Japan Services PMI for May came in at 55.9, or higher than 55.4 in April.</p>
      <p>“The Japanese service sector continued on its upward trend that began at the end of last year during May, with latest PMI data pointing to back-to-back record increases in business activity, new orders and exports,” Usamah Bhatti, an economist at S&P Global Market Intelligence, said in the report.</p>
      <p>“Firms were buoyed by the easing of the few remaining pandemic restrictions and have noted strong increases in demand, notably from overseas and inbound tourism,” Bhatti added.</p>
    </div>
  },
  {
    id: 547,
    title: 'Ripple locks 700M tokens as XRP turns 11',
    category: 'Crypto',
    subCategory: ['Ripple', 'tokens', 'XRP'],
    description: <div className="blog-desc">
      <p>XPR has now existed for eleven years, bringing its all-time performance to an impressive number.</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 3, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-600.jpg',
    content: <div className="blog-content">
    	<h3>In this articel:</h3>
    	<p>XPR has now existed for eleven years, bringing its all-time performance to an impressive number.</p>
      <ul>
        <li>The blockchain firm locked millions of XPR as part of its usual monthly procedure.</li>
        <li>XRP circulation increased in the last seven days.</li>
      </ul>
    	<p>The Ripple [XRP] token marked the 11th anniversary of its creation since the founding team developed it back on 2 June 2012. With the aim to create a better Bitcoin [BTC] version, David Schwarts, alongside Arthur Brito and Jed McCaleb, decided to create XPR on its own ledger— the XRP Ledger (XRPL).</p>	
    	<h3>Bolt-on the process</h3>
      <p>At press time, XRP’s value was $0.51. This represented an 8,679.89% hike in the price since it began trading live in the market.</p>	
    	<p>Prior to the commemoration, Ripple, the company behind the digital asset locked 700 million XRP tokens into a series of escrows.</p>
      <p>This move was part of Ripple’s ongoing efforts to manage the supply and distribution of XRP. The XRP escrow mechanism was designed to ensure a steady and predictable release of XRP into the market.</p>	
    	<p>Each month, a portion of the escrowed XRP is released, while the remaining amount is returned to a new escrow for future release.</p>
      <p>Although introduced in 2017, this process helps prevent large amounts of XRP from flooding the market at once, which could potentially impact its price and market stability. By locking 700 million XRP, Ripple demonstrated its commitment to responsible token management and reducing any potential concerns of market manipulation.</p>	
    	<h3>Decreasing issuance rising participation</h3>
      <p>Despite the action, the daily net amount of XRPL trust lines remained in the negative region at -2700. Trust lines are structures in the XRP ledger dedicated to holding XRP tokens. This decrease means that the momentum of  XRP issuance slowed down.</p>
      <p>Concerning its development activity, Ripple has not held back despite facing regulatory challenges. The metric served as a measure of the public GitHub contribution to the Ripple network.</p>	
    	<p>As of this writing, the metric was 5.17, as shown above. Hence, this suggested increased deduction toward polishing the Ripple blockchain.</p>
      <p>In addition, XRP’s seven-day circulation increased to 2.93 billion. Circulation reflected the number of unique tokens transacted within a day.</p>	
    	<p>Oftentimes, an increase in this metric means increased trading activity. However, its increase implies a hesitation in market participation. However, this has failed to show in the transaction which has severely decreased since 28 May.</p>
      <p>In conclusion, Ripple’s decision to lock a significant amount of XRP in escrow aligns with its long-term strategy. One such happens to be its aim to foster confidence and trust in the XRP ecosystem.</p>	
    	<p>As for the token, short to long-term performance might depend on the final judgment in its case with the SEC. For now, the community is optimistic about the outcome.</p>
    </div>
  },
  {
    id: 546,
    title: 'These Bitcoin metrics signal potential bull run as…',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'metrics', 'bull'],
    description: <div className="blog-desc">
      <p>Amidst the wild price swings, Bitcoin’s Realized Cap finds stability, hinting at a potential bull run. Miners profit as price falters in recent trades.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 3, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-599.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Amidst the wild price swings, Bitcoin’s Realized Cap finds stability, hinting at a potential bull run. Miners profit as price falters in recent trades.</p>
      <ul>
        <li>Bitcoin’s Realized Cap stabilizes and shows positive signs, potentially driving a bull run.</li>
        <li>BTC miners enjoy profitable returns while the cryptocurrency’s price experiences a slight loss in recent trading.</li>
      </ul>
    	<p>Bitcoin’s price has been on a rollercoaster ride lately, showing signs of volatility over the past few months. However, amidst these fluctuations, there were some encouraging developments to note.</p>	
    	<p>Recent data suggested that BTC was experiencing a positive shift in one of its crucial metrics. This improvement could potentially play a significant role in driving its bull run.</p>	
    	<h3>Bitcoin Realized Cap flashes positive</h3>
      <p>The Bitcoin Realized Cap, an essential metric in on-chain analysis, holds significant importance as it serves as the on-chain counterpart to Market Cap. It evaluates the value of each coin in circulation based on its last on-chain movement.</p>
      <p>After experiencing a notable outflow of capital, the Bitcoin Realized Cap has now stabilized and is starting to witness a net inflow of capital once again.</p>
      <p>However, compared to previous bull market conditions, this trend was still in its early stages, both in terms of duration and magnitude, as indicated by the recent Glassnode chart. Examining the Net Position Change of the realized BTC market cap revealed that it was currently positive but fading.</p>
      <p>As of this writing, the Relative Net Position change stood at approximately 1.19%. Moreover, the Realized Cap remained consistent at over $391.7 million for the past month.</p>
      <h3>Percent Supply in profit sees an uptrend</h3>
      <p>As the Realized Cap showed positive developments after over a year of being below it, the Bitcoin Percent Supply in Profit also experienced some upward movement.</p>
      <p>Analyzing the chart based on Glassnode data, this metric initiated an upward trend at the beginning of the year, reversing the decline observed in the previous year.</p>
      <p>As of this writing, the Percent Supply in Profit stood at over 68.4% based on the current spot price.</p>
      <p>While this signified a substantial profit level, it was still lower than the levels reached during the previous year before the decline occurred.</p>
      <h3>BTC miners enjoy profitable returns</h3>
      <p>Bitcoin miners have been experiencing a favorable trend in recent weeks, enjoying positive momentum. Despite the prevailing market conditions, Bitcoin miners have managed to maintain profitability.</p>
      <p>According to a recent Glassnode chart, they collectively generated a noteworthy revenue of $24.1 million, which included earnings from the Block Subsidy and Transaction Fees. Considering an estimated production cost of $19.1 million, this translated to a net profit exceeding $5 million.</p>
      <p>Given the sustained growth in transactions on the Bitcoin network and an upward trajectory in price, there was potential for the profit margin to expand further.</p>
      <h3>Bitcoin Daily timeframe chart</h3>
      <p>Following a modest upward movement in Bitcoin’s price towards the end of trading on June 2nd, the cryptocurrency began the current trading period with a slight loss. As of this writing, it was trading at approximately $27,220, reflecting a minor decrease of less than 1%.</p>
      <p>The volume indicator indicated a lack of significant activity in BTC’s movement, suggesting a relatively quiet market.</p>
      <p>Additionally, BTC was trapped in a bearish trend, as indicated by its Relative Strength Index (RSI) line, which remained below the neutral line.</p>
    </div>
  },
  {
    id: 545,
    title: 'Shiba Inu in liquidity squeeze – End of the road for SHIB?',
    category: 'Crypto',
    subCategory: ['Shiba', 'liquidity', 'SHIB'],
    description: <div className="blog-desc">
      <p>As a tier-1 memecoin, Shiba Inu has struggled with attracting the kind of liquidity it had in 2021. The path ahead is limited for SHIB, so where can it head next?</p>
    </div>,
    authorName: 'Victor Olanrewaju',
    authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 3, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-598.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>As a tier-1 memecoin, Shiba Inu has struggled with attracting the kind of liquidity it had in 2021. The path ahead is limited for SHIB, so where can it head next?</p>
    	<ul>
        <li>Investors overlooked contributing to ShibaSwap’s TVL.</li>
        <li>SHIB’s volume remains unimpressive while its price shredded significant numbers in the last 90 days.</li>
      </ul>
      <p>At the height of the 2021 bull market, Shiba Inu [SHIB] was at the forefront of leading price rallies. This performance, and the attention it gained, led ShibaSwap’s Total Value Locked (TVL) to hit as high as $1.75 billion.</p>	
    	<p>For the unfamiliar, ShibaSwap is the native Decentralized Exchange (DEX) of the Shiba Inu ecosystem. The TVL measures the way users provide liquidity to stake and swap tokens on the exchange.</p>	
    	<p>Unfortunately, the same TVL, which was in the billion-dollar range, had decreased massively. According to DefiLlama, ShibaSwap’s TVL was now $25.16 million.</p>
      <h3>No longer enticing to the big guns?</h3>
      <p>This depicts a situation where investors were no longer interested in adding volume to the smart contracts projects under the ecosystem. This affected ShibaSwap’s revenue and fees generated, all the while sparking speculation about a liquidity squeeze.</p>
      <p>In crypto, a liquidity squeeze occurs when there is a lack of deposits and conversion in transactions in a cryptocurrency. Sometimes, this results from the lack of contribution by whales.</p>
      <p>On evaluating on-chain data, Santiment revealed that investors who hold between 1-1,000,000 SHIB tokens have been increasing their balance. This implies that the retail cohort somewhat believes in the long-term performance of the meme.</p>
      <p>However, it was a different situation with addresses that hold between 1,000,000-1,000,000,000 tokens. In this division, there were mostly cuts from the balances.</p>
      <p>This signifies profit-taking and a possible belief that SHIB may no longer produce the sort of performance when it came into the spotlight.</p>
      <h3>Shrink in volume and wakening holders</h3>
      <p>As for the volume, it was only occasionally that the metric reached impressive levels. At press time, SHIB’s volume was down to 78.14 million.</p>
      <p>This metric measures the level of trading activity of a cryptocurrency in the entire market. And when the volume increases, it signifies a high demand for the asset in question. But in SHIB’s case, it was the opposite.</p>
      <p>Furthermore, the dormant circulation, although minimal in the last 365 days, has been relatively frequent in the last 90 days.</p>
      <p>The metric shows the number of unique tokens transacted daily that have not moved in a long while. When blockchain data shows a notable spike in the metric, it means that long-term holders have moved a considerable number of tokens.</p>
      <p>And according to Santiment’s data above, SHIB’s 90-day dormant circulation hit trillions several times between March and the time of writing. Thus, there was a possibility that these tokens were moved into exchanges.</p>
      <p>At press time, SHIB exchanged hands at $0.000009. This represented a 23.09% decrease in value in the last three months.</p>
    </div>
  },
  {
    id: 541,
    title: 'Bitcoin rises above US$27,000, Litecoin leads gains among top 10 cryptos',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Rises', 'Litecoin'],
    description: <div className="blog-desc">
      <p>Bitcoin and Ether gained in Friday afternoon trade in Asia along with all other top 10 non-stablecoin cryptocurrencies, following successful negotiations on raising the U.S. debt</p>
    </div>,
    authorName: 'Pradipta Mukherjee',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 2, 2023 - 1:50 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-575.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin and Ether gained in Friday afternoon trade in Asia along with all other top 10 non-stablecoin cryptocurrencies, following successful negotiations on raising the U.S. debt ceiling that injected some optimism into markets. Litecoin was the biggest gainer in 24 hours.</p>
    	<h3>Bitcoin gains, First Digital issues new stablecoin</h3>
      <p>Bitcoin, world’s largest cryptocurrency, rose 1.08% to US$27,076 in 24 hours to 4 p.m. in Hong Kong, bringing its weekly gains to 2.08%, according to CoinMarketCap data.</p>	
    	<p>Ether, the second biggest cryptocurrency in the world, gained 1.78% to US$1,886 in 24 hours, after rising 3.85% in the last seven days.</p>	
    	<p>Litecoin was the biggest gainer among top 10 cryptos, climbing 2.25% to US$94.48 in 24 hours, and strengthening 8.35% on the week. It rose on stronger buying support from its third halving event that is set for Aug. 2, which would reduce supply of the token.</p>
      <p>Litecoin’s on-chain activities increased in May, according to blockchain data tracker IntoTheBlock on Tuesday, which noted almost 8.5 million Litecoin addresses with a balance by the end of May, compared to 7.09 million addresses by the end of March.</p>
      <p>The global crypto market capitalization strengthened 1.21% to US$1.14 trillion, while the total crypto market volume lost 5.75% to US$29.94 billion in the last 24 hours.</p>
      <p>Hong Kong-based consultancy First Digital, has introduced a new stablecoin pegged to the U.S. dollar, First Digital USD (FDUSD), it said in a statement on June 1, the same day that Hong Kong’s new licensing regime for virtual asset service providers took effect.</p>
      <p>Issued by FD121 Limited, a subsidiary of the trust company and under the brand name First Digital Labs, the stablecoin is intended to be backed on a 1:1 basis by one U.S. dollar or asset of equivalent fair value, held in accounts of regulated financial institutions in Asia, the statement said.</p>
    </div>
  },
  {
    id: 544,
    title: 'Solana’s key metrics grow, but there’s a catch',
    category: 'Crypto',
    subCategory: ['Solana', 'metrics', 'grow'],
    description: <div className="blog-desc">
      <p>Solana’s NFT trade count and trade volume in USD both spiked in the last seven days.</p>
    </div>, 
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 1, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-596.jpg',
    content: <div className="blog-content">
      <p>Solana’s NFT trade count and trade volume in USD both spiked in the last seven days.</p>
    	<ul>
        <li>SOL’s TVL and DEX volumes registered upticks, which looked encouraging.</li>
        <li>Despite a green weekly chart, SOL’s market indicators remained bearish.</li>
      </ul>
      <p>Solana’s [SOL] Magic Eden has become the top performing NFT player in its ecosystem. The network’s NFT metrics also went green last week, as evident from CRYPTOSLAM’s charts.</p>
    	<p>As per the data, the number of NFT sellers and buyers both went up by more than 20% in the last seven days. Not only that, but the number of transactions also increased marginally.</p>	
    	<p>The same growth was also noted on Santiment’s chart as SOL’s NFT trade counts registered an uptick. Its NFT trade volume in USD also spiked, further reflecting growth in the NFT ecosystem.</p>	
    	<h3>Solana NFT ecosystem is growing along</h3>
      <p>Solana noted growth on other fronts as well. For instance, after a dip, SOL’s daily transactions gained upward momentum.</p>
      <p>Additionally, its DEX volume also spiked, reflecting its popularity among traders. Artemis’ data revealed that Solana’s TVL also increased in the last few days, which by and large is a positive signal for a blockchain.</p>
      <h3>Investors’ confidence improved</h3>
      <p>The improvement in the metrics might also have played a role in improving sentiment around SOL in the market. After a dip, Solana’s weighted sentiments registered an uptick, which is a positive signal.</p>
      <p>Moreover, its social volume also increased, again reflecting the token’s popularity in the crypto space. Interestingly, SOL’s development activity was also high, which indicated that the developers were pouring in more effort to improve the network.</p>
      <h3>Solana’s weekly chart is bullish</h3>
      <p>As per CoinMarketCap, SOL’s price increased by more than 6% in the last seven days, which was bullish. At the time of writing, SOL was trading at $20.68 with a market capitalization of over $8 billion, making Solana the 10th largest crypto by market cap.</p>
      <p>SOL’s MACD gave hope for a further increase in price as it showed a bullish upper hand in the market. </p>
      <p>However, the rest of the indicators were bearish on SOL. For instance, the Relative Strength Index was resting at the neutral mark. SOL’s Money Flow Index was also below the neutral zone.</p>
      <p>The Chaikin Money Flow registered a downtick, further increasing the chances of a price dip. In addition to that SOL’s Exponential Moving Average (EMA) Ribbon also supported the bears as the 20-day EMA was below the 55-day EMA.</p>
    </div>
  },
  {
    id: 540,
    title: 'Bitcoin prices fall 7% in May, on pace for first monthly loss of 2023',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'prices', 'fall'],
    description: <div className="blog-desc">
      <p>Bitcoin is on track to post its first monthly loss of 2023, as the cryptocurrency hovers above $27,000.</p>
    </div>,
    authorName: 'Yahoo Finance',
    authorurl: 'https://www.yahoo.com/author/yahoo--finance/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'June 1, 2023 - 12:26 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-576.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin is on track to post its first monthly loss of 2023, as the cryptocurrency hovers above $27,000. Yahoo Finance crypto reporter David Hollerith takes a look at how the cryptocurrency has traded over the last year and how other digital assets are faring so far in 2023.</p>
    	<p>- All right. Let's take a look at crypto. Bitcoin sliding over 7% in May. Headed for its first in negative month in just about a year here. David Hollerith-- of the year. Excuse me. David Hollerith is here with a closer look at the declines that we're seeing in that space. David.</p>	
	    <p>DAVID HOLLERITH: Hey, Seana. Yeah. After four months in the green, Bitcoin is closing down about 7%. It's hit a peak in mid-April of around $31,000. And since then, it's fluctuated in this range between $30,000 and $26,000. More recently, since that peak, it's lost about $66 billion in its market cap value. And so this total rise year to date has actually still been 63%. So that's since the beginning of the year. And that has far outpaced the S&P and NASDAQ but has fallen short of those major stock indexes, with a 15% loss from a year ago today.</p>	
	    <p>So excluding this past day which we've seen, the volatility for Bitcoin and other cryptocurrencies has dropped significantly in the past 30 days. And that signals a lack of selling or buying conviction amongst investors. Obviously, the hot labor data and Fed official talk has not been kind to Bitcoin either. Edward Moya, who is with OANDA, was telling us that Wall Street is appearing not to take as much interest into crypto as it has in past years. I say crypto. He said Bitcoin specifically.</p>
      <p>And it's important to point out that this time last year, Bitcoin was at the beginning of what was a very long sell off. It was pretty rough. And we saw a lot of company failures and potentially some fraud. So even though it's a down month and things seem to be quiet, that might be interpreted as a better thing. Bitcoin and cryptocurrencies in general are still sort of trying to get past the hype from 2021 and are kind of looking for more application or utility for adoption.</p>
      <p>So that's the month of May. In June, it's about a toss up historically in terms of what prices have done. We've seen gains in the month of June for about 5 of the 10 past years. And July is usually better. It's the third best month historically for Bitcoin and crypto assets more broadly. I also do want to point out the stablecoin Tether has been on a tear. Since around the time of regional bank turmoil, which started in March, Tether has gained about $11 billion in market cap value. Its second competitor, Circle's USD coin, has lost about $14 billion for the same period. Diane.</p>
    </div>
  },
  {
    id: 539,
    title: 'USDT Issuer Tether Ventures Into Payment Processing With Georgia Investment',
    category: 'Crypto',
    subCategory: ['USDT', 'Tether', 'Georgia'],
    description: <div className="blog-desc">
      <p>Tether, issuer of the $83 billion USDT stablecoin, has invested an undisclosed amount in payment processor firm CityPay.io, according to a Wednesday press release.</p>
    </div>,
    authorName: 'Krisztian Sandor',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 31, 2023 - 7:00 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-577.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Tether, issuer of the $83 billion USDT stablecoin, has invested an undisclosed amount in payment processor firm CityPay.io, according to a Wednesday press release.</p>
    	<p>CityPay.io added USDT as payment option for users in over 600 locations including restaurants, shops and hotels in the Caucasian country of Georgia, Tether tweeted. CityPay.io linked to Binance Pay, the payment arm of the world’s largest crypto exchange, allowing purchases with cryptocurrencies earlier this year, Binance tweeted.</p>	
    	<p>Tether’s investment is in line with a new plan to allocate a part of its excess profits to purchase bitcoin (BTC) and venture into infrastructure, payments and communication projects.</p>	
    	<p>The company reported almost $1.5 billion in net profit for the first quarter. Earlier this week, Tether also announced an investment in sustainable energy production and bitcoin mining in Uruguay.</p>
      <p>Stablecoins are a key piece of plumbing in the crypto ecosystem, bridging government-issued currencies and facilitating trading. Issuers are increasingly boosting stablecoins as a means for payments and remittances, especially in the developing world.</p>
      <p>Recently, Bitcoin-based payments app Strike integrated USDT as part of its global expansion to 65 countries. Earlier, rival stablecoin issuer Circle teamed up with payment firm Block’s (SQ) subsidiary TBD to offer remittance payments using its own stablecoin, USDC.</p>
      <p>Paolo Ardoino, Tether’s chief technology officer, said in a podcast episode with The Block that USDT is increasingly used for value transfers, making up about 40% of all token usage, compared to 60% of crypto trading.</p>
    </div>
  },
  {
    id: 538,
    title: 'Bitcoin, Ether flat; XRP jumps; U.S. equity futures mixed ahead of debt ceiling vote',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Ether', 'XRP'],
    description: <div className="blog-desc">
      <p>Bitcoin traded flat on Wednesday morning in Asia, after briefly breaching the US$28,000 level on Tuesday night. Ether dipped below the US$1,900 mark, while other top 10 non-stablecoin cryptocurrencies traded mixed.</p>
    </div>,
    authorName: 'Tom Zuo',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 31, 2023 - 7:55 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-578.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
	    <p>Bitcoin traded flat on Wednesday morning in Asia, after briefly breaching the US$28,000 level on Tuesday night. Ether dipped below the US$1,900 mark, while other top 10 non-stablecoin cryptocurrencies traded mixed.</p>
	    <p>XRP led the winners following crypto-related legal developments involving the U.S. Securities and Exchange Commission (SEC). Stock futures in the U.S. traded mixed, as investors wait to see if the U.S. debt ceiling deal passes through Congress. The deal faces opposition from some House Republicans. Meanwhile, concerns over further U.S. interest rate hikes and debt issuance continue to limit investor appetite for risk assets.</p>	
	    <h3>Bitcoin, Ether flat; XRP surges</h3>
      <p>Bitcoin dipped 0.35% over the last 24 hours to US$27,666 at 10:00 a.m. in Hong Kong, but was trading 1.92% higher for the week, according to data from CoinMarketCap. The world’s most dominant cryptocurrency reached a high of US$28,044 on Tuesday evening in Asia. However, it soon fell back down below the US$28,000 resistance level.</p>	
	    <p>Digital asset investment products saw outflows totalling US$39m in the week ending May 26, according to a Tuesday report from European cryptocurrency investment firm CoinShares. Bitcoin-linked products accounted for almost a third of those outflows. The six-week run of outflows totals US$272 million — an indication that bearish sentiment continues to weigh heavily on the crypto market.</p>
      <p>Ether inched down 0.13% to US$1,895, but gained 2.66% over the past seven days. The token has been trading around the US$1,900 resistance level since the start of the week.</p>
      <p>Most other top 10 non-stablecoin cryptocurrencies traded flat to lower in the past 24 hours, with the exception of XRP, Solana and Litecoin.</p>
      <p>XRP led the winners, jumping 6.15% in the past 24 hours to US$0.5209. That rise expanded its weekly gain to 12.75%.</p>
      <p>Markus Thielen, Head of Crypto Research at digital asset service platform Matrixport, suggested in a note that the token’s surge could be linked to the announcement of a settlement reached Monday between the SEC and a former manager at cryptocurrency exchange Coinbase. The former Coinbase employee agreed to SEC charges that he had engaged in insider trading.</p>
      <p>That resolution could, Thielen suggests, benefit Ripple — the issuer of XRP — in its own legal dispute with the SEC, leading to gains for XRP.</p>
      <p>Elsewhere, Dogecoin led the losers. The memecoin dropped 1.03% to US$0.07242, dipping 0.06% for the week.</p>
      <p>The total market cap of cryptocurrencies edged up 0.32% in the past 24 hours to US$1.16 trillion, while the 24-hour market volume inched 2.02% higher to US$30.06 billion.</p>
      <h3>Excitement returns to NFT market</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index dipped 0.22% to 3,403.49 in the 24 hours to 10:50 a.m. in Hong Kong, but edged up 0.32% for the week.</p>
      <p>NFT sales on Ethereum rose 22.00% in the past 24 hours to US$18.23 million. That rise was spearheaded by Bored Ape Yacht Club (BAYC) transactions, according to CryptoSlam data. Meanwhile, NFT sales on Bitcoin fell 18.77% to US$3.52 million.</p>
      <p>BAYC sales totaled US$2.86 million on Tuesday, almost three times higher than sales of the collection throughout Monday.</p>
      <p>“The rise in BAYC volume is interesting as some of the larger transactions price doesn’t match the traits/rarity,” said Eric Dettman, NFT advisor at CryptoSlam.</p>
      <p>“They aren’t washes on paper but there may be some behind the scenes dealing going on.”</p>
      <p>BRC-721E token standard was launched on Monday. The initiative allows traders to move their Ethereum-based NFTs to the Bitcoin network. That process is irreversible as it permanently burns the NFT on the Ethereum network before transfer.</p>
      <p>“Now we’ll get to see traders’ convictions on the Bitcoin ecosystem play out in real time,” said Yehudah Petscher, NFT Strategist at Forkast Labs. “At this point, no one can deny that Bitcoin’s NFTs are here to stay, but the question of ‘Will Bitcoin NFTs flip Ethereum NFTs?’ is another story. I think they will.”</p>
      <p>NFT trader sentiment is also seeing a boost from Web3 developments in China and Russia.</p>
      <p>On May 23, China’s national broadcaster CCTV reported on Hong Kong’s recent crypto regulation drive. That unexpected move preceded the release of a Web 3.0 white paper by the municipal government of Beijing. The wide-reaching paper covers sectors including NFTs and the metaverse.</p>
      <p>“China is coming into NFTs and crypto, and Russia will use crypto to settle payments across borders,” said Petscher. “These two major announcements are bringing excitement back to the NFT and crypto markets and lead many to believe that these alone can turn the market around.”</p>
      <h3>U.S. futures dip before debt ceiling vote</h3>     
      <p>U.S. stock futures fell as of 12:00 p.m. in Hong Kong, with Dow Jones Industrial Average futures dipping 0.34%, S&P 500 futures down 0.25%, and the Nasdaq futures losing 0.15%.</p>
      <p>Investors are jittery ahead of the U.S. Congress’ vote on the debt ceiling deal to avoid a default in the world’s biggest economy. The House vote is on Wednesday and could face objections, according to the Associated Press.</p>
      <p>An additional concern for investors is interest rate policy at the Federal Reserve. Loretta Mester, president of the Federal Reserve Bank of Cleveland, told the Financial Times there was no “compelling reason to pause” rate hikes in June, citing “embedded, stubborn inflationary pressure,” according to a report on Wednesday.</p>
      <p>The Federal Reserve meets on June 14 to decide on rates, which are now between 5 and 5.25%, the highest since 2006. The CME FedWatch Tool predicts a 37.0% chance the Fed will keep rates unchanged in June, and a 63.0% chance for another 25 basis-point rate hike, up from 59.6% on Tuesday.</p>
      <p>The U.S. employment report will be released on Friday, giving another insight into the state of the economy and inflation pressure.</p>
    </div>
  },
  {
    id: 543,
    title: 'Bitcoin: Is 2023 the best time to ‘buy the dip’',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'buy', 'dip'],
    description: <div className="blog-desc">
      <p>Buy the dip is an investment strategy that revolves around acquiring a token when its trading price declines. Does that apply to BTC in 2023?</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 30, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-595.jpg',
    content: <div className="blog-content">
    	<p>Buy the dip is an investment strategy that revolves around acquiring a token when its trading price declines. Does that apply to BTC in 2023?</p>
    	<p>The crypto-market is infamous for being highly volatile, with the same often fueling a shift in market sentiment. When the market witnesses a price correction, several investors tend to dump their assets in order to minimize their losses. However, many others follow the “buy the dip” strategy as it often bears fruit. History suggests that the market always bounces back when fear among investors is at its peak, giving investors the opportunity to maximize profits.</p>	
    	<h3>Never miss an opening</h3>
      <p>For starters, buy the dip is a popular investing strategy that revolves around acquiring an asset at a lower price, hoping that the market will bounce back. Opting for this strategy has been useful when a crypto’s price declines due to the doings of a third party and not based on the asset’s real-world use or performance. Therefore, buying at such a time gives investors the opportunity to increase profits as the market will most likely rise.</p>	
    	<h3>The 2021 crypto market dip</h3>
      <p>The crypto-market recorded a fall in early 2021 due to several reasons, including the Russia-Ukraine war. At that time, Bitcoin’s [BTC] price declined from $60,000 and drifted below $30,000, a nearly 50% depreciation. The market was quick to recover though as just in a few months, BTC’s price soared on the charts.</p>
      <p>In November 2021, the crypto’s price hit an all-time high of over $64,000. However, the crypto-winter followed, once again causing the market to decline. Ethereum [ETH] also saw a similar trend in its price during that period, when its price touched an ATH of $4,700.</p>
      <h3>Did investors buy the dip? </h3>
      <p>During the 2021 episode, a look at BTC’s on-chain metrics clearly revealed that investors were buying the dip. As per Santiment’s chart, after November, when BTC’s price plummeted, its supply on exchanges declined.</p>
      <p>This happened while BTC’s supply outside of exchanges rose – A sign of increased accumulation.</p>
      <p>Not only BTC, but ETH metrics also conveyed a similar story.</p>
      <p>Ethereum’s supply on exchanges declined, with the same accompanied by a hike in supply outside of exchanges. Moreover, the chart also revealed that ETH’s supply held by top addresses was also on the rise, reflecting investors’ trust in the token. However, ETH’s network growth declined over that period, indicating fewer new addresses were created to transfer the token.</p>
      <h3>2022 was no better</h3>
      <p>The series of mishaps did not come to an end in 2021. The following year began on a sour note, and it was further worsened by the Terra LUNA collapse. It severely affected the prices of all the cryptos, the effects of which are visible to date.</p>
      <p>However, it was interesting to see that a similar “buy the dip” trend was also seen during that period as investors remained confident for a change in the market’s fate over the coming years.</p>
      <h3>Will the market revive itself anytime soon?</h3>
      <p>Though 2023’s opening quarter was better as the market gained bullish momentum, the good days were short-lived.</p>
      <p>Right now, BTC seems to have settled under the $28,000-level – A concern for investors. However, this might be a good opening for investors to accumulate or rather “buy the dip”, before BTC’s price action once again turns bullish.</p>
      <h3>BTC halving is less than a year away</h3>
      <p>Bitcoin is scheduled to undergo its fourth halving next year in the month of April. If history is to be believed, Bitcoin’s price can skyrocket after its halving.</p>
      <p>For instance, during 2020’s Bitcoin halving, it was valued at $8,500, but it took a few months to climb over $27,000. A similar trend was seen during the first Bitcoin halving. The value of Bitcoin surged considerably in November 2013, after its first halving in November 2012. Therefore, this might be an appropriate opportunity for investors to accumulate BTC.</p>
      <h3>Investors are still in accumulation mode</h3>
      <p>A similar hike in the amount of supply outside of exchanges was also plotted on last month’s graph, indicating that investors were still buying. Not only that, but BTC’s Binance funding rate has been substantially high too.</p>
      <p>A high funding rate is a sign of demand in the derivatives market. Positive sentiment around Bitcoin is also high – A sign of investors’ confidence in the king coin.</p>
    </div>
  },
  {
    id: 537,
    title: 'Bitcoin Trades at a 20% Discount on Binance Australia Following Banking Issues in the Country',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Binance', 'Australia'],
    description: <div className="blog-desc">
      <p>Bitcoin was trading at about 20% discount on Binance’s Australia arm on Tuesday, compared with rival exchanges, according to data from CCData.</p>
    </div>,
    authorName: 'Lyllah Ledesma',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 30, 2023 - 10:08 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-579.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin was trading at about 20% discount on Binance’s Australia arm on Tuesday, compared with rival exchanges, according to data from CCData.</p>
    	<p>This comes after Binance Australia halted Australian dollar bank transfers earlier this month, citing its third-party payment service provider as the issue and said that it's working to find an alternative. Binance Australia will allow AUD withdrawals until June 1.</p>	  
    	<p>“The announcement from Binance prompted traders to sell their BTC/AUD pairs, resulting in the price reaching a historically high discount,” said Hosam Mahmoud, Research Analyst at CCData in a conversation with CoinDesk.</p>	
    	<p>Bitcoin was trading at around $33,400 AUD ($21,700) on Binance compared to other exchanges such as Kraken, Coinjar and BTCMarkets, where it was trading at around $42,500 AUD ($27,700), according to CCData.</p>
      <p>Binance Australia also had its derivatives license canceled in April, after the exchange made the request for it to be shut down.</p>
      <p>Although the exchange assured customers that it will continue to operate in Australia, the effort hasn't successfully addressed the issue, according to Mahmoud. “This is evidenced by record-low trading volumes for the BTC/AUD pairs,” he said.</p>
      <p>Daily trading volumes on Binance for the BTC/AUD pair were at 12,293,856.55 on May 18 prior to the announcement, and dropped to a low of 912,297.20 on May 20, according to CCData.</p>
      <p>“According to our data, despite the significant discount, arbitrageurs are taking advantage of the lower prices, suggesting that the problem may be resolved once the funds are transferred to USDT.”</p>
    </div>
  },
  {
    id: 542,
    title: 'Ethereum: Investors, know this before FOMO kicks in!',
    category: 'Crypto',
    subCategory: ['Ethereum', 'Investors', 'FOMO'],
    description: <div className="blog-desc">
      <p>Ethereum’s recent upside has not managed to push out of the narrow 2-week price range, hence underscoring weak prevailing demand.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 29, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-594.jpg',
    content: <div className="blog-content">
      <p>Ethereum’s recent upside has not managed to push out of the narrow 2-week price range, hence underscoring weak prevailing demand.</p>
	    <ul>
        <li>ETH bulls are in control but it might not be enough to support a strong breakout.</li>
        <li>The demand for derivatives seemed to be gradually recovering.</li>
      </ul>
      <p>Ethereum [ETH] is off to a promising start this week after delivering a bullish performance for four consecutive days. While this may usher in some excitement related to weekend accumulation, there are a few things that investors should consider before going all in.</p>
	    <p>ETH’s bullish performance in the last four days marks the first time that the price has favored a particular direction for over three days. Many analysts may translate this as a sign that demand is growing and outpacing sell pressure. However, a look at more data points is important to establish whether ETH is building towards potentially breaking out of its 2-week limbo.</p>	
	    <p>According to the latest Glassnode data, the amount of ETH supply last active in the last 3 – 6 months just reached a 10-month low. A confirmation that most ETH holders are not moving their coins. This suggests that there is still a long-term focus. On-chain exchange flow data reveals that more ETH has been flowing out of exchanges than the amount flowing in.</p>	
	    <p>A keen look at ETH total exchange inflows and outflows confirms that demand is currently outweighing sell pressure. However, there is something far more notable about the current exchange flows. Exchange flow volumes are now down to levels where we have previously seen a surge in on-chain volumes.</p>
      <p>The above observation is important because it means ETH may see a resurgence of volumes soon. If that happens, then it will likely break out of its narrow range where the cryptocurrency has been stuck for the last two weeks.</p>
      <h3>Are ETH whales buying?</h3>
      <p>The probability of a breakout or break below the recent range is largely dependent on strong demand from whales and institutions. Addresses holding at least 1000 ETH have been on a downward trajectory for the last two weeks. This is an indication that whales have been gradually offloading some of their coins.</p>
      <p>The same applies for ETH’s futures open interest which slid in the last five days. This is despite the 5% upside that the cryptocurrency has achieved during the same 5-day period. There are a few notables, such as the recent dip in the estimated leverage ratio, which suggests that the current upside is not supported by a lot of speculation.</p>
      <p>Also, exchange reserves are at monthly lows while funding rates are on the rise.</p>
      <p>The above underscores a cautious but optimistic outlook in the derivatives market, thus the absence of strong leverage.</p>
      <p>Nevertheless, the recent upside has not pushed out of the narrow 2-week price range, hence underscoring weak prevailing demand. As such, it might be too early to determine if the current upside represents a breakout.</p>
      <p>ETH exchanged hands at $1,842 at the time of writing. It is still trading within the narrow band in which it traded within the last two weeks.</p>
    </div>
  },
  {
    id: 536,
    title: 'This Week in Coins: Bitcoin and Ethereum See Fourth Flat Week as TRON and Tether Surge',
    category: 'Crypto',
    subCategory: ['TRON', 'Tether', 'bitcoin'],
    description: <div className="blog-desc">
      <p>It was the fourth consecutive week of flat prices. A generally slow news cycle meant that market leaders Bitcoin and Ethereum remained where they were last weekend.</p>
    </div>,
    authorName: 'Tim Hakki',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 27, 2023 - 10:51 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-570.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>It was the fourth consecutive week of flat prices. A generally slow news cycle meant that market leaders Bitcoin and Ethereum remained where they were last weekend.</p>
    	<p>Bitcoin depreciated a nominal 0.5% and currently trades at $26,815, while Ethereum rose 1% over the seven days and now enters the weekend at $1,829.</p>	
    	<p>On Monday, an AI-generated photo depicting a fabricated explosion at the Pentagon briefly caused a selloff in stocks and Bitcoin.</p>	
	    <p>Markets were decidedly flat across the board, with no top thirty cryptocurrency posting significant gains except TRON, which rallied 8% to $0.076778.</p>
      <p>TRON’s rally this week appears to have been driven by the climbing market capitalization of the U.S. dollar-pegged stablecoin Tether (USTD), as the latter inches towards overtaking its all-time high of $83.2 billion, set in May last year.</p>
      <p>According to CoinGecko, Tether may set a new high watermark any day now.</p>
      <p>TRON and USDT’s joint rally appears to be linked to the fact that the majority of USDT, approximately $46 billion, is issued on Tron, surpassing the $36 billion on Ethereum. Recent data shows a steep rise in USDT on the TRON network throughout 2023 so far.</p>
      <p>The biggest losses among cryptocurrencies this week were posted by Internet Computer (ICP), which fell 6% to $4.88, and Lido DAO (LDO), which dropped 7.1% to $1.96.</p>
      <h3>Coin politics</h3>
      <p>Crypto is already a major talking point in Bitcoin-friendly Florida Governor Ron DeSantis’s campaign as he gets ready to run as the GOP’s candidate in next year’s U.S. presidential elections.</p>
      <p>DeSantis headlined an otherwise disastrous Twitter Spaces the day he announced his candidacy and told his host, Twitter/Tesla CEO Elon Musk, that Biden’s administration “[has] it out for Bitcoin, and if it continues for another four years, they’ll probably end up killing it.”</p>
      <p>On Tuesday, The International Organization of Securities Commissions (IOSCO) released a report outlining policy recommendations for global crypto regulation as part of the public consultation process.</p>
      <p>In a nod to the fall of FTX, the IOSCO recommendations warn regulators about crypto companies "combining certain functions in a single legal entity or group of affiliated entities,” such as one entity running exchanges, trading firms, and custody businesses under one roof.</p>
      <p>On Thursday, watchdog the European Systemic Risk Board (ESRB) released a report concluding that the industry’s economic impact is minimal, but advising vigilance and policymaking that monitors leveraged trading and the industry’s financial contagion risks.</p>
      <p>That same day, the European Securities and Markets Authority (ESMA) and National Competent Authorities (NCAs) issued a statement saying that cryptocurrencies must be clearly labeled as unregulated and both investors and investment firms should understand the financial risks involved in trading them.</p>
    </div>
  },
  {
    id: 535,
    title: 'Bitcoin and Ethereum: Two titans battle for NFT supremacy',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Ethereum', 'NFT'],
    description: <div className="blog-desc">
      <p>This past weekend, two major cryptocurrency/non-fungible tokens events happened, Veecon Indianapolis 2023 and the BTC Miami/Ordinals Conference 2023.</p>
    </div>,
    authorName: 'Yohann Calpu',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 27, 2023 - 8:40 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-569.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>This past weekend, two major cryptocurrency/non-fungible tokens events happened, Veecon Indianapolis 2023 and the BTC Miami/Ordinals Conference 2023.</p>
    	<p>I chose to go to Indianapolis to meet my colleagues whom I’ve worked with for almost three years without meeting them in real life.</p>	
    	<p>At one point, as I was walking back to the hotel on the streets of Indianapolis, it became apparent to me that there was a split in the NFT community. One was the “status quo” ETH-based community that’s in the pre-teen stage meeting up at Veecon, and the other was a newly birthed baby in Miami at BTC Miami/Ordinals 2023.</p>	
	    <p>At Forkast Labs, we work on CryptoSlam.io to “unite the NFT community.” Ever since our co-founder Randy Wasinger added Loom (Sorare), Flow and WAX chains, alongside Ethereum in early 2021, we have always strived to include all new and relevant chains that we can.</p>
      <p>I also met loads of NFT industry celebs, including Richerd Chan from Manifold.xyz, who has created some of the most used Ethereum smart contracts in the NFT industry. He had actually reached out to me in 2020 through a client at the time and we had only talked online, but never met in person, and now he had so many folks around him that I could only briefly speak with him.</p>
      <p>As an epic Ethereum NFT celebrity and developer, I decided to ask him a very telling question: “Are you developing for Bitcoin?”</p>
      <p>His response made it clear that he wasn’t and had no intention of doing so at the moment. He posed a great question in return: “Is the BTC community even supporting Ordinals?”</p>
      <p>It’s a great question, to be honest.</p>
      <p>In Miami, it seems that the main theme revolves around the “laser-eyed maxis” who view Bitcoin as having a single use case: being “better money” and a store of value that shouldn’t be tampered with.</p>
      <p>On the other hand, the new Ordinal crew, represented by individuals like @rodarmor, @TO, @LeonidasNFT and @udiwertheimer, are leading the charge on alternative use cases such as digital artifacts (NFTs) and BRC-20s, which are the bitcoin equivalent of fungible tokens like ETH and BTC.</p>
      <p>The latest rankings on CryptoSlam.io show that $OXBT and $ORDI are in the top 10, indicating the rapid rise of Bitcoin in the scene.</p>
      <p>Personally, I have never seen an ecosystem boot up this fast, except for NFTs in 2020-2021, and I would argue that it’s even moving faster this time around.</p>
      <p>The Bitcoin market cap of over US$500 billion is more than double the Ethereum currency market cap of over US$200 billion. The Bitcoin protocol may be better suited to certain types of NFTs, ones that see immutability and scarcity as features instead of weaknesses.</p>
      <p>Fundamentally, there is a big difference forming between what we are seeing on ETH and what we are seeing on BTC. The community is splitting into people who value UX, and corporate partners and one who values ultimate security/scarcity and immutability. While I think there are strengths to each, there IS a place for both.</p>
      <p>Net new people are coming into the “NFT” ecosystem, even if they will only trade Ordinals on BTC or BRC-20s, and they are really small in numbers, they are net new people. What will happen when the Michael Saylors of the world see the value in Ordinal theory, and its burgeoning use cases on BTC?</p>
      <p>The NFT ecosystem, while can be seen as splitting, is actually growing and innovating, and while Taylor Swift fans are not coming in en masse, the core set of people who see the future of this technology is more excited about the growth of this industry than ever before.</p>
    </div>
  },
  {
    id: 534,
    title: 'An ‘Optimism’ outperformance – Will OP join in?',
    category: 'Crypto',
    subCategory: ['Optimism', 'OP', 'crypto'],
    description: <div className="blog-desc">
      <p>Optimism outperforms other chains and ranks number one in terms of net flows on the network. User retention remains high however there has been no positive impact on OP’s price.</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 26, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-558.jpg',
    content: <div className="blog-content">
      <p>Optimism outperforms other chains and ranks number one in terms of net flows on the network. User retention remains high however there has been no positive impact on OP’s price.</p>
      <ul>
        <li>Optimism does better than other layer 2 chains in terms of net flow. Protocol manages to retain a higher number of users.</li>
        <li>OP’s price continues to decline, however, whales show sudden interest.</li>
      </ul>
    	<p>Optimism has been having a tough time competing in the L2 space as competitors such as Arbitrum and Polygon have been dominating the market over the last few months. However, it appears that Optimism started to gain its footing in the L2 space yet again and has been outperforming other L2 solutions in some areas.</p>
	    <h3>On the brighter side</h3>
      <p>According to data provided by analyst Dynamo Patrick, Optimism ranked number 1 in terms of net bridge flow. For context, net bridge flow in crypto refers to the movement of digital assets between different blockchain networks or bridges.</p>	
	    <p>In the last week, Optimism’s net flow reached a total of $57.1 million.</p>	
	    <p>The spike in net flow on the Optimism protocol could be attributed to the growing number of users on the network. Not only were new users being onboarded to Optimism, but they were choosing to stay as well.</p>
      <p>This was showcased by Dune Analytics’ data which indicated that Optimism’s user retention began to surge over the last few days. At press time, the weekly retention for new users on Optimism was around 17.3%.</p>
      <p>However, the OP token didn’t see the same growth as its protocol. Since 15 April, the price of OP declined by 43.64%. At press time, OP was trading at $1.596 after fluctuating between the $1.867 and $.494 resistance and support.</p>
      <p>The Relative Strength Index (RSI) of OP experienced a significant and consistent decline over the past few days. It reached a value of 40.72 at the time of writing. Conversely, the Chaikin Money Flow (CMF) displayed a contrasting pattern by continuously increasing, with a recorded value of 0.13.</p>
      <p>This suggested that despite the weakening momentum indicated by the RSI, there was still a demand for OP, possibly from new buyers or institutional investors.</p>
      <p>The volume with which OP was being traded, also declined during this month. However, there was a spike in the number of whale transactions for the OP token. If whales continue to show interest and accumulate more OP, it could have a positive impact on the OP token going forward.</p>
    </div>
  },
  {
    id: 533,
    title: 'BNB drops below this key support level as Binance halts…',
    category: 'Crypto',
    subCategory: ['BNB', 'binance', 'crypto'],
    description: <div className="blog-desc">
      <p>Binance, on 25 May, decided to suspend transactions for some assets. This impact BNB’s price action which wasn’t doing well either given the sell pressure in the last two days. BNB also managed to drop below a key ascending support line.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 26, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-557.jpg',
    content: <div className="blog-content">
      <p>Binance, on 25 May, decided to suspend transactions for some assets. This impact BNB’s price action which wasn’t doing well either given the sell pressure in the last two days. BNB also managed to drop below a key ascending support line.</p>
    	<ul>
        <li>Binance halts deposits from 10 networks albeit temporarily.</li>
        <li>Assessing BNB’s fate after crashing through the ascending support line.</li>
      </ul>
      <p>The Binance Smart Chain’s native cryptocurrency has been on a bearish trajectory for six weeks. Its performance in the last two days has been particularly noteworthy because of its ascending support line. Should investors anticipate more BNB price weakness after failing to secure bullish momentum?</p>
    	<p>Let’s take a look at some of the most noteworthy observations regarding Binance before assessing BNB’s prospects. According to the latest Binance announcement, the BSC temporarily suspended deposits from multiple networks in the last 24 hours at the time of writing.</p>	
    	<p>Binance did not disclose whether the decision was based on a threat to its operations or whether it was due to routine maintenance. However, it did reveal later that it resumed deposits from Ethereum and FTM.</p>	
    	<p>We observed a significant dip in the number of active addresses in the last 24 hours in hours after Binance made the announcement. This was followed by a sharp bounce back presumably due to the reactivation of deposits.</p>
      <p><strong>Is there still hope for BNB bulls in the short run?</strong></p>
      <p>The likelihood that the halting of deposits may have had an impact on BNB’s price action was quite low. This was because the cryptocurrency maintained the same bearish momentum it was on 24 hours prior to the announcement about the temporary suspension of deposits.</p>
      <p>BNB’s price action was particularly notable because the selling pressure in the last two days broke through a key ascending support line. It traded at $304 at press time which is slightly above its 200-day moving average.</p>
      <p>The 200-day MA will likely act as the next support range to bear the $300 price level. This was largely because the MA may act as a psychological buy zone and the fact that the same price is within a zone of short-term support. Also, what was worth noting was that the MFI indicated that buying momentum was already building up.</p>
      <p>Some on-chain data also backed the bullish expectations. For example, the mean coin age has been on a steady upward trajectory for the last seven days. This confirmed that most investors were still holding their tokens. Additionally, investor consensus expectations have been on the rise for the last seven days indicating bullish expectations.</p>
      <p>Based on the above analysis, buying pressure was slowly building up and might soon lend favor to the bulls. However, this outcome was still subject to overall market conditions which may pull a surprising instance of capitulation if BNB fails to secure enough bullish momentum.</p>
    </div>
  },
  {
    id: 532,
    title: 'Bitcoin, Ether rise; Dogecoin falls despite record high transaction volume',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Ether', 'Dogecoin'],
    description: <div className="blog-desc">
      <p>Bitcoin edged up and continued trading above the US$27,000 threshold on Wednesday morning in Asia, with other top 10 non-stablecoin cryptocurrencies trading mixed.</p>
    </div>,
    authorName: 'Timmy Shen',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 24, 2023 - 6:48 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-556.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
	    <p>Bitcoin edged up and continued trading above the US$27,000 threshold on Wednesday morning in Asia, with other top 10 non-stablecoin cryptocurrencies trading mixed. Investors continue to eye U.S. debt ceiling talks.</p>
	    <h3>Bitcoin, Ether rise</h3>
      <p>Bitcoin rose 0.39% over the last 24 hours to US$27,172 at 9:45 a.m. in Hong Kong, according to data from CoinMarketCap. Hong Kong’s securities regulator announced Tuesday that it will allow trading of certain crypto assets from June 1, contributing to the rise.</p>	
	    <p>The world’s largest cryptocurrency by market capitalization was, however, down 0.14% over the past seven days.</p>	
	    <p>Ether climbed 0.43% in the past 24 hours to US$1,848, up 0.82% over the past week.</p>
      <p>Dogecoin was down 1% over the last 24 hours to US$0.07267. It also logged a 1% weekly decline.</p>
      <p>Conversely, daily transaction volume on the Dogecoin network reached an all-time high of 1.42 million transactions on Monday. That figure surpasses Ethereum’s 1.11 million, Bitcoin’s 519,716 and Litecoin’s 258,222 transactions, according to data from BitInfoCharts.</p>
      <p>The rise followed the launch on May 9 of a new Dogecoin mechanism. This allows the  issuance of so-called DRC-20 tokens. Some social media commentators have attributed the gains to the hype caused by the release.</p>
      <p>Meanwhile, BNB added 0.58% over the past 24 hours to US$312. That rise came despite a Reuters report published Tuesday alleging that Binance, the token’s issuer, had failed to properly separate client and company funds.</p>
      <p>“The report is definitely not bullish for Binance or BNB but, ultimately, that’s still one piece of news within a broader market,” said Justin d’Anethan, head of APAC business development at Belgium-based crypto market maker Keyrock.</p>
      <p>“It’s worth noting that BNB as a blockchain recorded a massive amount of transactions, which seems to hint at a lot of enthusiasm, building and utility for the chain, regardless of allegations on Binance’s business.”</p>
      <h3>Bitcoin-based NFT daily sales rise</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index edged up 0.01% at 3,389.10 in the 24 hours to 11:00 a.m. in Hong Kong. The index was up 0.12% over the past seven days.</p>
      <p>NFT sales on Ethereum fell 5.34% in the last 24 hours to US$13.49 million, according to CryptoSlam data. Whereas, sales on the Bitcoin blockchain expanded 49.67% to US$4.03 million.</p>
      <p>AssetDash Vanta, a Solana-based NFT collection that started minting on Tuesday, recorded the most sales across the NFT market in the past 24 hours. It recorded a daily sales value of US$1.54 million.</p>
      <p>Bitamigos, a Bitcoin Ordinals collection, is currently second in terms of daily sales value. Its daily sales rose 30.4% over the past 24 hours to US$1.05 million.</p>
      <p>According to Yehudah Petscher, NFT Strategist at Forkast Labs, “the past seven days in NFTs have been all about Bitcoin Ordinals, which now represent three of the top 10 collections.”</p>
      <p>He added that Bored Ape Yacht Club “has been dethroned for now, with two Ordinals projects and Mythos Chain’s DMarket all outselling the NFT community’s favorite apes.”</p>
      <p>Regardless, BAYC still saw the most sales on Ethereum in the last 24 hours. It recorded a daily sales value of US$950,020.</p>
      <h3>U.S. futures rise; New Zealand raises rates</h3>
      <p>Major U.S. stock futures rose as of noon in Hong Kong. The Dow Jones Industrial Average futures edged up 0.09%, while the tech-heavy Nasdaq 100 futures rose 0.1%. The S&P 500 futures added 0.11%.</p>
      <p>U.S. debt ceiling negotiations continue to drag on. A group of House Republicans raised questions Tuesday regarding the June 1 debt default deadline suggested by Treasury Secretary Janet Yellen.</p>
      <p>Yellen has repeatedly stated that the U.S. will fail to pay its debts from that date. The resulting default would lead to devastating consequences for U.S. and global markets. It would also, Yellen warns, erode global trust in U.S. treasuries as a safe haven for investment.</p>
      <p>House Majority Leader Steve Scalise told reporters on Tuesday that they would like to see “more transparency” on how the Treasury Department arrived at June 1 as the so-called “X-date” for the default.</p>
      <p>“It looks like they’re hedging now and opening the door to move that date back,” he said.</p>
      <p>Meanwhile, New Zealand’s central bank hiked interest rates by 25 basis points Wednesday to 5.5%. That is its highest rate in over 14 years. The move is in line with the expectations of economists polled by Reuters.</p>
      <p>Elsewhere, in Japan, business sentiment among domestic manufacturers turned positive for the first time this year, according to a Reuters Tankan poll for May.</p>
    </div>
  },
  {
    id: 531,
    title: 'Things look up for MATIC as zkEVM witnesses decline in…',
    category: 'Crypto',
    subCategory: ['Matic', 'zkEVM', 'crypto'],
    description: <div className="blog-desc">
      <p>Users should keep an eye on Ethereum’s price and network activity to take advantage of less transaction fees. MATIC’s price action after a long wait turned green.</p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 23, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-554.jpg',
    content: <div className="blog-content">
      <p>Users should keep an eye on Ethereum’s price and network activity to take advantage of less transaction fees. MATIC’s price action after a long wait turned green.</p>
      <ul>
        <li>Optimizations to Polygon zkEVM are expected to reduce fees by roughly 20%.</li>
        <li>MATIC’s price increased by 2% in the last 24 hours, and metrics looked bullish.</li>
      </ul>
    	<p>In its latest tweet, Polygon [MATIC] announced that over the next few weeks, optimizations to zkEVM are expected to reduce fees by roughly 20%. The decline in fees can be a game changer for zkEVM, as it will help attract new users.</p>
    	<h3>How will users benefit?</h3>
      <p>Though the fee reduction will take some time, Polygon revealed a few methods of which users can take advantage even now. As per the tweet, Ethereum’s [ETH] trading price can be tracked in order to make transactions with lower fees.</p>	
    	<p>Because most of the transaction fee is for interactions with L1, users should wait for the times of day when Ethereum is cheapest. Additionally, keeping a close eye on network activity will also help. This is because transaction fees usually go down when network activity increases.</p>	
    	<h3>A look at Polygon zkEVM’s performance </h3>
      <p>After quite a few weeks of sluggish performance, zkEVM’s metrics showed immense gains. For instance, Polygonscan’s data revealed that zkEVM’s daily transactions skyrocketed. In addition to that, zkEVM’s daily gas use also increased considerably, which reflected increased usage of the roll-up.</p>
      <p>Though Polygon zkEVM’s stats looked encouraging, it still had a long way to go before catching up with its competitor, zkSync Era. According to Artemis’ data, zkSync’s daily active addresses were considerably higher than those of zkEVM.</p>
      <p>The same trend remained true for daily transactions, as zkEVM’s figure was substantially lower than zkSync.</p>
      <h3>MATIC has finally responded positively </h3>
      <p>After a long wait, Polygon’s daily chart finally turned green. According to CoinMarketCap, Polygon’s price increased by 2% in the last 24 hours. At the time of writing, MATIC was trading at $0.8822, with a market capitalization of over $8.1 billion.</p>
      <p>A reason behind the surge might be MATIC’s declining exchange reserve, which suggests that the token was not under selling pressure. Whales seemed to have increased their holdings as the supply held by top addresses went up.</p>
      <p>MATIC’s supply on exchanges declined while its supply outside of exchanges increased slightly. Moreover, the token also remained in demand in the futures market, as evident from its green Binance funding rate.</p>
    </div>
  },
  {
    id: 530,
    title: 'What next after Bitcoin’s latest plateau',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'plateau', 'BTC'],
    description: <div className="blog-desc">
      <p>Bitcoin consolidates near key support with a bearish overhead stronghold and neutral futures market.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 22, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-555.jpg',
    content: <div className="blog-content">
      <p>Bitcoin consolidates near key support with a bearish overhead stronghold and neutral futures market.</p>
      <ul>
        <li>BTC oscillated between 50-EMA and 100-EMA since 9 May.</li>
        <li>Open interest rates remained unchanged; longs discouraged.</li>
      </ul>
    	<p>Bitcoin [BTC] continued consolidating near $26.6k support, with volumes remaining low since late March. While the current level could be an accumulation zone, BTC is yet to register a strong rebound, highlighting the tussle between bulls and bears at $26.6k.</p>
    	<p>A recent report highlighted that self-custody for both BTC and ETH has been on the rise, suggesting investors’ huge bets on a likely uptrend move.</p>	
    	<p>But key price resistance levels await, and here is a strong bearish stronghold for BTC bulls to watch out for on the daily chart.</p>	
    	<h3>Will $26.6k and 100-EMA check further drop?</h3>
      <p>The drop from 6 May, left behind an FVG (fair value gap) zone between $27,826 – $28,396 (white). Just below the FVG zone lay the 50-EMA of $27.57k (blue line). Above it lays the $28.5k resistance, a key obstacle back in the second half of March.</p>
      <p>This makes the area a strong bearish stronghold, and bulls must clear it to gain leverage and dent any prevailing bearish sentiment.</p>
      <p>A negative price reaction from the above bearish zone and subsequent breach of the $26.6k support could further weaken BTC’s market structure. The next support levels to the south are the dynamic 100-EMA (yellow) and $25.26k.</p>
      <p>However, a daily session close above the bearish stronghold ($28.5k) could set BTC to rally and retest recent highs of $29k or $31k. Above these resistance levels, the next resistance level lay at $32.4k.</p>
      <p>Meanwhile, the RSI was below the mid-range, highlighting limited buying pressure. However, the CMF (Chaikin Money Flow) was above the zero mark, indicating substantial capital inflows.</p>
      <h3>BTC’s open interest rates stagnate</h3>
      <p>According to Coinglass data, BTC’s futures open interest rates hovered around $11 billion since the second half of April. This shows a neutral position in the futures market in the same period, meaning prices can go in either direction.</p>
      <p>However, Coinglass’s total liquidation data showed longs were discouraged, with over $5 million worth of positions, out of $9.7 million, wrecked in the past 24 hours as of press time. This reinforces a mild bearish sentiment in the futures market.</p>
      <p>A more nuanced direction could be clear from Monday (22 May). The U.S. debt ceiling negotiation is a major development worth tracking over the coming days as it may affect BTC’s movements.</p>
    </div>
  },
  {
    id: 529,
    title: 'Solana’s ‘secret’ behind overtaking Tron despite low users is…',
    category: 'Crypto',
    subCategory: ['Solana', 'Tron', 'crypto'],
    description: <div className="blog-desc">
      <p>The Solana network continued to see improvements and outperformed Tron in various areas. What was driving SOL’s growth?</p>
    </div>,
    authorName: 'Himalay Patel',
    authorurl: 'https://ambcrypto.com/author/himalay-patel/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 22, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-546.jpg',
    content: <div className="blog-content">
      <p>The Solana network continued to see improvements and outperformed Tron in various areas. What was driving SOL’s growth?</p>
    	<ul>
        <li>Solana outperforms Tron in various sectors, despite having fewer active addresses.</li>
        <li>A booming NFT market and high activity on dApps helped the network’s rise.</li>
      </ul>
      <p>Tron [TRX] has been exceeding expectations as the number of daily active users continued to surge. This caused the protocol to generate large amounts of revenue.</p>
	    <p>However, despite these factors, Solana [SOL] outperformed Tron in various areas.</p>	
      <h3>Tron cannot catch up</h3>
	    <p>Artemis’ data showcased that the daily active addresses on the Tron network were high compared to Solana at press time. However, the latter still outperformed Tron in terms of daily transactions by a huge margin.</p>	
	    <p>This indicated that even though Tron had a large user base, Solana’s users were more engaged with the network. One reason for the same would be the popularity of Solana NFTs. Popular Solana NFT collections, such as Solana Monkey Business and DeGods, have been doing relatively over the last week.</p>
      <p>According to SolanaFloor’s data, the number of owners holding the Solana Monkey Business collection has grown materially over the last few days. Coupled with that, the floor price of this NFT collection also grew significantly in the same period.</p>
      <p>Until press time, no Tron NFT collection could garner the same level of interest as Solana Blue Chip NFT collections.</p>
      <h3>The dApp angle</h3>
      <p>Another factor that has aided Solana in retaining such a loyal and engaged user base is because of the dApps on its protocol. One of its dApps, called Raydium, has seen a massive uptick in the number of users on its protocol. Over the last month, the unique active addresses on the protocol surged by a whopping 163.5%.</p>
      <p>Subsequently, the number of transactions being made on the network also grew.</p>
      <p>Stakers were optimistic about the state of the network as well. Staking Reward’s data indicated that the number of stakers increased by 4.81% in the last month, standing at 621,060 at press time.</p>
    </div>
  },
  {
    id: 528,
    title: 'Bitcoin, Ether fall; Tron trades higher; Investors digest G7, U.S. debt ceiling implications',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Ether', 'Tron'],
    description: <div className="blog-desc">
      <p>Bitcoin fell below US$27,000 on Sunday, dipping further Monday morning in Asia as wider macro developments continue to weigh.</p>
    </div>,
    authorName: 'Timmy Shen',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 22, 2023 - 6:39 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-545.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin fell below US$27,000 on Sunday, dipping further Monday morning in Asia as wider macro developments continue to weigh. With the exception of Tron, which traded higher Monday morning, all other top 10 non-stablecoin cryptocurrencies fell. As the Group of Seven summit in Japan concluded Sunday, investors continue to weigh the implications of U.S. debt ceiling negotiations.</p>
    	<h3>The rise of Tron as Bitcoin, Ether fall</h3>
      <p>Bitcoin fell 1.95% over the last 24 hours to US$26,669 at 9:30 a.m. in Hong Kong. That decline contributed to a loss of 1.27% over the past seven days, according to data from CoinMarketCap.</p>	
    	<p>Bitcoin has so far failed to react positively to lower than expected U.S. consumer price index (CPI) data and to ongoing efforts to raise the U.S. debt ceiling, said Markus Thielen, head of research and strategy at Singapore-based crypto lender Matrixport, in a note.</p>	
    	<p>“The world’s largest cryptocurrency by market cap has been trading sideways since March, and bar any significant macro news, is unlikely to break US$30,000 anytime soon,” Jeff Mei, chief operating officer of crypto exchange BTSE, said. “Plus, the ongoing macro uncertainty is dampening retail investors’ enthusiasm for ‘YOLOing’ into risk assets right now, while institutional investors are cautiously hedging their bets too.”</p>
      <p>Ether dropped 1.14% in the past 24 hours to US$1,800, down 0.49% over the past week.</p>
      <p>Solana was the biggest loser among the top 10 non-stablecoin cryptocurrencies. It recorded a 4.22% decline over the past 24 hours and a 6.92% loss for the week.</p>
      <p>Tron, however, climbed 2.73% over the past 24 hours to US$0.07464, up 7.47% over the past week. Matrixport’s Thielen suggested this may be related to the announcement on May 11 by Tron founder Justin Sun that he would begin trading in memecoins.</p>
      <p>Meanwhile, Tornado Cash rose 23.38% in the past 24 hours to US$4.62. The coin had plummeted to as low as US$3.859 on Sunday before recovering.</p>
      <p>The drop came as the token’s issuer — the Tornado Cash decentralized autonomous organization (DAO) — fell victim to what appeared to be voter fraud over the weekend. Binance, the world’s largest crypto exchange, said on Sunday that it had temporarily halted trading of the token.</p>
      <h3>Bitcoin Ordinals continue to rise</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index remained flat at 3,399.6 points in the 24 hours to 10:30 a.m. in Hong Kong. The index was up 1.77% over the past seven days.</p>
      <p>NFT sales on Ethereum, the most-used blockchain for NFTs, shrank 3.96% in the last 24 hours to US$15.1 million. Sales on the Bitcoin blockchain dropped 18.5% to US$3.54 million, according to Cryptoslam data.</p>
      <p>The Mutant Ape Yacht Club collection recorded the most sales on Ethereum over the past 24 hours. Its daily sales rose 1.1% to US$1.9 million.</p>
      <p>With the growing popularity of Bitcoin Ordinals, so-called uncategorized Ordinals – Ordinals that are not part of an established collection – saw a 29% rise to US$960,597 in sales value over the past 24 hours.</p>
      <h3>US stock futures mixed as talks resume</h3>
      <p>U.S. stock futures were trading mixed as of 11:50 a.m. in Hong Kong, as investors continued to assess uncertainties surrounding the U.S. debt ceiling talks, alongside the outcomes of the weekend’s G7 summit.</p>
      <p>The Dow Jones Industrial Average was down 0.1%, while the S&P 500 edged down 0.036%. The Nasdaq Composite futures added 0.088%.</p>
      <p>Market watchers are expecting U.S. President Joe Biden to meet with House of Representatives Speaker Kevin McCarthy on Monday to carry on negotiations related to raising the debt ceiling. A deal would help the nation avoid a  potential default on its debt — an outcome that would likely prove devastating for U.S. and global markets.</p>
      <p>During a speech at the G7 summit in the Japanese city of Hiroshima Sunday, Biden unexpectedly drew crypto into the debt ceiling conversation.</p>
      <p>The president would not, he said, agree to “a deal that protects wealthy tax cheats and crypto traders while putting food assistance at risk for nearly a hundred – excuse me – nearly 1 million Americans.”</p>
      <p>The president’s words offered some indication of the bipartisan nature of ongoing debt discussions between the White House and Congress.</p>
      <p>Meanwhile, U.S. Treasury Secretary Janet Yellen reaffirmed in an NBC interview Sunday that June 1 remains a “hard deadline” to raise the debt limit. Failure to do so would undermine global trust in U.S. securities as a safe haven for investment, Yellen added.</p>
      <p>“It’s not an acceptable situation for us to be unable to pay our bills,” she said.</p>
      <p>Elsewhere, the People’s Bank of China on Monday morning said that it maintained its key lending rates steady for the ninth straight month, leaving its one-year loan prime rate unchanged at 3.65% and the five-year rate at 4.3%.</p>
    </div>
  },
  {
    id: 527,
    title: 'XRP gains; Lift-off for Space Pepe NFTs; US investor sentiment up on positive debt talks',
    category: 'Crypto',
    subCategory: ['XRP', 'NFTs', 'US'],
    description: <div className="blog-desc">
      <p>Bitcoin and Ether prices fell on Friday morning in Asia. After a bullish run, Litecoin joined most other top 10 non-stablecoin cryptocurrencies in recording losses.</p>
    </div>,
    authorName: 'Danny Park',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 19, 2023 - 7:32 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-542.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin and Ether prices fell on Friday morning in Asia. After a bullish run, Litecoin joined most other top 10 non-stablecoin cryptocurrencies in recording losses. XRP was the sole winner. It recorded gains following the launch of its new central bank digital currency (CBDC) platform by its issuer Ripple. U.S. equity futures moved higher after a strong rally in regular trading Thursday. Positive earnings reports, debt ceiling talks both played a role.</p>
      <h3>XRP makes standout gain</h3>
    	<p>Bitcoin fell 1.97% to US$26,852.75 in the 24 hours to 7:45 a.m. Friday in Hong Kong, down 0.67% over the last seven days according to CoinMarketCap data.</p>	
    	<p>The dip back below the $27,000 threshold indicated a return to a bearish trend following gains Thursday. That trend should be short lived, however, said financial analyst Tone Vays during an interview.</p>	
    	<p>“I do believe that we have bottomed. We are now in a halving cycle which tends to have Bitcoin go up with less than one year to the halving event.”</p>
      <p>Halving refers to a pre-programmed reduction of the rate at which new tokens are created, which reduces supply and can drive prices higher.</p>
      <p>“We have had an extended bear market and it’s just time for Bitcoin to rise again,” Vays added.</p>
      <p>Like Bitcoin, Ether fell 0.92% to US$1,804.6. However, it posted gains of 0.29% for the week.</p>
      <p>XRP was the sole gainer among the top 10 non-stablecoin cryptocurrencies. It gained 2.68% to US$0.4607 while adding 9.13% in the last seven days.</p>
      <p>Those gains followed an announcement Thursday by Ripple Labs. The payment protocol and exchange network said it is launching a platform for a CBDC as part of Hong Kong’s inaugural e-HKD (electronic Hong Kong dollar) pilot program.</p>
      <p>The Ripple platform will offer a “frictionless end-to-end solution for central banks, governments, and financial institutions to issue their own central bank digital currency,” according to a company press release.</p>
      <p>Ripple will partner with Taiwan-based Fubon Bank on the project.</p>
      <p>The announcement followed further Ripple-related news earlier this week. On Wednesday, a U.S. federal judge denied a motion filed by the Securities and Exchange Commission (SEC) to prevent public access to the so-called Hinman document.</p>
      <p>These internal documents relate to former SEC director William Hinman’s comments that Bitcoin and Ether are not financial securities. The decision is considered a win for Ripple Labs in its ongoing legal dispute with the SEC. The regulator accuses them of providing unregistered securities.</p>
      <p>Elsewhere, most other top 10 non-stablecoin cryptocurrencies traded lower. Solana led the losers. It fell 3.4% to US$20.36, but was up 0.37% over the past seven days.</p>
      <p>Litecoin lost ground after a bullish week. It was down 3.37% to US$90.77.</p>
      <p>The Bitcoin-like cryptocurrency had seen a surge in recent interest. This was mainly due to congestion and higher transaction fees on the Bitcoin network associated with the current popularity of ordinals. The Litecoin network has also now seen its own surge in ordinal inscriptions.</p>
      <p>On the recent popularity of ordinals on the Bitcoin network in particular, Vays identified a solution to the operational issues it raises.</p>
      <p>“The Bitcoin main chain was not really designed for this kind of purpose,” he said. “Yes, the Bitcoin blockchain can be used for these kinds of projects. I just wish they were done on the Liquid sidechain.”</p>
      <p>The layer 2 solution Liquid offers ways to incorporate ordinals and other initiatives on the Bitcoin blockchain without raising transactions fees, he added.</p>
      <p>The total crypto market capitalization fell 1.44% in the past 24 hours to US$1.12 trillion. Total trading volume lost 6.46% to US$32.07 billion.</p>
      <h3>Space Pepe NFTs soar 28270%</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token market, the Forkast 500 NFT index rose 0.1% to 3,387.40 points in the 24 hours to 9:30 a.m. in Hong Kong. It was up 0.1% over the last seven days.</p>
      <p>Daily NFT sales on Ethereum, the leading blockchain for NFTs, fell 17.31% to US$14.4 million. Sales on the Bitcoin blockchain also lost 11.45% according to CryptoSlam! data to US$6.9 million.</p>
      <p>Space Pepes, a Bitcoin NFT collection based on the Pepe the Frog meme, skyrocketed 28270% to US$7.3 million in the last 24 hours leading up to 11:30 a.m. in Hong Kong. The collection topped Cryptoslam’s NFT collection ranking by sales volume.</p>
      <p>Uncategorized Ordinals — Cryptoslam’s category of Bitcoin Ordinals that are not part of one established collection — rose 140.12% to US$1.9 million over the past 24 hours.</p>
      <p>The Mythos blockchain-based DMarket NFT collection ranked second. DMarket consists of online gaming NFTs traded on the game-item marketplace of the same name. It was acquired by blockchain game maker Mythical Games in January this year.</p>
      <p>In individual NFT sales, Bored Ape Yacht Club’s #5042 registered the highest price. It sold for US$172,135.</p>
      <h3>U.S. equities rally; hawkish noises from the Fed</h3>
      <p>U.S. stock futures traded higher as of 10:45 a.m. in Hong Kong, adding momentum to Thursday’s rally. The Dow Jones Industrial Average futures edged up 0.074%, while the S&P 500 futures gained 0.17%. The Nasdaq Composite futures added 0.28% after it gained 1.51% during market trading on Thursday.</p>
      <p>The three Wall Street stock indexes rose after U.S. House of Representatives Speaker Kevin McCarthy said a bill to raise the country’s debt ceiling could be put to a vote next week. The comments followed positive talks with U.S. President Joe Biden,  easing investor concerns that the U.S. could default on its debt.</p>
      <p>That scenario, which would have major repercussions for U.S. and global markets, can be avoided if the White House and congress can agree to raise the debt ceiling.</p>
      <p>Biden and McCarthy have been actively discussing a solution to the ongoing standoff  throughout this week.Biden too has expressed optimism that an agreement can be reached.</p>
      <p>Democratic negotiators informed the president Friday that they are making “steady progress” on debt ceiling talks ahead of his attendance in Japan for the weekend’s Group of Seven summit.</p>
      <p>Treasury Secretary Janet Yellen warned earlier this month that the U.S. will start to fail to make its debt payments as early as June 1. Yellen called for fast action on raising the debt ceiling, as she said a debt default would devastate jobs and businesses in the country.</p>
      <p>On the relationship between the crisis and crypto markets, Clara Medalie, Head of Research at crypto market data provider Kaiko, told Forkast via email that there was so far little sign of any impact based on recent price movements.This is “in large part because the event hasn’t yet played out and there remains hope that a deal will be reached before June 1,” she added.</p>
      <p>Elsewhere, U.S. investors saw positive Q1 earnings results from major U.S. companies.</p>
      <p>Walmart, the world’s largest company by revenue, reported 7.6% total revenue growth for the quarter. “We had a strong quarter. Comp sales were strong globally with eCommerce up 26%.  We leveraged expenses, expanded operating margin, and grew profit ahead of sales,” said Walmart President Doug McMillion in the company’s earnings report.</p>
      <p>California-based tech manufacturer Nvidia stocks jumped nearly 5% on Thursday after the company announced that it partnered with another California-based cloud software company ServiceNow to build AI for enterprises.</p>
      <p>On interest rates, Federal Reserve Chair Jerome Powell is due to speak publicly on Friday. He is expected to signpost the central bank’s latest policy views on inflation and rates.</p>
      <p>Other members of the Fed made hawkish comments Thursday. Fed governor Philip Jefferson said the U.S. should wait to assess the full effect of higher interest rates on inflation so far before deciding to rule out further hikes.</p>
      <p>Dallas Fed President Lorie Logan also said that it is not yet time to pause interest rate hikes.</p>
      <p>U.S. interest rates are now between 5% and 5.25%, the highest since 2006. The Fed meets again on June 14 to decide if another rate hike is necessary. Inflation was at 4.9% in April, lower than expected but still ahead of the Fed’s 2% target.</p>
      <p>The CME FedWatch Tool predicts a 66.7% chance the Fed will leave rates unchanged in June, down from 76.2% a day prior. It predicts a 33.3% chance for another 25 basis-point rate increase.</p>
    </div>
  },
  {
    id: 526,
    title: 'Matic leads crypto rise despite Ledger security concerns',
    category: 'Crypto',
    subCategory: ['Matic', 'Crypto', 'rise'],
    description: <div className="blog-desc">
      <p>Bitcoin and Ether rose during afternoon trading in Asia, along with most of the top 10 non-stablecoin cryptocurrencies by market capitalization. Polygon’s Matic token led the gains.</p>
    </div>,
    authorName: 'Zoltan Vardai',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 18, 2023 - 1:36 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-540.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin and Ether rose during afternoon trading in Asia, along with most of the top 10 non-stablecoin cryptocurrencies by market capitalization. Polygon’s Matic token led the gains.</p>
    	<h3>Crypto market bounces back</h3>
      <p>Bitcoin rose 2.23% to US$27,387  in the 24 hours to 4:30 p.m. in Hong Kong. The world’s first cryptocurrency fell as low as US$26,601 yesterday, before reclaiming the US$27,000 level. Ether fell 1.13% to US$1,827.</p>	
    	<p>“Bitcoin failed to reclaim the US$28,800 level on the weekly, and then Bitcoin weekly closed below US$27,600, failing to hold it as support… Turn US$27,600 into resistance and this could enable further downside into the low US$20,000s,” said pseudonymous crypto analyst Rekt Capital on Twitter. “</p>	
    	<p>Polygon’s Matic saw the biggest daily gain, rising 3.77% to US$0.8813, followed by Cardano’s ADA, up 3.32% to US$0.378.</p>
      <p>XRP token was the only coin in the red, as it fell 0.26% to US$0.4463, after leading the gains in yesterday’s trading session.</p>
      <p>A new security concern was raised after crypto wallet firm Ledger recently announced the launch of Ledger Recover, an optional recovery service for users’ seed phrase.</p>
      <p>The announcement caused widespread community distress about Ledger’s potential backdoor into their devices, putting users’ secret seed phrases at risk. Ledger Chief Technology Officer Charles Guillemet attempted to quell the fire by saying, “There is no backdoor for anyone, neither us, a provider or even a very gifted hacker to access it.”</p>
      <h3>Ethereum NFT sales down for a second day</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the NFT market, the Forkast 500 NFT index rose 0.24% to 3,390.51 points in the 24 hours to 4:30 p.m. in Hong Kong but declined 1.23% during the week.</p>
      <p>Ethereum’s 24-hour NFT sales decreased for the second consecutive day, dropping 19.17% to US$15.6 million. Bored Ape Yacht Club sales, the largest Ethereum-based NFT collection, fell 63.18% to US$811,464, while its spin-off collection Mutant Ape Yacht Club sales fell 21.22% to US$734,727.</p>
      <h3>Asian and U.S. equities strengthen</h3>
      <p>Most Asian equity markets were up on Thursday, with the Shanghai Composite rising 0.40%, Hong Kong’s Hang Seng Index 0.85% and Japan’s Nikkei 225 1.60%. The Shenzhen Component Index fell 0.12%.</p>
      <p>Investor concerns over China’s post-pandemic economic recovery persisted after data showed that China’s industrial production expanded by 5.6% year-on-year in April, missing market expectations of 10.9%.</p>
      <p>Major U.S. stock futures rose as of 4:30 p.m. in Hong Kong. The S&P 500 futures index inched up 0.090%, the tech-heavy Nasdaq-100 futures gained 0.12%, and the Dow Jones Industrial Average futures increased 0.015%.</p>
      <p>Investor sentiment was lifted by the prospect of the debt ceiling crisis being resolved after President Joe Biden and House Speaker Kevin McCarthy indicated on Wednesday that the government would not default on its debt.</p>
      <p>The optimism surrounding the U.S. debt ceiling talks inspired European bourses on Thursday. Germany’s DAX 40 rose 1.41% to 16,176 points, its highest level since Jan. 2022, while the benchmark STOXX 600 rose 0.51%.</p>
      <p>Eurozone inflation accelerated to 7.0% in April from 6.9% a month earlier, mainly driven by rising services and energy costs. Core inflation excluding food and fuel prices slowed to 7.3% from 7.5%, according to Eurostat.</p>
    </div>
  },
  {
    id: 525,
    title: 'XRP gains as SEC motion denied; NFT index down; US investors anticipate debt ceiling deal',
    category: 'Crypto',
    subCategory: ['XRP', 'SEC', 'US'],
    description: <div className="blog-desc">
      <p>Bitcoin prices fell on Wednesday morning in Asia but remained above the US$27,000 line. Ether gained but still posted a weekly drop.</p>
    </div>,
    authorName: 'Danny Park',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 17, 2023 - 5:25 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-528.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin prices fell on Wednesday morning in Asia but remained above the US$27,000 line. Ether gained but still posted a weekly drop. XRP led gainers among other top 10 non-stablecoin cryptocurrencies on the back of a favorable ruling for Ripple Labs in its lawsuit with the Securities and Exchange Commission (SEC). U.S. equity futures gained as U.S. President Joe Biden and Congress reportedly made progress on raising the debt ceiling.</p>
    	<p><strong>Ripple gains after positive ruling</strong></p>	
    	<p>Bitcoin fell 0.87% to US$27,036.75 in the 24 hours to 7:35 a.m. in Hong Kong, but lost 2.05% over the last seven days according to CoinMarketCap data.</p>	
    	<p>Ether edged up 0.28% to US$1,825.91, while posting a 1.10% weekly loss.</p>
      <p>Other top 10 non-stablecoin cryptocurrencies traded mixed on Wednesday morning. XRP led the gains, adding 2.68% to US$0.4399.</p>
      <p>The gain follows positive news for Ripple Labs, a payments firm that depends on XRP technology, as Judge Analisa Torres for the U.S. District Court for the Southern District of New York denied the SEC’s motion to seal the controversial Hinman Speech documents from the public.</p>
      <p>The document refers to a speech given by former SEC official William Hinman on June 14, 2018. In the speech, Hinman stated that the agency did not view Bitcoin or Ethereum as securities. This statement is a major point of focus in the long-running legal battle between Ripple and the SEC. The regulator accuses Ripple of providing unregistered securities.</p>
      <p>Meanwhile, Polygon’s Matic led losses among the top 10 non-stablecoins. It registered a loss of 2.21% to US$0.8442, with a 3.56% drop over the past seven days.</p>
      <p>The total crypto market capitalization fell 0.93% in the past 24 hours to US$1.13 trillion. The total trading volume also lost 7% to US$28.74 billion.</p>
      <p><strong>Bitcoin to challenge Ethereum’s NFT supremacy?</strong></p>
      <h3>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</h3>
      <p>In the non-fungible token market, the Forkast 500 NFT index recorded a loss of 0.13% to 3,378.53 points in the 24 hours to 7:30 a.m. in Hong Kong, down 2.32% during the week.</p>
      <p>This, said CryptoSlam NFT analyst Yehudah Petscher, can again be attributed to “low volume and high transacting fees from crypto trading.”</p>
      <p>24-hour NFT sales on Ethereum, the leading blockchain for NFTs, rose 2.16% to US$18.6 million. Sales on the Bitcoin blockchain took second place on Cryptoslam’s blockchain ranking by gaining 32.11% to US$4.6 million.</p>
      <p>“Bitcoin NFTs are just getting started and in the long run, I expect Bitcoin to compete head-to-head with Ethereum’s all-time sales. It may actually exceed them too,” Yehudah said.</p>
      <p>The Bored Ape Yacht Club NFT collection recorded the highest sales volume in the last 24 hours. It rose 8% to US$2.48 million. All 24 highest individual NFT sales in the past day were Bored Ape Yacht Club NFTs according to Cryptoslam. BAYC #466 sold for 98 ETH (US$178,751).</p>
      <p>Milady Maker was second in terms of NFT collection rankings by sales volume on Cryptoslam. It recorded a surge of 207.61% over the past 24 hours to US$1.3 million. The collection took the market by storm last week after Twitter and Tesla chief Elon Musk tweeted an image containing the Milady NFT.</p>
      <p>Traders can expect a slow week ahead in the NFT market as many in the NFT community attend the VeeCon Web3 conference in Indianapolis on May 18-20.</p>
      <p>“Those who believe NFTs can function as access passes, art, and collectibles all in one are in for a treat,” said Cryptoslam’s Yehudah. “I’m expecting markets to be slow while many are off networking, but we also know that degens are going to degen.”</p>
      <p><strong>U.S. stock futures gain after positive debt ceiling talks</strong></p>
      <p>U.S. stock futures traded higher as of 9:30 a.m. in Hong Kong, while the Dow Jones Industrial Average futures rose 0.15%. The S&P 500 futures added 0.19%. The Nasdaq Composite futures also gained 0.24% as investors anticipate a solution to the U.S. debt crisis.</p>
      <p>U.S. President Joe Biden met with leaders of Congress Tuesday to hash out an agreement that would allow a rise in the debt ceiling..</p>
      <p>On May 1, U.S. Treasury Secretary Janet Yellen warned Congress that the country will start to fail to meet debt payment obligations as early as June 1. That would result in a debt default and wider economic repercussions in the U.S. and globally.</p>
      <p>After the one-hour talk at the White House Tuesday, Biden told reporters that the discussion was productive. House Representative Kevin McCarthy appeared to agree. He said it is possible to reach an agreement on raising the debt ceiling by the end of this week.</p>
      <p>Yellen warned again Tuesday that a debt default would result in an income shock that could lead to a recession. This would destroy jobs and businesses in the country, she said. The Treasury leader called for faster action on raising the debt ceiling. She added that the country is already seeing a heavier debt burden as the stalemate drives borrowing costs higher.</p>
      <p>The Dow Jones, S&P 500 and Nasdaq Composite each posted losses during regular trading on Tuesday following earnings report releases earlier in the day. Major retailer Home Depot’s revenue dropped 4.2% year on year in the first quarter. Company executives warned investors it may see its first annual sales decline since 2009.</p>
      <p>Investors now await more earnings reports from Tencent Holdings, Cisco Inc., Siemens AG, Target Corp. and more on Wednesday.</p>
      <p>Federal Reserve Chair Jerome Powell is expected to share his views on interest hikes Friday ahead of the Fed’s next interest rate decision on June 14. The rate of between 5 and 5.25% is the highest since 2006. The CME FedWatch Tool predicts a 77.5% chance the Fed will keep rates unchanged in June. However, it also predicts a 22.5% chance for another 25 basis-point rate hike.</p>
    </div>
  },
  {
    id: 524,
    title: 'Bitcoin, Ether fall, Solana leads declines; U.S. stock futures trade flat; Asian equities mixed',
    category: 'Crypto',
    subCategory: ['BTC', 'ETH', 'Salona'],
    description: <div className="blog-desc">
      <p>Bitcoin, Ether fell in Tuesday afternoon trade in Asia, while all other top 10 non-stablecoin cryptocurrencies traded mixed.</p>
    </div>,
    authorName: 'Pradipta Mukherjee',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 16, 2023 - 3:37 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-529.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin, Ether fell in Tuesday afternoon trade in Asia, while all other top 10 non-stablecoin cryptocurrencies traded mixed. Solana was the biggest loser in 24 hours while Litecoin rose the most. European bourses were mixed while U.S. stock futures mostly traded flat. Asian equities were mixed after industrial output in China fell short of expectations, raising doubts over its economic recovery after three years of pandemic-induced disruptions.</p>
	    <p><strong>Top 10 cryptos trade mixed</strong></p>
      <p>Bitcoin fell 0.58% to US$27,261 in 24 hours to 4 p.m. in Hong Kong, after losing 1.51% on the week, according to CoinMarketCap. The world’s biggest cryptocurrency has been experiencing network congestion and upward pressure on transaction costs due to the popularity of Bitcoin Ordinals and the increased interest in BRC-20 memecoins like Pepe.</p>	
	    <p>Ether, the second-biggest cryptocurrency in the world, dropped 0.36% to US$1,822 and declined 1.48% on the week.</p>	
	    <p>Solana was the biggest loser of the day, dropping 1.67% to US$21.05, but gained 1.65% in the last seven days. Cardano’s ADA token was the second-biggest loser in 24 hours, falling 1.59% to US$0.3683, but is still up 0.91% on the week.</p>
      <p>Litecoin rose the most, gaining 1.65% to US$88.70, bringing its weekly gains to 12.6%. Like Bitcoin, Litecoin has a finite supply and works on a proof-of-work model. It faced a surge in interest due to the higher transaction fees affecting Bitcoin.</p>
      <p>The cryptocurrency is having its quadrennial block halving event on Aug. 3. Halving refers to a pre-programmed reduction of the rate at which new tokens are created, which reduces supply and can drive prices higher.</p>
      <p>The global crypto market capitalization dropped 0.91% to US$1.13 trillion, while the total crypto market volume rose 1.81% to US$28.36 billion in 24 hours.</p>
      <p><strong>Bored Apes boost Ethereum NFT sales</strong></p>
      <h3>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</h3>
      <p>In the NFT market, the Forkast 500 NFT index increased 0.82% to 3,377.81 points in the 24 hours to 4:30 p.m. in Hong Kong, but declined 3.15% during the week.</p>
      <p>24-hour NFT sales on Ethereum increased by 18.74% to US$18 million, boosted by a 390.38% sales surge for the Bored Ape Yacht Club, which generated US$2.9 million in sales for the network. 24-hour sales for the Mutant Ape Yacht Club were also up 401.44% to US$1.9 million, while sales for The Captainz collection rose 163.09% to US$1.4 million.</p>
      <p>As for the day’s largest NFT sales, Bored Ape #4980 sold for US$969,903 and DeGod #3251 sold for US$180,799, according to CryptoSlam data.</p>
      <p>“The weather in NFTs has been perfect, with good news from established projects, hot tech, a bit of money in traders’ pockets from trading meme coins, and a well-timed tweet from none other than the current (and soon-to-be former) chief executive officer of Twitter, Elon Musk,” according to Yehudah Petscher, an NFT strategist at Forkast Labs.</p>
      <p><strong>Asian equities mixed after China’s industrial output disappoints</strong></p>
      <p>Asian equity markets were mixed on Tuesday after China’s industrial production expanded by 5.6% year-on-year in April, missing market forecasts of about 10.9%, indicating that the economy is yet to fully recover after the removal of pandemic restrictions.</p>
      <p>Unemployment for 16-to-24-year-olds in the world’s second-biggest economy hit a record high of 20.4% in April, nearly four times higher than unemployment across all age groups, signaling that the health of the economy is not strong enough to absorb new entrants into the labor market.</p>
      <p>“Rising demand from China as its economy reopens could cause oil prices to climb and energy inflation to pick up again as we head into 2024. This would be against a backdrop of OPEC+ production cuts and geo-political tensions,” said David Rees, senior emerging markets economist at Schroders, said in an emailed statement.</p>
      <p>“Core inflation is still well above target in most major emerging markets, particularly in Latin America and Central Eastern Europe, but it has at least stopped rising, and slower economic growth as higher interest rates bite ought to gradually bring down core inflation,” Rees added.</p>
      <p>The Shanghai Composite dropped 0.6%, the Shenzhen Component Index slipped 0.71% while Hong Kong’s Hang Seng Index gained 0.04%. Japan’s Nikkei 225 strengthened 0.73% on Tuesday to close at an 18-month high, extending a climb towards its highest since the early 1990s, aided by strong corporate earnings and signs of easing inflationary pressure.</p>
      <p>U.S. stock futures were mostly trading flat as of 4:30 p.m. in Hong Kong. The S&P 500 futures index inched down 0.078%, the tech-heavy Nasdaq-100 futures inched up 0.0093% and the Dow Jones Industrial Average futures decreased 0.084%.</p>
      <p>Investors were optimistic about Atlanta Fed President Raphael Bostic and Chicago Fed boss Austan Goolsbee signaling their intent to pause interest rate hikes in the world’s largest economy.</p>
      <p>Goolsbee hopes to await the effects of increases aimed at lowering inflation from multi-decade highs. While Bostic also favors a pause on rate hikes for now, he suggested that the next move is likely to raise rates instead of lowering it, in light of persistent price pressures.</p>
      <p>Investors are now looking ahead at President Joe Biden’s negotiations with congressional leaders on raising the U.S. debt ceiling to keep the government solvent. The negotiations are set to resume on Tuesday. The U.S. treasury reiterated on Monday that it expects to default by June 1, without a debt limit increase.</p>
      <p>Pressured by the prospect of a government default, the U.S. dollar fell 0.17% to 102.2 points on Tuesday. The euro gained ground, increasing 0.22% to US$1.08, remaining close to its 12-month high of US$1.10.</p>
      <p>European bourses were mixed, despite data showing that Europe’s economy grew by a modest 0.1% in the first quarter while employment grew 0.4%, according to Eurostat. The pan-European STOXX 600 fell 0.11% while Germany’s DAX 40 inched up 0.095%.</p>
      <p>Markets were also weighed down by the release of the ZEW Indicator of Economic Sentiment amid already unfavorable economic sentiment over the next six months. ZEW’s measure of investor morale fell to -10.7 in May, the first negative since December 2022. The indicator declined from 4.1 in April and fell more than analyst expectations that predicted a decline to -5.</p>
    </div>
  },
  {
    id: 523,
    title: 'Bitcoin, Ether fall along with most top 10 cryptos; U.S. futures rise ahead of debt ceiling negotiations',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'ETH', 'Rise'],
    description: <div className="blog-desc">
      <p>Bitcoin and Ether fell during Friday afternoon trading in Asia, along with most of the top 10 non-stablecoin cryptocurrencies by market capitalization, apart from the XRP token.</p>
    </div>,
    authorName: 'Zoltan Vardai',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 12, 2023 - 2:33 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-511.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin and Ether fell during Friday afternoon trading in Asia, along with most of the top 10 non-stablecoin cryptocurrencies by market capitalization, apart from the XRP token. Most Asian equities declined despite data reflecting an economic recovery in Hong Kong U.S. stock futures strengthened for a second consecutive session, as negotiations over raising the debt ceiling are set to resume in the world’s largest economy.</p>
    	<p><strong>Crypto market declines as Bitcoin falls below US$27,000</strong></p>	
    	<p>Bitcoin dropped 4.14% to US$26,293 in 24 hours to 4:30 p.m. in Hong Kong, according to CoinMarketCap data. The daily decline by the world’s first cryptocurrency was the biggest daily drop in the top 10 coins by market cap.</p>	
    	<p>“Bitcoin has to hold [US$26,111] for continued bullish momentum,” Nilesh Verma, crypto market analyst and founder of Crypto Granth consultancy, told Forkast. “We believe that we will see strong buying near US$25,000-26,000,” he added.</p>
      <p>Ether fell 3.48% in the past 24 hours to trade at US$1,757, falling below US$1,800 for the second time this week.</p>
      <p>The Beacon Chain of the Ethereum Network briefly stopped validating transactions on early Friday morning, with Ethereum developers tweeting later that the outage had been resolved and the cause still under investigation.</p>
      <p>The XRP token was the only one in the green, inching up 0.75% to US$0.4271.</p>
      <p>The global crypto market capitalization fell 2.81% to US$1.1 trillion, while the total crypto market volume decreased 9.30% to US$42.22 billion in the last 24 hours.</p>
      <p><strong>NFTs sales on Ethereum fall</strong></p>
      <p>In the NFT market, the Forkast 500 NFT index decreased 2.21% to 3,363.89 points in the 24 hours to 4:30 p.m. in Hong Kong and declined 7.77% during the week.</p>
      <p>NFT trading is slowing due to the recent memecoin hype, with very few new users entering the space, according to Eric Dettman, NFT advisor at CryptoSlam. NFT buyers on the Ethereum blockchain totaled 45,298 in the past seven days, a drop of 72.97%.</p>
      <p>24-hour NFT sales on Ethereum dropped 51.7% to US$16.3 million, after yesterday’s strong session. Sales for the Bored Ape Yacht Club, the largest Ethereum-native NFT collection, fell 60.02% to US$2.4 million, while sales for the Mutant Ape Yacht Club also decreased 61.78% to US$1.4 million. 24-hour sales for Milady Maker dropped 88.83% to US$864,000, after generating over US$9.6 million of sales for the network on Thursday.</p>
      <p>As for the day’s most notable NFT transactions, Bored Ape #6805 sold for US$349,967 3 hours ago and CryptoPunk #1232 sold for US$148,324 8 hours ago, according to CryptoSlam data.</p>
      <p><strong>Most Asian equities fall; U.S. futures rise for second consecutive session</strong></p>
      <p>Asian equities declined on Friday, tracking losses on Wall Street, except for Japan’s Nikkei 225 that rose 0.9%. The Shanghai Composite dropped 1.12%, Hong Kong’s Hang Seng Index fell 0.59% and the Shenzhen Component Index declined 1.23%</p>
      <p>Investors reacted negatively to government data showing that Hong Kong’s economy grew 2.7% year-on-year in the first quarter, recovering from a 4.1% contraction in the previous quarter, accounting for a lower Hang Seng Index on the day. The expansion was Hong Kong’s first economic upturn since the fourth quarter of 2022, mainly supported by a recovery in the tourism sector and domestic demand</p>
      <p>U.S. stock futures strengthened for a second consecutive trading session, after consumer prices eased more than expected in April and bolstered expectations that the Federal Reserve will pause interest rate hikes.</p>
      <p>Overnight, U.S. jobless claims increased, adding to market concern of a potential recession, with the job market as one of the pillars that prop up the economy.</p>
      <p>The S&P 500 futures index rose 0.39%, the tech-heavy Nasdaq-100 futures gained 0.22% and the Dow Jones Industrial Average futures increased 0.41%.</p>
      <p>The U.S. dollar index inched up 0.06% to 102.1 points, set for its first weekly gain in three weeks. The euro inched down 0.07% to US$1.09, the lowest value in three weeks.</p>
      <p>President Joe Biden is set to continue negotiations to raise the debt ceiling later today, to keep the U.S. government solvent. Wednesday’s talks over the matter ended with no deal in sight.</p>
      <p>The Bank of England raised interest rates by 25 basis points on Thursday, marking its 12th consecutive hike, increasing borrowing costs to 4.5% – the highest since October 2008.</p>
      <p>London’s benchmark FTSE 100 rose 0.47% on Friday, after three consecutive sessions of losses, after data showed that the UK’s economy expanded 0.1% in the first quarter of the year, in line with market expectations.</p>
    </div>
  },
  {
    id: 522,
    title: 'Binance pulls out of Canada amid new crypto regulations',
    category: 'Crypto',
    subCategory: ['Binance', 'Canada', 'crypto'],
    description: <div className="blog-desc">
      <p>(Reuters) -Binance said on Friday it was withdrawing from Canada, weeks after the country issued a series of new guidelines for cryptocurrency exchanges including investor limits and mandatory registrations.</p>
    </div>,
    authorName: 'Reuters',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 12, 2023 - 11:21 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-512.jpg',
    content: <div className="blog-content">
    	<h3>In thi article:</h3>
    	<p>(Reuters) -Binance said on Friday it was withdrawing from Canada, weeks after the country issued a series of new guidelines for cryptocurrency exchanges including investor limits and mandatory registrations.</p>
    	<p>Canada has tightened regulations for crypto asset trading platforms in recent months, with the introduction of a pre-registration process. The companies that do not adhere to the rules will face potential enforcement action, according to the website of the Ontario Securities Commission.</p>	
    	<p>"Unfortunately, (the) new guidance related to stablecoins and investor limits provided to crypto exchanges makes the Canada market no longer tenable for Binance at this time," crypto exchange Binance said in a tweet.</p>	
    	<p>Binance said it does not agree with the latest guidance and hopes to engage with the Canadian regulators to create a comprehensive framework for crypto operations in the country.</p>
      <p>"We are confident that we will someday return to the market when Canadian users once again have the freedom to access a broader suite of digital assets," said the crypto exchange, founded by Canadian national Changpeng Zhao.</p>
      <p>The digital assets industry has been in the crosshairs of regulators around the world, especially since the collapse of Binance-rival FTX in November, which triggered a market rout in the prices of the biggest digital coins.</p>
      <p>Following the onset of the crypto winter of 2022, which wiped out more than a trillion dollars from the industry's market value, lawmakers and securities regulators demanded tighter guidelines for disclosures on how the crypto companies operate and hold customer funds.</p>
      <p>In March, Binance and its CEO Zhao were sued by the U.S. Commodity Futures Trading Commission for operating what the regulator alleged was an "illegal" exchange and a "sham" compliance program.</p>
      <p>(Reporting by Manya Saini in Bengaluru; Editing by Shinjini Ganguli)</p>
    </div>
  },
  {
    id: 521,
    title: 'Bitcoin Prices Slip to 2-Month Lows as CoinDesk’s Bitcoin Trend Indicator Turns Neutral From Bullish',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Prices', 'CoinDesk'],
    description: <div className="blog-desc">
      <p>Bitcoin (BTC) continues to lose ground in the wake of CoinDesk's Bitcoin Trend Indicator shedding the bullish bias for the first time in three weeks.</p>
    </div>,
    authorName: 'Omkar Godbole',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 12, 2023 - 11:06 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-513.jpg',
    content: <div className="blog-content">
    	<h3>In this atricle:</h3>
    	<p>Bitcoin (BTC) continues to lose ground in the wake of CoinDesk's Bitcoin Trend Indicator shedding the bullish bias for the first time in three weeks.</p>
    	<p>The top cryptocurrency by market value fell to $26,160 before press time, reaching the lowest since March 17, according to CoinDesk data. Prices have declined by over 12% since May 6, decoupling from the uptick in traditional risk assets like Nasdaq.</p>	
    	<p>The BTI indicator, which measures the directional momentum and strength in bitcoin's price, flipped from bullish to neutral on Thursday. The indicator consistently signaled an uptrend since Jan. 13, barring the brief red flash (downtrend signal) in mid-March and the neutral reading on April 24.</p>	
    	<p>According to Matthew Dibb, chief investment officer at Astronaut Capital, low liquidity seems to have helped few sellers drive prices lower.</p>
      <p>"There seems to be 'paper thin' liquidity right now, even across majors such as BTC. While we can't point at a direct reason for the weakness, any medium to large offers are driving the market down," Dibb said.</p>
      <p>Liquidity or market depth has recently worsened on major exchanges, including Binance, making it difficult for traders to execute large orders without influencing prices.</p>
      <p>According to Dick Lo, the founder and CEO of quant-driven crypto trading firm TDX Strategies, the downside move may gather pace if traditional risk assets turn lower.</p>
      <p>"We could get an acceleration on the downside IF U.S. equities also start to roll over," Lo told CoinDesk. "$25,200 is key support for BTC followed by $23,100 on potential downside acceleration."</p>
      <p>Lo added that the bearish bias would be invalidated if prices rise above $28,500.</p>
      <p>As analysts warned last week, bitcoin's drop to two-month lows has confirmed a head-and-shoulders bearish reversal pattern on technical charts. The breakdown has opened doors for a deeper slide toward support around $25,000.</p>
    </div>
  },
  {
    id: 520,
    title: 'Bitcoin, Ether edge up; Litecoin leads winners, Matic the losers; U.S. equity futures flat',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Ether', 'Litecoin'],
    description: <div className="blog-desc">
      <p>Bitcoin edged higher on Wednesday morning in Asia to hold above the US$27,500 mark as the hype for Bitcoin-based memecoins that had clogged the network seemed to cool off.</p>
    </div>,
    authorName: 'Tom Zuo',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 10, 2023 - 6:24 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-507.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin edged higher on Wednesday morning in Asia to hold above the US$27,500 mark as the hype for Bitcoin-based memecoins that had clogged the network seemed to cool off. Ether also gained, while other top 10 non-stablecoin cryptocurrencies traded mixed. Litecoin led the winners as high transaction fees on Bitcoin drove users to alternatives, with the Litecoin network logging a record-high in daily transactions. Polygon’s Matic led the losers. U.S. equity futures traded flat on concern about U.S. debt ceiling negotiations and ahead of inflation numbers on Wednesday.</p>
    	<p><strong>Bitcoin, Ether stabilize after dip</strong></p>	
    	<p>Bitcoin edged up 0.60% to US$27,754 in the 24 hours to 09:00 a.m. in Hong Kong, according to CoinMarketCap data, but was still holding a weekly loss of 2.64%.</p>	
    	<p>“Bitcoin prices have been impacted by significant congestion and skyrocketing transaction fees,” Denys Peleshok, head of Asia at London-based financial institute CPT Markets, said in an email. “Such network issues and infrastructure disruptions could limit institutional investors’ confidence in the asset.”</p>
      <p>The congestion on the Bitcoin network is attributed to the hype around BRC-20, a token standard introduced on March 9 that enables the minting of fungible tokens on the Bitcoin blockchain. The total market cap of BRC-20 tokens has dropped from around US$961 million on Monday to US$688 million at 9:00 a.m. on Wednesday in Hong Kong, according to blockchain data tracker BRC-20.io.</p>
      <p>Ether gained 0.49% to US$1,852. It’s down 0.44% for the week.</p>
      <p>Other top 10 non-stablecoin cryptocurrencies traded mixed, with Polygon’s Matic leading the losers, falling 2.40% to US$0.8782 and posting a weekly loss of 9.47%.</p>
      <p>Litecoin led the winners, gaining 3.45% to US$80.33. However, it’s down 8.56% for the week. The Litecoin blockchain has logged record-high daily transactions this week as high transaction fees on the Bitcoin network drove users to other platforms. Transactions on the Litecoin blockchain totaled over 525,000 on Monday, more than five times higher than the start of the month, according to blockchain data platform BitInfoCharts.</p>
      <p>The total crypto market capitalization edged up 0.46% in the past 24 hours to US$1.14 trillion. The total trading volume dropped 29.34% to US$32.02 billion.</p>
      <h3>NFTs fall</h3>
      <p><strong>The indexes are proxy measures of the performance of the global NFT market. They are managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index dipped 0.91% to 3,462.33 in the 24 hours to 09:00 a.m. in Hong Kong, losing 6.64% for the week.</p>
      <p>Azuki, an Ethereum-based NFT collection, saw the largest sales across the market in the past 24 hours, surging 388.12% to US$2.47 million to surpass US$1.51 million for DMarket and US$880,000 for Bored Ape Yacht Club, according to data by CryptoSlam.</p>
      <p>Binance, the world’s biggest crypto exchange, said Tuesday its NFT marketplace would soon add support for Bitcoin Ordinals inscriptions – the Bitcoin blockchain’s iteration of NFTs. The platform currently supports NFTs on BNB Chain, Ethereum and Polygon.</p>
      <p>Equities futures flat</p>
      <p>U.S. stock futures were flat to slightly higher as of 9:00 a.m. in Hong Kong. The Dow Jones Industrial Average futures edged up 0.06%. The S&P 500 futures gained 0.10%. And the Nasdaq Composite futures added 0.08%.</p>
      <p>The three U.S. indexes closed lower on Tuesday amid concerns about the U.S. government’s potential default in June. U.S. President Joe Biden met with congressional Republicans on Tuesday to discuss raising the government’s debt ceiling, but made little progress, according to Bloomberg on Wednesday. Another meeting is set for Friday.</p>
      <p>The U.S. April consumer price index report on Wednesday will give a peek into the pace of inflation and provide insights into the Federal Reserve’s next move on interest rates after a year-long round of hikes. Economists predict the inflation rate rose 5% in April on year, or the same pace as March and still far from the Fed’s goal of 2%, according to a Wednesday Bloomberg report.</p>
      <p>New York Federal Reserve President John Williams said on Tuesday it was still premature to say whether the Fed had done with its rate hikes, adding there is “a need to keep a restrictive stance of policy in place for quite some time to make sure” the inflation rate falls to 2%, according to Reuters on Wednesday.</p>
      <p>The Fed is set to meet on June 14 to decide on interest rates, which are now between 5 and 5.25%, the highest since 2006. The CME FedWatch Tool predicts a 78.8% chance the Fed will keep rates unchanged in June, while it gives a 21.2% chance for another 25 basis-point rate hike, up from 14.3% on Tuesday.</p>
    </div>
  },
  {
    id: 519,
    title: 'Ether Staking Deposits Top Withdrawals for First Time Since Shapella Upgrade',
    category: 'Crypto',
    subCategory: ['Ether', 'shapella', 'withdrawals'],
    description: <div className="blog-desc">
      <p>Ether (ETH) holders are rushing to stake their tokens with network validators, pushing deposit activity to the highest level since the Shapella upgrade earlier this year.</p>
    </div>,
    authorName: 'Shaurya Malwa',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 9, 2023 - 2:21 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-509.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Ether (ETH) holders are rushing to stake their tokens with network validators, pushing deposit activity to the highest level since the Shapella upgrade earlier this year.</p>
	    <p>More than 200,000 ether have been deposited to the network since the start of the week, data from the on-chain analytics tool Nansen show, marking the first time deposits have outpaced withdrawals since Shapella went live last month. The additions bring the number of ether locked for staking purposes to over 19 million tokens – about 15% of the total circulating supply.</p>	
	    <p>The influx comes as traders flock to meme coins such as pepecoin (PEPE), which has strained the Ethereum network and sent transaction fees to a 12-month high.</p>	
	    <p>Over 6 million staked ethers are held on Lido Finance, a protocol that issues depositors with alternative tokens representing the amount they've locked up. Those alternatives can then be used as liquidity in the broader decentralized finance (DeFi) ecosystem.</p>
      <p>Shappella – a portmanteau of Shanghai and Capella, two major Ethereum network upgrades that occurred simultaneously on April 12 – gave investors the ability to withdraw their staked ether at will for the first time.</p>
      <p>In a proof-of-stake blockchain such as Ethereum, users stake, or lock, cryptocurrency – ether in this case – to help secure and confirm new data blocks. These stakers receive network rewards in the form of tokens, creating a form of passive investing strategy.</p>
      <p>Platforms such as Lido pay out 6.6% in annualized yield rewards to stakers. More complex strategies involving staked ether and other tokens can yield up to 21%, data from Defillama shows.</p>
    </div>
  },
  {
    id: 518,
    title: 'Binance’s NFT Marketplace Adds Support for Bitcoin NFTs',
    category: 'Crypto',
    subCategory: ['Binance', 'NFT', 'Marketplace'],
    description: <div className="blog-desc">
      <p>Leading cryptocurrency exchange Binance’s non-fungible token (NFT) marketplace said Tuesday it’s planning to add support for Ordinals, or Bitcoin NFTs later in May, though an exact date was not announced.</p>
    </div>,
    authorName: 'Cam Thompson',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 9, 2023 - 1:00 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-510.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Leading cryptocurrency exchange Binance’s non-fungible token (NFT) marketplace said Tuesday it’s planning to add support for Ordinals, or Bitcoin NFTs later in May, though an exact date was not announced.</p>
    	<p>Binance NFT, which already supports NFTs on Ethereum, Polygon and its native BNB Chain, plans to expand its offerings by allowing traders to purchase Ordinals on the Bitcoin network. Collectors can soon buy and sell inscriptions, or NFTs created on the Bitcoin network, expanding the reach of the nascent Ordinals ecosystem.</p>	
    	<p>Additionally, in order to help onboard users to Bitcoin NFTs, Binance will allow traders to purchase Bitcoin-based NFTs with their Binance accounts, simplifying the process for users. Instead of having to set up a taproot-compatible Bitcoin wallet to purchase Ordinals, as collectors wanting to mint NFT giant Yuga Labs’ TwelveFold Ordinals needed to do, Binance aims to make the process simple and secure for new buyers to enter the growing market.</p>	
    	<p>Binance Head of Product Mayur Kamat said in a press release that he sees Bitcoin as “the broadest choice” for NFT collectors as well as a growing market for the exchange to tap into.</p>
      <p>“Bitcoin is the OG of crypto,” said Kamat. “We believe things are just getting started here and can’t wait to see what the future holds in this space.”</p>
      <p>However, Binance’s relationship with Bitcoin hasn’t all been positive in recent days. On Sunday, the exchange paused Bitcoin withdrawals twice, citing network congestion as the catalyst.</p>
      <p>As Ordinals reached 3 million inscriptions last week, the demand for marketplaces to embrace Bitcoin NFTs is only intensifying. In March, NFT marketplace Magic Eden added support for Ordinals, integrating Bitcoin wallets Hiro and Xverse to do so. In April, the marketplace rolled out a creator launchpad to help artists mint their inscriptions before listing on the secondary marketplace.</p>
    </div>
  },
  {
    id: 517,
    title: 'Binance Resumes Bitcoin Withdrawals, Looks at Lightning Network Integration',
    category: 'Crypto',
    subCategory: ['Binance', 'Bitcoin', 'withdrawals'],
    description: <div className="blog-desc">
      <p>Binance, one of the largest cryptocurrency exchanges in the world, temporarily halted Bitcoin withdrawals twice in 24 hours over the weekend.</p>
    </div>,
    authorName: 'Kyle Torpey',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 9, 2023 - 6:29 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-508.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Binance, one of the largest cryptocurrency exchanges in the world, temporarily halted Bitcoin withdrawals twice in 24 hours over the weekend. The exchange cited "network congestion" as the reason for the suspension, which lasted for about an hour on Sunday. The move came after Binance faced a similar issue on Saturday, when it paused Bitcoin withdrawals for about 20 minutes due to a "minor technical issue". The exchange assured its users that their funds were safe and that it was working to resolve the problem as soon as possible.</p>
    	<p>Bitcoin withdrawals are a crucial function for cryptocurrency exchanges, as they allow users to move their coins from the platform to their own wallets or other services. Any disruption or delay in this process can cause frustration and suspicion among customers, especially in a volatile market.</p>	
    	<p>Binance said they have increased the fees on withdrawals and are working to integrate the Lightning Network, a layer-two payments protocol for Bitcoin.</p>	
    </div>
  },
  {
    id: 516,
    title: 'Polygon [MATIC] approaches range lows – what traders can expect next',
    category: 'Crypto',
    subCategory: ['Polygon', 'MATIC', 'Traders'],
    description: <div className="blog-desc">
      <p>MATIC’s bullish order block at key support level continues to slow down bearish momentum.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 8, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-500.jpg',
    content: <div className="blog-content">
    	<h3>In this article</h3>
    	<p>MATIC’s bullish order block at key support level continues to slow down bearish momentum.</p>
      <ul>
        <li>MATIC’s market structure on the daily timeframe remained bearish.</li>
        <li>Indecision between buyers and sellers kept prices stuck in a range. </li>
      </ul>
    	<p>Polygon [MATIC] began the year as one of the best-performing cryptocurrencies. It posted a 108.9% gain between 1 January and 18 February. However, a huge correction flipped its market structure from bullish to bearish.</p>	
    	<p>Prices traded sideways between mid-March to mid-April before BTC’s correction sent MATIC on another downward spiral.</p>	
    	<p><strong>MATIC stuck in a tight spot</strong></p>
      <p>The indecision between buyers and sellers has confined MATIC to a tight range for the past three weeks. It has traded between the $0.950 support level and $1.028 resistance level on the four-hour timeframe.</p>
      <p>The bullish order block’s confluence with the $0.950 support level just below it has served as a rallying point for bulls. Price has bounced off the support level four times in quick succession to stem the selling pressure.</p>
      <p>However, the RSI and CMF both pointed to weakening buying pressure at that level. The RSI has stayed under neutral 50 over a two-day period while the CMF was at -0.15 as of press time.</p>
      <p>A four-hour candle close below the $0.950 support level could lead to a bearish breakout for MATIC with $0.835 as the target for bears.</p>
      <p><strong>Could a bullish reversal be on the horizon?</strong></p>
      <p>A look at key on-chain metrics suggested MATIC buyers might not be giving up yet. Data from Santiment showed a decline in active addresses and an increase in the total number of holders. This showed that an accumulation could be ongoing for Polygon’s native currency.</p>
      <p>Funding rates have been consistently positive over the past 14 days, which showed that demand existed for MATIC, even at press time. The spot CVD also experienced an uptick.</p>
      <p>A bullish reversal will be hugely dependent on the confluence of the bullish order block and support level at $0.950 holding out once again.</p>
    </div>
  },
  {
    id: 515,
    title: 'Crypto market in flux: ETH, PEPE contribute to $50 million liquidation; short positions suffer',
    category: 'Crypto',
    subCategory: ['ETH', 'PEPE', 'Market'],
    description: <div className="blog-desc">
      <p>The crypto market was awash with fear and uncertainty as liquidations reached over $50 million, leaving investors reeling and short positions crushed under losses.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 8, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-499.jpg',
    content: <div className="blog-content">
    	<h3>In this article</h3>
	    <p>The crypto market was awash with fear and uncertainty as liquidations reached over $50 million, leaving investors reeling and short positions crushed under losses.</p>
    	<ul>
        <li>The crypto market witnesses a $50 million liquidation event, with Ethereum and memecoins at the forefront.</li>
        <li>Short positions take a hit as investors face substantial liquidations.</li>
      </ul>
      <p>7 May witnessed a dramatic event in the crypto market as over $50 million worth of investments got liquidated. Ethereum [ETH] took the lead in this wave of liquidation. However, hot on its heels was a memecoin that had been capturing the attention of enthusiasts worldwide.</p>	
    	<p><strong>Crypto market witness over $50 million in liquidations</strong></p>	
    	<p>According to the latest data from Coinglass, the crypto market experienced an extraordinary 24-hour liquidation surpassing the $50 million mark. At the time of writing, the liquidation amount stood at approximately $52 million, having previously surged past $100 million. Ethereum took the lead in liquidation volume, with over $2 million currently liquidated.</p>
      <p>Adding to the liquidation statistics was memecoin Pepe [PEPE], which ranked second, with a staggering liquidation of over 640 billion PEPE. The tokens were equivalent to a value exceeding $1 million. Other affected cryptocurrencies included king coin Bitcoin [BTC], with over 1 million coins.</p>
      <p><strong>Short positions see more liquidations</strong></p>
      <p>Coinglass’ data further revealed that the liquidations primarily targeted short positions in the market. The data indicated that a substantial portion of investors holding short positions faced liquidation. Also, over 70% of liquidations were witnessed across various major exchanges.</p>
      <p>In certain instances, the liquidation rate reached a staggering 100%, leaving no room for short investors to escape unscathed.</p>
      <p>At the time of writing, a detailed breakdown of Bitcoin’s liquidation data demonstrated that shorts accounted for over $16 million. Whereas, long positions experienced liquidations totaling over $12 million.</p>
      <p>Considering Bitcoin’s significant share of the total cryptocurrency market capitalization, these liquidations bear significant implications for affected investors.</p>
      <p><strong>Crypto market maintains $1 trillion mark</strong></p>
      <p>According to an evaluation of the crypto market’s capitalization on CoinMarketCap, it remained at an impressive value exceeding $1 trillion. Also, Bitcoin maintained its dominance at nearly 50%.</p>
      <p>At the time of writing, the 24-hour trading volume had surpassed $42 billion. Although a widespread chain reaction of liquidations had not unfolded at that point, the prevailing market conditions warranted a cautious approach from investors.</p>
      <p>Furthermore, the rise in exchange withdrawals and notable instances of high-value cash-outs underscored the need for market participants to remain vigilant and adequately prepared for unforeseen fluctuations in market sentiment.</p>
    </div>
  },
  {
    id: 514,
    title: 'Tron [TRX] retracement on the way? Memecoin mania lends support to price rally',
    category: 'Crypto',
    subCategory: ['Tron', 'TRX', 'Memecoin'],
    description: <div className="blog-desc">
      <p>Tron’s development activity metric recently showed signs that activity is regaining after the previous downside seen since the last week of April. A stronger recovery backed by memecoin-related hype might be enough to also inject some investor excitement into TRX.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 8, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-498.jpg',
    content: <div className="blog-content">
    	<h3>In thsi article</h3>
    	<p>Tron’s development activity metric recently showed signs that activity is regaining after the previous downside seen since the last week of April. A stronger recovery backed by memecoin-related hype might be enough to also inject some investor excitement into TRX.</p> 
    	<ul>
        <li>Tron founder outlines a plan to take advantage of prevailing memecoin excitement.</li>
        <li>TRX on a retracement path after a bullish performance in the first week of May.</li>
      </ul>
      <p>The Tron [TRX] network is looking to capitalize on the recent memecoin hype. There was a lot of excitement in the memecoin segment as the rest of the market continued to slow down.</p>	
    	<p>Tron’s founder, Justin Sun, recently made an announcement revealing his company’s strategy to secure a bigger piece of the memecoin pie. The statement revealed that the network aims to incentivize the creation of more memecoins by offering funding to developers.</p>	
    	<p>Tron will also collaborate with major exchanges, including Poloniex and Huobi. Tron’s goal is to facilitate easy and early access to memecoins. Timely development addresses one of the most common recent problems, which is the availability of multiple scam memecoins alongside legitimate ones. Tron will be addressing such challenges with this memecoin program.</p>
      <p><strong>Can TRX benefit from budding memecoin optimism?</strong></p>
      <p>TRX is among the few cryptocurrencies that pulled off a significant upside since the start of May. However, it experienced some sell pressure over the weekend after briefly crossing into overbought territory.</p>
      <p>TRX exchanged hands at $0.069 after a bit of a weekend slide, although it had fallen by less than 3%. A sizable market reaction may curtain the potential downside and perhaps allow the bulls to regain control. But some of Tron’s metrics point to a different potential outcome.</p>
      <p>A look at TRX’s market cap confirmed recent significant outflows. Meanwhile, on-chain volume continued to drop, as has been the case since the start of May.</p>
      <p>The lack of a volume spike in the last few hours confirms the market’s response or lack there of, to Tron’s memecoin pursuits. In addition, investor sentiment remains low according to the weighted sentiment.</p>
      <p>Despite the above findings, there is one noteworthy upside. Tron’s development activity metric showed signs that activity was rebounding after the previous downside seen since the last week of April. A stronger recovery backed by memecoin-related hype might be enough to also inject some investor excitement into TRX.</p>
      <p>The memecoin hype may only come into play if Tron’s plan attracts more participation from developers. As such, the subsequent impact might only be evident in the mid to long term.</p>
    </div>
  },
  {
    id: 513,
    title: 'Bitcoin Ordinals: Miner fees breaks new record, assessing the effects on BTC',
    category: 'Crypto',
    subCategory: ['BTC', 'Fees', 'bitcoin'],
    description: <div className="blog-desc">
      <p>Bitcoin Ordinals reaches unprecedented heights, breaking fee records and surging in popularity, while BTC price cautiously recovers with modest gains, charting its course.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 8, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-497.jpg',
    content: <div className="blog-content">
    	<h3>in this article</h3>
    	<p>Bitcoin Ordinals reaches unprecedented heights, breaking fee records and surging in popularity, while BTC price cautiously recovers with modest gains, charting its course.</p>
    	<ul>
        <li>Bitcoin Ordinals breaks records with daily fees paid to miners reaching over $1.4 million.</li>
        <li>BTC price shows a different trajectory with modest gains of less than 1%.</li>
      </ul>
      <p>The Bitcoin [BTC] phenomenon continued to surge at press time, breaking new ground with each passing moment. A similar surge in popularity has propelled Bitcoin Ordinals to unprecedented heights.</p>	
    	<p>Ordinals’ latest data showcased a remarkable milestone for Bitcoin’s Non-Fungible Tokens [NFTs]. Riding this exhilarating wave, miners are reaping the rewards of their labor on the network, embracing the winds of change with open arms.</p>	
    	<p><strong>Bitcoin Ordinals breaks records</strong></p>
      <p>Leonidas’ post on 6 May delivered astonishing news regarding the soaring heights reached by Ordinals. The daily fees paid to miners broke all previous records, surpassing even the grand sum of $1.4 million.</p>
      <p>Per Dune Analytics, this remarkable achievement effortlessly outshined the last all-time high of over $800,000. The surge in value was not limited to fees alone, as the volume of Ordinals‘ trade surged to an unprecedented pinnacle, surpassing an astounding $9.2 million.</p>
      <p>The number of daily active users also experienced a surge, surpassing the remarkable milestone of 6,000. This exponential fee growth had a ripple effect on Bitcoin’s overall fee accumulation. Crypto fees revealed a staggering daily fee of over $5 million, indicating a promising upward trend in the daily fee chart. This surge in fees hinted at the potential for further growth.</p>
      <p><strong>Bitcoin miners feast as fees spike</strong></p>
      <p>The impact of Ordinals and BRC-20 transactions has left a significant imprint on miners’ fees, sparking a remarkable surge recently. As of this writing, the miner fees have skyrocketed by a staggering 11%, catapulting them to a level reminiscent of over two years ago.</p>
      <p>Before this impressive surge, the fees had experienced a decline around June 2021, reaching a peak of approximately 3%.</p>
      <p>The surge in Ordinals transactions, coupled with the heightened demand for mining blocks, naturally increased miners’ activities and subsequent fees. However, the sustainability of this remarkable spike remains uncertain, leaving room for speculation and anticipation.</p>
      <p><strong>BTC price sees a different trajectory</strong></p>
      <p>While the Ordinals brought excitement to miners, the price of BTC has yet to mirror this enthusiasm. At the time of writing, Bitcoin was recovering from a recent decline in value experienced during the previous trading period. It was traded at approximately 28,900, showcasing a modest gain of less than 1%.</p>
      <p>Despite the optimism surrounding Ordinals and its impact on miners, the price of Bitcoin appeared to be navigating a different trajectory, displaying a more cautious and subdued movement.</p>
    </div>
  },
  {
    id: 511,
    title: 'Bitcoin could rally 40% by the end of the year, according to a top blockchain founder',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'rally', 'blockchain'],
    description: <div className="blog-desc">
      <p>I'm somewhat of a skeptic when it comes to digital assets, but so far this year bitcoin's been the top performing asset by a landslide — it's up more than 73%.</p>
    </div>,
    authorName: 'Phil Rosen',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 6, 2023 - 3:00 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-490.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p><strong>Welcome to the weekend.</strong> Phil Rosen here, writing to you from Manhattan.</p>
    	<p>I'm somewhat of a skeptic when it comes to digital assets, but so far this year bitcoin's been the top performing asset by a landslide — it's up more than 73%.</p>	
    	<p>I caught up with a blockchain executive to get the scoop on why that rally could see more upside as the economy slows down.</p>	
    	<p>Bob Ras is the cofounder of blockchain firm Sologenic. This conversation has been lightly edited for length and clarity.</p>
      <p><strong>Phil Rosen: What's the impact of the banking crisis and the Fed on the outlook for bitcoin?</strong></p>
      <p><strong>Bob Ras:</strong>As big banks have been collapsing, it seems clearer by the day that these investors see bitcoin as a hedge because of its decentralized censorship resistance and lack of counterparty risk.</p>
      <p>There are growing expectations that the Fed will loosen monetary policy and effectively inject a lot more liquidity into the market to shore up the financial system. And bitcoin is front-running this scenario, pointing to a future that is effectively a return to relatively low rates.</p>
      <p>I wouldn't be surprised if $40,000 could be reached by the end of this year.</p>
      <p><strong>What's been fueling bitcoin's sharp rally to start 2023 so far?</strong></p>
      <p>A big reason why bitcoin has performed so well was that it was just really oversold during the collapse of FTX.</p>
      <p>Plus, there's on-chain evidence that more and more people and institutions have been accumulating bitcoin during the lows, which essentially translated into a supply shock.</p>
      <p>With so few sellers left, any uptick in demand has an outsize impact on price.</p>
      <p><strong>What's a downside risk ahead that could weigh on bitcoin prices?</strong></p>
      <p>The biggest risk would be a sharp credit crisis that causes a rapid contraction.</p>
      <p>In such a scenario, all assets would have a correlation of one with each other, including bitcoin and even gold. I'm not sure such a scenario would happen, but it's possible and would unleash a tremendous amount of volatility not only for bitcoin but all markets.</p>
      <h3>And here are the top stories from markets this week: </h3>
      <p><strong>1. A stock portfolio created by ChatGPT is outperforming the top investment funds across the UK.</strong> The theoretical portfolio created by Finder.com includes 38 stocks, and it's generated 4.9% returns over eight weeks.</p>
      <p><strong>2. Top economist Mohamed El-Erian warned of potential collateral damage from the First Republic-JPMorgan deal.</strong> The Wall Street giant agreed on Monday to acquire most of the battered firm's assets — but El-Erian sees four critical, unintended consequences that could materialize as a result.</p>
      <p><strong>3. UBS analysts just named 40 cheap stocks that they expect to outperform in a recession.</strong> Weaker economic growth will help defensives dominate over cyclicals, and this collection of relatively inexpensive picks look poised to fare well in a weaker landscape.</p>
      <p><strong>4. Russian gold is now flooding the United Arab Emirates, Hong Kong, and Turkey.</strong> Sellers have had to find new buyers for the key metal now in light of wartime sanctions from the West. Big gold buyers like HSBC and JPMorgan have shunned business with Moscow — leaving billions of dollars worth of gold in need of new landing spots.</p>
      <p><strong>5. Nobel economist Paul Krugman doesn't think dollar dominance is worth much to the US.</strong> The notion is overrated, in his view, and he thinks it's best just to ignore all the dollar doomers out there: "Or better yet, consider what their hyping of a nonissue says about their own judgment."</p>
      <p><strong>6. Apple attracted $1 billion to its new high-yield savings account in four days.</strong> The iPhone marker debuted the account last month, with a yield of 4.15%. And the number of people that have signed up so far represents just a fraction of total iPhone users.</p>
      <p><strong>7. The nation's leading market-watchers and policy pros are concerned about a credit crunch and a spiraling commercial real estate sector.</strong> It's an uncertain time for the US economy, with growth slowing down and inflation staying stubbornly high. Here's what three experts expect to come down the pipeline.</p>
      <p><strong>8. Yankees legend A-Rod learned two key rules about investing from Warren Buffett.</strong> Alex Rodriguez told Bloomberg he became business partners with the billionaire after the Berkshire chief insured his multi-million dollar playing contract. The pair made a habit of meeting up in Omaha — and Rodriguez still carries the lessons with him today.</p>
      <p><strong>9. These 20 defensive stocks could help your portfolio weather a downturn.</strong> Morgan Stanley's top strategists cautioned that a strong earnings season could convey a false sense of security. But they see this batch of names as one potential corner of opportunity.</p>
      <p><strong>10. BlackRock bond chief Rick Rieder manages $2.7 trillion.</strong> He told us how he's positioning cash for a fading economy and the portfolio strategy he's employing to avoid losses right now.</p>
    </div>
  },
  {
    id: 512,
    title: 'Bitcoin rises, Solana leads losses across top 10 cryptos, U.S. dollar slides amid banking sector concerns',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Solana', 'US'],
    description: <div className="blog-desc">
      <p>Bitcoin rose while Ether fell in Thursday afternoon trade in Asia, with declines across most of the other top 10 non-stablecoin cryptocurrencies by market capitalization. Solana’s SOL token was the biggest loser of the day. Asian equities were mixed, while U.S. stock futures strengthened in anticipation of April’s nonfarm payroll report. The U.S. dollar index fell, as woes at regional U.S. lenders PacWest and Western Alliance fueled market concerns of a banking crisis.</p>
    </div>,
    authorName: 'Zoltan Vardai',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 5, 2023 - 2:12 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-491.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
    	<p>Bitcoin rose while Ether fell in Thursday afternoon trade in Asia, with declines across most of the other top 10 non-stablecoin cryptocurrencies by market capitalization. Solana’s SOL token was the biggest loser of the day. Asian equities were mixed, while U.S. stock futures strengthened in anticipation of April’s nonfarm payroll report. The U.S. dollar index fell, as woes at regional U.S. lenders PacWest and Western Alliance fueled market concerns of a banking crisis.</p>
    	<p><strong>Crypto</strong></p>	
    	<p>Bitcoin, the world’s largest cryptocurrency, rose 0.11% to US$29,124 in the 24 hours to 4:30 p.m. in Hong Kong. Ether fell 0.19% to US$1,899.</p>	
    	<p>“Bitcoin needs to close above US$32,000 to confirm an upward trend,” Tom Wan, a research analyst at 21.co, the parent company of 21Shares, an issuer of crypto exchange-traded products, wrote in an email to Forkast.</p>
      <p>Solana’s SOL token fell 0.98% to US$21.93 as the day’s biggest loser, followed by Dogecoin that slipped 0.77% to US$0.0787.</p>
      <p>The global crypto market capitalization decreased by 0.07% to US$1.19 trillion, while total crypto market volume fell 20.02% to US$34.25 billion. </p>
      <p><strong>NFT</strong></p>
      <p>In the non-fungible token (NFT) market, the Forkast 500 NFT index fell 0.4% to 3,645.72 points in the 24 hours to 4:30 p.m. in Hong Kong and declined 1.85% during the week.</p>
      <p>24-hour NFT sales on the Ethereum network declined for a third consecutive day, falling 23.64% to US$13.9 million, despite a 47.40% surge in 24-hour sales for Azuki, the leading NFT collection on Ethereum. 24-hour sales for the Bored Ape Yacht Club declined 58.7% to US$872,793, according to CryptoSlam data.</p>
      <p><strong>Equities</strong></p>
      <p>Asian equities were mixed on Friday. The Shenzhen Component Index fell 0.82%, the Shanghai Composite lost 0.48%, while Japan’s Nikkei 225 inched up 0.12% and Hong Kong’s Hang Seng Index increased 0.5%.</p>
      <p>Investors continued to assess the global monetary policy outlook after the U.S. Federal Reserve and the European Central Bank raised interest rates by 25 basis points this week.</p>
      <p>U.S. stock futures strengthened as of 4:30 p.m. in Hong Kong. The tech-heavy Nasdaq-100 futures rose 0.43%, the Dow Jones Industrial Average futures strengthened 0.25% and the S&P 500 futures index gained 0.40%.</p>
      <p>U.S. nonfarm payroll data will be released later Friday. The world’s largest economy is expected to have added 180,000 jobs in April, which would be the smallest gain since December 2020, but well above the 70,000-100,000 monthly job gain needed to sustain the growth of the working-age demographic.</p>
      <p>The U.S. dollar fell 0.12% to 101.2 points, weighed down by recession fears and banking woes after shares of Los Angeles-based PacWest fell over 50% yesterday following a Bloomberg report that the lender was exploring a sale or other capital-raising moves in the wake of the recent collapse of other banks.</p>
      <p>Shares of Western Alliance bank holding company also plummeted over 60% on Thursday after a report alleged that it is exploring a potential sale. The company’s shares started recovering today after Western Alliance denied the report.</p>
    </div>
  },
  {
    id: 509,
    title: 'Cryptocurrency Prices And News: Bitcoin Rises Near $29,000. Coinbase Beats Earnings.',
    category: 'Crypto',
    subCategory: ['Cryptocurrency', 'BTC', 'Prices'],
    description: <div className="blog-desc">
      <p>Cryptocurrency News: Bitcoin and ethereum led cryptocurrency prices higher Thursday as bank worries deepened.  Meanwhile, ARK Invest CEO Cathie Wood loaded up on COIN stock this week ahead Coinbase's earnings beat late Thursday.</p>
    </div>,
    authorName: 'HARRISON MILLER',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 5, 2023 - 12:50 AM', 
    cover: 'https://ncx.cx/images/ncx-blog-485.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
	    <p>Cryptocurrency News: Bitcoin and ethereum led cryptocurrency prices higher Thursday as bank worries deepened.  Meanwhile, ARK Invest CEO Cathie Wood loaded up on COIN stock this week ahead Coinbase's earnings beat late Thursday.</p>
    	<h3>Cryptocurrency Price Action</h3>
      <p>Bitcoin traded around $28,900 Thursday afternoon, after peaking at $29,366 in the morning. BTC surged to a week-high of $30,008 last Wednesday on renewed banking fears but reversed below $28,000 by the afternoon. Bitcoin spiked above $30,000 for the first time in 10 months on April 10 and vaulted to $31,019 late April 14 to mark its highest level since last June. BTC rallied 20% in March to record its best month since January when it gained 38.7% The world's largest cryptocurrency bolted roughly 74% so far this year.</p>	  
    	<p>Ethereum hovered below $1,880 Thursday and rose as high as $1,917 in the morning. ETH traded around $1,900 over the weekend. Ethereum peaked at $2,139 on April 16 -  its highest level since last May and shot above $2,000 for the first time in nearly a year on April 13 following its Shanghai upgrade to a proof-of-stake network. The No. 2 crypto rose 10.6% in March and soared nearly 57% year-to-date.</p>	
    	<h3>Most Popular Cryptocurrencies</h3>
      <p>Digital asset investments are extremely volatile. While cryptocurrency's fundamentals and technical indicators may differ, investors should focus on the same key objectives. First, stay protected by learning when it's time to sell, cut losses or capture profits. Second, prepare to profit if the cryptocurrency starts to rebound.</p>
      <p>Despite their original promise, cryptocurrencies haven't acted as hedges against inflation. Instead, they've trended with the broader indexes. Read The Big Picture and Market Pulse to track daily market trends.</p>
      <p>View IBD's Best Cryptocurrencies And Crypto Stocks To Buy And Watch page to help navigate the world of digital asset investments.</p>
    </div>
  },
  {
    id: 510,
    title: 'Bitcoins huge rally is masking a drop in liquidity as smaller trades move the market',
    category: 'Crypto',
    subCategory: ['BTC', 'Market', 'liquidity'],
    description: <div className="blog-desc">
      <p>Bitcoin's huge price rally this year is masking a decline in market liquidity.</p>
    </div>,
    authorName: 'Filip De Mott,Zinya Salfiti',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 4, 2023 - 7:30 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-486.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
      <ul>
        <li>Bitcoin's huge price rally this year is masking a decline in market liquidity.</li>
        <li>That means smaller bitcoin trades can have a bigger impact on the world's top cryptocurrency.</li>
        <li>A trade of 462 bitcoins can move the price by 1% versus more than 1,400 bitcoins in January, according to CCData.</li>
      </ul>
    	<p>Bitcoin has surged 70% so far this year, but market liquidity for the world's top cryptocurrency is drying up.</p>
    	<p>That means smaller trades can increasingly move bitcoin prices. For example, a purchase of 462 bitcoins ($13 million) at the end of last month was able to move the price by 1% versus more than 1,400 bitcoins ($23 million) in January, according to CCData, cited by the Financial Times.</p>	
    	<p>That's the lowest point of market depth for bitcoin trades via the stablecoin tether since May 2022, when the crypto market was reeling from price crashes and the failure of key industry players.</p>
      <p>Overall liquidity in the market has been declining since the start of the year, CCData research analyst Jacob Joseph told Insider over email.</p>
      <p>"It's worth noting that the decline in Binance's market share in April may have also contributed to the decline in liquidity for the above BTC-USDT pair," he added.</p>	
      <p>Binance's woes may be related to a crackdown by regulators on the crypto exchange firm. Meanwhile, last year's collapse of FTX and sister firm Alameda created a gap in liquidity that other crypto firms have been unable to fill, traders told the FT.</p>
      <p>And while bitcoin's price has been on the up, especially as banking turbulence caused renewed confidence in the currency, it has stagnated lately.</p>
    	<p>In recent weeks, bitcoin has languished in the $28,000-$29,000 range. That has coincided with outflows of $72 million in digital asset investments over the last two weeks after six consecutive weeks of inflows, according to CoinShares data cited by the FT.</p>
    </div>
  },
  {
    id: 508,
    title: 'Assessing Bitcoin’s [BTC] state as Ordinals hit a new record',
    category: 'Crypto',
    subCategory: ['BTC', 'Record', 'Ordinals'],
    description: <div className="blog-desc">
      <p>Ordinals hit a new all-time high in terms of both daily inscriptions and fees paid. During the episode, BTC’s hashrate also increased, as did miners’ revenue </p>
    </div>,
    authorName: 'Dipayan Mitra',
    authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 3, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-481.jpg',
    content: <div className="blog-content">
    	<h3>in this article</h3>
    	<p>Ordinals hit a new all-time high in terms of both daily inscriptions and fees paid. During the episode, BTC’s hashrate also increased, as did miners’ revenue </p>
    	<ul>
        <li>Ordinals’ block size decreased as the text type of ordinals grabbed the largest share of total inscriptions.</li>
        <li>BTC’s price might witness a correction soon, as suggested by a few metrics.</li>
      </ul>
      <p>Bitcoin [BTC] Ordinals again made it to the limelight by registering massive growth in terms of the number of inscriptions. Daily inscriptions reached an all-time high of more than 300,000 on 1 May, pushing the total number of inscriptions to over 3 million. </p>	
    	<h3>key stats look encouraging</h3>
      <p>A look at the share of different types of Ordinals inscribed suggested that the majority of them were text type, which accounted for 79%. As per Dune’s data, text type Ordinals were followed by image type and others.</p>	
    	<p>Additionally, the increase in number of inscriptions was also accompanied by a massive surge in Ordinals fees paid, which also reached a new ATH on 2 May 2023.</p>
      <p>Tom Wan, a research analyst, pointed out an interesting development. Despite the massive increase in the number of inscriptions, the average block size decreased.</p>
      <p>The reason behind this decline was the dominance of text type Ordinals. As text type Ordinals’ size is less than that of image-type Ordinals, the average block size declined from its ATH of 2.5 MB to 1.57 MB.</p>
      <h3>Miners enjoyed the episode</h3>
      <p>Ordinals’ latest achievement also brought good news for miners. While Ordinals hit a new ATH, a hike in BTC’s hashrate was also seen, suggesting an influx of new miners in the network.</p>
      <p>Moreover, thanks to the increase in fees of Ordinals, miners also generated more revenue. As evident from Glassnode’s chart, miners’ total revenue in BTC registered a spike on 1 May, 2023.</p>
      <h3>Bitcoin investors should consider this</h3>
      <p>Though things looked good for the miners, the same can’t be said for investors. A look at BTC’s hash ribbon displayed the possibility of the 60-day moving average (MA) flipping the 30-day MA.</p>
      <p>Such a flip suggests that the market is not appropriate for more accumulation. Therefore, if the flip happens, BTC might be subjected to a price correction.</p>
      <h3>The trouble is for real</h3>
      <p>CryptoQuant’s data revealed that at the time of writing, Bitcoin’s exchange reserve was increasing, suggesting higher selling pressure. The increased sell pressure was also proven by BTC’s red aSORP, which indicated that more investors were selling at a profit.</p>
      <p>In addition to that, BTC’s taker buy/sell ratio pointed out that selling sentiment was dominant in the derivatives market, which was also a negative signal. According to CoinMarketCap, Bitcoin’s price increased by nearly 2% in the last 24 hours and at press time, it was trading at $28,481.10 with a market capitalization of over $551 billion.</p>
    </div>
  },
  {
    id: 507,
    title: 'BTC traders could be in soup as Bitcoin oscillates between red and green',
    category: 'Crypto',
    subCategory: ['BTC', 'Red', 'Green'],
    description: <div className="blog-desc">
      <p>As of 1 May, BTC’s hashrate reached a new all-time high of 473.87 EH/s. The spike in hashrate could be attributed to the rising Bitcoin ordinal inscriptions. However, despite this, BTC traded in the red at the time of writing.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 3, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-480.jpg',
    content: <div className="blog-content">
    	<h3>In this article</h3>
    	<p>As of 1 May, BTC’s hashrate reached a new all-time high of 473.87 EH/s. The spike in hashrate could be attributed to the rising Bitcoin ordinal inscriptions. However, despite this, BTC traded in the red at the time of writing.</p>
    	<ul>
        <li>Transactions on the Bitcoin network soar to new highs attracting more mining revenue.</li>
        <li>Miner revenue also soared to a six-month high as of 1 May.</li>
      </ul>
      <p>As the world waits for the crypto market to regain some price excitement, something interesting is happening on the Bitcoin [BTC] blockchain. Its hash rate experienced a noteworthy surge that may have something to do with the Bitcoin ordinals.</p>	
    	<p>At press time, Bitcoin’s hash rate stood at 473.87 EH/s and witnessed a rise in the last 24 hours. This observation was significant because it wasn’t only the highest weekly hash rate level, but also the highest ever. The main significance of this observation was that it highlighted a surge in miner participation in the market.</p>	
    	<p>The hash rate spike was likely due to the rising Bitcoin ordinals inscriptions. They have been a key driving factor for organic transactions on the network other than BTC trading activities. This reflected a recent surge in Bitcoin daily transactions which also reached a new ATH on 1 May.</p>
      <p>More daily transactions translate to higher miner revenue. This explains why Bitcoin’s hash rate increased as miners added more hash rate to take advantage of the revenue-generating opportunities. Glassnode’s miner revenue metric confirmed the same.</p>
      <h3>Assessing the potential impact on Bitcoin’s price performance</h3>
      <p>Miner revenue soared to a new six-month high on 1 May. If you are like most, you are probably wondering whether the Bitcoin ordinals will have an impact on BTC’s price action. First, the ordinal inscriptions do not have a direct impact on Bitcoin demand even though transactions are on the rise. However, they would likely have an impact if Bitcoin miner reserves were up.</p>
      <p>A closer look at Bitcoin’s on-chain data confirmed that the transaction count has been on the rise for the last few days. However, the situation is different for miner reserves, which, at press time were down to their lowest level in the last three months.</p>
      <p>Dwindling miner reserves are usually not considered a healthy sign for the market. This is because it shows a lack of incentives for miners to hold, which aligns with the prevailing market sentiment. The declining miner reserves explain the disconnect in the surging demand for Bitcoin ordinals and Bitcoin’s price performance.</p>
      <p>BTC crashed by over 3% on 1 May, the same day that transaction count and hash rate soared to new ATHs. Bitcoin exchanged hands at $28,592 at press time after securing some bullish volumes. The price action did not share the same level of enthusiasm as the hash rate or transactions on the network.</p>
    </div>
  },
  {
    id: 506,
    title: 'Bitcoin [BTC] staring at an uncertain future in the U.S? New data affirms…',
    category: 'Crypto',
    subCategory: ['BTC', 'Bitcoin', 'US'],
    description: <div className="blog-desc">
      <p>According to data provided by blockchain analytics firm CryptoQuant, BTC reserves at U.S.-based exchanges declined sharply since the beginning of 2023.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 3, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-474.jpg',
    content: <div className="blog-content">
    	<h3>in this article</h3>
	    <p>According to data provided by blockchain analytics firm CryptoQuant, BTC reserves at U.S.-based exchanges declined sharply since the beginning of 2023.</p>
	    <ul>
        <li>Despite a decline across all exchanges, the fall in reserves appears to be more significant on U.S. exchanges.</li>
        <li>With macro uncertainty looming, investors shied away from taking bullish bets on BTC.</li>
      </ul>
      <p>Bitcoin [BTC] extended its decline for the second consecutive day as its price dipped below the important $28,000 level on 1 May. However, the king coin reversed the losses to an extent by reaching $28,006 at press time, data from CoinMarketCap revealed.</p>	
    	<p>While this reflected a drop of over 2% in the last 24 hours, on a weekly basis BTC surged 2.40%. With the collapse of another big banking institution, First Republic Bank, it remains to be seen if BTC can go unscathed like it did during the banking turmoil of March.</p>	
      <h3>Trust erodes in U.S. exchanges</h3>    	
      <p>While sentiment around Bitcoin has been bullish since the start of 2023, it faced hurdles in one of the biggest financial and crypto markets of the world, U.S. According to data provided by blockchain analytics firm CryptoQuant, BTC reserves at U.S.-based exchanges declined sharply since the beginning of 2023.</p>
      <p>The analysis highlighted that despite a long-term decline across all exchanges, the fall in reserves appears to be more significant on American exchanges. A strong reason behind the drop could be increased scrutiny by U.S. regulators on the way crypto assets are handled on centralized exchanges. Earlier this year, Kraken, the second-largest exchange in the U.S., had to cease its staking services after the SEC charged Kraken with a $30 million penalty over irregularities in the staking program.</p>
      <p>As a result, investors started to prefer self-custody and offshore exchanges to move their BTC holdings.</p>
      <p>Moreover, data from DeFiLlama highlighted the stark contrast between BTC holdings on U.S. based trading platforms and offshore platforms. The share of BTC tokens on Binance was more than 25% of the total, while on Binance.US, the entity which operates solely for U.S. customers, the share was drastically low.</p>
      <p>Similarly, BTC occupied a 31% share of all assets on the Seychelles-based exchange, OKX[OKB] while reserves of Bitfinex, based in Hong Kong, consisted of nearly 60% of BTC tokens.</p>
      <h3>BTC: Bullish sentiment wanes?</h3>
      <p>With macro uncertainty looming, investors shied away from taking bullish bets on BTC. Data from Coinglass pointed towards a sharp increase in the number of short positions vis-à-vis long positions.</p>
    </div>
  },
  {
    id: 504,
    title: 'ADA bears could have an extended stay amid Cardano’s efforts to lure the bulls',
    category: 'Crypto',
    subCategory: ['ADA', 'Bears', 'cardano'],
    description: <div className="blog-desc">
      <p>ADA road to recovery may be filled with more bears than expected. At the time of writing, ADA didn’t see any buying pressure from traders despite a satisfactory roundup presented by the Cardano community.</p>
    </div>,
    authorName: 'Aashna Dunwani',
    authorurl: 'https://ambcrypto.com/author/aashna-dunwani/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'May 2, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-472.jpg',
    content: <div className="blog-content">
    	<h3>In this article</h3>
    	<p>ADA road to recovery may be filled with more bears than expected. At the time of writing, ADA didn’t see any buying pressure from traders despite a satisfactory roundup presented by the Cardano community.</p>
    	<ul>
        <li>Cardano’s daily digest shared a number of developments that could act in favor of ADA’s growth.</li>
        <li>However, at the time of writing, ADA was exchanging hands at a loss.</li>
      </ul>
      <p>Cardano’s [ADA] efforts with the Voltaire era helped the altcoin see some recovery on its Total Value Locked (TVL) and Long/Short Ratio front. However, as we step into a new month, the ecosystem had something more to offer to the community.</p>	
    	<p>The Twitter handle, Cardano Feed, published Cardano’s daily digest on 1 May and stated some important updates about the ecosystem. Adding to the Voltaire development, the roadmap presented other important updates related to the ecosystem.</p>	
    	<h3>Some high expectations doing the rounds</h3>
      <p>The Twitter thread highlighted Cardano’s collaboration with a next-gen Web3 gaming platform DEADPXLZ to develop its initial game on the Paima Engine. This would be integrated with Cardano.</p>
      <p>Additionally, the roundup also stated that the SummonsPlatform was launched on the Cardano mainnet. This would enable users to create and manage DAOs in a better way.</p>
      <p>With so much to offer, Cardano could be on a winning spree. However, was that the case with ADA’s price and its metrics? Let’s find out.</p>
      <h3>All about the bears</h3>
      <p>At the time of writing, Cardano’s native token ADA didn’t exactly step up its game. This was because the token was exchanging hands at $0.3842 and stood in the red. The Relative Strength Index (RSI) also stood at 44.28 and didn’t show any signs of making a recovery in the days to come.</p>
      <p>ADA’s Moving Average Convergence Divergence (MACD) indicator too witnessed a bearish crossover. Although, at press time, the signal and MACD line stayed above the zero line, it was only a matter of time before the two lines make their way past to the other side of zero.</p>
      <p>Data from the intelligence platform Santiment also seemed to be in support of the bears at the time of writing. Although ADA’s development activity did witness an uptrend over the last few days, at press time, it slipped. ADA’s development activity stood at 83.83, which was a drop from the chain’s development activity over the last few days.</p>
      <p>Furthermore, a look at the number of holders also didn’t make a significant recovery at the time of writing and moved in a sideways direction. However, ADA did manage to maintain its social dominance despite a bearish outlook on most fronts.</p>
      <p>Furthermore, as per data from CoinMarketCap, ADA was trading 0.18% lower in the last 24 hours and 3.46% lower over the last seven days. Thus, ADA traders would have to tread slowly at least over the next few days.</p>
      <p>However, despite ADA being controlled by the bears, the Cardano Foundation shared some good news with the community. As of 1 May, Cardano’s on-chain stats managed to show some growth.</p>
      <p>This could, thus, change the state of ADA but only if investors managed to garner some bullish momentum.</p>
    </div>
  },
  {
    id: 505,
    title: 'Assessing Shiba Inu’s [SHIB] status after the whales’ latest buying spree',
    category: 'Crypto',
    subCategory: ['SHIB', 'Shiba', 'Whales'],
    description: <div className="blog-desc">
      <p>Over the last 10 days, most large addresses stepped up their game in accumulating SHIB. As of this writing, 615.19 trillion SHIBs were sitting in whales’ wallets.</p>
    </div>,
    authorName: 'Suzuki Shillsalot',
    authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'Apr 30, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-473.jpg',
    content: <div className="blog-content">
    	<h3>In this article</h3>
    	<p>Over the last 10 days, most large addresses stepped up their game in accumulating SHIB. As of this writing, 615.19 trillion SHIBs were sitting in whales’ wallets.</p>
    	<ul>
        <li>Technical indicators sounded a bearish alarm for SHIB at press time.</li>
        <li>SHIB’s burn rate surged 36% in the last 24 hours but dropped 93% on a weekly basis.</li>
      </ul>
      <p>In what was a clear indication of growing demand for Shiba Inu [SHIB], a major whale snapped up more than 168 billion tokens in the last two days of trading, as per information from Etherscan. With the press time price of SHIB, the transactions were worth more than $1.72 million.</p>	
    	<p>As a result of the buying spree, SHIB became the most traded token among top Ethereum [ETH] whales over the last 24 hours, as per crypto whale tracking website Whalestats.</p>	
    	<h3>SHIB accumulation on the rise</h3>
      <p>The data from Etherscan revealed that the whale in question started with modest quantities, but quickly escalated to amass a whopping 108 billion SHIBs in a transaction executed a day and 14 hours before press time.</p>
      <p>However, it was interesting to note that the whale offloaded more than 49 billion tokens, indicating that an intense to-and-fro movement of SHIB was happening in the market.</p>
      <p>A staggering 5.15 trillion SHIB, worth more than $52 million, was held by the whale at the time of writing.</p>
      <p>Over the last 10 days, most large addresses stepped up their game in accumulating SHIB. As of this writing, 615.19 trillion SHIBs were sitting pretty in whales’ wallets, as per Santiment data.</p>
      <p>As large addresses started to hodl SHIB tokens, supply on exchanges dipped. Over the last 10 days, SHIB’s balance on centralized exchanges dropped from over 90 trillion to 89.2 trillion at press time.</p>
      <h3>The burn is real</h3>
      <p>Data from Shibburn revealed that SHIB’s burn rate surged by 36.97% in the last 24 hours, with more than 3.37 million tokens removed out of circulation.</p>
      <p>However, on a weekly basis, there was a significant decline of 93% from the previous week. As of this writing, SHIB had a circulating supply of 589.54 trillion, according to CoinMarketCap.</p>
      <p>SHIB exchanged hands at $0.00001029 at press time, up 0.33% in the last 24-hour period. The token was yet to embark on an upward momentum since the 19 April decline. The price kept moving sideways over the last few days of trading.</p>
      <p>The Relative Strength Index (RSI) continued to move below the neutral level, implying that bearish sentiments were strong. The Moving Average Convergence Divergence (MACD) was below the signal line, reinforcing the strong selling pressure narrative.</p>
    </div>
  },
  {
    id: 503,
    title: 'Bitcoin could jump nearly 70% if the US defaults on its debt, Standard Chartered analyst says',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'Jump', 'US'],
    description: <div className="blog-desc">
      <p>Bitcoin could climb by $20,000 if a US default happens, Standard Chartered's Geoff Kendrick said.</p>
    </div>,
    authorName: 'Filip De Mott',
    authorurl: '',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'Apr 29, 2023 - 4:51 PM', 
    cover: 'https://ncx.cx/images/ncx-blog-468.jpg',
    content: <div className="blog-content">
    	<h3>In this article:</h3>
      <ul>
        <li>Bitcoin could climb by $20,000 if a US default happens, Standard Chartered's Geoff Kendrick said.</li>
        <li>Not every crypto would act similarly, with some behaving more like equities, he told Insider.</li>
        <li>"So actually, the optimal trade would probably be long bitcoin, short ethereum."</li>
      </ul>
    	<p>With the looming debt ceiling crisis showing no signs of a resolution, both bond and equity markets have become jittery.</p>
    	<p>But while some investors are fearful of a historic default, one asset could potentially rise through such a situation: Bitcoin.</p>	
    	<p>Describing a US default as a "low-probability, high-impact event," Geoff Kendrick, head of FX research at Standard Chartered, said it may cause the bitcoin to jump by about $20,000, representing an increase of 68% from current levels.</p>	
    	<p>He told Insider that's because the top cryptocurrency by market cap has a reputation for performing well in periods of stress and is often seen as a safe haven, especially as it is a decentralized asset.</p>
      <p>But Kendrick doesn't think bitcoin would rally in a straight line in the event of a US default, saying "it probably comes a bit lower on day one or day two or week." In that case, bitcoin could dip by $5,000 initially, then jump by $25,000, he estimated.</p>
      <p>And not every cryptocurrency would follow bitcoin's behavior, he added, with others like ethereum trading more like stocks, which would likely fall in a default.</p>
      <p>"So actually, the optimal trade would probably be long bitcoin, short ethereum. That sort of mix would probably be a good expression of this," Kendrick said.</p>
      <p>If Congress fails to lift the $31.4 trillion federal debt limit, a default could come sometime in the summer — an event that could cause seismic ruptures global markets. On Tuesday, Treasury Secretary Janet Yellen warned a default would be catastrophic for the US economy, sparking mass unemployment, payment failures, and higher rates "into perpetuity."</p>
      <p>With House Republicans and the White House still far from a deal to raise the debt ceiling, yields on three-month Treasurys recently jumped to a 22-year high, as they would mature around a potential default date.</p>
      <p>Meanwhile, bitcoin is still recovering from a massive sell-off that began in late 2021 and continued through much of 2022 as the Federal Reserve embarked on an aggressive tightening cycle.</p>
      <p>But bitcoin has rallied nearly 80% so far in 2023 and recently neared $30,000 as First Republic Bank's woes reignited fears over the financial sector.</p>
      <p>For his part, Kendrick is already bullish on bitcoin, saying in a note on Monday that it could surge to $100,000 by the end of 2024 due to bank turmoil, bitcoin halving, and the expected end of Fed rate hikes, among other things, though it didn't mention the risk of a US default.</p>
      <p>"While sources of uncertainty remain, we think the pathway to the USD 100,000 level is becoming clearer," he wrote.</p>
    </div>
  },
  {
    id: 501,
    title: 'ETH short-term calls explode amid bullish relief across the market. Assessing…',
    category: 'Crypto',
    subCategory: ['ETH', 'market', 'bitcoin'],
    description: <div className="blog-desc">
      <p>ETH and most of the crypto market gained bullish momentum following reports of that another bank was at risk of collapse. More so, the price surge was backed by a wave of short-term calls.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'Apr 27, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-460.jpg',
    content: <div className="blog-content">
    	<p>ETH and most of the crypto market gained bullish momentum following reports of that another bank was at risk of collapse. More so, the price surge was backed by a wave of short-term calls.</p>
      <ul>
        <li>ETH bulls make a splash after whales ease up on their sell pressure.</li>
        <li>Short-term focus as the market eases off recent FUD.</li>
      </ul>
    	<p>Ethereum [ETH] bulls are back on top in a surprise move after interfering with a pullback that had the markets concerned about weak demand.</p>	
    	<p>However, current market data suggested that the ongoing upside might be limited. Understanding the reasons for the rally could help gauge the strength of the current bull run.</p>	
    	<p>ETH and most of the crypto market gained bullish momentum following reports that another bank was at risk of collapse. More so, the price surge was backed by a wave of short-term calls which may offer some insights into what to expect.</p>
      <p>The above findings suggested a high probability that the rally might be short-lived since the short-term calls were focused on short-term profits. But this does not necessarily guarantee that prices will not extend their upside in the short-to-mid-term.</p>
      <p><strong>Assessing the likelihood of a strong ETH rally</strong></p>
      <p>ETH’s upside will largely depend on whale activity. So what are ETH whales currently up to?</p>
      <p>Supply distribution showed a slowdown in sell pressure, especially from some whale categories. This included addresses holding between 10,000 and 100,000 ETH.</p>
      <p>This could be considered noteworthy because the aforementioned category controls most of the circulating supply hence it has the biggest impact on price movements.</p>
      <p>The potential upside may also be limited by some whales that have been taking profits in the last 24 hours. In addition, ETH exchange flows indicated that exchange inflows maintained a dominant position over exchange outflows.</p>
      <p>The surge in short-term calls reflected the spike in funding rates in the last 24 hours. This confirmed that there was strong demand for ETH in the derivatives segment.</p>
      <p>We also saw a drop in leverage in the last few days due to liquidations and market uncertainty. However, the demand for leverage registered a small bounce back in the last 24 hours indicating a return of confidence.</p>
      <p><strong>A quick look at the price action…</strong></p>
      <p>ETH exchanged hands at $1,953 at press time, which represented an 8.29% upside in the last two days. The bullish wave facilitated a healthy bounce after a brief interaction with the 50-day moving average.</p>
      <p>ETH’s MFI maintained a downward trend in the last two days despite the sharp bounce back in the last two days. This lends credence to the expectations of a limited upside.</p>
      <p>However, investors should also note that the unexpected may also happen just as has been the case with the sudden pivot. ETH and other top cryptos may continue rallying higher if another banking contagion ensues.</p>
    </div>
  },
  {
    id: 500,
    title: 'Bitcoin leveraged longs liquidated after brief mid-week recovery above $30,000',
    category: 'Crypto',
    subCategory: ['Bitcoin', 'recovery', '$30,000'],
    description: <div className="blog-desc">
      <p>Bitcoin’s long liquidations on 26 April soared to their highest level so far in April. The liquidations also triggered a sharp drop in the estimated leverage ratio as leverage traders quickly shifted to the side of caution.</p>
    </div>,
    authorName: 'Michael Nderitu',
    authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
    authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
    authorContent: '',
    createdAt: 'Apr 27, 2023', 
    cover: 'https://ncx.cx/images/ncx-blog-459.jpg',
    content: <div className="blog-content">
    	<p>Bitcoin’s long liquidations on 26 April soared to their highest level so far in April. The liquidations also triggered a sharp drop in the estimated leverage ratio as leverage traders quickly shifted to the side of caution.</p>
    	<ul>
        <li>Bitcoin leverage traders felt the heat as unexpected crash triggers heavy liquidations.</li>
        <li>Bulls still in charge despite the liquidation shock.</li>
      </ul>
      <p>Bitcoin [BTC] traders were in for a rollercoaster of price performance during 26 April’s trading session. Bitcoin briefly managed to rally back above $30,000, leading to euphoria about its bullish prospects but another crash sent it below $28,000.</p>	
    	<p>To put things into perspective, Bitcoin’s market cap fell by roughly $49.13 billion from its highest point on that day. This officially makes 26 April the most volatile day in the last seven days. Note that, despite the sharp crash the market cap recovered slightly.</p>	
	    <p><strong>Leveraged long liquidations fueled the Bitcoin volatility</strong></p>
      <p>The $30,000 retest attracted an unusual amount of volatility. One major reason for this is that the price was previously on a bearish trend, followed by a brief period of uncertainty regarding market direction.</p>
      <p>However, the midweek outcome demonstrated a return of bullish volumes, hence the uptick and retest of the $30,000 range which previously demonstrated resistance.</p>
      <p>Many traders saw the recovery as a sign of demand and a possible push past the resistance range. As such, many traders in the derivatives segment were convinced that Bitcoin would offer a good rally, hence they embraced leverage. This explains why Bitcoin’s recent slap-down resulted in heavy liquidations.</p>
      <p>Bitcoin’s long liquidations on 26 April soared to their highest level so far in the month. The liquidations also triggered a sharp drop in the estimated leverage ratio as leverage traders quickly shifted to the side of caution.</p>
      <p>The Bitcoin long liquidations highlight some of the risks in the market. Some analysts believe that the recent rally was a setup for whales to secure exit liquidity.</p>
      <p>Bitcoin’s brief rally presented the perfect opportunity for a leverage shakedown. Traders were high on hopium given that the market previously struggled to find enough momentum.</p>
      <p>The news that another major bank was in trouble further supported the bullish expectations. Investors should also note that there might also be a bear trap ahead.</p>
      <p>Bitcoin has already recovered slightly in the last few hours to its $28,995 press time price. The timing is particularly noteworthy because most investors will be sitting on the sidelines after the mid-week events.</p>
      <p>There is still some uncertainty regarding BTC’s next move, especially as the market rolls into a new month.</p>
    </div>
  },
    {
        id: 440,
        name: 'blog-post-title',
        title: 'Bitcoin-Tether Pair Is Most Liquid on Binance Even as TUSD Pair Sees Higher Volume',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Tether', 'PAIR'],
        description: <div className="blog-desc">
          <p>Trading volume on Binance of bitcoin (BTC) denominated in ArchBlock's dollar-pegged stablecoin trueUSD (TUSD) has surged over the past four weeks after the crypto exchange introduced zero trading fees in the pair on March 23 while simultaneously reintroducing fees for all other pairs.</p>
        </div>,
        authorName: 'Omkar Godbole',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 25, 2023 - 4:05 PM', 
        cover: 'https://ncx.cx/images/ncx-blog-455.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Trading volume on Binance of bitcoin (BTC) denominated in ArchBlock's dollar-pegged stablecoin trueUSD (TUSD) has surged over the past four weeks after the crypto exchange introduced zero trading fees in the pair on March 23 while simultaneously reintroducing fees for all other pairs.</p>
            <p>Still, the trade denominated in tether (USDT), the largest dollar-pegged stablecoin by market value, remains the most liquid, allowing large orders to execute with relatively less impact on the cryptocurrency's price than the TUSD trade.</p>	
            <p>"Market depth data on Binance shows us that BTC-USDT is still king from a liquidity standpoint, with market makers evidently more comfortable with exposure to Tether over TUSD," Conor Ryder, a research analyst at Paris-based crypto data provider Kaiko, said in a market update last week.</p>	
            <p>Liquidity conditions are commonly assessed with the help of a metric called market depth – a collection of buy and sell offers within 1% or 2% of the mid-price or the average of the bid and the ask/offer prices.</p>
          <p>The more significant the depth, the more liquid an asset is said to be and the less the slippage. Slippage is the difference between the expected price at which a trade is placed and the actual price at which the transaction is executed. Slippage usually occurs when there is low market liquidity or high volatility.</p>
          <p>The chart shows liquidity in BTC/USDT was $30 million at the 1% depth last week or 200% greater than $10 million in BTC/TUSD. In other words, traders might be better off executing large orders in the BTC/USDT pairs than the BTC/TUSD pair.</p>
          <p>TUSD's market depth has improved sharply from practically zero to $10 million in a month, surpassing BUSD to become the second-most liquid pair on Binance.</p>
          <p>The BTC-TUSD pair accounted for 50% of the total bitcoin market volume on Binance last week.</p>
          <p>"For as long as zero-fee trading lasts on BTC-TUSD, TUSD needs to be considered a top stablecoin in crypto, whether people like it or not. Similar to how Binance favored BUSD, TUSD is the benefactor of increased volumes now, even if the reasons why Binance granted TUSD this gift are unclear," Ryder said.</p>
        </div>
      },
      {
        id: 422,
        title: 'CoinShares says weekly investment in Ethereum-linked products rebounds after hard fork',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Ethereum', 'investment'],
        description: <div className="blog-desc">
          <p>Ethereum-linked digital asset investment products saw inflows of around US$17 million in the week ending April 21, up from US$300,000 in the previous week, appearing to show investor confidence following the blockchain’s milestone upgrade on April 12, according to a report from European cryptocurrency investment firm CoinShares.</p>
        </div>,
        authorName: 'Timmy Shen',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 25, 2023 - 8:16 AM', 
        cover: 'https://ncx.cx/images/ncx-blog-437.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Ethereum-linked digital asset investment products saw inflows of around US$17 million in the week ending April 21, up from US$300,000 in the previous week, appearing to show investor confidence following the blockchain’s milestone upgrade on April 12, according to a report from European cryptocurrency investment firm CoinShares.</p>
            <h3>Fast facts</h3>	
        <ul>
          <li>However, total investments in digital asset products saw outflows of around US$30 million last week, ending a six-week run of inflows, the report said. Bitcoin had seen inflows of US$310 million in the prior four weeks but last week saw outflows of US$53 million.</li>
          <li>“The outflows began the prior Friday [April 14] when Bitcoin reached the very psychological level of US$30,000, suggesting the most recent sell-off was a result of profit-taking, particularly in the absence of any macro-economic triggers,” the CoinShares report says.</li>
          <li>Developers of the Ethereum blockchain on April 12 updated the network with new features including allowing the withdrawal of staked Ether. Such tokens were deposited to support the operations of the Ethereum blockchain, the world’s second-biggest, in return for a passive income, usually in the form of more tokens.</li>
          <li>As of 11 a.m. on Tuesday in Hong Kong, around 1.66 million staked Ether (US$3.04 billion) have been withdrawn since the April 12 upgrade, with another 1.07 million Ether deposited for staking, according to data from Token Unlocks. About 616,500 Ether are awaiting withdrawal.</li>
          <li>Ether dipped 1.76% in the past 24 hours to trade at US$1,834, and lost 11.93% in the past seven days, according to data from CoinMarketCap. Bitcoin fell 1.22% in the last 24 hours to change hands at US$27,399, logging a 7.06% weekly drop.</li>
        </ul>
        </div>
      },
      {
        id: 423,
        title: 'Bitcoin loses ground, Ether dips even as inflows rise; Litecoin edges up, with halving cited',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'litecoin', 'crypto'],
        description: <div className="blog-desc">
          <p>Bitcoin fell on Tuesday morning in Asia to trade below the US$27,500 support line, with the slide ascribed to more profit-taking after this year’s strong gains. Ether and most other top 10 non-stablecoin cryptocurrencies traded lower amid a broader correction in the crypto market. XRP led the losers, while Litecoin stood out as the only gainer in the top 10. U.S. equity futures edged lower in Asia ahead of a bunch of economic readings and first-quarter earning reports from major U.S. companies this week. Wall Street closed mixed on Monday.</p>
        </div>,
        authorName: 'Tom Zuo',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 25, 2023 - 7:16 AM', 
        cover: 'https://ncx.cx/images/ncx-blog-438.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Bitcoin fell on Tuesday morning in Asia to trade below the US$27,500 support line, with the slide ascribed to more profit-taking after this year’s strong gains. Ether and most other top 10 non-stablecoin cryptocurrencies traded lower amid a broader correction in the crypto market. XRP led the losers, while Litecoin stood out as the only gainer in the top 10. U.S. equity futures edged lower in Asia ahead of a bunch of economic readings and first-quarter earning reports from major U.S. companies this week. Wall Street closed mixed on Monday.</p> 
          <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin fell 1.22% to US$27,461 in the 24 hours to 9:00 a.m. in Hong Kong for a loss of 6.51% for the week, according to CoinMarketCap data. The world’s largest cryptocurrency retreated to US$27,070 at one stage on Tuesday morning, the lowest price since March 28.</li>
            <li>Ether dropped 1.88% to US$1,841, losing 11.25% for the week and wiping out its gains since April 12 from the Shanghai upgrade of the Ethereum blockchain.</li>
            <li>XRP led the losers, dropping 4.75% to US$0.4596 for a seven-day loss of 10.27%.</li>
            <li>Bitcoin’s slide over the past week is investors taking profit following the token’s surge so far this year, according to a Monday blog post by digital asset investment firm CoinShares, noting an outflow of US$30 million from digital asset investment products in the week ending April 21, interrupting a 6 week run of inflows.</li>
            <li>However, Ether saw an inflow of US$17 million in the past week, which suggests some confidence among Ether investors after the Shanghai upgrade.</li>
            <li>Despite the widespread profit-taking, the U.K.-based Standard Chartered Bank on Monday said Bitcoin could reach US$100,000 by the end of 2023 on the back of recent failures of traditional banks, a rebound in profits in crypto mining, and the potential end of the U.S. monetary tightening cycle, according to Reuters.</li>
            <li>Litecoin managed to log gains despite the overall downtrend, rising 0.76% to US$87.99. This followed a tweet from Litecoin noting the token’s third halving event is in 100 days, which will make the coin more scarce. Still, it has some ground to make up as it’s lost 11.09% for the past seven days.</li>
            <li>The total crypto market capitalization dipped 1.20% in the past 24 hours to US$1.16 trillion. The total trading volume over the last 24 hours rose 28.71% to US$38.23 billion.</li>
            <li>In the non-fungible token (NFT) market, the Forkast 500 NFT index dipped 0.57% to 3,755.63 in the 24 hours to 9:00 a.m. in Hong Kong, down 7.23% for the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day. It is managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</li>
            <li>The underperformance of the NFT market could be attributed to a surge in gas fees on the Ethereum blockchain, following the recent rise of memecoin PEPE. However, the memecoin, launched on April 14, fell 18.81% to US$0.0000002626 in the 24 hours to 7 p.m. on Monday in Hong Kong. The coin is still up over 392% on the weekly chart and has a market capitalization of around US$116 million, according to DEXTools.</li>
            <li>U.S. stock futures traded flat to lower as of 9:00 a.m. in Hong Kong. The Dow Jones Industrial Average futures inched 0.04% lower. The S&P 500 dipped 0.10% and the Nasdaq Composite Index edged down 0.09%. The three indexes closed mixed in regular Monday trading on Wall Street, with investors facing a week busy with inflation gauges and earnings from U.S. corporations.</li>
            <li>178 of the S&P 500 companies will release first-quarter earnings this week, including Alphabet, Amazon, Microsoft and Meta.</li>
            <li>On the inflation front, investors are also facing U.S. first-quarter gross domestic product (GDP) on Thursday and personal consumer expenditures on Friday, which will provide an insight into the broader U.S. economy and the Federal Reserve’s possible next move on interest rates. Analysts project annual growth of 2.5% in U.S. real GDP, down from 2.6% in the previous quarter.</li>
            <li>U.S. interest rates are currently between 4.75% to 5%, the highest since June 2006. Analysts at the CME Group now see a 16% chance the Fed will leave interest rates unchanged at its next meeting on May 3, while 84% predict a 25-basis-point increase, down from 89.1% on Monday.</li>
          </ul>
        </div>
      },
      {
        id: 424,
        title: 'Ethereum Shanghai Upgrade Brings Record Inflow of 572k ETH Staked in a Week',
        category: 'Crypto',
        subCategory: ['Ethereum', 'Shanghai', 'staked'],
        description: <div className="blog-desc">
          <p>Ethereum’s Shanghai upgrade brought a record-breaking weekly inflow of ether (ETH) deposits for staking last week, mainly driven by institutional staking service providers and investors reinvesting rewards after withdrawal, crypto analysts said.</p>
        </div>,
        authorName: 'Krisztian Sandor',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 25, 2023 - 3:50 AM', 
        cover: 'https://ncx.cx/images/ncx-blog-439.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Ethereum’s Shanghai upgrade brought a record-breaking weekly inflow of ether (ETH) deposits for staking last week, mainly driven by institutional staking service providers and investors reinvesting rewards after withdrawal, crypto analysts said.</p>
            <p>Investors deposited some 571,950 ETH tokens into staking contracts, worth more than $1 billion, according to a Dune Analytics data dashboard created by Tom Wan, an analyst of digital asset investment firm 21Shares.</p>	
            <p>This was the largest weekly token inflow in ether staking’s almost two-and-a-half-year history, per blockchain data provided by 21Shares.</p>	
            <p>The Ethereum blockchain completed its highly anticipated tech upgrade, often called Shanghai or Shapella, on April 12. The initiative enabled the withdrawal of tokens previously locked-up in staking contracts for the first time. It was the last key step after last year’s Merge to complete the network’s transition to validating transactions through staking from a mining-based system.</p>
          <p>ETH, the network’s native token and the second largest cryptocurrency by market capitalization after bitcoin (BTC), surged following the successful implementation of the upgrade, but relinquished all its gains last week in a broader crypto market sell-off, caused mostly by macroeconomic concerns about inflation and a looming recession.</p>
          <p><strong>Institutional ETH staking</strong></p>
          <p>Institutional staking services drove the deposit surge in ETH staking, Wan tweeted.</p>
          <p>Since the Shanghai upgrade went live, the top five institutional-grade staking service providers – Bitcoin Suisse, Figment, Kiln, Staked.us and Stakefish – staked a total of 235,330 ETH combined, worth some $450 million, according to 21Shares’ Dune dashboard.</p>
          <p>CoinDesk reported last week that the Shanghai upgrade boosted demand for ETH staking among institutional investors, including traditional finance firms.</p>
          <p>The increased interest also highlights that enabling withdrawals from Ethereum’s proof-of-stake chain has significantly reduced the liquidity risk associated with locking up tokens, Wan added in a separate tweet.</p>
          <p><strong>Restaking rewards</strong></p>
          <p>Another likely catalyst for the record inflow has been investors choosing to reinvest their previously earned and withdrawn rewards from staking, Anders Helseth, vice president of digital asset market research firm K33 Research, explained in an emailed note.</p>
          <p>Through the first eight days after the upgrade, investors withdrew some 900,000 ETH in staking rewards. Meanwhile, staking deposits totaled some 667,000 tokens, which was six times larger than the amount deposited during the last eight days before allowing withdrawals.</p>
          <p>The dynamic indicates that investors decided to restake a large part of the withdrawn rewards, Helseth wrote.</p>
          <p><strong>Reduced selling pressure</strong></p>
          <p>The Shanghai upgrade was a “net positive” in terms of total staking inflows, Noelle Achison, a market analyst and former head of research at Genesis Trading and CoinDesk (both subsidiaries of Digital Currency Group), wrote in a newsletter Monday.</p>
          <p>“So far, the rhythm of new deposits has exceeded the amount leaving the network, if rewards can be excluded,” she said.</p>
          <p>The reason for differentiating reward withdrawals from full exits comes from how Ethereum’s staking system works. Individual stakers or staking services have to lock up exactly 32 ETH to open a node and earn rewards for securing the network. Keeping the accrued rewards in the validator node does not improve the node’s return. Acheson explained that it’s logical for stakers to withdraw rewards, bundle the tokens and establish new nodes to increase potential returns.</p>
          <p>“It seems this is what is happening,” she said. “Overall net inflow has been positive, which suggests that a significant portion of these rewards are being restaked.”</p>
          <p>The trend of reinvesting rewards is also potentially a positive sign for ETH price as it reduces sell pressure, according to Acheson, “which is likely to end up being much less than many feared.“</p>
        </div>
      },
      {
        id: 502,
        title: 'Filecoin bulls show signs of weakness and April can see FIL drop below…',
        category: 'Crypto',
        subCategory: ['Filecoin', 'bulls', 'FTL'],
        description: <div className="blog-desc">
          <p>The retest of the bullish order block at $5.2 saw the bulls come up with only a meek response. The market structure of Filecoin also pointed toward a downtrend.</p>
        </div>,
        authorName: 'Akashnath S',
        authorurl: 'https://ambcrypto.com/author/akashnath-sumukar/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 24, 2023', 
        cover: 'https://ncx.cx/images/ncx-blog-461.jpg',
        content: <div className="blog-content">
          <p>The retest of the bullish order block at $5.2 saw the bulls come up with only a meek response. The market structure of Filecoin also pointed toward a downtrend.</p>
          <ul>
            <li>The daily market structure flipped bearish after the drop beneath $5.6.</li>
            <li>The swift losses left an imbalance that could be filled in the coming days.</li>
          </ul>
          <p>The rejection at $6.43 on 15 April was an alarming development for Filecoin. A recent report highlighted how the fall below $6 reinforced bearish pressure. At the time of writing, an imbalance on the charts could offer sellers an opportunity to short FIL upon a bounce.</p>	
          <p>The metrics were not encouraging for Filecoin either. There were many issues that the network faced, and the selling pressure across the market did not help FIL bulls. The $5 support would likely not withstand a bearish assault.</p>	
          <p><strong>The retest of a bullish order block noted underwhelming buying pressure</strong></p>
          <p>A bullish order block on the daily timeframe was highlighted in cyan. It saw a sharp break in the previous market structure and flipped it to bullish on 13 March. The buyers were unable to sustain that pressure in the weeks since.</p>
          <p>Instead, when the price ventured into the order block and as deep as the $5.2 support, the bulls were able to come up with only a meek response. They were unable to drive the rally past $6.4, and the market structure was bearish once more.</p>
          <p>The RSI was at 40 showing downward momentum, and the 21 and 55-period moving averages showed bearishness as well. Surprisingly the CMF revealed strong capital inflow to FIL markets over the past ten days. It remained uncertain if this can fuel a rally.</p>
          <p>A fair value gap was spotted in the $5.87 area. A retest of this region could see the downtrend continue. Therefore short sellers can look to re-enter after a bearish reaction from the $5.82-$6.02 area.</p>
          <p><strong>Liquidation data showed bulls severely hurt, short-term sentiment in bearish favor</strong></p>
          <p>According to Coinglass data, long positions worth $3.85 million were liquidated within a 4-hour timespan on 19 April. Since then, long positions continued to suffer much more than the sellers. Over the past 24 hours, the long/short ratio showed 51% of market participants were shorting the asset.</p>
          <p>The Open Interest data showed discouraged bulls as well. The OI had been falling almost as rapidly as the price. FIL saw a bounce from $5.1 to $5.35 in the past 36 hours.</p>
          <p>The OI continued to stay at the $120 million mark during this time, therefore we can conclude that bullish speculators remained sidelined.</p>
        </div>
      },
      {
        id: 441,
        title: 'Bitcoin could soar 268% to $100,000 as crypto winter is finally over, Standard Chartered says',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'BTC', 'Standard Chartered'],
        description: <div className="blog-desc">
          <p>The crypto winter has melted away, and bitcoin is set to flourish, a Standard Chartered note forecasted on Monday.</p>
        </div>,
        authorName: 'Filip De Mott',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 24, 2023 - 9:43 PM', 
        cover: 'https://ncx.cx/images/ncx-blog-456.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
          <ul>
            <li>Bitcoin could surge to $100,000 by the end of 2024, according to a Standard Chartered note.</li>
            <li>That's due to bank turmoil, bitcoin halving, and the expected end of Fed rate hikes, among other things.</li>
            <li>"While sources of uncertainty remain, we think the pathway to the USD 100,000 level is becoming clearer."</li>
          </ul>
            <p>The crypto winter has melted away, and bitcoin is set to flourish, a Standard Chartered note forecasted on Monday.</p>
            <p>The world's biggest digital currency by market cap could surge to $100,000 by the end of 2024, the bank said, representing a 268% increase from current levels.</p>	
            <p>"We see potential for Bitcoin to reach the USD 100,000 level by end-2024, as we believe the much-touted 'crypto winter' is finally over," wrote Standard Chartered's Geoff Kendrick.</p>	
            <p>One of the reasons for the bullish outlook is the turmoil caused by Silicon Valley Bank's collapse last month. As worries of a banking contagion spread, bitcoin re-established its use case as a "decentralized, trustless and scarce digital asset," according to the note.</p>
          <p>At the same time, key rivals to bitcoin lost ground, especially stablecoins, some of which lost their peg to the US dollar. In fact, some of the assets that backed their value were held in SVB.</p>
          <p>"Against this backdrop, Bitcoin has benefited from its status as a branded safe haven, a perceived relative store of value and a means of remittance," Kendrick added.</p>
          <p>Standard Chartered now sees bitcoin's share of the total crypto market cap rising to the 50%-60% range, up from 45% today and from 40% before the SVB collapse.</p>
          <p>In addition, the note said bitcoin's recent rebound back above $30,000 also represented a turnaround for crypto miners, who previously had seen mining margins get squeezed.</p>
          <p>Bitcoin has since dipped below $30,000, but Kendrick said if prices remain well above mining costs ($15,000), miners will likely hold onto what they mine instead of selling.</p>
          <p>"This creates price upside, in our view. In addition, with energy prices likely having peaked, the structural profitability backdrop for miners should improve, adding further upside," he wrote.</p>
          <p>Bitcoin is also set to trade better in the future as the Federal Reserve is nearing the end of its tightening cycle, Kendrick said, noting that its correlation with the Nasdaq suggests the crypto should trade better if risk-on assets improve broadly.</p>
          <p>Another tailwind is the coin's upcoming halving in 2024, which is when the reward given to miners is cut in half. This is to cap the supply of bitcoins, and has historically led to price increases.</p>
          <p>Bitcoin could also see upswings thanks to regulatory changes that promote investor access to the market — such as the creation of digital asset ETFs and stablecoin regulation. Already, some proposals that have been passed in Europe hold positive implications on crypto volatility.</p>
          <p>"While sources of uncertainty remain, we think the pathway to the USD 100,000 level is becoming clearer," Kendrick said.</p>
        </div>
      },
      {
        id: 401,
        title: 'Polkadot: Here’s where bulls can look to re-enter following resistance breach',
        category: 'Crypto',
        subCategory: ['Polkadot', 'Bulls', 'Crypto'],
        description: <div className="blog-desc">
          <p>An important bearish order block lay at the $6.6 region, but it has been broken over the past week. The market structure was bullish and the RSI remained above neutral 50 as well, supporting the idea of upward momentum.</p>
        </div>,
        authorName: 'Akashnath S',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 20, 2023', 
        cover: 'https://ncx.cx/images/ncx-blog-415.jpg',
        content: <div className="blog-content">
          <p>Disclaimer: The information presented does not constitute financial, investment, trading, or other types of advice and is solely the writer’s opinion</p>
            <p>An important bearish order block lay at the $6.6 region, but it has been broken over the past week. The market structure was bullish and the RSI remained above neutral 50 as well, supporting the idea of upward momentum.</p>
            <ul>
            <li>Polkadot has breached an important area of resistance.</li>
            <li>Although the reaction wasn’t strongly bullish yet, it was likely that DOT would rally toward $7.</li>
          </ul>
          <p>Polkadot has performed well on the price charts over the past two weeks. The token has noted gains of 15% measuring from 9 April to 18 April, and more green could follow.</p>	
            <p>Bitcoin was also quick to recover after a drop to the $29.2k level, which suggested buyers had some strength, but not enough to force another rally.</p>	
            <p>Polkadot bulls managed to drive prices above a bearish order block which acted as a stern resistance since 27 February. This move meant further gains could materialize in the coming days.</p>
          <h3>The bullish breaker could be retested once more</h3>
          <p>The bearish order block mentioned previously lay at the $6.6 region, but over the past week, it has been broken. A 4-hour trading session close above the order block flipped it to a bullish breaker, and so far, buyers have defended this area. It was highlighted in cyan, and it was likely to be tested once more.</p>
          <p>This was because the most recent lower timeframe pump went from $6.57 to $7 on 18 April. This surge in prices occurred quickly and left behind an imbalance on the charts. If this fair value gap, extending from $6.72 to $6.82, was retested, it would likely give a bullish reaction.</p>
          <p>The market structure was bullish and the RSI remained above neutral 50 as well, supporting the idea of upward momentum. The OBV has been flat over the past week, which was not an encouraging finding after the move above $6.6.</p>
          <p>Hence, buyers can look to enter a test of the FVG, targeting the next H4 bearish order block at the $7.22 area (red box).</p>
          <p>This idea will be invalidated if DOT closes a 4-hour session at $6.56. Such a session close will be discouraging for the bears, who have spent the past two weeks trying to force a move above the resistance. A fall beneath $6.6 will likely see $6 tested as support.</p>
          <h3>Futures markets showed long positions were discouraged in recent hours</h3>
          <p>Coinalyze data showed the funding rate was positive, and hadn’t changed much in the past ten days. This highlighted a bullish bias but the Open Interest told a slightly different story.</p>
          <p>In the past 24 hours, OI dropped by $7 million after a 2.5% slide in DOT prices. This suggested some bearish sentiment on the lower timeframes. If this continued, or if the OI rose alongside falling prices, it would highlight the heightened bearish sentiment.</p>
        </div>
      },
      {
        id: 397,
        title: 'Bitcoin falls below US$29,000, Ether slumps, U.S. equities stall on inflation concerns',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Ether', 'Inflation'],
        description: <div className="blog-desc">
          <p>Bitcoin slid below the US$29,000 mark for the first time in 10 days on Thursday morning in Asia. Ether also fell to lose hold of the US$2,000 price support in a sell off across all top 10 non-stablecoin tokens. Some commentators ascribed the declines to a repricing based on recent concerns about inflation and higher interest rates ahead. Litecoin led the losers. U.S. equity futures dipped after Wall Street closed flat to lower on Wednesday.</p>
        </div>,
        authorName: 'Tom Zuo',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 20, 2023 - 7:05 AM', 
        cover: 'https://ncx.cx/images/ncx-blog-410.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Bitcoin slid below the US$29,000 mark for the first time in 10 days on Thursday morning in Asia. Ether also fell to lose hold of the US$2,000 price support in a sell off across all top 10 non-stablecoin tokens. Some commentators ascribed the declines to a repricing based on recent concerns about inflation and higher interest rates ahead. Litecoin led the losers. U.S. equity futures dipped after Wall Street closed flat to lower on Wednesday.</p>
            <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin fell 4.59% to US$28,943 in the 24 hours to 09:00 a.m. in Hong Kong for a weekly loss of 3.59%, according to CoinMarketCap data. The world’s largest cryptocurrency lost about US$1,000 in an hour starting from 4:00 p.m. Hong Kong on Wednesday.</li>
            <li>Ether slumped 7.20% to US$1,947, but remains 1.93% higher for the past seven days. The token traded below the US$2,000 mark for the first time since April 13, when the Ethereum blockchain completed its Shanghai upgrade.</li>
            <li>Litecoin led the losers with a drop of 10.50% in the past 24 hours and traded at US$90.85, falling 1.39% for the week.</li>
            <li>The latest selloff in cryptocurrencies is an overdue correction following the rally this year and investors taking profits as a risk-off trend emerged in global markets, according to a CNBC report on Wednesday citing Joel Kruger, market strategist at forex and crypto platform Lmax Group.</li>
            <li>The risk-off mood follows more global inflation signals. The U.K. on Wednesday reported a 10.1% annual increase in the consumer price index for March, beating projections for a 9.8% gain. Also this week, Atlanta Federal Reserve President Raphael Bostic and St. Louis Fed President James Bullard said it’s too early to consider an interest rate cut, given the stubborn U.S. inflation rate, which stood at 5% in March, compared to the Fed’s goal of 2%.</li>
            <li>Lmax Group tweeted on Thursday the crypto market “has been forced to have a serious rethink about how it has been pricing” with respect to monetary policies.</li>
            <li>Another down arrow is U.S. Securities and Exchange Commission Chair Gary Gensler’s testimony before Congress on Tuesday, who said much of the business model of the cryptocurrency sector has been built around non-compliance with securities regulations.</li>
            <li>The total crypto market capitalization fell 5.04% in the past 24 hours to US$1.21 trillion. The total trading volume over the last 24 hours rose 33.08% to US$62.36 billion.</li>
            <li>In the NFT market, the Forkast 500 NFT index dropped 1.23% to 4,012.98 in the 24 hours to 09:00 a.m. in Hong Kong, but remained 0.74% higher for the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day. It is managed by CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</li>
            <li>U.S. stock futures traded lower as of 9:00 a.m. in Hong Kong. The Dow Jones Industrial Average dipped 0.11%. The S&P 500 futures fell 0.24%. The Nasdaq Composite Index moved down 0.35%.</li>
            <li>The three U.S. equity indexes stalled on Wednesday on softer first-quarter earning reports, while the Federal Reserve’s Beige Book survey on economic conditions published on Wednesday shows a sharp fall in lending after the failure of a trio of regional banks last month.</li>
            <li>Negatives for crypto from the bank failures include “dwindling liquidity driven by strong risk-off sentiments and regulatory pressures impacting banking access for the sector,” Ben Ritchie, director of Digital Capital Management AU said in a report by information services platform Finder.</li>
            <li>U.S. interest rates are currently between 4.75% to 5%, the highest since June 2006. Analysts at the CME Group now see a 16.7% chance the Fed will leave interest rates unchanged at its next meeting on May 3, while 83.3% predict a 25-basis-point increase, down from 84.8% on Wednesday.</li>
          </ul>
        </div>
      },
      {
        id: 406,
        title: 'Ethereum (ETH) Price Prediction 2025-2030: Is $3000 within reach now?',
        category: 'Crypto',
        subCategory: ['Ethereum', 'ETH', 'Price'],
        description: <div className="blog-desc">
          <p>Shanghai is finally behind us, with the hike above $2,000 evidence of ETH’s bullishness. What next though?</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 19, 2023', 
        cover: 'https://ncx.cx/images/ncx-blog-420.jpg',
        content: <div className="blog-content">
          <p>Shanghai is finally behind us, with the hike above $2,000 evidence of ETH’s bullishness. What next though?</p>
            <p>Disclaimer: The datasets shared in the following article have been compiled from a set of online resources and do not reflect AMBCrypto’s own research on the subject.</p>
            <p>In the four days following the launch of Ethereum’s Shanghai upgrade, crypto-exchanges noted net inflows of 179,500 ether (ETH), worth around $375 million.</p>	
            <p>In fact, according to CryptoQuant statistics, traders deposited 1,101,079 ETH to exchanges between 13 April and 16 April, but only removed 921,579 tokens. This was the month’s greatest four-day net inflow.</p>
          <p>Transferring tokens to exchanges typically implies that investors are prepared to sell, which may result in a decline in its price.</p>
          <p>For the first time since August, Ethereum, the market’s second-largest cryptocurrency, has hiked past the $2000-mark on the charts. Even though the optimism seemed to be dissipating, ETH was still valued at above $2k at press time.</p>	
            <p>This is interesting, especially since after bottoming out in June 2022 at about $880, the price of ETH has more than doubled. This, despite several unfavourable developments such as the demise of FTX, a rise in interest rates, and stricter U.S. regulations.</p>
          <p>Here, it is important to note that the Consumer Price Index (CPI) calculates inflation or the rate of growth in product and service prices. As a result, traders and investors predict that the US Federal Reserve will opt to postpone or slow down its intentions to hike interest rates at its next meeting if the CPI report indicates a high level of inflation.</p>
          <p>Ethereum (ETH), the token that powers the world’s leading smart contracts platform, was valued at over $2085, at the time of writing. Needless to say, cynicism around Shanghai seemed to be unwarranted. </p>
          <p>As a result, ETH/USD has drawn an ascending triangle, which is supported and opposed by a rising trendline and horizontal level, respectively. As lows rise steadily and highs hover around the same level, the pattern points to aggressive purchasing at these price levels.</p>
          <p>Speculations around Shanghai weren’t without reason, however. Especially since ETH was once valued at over $3,700, just over a year ago.</p>
          <p>Even so, the short-term sell-off event that was expected to follow the update, one which will allow stakers to withdraw their vested tokens from Ethereum’s proof-of-stake (PoS) smart contract, has not come to pass. At least, not yet.</p>
          <p>The Ethereum community seems to be supportive of Coinbase’s recently revealed layer-2 network, Base, which has been called a “watershed moment” and a “huge confidence vote” for the blockchain network.</p>
          <p>Base, a layer-2 network driven by Optimism and secured on Ethereum, seeks to eventually develop into a network for creating decentralized applications (dApps) on the blockchain. According to Brian Armstrong, CEO of Coinbase, the layer-2 network is now in its testnet phase.</p>
          <p>The move is “a massive vote of confidence for Ethereum,” according to Ryan Sean Adams, host of the Bankless Show. This could set a precedent for cryptocurrency businesses and financial institutions to use Ethereum as their preferred settlement layer.</p>
          <p>Recently, Vitalik Buterin, the creator of Ethereum, donated $150,000 in ETH to Syrian and Turkish victims. Additionally, the native coin of the Ethereum blockchain, ETH, experienced a significant price drop after whales sold 350,000 ETH tokens.</p>
          <p>According to Diogo Mónica, co-founder and president of Anchorage Digital, a cryptocurrency bank with a market cap of over $3 billion, the Merge’s success transformed Ethereum from “a smart contract platform lagging behind” into “something that was doing things properly.” This is accurate: After the Merge, institutional interest in ETH staking rose, according to Matt Hougan, CIO at Bitwise Asset Management.</p>
          <p>As ETH dominance has increased compared to other cryptocurrencies over the past few years, Ether’s bullish setup vs Bitcoin is apparent. Both Bitcoin and Ethereum have consolidated over the week as the broader crypto market continues to enjoy a bullish spell.</p>
          <p>The price of Ethereum has lately undergone a significant correction, yet the whales have been purchasing at every decline. The fifth-largest accumulation day in a year was recorded last week as ETH whale activity reached a new level. As the FTX problem developed over this month of November, Ethereum whales have been building up. According to a Santiment report,</p>
          <p><strong>“Ethereum’s large key addresses have been growing in number since the #FTX debacle in early November. Pictured are the key moments where shark & whale addresses have accumulated & dumped. The number of 100 to 100k $ETH addresses is at a 20-month high.”</strong></p>
          <p>It almost reached the lows during the FTX collapse-driven meltdown of the cryptocurrency market, but it rapidly bounced back and was able to maintain above those levels as well. This strengthens the argument since Ethereum has typically outperformed Bitcoin.</p>
          <p>Given everything, buying Ethereum must be a sound investment in the long term, right? Most experts have positive predictions for ETH. Furthermore, the bulk of long-term Ethereum price projections are upbeat.</p>
          <h3>Why are projections important?</h3>
          <p>Since Ethereum has seen phenomenal growth in recent years, it is not surprising that investors are placing significant bets on this cryptocurrency. Ethereum gained traction after the price of Bitcoin dropped in 2020, following a protracted period of stagnation in 2018 and 2019.</p>
          <p>Interestingly, much of the altcoin market remained idle even after the halving. One of the few that picked up the momentum quickly is Ethereum. Ethereum had increased by 200% from its 2017 highs by the end of 2021.</p>
          <p>Ethereum may experience such a spike thanks to several crucial factors. One of these is an upgrade to the Ethereum network, specifically a move to Ethereum 2.0. Another reason is the Ethereum tokenomics debate. With the switch to Ethereum 2.0, ether tokenomics will become even more deflationary. As a result, there won’t be as many tokens on the market to meet increasing demand. The outcome might increase Ethereum’s rising momentum in the future.</p>
          <p>In this article, we’ll take a quick look at the cryptocurrency market’s recent performance, paying particular attention to market cap and volume. The most well-known analysts’ and platforms’ predictions will be summarized at the end, along with a look at the Fear & Greed Index to gauge market sentiment.</p>
          <h3>Ethereum’s price, volume, and everything in between</h3>
          <p>At press time, ETH was trading at $2085 with a market capitalization of $241.6 billion. Over the last 7 days, ETH had appreciated by 10% on the charts.</p>
          <p>Even though it’s difficult to forecast the price of a volatile cryptocurrency, most experts concur that ETH may once again cross the $4,000 barrier in 2023. And, according to a recent forecast by Bloomberg intelligence analyst Mike McGlone, the price of Ethereum will conclude the year between $4,000 and $4,500.</p>
          <p>Additionally, according to a report by Kaiko last year, ETH’s market share of trading volume will reach 50% parity with Bitcoin’s for the first time in 2023.</p>
          <p>According to Kaiko, ETH outpaced Bitcoin in July last year as a result of significant inflows into the spot and derivative markets. Most exchanges have seen this surge, which can be an indication of returning investors. Additionally, a rise in average trade size is the exact reverse of what has been seen so far in 2022’s downturn.</p>
          <p>In fact, a majority of cryptocurrency influencers are bullish on Ethereum and anticipate it to reach incredible highs.</p>
          <p>While the broader Ethereum community was looking forward to the environment-friendly PoS update, a faction emerged in favor of a fork that will retain the energy-intensive PoW model.</p>
          <p>The faction was mostly made up of miners who risk losing their investment in expensive mining equipment since the update would render their business model useless. Prominent Chinese miner Chandler Guo stated on Twitter that an ETHPoW is “coming soon”.</p>
          <p>At the time, Binance clarified that in the event of a fork which creates a new token, the ETH ticker will be reserved for the Ethereum PoS chain, adding that “withdrawals for the forked token will be supported”. Stablecoin projects Tether and Circle both reiterated their exclusive support for the Ethereum PoS chain after the Merge.</p>
          <p>TradingView expressed the same opinion at the time this article was written, and their technical analysis of the Ethereum price indicated that it was a “Buy” signal for ETH.</p>
          <p>In fact, PwC’s Crypto-head Henri Arslanian claimed in an edition of First Mover that “Ethereum is the only show in town.” However, investors will need to witness increased demand and functioning for Ether’s price to keep climbing.</p>
          <p>According to investor and creator of the cryptocurrency research and media organization Token Metrics Ian Balina, “I think Ethereum can go to $8,000.”</p>
          <h3>ETH Whale Activity</h3>
          <p>On 27 March, blockchain analytics firm Santiment revealed that almost 90% of Ethereum’s supply was stored in self-custody addresses. The last time the figure was so high was nearly eight years ago in 2015, shortly after the protocol’s native token saw the light of day. This was happening as users are withdrawing their assets from Binance that is facing CFTC’s investigation.</p>
          <p>This essential all-time low ratio of ETH on exchanges (10.31%) indicated confidence from hodlers.</p>
          <p>Data from blockchain analytics firm Santiment shows ETH supply held by the top addresses on crypto exchanges has been on the rise since early June. On the other hand, ETH supply held by the top non-exchange addresses i.e. ETH held in hardware wallets, digital wallets etc. has been declining since early June. But why June? Because it was around that time that a tentative timeline for the Merge was disclosed to the community.</p>
          <p>Also, Santiment had tweeted that over the past 3 months, whales had beefed up their exchange holdings by 78%.</p>
          <p>So what does this mean? It means that Ethereum whales are moving their ETH onto exchanges. Top ETH hodlers are taking their supply out of cold storage and moving it to exchanges, most likely to facilitate a quick transaction if needed.</p>
          <p>In the run up to the merge, a number of exchanges like Coinbase and Binance announced that they will be suspending all ETH and ERC-20 token deposits and withdrawals, in order to ensure a seamless transition.</p>
          <p>It is possible that the whales moved their holdings onto exchanges to either preemptively dump their holdings in anticipation of a price slump after the Merge. The other possibility is them waiting till well after the Merge to act on ETH’s price action.</p>
          <p>Let’s now look at what well-known platforms and analysts have to say about where they believe Ethereum will be in 2025 and 2030.</p>
          <h3>Ethereum Price Prediction 2025</h3>
          <p>According to Changelly, the least expected price of ETH in 2025 is $4,204.12, while the maximum possible price is $5,063.95. The average expected trading cost is $4,355.45.</p>
          <p>DigitalCoinPrice is even more bullish in its assessment of ETH’s future performance. It predicts that ETH will trade as low as $5,380.03 and as high as $6,601.51, with its average price being $5,918.92.</p>
          <p>However, you have to remember that the year is 2025, and a lot of these projections are based on Ethereum 2.0 launching and performing successfully. And by that, it means Ethereum has to solve its high-cost gas fees issues as well. Also, global regulatory and legislative frameworks have not yet consistently backed cryptocurrencies.</p>
          <p>However, even though newer and more environmentally friendly technologies have been developed, analysts frequently claim that Ethereum’s “first mover advantage” has positioned it for long-term success, despite new competition. The price predictions seem conceivable because, in addition to its projected update, Ethereum is anticipated to be used more frequently than ever before in the development of DApps.</p>
          <h3>Ethereum Price Prediction 2030</h3>
          <p>Changelly also argued that the price of ETH in 2030 has been estimated by cryptocurrency specialists after years of price monitoring. It will be traded for a minimum of $24,867.82 and a maximum of $30,483.23. So, on average, you can anticipate that in 2030, the price of ETH will be roughly $25,593.23.</p>
          <p>DigitalCoinPrice is, however, not as bullish in its 2030 prediction for ETH. It predicts that the minimum and maximum prices of ETH in 2030 will be $17,805.72 and $19,116.90. On average, it will be traded at $18,729.30.</p>
          <p>Long-term Ethereum price estimates can be a useful tool for analyzing the market and learning how key platforms anticipate that future developments like the Ethereum 2.0 upgrade will affect pricing.</p>
          <p>Crypto-Rating, for instance, predicts that by 2030, Ethereum’s value will likely exceed $100,000.</p>
          <p>Both Pantera Capital CEO Dan Morehead and deVEre Group founder Nigel Green also predict that during the next ten years, the price of ETH will hit $100,000.</p>
          <p>Sounds like too much? Well, the functional capabilities of the network, such as interoperability, security, and transaction speed, will radically change as a result of Ethereum 2.0. Should these and other related reforms be successfully implemented, opinion on ETH will change from being slightly favorable to strongly bullish. This will provide Ethereum the chance to entirely rewrite the rules of the cryptocurrency game.</p>
          <h3>Conclusion</h3>
          <p>A potential concern is the prospect of a price impact when Ether’s short-term and long-term trends appear to be biassed in favor of the bulls from an on-chain viewpoint.</p>
          <p>According to the most current data from Santiment, the majority of Ethereum whale cohorts have increased their ETH accumulation in recent weeks. For instance, in March, the amount of Ether owned by addresses with a balance of 1,000 to 10,000 ETH</p>
          <p>Another potential worry on investors’ concerns is the prospect of a price impact when validators are finally free to return their 32 ETH deposits following the conclusion of the Shapella hard fork. How many of the 16 million ETH that is currently staked on the Beacon Chain will be sold on the open market is unknown.</p>
          <p>A compelling argument in favor of transitioning to liquid staking platforms is the capability to use liquid staking derivatives on other decentralized finance networks without sacrificing staking reward.</p>
          <p>While some of these investors have invested in rival tokens in order to profit, others are doing it out of precaution in order to hedge their portfolios. This has been corroborated by the volatility witnessed in metrics like daily active users and price action of so-called Ethereum killers like Avalanche, Solana, Cardano etc. in the run up to the merge event which is less than a month away.</p>
          <p>The majority of investors anticipated that Ethereum would bottom out at $3500 early this year, but the currency moved lower to show them incorrect. In fact, ETH briefly fell below the terrifying $1000 threshold.</p>
          <p>However, the coin has always rebounded when it appeared that it was poised to strike the target once more, restoring confidence in its future. This includes the incident in November 2022 when an FTX hacker allegedly dumped over 30,000 ETH. Hope is offered by the token’s persistence in the wake of the FTX bankruptcy and the protracted crypto cold.</p>
          <p>There is broad hope that the first smart contract blockchain will survive this period of trials, despite Ethereum’s rivalries and other factors contributing to its continuous instability.</p>
          <p>As far as the Merge is concerned, it is being hailed as a major success story by the Ethereum community. Buterin cited a research study by an Ethereum researcher, Justin Drake, that suggests that the “merge will reduce worldwide electricity consumption by 0.2%.”</p>
          <p>It also reduces the time to mine one block of ETH from 13 seconds to 12 seconds. The Merge marks 55% completion of Ethereum’s journey toward greater scalability and sustainability.</p>
          <p>The likelihood that Ether will experience a price surge of 50% in the future is increased by its superior interim fundamentals to those of Bitcoin. To begin with, Ether’s annual supply rate plummeted in October 2022, in part because of a fee-burning mechanism known as EIP-1559 that takes a certain amount of ETH out of perpetual circulation anytime an on-chain transaction takes place.</p>
          <p>Concerns about censorship on the Ethereum ecosystem have also emerged post the Merge. Around half of the Ethereum blocks are Office of Foreign Assets Control (OFAC)-compliant as MEV-Boost got implemented. As Ethereum has upgraded to a PoS consensus, MEV-Boost has been enabled to a more representative distribution of block proposers, rather than a small group of miners under PoW. This development raises a concern about censorship under the force of OFAC.</p>
          <p>It is interesting to note that while many eagerly waited for Ethereum’s Merge and beefed up their holdings in anticipation of a price surge, there was a group of investors who weren’t confident in the Merge’s successful rollout. These investors were betting on a glitch in the rollout process, hoping that the update runs into trouble. While some of these investors have started investing in rival tokens in order to profit, others are doing it out of precaution in order to hedge their portfolios. This was corroborated by the volatility witnessed in metrics like daily active users and price action of so-called Ethereum killers like Avalanche, Solana, Cardano etc. in the run up to the Merge.</p>
          <p>The majority of Ethereum price forecasts indicate that ETH can anticipate tremendous growth over the ensuing years.</p>
          <p>As per Santiment, Ethereum’s active addresses have sunk to 4-month lows with weak hands continuing to drop post-Merge and disinterest at a high as prices have stagnated.</p>
          <p>What about the flippening then? Is it possible that the altcoin might pass Bitcoin on the charts in the future? Well, that is possible. In fact, according to BlockchainCenter, ETH has already surpassed BTC on a few key metrics.</p>
          <p>Consider Transaction Counts and Total Transaction Fees, for instance. On both counts, ETH is ahead of BTC.</p>
          <p>On the contrary, the traditional definition of a ‘flippening’ relates to the market cap of cryptos flipping.</p>
          <p>However, remember that a lot can change over these years, especially in a highly volatile market like cryptocurrency. Leading analysts’ projections may vary, but even the most conservative one’s might cause respectable profits for anyone choosing to invest in Ethereum. As far as the F&G Index is concerned, ETH shows ‘neutral’ market sentiment for the moment.</p>
        </div>
      },
      {
        id: 405,
        title: 'Chainlink (LINK) Price Prediction 2025-2030: Is sky the limit for LINK?',
        category: 'Crypto',
        subCategory: ['Chainlink', 'Link', 'Price'],
        description: <div className="blog-desc">
          <p>The last 7 days saw LINK hike by over 15%. Does this mean its uptrend is finally here to stay?</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 19, 2023', 
        cover: 'https://ncx.cx/images/ncx-blog-419.jpg',
        content: <div className="blog-content">
            <p>The last 7 days saw LINK hike by over 15%. Does this mean its uptrend is finally here to stay?</p>
            <p>Chainlink (LINK) is a decentralized oracle network that connects smart contracts on blockchain platforms to real-world data. The network enables smart contracts to access off-chain resources, such as data from APIs and web pages, which makes it possible for them to interact with the real world. </p>	
            <p>Chainlink is used by a wide range of decentralized applications and platforms, including decentralized finance (DeFi) platforms, prediction markets, and gaming dApps. LINK’s popularity has been driven by its use case as a decentralized oracle solution, providing reliable and tamper-proof external data feeds to smart contracts.</p>	
            <p>The value of LINK is largely determined by the use cases of the decentralized oracle network. The more nodes that secure the network, the higher the demand for LINK tokens. With a maximum supply of 1 billion tokens, the token has seen tremendous growth since its launch in 2017. In fact, it rose from an average of $0.50 to its all-time high of $52.88 on May 10, 2021.</p>
          <p>In late 2020, LINK’s price noted a significant bull run, hitting an all-time high of over $20 in December of that year. This was driven in part by the overall bull market in the cryptocurrency space, as well as a strong demand for LINK as a utility token on the Chainlink network. Since then, the price of LINK has come down somewhat, but it has remained relatively stable and continues to be a popular investment asset.</p>
          <p>One reason for LINK’s relatively strong performance may be its strong adoption in the cryptocurrency space. The Chainlink network has gained significant traction among developers and users, and it has a number of high-profile partnerships and collaborations. Additionally, LINK has a strong development team and is backed by a number of well-respected investors, which adds to its credibility and appeal.</p>
          <p>On 10 November, Chainlink started offering proof of reserve services for troubled crypto exchanges. This feature was launched back in 2020 but has started to gain popularity in the wake of the current unrest in the industry.</p>
          <p>Apart from the staking upgrade, Chainlink announced various partnerships over the last week that will increase its adoption. The company announced on 24 October that prices in the Bitizen wallet will be powered by Chainlink price feeds following its integration into Polygon mainnet.</p>
          <p>Chainlink also revealed a channel partnership with Tokenomia.pro, a web3 consultancy firm catering to token engineering and smart contract design, among other things.</p>
          <p>Chainlink also announced a partnership with international banking network SWIFT, which came as much-needed positive news for its stakeholders.</p>
          <p>Speaking at SmartCon22, Chainlink Co-founder Sergey Nazarov unveiled plans to launch staking at the end of 2022, in addition to a new economic model for the Web3 services platform.</p>
          <p>On 29 September, SWIFT, the international banking network, announced a collaboration with Chainlink in order to develop a cross-chain interoperability protocol (CCIP) in an initial proof-of-concept (PoC). This move will pave the way for the institutional adoption of Distributed Ledger Technology (DLT).</p>
          <p>According to Chainlink’s official website, the transaction value enabled by the network so far is a whopping $7.2 trillion.</p>
          <p>Back in 2014, SmartContract.com set out to develop a bridge between external data sources and public blockchains. Ironically, this led to the creation of a centralized oracle system called Chainlink. In 2017, this product was reshaped into what we now know as the Chainlink Network.</p>
          <p>Chainlink is the largest oracle project in terms of market cap and total value secured, and a number of crypto-projects associated with it. An oracle is basically software that acts as an intermediary between the on-chain and the real world.</p>
          <p>Moreover, Chainlink provides a lot of use cases. Users of Chainlink can operate nodes and make money by managing the blockchain’s infrastructure. The Price Feed Oracle Networks are powered by a number of node operators. The platform integrates more than 100 projects with 700 Oracle networks, giving it access to over a billion data points and protecting over $75 billion.</p>
          <p>So, what does this movement mean, and is now a good time to get into LINK? This article will talk about the altcoin ranked twenty-fourth by market capitalization. In fact, it will also touch upon what are the key factors to consider when making a decision on buying into LINK.</p>
          <p>Here’s a fun fact from Defi Llama – Chainlink is securing more value than all of its competitors combined. The network has secured more than $14 billion from protocols that rely on its data feeds.</p>
          <p>In May 2021, Sergey Nazarov, Co-founder, and CEO of Chainlink disclosed in a podcast that Chainlink is estimated to have 60% of the market share.</p>
          <p>A monopoly like this has its cons. For instance, during the Terra collapse, Chainlink caused an $11.2 million loss to the Venus protocol. This was when the latter was unable to access accurate data from Chainlink’s price feed.</p>
          <p>In fact, the Chainlink ecosystem boasts some big names like VISA, SWIFT, Google Cloud, etc.</p>
          <p>It’s important to note that most of the LINK in circulation is being used for speculation rather than rewarding node operators. This, as expected, raises eyebrows among value investors.</p>
          <p>Some believe that Chainlink is creating economic value in the industry by catering to a number of crypto-projects. Alas, that value doesn’t seem to reflect on their native token’s price.</p>
          <p>Even so, following Chainlink’s 7 June proposal of the staking update, LINK surged by nearly 20% from $7 all the way up to $9.</p>
          <p>The proposed staking update is much anticipated in the crypto space. The update will be beneficial for the token’s value as oracles will be required to stake LINK. This update will also enable community participation, leading to enhanced overall security.</p>
          <p>Nazarov clarified that Chainlink does not produce blocks, but “make consensus on hundreds of oracle networks about price data.” He further added that the developer’s team is finally satisfied with the security and scalability of the consensus mechanism and ready to launch staking this year.</p>
          <p>The update will also bring additional utility to LINK, beyond facilitating payments to node operators.</p>
          <p>Chainlink developers estimate that the proposed staking will yield 5% annually thanks to proceeds from Chainlink’s data feed users and emissions from the treasury reserve. The goal is for treasury emissions to end once Chainlink’s usage grows, leaving all staking rewards to come from fees paid by oracle users.</p>
          <p>While speaking at NFT.NYC 2022, Lauren Halstead from Chainlink Labs outlined the spectrum of Chainlink’s use cases using the example of dynamic NFTs. Halstead demonstrated how dynamic NFTs can be updated in real-time with the help of off-chain data gathered by Chainlink.</p>
          <p>Interest Protocol, the first fractional reserve banking protocol on the Ethereum blockchain, announced earlier this month that it had entered into a strategic partnership with Chainlink. Chainlink will help Interest Protocol integrate two of its features, namely Chainlink Keepers and Chainlink Proof of Reserve.</p>
          <p>On 15 August, Floki Inu announced that they had integrated two products from Chainlink’s suite with their newly launched FlokiFi Locker on BNB Chain and the Ethereum mainnet. In an interview with BSC news, a core team member of Floki said,</p>
          <p><strong>“We feel excited to be working with Chainlink to enhance the integrity of the FlokiFi Locker protocol. Chainlink is by far the biggest decentralized oracle solution in the world as well as the best and most reliable.”</strong></p>
          <p>On 28 August, Chainlink informed its community on Reddit that the Chainlink Verifiable Random Function (VRF) was being used by more than 350 projects across Avalanche, Ethereum, Fantom, and Polygon, as a source of provably fair randomness for their NFTS, dApps etc. Chainlink VRF is the industry-leading random number generator (RNG) solution for an off-chain solution and smart contracts.</p>
          <p>Data from whalestats revealed that LINK is the most widely held token among top Ethereum whales. This information is derived from the data collected from the wallets of the top 5000 Ethereum whales.</p>
          <p>According to a report published by Fortune Business Insights, the global Internet of Things (IoT) market is projected to grow at a CAGR of 26.4% annually between 2022 and 2029. Given the rising adoption of blockchain technology in mainstream businesses like banking, logistics ets, we can expect a similar growth rate in cryptocurrencies that enhance IoT-based businesses. Chainlink would be an appropriate example of this.</p>
          <h3>LINK Price Analysis</h3>
          <p>At press time, LINK was trading at $8.57, hiking by 16.5% in the last 7 days. Moreover, LINK had a market capitalization of $4.4 billion, with the crypto ranked 21st on the crypto-rankings chart.</p>
          <p>The month of August 2022 saw Chainlink closing in on double-digit territory when it set a two-month high of $9.52, before falling to prices that rendered the monthly return negative. This is pretty volatile, compared to the rather calm sideways movement witnessed by LINK’s price in July.</p>
          <p>Even with all the volatility, the overall theme for August can be summed up with one word: Bearish.</p>
          <p>September, however, was bullish, with October seeing bits of both. As far as November and December are concerned, the less said, the better.</p>
          <p>While 2023 began on a positive note, its fortunes were reversed in mid-February. LINK’s most recent downtrend has been fueled by the macroeconomic headwinds faced by the crypto-market at large. It has recovered since, however, and this has been fueled by how ETH and BTC have been doing.</p>
          <h3>Chainlink’s critics</h3>
          <p>Eric Wall from Arcane Assets has been rather critical of Chainlink’s activities. In May 2021, he stated that the network is not “crypto-economically secure,” citing the developer’s state and the fact that the model relies on a trusted system.</p>
          <p>Zeus Capital has been a vocal critic of Chainlink since 2020 when they published a fifty-nine-page investigative report. One outlining how the network is a fraud, going as far as calling it the “wirecard of crypto.”</p>
          <p>CryptoWhale turned up the heat on Chainlink developers in a series of tweets too. It accused the team of running a pump-and-dump scheme. These allegations came following a $1.5 billion LINK sell-off allegedly by Chainlink insiders and developers in June 2021.</p>
          <h3>LINK Tokenomics</h3>
          <p>One billion LINK tokens were pre-mined in 2017, following which Chainlink raised $32 million through an initial coin offering (ICO). Thirty percent went to the founders and the project. Thirty-five percent accounted for airdrops and rewards for node operators. The remaining thirty-five percent went towards issuing to investors.</p>
          <p>According to Etherscan, the top hundred wallets hold roughly 75% of LINK supply. This doesn’t look so good for a token that’s supposed to be decentralized. Chainlink’s supporters have, however, argued that a certain degree of centralization will help developers to effectively respond to network-threatening events.</p>
          <p>Data from Etherscan also revealed Chainlink developers’ addresses consistently dumping their holdings on Binance, something that hasn’t been received well by the community.</p>
          <p>One would think that this works out well in favor of decentralization, but most of those tokens have been bought up by whales.</p>
          <p>A number of analysts believe that the performance of LINK and ETH is correlated to some extent.</p>
          <p>Chainlink’s growth is inherently tied to the growth of smart contracts and blockchain services. Increased adoption of smart contracts translates to an increase in demand for data feeds from oracles.</p>
          <p>Chainlink’s utility has attracted cross-chain ventures. Non-Ethereum-based protocols like Polkadot and Solana are building integrations with Chainlink for access to its oracle network.</p>
          <h3>Chainlink (LINK) Price Prediction 2025</h3>
          <p>Experts at Changelly concluded from their analysis of LINK’s previous price action that in 2025, the crypto should be worth at least $26.64. The maximum price for LINK, according to them, would be $32.01. Considering its press time price, that would yield a whopping 312% profit.</p>
          <p>On the contrary, Finder’s panel of experts has projected a median value of $40 for LINK by December 2025.</p>
          <p>Ethereum merging its mainnet and Beacon Chain is expected to affect LINK’s price action, too. In fact, it has also been demonstrated that there’s some correlation between ETH and LINK. ETH rose above $4000 and LINK broke the $50-mark to reach its all-time high last year.</p>
          <p>Talking in the context of the Mainnet merge, if ETH should break the $ 10,000 level, then it is likely that LINK will follow suit and touch $100.</p>
          <p>In light of new business partnerships, API connection improvements, and Chainlink’s customized services, there are also projections that place a maximum price of $45.75 on LINK by 2025.</p>
          <h3>Chainlink (LINK) Price Prediction 2030</h3>
          <p>Changelly’s crypto experts have estimated that in 2030, LINK will be trading for at least $182.88, possibly peaking out at $221.4. That would mean a return of 2650%.</p>
          <p>Joseph Raczynski, the technologist, and futurist at Thomson Reuters and one of the panelists for Finder, has a rather positive outlook on LINK’s future. He sees the coin worth $100 in 2025 and $500 by 2030.</p>
          <p><strong>“Link is pushing the boundary on one of the most important aspects of blockchain technology — connections to other blockchains, databases and ecosystems. Chainlink could be the highway among blockchains, which is a huge key for the industry.”</strong></p>
          <p>Justin Chuh, the Senior Trader at Wave Financial, made his own projections for the future of LINK too. He sees the coin at $50 in 2025 and $100 in 2030.</p>
          <p>Forrest Przybysz, the Senior Cryptocurrency Investment Analyst at Token Metrics, shared his immensely bullish stance on the token’s future value and projected LINK to be worth $500 by 2025 and $2500 by the end of 2030.</p>
          <p>He added,</p>
          <p><strong>“LINK has one of the fastest, smoothest growth curves of any cryptocurrency and has a major lead in terms of its competition.”</strong></p>
          <h3>Conclusion</h3>
          <p>Chainlink had previously clarified that it would continue operating on the Ethereum blockchain following the Merge to the proof-of-stake (PoS) consensus layer scheduled for next month, rubbishing claims of any association with forked versions of the Ethereum blockchain, including proof-of-work forks.</p>
          <p>The major factors that will influence LINK’s price in the coming years are,</p>
          <ul>
            <li>Timely implementation of Staking update</li>
            <li>Increased Adoption of WEB 3.0</li>
            <li>Partnerships with established businesses.</li>
          </ul>
          <p>Launched in 2017, Chainlink is fairly new to the industry and its full potential is yet to be determined. On-chain metrics suggest that users are confident about the future of LINK.</p>
          <p>While it is true that the service provided by Chainlink pertains to a specific niche, one cannot deny the relevance of said niche and its importance in the future. Oracles essentially cater to all blockchains that utilize smart contracts, making the services of platforms like Chainlink vital for their operations. Companies from both traditional backgrounds and from the crypto space agree that smart contracts hold considerable significance, significance that will only grow in the future.</p>
          <p>From an investment point of view, one might compare Chainlink and its token to how a traditional company and its shares function. If the company has a healthy balance sheet and has a meaningful contribution to the economy, then its shares are bound to perform well. The same can be said for Chainlink, because they are the leaders of their sector and their services are essential to several projects, both now and in the future.</p>
          <p>The above analogy would not hold true for even a third of the thousands of crypto projects that exist today.</p>
          <p>As far as the Fear and Greed Index is concerned, it flashed signs of ‘Neutral.’</p>
        </div>
      },
      {
        id: 404,
        title: 'Shiba Inu (SHIB) Price Prediction 2025-2030: Is SHIB stuck in a loop again?',
        category: 'Crypto',
        subCategory: ['Shiba', 'SHIB', 'Price'],
        description: <div className="blog-desc">
          <p>After a promising start, SHIB is stuck within a price range. Here’s what can spur its fortunes…</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 19, 2023', 
        cover: 'https://ncx.cx/images/ncx-blog-418.jpg',
        content: <div className="blog-content">
            <p>After a promising start, SHIB is stuck within a price range. Here’s what can spur its fortunes…</p>
            <p>The crypto-markets began trading on a gloomy note on 18 April as Bitcoin was unable to break through its resistance level of $30,400. When Bitcoin’s price dropped to $29,700, briefly, several altcoins and meme tokens followed. Shiba Inu (SHIB), like many others, dropped on the charts too, before recovering somewhat and falling again finally.</p>	
          <p>Shiba Inu project’s lead developer Shytoshi Kusama recently updated his Twitter location to “in the Lab,” prompting the crypto-community to speculate about Shibarium’s progress.</p>	
          <p>The recent release of Shibarium beta’s public documentation and ongoing project developments demonstrate Shiba Inu’s strong commitment to expanding their ecosystem and improving the platform’s capabilities. The price of SHIB, on the other hand, remains sideways as market sentiment remains uncertain.</p>	
          <p>There is mild but consistent growth in the total number of Shiba Inu (SHIB) tokens being burnt as the price of the meme coin takes a breather. Over 5.2 million SHIB tokens have been burnt over the last 24 hours. In fact, Shiba Inu has been on a rampage for the better part of the week.</p>	
          <p>Consider this – Shiba Inu (SHIB) reportedly noted its highest daily surge in active addresses in 2023 at the end of March, when nearly 36,000 SHIB addresses transacted on the network. Citing Santiment data, crypto-analyst Ali Martinez made the claim on Twitter that the network activity has set a record high since the beginning of the year.</p>	
          <p>Despite the launch of the much-awaited Shibarium L2,  however, whale trading activity has continued to decline.</p>	
          <p>Shytoshi Kusama had previously promised that Shibarium would not only serve as a Layer 2 platform for Shiba Inu ecosystem members, but would also attract members of the broader crypto-community. Built on the Ethereum blockchain, Shibarium is expected to bring a lot of attention to meme coins.</p>	
          <p>SHIB took a massive hit in the wake of the Silicon Valley Bank collapse. The token tumbled down the price chart but has been trying to recover.</p>	
          <p>That being said, the arrival of Shibarium might potentially mark a new era for Shiba Inu. One in which it moves from being just a meme cryptocurrency to being a platform that offered genuine utility. Just last week, the project announced the public beta launch of Shibarium, a launch that is expected to push SHIB’s price in the future.</p>	
          <p>Shiba Inu was founded in 2020 by an anonymous techpreneur. Elon Musk and Vitalik Buterin, both tech billionaires, have praised the project. Shiba Inu has been successful in attracting people who missed the Dogecoin craze previously.</p>	
          <p>Investors seeking to join the joke have increased the popularity of memecoins, which are not supposed to be taken seriously.</p>	
          <p>Shiba Inu has been known to be a disrupter when it comes to management and operations. The enterprise began with a supply of one quadrillion; its founder Ryoshi first locked 50% in Uniswap and then burned another 50% to Vitalik Buterin, the founder of Ethereum. We are yet to know why this step was taken.</p>	
          <p>Later in 2021, Buterin burned 90% of his Shiba Inu holdings and donate the rest to charities. He had earlier donated SHIB tokens worth $1 billion to a COVID-19 relief fund for India, CryptoRelief. He said that this was the only way he could avoid his transactions in Shiba Inu being read as actions.</p>	
          <p>Buterin’s decision to burn his holdings, nearly 50% of the Shiba Inu coins in circulation, effectively put them outside the purview of the market since these coins are now stored on an inaccessible public address. Ryoshi thanked Buterin for his contribution to creating a decentralized financial system.</p>
          <p>Buterin has also praised the Shiba Inu community for supporting different initiatives such as the Fellows in AI Existential Safety.</p>	
          <p>The platform also supports the Shiba Inu Incubator, facilitating a large group of creative digital artists who bring forth an experience of what feels like a decentralized movement. The NFT project gives support to artists in the creation, exhibition, promotion and auction of their artworks, besides providing them with other streams of income.</p>	
          <p>In August 2022, the Shiba Inu Ecosystem announced on Twitter the name of the Shib CCG game, Shiba Eternity. It also revealed that the game would be available on both Google PlayStore and Apple AppStore. On 17 September, the game was launched in Australia and plans to venture into other countries.</p>	
          <p>The community is also involved in rescuing real Shiba Inu dogs through its association with the NGO named Shiba Inu Rescue Association.</p>	
          <p>Skeptics are unsure of the future of cryptocurrency and memecoins in particular. While the mainstream cryptocurrencies projected themselves to be the alternatives to fiat currencies, memestream cryptocurrencies such as Shiba Inu were only poking fun at the former’s claims.</p>	
          <p>But as these memecoins got popular, they didn’t go in the direction of developing use cases of the currency. That wasn’t the intention either, neither did the community expect such a popularity in the growth of memecoins. There are, however, a number of merchants today in the US accepting Shiba Inu as a payment method.</p>	
          <p>Another factor affecting the growth of the Shiba Inu currency is the huge number of crypto whales selling the memecoins. As per a report, whales at one point of time held $1 billion worth of SHIB coins. However, they keep selling and burning the currency from time to time, and now they hold barely a fraction of that amount.</p>	
          <p>The second quarter of 2022 proved to be a bloodbath for the entire cryptocurrency industry as Terra Luna, FTX and several crypto projects failed in the year. The banking crisis following the collapse of the crypto-friendly Silicon Valley Bank (SVB) also impacted SHIB’s performance.</p>	
          <h3>Why these projections matter </h3>
          <p>As is evident, a sense of community is crucial to the growth of SHIB. Whether it is their demand to get it listed on Robinhood or asking McDonald’s to accept it as a mode of payment, the community has always rallied around the growth of SHIB.</p>	
          <p>It has also become home to a huge community of NFT artists who focus on the Shiba Inu breed of dogs in their artworks.</p>	
          <p>“Rather than simply dismissing the hype outright, it’s important to realize that what we’re seeing is the mass movement of traders new to crypto moving into the space,” said Ben Caselin, Head of Research and Strategy at AAX. He added, “SHIB coin is a meme coin and embraces that.”</p>	
          <p>In this article, we will give an overview of the key performance indicators of SHIB such as price and market cap. We will then share with you what most popular crypto-analysts have to say about the future of this meme coin. This information will be complemented by data charts for a better understanding of the trajectory of SHIB so far and later.</p>	
          <h3>Shiba Inu’s Price, Market Cap, and everything in between</h3>
          <p>During the crypto-bloom of 2021, SHIB’s price continued to soar and hit 0.00003503 in mid-May. Its price further rose by over 34% when it got listed on the crypto-exchange Coinbase in September 2021.</p>	
          <p>When the Shiba Inu community clamoured to get the cryptocurrency listed on Robinhood Markets Inc., its price soared to an ATH of $0.00008845 in late October 2021. The altcoin, however, was not listed on the exchange before April 2022. At that point in time, it even dislodged Dogecoin to become the most-valued meme coin by market capitalization. The surge also led to its mass adoption by many cryptocurrency enthusiasts.</p>	
          <p>As the cryptocurrency market crashed during Q2 of 2022, SHIB’s market price also continued to fall. From its April 2022 price of 0.000026, it fell to a little below $0.000008 by mid-June 2022. Since then, it hasn’t performed much better.</p>	
          <p>SHIB, at press time, was trading at $0.00001122 with a market cap of $6.7 billion and a 24-hour trading volume of $377 million.</p>	
          <p>However, one cannot disregard the fact that along with DOGE, SHIB has been responsible for a flood of meme-coins in the cryptocurrency market such as Bit Shiba, King Shiba and Baby Doge.</p>	
          <h3>Shiba Inu Coin’s 2025 Predictions </h3>
          <p>Before reading further, we must understand that market predictions of different analysts widely vary. A good number of times, these predictions will prove to be wrong as analysts cannot always foresee events such as political or environmental crises. It is wise that an investor conducts their own research before investing in a cryptocurrency, especially in something as volatile as SHIB.</p>	
          <p>A Changelly blogpost says that after studying the SHIB prices and market fluctuations, experts predict that SHIB could hit as high as $0.0000440756 and as low as $0.0000366063 in 2025. Its average price is expected to remain at $0.0000376362.</p>	
          <p>Telegaon writes that according to some crypto experts, the Shiba Inu Coin price can hit a new ATH in 2025. They predict the minimum and maximum prices of SHIB this year to be $0.00009839 and $0.0001928, respectively. Its average price is predicted to be around $0.0001152.</p>	
          <p>As per DigitalCoinPrice, the minimum and maximum prices of SHIB in 2025 will be $0.0000312 and $0.0000381, with its average price remaining at $0.0000356.</p>	
          <p>Shiba Inu’s 2030 Predictions</p>	
          <p>The aforementioned Chanelly blogpost writes that SHIB could hit as high as $0.0002908409 and as low as $0.0002463454 in 2030. Its average price is expected to remain at $0.0002532547</p>	
          <p>Telegaon writes that SHIB’s price can hit as high as $0.00112 and as low as $0.0009354 in 2030. Its average price in the said year however will be $0.0009915.</p>	
          <p>The post adds that if users burn enough tokens, a cryptocurrency’s acceptance is bound to increase. But we should understand that for this purpose, it has to have different use cases.</p>	
            <p>The aforementioned DigitalCoinPrice article predicts that SHIB’s average price in 2030 will be $0.000108. Its minimum and maximum prices for the year will be $0.000103 and $0.000111.</p>	
            <p>At the time of writing, SHIB’s Fear and Greed Index was in the ‘neutral’ position.</p>
          <h3>Conclusion</h3>
          <p>Shiba Inu Coin has continued to grow, hand in hand with the token burns. However, as its supply decreases and demand increases, its price should rise. Ideally. In fact, many investors have become millionaires by investing in this meme coin.</p>
          <p>Shiba Inu has a lot of promising possibilities that will keep pushing the market. What also works in its favor is its extremely low price, which is why a lot of people keep buying it.</p>
          <p>Shibarium, the platform’s Layer 2 protocol, has been launched. It will allow users to move assets with minor gas fees, facilitating micro transactions over Shiba Inu-based dApps. The team has deployed a host of new and innovative developers to build the UX/UI of its platform and web portals as a part of the same process.</p>
          <p>The launch of Shibarium was accompanied by the launch of the TREAT token. It will be used as a reward token on Shiba Inu-based metaverse and games such as the Shiba Collectible Card Game. A limited supply of these coins is reserved for its most loyal Breed members who have been working for years to build public confidence in the entity. The team has also been contemplating launching SHI, a stablecoin; but no concrete information about this coin is there so far.</p>
          <p>In January 2022, Shiba Inu Ecosystem announced the arrival of Shiberse, the native metaverse of the Shiba Inu community. As digital coins, games, virtual land and memes populate the Shiberse, it is bound to get popular among an audience that is keen to explore a brave new world beyond the confines of time and space. </p>
          <p>The same month, the group tweeted the news about Shiba Eternity, a collectible card game. The game has been developed in close collaboration with the legendary AAA game development studio PlaySide Studios.</p>
          <p>What the enterprise is certain to face is a challenge in this space from Big Tech metaverse ventures such as Yuga Labs’ Otherside and Zuckerberg’s Meta. It is difficult for an independent community such as the Shiba Inu Ecosystem to sustain the might of Big Money and Big Tech. The only way it can gain an edge in this segment is by introducing revolutionary ideas in the metaverse, gaming and NFT segments. So far, we haven’t seen anything new.</p>
          <p>In October 2022, Elon Musk announced the launch of a fragrance that can be bought with SHIB, besides PayPal, Google Pay, DOGE and regular bank cards. As cryptocurrency is accepted in collaboration with BitPay payment processor, payments in SHIB are also possible.</p>
          <p>The same month, Google Cloud and Coinbase made an announcement about their relationship for Web 3.0 development initiatives. A select group of clients will also be able to pay for these services using SHIB and other cryptocurrencies. We’ll have to wait and see if the decision is implemented and if other businesses decide to follow suit.</p>
          <p>Shibarium’s launch suggests that the Shiba Inu team looks serious about being more than another memecoin, something also indicated by its long-awaited metaverse. As such, SHIB really could be one of the major coins to watch this year, with significant rallies potentially in sight.</p>
          <p>Furthermore, its upcoming Metaverse project improves this project’s allure from a tokenomics perspective. As a memecoin, the value of SHIB is driven by how bullish (and large) the Shiba Inu community is at a given point in time.</p>
          <p>Analysts speculate that SHIB cryptocurrency might retrace its recovery path as soon as it gets support from the level. However, volume change can be seen declining and under the influence of sellers. Buyers need to come forward to rescue the SHIB token from the seller’s grip.</p>
          <p>Early last month, Shiba Inu announced that it will debut its Wagmi Temple at the upcoming SXSW festival. Here, WAGMI stands for “We’re all gonna make it,” a popular phrase among fans of Shiba Inu. Meanwhile, the broader crypto community is representing their belief in the potential of Shiba Inu for growth and success.</p>
        </div>
      },
      {
        id: 403,
        title: 'Stellar (XLM) Price Prediction 2025-30: What XLM must do for a clear uptrend',
        category: 'Crypto',
        subCategory: ['Stellar', 'XLM', 'Price'],
        description: <div className="blog-desc">
          <p>At press time, a significant correction seemed to be underway. What does this mean for XLM now?</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 19, 2023', 
        cover: 'https://ncx.cx/images/ncx-blog-417.jpg',
        content: <div className="blog-content">
          <p>At press time, a significant correction seemed to be underway. What does this mean for XLM now?</p>
            <p>Stellar Lumens [XLM] gained somewhat over the last seven days, on the back of the larger market’s uptrend on the charts. In fact, following the aforementioned price hike, XLM’s volume rose too, hitting a weekly high of 223.7 million recently. Even so, at the time of writing, the altcoin was on its way down, losing over 4% of its value in 2 hours alone. </p>
          <p>Curiously, it was only last week that XLM’s price surpassed $0.1 for the first time in nearly a year. Coincidentally, the additional increase last week coincided with the project’s ninth anniversary.</p>
          <p>Early this month, Brazil’s central bank launched the pilot for its experimental central bank digital currency (CBDC). The test for Brazilian CBDC, Digital Real, is taking place on the Stellar blockchain network.</p>
          <p>Soon thereafter, Pendulum announced the release of the Spacewalk bridge that connects the Polkadot infrastructure to Stellar’s blockchain so Polkadot can access fiat and stablecoin currencies. The Spacewalk bridge will allow Polkadot to access the entire infrastructure created by Stellar, especially that related to cross-border payments.</p>
          <p>The trust of large institutions in the Stellar network is a primary reason for its strong market presence.  </p>
          <p>Stellar is today one of the best platforms for facilitating faster and easier international financial transactions. It is based on a decentralized on-chain protocol. Stellar users trade in Lumens (XLM) which is the platform’s native cryptocurrency.</p>
          <p>Another important feature of Stellar is that individuals are more interested in Stellar than organizations, as it is a platform for small-amount payments. It is gaining popularity due to its simple user interface.</p>
          <p>Stellar enables real-time transactions to occur anywhere in the world in as little as five seconds. A new smart contracts platform called Soroban has released its second preview. The upgrade intends to increase platform developer friendliness, scalability, and sensibility.</p>
          <p>Last year, Ethereum transitioned from the proof-of-work (PoS) to proof-of-stake (PoS) consensus mechanism following the Merge. A major reason for this step was claimed to be the adoption of eco-friendly processes. In this regard, Stellar is very promising, as it has a smaller carbon footprint. Its authentication cycle is also fast, keeping energy use to a minimum.</p>
          <p>Acting as a decentralized currency exchange, Stellar helps you track all of your assets with an order book. You can sell, buy and manage all of your assets here, with XLM acting as an intermediate currency for paying transaction fees. The currency is very useful for the users because it helps you reduce transaction costs.</p>
          <p>Transactions on the Stellar platform are performed swiftly due to the ease of Lumens. The currency not only makes transactions seamless for the sender and the recipient but also ensures that transactions are secure.</p>
          <p>Jed McCaleb, Co-founder, and CTO of Stellar said in an interview with CoinMarketCap that XLM is used in a fundamental way for the network.</p>
          <p><strong>“Maybe that affects the price, maybe price is a secondary indicator of how useful the underlying protocol is in some way… But I think that the trend is there: that where price and utility can come into play.”</strong></p>
          <p>XLM is listed on a number of crypto exchanges including Binance, eToro, Huobi Global, CoinTiger, FTX, and OKEx. This shows that the currency is an increasingly accepted choice of investors now.</p>
          <p>A total of 100 billion XLMs were issued when the Stellar network was launched in 2015. In 2019, the group announced that it was burning over half of the cryptocurrency’s supply. Stellar mentions on its website that currently, there are around 50 billion XLMs in existence; 20 billion XLMs are in circulation and 30 billion XLMs are retained by the Stellar Development Foundation for project development. Nothing more will be created.</p>
          <h3>Why these projections matter</h3>
          <p>Where Stellar trumps other financial platforms is its low transaction fees, which have drawn a huge number of cryptocurrency users to it. It is one of the few blockchain networks that has been successful in collaborating with large tech corporations, such as Deloitte and IBM. Stellar, in partnership with IBM, launched a project that enables FinTech to engage in financial transactions using assets such as stablecoins.</p>
          <p>It must be noted that Stellar is one of the large corporations that are operating in the cryptocurrency market. It is one of the most centralized cryptocurrency networks active on the internet. While the Stellar network uses decentralized nodes, it doesn’t have that many validators. Such an infrastructure gives the group a lot of control over the operations and price movement of XLM.</p>
          <p>In 2016, Deloitte announced a partnership with Stellar, along with four other blockchain networks, to provide new technological capabilities to its global financial institution client base.</p>
          <p>In June 2018, Fortune reported that New York financial regulators approved Stellar Lumens to trade on the Bit exchange, the first time the state’s authorities have given it the green light.</p>
          <p>In October 2021, IBM partnered with Stellar to facilitate cross-border payments by banks. The system uses XLM as a bridge currency for transactions and it has been successful in the South Pacific region.</p>
          <p>The same year, Moneygram announced a partnership with Stellar. Its integration with Stellar facilitates the conversion of the USDC stablecoin into cash and vice versa. The facility aims to encourage the liquidity of cryptocurrencies and the integration of traditional and cryptocurrency markets.</p>
          <p>In October 2021,  Flutterwave, a global payments technology company, also announced two new remittance corridors between Europe and Africa on the Stellar network. The step is a major step in Stellar’s expansion in the global market.</p>
          <p>It also succeeded in receiving certification from the Islamic scholars of Bahrain in 2018, aiming to integrate the technology into the field of sharia-compliant financial products, reported Reuters.</p>
          <p>“We have been looking to work with companies that facilitate remittances, including in the United Arab Emirates, Saudi Arabia, and Bahrain. It’s a huge market,” said Lisa Nestor, the then-director of partnerships at Stellar. Since the Middle East and South Asian regions are key areas of growth for the group, where a lot of countries are run on a sharia-compliant system, this is a major success for Stellar.</p>
          <p>Developing economies are the focus of the Stellar network in the areas of remittances and loans. It primarily aims to target those who are still not part of the traditional banking system.</p>
          <p>In June 2022, the global platform for modern money movement, Nium, and Stellar announced a partnership to enable payouts to 190 countries. “This integration truly drives home the value that blockchain-powered cross-border payment solutions bring to the current financial system,” said Denelle Dixon, CEO and Executive Director of the Stellar Development Foundation. “At SDF, we are always working to fill up the map and connect the network to more of the globe. Together with Nium, we are thrilled to expand the reach of the Stellar network so significantly.”</p>
          <p>Another prominent feature of Stellar is that it gives power to the community to decide what project(s) the blockchain should focus on.</p>
          <p>We will now briefly give an overview of the key performance indicators of XLM, such as price and market capitalization. We will then summarize what the world’s leading crypto-analysts have to say about the future of this currency, along with its Fear & Greed Index.</p>
            <h3>XLM’s Price, Market Cap, and everything in between</h3>
          <p>XLM’s price has hiked significantly over the last few years. Back in 2018-2019, it kept falling below its previous ATH of around $0.93 (recorded in early January 2018). It was only in 2021 that its price again rose, hitting a price level of over $0.7 around mid-May. However, as the cryptocurrency market collapsed in the second quarter of 2022, XLM went into a bearish dive.</p>	
            <p>At press time, XLM was changing hands at $0.0985, with a market cap of $2.58 billion.</p>	
            <p>The market capitalization of the cryptocurrency follows its price trends throughout. In early January 2018, it was nearly $9 billion, and it skyrocketed to as high as $16.5 billion (May 2021) during the crypto-boom of 2021. In fact, it was performing fairly well in 2022 too, until the market crashed during the year’s second quarter.</p>
          <p>Stellar has seen many growth spurts over the past few years, such as when Mercado Bitcoin announced its use of the platform. In less than a year, Stellar housed almost 3 million user accounts. Since that time, however, Stellar has built a network of partners that includes Flutterwave and MoneyGram.</p>
          <h3>Lumens’ 2025 Predictions</h3>
          <p>A Changelly blogpost says that many experts have observed the prices and fluctuations of Lumens over the years and concluded that the currency could hit as high as $0.309969 and as low as $0.259974 in 2025. Its average price will remain around $0.259974 in the said year.</p>
          <p>Telegaon is very bullish in its assessment of XLM’s performance. It writes that the average price of XLM can be around $2.96 by 2025 if current growth continues. Its maximum price could be up to $3.53, while its minimum price can go down up to $1.32.</p>
          <p>As per DigitalCoinPrice, XLM’s price could reach as high as $0.36 and as low as $0.31 in 2025. Its average price in the year is going to be $0.38.</p>
          <h3>Lumens’ 2030 Predictions</h3>
          <p>The aforementioned Changelly blogpost predicts the maximum and minimum prices of XLM in 2030 to be $2.12 and $1.74 respectively. Its average price in the year will be $1.79.</p>
          <p>Telegaon remains very bullish in its assessment for 2030 as well. It writes that the currency could reach as high as $31.02 and as low as $23.31. It predicts XLM’s average price to be around $25.62.</p>
          <p>As per DigitalCoinPrice, XLM’s average price in 2030 is going to be $1.1, with its maximum and minimum prices being $1.11 and $1.04.</p>
          <h3>Conclusion</h3>
          <p>Stellar has, time and again, stressed its role in increasing financial inclusion across the globe. In particular, it focuses on working towards better micro-finance management. Today, it operates in association with a number of financial institutions across the globe, shaping the future of a financial system that is welcoming to cryptocurrency.</p>
          <p>Any financial institution can integrate with Stellar and avoid the hassle of building its own payment gateway. This integration connects these global players in such a way that interoperability and communication among different systems are seamless.</p>
          <p>A significant accomplishment of Stellar is the integration of the global financial system while cutting fees. Stellar has a sizable user base, which is not surprising given that it has become a crucial tool for enabling economic empowerment.</p>
          <p>The Stellar network is considered a rival to the Ripple network. While Ripple helps banks make fund transfers, Stellar helps individuals outside the banking system make fund transfers. Its simple, swift and economical process has made it very popular among users across several developed countries.</p>
          <p>These developments are certain to boost Stellar’s credibility among the users and raise the price of Lumens. Besides, XLM is one of the most eco-friendly cryptocurrencies. Its consensus model is faster than both PoS and PoW, making it the preferred choice of many investors.</p>
          <p>The unique features of Stellar such as strategic partnerships and convenience, make XLM one of the most reliable crypto investments. Its growth as a payments network will be the most important factor influencing the future of XLM.</p>
          <p>Despite being embroiled in a legal dispute with the Securities and Exchange Commission (SEC), Stellar’s Lumens coin is a major cryptocurrency to wager on.</p>
          <p>In June 2022, the system upgraded Protocol 19, building payment channels and key recovery channels. Stellar is also working on the Project Jump Cannon to facilitate a robust execution environment for smart contracts.</p>
          <p>In 2022 itself, many crypto exchanges such as WhiteBIT, CoinMe, and Mercado Bitcoin enabled USD-backed stablecoin transactions, increasing the access to USDC on Stellar.</p>
          <p>Coinbase Wallet announced in November 2022 that it will no longer support the XLM token, along with BCH, ETC, XRP, effective 5 December. Coinbase cited “low usage” as a reason for delisting the four coins.</p>
          <p>The SDF has also established a $100 million fund to encourage developers to create applications for the Soroban smart contract platform. Soroban adds Turing-complete smart contracts to the Stellar blockchain, allowing developers to create new financial services rails on the network.</p>
          <p>The Stellar Community Fund (SCF) has announced that 21 projects will receive funds as part of its 11th round. The required funding for the winning projects will be given to them in XLM tokens. The grants total more than 13 million XLM for the entire round.</p>
          <p>South Korea’s leading cryptocurrency exchange, Upbit, announced the temporary suspension of deposits and withdrawals of the Stellar network’s native token, XLM, and other assets on the network. Upbit stated that the event was caused by the need for maintenance of the Stellar network and the wallets in it.</p>
          <p>In March 2022, Stellar stated in its blog that it will launch Project Jump Cannon, an E&D venture to introduce native smart contracts for its blockchain. The same month, it also introduced the Starbridge project that would create bridges between Stellar and other blockchains, enabling interoperability.</p>
          <p>Since December 2022, the Stellar network has been working with the United Nations High Commissioner for Refugees (UNHCR) to provide its blockchain payment solutions to those affected by the war in Ukraine.</p>
          <p>In January 2023, Stellar (XLM) announced its decision to join the U.S. Commodity Futures Trading Commission (CFTC) Global Markets Advisory Committee (GMAC). It is important to note that the committee is composed of a diverse group of members with backgrounds in both traditional finance and cryptocurrency. As a result, Stellar’s (XLM) unique perspective on Layer 1 protocols may not carry the same weight as that of more established players in the space.</p>
          <p>If Stellar continues to adopt more of such innovations and succeeds in building a larger community, it can prove to be a significant player in the crypto market.</p>
          <p>The latest Fear & Greed Index projects a ‘neutral’ market sentiment for XLM.</p>
        </div>
      },
      {
        id: 402,
        title: 'Dogecoin sees an ugly rejection at resistance, are the bears back?',
        category: 'Crypto',
        subCategory: ['Dogecoin', 'Bears', 'Rejection'],
        description: <div className="blog-desc">
          <p>Dogecoin had been in a steady uptrend over the past week, but the sudden drop in prices came as a surprise, and millions of dollars of long positions were caught offside.</p>
        </div>,
        authorName: 'Akashnath S',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 19, 2023', 
        cover: 'https://ncx.cx/images/ncx-blog-416.jpg',
        content: <div className="blog-content">
          <p>Disclaimer: The information presented does not constitute financial, investment, trading, or other types of advice and is solely the writer’s opinion</p>
            <p>Dogecoin had been in a steady uptrend over the past week, but the sudden drop in prices came as a surprise, and millions of dollars of long positions were caught offside.</p>
            <ul>
            <li>Losses of 10% within two hours shatter the bullish structure.</li>
            <li>Capital flow was firmly directed out of the market.</li>
          </ul>
          <p>Dogecoin had been in a steady uptrend over the past week. From 12 April to 18 April, DOGE bulls managed to defend the $0.08 support zone and steadily drive prices higher to reach a local high at $0.095.</p>	
            <p>This move amounted to gains of 18.2% within a week but a good chunk of them was erased within hours on Wednesday. Bitcoin would need to defend the $28.8k support in the coming hours to shore up bullish confidence.</p>	
            <h3>The swift descent had a huge volume, but can bulls hope for a recovery?</h3>
          <p>The $0.093 resistance had been a tough opponent for the buyers in recent days. Their attempts to drive the DOGE rally higher were consistently halted. The RSI hovered between the 50 and 60 values over the past three days, showing some bullish momentum.</p>
          <p>This was swiftly undone in the hours preceding press time. The CMF had been hovering around the-0.05 mark over the past 24 hours. It slumped swiftly to -0.13 before bouncing to -0.08. Overall it showed significant capital flow out of the market.</p>
          <p>The price fell below the $0.085 level but was quick to bounce to $0.0875 at press time. The ideal scenario for the bulls is a push above the $0.088 resistance level.</p>
          <h3>“$6 million rekt,” says Coinglass, with bulls being the vast majority</h3>
          <p>The sudden drop in prices came as a surprise, and millions of dollars of long positions were caught offside. Coinglass data showed $5.94 million worth of positions were long, of the $6.06 million liquidated in the four hours before press time.</p>
          <p>The fall in prices was accompanied by a massive decline of close to $50 million in Open Interest.</p>
          <p>This indicated short-term sentiment was firmly in favor of the sellers, which was made worse by the findings from the CVD. The spot CVD chart showed selling pressure has been dominant since 14 April.</p>
        </div>
      },
      {
        id: 398,
        title: 'Ethereum drops 7% amid wider crypto sell-off as traders assess rate uncertainty',
        category: 'Crypto',
        subCategory: ['ETH', 'Ethereum', 'crypto'],
        description: <div className="blog-desc">
          <p>Ethereum dropped as much as 7% on Wednesday amid a broader crypto market sell-off as traders navigate a murky environment.</p>
        </div>,
        authorName: 'Morgan Chittum',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 19, 2023 - 7:13 PM', 
        cover: 'https://ncx.cx/images/ncx-blog-411.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
          <ul>
            <li>Ethereum fell as much as 7% and bitcoin fell 3% in under 30 minutes early Wednesday.</li>
            <li>Altcoins are taking hits too, with solana and polygon down 8% and 5%, respectively.</li>
            <li>It's unclear what led to to the sell-off, but markets have been sensitive to signs of central bank tightening.</li>
          </ul>
            <p>Ethereum dropped as much as 7% on Wednesday amid a broader crypto market sell-off as traders navigate a murky environment.</p>
            <p>Bitcoin fell almost 3% in under 30 minutes early this morning, according to Messari. Meanwhile, altcoins are taking hits too, with solana and polygon down 8% and 5%, respectively.</p>	
            <p>Although it's unclear what exactly led to the downtrend in prices, markets have been sensitive to signs of central bank tightening.</p>	
            <p>The Federal Reserve has consistently hiked rates for over a year to combat decades-high inflation, and it is widely expected to raise rates by another 25 basis points next month.</p>
          <p>Overseas, consumer price index data indicated that UK inflation remains above 10%, meaning the Bank of England may need to raise interest rates further.</p>
          <p>"After last month's shock CPI reading, today's print still shows double-digit inflation," Giles Coghlan, chief market analyst at financial services firm HYCM, told Insider in a statement. "This is a sign that inflation is not yet back on track and the longed-for decline towards pre-Covid levels is not quite there."</p>
          <p>Coghlan added: "The question investors will be asking now is, where does the Bank of England go from here?"</p>
          <p>Other experts said recent inflation data did not play in the market downturn. Rather, it was a large crypto holder, commonly known as a "whale," offloading a massive position on an exchange.</p>
          <p>"It may have been caused by a whale that sold about 16,000 BTC on Binance at the market," Timothy T. Shan, COO at decentralized exchange Dexalot, told Insider. "This subsequently triggered an overall derisking amongst longs (including levered longs) across the market."</p>
          <p>Alex Altgausen, CEO at blockchain gaming startup Banksters, pointed to Binance enabling withdraws of staked ether following Ethereum's latest software upgrade.</p>
          <p>"ETH's price decrease can be attributed to the activation of ETH unstaking on Binance. After years of waiting, people are now able to unstake and sell their profits, which is contributing to the drop in price," Altgausen told Insider.</p>
          <p>Volatility has returned to the market after months of upside for digital assets.</p>
          <p>Ethereum notched an 11-month high after its highly-anticipated Shanghai upgrade, also known as "Shapella," last week. Elsewhere, bitcoin hit a 10-month record and has soared 39% in the past three months.</p>
        </div>
      },
      {
        id: 399,
        title: 'Bitcoin rises to near US$31,000, Ether leads gains across top 10 cryptos',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Ether', 'Rises'],
        description: <div className="blog-desc">
          <p>Bitcoin continued its rally to near the US$31,000 mark in Friday afternoon trading in Asia. All other top 10 non-stablecoin cryptocurrencies also gained, led by Ethereum following the successful launch of the blockchain’s Shanghai hard fork on Wednesday. Asian equity markets moved higher following a rally in Wall Street on Thursday, on signs of cooling inflation in the U.S. and on strong Chinese exports data.</p>
        </div>,
        authorName: 'Tom Zuo',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 14, 2023 - 1:44 PM', 
        cover: 'https://ncx.cx/images/ncx-blog-412.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Bitcoin continued its rally to near the US$31,000 mark in Friday afternoon trading in Asia. All other top 10 non-stablecoin cryptocurrencies also gained, led by Ethereum following the successful launch of the blockchain’s Shanghai hard fork on Wednesday. Asian equity markets moved higher following a rally in Wall Street on Thursday, on signs of cooling inflation in the U.S. and on strong Chinese exports data.</p>
            <h3>Fast facts</h3>	
          <ul>
            <li>Bitcoin moved up 2.13% in the 24 hours to US$30,756 at 4:30 p.m. in Hong Kong, posting a weekly gain of 10.18%, according to CoinMarketCap data. The world’s largest cryptocurrency exceeded US$30,000 on Tuesday for the first time since June 2022, and is pushing US$31,000.</li>
            <li>Ethereum strengthened 8.62% to US$2,109, trading 13.64% higher for the week, shrugging off market concern that the Shanghai hard fork of the Ethereum blockchain – which allows investors to withdraw their staked Ether for the first time – could trigger a massive withdrawal of the token and lower prices.</li>
            <li>The upgrade might make Ether staking more appealing to investors, according to Chen Zhuling, co-founder and CEO of crypto staking platform RockX, in an interview with Forkast.News on Thursday.</li>
            <li>“This upgrade actually eliminates the biggest counterparty risk. In the past, you’re basically sending your ETH into a black hole and you’re hoping that indefinite time you can get your ETH back together with the rewards.” Chen said.</li>
            <li>Cardano gained 8.12% on the day to US$0.437, and added 15.27% for the week, after Cardano Foundation launched the alpha phase of Aiken on Thursday, an open-source smart contract language and toolchain to simplify smart contract developments on the Cardano blockchain</li>
            <li>The total crypto market capitalization rose 3.98% in the past 24 hours to US$1.28 trillion and total trading volume over the last 24 hours moved up 30.12% to US$58.95 billion.</li>
            <li>In the non-fungible token (NFT) market, the Forkast 500 NFT index edged up 0.76% to 4,009.92 points in the 24 hours to 4:45 p.m. in Hong Kong, adding 0.64% for the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day. It is managed by Forkast Labs data branch, CryptoSlam.</li>
            <li>Asian equities moved higher after a strong rally in the U.S. equity markets on Thursday, as Thursday’s soft readings in the U.S. producer price index and jobless claims indicated a slowdown in inflation. China on Thursday reported a 14.8% increase in its March exports, well beyond economist projections of a 7% decline, boosting investor sentiment.</li>
            <li>China’s Shanghai Composite Index moved up 0.6% and the Shenzhen Component Index rose 0.51%. Hong Kong’s Hang Seng Index gained 0.46% and Japan’s Nikkei 225 added 1.2%.</li>
            <li>European bourses also gained on Friday on the rally in equities. The STOXX 600 rose 0.46% as of 4:30 p.m. in Hong Kong, and Germany’s DAX 40 gained 0.33%.</li>
            <li>Investors are awaiting a series of U.S. economic data to be released on Friday, including U.S. retail sales that dipped 0.4% in February, with some analysts projecting a 0.5% decline in March, according to financial information provider MarketWatch.</li>
            <li>The U.S. dollar index fell 0.07% to 100.94 points following soft economic readings on Thursday. The euro inched up 0.15% to US$1.10.</li>
            <li>Gold price dipped 0.27% to US$2,034.36 per ounce on Friday, as fears of an U.S. inflation eased.</li>
          </ul>
        </div>
      },
      {
        id: 362,
        title: 'Bitcoin heads higher, Ether breaches US$2,000, U.S. equities rally on weak inflation readings',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Equities', 'US'],
        description: <div className="blog-desc">
          <p>Bitcoin moved higher in the US$30,000 band in Friday morning trading in Asia amid strong gains among the top 10 non-stablecoin cryptocurrencies. Ethereum led the winners following the blockchain’s Shanghai hard fork this week, jumping almost 10% and breaching the US$2,000 mark for the first time since August 2022. U.S. equities rallied overnight on softer readings in the U.S. producer price index (PPI) and jobless claims, adding to optimism the Federal Reserve could soon end its year-long cycle of raising interest rates to curb inflation.</p>
        </div>,
        authorName: 'Tom Zuo',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 14, 2023 - 7:08 AM', 
        cover: 'https://ncx.cx/images/ncx-blog-373.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Bitcoin moved higher in the US$30,000 band in Friday morning trading in Asia amid strong gains among the top 10 non-stablecoin cryptocurrencies. Ethereum led the winners following the blockchain’s Shanghai hard fork this week, jumping almost 10% and breaching the US$2,000 mark for the first time since August 2022. U.S. equities rallied overnight on softer readings in the U.S. producer price index (PPI) and jobless claims, adding to optimism the Federal Reserve could soon end its year-long cycle of raising interest rates to curb inflation.</p>
            <p><strong>Fast facts</strong></p>	
            <ul>
            <li>Bitcoin rose 2.54% to US$30,769 in the 24 hours to 09:00 a.m. in Hong Kong to post a weekly gain of 9.47%, according to CoinMarketCap data. The leading cryptocurrency has gained 83% so far this year as it seemed to rediscover a role as a hedge against inflation and recession and looks set to test US$31,000 as it moved within a couple of hundred dollars of that ceiling in later morning trading in Asia.</li>
            <li>Bitcoin also found support after the London Stock Exchange Group plc. on Thursday said it had partnered with GFO-X – the UK’s first regulated and centrally cleared trading venue for digital asset derivatives – to provide the country’s first trading and clearing services for Bitcoin index derivatives, according to Reuters on Friday.</li>
            <li>Ethereum jumped 9.97% to US$2,100, adding 11.66% for the week after the network’s Shanghai upgrade, which allows investors to withdraw their staked Ether for the first time. Etheruem holders have so far withdrawn around 215,000 staked Ether, while another 961,000 withdrawals are pending, according to data from Token Unlocks, which accounts for about 6% of the total staked Ether.</li>
            <li>Earlier speculation that the Shanghai upgrade may lead to a flood of selling and depress the price of the token isn’t panning out, Chen Zhuling, co-founder and CEO of crypto staking platform RockX, said in an interview on Thursday. Even if Ether are unstaked, it doesn’t necessarily mean that people are going to sell them, because they could also use it for other DeFi investments, he said.</li>
            <li>Dogecoin jumped 6.25% to US$0.0885 to hold a weekly gain of 2.79%, after multi asset trading platform eToro announced on Thursday a partnership with Elon Musk’s Twitter to enable cryptocurrency and stock trading on the world’s leading social media platform.</li>
            <li>Cardano rose 4.54% to US$0.4264, moving up 11.00% for the week. Cardano Foundation launched the alpha phase of Aiken on Thursday, an open-source smart contract language and toolchain that aims to simplify smart contract developments on the Cardano blockchain, according to the company.</li>
            <li>The total crypto market capitalization rose 3.85% in the past 24 hours to US$1.28 trillion. The total trading volume over the last 24 hours edged down 0.05% to US$48.58 billion.</li>
            <li>In the non-fungible token (NFT) market, the Forkast 500 NFT index added 0.82% to 4,017.62 in the 24 hours to 09:00 a.m. in Hong Kong, moving up 0.32% for the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day. It is managed by  CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</li>
            <li>U.S. equities closed higher on Thursday after economic data pointed to a slowdown in inflation. The Dow Jones Industrial Average added 1.14%, the S&P 500 moved up 1.33%, and the Nasdaq Composite Index rose 1.99%.</li>
            <li>U.S. PPI in March rose but at a 2.7% pace, or lower than the expected 3% to mark the smallest increase since January 2021, according to Reuters on Thursday. U.S. weekly jobless claims also pointed to a slowing economy with the figure of 239,000, higher than analysts’ expectation of 235,000, according to a Bloomberg report.</li>
            <li>Investor sentiment also got a lift from an unexpected surge in China exports in March, which jumped 14.8% from last year, well beyond the expectation of a 7% decrease, according to Reuters.</li>
            <li>U.S. interest rates are currently between 4.75% to 5%, the highest since June 2006, and analysts at the CME Group now see a 33.5% chance the Fed will not raise interest rates at its next meeting on May 3, while 66.5% predict a 25-basis-point rate hike, down from 70.4% on Thursday.</li>
            <li>U.S. stock futures traded mixed as of 9:00 a.m. in Hong Kong. The Dow Jones Industrial Average dipped 0.09%. The S&P 500 futures gained 0.05%. The Nasdaq Composite Index inched 0.02% higher.</li>
          </ul>
        </div>
      },
      {
        id: 363,
        title: 'Ether’s Post-Shanghai Rally Knocks Bitcoin Dominance From 21-Month High',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Dominance', 'Shanghai'],
        description: <div className="blog-desc">
          <p>Ether’s (ETH) fresh rally above $2,000 after the successful Shanghai upgrade late Wednesday dropped bitcoin’s (BTC) dominance from an almost two-year record high, according to TradingView data.</p>
        </div>,
        authorName: 'Krisztian Sandor',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 14, 2023 - 3:16 AM', 
        cover: 'https://ncx.cx/images/ncx-blog-374.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Ether’s (ETH) fresh rally above $2,000 after the successful Shanghai upgrade late Wednesday dropped bitcoin’s (BTC) dominance from an almost two-year record high, according to TradingView data.</p>
            <p>BTC’s dominance rate rose to as high as 49.06% early Wednesday, according to TradingView data, before retreating to 48.12% as ETH’s price rose. The last time the metric was around the 49% level happened in July 2021, some 21 months ago, TradingView shows.</p>	
            <p>ETH dominance, on the other hand, surged to 19.87% on Thursday, marking a one-month high.</p>	
            <p>The BTC dominance rate is the BTC market capitalization’s share of the total market cap of the cryptocurrency market. The metric is important to assess the relative strength of BTC, the largest cryptocurrency by market value, compared to the broader crypto market, or identify periods when altcoins outperform, also known as an altcoin season. Ether dominance similarly shows the second largest cryptocurrency’s relative value to the crypto market.</p>
          <p>Ether’s improved performance has reduced bitcoin’s share of the crypto market. The shift has come after the Ethereum network’s long-awaited tech upgrade, called Shanghai or Shapella, was deployed without a hitch late Wednesday.</p>
          <p>The upgrade enabled the withdrawal of more than 17.4 million of staked tokens, worth some $35 billion, from Ethereum’s proof-of-stake blockchain for the first time since its launch in December 2020. Its successful deployment eliminated a slight but concerning f risk that ETH investors might not be able to reclaim their tokens locked up in staking contracts..</p>
          <p>ETH rose as high as $2,023 on Thursday, gaining 5% in the past 24 hours, according to CoinDesk data, leading the upswing of the broader crypto prices.</p>
          <p>BTC, which also reacted positively to the upgrade, is only up 1.5% through the day, lagging behind altcoins.</p>
        </div>
      },
      {
        id: 400,
        title: 'Bitcoin fluctuates at US$30,000; Ether gains; Litecoin, XRP drop; U.S. equities fall on recession concern',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Ehter', 'litecoin'],
        description: <div className="blog-desc">
          <p>Bitcoin dipped but held above US$30,000 in Thursday morning trading in Asia that saw mixed performances among the top 10 non-stablecoin cryptocurrencies. Ethereum gained following the blockchain’s network upgrade on Wednesday, seemingly shrugging off concerns the upgrade could bring selling pressure on the Ether token. Solana continued to lead the winners as investors anticipate its first smartphone launch on Thursday. U.S. equities dropped on Wednesday after Federal Reserve minutes from March showed officials projecting a recession in the second half of 2023, while the U.S. consumer price index (CPI) in March indicated cooling but stubborn inflation.</p>
        </div>,
        authorName: 'Tom Zuo',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 13, 2023', 
        cover: 'https://ncx.cx/images/ncx-blog-413.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Bitcoin dipped but held above US$30,000 in Thursday morning trading in Asia that saw mixed performances among the top 10 non-stablecoin cryptocurrencies. Ethereum gained following the blockchain’s network upgrade on Wednesday, seemingly shrugging off concerns the upgrade could bring selling pressure on the Ether token. Solana continued to lead the winners as investors anticipate its first smartphone launch on Thursday. U.S. equities dropped on Wednesday after Federal Reserve minutes from March showed officials projecting a recession in the second half of 2023, while the U.S. consumer price index (CPI) in March indicated cooling but stubborn inflation.</p>
            <h3>Fast facts</h3>	
          <ul>
            <li>Bitcoin dropped 0.76% to US$30,008 in the 24 hours to 09:00 a.m. in Hong Kong, but held a weekly gain of 7.26%, according to CoinMarketCap data. The world’s largest cryptocurrency has jumped over 80% since the start of the year.</li>
            <li>Bitcoin buyers ignored renewed criticism from U.S. investor Warren Buffet in a CNBC interview on Wednesday, where he called the cryptocurrency a “gambling token” without intrinsic value.</li>
            <li>Ethereum gained 1.10% to US$1,910, adding 0.79% for the week. The Shanghai upgrade of the Ethereum blockchain activated on Wednesday and allows investors to withdraw their staked Ether for the first time. Some analysts expect the upgrade may trigger a withdrawal of nearly US$2.3 billion worth of Ether, about 6% of the total value of staked Ether, according to a Wednesday Bloomberg report.</li>
            <li>Litecoin led the losers in the top 10, dropping 2.45% to trade at US$92.23. The token is flat for the seven-day period.</li>
            <li>XRP fell 2.06% to US$0.5041, but is still up 1.08% for the week. The U.S. Securities and Exchange Commission (SEC) filed a letter on Tuesday to support its case against Ripple Labs, which it has accused of offering unregistered securities in the form of XRP. The letter quoted the SEC’s case against investment adviser Commonwealth Equity Services, which involved violation of securities laws and saw the defendant’s fair notice argument rejected by the court.</li>
            <li>Solana rose 2.49% to US$24.01, up 15.51% for the week. Solana Mobile, a subsidiary of Solana Labs, will release its first smartphone Saga on Thursday, which integrates the Solana blockchain and allows users to make on-chain transactions, manage digital assets, and access a variety of decentralized apps.</li>
            <li>The total crypto market capitalization dipped 0.52% in the past 24 hours to US$1.23 trillion. The total trading volume over the last 24 hours rose 13.33% to US$48.60 billion.</li>
            <li>In the non-fungible token (NFT) market, the Forkast 500 NFT index edged up 0.32% to 3,992.64 in the 24 hours to 09:00 a.m. in Hong Kong, but was still down 0.48% for the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day. It is managed by  CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</li>
            <li>U.S. equities closed lower on Wednesday after minutes of the Fed’s meeting in March resurfaced concerns about a recession, while Buffet in the same CNBC interview warned the U.S. banking system could face further stresses after the recent failures of a trio of lenders. The Dow Jones Industrial Average dipped 0.11%, the S&P 500 moved 0.41% lower, and the Nasdaq Composite Index dropped 0.85%.</li>
            <li>At the meeting on March 21 and 22, the Federal Reserve raised interest rates by 25 basis points and Fed officials projected the U.S. economy could enter a “mild recession” later this year. Several Fed officials considered leaving interest rates unchanged, noting the bank failures, but decided on the rate hike due to “elevated inflation.”</li>
            <li>U.S. gross domestic product is expected to rise 2.9% in the second quarter of 2023, but to drop by 0.3% in the third quarter and 0.1% in the fourth, according to data from Trading Economics.</li>
            <li>U.S. CPI released Wednesday rose 5% on year in March, down from 6% in February, and the smallest increase since May 2021. The number was lower than economists’ expectation of 5.2% and pointed to a slowing of inflation, but is still well above the Fed’s target rate of around 2%, Reuters reported on Thursday. Core CPI – excluding food and energy – moved up 5.6% on year, accelerating from February.</li>
            <li>U.S. interest rates are currently between 4.75% to 5%, the highest since June 2006. Analysts at the CME Group expect a 29.6% chance the Fed will not raise interest rates at its next meeting on May 3, while 70.4% predict a 25-basis-point rate hike, up from 66.9% on Wednesday.</li>
            <li>U.S. stock futures traded flat to lower as of 9:00 a.m. in Hong Kong. The Dow Jones Industrial Average dropped 0.12%. The S&P 500 futures dipped 0.10%. The Nasdaq Composite Index inched 0.02% lower.</li>
          </ul>
        </div>
      },
      {
        id: 364,
        title: 'Bitcoin Hits a 10-Month High. Ether Is Rallying, Too.',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'High', 'Ether'],
        description: <div className="blog-desc">
          <p>The Ethereum network successfully completed a critical upgrade late Wednesday, and has so far avoided selling pressure that could have ensued.</p>
        </div>,
        authorName: 'Jack Denton',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 13, 2023 - 6:44 PM', 
        cover: 'https://ncx.cx/images/ncx-blog-375.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>The Ethereum network successfully completed a critical upgrade late Wednesday, and has so far avoided selling pressure that could have ensued.</p>
            <p>Bitcoin BTCUSD +0.17%  and other cryptocurrencies were rising Thursday, with Ether—the second-largest digital asset—outperforming after a critical upgrade to the Ethereum blockchain network was completed.</p>	
            <p>The price of Bitcoin has risen 1% over the past 24 hours to near $30,250, with the largest digital asset trading around its highest level since last June after breaking through the key $30,000 level late Monday. The $30,000 level is psychologically important because it represents where Bitcoin stood last summer before a string of business failures across the crypto industry turned a selloff into a brutal bear market.</p>	
        </div>
      },
      {
        id: 365,
        title: 'Dogecoin Surges 5.8% as Traders Anticipate Elon Musks Next Move',
        category: 'Crypto',
        subCategory: ['Dogecoin', 'Trade', 'Elon Musks'],
        description: <div className="blog-desc">
          <p>Dogecoin (DOGE) has risen from Wednesday's low of 8 cents with a 5.89% move to the upside after Twitter struck a deal with eToro to offer crypto trading.</p>
        </div>,
        authorName: 'Oliver Knight',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 13, 2023 - 2:39 PM', 
        cover: 'https://ncx.cx/images/ncx-blog-376.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Dogecoin (DOGE) has risen from Wednesday's low of 8 cents with a 5.89% move to the upside after Twitter struck a deal with eToro to offer crypto trading.</p>
            <p>Twitter CEO Elon Musk has often touted dogecoin as one of his favorite cryptocurrencies, dedicating a segment to it on an appearance on "Saturday Night Live" in 2021. Twitter also briefly changed its logo to the dogecoin logo last week to spur a rally that gained as much as 37% for some traders.</p>	
            <p>Before today's rise, the popular meme coin had slumped by 22.64% as the hype surrounding Twitter's logo change subsided.</p>	
            <p>It is currently trading at nearly 9 cents after prominent Crypto Twitter trader @TreeofAlpha tweeted a macro thesis for dogecoin.</p>
          <p>"It is becoming increasingly harder to not accumulate doge at those prices as it fully retraced the logo change, Elon will integrate eToro trading on Twitter, market is looking healthy, 4/20 is coming and the market showed how low it was willing to go on it during the peak of bear ($0.06c)," Tree of Alpha tweeted moments before another spike to the upside.</p>
          <p>It is worth noting that eToro currently does not offer dogecoin, so in the immediate future only the likes of bitcoin (BTC), ether (ETH), BNB and IOTA will be initially available on Twitter.</p>
        </div>
      },
      {
        id: 366,
        title: 'Bitcoin inches up, remains above US$30,000; Ether gains after Shapella upgrade',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Ether', 'Shapella'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ether rose during Asian trading hours on Thursday, along with all of the top 10 non-stablecoin cryptocurrencies by market capitalization. Ether rose the most, following the successful completion of the Shapella upgrade, despite initial market concerns over sell pressure caused by the enabling of staking withdrawals. Asian equity markets closed mixed despite Wall Street’s overnight rally, as investors digested cooler-than-expected U.S. inflation data. The U.S. dollar continued its decline, while the euro and gold values strengthened.</p>
        </div>,
        authorName: 'Zoltan Vardai',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 13, 2023 - 2:25 PM', 
        cover: 'https://ncx.cx/images/ncx-blog-377.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Bitcoin and Ether rose during Asian trading hours on Thursday, along with all of the top 10 non-stablecoin cryptocurrencies by market capitalization. Ether rose the most, following the successful completion of the Shapella upgrade, despite initial market concerns over sell pressure caused by the enabling of staking withdrawals. Asian equity markets closed mixed despite Wall Street’s overnight rally, as investors digested cooler-than-expected U.S. inflation data. The U.S. dollar continued its decline, while the euro and gold values strengthened.</p>
            <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin rose 0.33% to US$30,100 in the 24 hours to 4:30 p.m. in Hong Kong. The world’s first cryptocurrency strengthened by 7.87% during the week, after the network exceeded 1 million Ordinals inscriptions earlier this week.</li>
            <li>Ether rose 3.02% as the biggest gainer of the day, to trade at US$1,927 while rising 2.73% during the week. It remained firmly above the US$1,900 mark after the successful completion of the Shanghai upgrade, despite initial market concerns that the enabling of staking withdrawals will create significant sell pressure.</li>
            <li>Cardano’s ADA, the day’s second-biggest winner, rose 2.3% to US$0.40 and gained 5.11% on the week.</li>
            <li>The global cryptocurrency market capitalization increased by 0.81% to US$1.23 trillion in the 24 hours to 4:30 p.m. in Hong Kong, while total crypto market trading volume increased by 10.13% to US$45.29 billion.</li>
            <li>The Forkast 500 NFT index inched up 0.03% to 3,980.79 points during the day and slid 0.53% during the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day.</li>
            <li>Asian equities were mixed following a 0.41% drop in the S&P 500, despite cooler-than-expected U.S. inflation data, showing that inflation rose 5% on the year in March, but declined from 6% in February, representing the smallest increase since May 2021. Despite persistent inflation, investors are increasingly optimistic that the Federal Reserve’s tightening cycle will end after May’s interest rate hike.</li>
            <li>Japan’s Nikkei 225 rose 0.26%, the Shanghai Composite fell 0.27%, the Shenzhen Component lost 1.21% and Hong Kong’s Hang Seng index ended the day 0.17% higher.</li>
            <li>European bourses strengthened to a one-month high on Thursday, as the pan-European STOXX 600 rose 0.23% to 463 points and Germany’s DAX 40 inched up 0.25% to 15,742 points. France’s benchmark CAC 40 rose 0.93% to an all-time high of 7.466 points.</li>
            <li>Shares of LVMH, the world’s largest luxury brand, rose 4.54% during the day to a new all-time high of €875, after reporting a 17% rise in Q1 sales, doubling analyst expectations.</li>
            <li>The U.S. dollar fell 0.24% to 101.5, trading close to its lowest levels since early February, while the euro gained 0.33% to stabilize above US$1.10.</li>
            <li>Gold strengthened 0.67% to US$2,028 per ounce, trading close to a one-year high, as growing fears of a recession continued bolstering demand for the precious metal.</li>
          </ul>
        </div>
      },
      {
        id: 367,
        title: 'Bitcoin fluctuates at US$30,000; Ether gains; Litecoin, XRP drop; U.S. equities fall on recession concern',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Ether', 'Litecoin'],
        description: <div className="blog-desc">
          <p>Bitcoin dipped but held above US$30,000 in Thursday morning trading in Asia that saw mixed performances among the top 10 non-stablecoin cryptocurrencies. Ethereum gained following the blockchain’s network upgrade on Wednesday, seemingly shrugging off concerns the upgrade could bring selling pressure on the Ether token. Solana continued to lead the winners as investors anticipate its first smartphone launch on Thursday. U.S. equities dropped on Wednesday after Federal Reserve minutes from March showed officials projecting a recession in the second half of 2023, while the U.S. consumer price index (CPI) in March indicated cooling but stubborn inflation.</p>
        </div>,
        authorName: 'Tom Zuo',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 13, 2023 - 7:45 AM', 
        cover: 'https://ncx.cx/images/ncx-blog-378.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Bitcoin dipped but held above US$30,000 in Thursday morning trading in Asia that saw mixed performances among the top 10 non-stablecoin cryptocurrencies. Ethereum gained following the blockchain’s network upgrade on Wednesday, seemingly shrugging off concerns the upgrade could bring selling pressure on the Ether token. Solana continued to lead the winners as investors anticipate its first smartphone launch on Thursday. U.S. equities dropped on Wednesday after Federal Reserve minutes from March showed officials projecting a recession in the second half of 2023, while the U.S. consumer price index (CPI) in March indicated cooling but stubborn inflation.</p>
            <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin dropped 0.76% to US$30,008 in the 24 hours to 09:00 a.m. in Hong Kong, but held a weekly gain of 7.26%, according to CoinMarketCap data. The world’s largest cryptocurrency has jumped over 80% since the start of the year.</li>
            <li>Bitcoin buyers ignored renewed criticism from U.S. investor Warren Buffet in a CNBC interview on Wednesday, where he called the cryptocurrency a “gambling token” without intrinsic value.</li>
            <li>Ethereum gained 1.10% to US$1,910, adding 0.79% for the week. The Shanghai upgrade of the Ethereum blockchain activated on Wednesday and allows investors to withdraw their staked Ether for the first time. Some analysts expect the upgrade may trigger a withdrawal of nearly US$2.3 billion worth of Ether, about 6% of the total value of staked Ether, according to a Wednesday Bloomberg report.</li>
            <li>Litecoin led the losers in the top 10, dropping 2.45% to trade at US$92.23. The token is flat for the seven-day period.</li>
            <li>XRP fell 2.06% to US$0.5041, but is still up 1.08% for the week. The U.S. Securities and Exchange Commission (SEC) filed a letter on Tuesday to support its case against Ripple Labs, which it has accused of offering unregistered securities in the form of XRP. The letter quoted the SEC’s case against investment adviser Commonwealth Equity Services, which involved violation of securities laws and saw the defendant’s fair notice argument rejected by the court.</li>
            <li>Solana rose 2.49% to US$24.01, up 15.51% for the week. Solana Mobile, a subsidiary of Solana Labs, will release its first smartphone Saga on Thursday, which integrates the Solana blockchain and allows users to make on-chain transactions, manage digital assets, and access a variety of decentralized apps.</li>
            <li>The total crypto market capitalization dipped 0.52% in the past 24 hours to US$1.23 trillion. The total trading volume over the last 24 hours rose 13.33% to US$48.60 billion.</li>
            <li>In the non-fungible token (NFT) market, the Forkast 500 NFT index edged up 0.32% to 3,992.64 in the 24 hours to 09:00 a.m. in Hong Kong, but was still down 0.48% for the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day. It is managed by  CryptoSlam, a sister company of Forkast.News under the Forkast.Labs umbrella.</li>
            <li>U.S. equities closed lower on Wednesday after minutes of the Fed’s meeting in March resurfaced concerns about a recession, while Buffet in the same CNBC interview warned the U.S. banking system could face further stresses after the recent failures of a trio of lenders. The Dow Jones Industrial Average dipped 0.11%, the S&P 500 moved 0.41% lower, and the Nasdaq Composite Index dropped 0.85%.</li>
            <li>At the meeting on March 21 and 22, the Federal Reserve raised interest rates by 25 basis points and Fed officials projected the U.S. economy could enter a “mild recession” later this year. Several Fed officials considered leaving interest rates unchanged, noting the bank failures, but decided on the rate hike due to “elevated inflation.”</li>
            <li>U.S. gross domestic product is expected to rise 2.9% in the second quarter of 2023, but to drop by 0.3% in the third quarter and 0.1% in the fourth, according to data from Trading Economics.</li>
            <li>U.S. CPI released Wednesday rose 5% on year in March, down from 6% in February, and the smallest increase since May 2021. The number was lower than economists’ expectation of 5.2% and pointed to a slowing of inflation, but is still well above the Fed’s target rate of around 2%, Reuters reported on Thursday. Core CPI – excluding food and energy – moved up 5.6% on year, accelerating from February.</li>
            <li>U.S. interest rates are currently between 4.75% to 5%, the highest since June 2006. Analysts at the CME Group expect a 29.6% chance the Fed will not raise interest rates at its next meeting on May 3, while 70.4% predict a 25-basis-point rate hike, up from 66.9% on Wednesday.</li>
            <li>U.S. stock futures traded flat to lower as of 9:00 a.m. in Hong Kong. The Dow Jones Industrial Average dropped 0.12%. The S&P 500 futures dipped 0.10%. The Nasdaq Composite Index inched 0.02% lower.</li>
          </ul>
        </div>
      },
      {
        id: 368,
        title: 'Ether-Bitcoin Ratio Likely to See Deeper Decline After Shapella Upgrade: QCP Capital',
        category: 'Crypto',
        subCategory: ['Ether', 'Bitcoin', 'Shapella'],
        description: <div className="blog-desc">
          <p>Singapore-based crypto options trading giant QCP Capital expects a deeper decline in the ether-bitcoin (ETH/BTC) ratio following the impending Shapella hard fork, dubbed Shanghai upgrade.</p>
        </div>,
        authorName: 'Omkar Godbole',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 12, 2023 - 4:28 PM', 
        cover: 'https://ncx.cx/images/ncx-blog-379.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Singapore-based crypto options trading giant QCP Capital expects a deeper decline in the ether-bitcoin (ETH/BTC) ratio following the impending Shapella hard fork, dubbed Shanghai upgrade.</p>
            <p>"ETH/BTC has broken through the key support level of 0.658 and can potentially head back to 0.0553, as continued and sustained spot selling pressure in thin markets for days after Shapella leads to further bearish price action on ETH," QCP Capital's market insights team told CoinDesk.</p>	
            <p>The ETH/BTC ratio has declined 13.7% this year amid lingering fears that investors will rush to liquidate coins after Shapella opens withdrawals of staked ether.</p>	
            <p>According to some analysts, the selling pressure will be distributed over several days, allowing buyers to absorb selling pressure and keep prices steady. QCP, suggests otherwise.</p>
          <p>"We fail to see what the bullish case can be for this event as those at the front of the queue [in withdrawals] are likely to sell spot, while those further back will be hedging via perps/futures if they have not already done so," QCP noted.</p>
          <p>Users cannot withdraw the entire stack of over 18 million staked ether immediately after the upgrade. However, just over 1 million ETH earned in staking rewards can be pulled out immediately. Troubled entities like crypto lender Celsius might sell its staked ether balance of 158,176 ETH to recover at least a portion of creditors' funds, according to K33 Research.</p>
        </div>
      },
      {
        id: 369,
        title: 'Bitcoin slips but remains above US$30,000 ahead of U.S. inflation data',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'US', 'Inflation'],
        description: <div className="blog-desc">
          <p>Bitcoin slipped in Wednesday afternoon trade in Asia but remained above the US$30,000 mark. Ether prices sank, along with all top 10 non-stablecoin cryptocurrencies by market capitalization, with Cardano and Dogecoin as the biggest losers. Asian equity markets traded mixed as investors were cautious ahead of U.S. inflation data later on Wednesday that will likely determine interest rate decisions next month.</p>
        </div>,
        authorName: 'Pradipta Mukherjee',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 12, 2023 - 2:34 PM', 
        cover: 'https://ncx.cx/images/ncx-blog-380.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Bitcoin slipped in Wednesday afternoon trade in Asia but remained above the US$30,000 mark. Ether prices sank, along with all top 10 non-stablecoin cryptocurrencies by market capitalization, with Cardano and Dogecoin as the biggest losers. Asian equity markets traded mixed as investors were cautious ahead of U.S. inflation data later on Wednesday that will likely determine interest rate decisions next month.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin, the world’s biggest cryptocurrency by market capitalization, dropped 0.4% to US$30,003 in 24 hours to 4 p.m. in Hong Kong, but strengthened 5.16% on the week, according to CoinMarketCap data.</li>
            <li>Ethereum lost 2.68% to US$1,871, while posting a weekly loss of 2.05%. Ethereum’s decline came ahead of the blockchain’s Shanghai hard fork, also called the Shapella upgrade, scheduled for Thursday morning Hong Kong time which will allow withdrawal of staked Ether for the first time.</li>
            <li>Cardano’s ADA token was the biggest loser among top 10 cryptos, dropping 3.89% in 24 hours to US$0.395.</li>
            <li>Dogecoin was the second-biggest loser of the day, falling 3.83% to US$0.08217 and losing 15.16% on the week. The memecoin’s supporter Elon Musk, also Twitter’s chief, tweeted “X” on Tuesday amid speculation on his plans for the social media platform after reports of its merger with a newly formed shell company called X Corp.</li>
            <li>The global crypto market capitalization sank 1.38% to US$1.22 trillion, while the total crypto market volume declined 15.01% to US$41.29 billion in the last 24 hours.</li>
            <li>The Forkast 500 NFT index rose 1.2% to 3,985.99 points during the day and fell 1.12% during the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day.</li>
            <li>Asian equity markets were mixed ahead of U.S. March inflation data due for release later on Wednesday, which is expected to determine interest rates and the direction of the economy. The March Consumer Price Index is expected to slow to 5.2%, the slowest since May 2021 and a decline from 6% annual gain in February.</li>
            <li>“Investors are increasingly concerned that the Fed’s overtightening now – when monetary policy time lags are notoriously long – could steer the U.S. economy into a recession,” said Nigel Green, chief executive of financial advisory firm deVere Group.</li>
            <li>The Shanghai Composite rose 0.41% while the Shenzhen Component Index gained 0.05%. Hong Kong’s Hang Seng Index dipped 0.86% and Japan’s Nikkei 225 strengthened 0.57%.</li>
            <li>European bourses hovered near one-month highs, as the benchmark STOXX 600 rose 0.25%, Germany’s DAX 40 inched up 0.43% and France’s CAC 40 gained 0.36% to an all-time high of 7,418 points, extending gains for a third consecutive day.</li>
            <li>The U.S. dollar index slid 0.01% to remain above 102, while the euro strengthened 0.1% to US$1.09.</li>
            <li>Gold mostly traded flat around US$2,009 per ounce, after briefly hitting US$2,021 earlier in the day. The precious metal saw upside momentum for the second consecutive day as investors were cautious ahead of the U.S. inflation report.</li>
          </ul>
        </div>
      },
      {
        id: 372,
        title: 'Bitcoin crosses $30,000 for first time in 2023',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Rise', '2023'],
        description: <div className="blog-desc">
          <p>Bitcoin (BTC-USD) crossed $30,000 Tuesday, reaching the highest level for the world's largest cryptocurrency in 10 months.</p>
        </div>,
        authorName: 'David Hollerith',
        authorurl: 'https://www.yahoo.com/author/david-hollerith',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 11, 2023 - 4:51 PM', 
        cover: 'https://ncx.cx/images/ncx-blog-383.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Bitcoin (BTC-USD) crossed $30,000 Tuesday, reaching the highest level for the world's largest cryptocurrency in 10 months.</p>
            <p>It briefly touched $30,301 around 2:20 AM ET Tuesday before easing later in the morning. By 8:00 AM ET, bitcoin was changing hands at $30,027, up 6.2% for the last 24 hours. The last time the digital currency was this high was June 6, 2022.</p>	
            <p>The bitcoin rally buoyed other crypto assets and companies Tuesday, including the stock of cryptocurrency exchange Coinbase Global (COIN) and the price of the world's second-largest cryptocurrency, Ether (ETH-USD), as well as the stocks of bitcoin mining companies Marathon Digital (MARA) and Riot (RIOT).</p>	
            <p>Bitcoin still has a ways to go to reach the heights it scaled during its peak. It still trades 23% below where it stood one year ago.</p>
          <p>The rapid climb of digital currencies is one of this year's big surprises in markets after a crash in 2022 that cost investors billions as higher interest rates and inflation lowered the value and appeal of risky assets. Several crypto players filed for bankruptcy, including FTX in November. FTX's former CEO Sam Bankman-Fried now faces criminal charges that he stole billions of FTX customer funds and misled investors.</p>
          <p>"It's a bounce back from the really poor performance we saw in 2022," VettaFi research head Todd Rosenbluth told Yahoo Finance.</p>
          <p>What gave the market added momentum this year was a banking crisis in March that took down three lenders, including two that specifically served cryptocurrency customers.</p>
          <p>Since March 10, the day regulators seized Silicon Valley Bank in the second-largest bank failure of U.S. history, bitcoin has climbed $9,500 higher for a more than 30% rise. Ether (ETH-USD) is up more than 29%. According to Coinmarketcap, the total value for all crypto assets is up more than 24% during that period, as of Tuesday at 8:00 a.m. ET.</p>
          <p>Some investors chose to seek out bitcoin as a safe haven from the perceived instability of banking. Others anticipated the Fed might have to cut interest rates in response to the crisis, sparking more flows into alternative risk assets.</p>
          <p>However, the banking crisis also has significantly restrained liquidity in the crypto market according to data collected from Kaiko Research. Because investors now have less banking partners to assist in moving funds from dollars to cryptocurrencies and vice versa, analysts have argued it is much easier to manipulate bitcoin’s price with a large order.</p>
          <p>Crypto’s 2023 comeback unfolds even as Washington regulators step up their efforts to rein in the market.</p>
          <p>The Securities and Exchange Commission has issued 11 enforcement actions since the beginning of January against crypto firms and individuals, while serving formal letters to Paxos, DeFi exchange Sushi, and Coinbase Global warning the agency plans to bring an enforcement action. Coinbase is the largest U.S. crypto exchange.</p>
          <p>Coinbase shares have rebounded 86% year to date but are still suppressed by two thirds below their value a year ago. Marathon Digital and Riot have both more than doubled since the beginning of 2023 but still trade 60% and 45% below their price a year ago.</p>
          <p>Another regulator, the Commodities and Futures Trading Commission, also has sued crypto exchange Binance and its CEO Changpeng Zhao for allegedly selling derivatives backed by digital assets to U.S. customers despite not being registered to do so.</p>
        </div>
      },
      {
        id: 370,
        title: 'Tron’s TRX Drops 6% as Binance.US Delists the Token',
        category: 'Crypto',
        subCategory: ['Tron', 'TRX', 'Binance'],
        description: <div className="blog-desc">
          <p>This comes weeks after the U.S. Securities and Exchange Commission sued Tron's Justin Sun over allegations of selling and airdropping unregistered securities, fraud and market manipulation involving the TRX token.</p>
        </div>,
        authorName: 'Sam Reynolds',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 12, 2023 - 7:08 AM', 
        cover: 'https://ncx.cx/images/ncx-blog-381.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
         <p>Tron’s TRX token has been delisted on Binance.US.</p>
            <p>This comes weeks after the U.S. Securities and Exchange Commission sued Tron's Justin Sun over allegations of selling and airdropping unregistered securities, fraud and market manipulation involving the TRX token.</p>
            <p>TRX dropped 6.4% on the news, according to CoinDesk market data.</p>	
            <p>Binance.US says trading for TRX will end on April 18.</p>	
            <p>"We operate in a rapidly evolving industry and our digital asset monitoring process is designed to be responsive to market and regulatory developments. When a digital asset no longer meets our high standards, or industry circumstances change, we conduct a more in-depth review of the affected asset and assess whether further action is necessary," Binance.US said on its page announcing the delisting.</p>
          <p>A spokesperson for Tron said it is in communication with Binance.US over this and has no further comment at this time.</p>
        </div>
      },
      {
        id: 371,
        title: 'Bitcoin maintains US$30,000, Solana jumps, U.S. equities trade mixed',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Solana', 'equities'],
        description: <div className="blog-desc">
          <p>Bitcoin continued trading above US$30,000 in Wednesday morning trading in Asia, while most other top 10 non-stablecoin cryptocurrencies moved higher. Ethereum dipped ahead of the Shanghai hard fork as some investors expect massive withdrawals of staked Ether as Solana led the winners ahead of its first smartphone launch on Thursday. U.S. equities closed mixed on Tuesday as investors wait for the U.S. consumer price index (CPI) in March, which will provide insight into inflation and the Federal Reserve’s next move on interest rates.</p>
        </div>,
        authorName: 'Tom Zuo',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 12, 2023 - 5:51 AM', 
        cover: 'https://ncx.cx/images/ncx-blog-382.jpg',
        content: <div className="blog-content">
          <p></p>
        <h3>In this article:</h3>
        <p>Bitcoin continued trading above US$30,000 in Wednesday morning trading in Asia, while most other top 10 non-stablecoin cryptocurrencies moved higher. Ethereum dipped ahead of the Shanghai hard fork as some investors expect massive withdrawals of staked Ether as Solana led the winners ahead of its first smartphone launch on Thursday. U.S. equities closed mixed on Tuesday as investors wait for the U.S. consumer price index (CPI) in March, which will provide insight into inflation and the Federal Reserve’s next move on interest rates.</p>
        <h3>Fast facts</h3>
      <ul>
        <li>Bitcoin moved up 1.33% in the 24 hours to US$30,256 at 09:00 a.m. in Hong Kong, holding a weekly gain of 5.51%, according to CoinMarketCap data. The world’s largest cryptocurrency breached the US$30,000 mark on Tuesday and reached US$30,509 early Wednesday, the highest price since June 2022. Investors bet the U.S. CPI data on Wednesday would allow the Fed to revise its monetary tightening policies, which can hint that the end of rate hikes is around the corner, according to a Reuters report on Wednesday.</li>
        <li>Ethereum dipped 1.37% to US$1,890, trading 1.23% lower for the week. The Shanghai hard fork of the Ethereum blockchain, also known as the Shapella upgrade, will come on Wednesday (Thursday morning in Hong Kong). The hard fork includes an upgrade that allows investors to withdraw their staked Ether for the first time.</li>
        <li>Solana jumped 11.98% to US$23.44 and added 9.95% for the week. Solana Mobile, a subsidiary of Solana Labs, is set to release its first smartphone Saga on Thursday, which has integrated the Solana blockchain, allowing users to make on-chain transactions, manage digital assets, and access a variety of decentralized apps.</li>
        <li>The total crypto market capitalization rose 0.70% in the past 24 hours to US$1.24 trillion. The total trading volume over the last 24 hours moved up 5.10% to US$43.63 billion.</li>
        <li>In the non-fungible token (NFT) market, the Forkast 500 NFT index rose 1.27% to 3992.14 in the 24 hours to 09:00 a.m. in Hong Kong, but was still down 1.04% for the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day. It is managed by Forkast Labs data branch, CryptoSlam.</li>
        <li>U.S. equities closed mixed on Tuesday as investors waited for the U.S. March CPI on Wednesday. The Dow Jones Industrial Average added 0.29%, the S&P 500 changed little, and the Nasdaq Composite Index dropped 0.43%.</li>
        <li>Analysts expect the U.S. CPI rose 5.1% by year in March, down from 6% in February but still well above the Fed’s goal to curb inflation below 2%, according to Bloomberg on Wednesday. Some investors are no longer eyeing the possibility of another 25-basis-point rate hike in the U.S. in May, but for indicators of lower rates in the second half of 2023, according to a Reuters report on Wednesday.</li>
        <li>The U.S. employment situation summary released last Friday showed a mixed picture. While the employment rate dropped to 3.5% in March, lower than the 3.6% forecast by analysts, employers only added 236,000 nonfarm payrolls, the smallest increase since December 2020, indicating a slowdown in the economy.</li>
        <li>With the mixed information, analysts at the CME Group expect a 33.1% chance the Fed will not raise interest rates at its next meeting on May 3, while 66.9% predict a 25-basis-point rate hike, down from 71.3% on Tuesday. U.S. interest rates are currently between 4.75% to 5%, the highest since June 2006.</li>
        <li>U.S. stock futures edged higher as of 9:00 a.m. in Hong Kong. The Dow Jones Industrial Average added 0.06%. The S&P 500 futures edged up 0.05%. The Nasdaq Composite Index gained 0.06% higher.</li>
      </ul>
        </div>
      },
      {
        id: 334,
        title: 'Crypto Traders Eye Inflation Data to Get Bitcoin Rally Back on Track',
        category: 'Crypto',
        subCategory: ['Trader', 'Inflation', 'Bitcoin'],
        description: <div className="blog-desc">
          <p>Data on consumer prices and producer prices due this week could be the catalysts to shake Bitcoin out of its holding pattern.</p>
        </div>,
        authorName: 'Jack Denton',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 10, 2023 - 2:49 PM',
        cover: 'https://ncx.cx/images/ncx-blog-345.jpg',
        content: <div className="blog-content">
          <p>Data on consumer prices and producer prices due this week could be the catalysts to shake Bitcoin out of its holding pattern.</p>
          <p>Bitcoin BTCUSD +2.78%  and other cryptocurrencies edged higher Monday, remaining at some of the highest levels for digital assets since last summer, as traders eye inflation data in the coming days as possible catalysts for the next leg upward.</p>
          <p>The price of Bitcoin has risen 1.5% over the past 24 hours to $28,325. The largest digital asset has been hovering around $28,000 for weeks now, spiking as high as near $29,500 amid bouts of enthusiasm—representing the highest point since the crypto crash accelerated into a brutal bear market last June. Up some 70% so far this year, Bitcoin’s rally has spurred calls for a new crypto bull market.</p>
        </div>
      },
      {
        id: 332,
        title: 'Hong Kong financial secretary eyes Web3 push despite market fluctuations',
        category: 'Crypto',
        subCategory: ['Hong Kong', 'Web3', 'Financial'],
        description: <div className="blog-desc">
          <p>Hong Kong’s financial secretary, Paul Chan, said it is the right time for the city to push for Web3 despite market fluctuations.</p>
        </div>,
        authorName: '',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 10, 2023 - 12:52 PM',
        cover: 'https://ncx.cx/images/ncx-blog-343.jpg',
        content: <div className="blog-content">
          <p>Hong Kong’s financial secretary, Paul Chan, said it is the right time for the city to push for Web3 despite market fluctuations.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>He said that Hong Kong has the potential to become a key player in the emerging digital economy and is well-positioned to tap into the opportunities presented by Web3. He made these remarks in a blog post published Sunday on the Financial Secretary’s Office website.</li>
            <li>Despite the volatility in the cryptocurrency market, Hong Kong should not miss the opportunity to invest in Web3, Chan said.</li>
            <li>Web3 technologies such as blockchain, smart contracts, and decentralized finance can potentially transform various sectors, including finance, supply chain management, and social governance.</li>
            <li>Chan said the next development stage would be on real-world blockchain applications and related technologies that can benefit the economy and existing business models.</li>
            <li>China banned cryptocurrency transactions in 2021, but Hong Kong has set up a new licensing regime to take effect in June. The regime may eventually extend to retail crypto trading, which has been limited in the special administrative region.</li>
            <li>The financial secretary said in January that the city will continue pushing ahead with the development of Web3 technologies to become a regional hub for the industry.</li>
            <li>Chan’s blog post precedes a busy week for the Web3 industry in the region. Hong Kong Web3 Festival, an industry gathering, is scheduled to kick off on Wednesday, and the Hong Kong Government will be hosting its two-day Digital Economy Summit from Thursday.</li>
          </ul>
        </div>
      },
      {
        id: 331,
        title: 'Bitcoin Faces Low Risk of Liquidations-Induced Price Volatility After 70% Surge',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Risk', 'Liquidations'],
        description: <div className="blog-desc">
          <p>Bitcoin (BTC) has surged 70% this year, hitting nine-month highs of over $29,000. While the sharp rally has brought the derivatives market back to life, the overall use of leverage remains muted, suggesting a low risk of "liquidations-induced" wild price swings.</p>
        </div>,
        authorName: 'Omkar Godbole',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 10, 2023 - 12:16 PM',
        cover: 'https://ncx.cx/images/ncx-blog-342.jpg',
        content: <div className="blog-content">
          <h3>In this article:</h3>
          <p>Bitcoin (BTC) has surged 70% this year, hitting nine-month highs of over $29,000. While the sharp rally has brought the derivatives market back to life, the overall use of leverage remains muted, suggesting a low risk of "liquidations-induced" wild price swings.</p>
          <p>Liquidations refer to the forced closure of bullish long and bearish short positions in leveraged perpetual futures markets, which allow traders to open positions worth much more than the money deposited as margin. The forced closure for cash or cash equivalent happens when the trading entity fails to meet the margin shortage stemming from the market moving against its bullish or bearish bet.</p>
          <p>When the degree of leverage in the market – measured by the ratio between the dollar value locked in perpetual futures (open interest) and the cryptocurrency's market capitalization – is high, short liquidations tend to exacerbate bullish moves. That, in turn, shakes out more shorts, leading to a short squeeze. Similarly, long liquidations exacerbate bearish moves, leading to a long squeeze.</p>
          <p>Long/short squeezes were quite common during the 2021 bull run and early bear market days of 2022 when the amount of leverage outstanding relative to the size of the market was quite high and price moves would shake out billions of dollars' worth of leveraged trading positions. So far this year, the ratio has continued to drop.</p>
          <p>"High open interest relative to market cap means the market could be vulnerable to a short-squeeze or liquidation cascade, which would result in a price swing being more volatile than it otherwise would have been due to forced buying or selling, respectively," analysts at Blockware Solutions said in a weekly newsletter.</p>
          <p>"The medium-term trend of decreasing open interest/market cap has not been broken, which is reassurance that, even in the event of downward volatility, price is most likely not going to decrease to the level it was at to begin the year," the analysts added.</p>
          <p>The perpetual futures open interest to market ratio has been falling since FTX, which was once the third-largest cryptocurrency exchange in the world and one of the preferred avenues to trade perpetual futures, went bust in early November.</p>
          <p>The ratio has stayed low despite the recent price consolidation, a sign of investors' low appetite for risk, according to Blockware Solutions.</p>
          <p>"BTC has essentially traded sideways for the past three weeks, yet, we haven’t seen a build-up in open interest. This is a signal that the market is still in a risk-off mode," Blockware's analysts noted, saying the non-expiring perpetual futures are typically in demand during periods of sideways price action, as seen ahead of FTX's implosion.</p>
          <p>Bitcoin has been locked in the narrow range of $29,000 to $27,000 since March 21, according to CoinDesk data.</p>
        </div>
      },
      {
        id: 333,
        title: 'Bitcoin resumes rise, Ether gains ahead of upgrade, Dogecoin makes up some lost ground',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Rise', 'Dogecoin'],
        description: <div className="blog-desc">
          <p>Bitcoin resumed its rise above US$28,000 in Monday morning trading in Asia, a support level it has fluctuated around for most of April after a strong start to the year even amid higher interest rates, bank failures, and concerns about a recession in the U.S. Most other top 10 non-stablecoin cryptocurrencies gained, including Ether ahead of Wednesday’s upgrade to the Ethereum blockchain. Dogecoin rose. U.S. stock futures were mixed after a three day weekend.</p>
        </div>,
        authorName: '',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 10, 2023 - 6:47 AM',
        cover: 'https://ncx.cx/images/ncx-blog-344.jpg',
        content: <div className="blog-content">
          <p>In this article:</p>
          <p>Bitcoin resumed its rise above US$28,000 in Monday morning trading in Asia, a support level it has fluctuated around for most of April after a strong start to the year even amid higher interest rates, bank failures, and concerns about a recession in the U.S. Most other top 10 non-stablecoin cryptocurrencies gained, including Ether ahead of Wednesday’s upgrade to the Ethereum blockchain. Dogecoin rose. U.S. stock futures were mixed after a three day weekend.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin added 1.40% to US$28,343 in the 24 hours to 8:00 a.m. in Hong Kong, up 0.57% for the week or little changed, according to CoinMarketCap data.</li>
            <li>The world’s biggest cryptocurrency has risen 70% so far this year and is holding its ground around US$28,000 this month even as equity markets have faced headwinds from higher interest rates to stem inflation, a series of bank failures, and signs the U.S. economy may be headed for a recession.</li>
            <li>Bitcoin could break through US$30,000 in the coming week but the caution is that a recession may be looming, said Kadan Stadelmann, chief technical officer of blockchain infrastructure development firm Komodo.</li>
            <li>“Normally, we see risk on conditions as something that drives up the Bitcoin price. But, over the last month with the banking crisis, we’ve actually seen risk off be positive for Bitcoin. Right now, the price action is telling us the market is undecided,” said Stadelmann.</li>
            <li>Ether edged up 0.55% to US$1,859, a gain of 3.62% for the week ahead of  Wednesday’s Shanghai hardfork, also known as the Shapella upgrade, which will allow investors to withdraw their staked Ether for the first time.</li>
            <li>Most other top 10 cryptocurrencies by market capitalization showed moderate gains led by Dogecoin, which added 2.31% to US$0.08328 for a weekly rise of 5.30%. The meme token surged last week when Elon Musk changed Twitter’s blue bird icon to the Dogecoin dog icon, breaching US$0.10 on April 4. It then lost steam as the Twitter icon shifted back to the bird.</li>
            <li>Solana added 1.51% to US$20.33, but showed a weekly drop of 1%. Polkadot rose 1.29% to US$6.22 but similarly lost 0.87% over the past seven days.</li>
            <li>The total crypto market capitalization gained 0.95% in the past 24 hours to US$1.19 trillion. Total trading volume in the same period rose 8.19% to US$29.46 billion.</li>
            <li>In the non-fungible token (NFT) market, the Forkast 500 NFT index rose 0.12% to 3,968.42 in the 24 hours to 08:00 a.m. in Hong Kong, losing 2.34% for the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day. It is managed by Forkast Labs data branch, CryptoSlam.</li>
            <li>U.S. stock futures traded mixed as of 8:00 a.m. in Hong Kong. The Dow Jones Industrial Average futures rose 0.14%, S&P 500 futures moved up 0.091% and the Nasdaq-100 futures Index dropped 0.10%.</li>
            <li>The U.S. unemployment rate, released last Friday, fell to 3.5% in March compared to 3.6% in February. The U.S. saw 236,000 jobs created in March, slightly below expectations but still pointing to a strong labor market, according to TradingEconomics.</li>
            <li>However, several U.S. indicators released earlier last week pointed to a cooling economy. Job openings which fell to 9.9 million in February, the lowest since May 2021. The U.S. manufacturing purchasing managers’ index in March also signaled a possible recession, falling to 46.3%, down from 47.7% in February.</li>
            <li>U.S. interest rates are now between 4.75% to 5%, the highest since June 2006. Ahead of the Federal Reserve rates meeting on May 3, analysts at the CME Group expect a 35.3% chance of no change to rates, while 64.7% expect a 25 basis-point rise, up from 44.5% on Friday.</li>
            <li>This week brings more economic indicators in the U.S. — The Bureau of Labor Statistics’ consumer and producer prices index on Thursday, the monthly retail trade report expected on Friday, and minutes from the Federal Open Market Committee meeting expected to come out Tuesday.</li>
          </ul>
        </div>
      },
      {
        id: 374,
        title: 'Cryptocurrencies continue rebound as some say crypto is through the bear market',
        category: 'Crypto',
        subCategory: ['Crypto Currencies', 'Bear', 'market'],
        description: <div className="blog-desc">
          <p>Cryptocurrencies are pushing higher in 2023 despite a banking crisis, rising interest rates, and a slew of new legal and regulatory headaches for the fledgling industry.</p>
        </div>,
        authorName: 'David Hollerith',
        authorurl: 'https://www.yahoo.com/author/david-hollerith',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 7, 2023', 
        cover: 'https://ncx.cx/images/ncx-blog-385.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Cryptocurrencies are pushing higher in 2023 despite a banking crisis, rising interest rates, and a slew of new legal and regulatory headaches for the fledgling industry.</p>
            <p>Bitcoin, the largest and best-known digital currency, rose 69% in the three months ending March 31, while ether, the second-largest, was up 51%. Bitcoin (BTC) is currently hovering near $28,000 while ether (ETH) on Wednesday climbed above $1,900 per coin, its highest level since September of last year. Both are relatively flat over the last 24 hours.</p>	 
            <p>Even Dogecoin (DOGE), a cryptocurrency that began as a joke, shot 30% higher Monday after Elon Musk swapped Twitter's bird icon with the Dogecoin's Shiba Inu logo. Doge forfeited some of these gains on Thursday when Twitter's logo was swapped back.</p>	
            <p>SkyBridge Capital Founder and Managing Partner Anthony Scaramucci told Yahoo Finance Thursday, "I would guess right now that we're through the bear market" in cryptocurrencies. "If something happens to Binance," he said, referring to a crypto exchange experiencing new legal pressure from regulators, "I think it will be a short-term hit."</p>
          <p>Skybridge had some challenges last year as some customers requested to withdraw their money. It also sold a 30% stake to crypto exchange FTX before the exchange collapsed. Total assets under management at Skybridge dropped to $1.8 billion at the end of 2022, down 50% from a year earlier and down 80% from a 2015 peak of $9.2 billion.</p>
          <p>But Scaramucci remains confident in his firm's crypto investments. "Any time that you've held bitcoin in a four-year rolling interval, so you pick the day, hold it for four years, you've outperformed every other asset class," Scaramucci said.</p>
          <p>The rapid climb of digital currencies is one of the year's big surprises in markets after a crash in 2022 cost investors billions as higher interest rates and inflation lowered the value and appeal of risky assets.</p>
          <p>Several crypto players filed for bankruptcy, including FTX in November. FTX's former CEO Sam Bankman-Fried now faces criminal charges that he stole billions of FTX customer funds and misled investors.</p>
          <p>"It's a bounce back from the really poor performance we saw in 2022," VettaFi research head Todd Rosenbluth told Yahoo Finance on Tuesday.</p>
          <p>What gave the market added momentum this year, ironically, was a banking crisis in March that took down three lenders, including two that specifically served cryptocurrency customers.</p>
          <p>Some investors chose to seek out cryptocurrencies as a safe haven from the perceived instability of banking. Others anticipated the Fed might have to cut interest rates in response to the crisis, sparking more flows into alternative risk assets.</p>
          <p>Since March 10, the day regulators seized Silicon Valley Bank in the second-largest bank failure of U.S. history, bitcoin is up more than 33% and ether is up more than 26%. The total value for all crypto assets is up more than 22% during that period, as of Friday at 8:30 a.m. ET.</p>
          <h3>Regulatory pressure ramps</h3>
          <p>This year's crypto comeback is unfolding even as Washington regulators step up their efforts to rein in this market.</p>
          <p>The Securities and Exchange Commission has issued 11 enforcement actions since the beginning of January against crypto firms and individuals, while serving formal letters to Paxos, DeFi exchange Sushi, and Coinbase Global (COIN) warning the agency plans to bring an enforcement action. Coinbase is the largest U.S. crypto exchange.</p>
          <p>Coinbase shares have rebounded 73% year to date but are still two thirds below their value a year ago.</p>
          <p>Another regulator, the Commodities and Futures Trading Commission, also has sued crypto exchange Binance and its CEO Changpeng Zhao for allegedly selling derivatives backed by digital assets to U.S. customers despite not being registered to do so.</p>
          <p>"This industry is not in a good trust position right now with the public, with investors, with customers," CFTC commissioner Christy Goldsmith Romero said Wednesday at the Links NYC conference hosted by crypto firm Chainalysis.</p>
          <p>Goldsmith Romero noted that crypto firms are still grappling with issues "learned" from the collapse of FTX last November while regulators try to move fast to understand the technology. CFTC has worked enforcement cases with other agencies, "and I think you’ll probably see more of those," she added.</p>
          <h3>Liquidity dries up</h3>
          <p>Another concern for some investors is that liquidity for major cryptocurrencies has fallen to record lows. Data from research firm Kaiko shows the ease of exchange between bitcoin and ether into cash has fallen a total 50% and 41% respectively since FTX filed for bankruptcy on November 11.</p>
          <p>"It makes me weary of any recent rally although bitcoin is quite close to $30K," Christopher Newhouse, a crypto derivatives trader with GSR, told Yahoo Finance.</p>
          <p>Newhouse said he worries everyday traders aren’t entering the market, citing recent sizable allocations to bitcoin and ether made by major corporate players Binance and MicroStrategy (MSTR). MicroStrategy disclosed Wednesday in an SEC filing that it purchased a little over a thousand bitcoins, raising its total BTC stash to roughly $3.9 billion, according to current market prices.</p>
          <p>"It makes me wonder whether new entrants are really entering the market and from a price perspective if this rally is sustainable or organic buying has died down," Newhouse added.</p>
          <p>But since the beginning of February, bitcoin's 50-day average has risen above the 200-day moving average, a so-called "golden cross" pattern that suggests to chart users that market sentiment has flipped from bearish to bullish.</p>
        </div>
      },
      {
        id: 373,
        title: 'Solana introduces ‘state compression’ to lower NFT storage costs',
        category: 'Crypto',
        subCategory: ['Solana', 'NFT', 'Costs'],
        description: <div className="blog-desc">
          <p>Solana has introduced a solution that aims to reduce on-chain storage costs for non-fungible tokens (NFT).</p>
        </div>,
        authorName: 'Forkast.News',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 7, 2023', 
        cover: 'https://ncx.cx/images/ncx-blog-384.jpg',
        content: <div className="blog-content">
            <h3>In this article:</h3>
            <p>Solana has introduced a solution that aims to reduce on-chain storage costs for non-fungible tokens (NFT).</p>
          <h3>Fast facts</h3>
          <ul>
            <li>On-chain storage refers to storing data on the blockchain network, which can be a costly process. The new solution, called “state compression,” reduces the amount of on-chain storage required to store NFTs, resulting in lower costs for creators and buyers.</li>
            <li>Solana claimed in a blog post on Thursday that state compression can reduce storage costs by up to 100 times.</li>
            <li>State compression solution could become an important feature for NFT creators, who often face high costs when minting NFTs.</li>
            <li>Minting refers to the process of creating and putting NFTs on the blockchain. The process requires a certain amount of digital storage space, which can be expensive. By reducing the storage space needed, state compression can make NFT minting more affordable for creators.</li>
            <li>The Solana blockchain’s native cryptocurrency (SOL) price was little changed following the announcement and was trading at US$20.5. Meanwhile, the Forkast SOL NFT Composite, a proxy measure for the Solana NFT market, dropped 0.28%.</li>
            <li>The state compression solution comes at a time when the Solana ecosystem has taken a hit from popular NFT projects leaving the network. Degods and Y00ts recently began migrating to Ethereum and Polygon, respectively.</li>
          </ul>
        </div>
      },
      {
        id: 320,
        title: 'Bitcoin holds US$28,000 in choppy trading; Ether falls after US Treasury warns against DeFi risk',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Defi', 'Us'],
        description: <div className="blog-desc">
          <p>Bitcoin moved in a narrow range above the support line at US$28,000 in Friday morning trading in Asia, while most other top 10 non-stablecoin cryptocurrencies declined. Ether lost ground as the U.S. Treasury said the growth of decentralized finance (DeFi) posed a security risk. U.S. equities closed higher Thursday despite signs of a slowing labor market and a grim global economic outlook report from the International Monetary Fund (IMF).</p>
        </div>,
        authorName: 'Danny Park',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 7, 2023 - 6:27 AM',
        cover: 'https://ncx.cx/images/ncx-blog-331.jpg',
        content: <div className="blog-content">
          <h3>In this article:</h3>
          <p>Bitcoin moved in a narrow range above the support line at US$28,000 in Friday morning trading in Asia, while most other top 10 non-stablecoin cryptocurrencies declined. Ether lost ground as the U.S. Treasury said the growth of decentralized finance (DeFi) posed a security risk. U.S. equities closed higher Thursday despite signs of a slowing labor market and a grim global economic outlook report from the International Monetary Fund (IMF).</p>
          <p>Fast facts</p>
          <ul>
            <li>Bitcoin dipped 0.49% to US$28,041 in the 24 hours to 8:00 a.m. in Hong Kong, edging up 0.04% for the week, according to CoinMarketCap data. The token moved back into positive territory later in the morning and moved in a tight range.</li>
            <li>Ether dipped 1.90% to US$1,873, following the U.S. Treasury Department’s statement on Thursday describing DeFi services as a threat to national security. Ethereum is a primary blockchain platform that allows development of decentralized applications in DeFi, where its Ether token is used as the primary means of exchange.</li>
            <li>Ether, however, is up 4.4% on the week as the blockchain awaits next Wednesday’s Shanghai hardfork, also known as Shapella upgrade, which will allow investors to withdraw their staked Ether for the first time.</li>
            <li>Dogecoin fell 8% to US$0.08542 after Elon Musk switched the Twitter icon back to the traditional blue bird after replacing it with the Dogecoin dog image earlier this week. Still, the meme token is still up 15% on the week.</li>
            <li>Other tokens followed a similar price pattern, down for Friday but holding gains over seven days. Cardano’s ADA token dipped 2.32% to US$0.3834, but is up 1.85% week-to-date. Polygon’s Matic fell 2.27%, yet is up about 2% higher over the seven days.</li>
            <li>The total crypto market capitalization dropped 0.84% in the past 24 hours to US$1.18 trillion. Total trading volume in the same period lost 15.64% to US$37.11 billion.</li>
            <li>In the non-fungible token (NFT) market, the Forkast 500 NFT index slipped 0.53% to 3,987.19 in the 24 hours to 08:00 a.m. in Hong Kong, losing 0.65% for the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day. It is managed by Forkast Labs data branch, CryptoSlam.</li>
            <li>U.S. equities closed little changed to higher on Thursday. The Dow Jones Industrial Average finished flat, the S&P 500 gained 0.36%, and the Nasdaq Composite Index rose 0.76%.</li>
            <li>The U.S. Labor Department on Thursday said the number of Americans filing new claims for unemployment benefits fell to 228,000 in the week ended April 1, but annual revisions to the number showed applications were higher this year than expected, indicating a slowing labor market. This added to concern the U.S. economy may be headed for a recession after a raft of interest rate hikes to curb inflation.</li>
            <li>This view gained some credence from IMF’s managing director Kristalina Georgieva predicting just 3% growth in the global economy for the next five years, the lowest forecast in two decades.</li>
            <li>U.S. interest rates are now between 4.75% to 5%, the highest since June 2006. Ahead of the Federal Reserve rates meeting on May 3, analysts at the CME Group expect a 44.5% chance of a 25 basis-point rise, while 55.5% expect no change, up from 54.2% on Thursday.</li>
            <li>U.S. stock futures traded lower as of 8:00 a.m. in Hong Kong. The Dow Jones Industrial Average futures dipped 0.12%, S&P 500 futures moved down 0.097% and the Nasdaq-100 futures Index dropped 0.11%. U.S. equity markets are closed Friday in observance of Good Friday.</li>
            <li>(Updates Dogecoin section with icon switch back to blue bird at Twitter.)</li>
          </ul>
        </div>
      },
      {
        id: 375,
        title: 'Overseas crypto transfers cost 96.7% less than traditional system: Coinbase',
        category: 'Crypto',
        subCategory: ['Crypto', 'overseas', 'coinbase'],
        description: <div className="blog-desc">
          <p>Americans spend more than US$12 billion in remittance fees each year when sending money to family and friends living overseas using the traditional financial system. However, overseas remittances using Bitcoin and Ethereum cost 96.7% less, according to research by Coinbase, one of the largest crypto exchanges based in the U.S.</p>
        </div>,
        authorName: 'Pradipta Mukherjee',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 5, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-386.jpg',
        content: <div className="blog-content">
           <h3>In this article:</h3>
           <p>Americans spend more than US$12 billion in remittance fees each year when sending money to family and friends living overseas using the traditional financial system. However, overseas remittances using Bitcoin and Ethereum cost 96.7% less, according to research by Coinbase, one of the largest crypto exchanges based in the U.S.</p>
         <h3>Fast facts</h3>
         <ul>
          <li>“The U.S. average fee rate of 6.18% means Americans’ average yearly spend is likely close to US$12 billion on remittance fees. You shouldn’t have to spend money to send money,” Coinbase said in a blog post on April 3.</li>
          <li>Sending Bitcoin to another wallet costs US$1.50 per transaction on average, while an Ethereum transaction costs US$0.75, Coinbase said.</li>
          <li>Remittance through the traditional banking system takes an average of 1-10 days as it has to go through many intermediaries and functions during fixed banking hours. The average waiting time for crypto transfers is about 10 minutes, Coinbase said.</li>
          <li>Close to a billion people globally depend on remittances, either by sending or receiving them, and close to 800 million people depend on money sent by their family members living elsewhere for work, according to the United Nations.</li>
          <li>“We need our American regulators to see the true value of responsible crypto products and services and their ability to update an antiquated system,” Coinbase said.</li>
         </ul>
        </div>
      },
      {
        id: 302,
        title: 'Ethereum soars 8% – closes in on $2k ahead of Shanghai upgrade',
        category: 'Crypto',
        subCategory: ['ETH', 'Ethereum', 'Shanghai'],
        description: <div className="blog-desc">
          <p>Ethereum has gained 8% in the past 24 hours as it edges closer to the $2,000 (£1,602) mark ahead of the blockchain's Shanghai upgrade.</p>
        </div>,
        authorName: 'Brian McGleenon',
        authorurl: 'https://www.yahoo.com/author/brian-mcgleenon',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 5, 2023 - 12:30 PM',
        cover: 'https://ncx.cx/images/ncx-blog-313.jpg',
        content: <div className="blog-content">
          <p>In this article:</p>
          <p>Ethereum has gained 8% in the past 24 hours as it edges closer to the $2,000 (£1,602) mark ahead of the blockchain's Shanghai upgrade.</p>
          <p>Ethereum (ETH-USD), the world's second largest cryptocurrency by market capitalisation, reached a high of $1,920 in the last 24 hours, a price level not seen since August 2022.</p>
          <p>The upward trajectory comes despite uncertain macroeconomic conditions caused by successive US Federal Reserve rate hikes, and turmoil in the global banking sector following the collapse of Silicon Valley Bank (SIVB).</p>
          <h3>What is Ethereum's Shanghai Upgrade?</h3>
          <p>The upcoming Shanghai upgrade will mark Ethereum's complete transition to a proof-of-stake network, further solidifying its position within the cryptocurrency landscape as the most widely adopted layer one blockchain.</p>
          <p>Scheduled for April 12, the Shanghai upgrade, commonly known as 'Shapella', will signify the blockchain's complete shift from a proof of work (PoW) to a proof of stake (PoS) network. Proof of stake blockchains use over 99% less energy to validate than those that operate using proof of stake.</p>
          <p>This transition follows the previous 'merge' upgrade in September, and for the first time, it will allow users to withdraw their staked ether, Ethereum's native cryptocurrency.</p>
          <p>By moving to a proof of stake network, Ethereum will reduce its energy consumption by 99.9%, and enable users to generate passive income through staking their cryptocurrency.</p>
          <p>Staking involves earning financial rewards for contributing to the blockchain's network and supporting its functionality.</p>
          <p>The Shanghai Upgrade will allow those ether 'stakers' who lock their funds to make the network function, to withdraw their staked ETH.</p>
          <p>This is a relief to many stakers who experienced anxiety throughout the last quarter of 2022 when the price of the cryptocurrency was sent into a downward spiral after the collapse of the FTX exchange.</p>
          <h3>Today's crypto prices</h3>
          <p>The global cryptocurrency market cap today stands at $1.25tn (£860bn), a gain of 2.5% in the last 24 hours, according to data from Coingecko.</p>
          <p>Ethereum stands at $1,911 as of the time of writing, a gain of 8% in the past week.</p>
          <p>Bitcoin (BTC-USD) is now priced at $28,531, up 5% in the past week.</p>
          <p>Bitcoin has gained 70% since beginning of the year, and according to data from Coinglass, in the past 24 hours most traders of the digital asset hold long positions, suggesting market sentiment is currently in favour of more upward momentum.</p>
          <p>Coinglass data showed that in the past 24 hours, across the entire cryptocurrency market, 30,188 traders were liquidated, with the total liquidations comes in at $97.95m. The majority of these liquidations were of short positions as the whole market gained in value.</p>
          <p>However, one of the most prominent losers in the past 24 hours was dogecoin (DOGE-USD).</p>
          <p>The price of dogecoin shot up yesterday after Twitter changed the logo on the social media platform's website from the iconic blue bird to an image of a Shiba Inu dog associated with the dogecoin cryptocurrency.</p>
          <p>The price of dogecoin, which is typically volatile, soared 35% in one day, however, it fell as day traders dumped the memecoin to take gains.</p>
          <p>Dogecoin now sits at $0.096 per token, a fall of 2.2% in the past 24 hours, according to data from Coingecko.</p>
        </div>
      },
      {
        id: 303,
        title: 'Bitcoin pushes beyond US$28,000; Polygon’s Matic leads gainers, same chain sees surge in NFT trades',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Polygon', 'NFT'],
        description: <div className="blog-desc">
          <p>Bitcoin rose on Wednesday morning in Asia, moving beyond the US$28,000 mark. Ether and most other top 10 non-stablecoin cryptocurrencies also gained, with Polygon’s Matic token leading the winners. Polygon’s blockchain is also seeing a surge in non-fungible token (NFT) sales. U.S. equities fell as job openings declined to the lowest since May 2021, signaling a cooling economy and stirring concern about a recession after a year-long series of interest rate hikes by the Federal Reserve.</p>
        </div>,
        authorName: 'Danny Park',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 5, 2023 - 7:36 AM',
        cover: 'https://ncx.cx/images/ncx-blog-314.jpg',
        content: <div className="blog-content">
          <p>In this article:</p>
          <p>Bitcoin rose on Wednesday morning in Asia, moving beyond the US$28,000 mark. Ether and most other top 10 non-stablecoin cryptocurrencies also gained, with Polygon’s Matic token leading the winners. Polygon’s blockchain is also seeing a surge in non-fungible token (NFT) sales. U.S. equities fell as job openings declined to the lowest since May 2021, signaling a cooling economy and stirring concern about a recession after a year-long series of interest rate hikes by the Federal Reserve.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin gained 1.36% to US$28,172 in the last 24 hours to 8 a.m. in Hong Kong, up 3.32% in the last week, according to CoinMarketCap data. The leading cryptocurrency added almost 3% by mid-morning in Asia trading. Ethereum rose 3.35% to US$1,870, a weekly gain of 5.53%.</li>
            <li>Polygon’s Matic token was the biggest gainer out of the top 10 non-stablecoin cryptocurrencies by market capitalization, adding 3.84% to US$1.14 for a weekly gain of 4.74%. Polygon’s y00ts NFT sale was the largest among all blockchains in the 24 hours to Tuesday morning, according to Polygon’s official Twitter handle.</li>
            <li>“Compared to this time last year, Polygon is seeing roughly 3-5x the number of unique sellers, buyers, and total transactions monthly. This is largely due to non-NFT traders now being exposed to non-fungible tokens through Starbucks rewards and Reddit Avatars,” said Yehudah Petscher, strategist at Forkast Labs’ NFT data specialist unit, Cryptoslam.</li>
            <li>Dogecoin, which surged Tuesday following Elon Musk’s move to change Twitter’s blue bird icon to the memecoin’s Shiba Inu dog image, fell back 0.89% to US$0.09523. The meme token’s value jumped 29% over the last seven days, which seems to have awakened some wallets, with Whale Alert noting one containing 1.2 million Doge activated after more than nine years dormant.</li>
            <li>The global crypto market capitalization rose 1.43% to US$1.19 trillion while the total market trade volume dipped 14% to US$42.06 billion in the last 24 hours.</li>
            <li>In the NFT market, the Forkast 500 NFT Index dipped 0.11% to 4,035.10 in the 24 hours to 09:00 a.m. in Hong Kong, but added 0.74% for the week. The CryptoSlam index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day.</li>
            <li>From Jan. 1, 2022, to April 4, 2022, the Forkast 500 showed the market fell almost 12% and for the same period through April 4 this year, the index is down just 2%, said Petscher. “We trade in cycles so I’m curious if we really have much further to fall, or if we’ve found some sort of floor that’s supported by collectors that are here for the long haul,” he said.</li>
            <li>U.S. equities closed lower on Tuesday. The Dow Jones Industrial Average fell 0.59%, and the S&P 500 lost 0.58%. The Nasdaq Composite slipped 0.52%.</li>
            <li>The number of job openings in the U.S. fell to 9.9 million in February, the lowest since May 2021 and below market expectations of 10.4 million, indicating a cooling economy, according to Trading Economics.</li>
            <li>The data points indicate the Fed’s battle against inflation may be working, but at the same time concern is growing that with U.S. interest rates now between 4.75% to 5%, or the highest in 17 years, the central bank may have gone too far and the world’s biggest economy could slide into a recession. Analysts at the CME Group have shifted in their view on the outcome of the Fed May 3 meeting to decide on interest rates, with a majority of analysts, about 59%, predicting no change, while 41.1% expect a 25 basis-point rise.</li>
            <li>U.S. stock futures traded higher of 9:30 a.m. in Hong Kong. The Dow Jones Industrial Average Index Futures edged up 0.098%, S&P 500 Futures gained 0.097% and the  Nasdaq-100 Futures added 0.13%.</li>
          </ul>
        </div>
      },
      {
        id: 321,
        title: 'Dogecoin soars 20% after the tokens Shiba Inu mascot replaces Twitters bird logo',
        category: 'Crypto',
        subCategory: ['Dogecoin', 'twitter', 'token'],
        description: <div className="blog-desc">
          <p>Dogecoin, the meme cryptocurrency that counts Elon Musk among its most famous supporters, soared 20% on Monday after the token's Shiba Inu mascot replaced the bird logo on Twitter's website. Meanwhile, Musk brushed off a lawsuit accusing him of running a pyramid scheme, with lawyers for the exec asking a judge to throw out the $258 billion case.</p>
        </div>,
        authorName: 'Jennifer Sor',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 4, 2023 - 12:14 AM',
        cover: 'https://ncx.cx/images/ncx-blog-332.jpg',
        content: <div className="blog-content">
          <h3>In this article:</h3>
          <ul>
            <li>Dogecoin jumped 20% on Monday after Twitter users noticed the token's Shiba Inu mascot on Twitter's website.</li>
            <li>Elon Musk, who's regularly pumped the meme coin, also brushed off a lawsuit related to the token.</li>
            <li>Musk's lawyers defended his "silly" tweets about the coin, asking a judge to toss a $258 billion suit.</li>
          </ul>
          <p>Dogecoin, the meme cryptocurrency that counts Elon Musk among its most famous supporters, soared 20% on Monday after the token's Shiba Inu mascot replaced the bird logo on Twitter's website. Meanwhile, Musk brushed off a lawsuit accusing him of running a pyramid scheme, with lawyers for the exec asking a judge to throw out the $258 billion case.</p>
          <p>The price of Dogecoin surged to $.09 as of 2:30 pm ET, up about 19%.</p>
          <p>The jump occurred as Twitter users noticed Dogecoin's distinctive Shiba Inu logo had replaced the bird icon on Twitter's website, per Decrypt's report.</p>
          <p>The surge behind the token's price also comes a few days after Musk refuted claims from dogecoin investors alleging in a new lawsuit that Musk's well-documented affinity for the meme token was part of a "pyramid scheme" to support the cryptocurrency.</p>
          <p>Musk's lawyers filed a request on Friday to dismiss the $258 billion suit, calling the claims brought forward a "fanciful work of fiction." They added that Musk's tweets about dogecoin, which include statements like "no highs, no lows, only Doge," were merely "innocuous" and "silly" remarks from the tech mogul.</p>
          <p>When Insider reached out to Twitter for comment regarding the logo change and the lawsuit, Twitter responded with a poop emoji, which Musk previously said was the new auto-response to any email sent to Twitter's press team.</p>
          <p>Musk has regularly tweeted about dogecoin for years, and first promoted it to his Twitter followers in 2019, when he said that the meme token was his "fav cryptocurrency."</p>
          <p>The coin's valued peaked around $0.74 in mid-2021, but has since spiked periodically, often on comments from Musk. Dogecoin's last big jump came late last year, with the crypto gaining around $10 billion in market value as Musk began his takeover of Twitter.</p>
        </div>
      },
      {
        id: 304,
        title: 'Bitcoin drops as US plans to sell over 41,000 BTC',
        category: 'Crypto',
        subCategory: ['BTC', 'US', 'Drops'],
        description: <div className="blog-desc">
          <p>Bitcoin (BTC-USD) has fallen below $28,000 (£22,730) as regulatory action against the crypto industry intensifies and the US government announces a schedule to sell 41,490 BTC in 2023.</p>
        </div>,
        authorName: 'Brian McGleenon',
        authorurl: 'https://www.yahoo.com/author/brian-mcgleenon',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Apr 3, 2023 - 12:48 PM',
        cover: 'https://ncx.cx/images/ncx-blog-315.jpg',
        content: <div className="blog-content">
          <p>In this article:</p>
          <p>Bitcoin (BTC-USD) has fallen below $28,000 (£22,730) as regulatory action against the crypto industry intensifies and the US government announces a schedule to sell 41,490 BTC in 2023.</p>
          <p>The global cryptocurrency market cap stood at $1.21tn on Monday, a drop of 2.1%, according to Coingecko.</p>
          <p>Bitcoin fell 2.2% to $27,887.</p>
          <p>The recent price action has left bitcoin's market capitalisation at $538bn. But, bitcoin's price has risen by 67.76% so far this year.</p>
          <p>Ethereum (ETH-USD) fell 1.27% to $1,790.</p>
          <p>The Ethereum Foundation said last week that the blockchain's Shanghai hard fork, featuring an upgrade enabling investors to withdraw their staked ETH for the first time, is set for 12 April.</p>
          <h3>US to sell over 41,000 bitcoin</h3>
          <p>Last month, the US government sold $216m worth of bitcoin that it had seized during a November 2021 search connected to cryptocurrency that had been stolen from the Silk Road dark web marketplace.</p>
          <p>The bitcoin sold was a portion of a 50,000 BTC haul that was seized following the arrest of James Zhong, who pleaded guilty to wire fraud after the government alleged he manipulated the transaction system on Silk Road in 2012.</p>
          <p>The US government plans to sell the remaining 41,490 bitcoins in four separate portions throughout 2023, according to a court filing.</p>
          <h3>Ripple versus the SEC</h3>
          <p>XRP (XRP-USD) climbed 3.06% to $0.5192 — a 15.57% weekly increase.</p>
          <p>It is thought that Ripple Labs, whose crypto payment platform utilises XRP, will soon resolve its ongoing legal battle with the US Securities and Exchange Commission (SEC).</p>
          <p>The SEC has accused the company and its executives of violating securities regulations.</p>
          <p>SEC chair Gary Gensler asked Congress for $2.4bn in funding last Wednesday to enhance the agency's efforts in combating cryptocurrency-related malpractices.</p>
          <h3>Binance versus the CFTC</h3>
          <p>The cryptocurrency market has experienced instability due to last week's litigation from US regulators against crypto exchange Binance.</p>
          <p>Last Monday, the US Commodities Futures Trading Commission (CFTC) filed an enforcement action against Binance and its founder Changpeng 'CZ' Zhao.</p>
          <p>According to the CFTC, which has been investigating Binance’s business since 2021, the company failed to meet its regulatory obligations by not properly registering with the derivatives regulator.</p>
          <p>The CFTC classifies bitcoin (BTC-USD), ethereum (ETH-USD) and litecoin (LTC-USD) as commodities and has accused Binance of conducting unregistered derivative trading with these digital assets.</p>
          <p>Binance founder Changpeng “CZ” Zhao has also been accused of encouraging Americans to “evade compliance controls” by the US regulator.</p>
          <h3>UK banks restricting web3 firms, claims report</h3>
          <p>The UK cryptocurrency sector is finding it increasingly difficult to access banking services, with restrictions on web3 firms holding back Britain's aspiration to become a "global crypto hub", a new report has claimed.</p>
          <p>The few banks that continue to collaborate with web3 businesses are requiring more extensive documentation, with problems ranging from having applications rejected to getting buried in paperwork, according to the report by Bloomberg.</p>
          <p>Web3 firm SavingBlocks said it applied to nine banking service providers for a corporate account and was turned down by seven of them. Those that did offer services asked for "insurmountable" additional documentation.</p>
          <p>“There aren’t many options available, most traditional banks won’t offer banking services to crypto firms," Edouard Daunizeau, SavingBlocks founder and CEO, told Bloomberg.</p>
          <p>“With the recent string of events it will be even tougher, so we are now seeking licenses in France where we think it will be easier."</p>
          <p>Some web3 founders have complained to the UK government, saying that the limitations contradict prime minister Rishi Sunak's intention to prioritise financial technology innovation and establish the UK as a global crypto hub.</p>
          <p>“The UK banking reaction has been more acute than the EU one," Tom Duff-Gordon, vice president of international policy at Coinbase (COIN), told Bloomberg. He said that the European Union's efforts to create a digital asset framework make banks in other countries more open to working with crypto firms.</p>
          <p>The European parliamentary committee approved the Markets in Crypto Assets (MiCA) legislation in October, with the final vote set to take place this month.</p>
          <p>The UK is losing ground to the rest of Europe, according to the Bloomberg report, as data from PitchBook showed venture capital funding for UK digital asset companies has experienced a 94% decline over the past year.</p>
          <p>The EU saw a 31% increase in investments during the same period.</p>
          <h3>Equity markets</h3>
          <p>US stocks advanced on Friday, as the Federal Reserve's preferred inflation measure indicated signs of cooling, and a potential end to the Fed's monetary tightening cycle.</p>
          <p>The tech-heavy Nasdaq (^IXIC) increased by 1.7%, while the S&P 500 (^GSPC) and the Dow Jones Industrial Average (^DJI) gained 1.2% and 1.4%, respectively.</p>
        </div>
      },
      {
        id: 305,
        title: 'Bitcoin, Ether fall; top 10 cryptos mixed; gold closes March 8% higher',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'gold', 'top 10'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ether fell during Asian trading hours on Friday, while the rest of the top 10 non-stablecoin cryptocurrencies by market capitalization were mixed, amid mounting allegations against Binance – the world’s largest crypto exchange. Asian and European equities markets strengthened, mirroring Wall Street’s overnight rally, amid easing inflation in Europe and China’s economic recovery.</p>
        </div>,
        authorName: 'Zoltan Vardai',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 31, 2023 - 2:39 PM',
        cover: 'https://ncx.cx/images/ncx-blog-316.jpg',
        content: <div className="blog-content">
          <h3>In this article:</h3>
          <p>Bitcoin and Ether fell during Asian trading hours on Friday, while the rest of the top 10 non-stablecoin cryptocurrencies by market capitalization were mixed, amid mounting allegations against Binance – the world’s largest crypto exchange. Asian and European equities markets strengthened, mirroring Wall Street’s overnight rally, amid easing inflation in Europe and China’s economic recovery.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin fell 3.3% to US$27,760 in the 24 hours to 4:30 p.m. in Hong Kong. Ether slid 0.78% to US$1,792, according to CoinMarketCap data.</li>
            <li>The day’s biggest winner Cardano’s ADA token rose 1.58% to trade at US$0.38. Polkadot’s DOT had the second biggest gain on the day, rising 1% to US$6.24.</li>
            <li>The global cryptocurrency market capitalization decreased by 1.89% to US$1.17 trillion in the 24 hours to 4:30 p.m. in Hong Kong, while total crypto market trading volume decreased by 15.09% to US$44.07 billion.</li>
            <li>The crypto market continues to factor in this week’s events when Binance was sued by the U.S. Commodity Futures Trading Commission for allegedly violating derivative rules, days before it was accused of obscuring its China ties. Binance has denied all allegations.</li>
            <li>The Forkast 500 NFT index rose 0.52% on the day to 4,027.14 points, making for a weekly loss of 2.34%. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day.</li>
            <li>Asian equities rallied on Thursday in line with the S&P 500 that rose 0.57% during the day. Retail sales in Hong Kong for February increased 9.6% year-on-year, the largest growth since February 2021.</li>
            <li>Japan’s Nikkei 225 rose 0.93%, the Shanghai Composite gained 0.36%, the Shenzhen Component inched up 0.64% and Hong Kong’s Hang Seng Index strengthened 0.45% to close the day near a three-week high, bolstered by China’s economic recovery in March.</li>
            <li>European bourses strengthened on Friday, with the STOXX 600 inching up 0.23% and the DAX 40 rising 0.45%. Year-on-year consumer price inflation in the Eurozone for March declined to 6.9%, the lowest since February 2022 and stood below market expectations of 7.1%.</li>
            <li>Gold largely traded flat on Friday at around US$1,980 per ounce. It gained over 8% in March as demand for safe-haven assets soared following global banking turmoil.</li>
            <li>Seasonally-adjusted unemployment in the Euro area also declined to a record low of 6.6% for February, compared to 6.8% a year earlier.</li>
          </ul>
        </div>
      },
      {
        id: 291,
        title: 'Arbitrum [ARB] dApps show growth, but protocol has a long road ahead',
        category: 'Crypto',
        subCategory: ['ARB', 'Arbitrum', 'Growth'],
        description: <div className="blog-desc">
          <p>Arbitrum [ARB] has been receiving a lot of attention due to its recent token AirDrop. The dApps on the Arbitrum network seem to have taken advantage of this attention as many of them observed growth after the AirDrop.</p>
        </div>,
        authorName: 'Himalay Patel',
        authorurl: 'https://ambcrypto.com/author/himalay-patel/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 29, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-302.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Arbitrum’s dApps observed high activity on its network.</li>
            <li>However, the overall state of Arbitrum returned to normal as the AirDrop hype died down.</li>
          </ul>
          <p>Arbitrum [ARB] has been receiving a lot of attention due to its recent token AirDrop. The dApps on the Arbitrum network seem to have taken advantage of this attention as many of them observed growth after the AirDrop.</p>
          <p>Santiment’s data revealed that Stargate, a DeFi protocol operating on the Arbitrum network, experienced a significant increase in its network growth despite a 51% decrease in the value of its token STG.</p>
          <p>Another dApp that witnessed growth was GMX. According to Dapp Radar, the overall volume on the GMX protocol increased by 6.72% in the past 30 days. During this period, the number of unique active wallets on the GMX network also increased.</p>
          <p>These developments led to a 28.6% surge in the TVL of Arbitrum last week, according to data provided by L2Beats.</p>
          <h3>Still a long way to go</h3>
          <p>However, the growth of the aforementioned dApps wasn’t reflected by the Arbitrum network. According to Artemis’ data, the daily activity addresses on the network declined materially over the last few days, falling from 611,000 addresses to 167,300 addresses at press time.</p>
          <p>This decline in activity was observed despite Arbitrum offering lower transaction fees to its users, which helped them save higher amounts of ETH on each of their transactions. According to Dune Analytics’ data, Arbitrum outperformed other L2 solutions like Optimism in terms of savings provided to users.</p>
          <p>Even though there was a decline in activity on Arbitrum, there wasn’t much of an impact on the revenue that was being generated. According to Token Terminal’s data, the revenue rose by 22.7% in the last 24 hours.</p>
          <p>The number of active developers on the network spiked during this period as well. This indicated that Arbitrum was capitalizing on the renewed attention on its network to roll out new upgrades and updates on the network.</p>
        </div>
      },
      {
        id: 290,
        title: 'Dogecoin [DOGE]: Short-term investors could gain, but only if…',
        category: 'Crypto',
        subCategory: ['DOGE', 'Investors', 'Dogecoin'],
        description: <div className="blog-desc">
          <p>The higher timeframe price charts of Dogecoin [DOGE] showed strong bearish pressure on one of the most popular meme coins. The $0.08 region has posed stern resistance since early March, and this does not look likely to change soon.</p>
        </div>,
        authorName: 'Akashnath S',
        authorurl: 'https://ambcrypto.com/author/akashnath-sumukar/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 29, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-301.jpg',
        content: <div className="blog-content">
          <ul>
            <li>The lower timeframe structure of DOGE was bearish on the charts.</li>
            <li>The support zone at $0.071 could see a minor bullish reaction.</li>
          </ul>
          <p>The higher timeframe price charts of Dogecoin [DOGE] showed strong bearish pressure on one of the most popular meme coins. The $0.08 region has posed stern resistance since early March, and this does not look likely to change soon.</p>
          <p>Bitcoin [BTC] could see some volatility after dropping to $26.5k on 27 March. In this scenario, a bounce for DOGE would offer short sellers a better risk-to-reward region to sell Dogecoin.</p>
          <p>There were two scenarios that short-term bears can watch out for. One is a drop beneath the $0.071 region, highlighted in cyan. This would be a strongly bearish scenario, and bears can look to short the retest of this region as resistance. They can target the zones of support at $0.068 and $0.065 to take profits. An extremely wide stop-loss can be used above the recent Monday’s swing high.</p>
          <p>The blue line at $0.0713 marked a strong lower timeframe zone of support for DOGE. The second scenario that could unfold was a bounce from this region that reached the $0.075-$0.078 area.</p>
          <p>A move into this zone can also be shorted, with a stop-loss above the $0.0805 mark. This could offer a better R: R trade and sellers can take partial profits at the support zones if they choose to.</p>
          <p>The RSI on the two-hour chart has not surfaced above neutral 50 since 24 March, which highlighted a near-term downtrend. The CMF was also at -0.10 to indicate significant capital flow out of the Dogecoin market.</p>
          <p>Dogecoin reached a swing high of $0.0764 on Monday and dropped to test the $0.0713 support level just a couple of hours later. This constituted a drop of 6.7%, and the futures traders remained sidelined or lost money.</p>
          <p>The Open Interest dove from $261 million to $243 million after this move, and Coinalyze’s data showed $1.6 million worth of long positions were liquidated in those two hours. However, the predicted funding rate was positive, which hinted at slight bullishness. Overall, the inference was bearish sentiment toward DOGE.</p>
        </div>
      },
      {
        id: 294,
        title: 'Ethereum suffers yet another weekly outflow, but how did BTC perform',
        category: 'Crypto',
        subCategory: ['ETH', 'BTC', 'Ethereum'],
        description: <div className="blog-desc">
          <p>For the third straight week, Ethereum [ETH] investment products failed to attract the purses of digital asset investors, CoinShares’ 27 March report revealed.</p>
        </div>,
        authorName: 'Victor Olanrewaju',
        authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 28, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-305.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Ethereum outflows were on the high side but Bitcoin shied away from a similar fate.</li>
            <li>Other altcoins joined the BTC trend but investors remained wary of ETH.</li>
          </ul>
          <p>For the third straight week, Ethereum [ETH] investment products failed to attract the purses of digital asset investors, CoinShares’ 27 March report revealed.</p>
          <p>According to the report duly delivered by James Butterfill, the altcoin faced the same fate as that of the previous weeks, seeing a $5.2 million outflow.</p>
          <h3>ETH stuck, BTC finds an escape route</h3>
          <p>However, products linked to Bitcoin [BTC] had the opposite experience as the inflow was as high as $127.5 million. Every week, CoinShares discloses the activities with respect to crypto Exchange Traded Products (ETPs) across several countries.</p>
          <p>But before the latest report, both Bitcoin and Ethereum were on the same page. This was largely due to the instability in the traditional finance sector.</p>
          <p>However, the trust issues with the banking sector seem to have resulted in gains for the crypto ecosystem. By and large, the overall inflow totaling $160 million was the highest since July 2022.</p>
          <p>This hike implies that the level of trust in crypto products was high at the expense of traditional institutions’ offers. CoinShares was of the same view even though it admitted that the inflows were relatively low at the beginning of the previous week. The report stated:</p>
          <p><b>“While the inflows came relatively late compared to the broader crypto market, we believe it is due to increasing fears amongst investors for stability in the traditional finance sector.”</b></p>
          <h3>Until Shanghai leaves the stage</h3>
          <p>But why has Ethereum failed to take a significant share of the input since it was the second-largest cryptocurrency in market value? Well, the long-standing investment group opined that Ethereum’s decline could be due to several factors. And like CoinShares opined last week, the Shanghai upgrade topped the list. The trading firm pointed out,</p>
          <p><b>“We believe investor jitters around the Shanghai upgrade (expected 12th April) are the most likely reason”</b></p>
          <p>The event, expected to happen in a few weeks, would set the grounds for staking withdrawals which could, in turn, result in selling pressure.</p>
          <p>Besides that, recent Ethereum developments have not necessarily resulted in a positive price action. So, it could be valid that investors are being skeptic about committing funds to products related to the altcoin.</p>
          <p>However, Bitcoin was not the only claimant with respect to improved inflow as some other altcoins joined the fray. For instance, Ripple [XRP], which outperformed several cryptocurrencies in the past week got inflows worth $1.2 million.</p>
          <p>Polygon [MATIC], and Solana [SOL] received inflows worth $1.9 million and $4.8 million respectively.</p>
        </div>
      },
      {
        id: 293,
        title: 'Polygon zkEVM Mainnet Beta in operation; Vitalik launches first live transaction',
        category: 'Crypto',
        subCategory: ['Polygon', 'Ceta', 'Vitalik'],
        description: <div className="blog-desc">
          <p>Almost six months after its Public Testnet, the Polygon [MATIC] zkEVM Mainnet Beta went live on Monday 27 March. The event, which began at 10 a.m. E.T, was broadcast live on YouTube. It had in attendance Vitalik Buterin, Ethereum’s [ETH] co-founder. As expected, Jordin Baylina, technical lead of the Polygon Hermez zkEVM project, was also an active participant.</p>
        </div>,
        authorName: 'Victor Olanrewaju',
        authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 28, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-304.jpg',
        content: <div className="blog-content">
          <ul>
            <li>The Polygon team officially deployed the zkEVM Mainnet for public use. </li>
            <li>Ethereum’s Vitalik Buterin made the first transaction on the network as over 50 projects were in line to join.</li>
          </ul>
          <p>Almost six months after its Public Testnet, the Polygon [MATIC] zkEVM Mainnet Beta went live on Monday 27 March. The event, which began at 10 a.m. E.T, was broadcast live on YouTube. It had in attendance Vitalik Buterin, Ethereum’s [ETH] co-founder. As expected, Jordin Baylina, technical lead of the Polygon Hermez zkEVM project, was also an active participant.</p>
          <p>Known to have made its primary objective to scale Ethereum, Polygon considered the zero-knowledge (ZK) rollup as a viable solution to the delays experienced on its optimistic match. The idea behind it was to use the existing smart contracts’ power to reduce transaction costs while utilizing Ethereum’s network security.</p>
          <h3>Finally, Beta is triumphant</h3>
          <p>In an exclusive press release sent to AMBCrypto, Polygon noted that the Ethereum founder would make the first transaction on the Mainnet. The project’s co-founder, Mihailo Bjelic, who seemed elated in anticipation of the development and Vitalik’s presence, said,</p>
          <p><b>“Today’s launch is an incredible milestone and the beginning of a new chapter for blockchain technology, fulfilling Ethereum’s original promise of becoming a true ‘world computer’ and creating a fairer, more egalitarian internet for all.”</b></p>
          <p>However, the ability to transact was not the only benefit that Polygon was offering. According to Baylina, the team was also open-sourcing the code. This would allow developers to collaborate and innovate within the community.</p>
          <p>When asked how Polygon could achieve the milestone less than two years after ideation, the technical lead said that it was down to the team’s dedication. Furthermore, he mentioned that the utilization of layers in the zkEVM architecture was vital to its fast execution.</p>
          <p>Meanwhile, Vitalik was able to make the first successful transaction on the Mainnet in a few minutes. Here, he sent an ETH equivalent of $8.62 to the last address that opened a contract on the zkEVM network.</p>
          <h3>Constraints beaten, but caution may be profitable </h3>
          <p>After the transaction, the Ethereum founder admitted that scalability was one of the project’s limitations in the early days. But with glaring delight, he commented on the success of the Polygon landmark, saying,</p>
          <p><b>“​​Millions of constraints for man, unconstrained scalability for mankind.”</b></p>
          <p>Polygon also mentioned that over 50 web3 enterprises already showed an interest in joining the zkEVM. Some of these included Solana [SOL]-based Phantom, Etherscan, and Aavegotchi.</p>
          <p>The project had pointed out that transacting within the zkEM rollup would take only a matter of seconds. However, the web3 project also warned that users bridging Ethereum could encounter a 30 to 60 minutes’ delay in this first phase.</p>
          <p>Also, there could be vulnerabilities that could hinder the seamless processing of large deposits within this period. Polygon’s president Ryan Wyatt commented on the deployment, noting that there was more to do with the ZK expansion in the long term.</p>
        </div>
      },
      {
        id: 292,
        title: 'Solana’s [SOL] high activity may not be enough for its revival: Here’s why',
        category: 'Crypto',
        subCategory: ['Solanas', 'SOl', 'revival'],
        description: <div className="blog-desc">
          <p>Solana [SOL] has been in muddy waters for quite some time, having been on the receiving end of criticism due to its frequent downtimes. However, new data revealed that there was a spike in the number of transactions being made on the network.</p>
        </div>,
        authorName: 'Himalay Patel',
        authorurl: 'https://ambcrypto.com/author/himalay-patel/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 28, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-303.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Solana witnessed a high number of transactions being made on the network.</li>
            <li>However, DEX volume and active addresses on the network fell.</li>
          </ul>
          <p>Solana [SOL] has been in muddy waters for quite some time, having been on the receiving end of criticism due to its frequent downtimes. However, new data revealed that there was a spike in the number of transactions being made on the network.</p>
          <p>Artemis’ data shows that the volume of transactions on the Solana network has hit its highest point in six months. This resulted in a 22.75% increase in its TVL over the past month, according to DeFiLlama’s data.</p>
          <p>Although the Solana network has seen high levels of activity, there has been a decline in interest in its DEXs, as evidenced by the declining volume of DEX transactions on the network.</p>
          <p>The decrease in DEX volume on the Solana network could be linked to the underperformance of DeFi protocols like Saber and Radium. Based on data from Dapp Radar, there was a 22.5% drop in the number of unique active wallets on Saber and a 33.97% decrease in the number of unique active wallets on Radium.</p>
          <h3>SOL on the metric front</h3>
          <p>On the social front, things were not going too well for Solana either. According to LunarCrush’s data, the number of social mentions and engagements for Solana fell by 64.7% and 47.11%, respectively. Along with that, the weighted sentiment for Solana also fell. This shows that the crypto community had a pessimistic outlook towards the future of Solana at press time.</p>
          <p>This negative outlook impacted the price of SOL as well, which decreased materially over the last month. Coupled with that, the volume of the SOL token fell from 1.13 billion to 271 million in the same period.</p>
          <p>However, one aspect of the Solana ecosystem that could turn things around for the protocol would be its NFT market. According to Santiment’s data, the number of NFT trades rose over the last few days. If the trend continued, it could dampen the negative sentiment against Solana and make users interested in the protocol and its token again.</p>
        </div>
      },
      {
        id: 284,
        title: 'El Salvadors Bitcoin Boom: How the Worlds First Crypto Nations Big Bet Is Stabilizing Its Economy, Proving Critics Wrong, and Paying Back Its Debt',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Salvadors', 'Economy'],
        description: <div className="blog-desc">
          <p>When El Salvador President Nayib Bukele made the controversial decision to make Bitcoin legal tender in his country, many people were skeptical and concerned about the potential risks.</p>
        </div>,
        authorName: 'Jeannine Mancini',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 28, 2023 - 7:14 PM',
        cover: 'https://ncx.cx/images/ncx-blog-291.jpg',
        content: <div className="blog-content">
          <p>In this article:</p>
          <p>When El Salvador President Nayib Bukele made the controversial decision to make Bitcoin legal tender in his country, many people were skeptical and concerned about the potential risks.</p>
          <p>Bukele announced on Twitter that the country would purchase one Bitcoin (CRYPTO: BTC) each day starting Nov. 18, 2022. How is that going in 2023? The latest news out of El Salvador suggests that the government's bet on cryptocurrency is paying off.</p>
          <p>According to Treasury Minister Alejandro Zelaya, the government has successfully repaid one of two outstanding $800 million bonds. This move is likely to allay concerns about a potential default, which had grown amid the country's adoption of Bitcoin.</p>
          <p>Zalaya announced the news via Twitter on Jan. 23, highlighting the government's commitment to meeting its debt obligations and proving the skeptics wrong. Bukele tweeted, “Well, we just paid in full 800 million dollars plus interest. But of course, almost no one is covering the story.”</p>
          <p>The government still owes $367 million plus interest on an additional bond set to mature in January 2025, but the successful repayment of the 2023 bond is a positive sign for the country's financial stability.</p>
          <p>What initially started as a largely controversial might be paying off as Bitcoin and many aspects of the blockchain-based asset market have began to rebound. Gamestop Corp.’s NFT marketplace has seen a considerable boom. Some of the top NFT projects on the platform have seen volume spikes as high as 21,446%.</p>
          <p>Similarly, leading NFT marketplace startup Gameflip recently crossed $900,000 raised from retail investors in their active equity crowdfunding raise. This comes after their announcement of hitting $160 million in lifetime volume on the platform earlier this month and a host of other milestones.</p>
          <h3>Bitcoin as Legal Tender</h3>
          <p>The small Central American country made headlines in 2021. The legislation required all businesses within the country to accept Bitcoin as a form of payment for goods and services, granting it the same status as traditional fiat currencies.</p>
          <p>Chivo Wallet, the wallet created by the government of El Salvador, said 2.2 million Salvadorans were onboarded just one month after Bitcoin became the country’s legal tender. To encourage adoption, every user who successfully downloaded the app immediately received $30 in Bitcoin. By early 2022, about half of Salvadorans had downloaded the app.</p>
          <p>The “Bitcoin bet” has been met with mixed reactions from international organizations and governments, with many expressing their concerns about potential risks that may arise from the use of cryptocurrency. The International Monetary Fund (IMF) recently issued a statement on El Salvador's decision to accept Bitcoin as legal tender, cautioning against potential risks associated with such adoption.</p>
          <p>Although there have not been any significant risks thus far following El Salvador's adoption of Bitcoin, they noted it’s important for authorities to be vigilant when providing government exposure to digital assets. According to the statement, crypto markets are still largely speculative and unpredictable in nature and should be approached with caution for El Salvador’s economy to be safeguarded against potential losses.</p>
          <p>The IMF also highlighted the importance of transparency when dealing with cryptocurrency transactions and emphasized that it is crucial for regulators to ensure they remain compliant with anti-money laundering laws and regulations. It advised El Salvador to develop effective regulatory frameworks aimed at mitigating financial stability risks associated with cryptocurrency use.</p>
          <p>El Salvador appears undeterred by the IMF's recommendations. In January, its congress approved a digital securities law, paving the way for the country to raise funds through the issuance of the world's first sovereign blockchain bond. The law allows for the use of blockchain technology in the issuance, trading and clearing of securities, enabling greater efficiency and transparency in the process. The additional step forward for the country could potentially attract investment from tech-savvy investors looking for new opportunities in the cryptocurrency market.</p>
          <h3>El Salvadorian Prosperity in a time of uncertainty</h3>
          <p>While the U.S. market faces uncertainty around banking and a general decline in U.S. equities, El Salvador’s bet looks to be paying off. While El Salvador began buying Bitcoin at a significant premium to the current price, they also announced a purchase of 80 Bitcoin at a price around the $19,000 mark in July of 2022. They have also committed to purchasing one Bitcoin per day, every day since November of 2022. At the time, the price of Bitcoin was below $16,000. Roughly 126 days have passed since then meaning the company is still pouring millions into the digital currency despite the controversy, much of which has been at a steep discount to its current price of over $28,000.</p>
        </div>
      },
      {
        id: 283,
        title: 'Bitcoin, Ether extend declines amid U.S. lawsuit against Binance; Asian equities mixed',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Binance', 'Equities'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ether extended their declines in Tuesday afternoon trade in Asia, along with most other top 10 non-stablecoin cryptocurrencies by market capitalization, after regulators in the U.S. pulled up Binance, the world’s biggest crypto exchange, for allegedly violating trading mandates. Asian markets mostly gained on Tuesday, reflecting a rise in U.S. equities on Monday, as investor sentiment picked up following a slew of positive announcements that eased fears of a banking sector crisis.</p>
        </div>,
        authorName: 'Pradipta Mukherjee',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 28, 2023 - 1:48 PM',
        cover: 'https://ncx.cx/images/ncx-blog-290.jpg',
        content: <div className="blog-content">
          <p>In this article:</p>
          <p>Bitcoin and Ether extended their declines in Tuesday afternoon trade in Asia, along with most other top 10 non-stablecoin cryptocurrencies by market capitalization, after regulators in the U.S. pulled up Binance, the world’s biggest crypto exchange, for allegedly violating trading mandates. Asian markets mostly gained on Tuesday, reflecting a rise in U.S. equities on Monday, as investor sentiment picked up following a slew of positive announcements that eased fears of a banking sector crisis.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin, the world’s biggest cryptocurrency by market cap, lost 3.06% to US$26,960 in 24 hours to 4 p.m. in Hong Kong, according to data from CoinMarketCap, declining 2.22% in the last seven days. Ethereum dropped 1.61% to US$1,724, after losing 0.6% on the week.</li>
            <li>BNB, the native token of the world’s largest crypto exchange Binance, saw the biggest decline among top 10 cryptos. The token dropped 5.19% in the last 24 hours to US$309, after the U.S. Commodity Futures Trading Commission said Monday it filed a “civil enforcement action” lawsuit against Binance and its top executives for allegedly breaking trading rules.</li>
            <li>XRP was the only token among top 10 cryptos to gain in Tuesday afternoon trade in Asia, climbing 5.61% to US$0.4843, and has risen 25.47% on the week. The gains come as Ripple Labs, whose payment network is powered by XRP, expects to win a lawsuit filed against it by the U.S. Securities and Exchange Commission for allegedly selling US$1.3 billion in unregistered securities.</li>
            <li>The global crypto market capitalization dropped 1.96% to US$1.13 trillion, while the total crypto market volume gained 30.1% to US$43.42 billion in the last 24 hours.</li>
            <li>The Forkast 500 NFT index fell 0.54% to 4,004.93 on the day and declined 3.34% on the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day.</li>
            <li>Asian equity markets mostly rose on Tuesday after concerns regarding a banking industry crisis eased. Hong Kong’s Hang Seng Index rose 1.11%, South Korea’s Kospi increased 1.07% and Japan’s Nikkei 225 gained 0.15%.</li>
            <li>The Shanghai Composite lost 0.19% and the Shenzhen Component Index dropped 0.72%, over concerns that Covid-19 related disruptions continue to hamper China’s economic recovery.</li>
            <li>Gold slid 0.24% to US$1,951 an ounce, after falling 1% on Monday. The precious metal remains under its one-year high of US$2,000 that it touched last week.</li>
            <li>European bourses rose for a second consecutive day. The benchmark STOXX 600 gained 0.34% and Germany’s DAX 40 advanced 0.5%.</li>
            <li>European Central Bank President Christine Lagarde will speak at the opening ceremony of the Bank for International Settlements’ Innovation Hub Eurosystem Centre later Tuesday.</li>
            <li>London’s benchmark FTSE 100 rose 0.43% during the day, after Bank of England Governor Andrew Bailey said the country’s financial system is resilient and has robust capital, but warned that interest rates may move higher.</li>
          </ul>
        </div>
      },
      {
        id: 295,
        title: 'Analyzing how Decentraland’s hype failed to aid MANA’s climb',
        category: 'Crypto',
        subCategory: ['MANAs', 'Climb', 'Decentraland'],
        description: <div className="blog-desc">
          <p>A large part of the crypto ecosystem experienced unusual inaction in the just-ended 24- 26 March weekend.</p>
        </div>,
        authorName: 'Victor Olanrewaju',
        authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 27, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-306.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Decentraland dominated the social space over the weekend.</li>
            <li>MANA’s price remains in the bearish region amid chances to exit the zone.</li>
          </ul>
          <p>A large part of the crypto ecosystem experienced unusual inaction in the just-ended 24- 26 March weekend.</p>
          <p>Strange because almost all other weekends in the month were filled with evident price rises. Despite the market sluggishness, Decentraland [MANA] jumped to the top of the most discussed out of the thousands of crypto projects.</p>
          <h3>Opium in the virtual land</h3>
          <p>According to Santiment, MANA’s social dominance rose sharply on 26 March. At press time, Ethereum [ETH] virtual world project maintained the hike at 0.354%.</p>
          <p>This increase implied that the Decentraland community actively participated in conversations around the token. And the depth of discussion was far above many others.</p>
          <p>However, the rise in social activity is not an inescapable condition for a positive price action or perception. This was further revealed by the weighted sentiment. The sentiment takes into account the unique social volume and measures the average subjective information about an asset.</p>
          <p>At the time of writing, MANA’s weighted sentiment was -0.388. This means that the impression that investors had towards the token was not necessarily optimistic. The MANA price decreased 1.74% in the last 24 hours, following a similar trend as most cryptocurrencies.</p>
          <p>Regardless, it did not seem that the token would go for a trend reversal anytime soon, as indicated by the Awesome Oscillator (AO). Based on the daily chart, the AO was -0.0161, at press time.</p>
          <p>This value suggested that the momentum was bearish. However, there was potential for a trend reversal with the emerging presence of green bars.</p>
          <p>In terms of volatility, the Bollinger Bands (BB) showed that MANA was contracting. And with the price evading both the upper and lower band, it meant that the token was not oversold or overbought, at the time of publication.</p>
          <h3>No accolades yet</h3>
          <p>Furthermore, the number of active addresses on the Decentrand network had significantly reduced. Active addresses show the number of unique participants that had successful transactions within a network.</p>
          <p>According to Glassnode, the last time MANA had a notable hike in this regard was 17 January. But at press time, these addresses were down to 729.</p>
          <p>Lastly, Decentrland has been somewhat quiet in this first quarter as there has been no notable development. Since this was the case, it is likely that MANA’s price action only responds to widespread crypto trends.</p>
        </div>
      },
      {
        id: 270,
        title: 'Bitcoin rises, most top 10 cryptos drop, amid mixed signals on state of banking sector',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Banking', 'Rises'],
        description: <div className="blog-desc">
          <p>Bitcoin rose while Ether and most of the other top 10 non-stablecoin cryptocurrencies fell during afternoon trading in Asia on Monday. U.S. and European equities strengthened while Asian equities were mixed, after the International Monetary Fund (IMF) warned of potential financial stability risks in the wake of the banking turmoil. U.S. investors saw some relief after North Carolina-based First Citizens BancShares bought all the loans of failed Silicon Valley Bank.</p>
        </div>,
        authorName: 'Zoltan Vardai',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 27, 2023 - 2:11 PM',
        cover: 'https://ncx.cx/images/ncx-blog-277.jpg',
        content: <div className="blog-content">
          <p>Bitcoin rose while Ether and most of the other top 10 non-stablecoin cryptocurrencies fell during afternoon trading in Asia on Monday. U.S. and European equities strengthened while Asian equities were mixed, after the International Monetary Fund (IMF) warned of potential financial stability risks in the wake of the banking turmoil. U.S. investors saw some relief after North Carolina-based First Citizens BancShares bought all the loans of failed Silicon Valley Bank.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin rose 1.01% to US$27,806 in the 24 hours to 4:00 p.m. in Hong Kong. The world’s first cryptocurrency hovered around US$28,000 for most of last week, amid looming concerns of a potential banking crisis.</li>
            <li>Ether fell 0.12% to US$1,752 in the same timeframe, making for a weekly drop of 2.48%.</li>
            <li>Bitcoin saw the day’s biggest gains, followed by Binance’s BNB token that rose 0.62% to change hands at US$326.24. Cardano’s ADA saw the biggest drop, as it fell 1.81% to US$0.35.</li>
            <li>The global cryptocurrency market capitalization increased by 0.32% to US$1.16 trillion in the 24 hours to 4 p.m. in Hong Kong, with total crypto market trading volume up by 6.93% to US$33.32 billion.</li>
            <li>The Forkast 500 NFT index fell 1.49% to 4,021.44 points, losing 2.68% during the week. The index is a proxy measure of the performance of the global NFT market and includes 500 eligible smart contracts on any given day.</li>
            <li>U.S. investor concerns over a banking crisis partially eased after First Citizens BancShares, the parent company of First Citizens Bank, bought all the loans and deposits of failed Silicon Valley Bank, resulting in a 0.56% gain for the S&P 500.</li>
            <li>Asian equities were mixed on Monday, after the IMF’s managing director Kristalina Georgieva warned on Sunday that financial stability risks have increased after a banking sector crisis in the U.S. and Europe earlier this month. Georgieva also said that global growth would slow to just 3% this year due to war in Ukraine, Covid-19 related restrictions and monetary policy tightening to tackle sticky inflation.</li>
            <li>The Shanghai Composite slid 0.44% while the Shenzhen Component Index inched up 0.12%. Japan’s Nikkei 225 rose 0.33% and Hong Kong’s Hang Seng index dropped 1.75%.</li>
            <li>European bourses saw a rebound after a steep sell-off last week, led by gains in the banking sector. The benchmark STOXX 600 rose 0.97% and Germany’s DAX 40 gained 1.11%.</li>
            <li>Gold fell 0.45% to US$1,968 an ounce on Monday, after testing the US$2,000 mark multiple times last week. The U.S. dollar is struggling to recover from a seven-week low, after St. Louis Fed President James Bullard clarified last Friday that the U.S. central bank is planning further monetary tightening.</li>
          </ul>
        </div>
      },
      {
        id: 271,
        title: 'What impact will Ethereum’s Shanghai upgrade have on ETH and crypto markets?',
        category: 'Crypto',
        subCategory: ['ETH', 'Crypto', 'Market'],
        description: <div className="blog-desc">
          <p>Ethereum’s Shanghai upgrade is upon us and for the first time, individuals who have “staked” their ETH as part of the network’s security mechanism will be able to withdraw their holdings. Will this create huge sell pressure for the second-largest cryptocurrency? Hardly.</p>
        </div>,
        authorName: 'Bill Xing',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 27, 2023 - 1:01 PM',
        cover: 'https://ncx.cx/images/ncx-blog-278.jpg',
        content: <div className="blog-content">
          <h3>In this article:</h3>
          <p>Ethereum’s Shanghai upgrade is upon us and for the first time, individuals who have “staked” their ETH as part of the network’s security mechanism will be able to withdraw their holdings. Will this create huge sell pressure for the second-largest cryptocurrency? Hardly.</p>
          <p>After the Shanghai upgrade (or “hard fork”), users who had locked a minimum of 32 ETH to secure an APY staking reward of 4-5% will be able to withdraw a portion of their stake immediately. But those wishing to withdraw their entire stake will need to wait one to 36 days, which dampens potential sell pressure. Furthermore, only 16% of ETH stakers are in profit, so why would they sell now?</p>
          <p>In fact, for rational investors, selling ETH now would likely make little sense. And the investor profile for ETH holders leans towards rationality and a long-term outlook. It’s arguably one of the world’s premier risk assets, and the only reasons investors are still on the sidelines may be because they don’t understand it, they fear its ferocious volatility, or they find the regulatory uncertainty to be unnerving.</p>
          <p>But the answer to the first objection also answers the second, and will go a long way toward answering the final objection. By the end, you may grok the thesis that Ether — in the crypto vernacular — isn’t just “sound money” it’s “ultrasound money.”</p>
          <p>Ethereum is a blockchain that sells block space to apps. The apps must pay for transactions using the blockchain’s native currency: ETH.</p>
          <p>The demand side of the trade relies on simple network effects, the more apps, the more users, the more demand for ETH. And this is because the Ethereum network is the oldest and most established decentralized smart contract ecosystem out there.</p>
          <p>Ethereum and Bitcoin networks were analyzed by researcher Ken Alabi in a 2017 paper. “The analysis shows that the networks were fairly well modeled by Metcalfe’s Law, which identifies the value of a network as proportional to the square of the number of its nodes, or end users.” This means that adoption can increase the value of any given network exponentially.</p>
          <p>On the supply side, ETH is issued as compensation to independent actors who run network nodes, and this currently amounts to 671,000 ETH per year. However, thanks to a novel burn mechanism that destroys a small amount of ETH per transaction (by sending it to a wallet from which it can never be withdrawn), the supply of ETH is actually shrinking despite token issuance.</p>
          <p>Ultrasound.money is a dashboard that tracks the burn rate in real-time showing that the supply of ETH has been shrinking for months — and that’s in the middle of a raging bear market, imagine what would happen when demand picks up again. In fact, despite the bear market, transactions on Ethereum are still maintaining a solid uptrend thanks to innovations like NFTs and decentralized finance.</p>
          <p>Volatility should be ironed out slowly as the effects of network adoption take place. The more people who join the Ethereum network, the more the value of ETH increases, which leads to more adoption. As the market cap increases it will take larger and larger market movements to rock the boat, reducing the large deviations in the price.</p>
          <p>The best that crypto-bashing regulators can hope for is to slow down the pace of adoption. But there’s not much incentive for most governments to support this approach because a) crypto, on the retail side, is driven by Millennials and Generation Z, so it will be a voter issue in the coming years, and b) it will only move innovation offshore.</p>
          <p>Even as the U.S. is putting a squeeze on crypto, Dubai, Hong Kong and the U.K. are already preparing to scoop up exiled crypto companies. And even if regulators shoehorn ETH into the antiquated category of a security — so what? Anyone could buy securities in minutes using an app on a phone.</p>
          <p>The upcoming Shanghai upgrade will be followed by a more complex upgrade that will reduce fees on the Ethereum network, making it faster and even more user-friendly. The genie is well and truly out of the bottle, and investors may well find a magic carpet beckoning.</p>
        </div>
      },
      {
        id: 296,
        title: 'Polygon’s NFT space is booming as trade count surges, thanks to…',
        category: 'Crypto',
        subCategory: ['Polygon', 'NFT', 'trade'],
        description: <div className="blog-desc">
          <p>Polygon [MATIC] recently witnessed a major boom in its NFT ecosystem, suggesting growth. Santiment’s chart showed that Polygon’s total NFT trade count and trade volume in USD increased considerably since 16 March 2023.</p>
        </div>,
        authorName: 'Dipayan Mitra',
        authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 26, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-307.jpg',
        content: <div className="blog-content">
          <ul>
            <li>y00ts announced it will migrate to Polygon on 27 March 2023.</li>
            <li>A day remains until the zkEVM release but MATIC continues to suffer.</li>
          </ul>
          <p>Polygon [MATIC] recently witnessed a major boom in its NFT ecosystem, suggesting growth. Santiment’s chart showed that Polygon’s total NFT trade count and trade volume in USD increased considerably since 16 March 2023.</p>
          <p>Thanks to the increase in trade counts, the number of unique addresses holding NFTs have also increased.</p>
          <h3>What can fuel further growth?</h3>
          <p>Things might get even better in the coming days as y00ts, one of the most popular NFT collections on Solana [SOL], announced that it will migrate to Polygon on 27 March.</p>
          <p>As per the announcement, users will be able to directly migrate from y00ts website. Not only can holders simply migrate, but they will also be rewarded for the same. In fact, holders will be rewarded with $5 USDC per y00t listed on Magic Eden.</p>
          <p>Additionally, the official tweet mentioned that y00ts is disapproving of further development for y00ts/DeGods on SOL.</p>
          <p>The reason why this can help Polygon is that for the next 30 days, there will be no trading fees for y00ts on Magic Eden. Therefore, holders and new users can be motivated to buy or sell more, helping Polygon’s NFT ecosystem grow further.</p>
          <h3>Polygon is all set for the zkEVM launch</h3>
          <p>While y00ts will migrate to Polygon, the blockchain will be launching its much-awaited zkEVM. As the network prepares for the launch, it has started a bug bounty program.</p>
          <p>The bounty program, which will be run by Immunefi, is one of the many safeguards in place for the upcoming release of mainnet beta.</p>
          <p>This program will further help Polygon in refining its product. Well, its competitor, Ethereum’s [ETH] layer-2 solution, zkSync Era, was fully opened to the public on 25 March 2023.</p>
          <p>Beating Polygon, the zkSync Era became the first EVM with the support of zero-knowledge rollup (zk-rollup) to be launched on the mainnet.</p>
          <h3>MATIC performance was not at par</h3>
          <p>While the NFT ecosystem witnessed growth, the same was not true for MATIC, which has fallen victim to the bears. According to CoinMarketCap, MATIC’s price declined by over 8% in the last week. At press time, it was trading at $1.09 with a market capitalization of over $9.8 billion.</p>
          <p>Due to the decline, MATIC’s MVRV ratio plummeted sharply. The hype around the zkEVM launch also was not enough to give investors confidence as MATIC’s weighted sentiment remained on the negative side.</p>
          <p>The future of MATIC post-zkEVM launch remains to be seen.</p>
        </div>
      },
      {
        id: 215,
        title: 'Chainlink receives fresh attention from whales, but here’s a problem',
        category: 'Crypto',
        subCategory: ['Chainlink', 'Whales', 'Crypto'],
        description: <div className="blog-desc">
          <p>Chainlink [LINK] has been witnessing a constant decline in its number of holders lately. Dune’s data revealed that the daily number of wallets with 1+ LINK has been on a declining trend for months. </p>
        </div>,
        authorName: 'Dipayan Mitra',
        authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 26, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-221.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Gas spent and the daily wallets with 1+ LINK were on a declining trend, at press time. </li>
            <li>Metrics showed signs of recovery, but LINK’s price action was bearish. </li>
          </ul>
          <p>Chainlink [LINK] has been witnessing a constant decline in its number of holders lately. Dune’s data revealed that the daily number of wallets with 1+ LINK has been on a declining trend for months. </p>
          <p>And as per Market.link, gas spent on the chain has also gone down, suggesting less usage of the Chainlink network. </p>
          <h3>Can LINK regain users? </h3>
          <p>While the number of users on the network declined, whales still preferred LINK over the rest of the cryptocurrencies.</p>
          <p>Consider this- LINK recently flipped Shiba Inu [SHIB] to become the most traded token among the top 500 Ethereum [ETH] whales.</p>
          <p>Additionally, LINK has also been stepping up its efforts to increase adoption, which can play a role in attracting new users to the network.</p>
          <p>One of the latest ones was the integration with SteakHut, which has equipped Chainlink Automation to support automated liquidity management.</p>
          <p>The official announcement mentioned that SteakHut decided to use Chainlink Automation to monitor the state of smart contracts and perform advanced liquidity management on-chain because it is the most reliable Web3 automation service. </p>
          <p>As per Santiment, LINK’s network growth remained relatively high, indicating that a larger number of new accounts were used to transfer the token. The velocity also followed a similar trend, which too suggested high network activity.</p>
          <p>However, negative sentiments around LINK were dominant in the market. Well, this can cause trouble for LINK since investors’ faith seems to be dwindling. </p>
          <h3>LINK is feeling the heat</h3>
          <p>Though there were signs of recovery on the network front, LINK’s price action aligned with the sellers’ interests. According to CoinMarketCap, the price of LINK dropped by more than 3% in the last 24 hours, and it was trading at $7.13, at the time of writing.</p>
          <p>Nonetheless, much like network activity, LINK’s on-chain metrics gave hope for better days.</p>
          <p>For instance, LINK’s supply on exchanges declined while its supply outside of exchanges went up.</p>
          <p>Furthermore, LINK’s demand in the derivatives market also remained high, as suggested by its Binance funding rate. This might help LINK change its price trajectory in the coming days.</p>
          <h3>More selling pressure soon? </h3>
          <p>Meanwhile, LINK’s daily chart revealed that the sellers might soon increase their pressure. At press time, LINK’s Money Flow Index (MFI) was in the overbought zone. In fact, the coin’s Relative Strength Index (RSI) also registered a downtick, which was a development in the bear’s favor.</p>
          <p>However, its Exponential Moving Average (EMA) Ribbon displayed a slight bullish crossover. The MACD supported EMA readings.</p>
          <p>However, the entry of selling forces in the near term can’t be ruled out. </p>
        </div>
      },
      {
        id: 214,
        title: 'Ethereum holders might be elated to know this about exchange balance',
        category: 'Crypto',
        subCategory: ['Ethereum', 'exchange', 'Crypto'],
        description: <div className="blog-desc">
          <p>The beginning of the year ushered in a whirlwind of events that significantly influenced the crypto industry, with Ethereum being (ETH) no exception.</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 26, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-220.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Ethereum exchange balance hit a five-year low as more holders moved their assets to self-custody options and DeFi platforms.</li>
            <li>The decline in exchange balance can be attributed to factors such as the rise of DeFi, the switch to PoS, and the downturn in the crypto market.</li>
          </ul>
          <p>The beginning of the year ushered in a whirlwind of events that significantly influenced the crypto industry, with Ethereum being (ETH) no exception.</p>
          <p>The current state of affairs, including the SEC’s regulatory crackdown and potential bank runs, has undoubtedly left ETH holders feeling disillusioned. However, other factors might be responsible for the dwindling ETH exchange balance.</p>
          <h3>Ethereum exchange balance declines</h3>
          <p>In 2022, the FTX crash sent shockwaves through the crypto world, causing many holders to question the safety of keeping their assets on exchanges.</p>
          <p>The incident sparked a renewed interest in self-custody to secure crypto holdings. However, while Ethereum experienced a decrease in exchange balances in the months following the crash, this trend can be attributed to other factors beyond fear of exchange insecurity.</p>
          <h3>Ethereum exchange netflow flashes negative</h3>
          <p>According to a recent Glassnode chart from Glassnode alerts, the balance of Ethereum held on exchanges has been steadily decreasing.</p>
          <p>As of this writing, the exchange balance had reached a five-year low, hovering just above $18 million. This trend indicates that more ETH holders are opting for alternative storage methods rather than leaving their assets on exchanges.</p>
          <p>In addition, a closer examination of Ethereum’s exchange netflow reveals that the outflow of ETH from exchanges has exceeded the inflow, with few exceptions of inflow spikes.</p>
          <p>Currently, the netflow of ETH on exchanges remains negative, with outflow continuing to dominate. At the time of writing, the netflow had surpassed 11,000 ETH already, highlighting the ongoing trend of ETH holders moving their assets away from exchanges.</p>
          <h3>Possible reasons for declining exchange balance</h3>
          <p>One possible factor is the rise of decentralized finance (DeFi) platforms built on the Ethereum network. Many holders have moved their funds from centralized exchanges to DeFi protocols to earn higher yields.</p>
          <p>The yields come through liquidity provision, staking, or other forms of participation in decentralized finance. Also, ETH stakes account for 15% of the coins in circulation per staking rewards.</p>
          <p>It is also possible that some holders took a more long-term investment approach by holding their assets in personal wallets. It is a means of storing value and avoiding short-term trading risks.</p>
          <p>Also, the crypto market experienced a downturn in the latter half of 2022. The downturn may have led some holders to move their assets off exchanges and into personal wallets.</p>
          <h3>Daily timeframe move and 365-day MVRV</h3>
          <p>Despite experiencing a decent price run, Ethereum (ETH) had yet to regain the price zone it dropped back in May. As of this writing, it was trading at approximately $1,740 and had sustained losses for two consecutive days. However, ETH had maintained a support level of around $1,732 and $1,630, previously resistance levels. </p>
          <p>The 365-day Market Value to Realized Value ratio (MVRV) revealed that for most of the period being analyzed, ETH was trading below zero.</p>
          <p>However, as of this writing, the MVRV had surpassed the zero line and currently sat at 13.60%. This indicated that, on average, the holders of ETH were now profitable, given the price at which they acquired their coins.</p>
        </div>
      },
      {
        id: 213,
        title: 'Bitcoin [BTC] bears gain edge against the bulls- Is it right time to short',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'BTC', 'bulls'],
        description: <div className="blog-desc">
          <p>In the last few days, we have seen Bitcoin [BTC] struggle to sustain its upside. Now the market is showing some bullish weakness and the bears are trying to take advantage. The next few days might bring forth a significant pullback and here’s why.</p>
        </div>,
        authorName: 'Michael Nderitu',
        authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 26, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-219.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Bitcoin experienced a net outflow in the last three days; short positions may not be a good idea.</li>
            <li>Bitcoin whales demonstrate mixed reactions that explain the current stalemate.</li>
          </ul>
          <p>In the last few days, we have seen Bitcoin [BTC] struggle to sustain its upside. Now the market is showing some bullish weakness and the bears are trying to take advantage. The next few days might bring forth a significant pullback and here’s why.</p>
          <p>Recent data suggests that Bitcoin’s failure to continue rallying past the $28,300 resistance level yielded a loss of confidence among investors. The situation was further exasperated by miner outflows which are currently at a monthly high according to the latest glassnode data.</p>
          <p>Bitcoin miner outflows are often considered a confidence indicator. This is because miners often hold on to their BTC when there are bullish expectations and sell when there are bearish expectations.</p>
          <p>It is thus not surprising that they are offloading some BTC, right after Bitcoin struggled to push beyond its current resistance level.</p>
          <h3>Will Bitcoin outflows lend favor to the bears?</h3>
          <p>The Bitcoin miner observations also align with BTC’s -$119 million net flows in the last 24 hours according to glassnode alerts. This is slightly lower than the -$190.5 million net flow on 24 March and even lower than the -$342 million net flow on 23 March.</p>
          <p>It is important to note that the net flows confirm that sell pressure has also been slowing down. In other words, Bitcoin might not necessarily be in for a strong bearish pullback this week contrary to expectations. This will, however, depend on the possibility of market events that may swiftly change the outcome.</p>
          <p>The lower net flows may explain why short sellers may not necessarily be keen on executing leveraged positions. Bitcoin’s estimated leverage ratio is currently down to its lowest level since the start of 2023. Meanwhile, funding rates have leveled out for the most part.</p>
          <p>The lack of confidence among short sellers and leverage traders reflects mixed observations with Bitcoin’s supply distribution. For example, addresses holding between 10,000 and 100,000 BTC have been buying in the last three days, hence providing a cushion for the price. The same whale category currently controls 12.13% of the circulating supply.</p>
          <p>On the other hand, addresses holding between 1,000 and 10,000 BTC have been selling in the last three days. The same addresses currently control 23% of the total BTC supply, hence their impact is more pronounced.</p>
        </div>
      },
      {
        id: 212,
        title: 'Polkadot [DOT]: Pall of uncertainty over price despite surging metrics',
        category: 'Crypto',
        subCategory: ['Polkadot', 'Price', 'Uncertainty'],
        description: <div className="blog-desc">
          <p>Layer 0 blockchain Polkadot [DOT] has emerged as the leading blockchain network in terms of development activity in the last 30 days, new data from Santiment revealed. </p>
        </div>,
        authorName: 'Abiodun Oladokun',
        authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 26, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-218.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Polkadot was the blockchain network with the highest development activity in the last 30 days.</li>
            <li>DOT traded within a narrow range in the last week as investors remain unsure of the coin’s next direction.</li>
          </ul>
          <p>Layer 0 blockchain Polkadot [DOT] has emerged as the leading blockchain network in terms of development activity in the last 30 days, new data from Santiment revealed. </p>
          <p>According to the on-chain data provider, Polkadot GitHub commits during the period under review totaled 591.47, tying with Kusama. The Ethereum [ETH] network, on the other hand, logged a development activity count of 387.67, putting it four places behind Polkadot.</p>
          <p>Development activity is an important metric as it offers insights into a crypto project’s commitment to creating a working product and the likelihood of shipping new features. In addition, a high development activity often reduces the possibility of the project being an exit scam.</p>
          <h3>DOT wading through troubled waters</h3>
          <p>Despite sharing a statistically significant positive correlation with Bitcoin [BTC], whose value jumped by 13% in the last 30 days, DOT’s value decreased by 17% during the same period. </p>
          <p>The drop in DOT’s value occurred despite the optimistic sentiment that lingered in the cryptocurrency market and investors’ increased accumulation in the face of uncertain macro factors. </p>
          <p>According to data from CoinMarketCap, the altcoin exchanged hands at $6.04 at press time.</p>
          <p>The decline in value is partly attributable to the fall in the token’s Open Interests since 17 February. According to Coinglass, DOT’s Open Interest has since fallen by 25%.</p>
          <p>When a crypto asset’s Open Interest falls, it indicates reduced trading activity, as investors may be closing their positions or taking profits. </p>
          <h3>Up or down, who knows?</h3>
          <p>An assessment of DOT’s movement on a daily chart revealed severe price volatility in the first 19 days of March. However, in the last week, investors appeared to have stayed their hands as many remain unsure of the coin’s next direction. </p>
          <p>Since 20 March, DOT’s price was at the middle line of the Bollinger Bands indicator, and remained so at press time. When the asset’s price is on the middle line of the Bollinger Bands, it indicates that the asset’s price is in a neutral position, and there is no clear trend in either direction. It is generally considered a “wait-and-see” situation for traders and investors until there is a clearer signal of where the asset’s price is heading.</p>
          <p>While DOT’s price oscillated within a narrow range in the last week, its Relative Strength Index (RSI) has dipped, while its Money Flow Index (MFI) embarked on an uptrend. This suggested that some buying activity was underway despite the decline in RSI.</p>
          <p>However, the neutral price action indicates that the market remains uncertain about the asset’s direction.</p>
        </div>
      },
      {
        id: 211,
        title: 'Shiba Inu: This new update might end FUD surrounding Shibarium',
        category: 'Crypto',
        subCategory: ['Shiba inu', 'Shibarium', 'FUD'],
        description: <div className="blog-desc">
          <p>The FUD which gripped the Shiba Inu [SHIB] ecosystem since the testnet launch of layer-2 blockchain Shibarium, could soon be a thing of the past.</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 26, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-217.jpg',
        content: <div className="blog-content">
          <ul>
            <li>The chain ID of the Shibarium testnet was a source of significant FUD.</li>
            <li>Daily transactions on the test network zoomed to 718 on 24 March.</li>
          </ul>
          <p>The FUD which gripped the Shiba Inu [SHIB] ecosystem since the testnet launch of layer-2 blockchain Shibarium, could soon be a thing of the past.</p>
          <p>According to the latest update provided by a SHIB influencer, the chain ID of the Shibarium testnet, also called ‘Puppynet’, has been changed. As per a screenshot posted in the same thread, the new chain ID was 719.</p>
          <h3>Basis of the FUD</h3>
          <p>The chain ID of the Shibarium testnet became a bone of contention as soon as it was launched. A Shiba Inu discord channel member pointed out that Shibarium’s chain ID shared the same ID as the Rinia testnet blockchain.</p>
          <p>The member insinuated that Shibarium might have copied the genesis file from the Rinia testnet and changed their name to Shibarium without changing the chain ID.</p>
          <p>While Shibarium developers termed this as a coincidence, the revelation caused much furor in the SHIB community and the price of SHIB fell by more than 8%.</p>
          <p>However, after the announcement of a new chain ID, the FUD might subside. And there were strong positive signs coming from Puppyscan, the Puppynet explorer. The daily transactions on the network zoomed to 718 on 24 March, after staying lulled for the last few days.</p>
          <p>Furthermore, the Shibarium team was also working on the faucet update. Faucets are used to obtain the test cryptocurrency needed to interact with the testnet blockchain. The Shibarium faucet will utilize a BONE token in order to allow users and developers to perform tests on the network.</p>
          <h3>Whales are back in business?</h3>
          <p>Meanwhile, SHIB exchanged hands at $0.00001055 at the time of writing, falling by 1.21% over the previous day, per CoinMarketCap.</p>
          <p>With clarity on chain ID in place, big addresses were attracted to SHIB. As per Santiment, the supply held by large addresses as a percentage of the total supply increased over the last two days.</p>
          <p>This could be due to whales amassing SHIB in large quantities as the number of transactions transferring more than $1 million rose.</p>
          <p>However, despite the increase in transaction numbers, data from Shibburn revealed that the burn rate declined by over 92% in the last 24 hours, with a little over 1.34 million tokens removed from circulation.</p>
        </div>
      },
      {
        id: 210,
        title: 'Solana: Assessing the state of NFT prices and trading volume',
        category: 'Crypto',
        subCategory: ['Solana', 'NFT', 'Prices'],
        description: <div className="blog-desc">
          <p>Despite the re-emergence of interest in profile picture NFTs  (PFPs) since the beginning of the year, Solana [SOL] has witnessed a steady decline in NFT activity. </p>
        </div>,
        authorName: 'Abiodun Oladokun',
        authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 26, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-215.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Solana has seen a drop in NFT activity since the year started.</li>
            <li>SOL’s value has grown tremendously in the last three months.</li>
          </ul>
          <p>Despite the re-emergence of interest in profile picture NFTs  (PFPs) since the beginning of the year, Solana [SOL] has witnessed a steady decline in NFT activity. </p>
          <p>According to data from Dune Analytics, the network has seen a steep decline in its count of monthly NFT trades completed since September 2022. With 349,937 NFT trades completed on Solana this month, there has been a 63% drop from the 944,010 total NFT trades completed in January. </p>
          <p>As a result of a consistent fall in NFT trade count on Solana since the year started, the network has also experienced a drop in sales volume. With a sales volume of $35.35 million recorded so far this month, Solana has witnessed a 64% decrease from the $99.37 million registered as total NFT sales volume in January. In the last month alone, this fell by 22%. </p>
          <h3>Solana marketplaces are the biggest losers</h3>
          <p>Due to a decline in NFT activity on Solana, the NFT marketplaces native to the chain have faced a persistent fall in sales volume, buyer and seller count, and revenue. </p>
          <p>Formfunction, a Solana-based NFT marketplace, recently announced its decision to stop operating after only 13 months of operation due to a decline in Solana NFT prices and trading volumes. </p>
          <p>Also, leading NFT marketplace Magic Eden has seen a drop in NFT activity and a decline in its market share. For example, the weekly count of NFT transactions completed on Magic Eden has dropped by 75% since the first week of the year. </p>
          <p>Likewise, the number of active traders using the marketplace has also trended downward since the year started. Per data from Dune Analytics, this has since fallen by 31%.</p>
          <h3>SOL amid all this</h3>
          <p>Thanks to the rally in the general cryptocurrency market since the year began, SOL’s value has surged by 105% on a year-to-date basis. At press time, the altcoin exchanged hands at $20.54 per SOL, data from CoinMarketCap revealed. </p>
          <p>With increased coin accumulation in the last week, SOL buyers were spotted in control of the market on a daily chart at press time.</p>
          <p>The Directional Movement Index (DMI) indicator put the buyers’ strength (green) at 19.85, above the sellers’ (red) at 16.69.</p>
          <p>Likewise, SOL’s Money Flow Index (MFI) rested above the neutral 50 line at 64.57, indicating growing buying momentum. </p>
        </div>
      },
      {
        id: 209,
        title: 'Tron sees expansion, but this impending danger can’t be ignored',
        category: 'Crypto',
        subCategory: ['Tron', 'expansion', 'crypto'],
        description: <div className="blog-desc">
          <p>The TRON [TRX] ecosystem shared a report on the network’s notable developments that took place over the week that went by.</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 26, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-214.jpg',
        content: <div className="blog-content">
          <u>
            <li>The total stablecoin market cap of Tron grew by 5% over the last week.</li>
            <li>The TVL on the network dropped 3% since the SEC launched a probe against the TRON foundation.</li>
          </u>
          <p>The TRON [TRX] ecosystem shared a report on the network’s notable developments that took place over the week that went by.</p>
          <p>One of the major takeaways was the network’s increasing dominance in the stablecoin sector.  Tether [USDT], the largest stablecoin by market cap, saw its circulating supply on Tron reach a record high. At press time, it was over 44 billion, as per Tronscan.</p>
          <p>Additional data from DeFiLlama revealed that the total stablecoin market cap on the network grew by 5% over the last week to $43.57 billion.</p>
          <p>This was in stark contrast to the chain with the highest stablecoin market cap, Ethereum [ETH], which registered a weekly drop of 2.3%.</p>
          <h3>Network expands thanks to stablecoins</h3>
          <p>Tron became a go-to platform for stablecoins ever since the USD Coin [USDC] depegging episode which rattled the broader crypto market. In fact, a day after the fateful event, the stablecoin inflows into the network reached a three-month high of $1.17 billion.</p>
          <p>The remarkable growth prompted Tron founder, Justin Sun, to announce an ambitious plan of growing the stablecoin market cap to $60 billion.</p>
          <p>It became evident that stablecoin-dominated trading powered the whole network. Since the stablecoin turmoil, the revenue generated on the chain surged 70% until 24 March while the daily user base expanded by 21%.</p>
          <p>The growth in revenue propelled Tron as the second-best performing chain over the last week.</p>
          <h3>Next challenge for TRX</h3>
          <p>On the flip side, the total funds deposited on the network’s smart contracts fell significantly, sparking liquidity concerns. Since March 2022, the total value locked (TVL) dropped 3% until press time, as per DeFiLlama.</p>
          <p>Notably, this happened after the United States Securities and Exchanges Commission (SEC) initiated a probe against the Tron foundation and Justin Sun over charges of unregistered sale of TRX and BTT which the regulator termed as securities.</p>
          <p>Following this, TRX’s price tumbled 13%. However, the coin recovered to its press time value of $0.06375, per data from CoinMarketCap.</p>
          <p>Meanwhile, according to Tronscan, more than 18 million TRX tokens were moved out of circulation in the last 24 hours. Adjusted against newly minted tokens, there was a net decrease in the circulating supply of TRX  by 13.4 million.</p>
        </div>
      },
      {
        id: 208,
        title: 'Cosmos heading to crucial $10.58 – shorting opportunity limited?',
        category: 'Crypto',
        subCategory: ['Cosmos', 'crypto',],
        description: <div className="blog-desc">
          <p>Cosmos [ATOM] has depreciated by over 15%, clearing gains from the recovery in mid-March. ATOM dropped from $13.5 and was operating at the $11 price level at the time of writing. A retest of a crucial support level could offer new buying opportunities at discounted prices.</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 26, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-212.jpg',
        content: <div className="blog-content">
          <ul>
            <li>ATOM was bearish on the 12-hour chart.</li>
            <li>Funding rates declined, but development activity improved slightly.</li>
          </ul>
          <p>Cosmos [ATOM] has depreciated by over 15%, clearing gains from the recovery in mid-March. ATOM dropped from $13.5 and was operating at the $11 price level at the time of writing. A retest of a crucial support level could offer new buying opportunities at discounted prices.</p>
          <p>At press time, Bitcoin’s [BTC] value was below $28K and could further exert selling pressure on ATOM to retest this key support level.</p>
          <h3>The $10.58 support – Can ATOM bulls prevail?</h3>
          <img src="https://ncx.cx/images/ncx-blog-212-01jpg.png" alt="ncx-blogs" />
          <p>After facing price rejection at $15.5 in January, ATOM has weathered selling pressure that has seen it drop to $10.58. Bulls successfully recovered from the $10.58, but the second leg of the rally was interrupted by the obstacle at $13.5.</p>
          <p>ATOM’s retracement could set it to retest the $10.58 support, and a rebound could be likely if the bulls defend it. A pullback retest on the support could offer long positions targeting 50 EMA, 200 EMA, or $13.5.</p>
          <p>A close above $13.5 could usher ATOM into the second leg of the rally with a target of $15.5 if the bulls clear the $14.77 hurdle.</p>
          <p>On the other hand, ATOM could depreciate further to $9.57 if bulls fail to defend the $10.58 support. As such, the downswing could offer short-sellers extra shorting opportunities at $9.57 if ATOM closes below $10.58.</p>
          <p>The Relative Strength Index (RSI) retreated from the upper ranges and fluctuated below the 50 mark, showing buying pressure declined. Similarly, the Average Directional Index (ADX) dropped, suggesting a likely further retracement or consolidation.</p>
          <h3>Funding rate was negative: development activity improved</h3>
          <img src="https://ncx.cx/images/ncx-blog-212-02.png" alt="ncx-blogs" />
          <p>According to Santiment, funding rates flashed red at press time – an indication of bearish sentiment in the derivatives market. Moreover, the weighted sentiment had dropped significantly but remained positive, which could give bulls little hope.</p>
          <p>Development activity has declined since mid-March but showed signs of improvement at press time. The trend could bolster investors’ confidence and prop up ATOM’s value in the long run. Investors should track BTC’s performance to make better moves.</p>
        </div>
      },
      {
        id: 221,
        title: 'Cardano [ADA] hits a price ceiling of $0.388 – Are more gains unlikely?',
        category: 'Crypto',
        subCategory: ['Cardano', 'ADA', 'Prices'],
        description: <div className="blog-desc">
          <p>Cardano’s [ADA] recovery continues to face challenges but has clawed back some of the gains lost after retracement from mid-February. At press time, ADA hit a price ceiling of $0.3876, and bears seemed determined to sink to key support at $0.3578.</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 25, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-227.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Price action faced an obstacle at $0.3876.</li>
            <li>Aggregated CVD (cumulative volume delta) showed sellers had leverage. </li>
          </ul>
          <p>Cardano’s [ADA] recovery continues to face challenges but has clawed back some of the gains lost after retracement from mid-February. At press time, ADA hit a price ceiling of $0.3876, and bears seemed determined to sink to key support at $0.3578.</p>
          <p>So far, the $0.3578 has prevented a dip plunge twice, giving a reprieve to bulls. The support could be steady if BTC maintains a hold on $28K. However, bulls’ efforts could be complicated if BTC drops below $28K.</p>
          <h3>Will the $0.3578 support hold?</h3>
          <p>The retracement from mid-February saw ADA depreciate by 28%, dropping to $0.30. But the low price saw increased demand, tipping bulls for a recovery. Price action oscillated between $0.3236 – $0.3578 before breaking above the range after BTC zoomed to the $28K area.</p>
          <p>Bears could sink ADA to retest the $0.3578 support. But a close below the support could attract a more aggressive price dump that could push ADA towards the lower support at $0.3236, especially if BTC drops below $28K.</p>
          <p>But bulls could gain leverage if ADA closes above the price range upper level of $0.3876. Such an upswing could push the price toward $0.4056 or the overhead resistance level of $0.4201.</p>
          <p>The Relative Strength Index (RSI) faced rejection below the 60-mark and moved sideways after retreating from the oversold zone. It shows buying pressure eased. The Chaikin Money Flow (CMF) moved south, showing bears had more influence in the market.</p>
          <h3>CVD slope turned negative</h3>
          <p>According to Coinalyze, the aggregated CVD (Cumulative Volume Delta) slope turned negative and moved downwards, indicating that sellers were more dominant in the market at press time. Therefore a retest of the $0.3578 was highly likely, and a breach below could occur if BTC falls below $28K.</p>
          <p>However, more short positions ($445K) have been liquidated in the past 24 hours compared to long positions ($345K). It shows an underlying bullish sentiment which could offer bulls hopes of a possible rebound at $0.3578. But investors should track BTC’s price movements for more profitable moves.</p>
        </div>
      },
      {
        id: 220,
        title: 'Tether releases Q1 USDT profit estimate amid growing FUD',
        category: 'Crypto',
        subCategory: ['Tether', 'USDT', 'FUD'],
        description: <div className="blog-desc">
          <p>Tether has expressed optimism about its profits in Q1, especially now that March is approaching its conclusion. The demand for USDT surged in during the quarterly period for numerous reasons but the road ahead might have some bumps.</p>
        </div>,
        authorName: 'Michael Nderitu',
        authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 25, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-226.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Tether expects its Q1 earnings at roughly $700 million.</li>
            <li>The SEC launches an assault on stablecoins and USDT might be caught in the crosshairs.</li>
          </ul>
          <p>Tether has expressed optimism about its profits in Q1, especially now that March is approaching its conclusion. The demand for USDT surged in during the quarterly period for numerous reasons but the road ahead might have some bumps.</p>
          <p>According to the latest reports, Tether expects more than $1 billion in total revenue from USDT in Q1. It also anticipates a $700 million profit for the quarter.</p>
          <p>The first three months of March have so far been quite eventful for USDT and the rest of the stablecoin segment following USDC’s depegging.</p>
          <p>USDT saw a large influx of volumes especially as many people migrated from USDC. This new volume added to Tether’s transaction revenue generated from USDT transactions.</p>
          <p>Tether also increased USDT supply in Q1 and continues to increase it further, according to recent data. There was roughly $77.6.14 billion worth of the stablecoin in circulation on Thursday according to the latest Glassnode data.</p>
          <p>But can this surge in circulating supply match the prevailing stablecoin demand? A comparison between active addresses and transfer volumes may provide some interesting insights.</p>
          <p>Active addresses peaked in mid-February, during which there was a surge in daily transfer volume. This is likely because of the outflows as the market saw ample demand.</p>
          <p>Interestingly, the USDT transfer volume had its highest peak on 11 March. This was mainly because of the aforementioned inflows due to USDC migration.</p>
          <p>However, transfer volume dropped since then due to outflows as demand for cryptocurrencies surged.</p>
          <h3>U.S. regulator finds inroad to criticize stablecoins</h3>
          <p>USDT’s growing circulation might be in preparation for more demand but it has not been without concerns.</p>
          <p>Many crypto proponents have expressed concerns regarding Tether’s ability to provide a proper guarantee of reserves. The SEC recently took advantage of those concerns to launch criticism against Tether and USDT.</p>
          <p>In fact, the SEC described proof of reserves as meaningless in a recent statement to investors. The latter constitutes the latest of the market’s concerns.</p>
          <p>Regulators have been increasingly pushing back against cryptocurrencies and stablecoins. However, some view this as a good sign that regulators are concerned about the crypto market appearing as a threat to the traditional finance system.</p>
          <p>Regardless of the current market views, there are still concerns about potential bank runs that may trigger the loss of stablecoin pegs in the future.</p>
        </div>
      },
      {
        id: 219,
        title: 'Polygon [MATIC] sees uptick in these metrics, what does the future hold',
        category: 'Crypto',
        subCategory: ['Poloygon', 'MATIC', 'metrics'],
        description: <div className="blog-desc">
          <p>Polygon [MATIC] witnessed a massive surge in transactions as the metric crossed $40 million in value on 24 March. MATIC was the only top-10 crypto that registered these numbers. Apart from Polygon, Fetch [FET], and The Sandbox [SAND] registered similar growth. </p>
        </div>,
        authorName: 'Dipayan Mitra',
        authorurl: 'https://ambcrypto.com/author/dipayan-mitra/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 25, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-225.jpg',
        content: <div className="blog-content">
          <ul>
            <li>MATIC transaction surged considerably, and accumulation also increased. </li>
            <li>NFT space witnessed decline and market indicators were bearish. </li>
          </ul>
          <p>Polygon [MATIC] witnessed a massive surge in transactions as the metric crossed $40 million in value on 24 March. MATIC was the only top-10 crypto that registered these numbers. Apart from Polygon, Fetch [FET], and The Sandbox [SAND] registered similar growth. </p>
          <p>It was also interesting to note that MATIC accumulation by whales was on a rise. Santiment’s chart revealed that MATIC’s supply held by top addresses went up over the last week, reflecting the investors’ confidence in the token. </p>
          <h3>Polygon gains in popularity</h3>
          <p>Not only did whales accumulate more MATIC, growth was also noted on other fronts. For instance, Dune’s chart indicated that MATIC’s unique users were on an increasing trend, indicating more usage and adoption of the network.</p>
          <p>Though there were several reasons for this growth, one of them can be attributed to the recent partnership of MATIC with Immutable, a leading Web3 gaming platform.</p>
          <h3>A quick overview of Polygon’s NFT space</h3>
          <p>While the number of unique addresses was rising, a decline was registered in NFT sales. According to CRYPTOSLAM’s chart, MATIC’s NFT sales volume declined by over 38% in the last seven days. Not only that, but after spiking during late February 2023, MATIC weekly volume on Polygon marketplaces declined sharply.</p>
          <p>Despite the decline in the NFT ecosystem, Polygon Daily, a popular Twitter handle that posts updates about the network’s development, tweeted about the most traded NFT collection on OpenSea. As per the tweet, CollectTrumpCards once again topped the list, followed by The Sandbox and Lens Protocol.</p>
          <h3>Other metrics looked decent</h3>
          <p>Things were looking pretty good for MATIC in terms of its on-chain performance. For instance, MATIC’s exchange reserve decreased, suggesting less selling pressure. The token’s supply outside of exchanges also increased, which is a typical bull signal.</p>
          <p>Thanks to the massive surge in transactions, MATIC’s network growth remained high last week, indicating that more new addresses were used to transfer MATIC. Additionally, more tokens were transferred among multiple addresses, which was evident from looking at MATIC’s spiking velocity on 21 March.</p>
          <h3>A bear takeover?</h3>
          <p>Though the metrics were fine, MATIC’s price action turned bearish lately. As per CoinMarketCap, the token’s price declined by over 1.8% in the last 24 hours. At the time of writing, it was trading at $1.11 with a market capitalization of more than $9.6 billion. Polygon’s daily chart also gave a bearish notion and suggested a further price decline.</p>
          <p>The MACD displayed the possibility of a bearish crossover. MATIC’s Relative Strength Index (RSI) registered a slight decline, which was bearish. The Bollinger Band’s findings indicated that MATIC’s price was in a less volatile zone, further decreasing the chances of a northbound breakout in the near term.</p>
          <p>However, the token’s Money Flow Index (MFI) remained bullish, as it was moving above the neutral mark. Combining that with the upcoming zkEVM launch, the possibility of a trend reversal can’t be ruled out.</p>
        </div>
      },
      {
        id: 218,
        title: 'Aptos [APT] diversifies into NFTs and music: How did its price react',
        category: 'Crypto',
        subCategory: ['Aptos', 'NFTs', 'Music'],
        description: <div className="blog-desc">
          <p>The Aptos [APT] network is tapping into the latest hype around its platform to pursue more growth opportunities. Two of its latest announcements reveal that the network is eyeing growth opportunities in the music industry as well as pushing deeper into the NFT market.</p>
        </div>,
        authorName: 'Michael Nderitu',
        authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 25, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-224.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Aptos revealed its next growth plan involving NFTs and the music industry.</li>
            <li>APT struggled to maintain bullish momentum as sell pressure slowly trickled back.</li>
          </ul>
          <p>The Aptos [APT] network is tapping into the latest hype around its platform to pursue more growth opportunities. Two of its latest announcements reveal that the network is eyeing growth opportunities in the music industry as well as pushing deeper into the NFT market.</p>
          <p>Aptos recently affirmed its plans to play an active role in the music industry and potentially even revolutionize it. The network aims to achieve this by bringing WEB3 benefits to the music industry through KYD apps.</p>
          <p>Aptos is likely going to leverage its NFT capabilities as part of its plans for the music industry. The network also conveniently launched OrderDAO, its new community governance protocol for NFT projects. The protocol will make it easier for projects to make efficient decentralized decisions regarding their future.</p>
          <h3>Can these announcements breathe new life into APT?</h3>
          <p>Aptos’ native cryptocurrency APT struggled to find directional performance this week after its rally last week. This suggests that the bulls ran out of momentum. Meanwhile, the bears have been pushing to take over. APT tanked by 6.8% to its $12.20 press time price.</p>
          <p>There are other reasons for investors to expect sell pressure to prevail. For example, APT’s RSI attempted to push below the 50% level. The price also encountered resistance above the $13.7 price range and it has been struggling to rally above its 50-day moving average.</p>
          <p>Numerous Aptos metrics show a higher potential for a pivot. For example, APT’s price volatility has been declining and is currently at a weekly low. In addition, the price experienced a sizable drop in volume between 23 – 24 March, confirmation that the bulls were losing strength.</p>
          <p>APT’s weighted sentiment dropped slightly in the last 24 hours at press time, confirming that some investors were losing faith in the bulls. However, its press time level was notably higher than its weekly low on 20 March.</p>
          <p>Aptos’ social dominance metric had its highest weekly spike in the last 24 hours. This was an important observation, as it may indicate that APT investors were aware of the bulls’ sluggishness.</p>
          <p>They may thus act in accordance with the current observations, potentially contributing to more sell pressure. Of course, this will depend on the overall market direction, which may regain its upside unexpectedly.</p>
        </div>
      },
      {
        id: 217,
        title: 'AAVE outperforms LDO as DeFi TVL reaches YTD high, more inside',
        category: 'Crypto',
        subCategory: ['AAVE', 'DeFi', 'LDO'],
        description: <div className="blog-desc">
          <p>The Total Value Locked of Aave [AAVE] gained as much as 8.69% in the week ended 25 March. The rise in unique smart contract deposits made the protocol the best-performing one as the overall TVL hit $5o.01 billion — a Year-To-Date (YTD) high. However, the TVL fell to $49.42 billion at press time</p>
        </div>,
        authorName: 'Victor Olanrewaju',
        authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 25, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-223.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Aave recorded the most asset deposits in its protocol.</li>
            <li>Though Lido underperformed, its TVL hit 2023 highs.</li>
          </ul>
          <p>The Total Value Locked of Aave [AAVE] gained as much as 8.69% in the week ended 25 March. The rise in unique smart contract deposits made the protocol the best-performing one as the overall TVL hit $5o.01 billion — a Year-To-Date (YTD) high. However, the TVL fell to $49.42 billion at press time</p>
          <p>The TVL considers factors like the circulating supply, asset locked, as well as user interest in a protocol to measure the protocol’s health. So, Aave’s hike implied that many participants in the last seven days looked in the lending and borrowing direction.</p>
          <h3>Aave takes the week and triumphs</h3>
          <p>Lido Finance [LDO] dominated the space for several weeks before Aave took over. Although the liquid staking protocol maintained its stay as the project with the highest TVL, it did not perform well enough to place second-fiddle to Aave. According to DeFi Llama, Lido’s performance over the week yielded a less than 1% hike.</p>
          <p>However, this does not take away Lido’s position as the project which has accommodated the most staked Ether [stETH] ready to be unlocked on 12 April when the Ethereum’s [ETH] Shanghai upgrade takes place. </p>
          <p>Aave, on the other hand, may not boast of such, but its rise in popularity could also be due to Ethereum’s impact. With a $4.82 billion lifetime contribution, Ethereum has helped Aave record a substantial number in fees and revenue.</p>
          <p>Meanwhile, the non-custodial liquidity protocols have not experienced significant strides regarding developments. As of 23 March, development activity reached a yearly low of 3.792.</p>
          <p>A detailed understanding of the metric points to feature upgrades, and public commitment by the project’s developers to improve products under the blockchain.</p>
          <p>But at press time, there has been some recovery as the metric improved to 4.31. This implies that the developers’ activity contribution has been notably restored.</p>
          <h3>NFT volume rises as…</h3>
          <p>In addition to the TVL increase, NFT sales on the Aave network were also impressive over the week. According to Santiment, the digital collectibles volume reached $16.05 million on 20 March. Although it was reduced afterwards, most of the daily sales surpassed those of the previous week.</p>
          <p>Furthermore, Aave’s activities during the week included playing its part in returning funds to those affected by the Platypus exploit. The exploit happened in February when a flashloan drained the Avalanche [AVAX]-based protocol of $9 million.</p>
          <p>Although it threatened the project’s solvency, Aave’s governance was able to help out as the rightful owners got back their funds.</p>
        </div>
      },
      {
        id: 216,
        title: 'U.S. loses web3 lead amidst regulatory pushback, emerging markets rise',
        category: 'Crypto',
        subCategory: ['US', 'web3', 'Market'],
        description: <div className="blog-desc">
          <p></p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 25, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-222.jpg',
        content: <div className="blog-content">
          <ul>
            <li>The blockchain developer share in the U.S. fell from 40% in 2017 to 29% in 2022.</li>
            <li>Emerging markets like India were capturing the share away from the U.S.</li>
          </ul>
          <p>As per an analysis by Developer Report, the U.S. ceded a considerable chunk of the blockchain development pie to emerging markets around the world.</p>
          <p>The report highlighted that developer share in the U.S. fell from 40% in 2017 to 29% in 2022, corresponding to about 2% drop every year.</p>
          <p>The ongoing crackdown by regulators on centralized exchanges and other crypto participants has left the U.S. crypto sector in a state of trepidation, threatening the market dominance that the country has enjoyed over the last few years.</p>
          <h3>India, Ukraine show consistent web3 growth</h3>
          <p>The overall growth in development activity was encouraging, even as the crypto network value dropped to January 2018 levels, the report underlined. The number of developers jumped almost by four times during the same time period.</p>
          <p>U.S. was still the market leader at the time of writing, accounting for 29% of all blockchain developers. European nations also enjoyed a combined market share of 29% while Asia was home to 13% of all web3 developers.</p>
          <p>But while U.S.’ share has declined over the years, emerging markets like India saw their developer share steadily increasing from 2% in 2017 to 6% in 2022. India’s growth trajectory showed more consistency as compared to other regions, the report stated.</p>
          <p>India’s lucrativeness as a growing market for blockchain development was evidenced by the data showing how the country was eating into the share of the U.S.</p>
          <p>Data from Europe also revealed an interesting development. While UK and Germany lead in developer share, both countries saw their dominance decline in the 2017-2022 period. Conversely, conflict-ridden Ukraine’s share increased from 4% to 6% since the war.</p>
          <h3>Will it only get worse?</h3>
          <p>Recently there were reports that Coinbase was contemplating setting up a trading platform outside the U.S. While Coinbase didn’t make any official statement, the report had weight owing to concerns of an enforcement action on the largest crypto exchange in the U.S.</p>
          <p>Brad Garlinghouse, the CEO of Ripple Labs, said in an interview that the crypto industry has already moved overseas and the hawkish stances of the SEC may dent the prospects of U.S. as an inviting destination for blockchain development and cryptos.</p>
        </div>
      },
      {
        id: 190,
        title: 'Is Optimism’s ecosystem really thriving after Shapella Fork’s interruption',
        category: 'Crypto',
        subCategory: ['Ecosystem', 'Shapella Forks',],
        description: <div className="blog-desc">
          <p>The implementation of the long-anticipated Shapella hard fork took place on Ethereum’s Goerli network on March 15. This resulted in a brief pause in Optimism‘s Goerli nodes. Goerli is a network utilized by Ethereum developers for testing purposes. Nevertheless, the Optimism protocol persevered, with its ecosystem flourishing despite the disruption to the Goerli nodes.</p>
        </div>,
        authorName: 'Himalay Patel',
        authorurl: 'https://ambcrypto.com/author/himalay-patel/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 16, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-194.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Optimism Goerli nodes halted temporarily, business went on as usual for Optimism</li>
            <li>Spike in daily transactions observed while fees collected declined on the charts</li>
          </ul>
          <p>The implementation of the long-anticipated Shapella hard fork took place on Ethereum’s Goerli network on March 15. This resulted in a brief pause in Optimism‘s Goerli nodes. Goerli is a network utilized by Ethereum developers for testing purposes. Nevertheless, the Optimism protocol persevered, with its ecosystem flourishing despite the disruption to the Goerli nodes.</p>
          <h3>Glass half full</h3>
          <p>The growth of Optimism’s ecosystem is evident in the neck-to-neck competition for its 10th grant cycle. This cycle received a high number of applications, with many developers and applications competing for a grant to build on the network. The incentives to build on Optimism could help the protocol in attracting more developers and users to the network.</p>
          <p>One of the applicants is a dApp called KyberSwap, which has seen its Optimism-based volumes grow by 9 times over the past year. While Uniswap and Velodrome still hold a lead over KyberSwap in terms of volume, the incentives to build on Optimism are attracting more developers and users to the network.</p>
          <p>Another sign of the network’s expansion is the rise in its transaction volume. The same has increased from 159,600 transactions to 269,700 transactions over the last few months.</p>
          <p>However, despite this hike, the fees generated by the protocol have declined. This, likely due to greater competition among layer-2 solutions, something that has driven down fees generated by the network.</p>
          <h3>And the state of OP is…</h3>
          <p>Despite the decline in fees, the price of the OP token has continued to rise. This is a sign that investors have faith in the long-term potential of the Optimism network. Coupled with that, the MVRV ratio for OP declined, indicating that most holders would not profit if they sold their holdings. This is a sign that there is low selling pressure on OP at this point.</p>
          <p>Additionally, the long/short difference for the OP token has been highly positive too. This is evidence that many holders are long-term investors who are unlikely to sell their holdings.</p>
        </div>
      },
      {
        id: 189,
        title: 'Ethereum can look forward to this ‘stress test’ as Shanghai looms ahead',
        category: 'Crypto',
        subCategory: ['Ethereum', 'Shanghai',],
        description: <div className="blog-desc">
          <p>Christine Kim, the Vice President of Research at Galaxy Digital, recently appeared on a podcast hosted by Laura Shin. Kim, who is an experienced Ethereum researcher, shared her thoughts on the highly anticipated Shanghai upgrade and its impact on the network and ETH’s price. </p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 16, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-193.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Galaxy Digital’s Christine Kim discussed Ethereum’s Shanghai upgrade on an episode of Unchained</li>
            <li>Kim believes unlocking of staked Ether, subsequent withdrawals will stress test the network</li>
          </ul>
          <p>Christine Kim, the Vice President of Research at Galaxy Digital, recently appeared on a podcast hosted by Laura Shin. Kim, who is an experienced Ethereum researcher, shared her thoughts on the highly anticipated Shanghai upgrade and its impact on the network and ETH’s price. </p>
          <h3>Shanghai upgrade will stress test the network</h3>
          <p>Ethereum validators have eagerly anticipated the Shanghai upgrade for a while. EIP 4895 is arguably the most-awaited aspect of the upgrade, which will unlock staked Ether and allow its withdrawals. Speaking about the upgrade, Christine Kim noted that the activation of staked ETH withdrawals on Ethereum is a major milestone. According to her, Shanghai and Capella aren’t necessarily different upgrades since they both work towards the same goal of enabling withdrawals of staked ETH. </p>
          <p>As for the subsequent Capella upgrade, Kim claimed that Capella illustrates the changes that will come to the beacon chain, the consensus layer of Ethereum, while Shanghai illustrates the changes that will come to the execution layer of Ethereum.</p>
          <p><b>“When you withdraw your stake, your stake is coming out of the beacon chain, but it’s being moved to an address where you can interact with (it) on the execution layer of Ethereum.”</b></p>
          <p>When asked about the impact of the withdrawals of staked Ether on the network, Kim acknowledged that it is a matter of concern, given that there are more than 17.5 million staked ETH at the moment. She agreed that mass withdrawal of staked Ether will stress test the limitations of the network. She also added that withdrawals will impact the incentives, with the network increasing rewards in order to attract validators.</p>
          <ul><li>Liquid staking platforms post Shanghai upgrade</li></ul>
          <p>Given Ethereum’s shift from proof-of-work to proof-of-stake, liquid staking platforms have become much more relevant for the network. Christine Kim revealed that Lido finance, the largest liquid staking provider which controls more than a third of the total staked Ether, is currently working towards becoming more decentralized. According to her, this initiative is really important for the health of Ethereum over time.</p>
          <p>Rocketpool is gearing up for an upgrade where instead of validator node operators being required to post 16 ETH, the requirement is being slashed by half to 8 ETH. The liquid staking provider is also exploring other upgrades where the staking requirement can be reduced further for institutional staking providers.</p>
          <p>As for Ethereum’s roadmap, the full focus is on increasing scalability and changing its data structure. In fact, according to the analyst, the roadmap is “very ambitious.”</p>
        </div>
      },
      {
        id: 188,
        title: 'Binance Coin’s [BNB] breakout above $300 means this for the market’s bulls',
        category: 'Crypto',
        subCategory: ['Binance', 'Markets', 'Bull'],
        description: <div className="blog-desc">
          <p>Binance Coin [BNB] reacted extremely positively over the past two days of trading. The bullish euphoria over Bitcoin’s gains translated across the crypto-market and BNB gained by 20.5% within four days. A retracement before a sustained move higher up the charts remains possible too.</p>
        </div>,
        authorName: 'Akashnath S',
        authorurl: 'https://ambcrypto.com/author/akashnath-sumukar/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 16, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-192.jpg',
        content: <div className="blog-content">
          <ul>
            <li>The daily market structure has turned bullish</li>
            <li>A pullback as deep as $290 remains a possibility though</li>
          </ul>
          <p>Binance Coin [BNB] reacted extremely positively over the past two days of trading. The bullish euphoria over Bitcoin’s gains translated across the crypto-market and BNB gained by 20.5% within four days. A retracement before a sustained move higher up the charts remains possible too.</p>
          <p>On the daily timeframe, the market structure for Binance Coin seemed to be bullish once more. The recent lower high at $294 was beaten during the latest reversal, but the $309-mark has posed some resistance over the past 24 hours.</p>
          <h3>A strong confluence of support at $292</h3>
          <p>The Volume Profile Visible Range showed that the price was right above the Point of Control, based on the trading from 8 February. The POC was at $304.2, and Binance Coin seemed to be retesting it as support, at the time of writing.</p>
          <p>The Value Area High and Low were found at $322.8 and $274.7, respectively. This meant that $304.2 and $274.7 are two levels buyers will be interested in. In fact, a set of Fibonacci retracement levels (yellow) were drawn based on the latest surge too.</p>
          <p>It showed the 78,6% retracement level lay at $276.7, which was almost the same level as the VAL. Moreover, the price action from early March showed the $285-$292 region was significant support on the lower timeframes. The RSI crossed over above the neutral 50-level and indicated bullish momentum had taken root. The OBV also recorded gains and could continue higher.</p>
          <p>Therefore, longer-term buyers can wait for a retracement into the $285-$292 zone. More risk-averse traders can wait for a positive reaction over three days before looking to buy and trade with the trend.</p>
          <ul><li>Spot CVD surges past February highs in response to demand</li></ul>
          <p>The funding rate had been negative over the last two days, but it has since slowly climbed above into positive territory. This implied long positions were once more dominant in the market – Signs of a possible flip in sentiment.</p>
          <p>The rising spot CVD underlined strong demand, but the Open Interest dipped over the last 48 hours. And yet, the price has appreciated during this time, which is evidence of weakening bullish sentiment.</p>
        </div>
      },
      {
        id: 187,
        title: 'MATIC’s recovery faces headwinds – Can bulls prevail?',
        category: 'Crypto',
        subCategory: ['MATIC', 'headwinds',],
        description: <div className="blog-desc">
          <p>While the bears have controlled MATIC’s market since mid-February, bulls only gained considerable leverage on 10 March. At the time, while the price action rebounded from $0.943, it has since faced stiff resistance levels. </p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 16, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-191.jpg',
        content: <div className="blog-content">
          <ul>
            <li>The bulls seem to have fronted a recovery on the charts</li>
            <li>CVD (Cumulative Volume Delta) fell and could affect bulls’ efforts</li>
          </ul>
          <p>While the bears have controlled MATIC’s market since mid-February, bulls only gained considerable leverage on 10 March. At the time, while the price action rebounded from $0.943, it has since faced stiff resistance levels.</p>
          <p>Additionally, Bitcoin [BTC] faced rejection around the $26K zone and dropped below $25K, further undermining the rest of the altcoin’s market recovery in the short-term. However, a retest of BTC’s latest highs could lead the altcoin market into a renewed recovery process.</p>
          <h3>MATIC’s recovery at stake – Can bulls push forward?</h3>
          <p>MATIC’s drop since mid-February was successfully stopped by the $.9427 support. The support was also crucial in January. Bulls cleared the hurdle at 200-period MA (Moving Average), the 20 EMA (Exponential Moving Average), and the $1.1654-resistance level. At press time, MATIC was struggling to bypass the obstacle at $1.23, partly due to BTC’s price fluctuations.</p>
          <p>Bulls could attempt to clear the $1.23 hurdle if BTC surges above $25K. A close above the hurdle could push the price towards the overhead resistance of $1.5723, especially if the obstacle at $1.34 is cleared too.</p>
          <p>On the contrary, BTC fluctuations could set MATIC into a consolidation range of $1.17 – $1.23 if 20 EMA remains steady. However, MATIC could sink to the new low of $0.943 if bears clear the obstacle at 200 MA ($1.0414).</p>
          <p>The Relative Strength Index (RSI) retreated from the oversold territory – Evidence of increased buying pressure over the past few days. However, the Average Directional Index (ADX) slope hadn’t moved north, indicating a weak uptrend direction which should caution bulls.</p>
          <h3>Funding rate was positive, but CVD fell</h3>
          <p>According to the crypto-derivatives data platform Coinalyze, MATIC’s funding rates have been positive since March 13. It underlined increased demand for MATIC in the derivatives market, which helped bypass the $1.1654-hurdle.</p>
          <p>However, the CVD (cumulative volume delta) fell with a negative slope, indicating sellers were gaining leverage in the market. If the CVD was flat, it would mean neither buyers nor sellers had absolute leverage. On the contrary, a positive slope and growth would suggest buyers’ greater market influence. The negative slope, therefore, could complicate the recovery.</p>
        </div>
      },
      {
        id: 186,
        title: 'Why Cardano may get a quick fix to its month-long social neglect',
        category: 'Crypto',
        subCategory: ['Cardano', 'social',],
        description: <div className="blog-desc">
          <p>Social sentiment in connection with Cardano has begun to rise</p>
        </div>,
        authorName: 'Victor Olanrewaju',
        authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 16, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-190.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Social sentiment in connection with Cardano has begun to rise</li>
            <li>Project’s TVL also hiked while its founder pushed for a bank-free crypto-sector</li>
          </ul>
          <p>Cardano [ADA], the decentralized Proof-of-Stake (PoS) blockchain, has put up with consistent neglect by folks in the crypto-channel. The lack of attention given to the network might be surprising though, especially as the ecosystem reacted positively to recent crises in the industry.</p>
          <h3>An escape route for the fallen</h3>
          <p>However, according to on-chain data, Cardano’s struggles are not really a recent matter. In fact, Santiment data revealed that social dominance has hit severe rock bottom repeatedly since 26 February.</p>
          <p>Social dominance gauges the percentage of discussions around an asset among the top-100 per market capitalization. However, the project’s image seems to be on its way to redemption as the metric hit 2.687% on 14 March. Despite its modest decrease at the time of writing, the condition confirmed that Cardano’s mentions across socials had become mainstream.</p>
          <p>While the aforementioned proved that sentiment might be changing, the development activity had a reading of 85.26. This metric detects the improvements in a project’s network and commitment to its core fixes. </p>
          <p>At press time, Cardano’s Development Activity did not measure very highly. Instead, it fell slightly but remained around the zone it has occupied since March began. This implied that there has been no notable demand for upgrades on Cardano.</p>
          <p>Another metric that could ignite recognition for ADA is whale activity. According to Santiment, Cardano transactions in the $100,000 to $1 million range have been hitting several highs since January. In fact, at the time of writing, a hundred ADA $100,000 transactions had taken place already on 15 March.</p>
          <p>These values describe a “buy the dip” situation. However, there is no confirmation that ADA’s current value is close to its bottom. Even so, consistent action could drive retail interest and possibly, rekindle the neglect that Cardano has been facing. </p>
          <h3>More deposits, but there’s a need for independence</h3>
          <p>Looking at its Total Value Locked (TVL), Cardano gained by 188.01% over the last 30 days. TVL measures the total value of all crypto-assets staked or locked up in a protocol. Interestingly, the hike also helped Cardano jump into the top-20 chains per TVL.</p>
          <p>An assessment of the information provided by DeFi Llama showed that the milestone would have been impossible if not for the contribution of Aada, Miniswap, and its algorithmic stablecoin – Djed.</p>
          <p>However, this does not automatically assure us that its recently-acquired escape from disregard would last.</p>
          <p>In the meantime, the project’s founder Charles Hoskinson weighed in on the problems facing the U.S. banking industry. Hoskinson noted that the crypto-sector needs to re-assess its attachment to traditional financial institutions.</p>
        </div>
      },
      {
        id: 185,
        title: 'Fetch [FET] hits the obstacle at 61.8% Fib level – Are more gains unlikely?',
        category: 'Crypto',
        subCategory: ['FET', 'Obstacle', 'Fib level'],
        description: <div className="blog-desc">
          <p>FET hit a key price rejection level </p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 16, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-189.jpg',
        content: <div className="blog-content">
          <ul>
            <li>FET hit a key price rejection level </li>
            <li>Bulls can be hopeful because of rising Mean Coin Age and favorable sentiment </li>
          </ul>
          <p>Fetch [FET] oscillated around $0.371 – $0.490 over the last few days before breaking below the range. However, bulls found steady ground at $0.298 on March 12, pushing the price back into its previous range only to face rejection at the range’s upper boundary. </p>
          <p>At press time, the AI-focused token’s value was $0.4310, down 10% in the last 24 hours. However, FET could benefit from a renewed AI narrative.</p>
          <p>OpenAI released its latest and advanced ChatGPT-4 as a Google-backed Anthropic also rolled out a “safer” rival AI chatbot called Claude. This marked a renewal of the AI-wars that could boost AI-focused tokens like Fetch [FET], as seen in the past trend. </p>
          <h3>Will the price ceiling at 61.8% Fib level hold any longer?</h3>
          <p>After price rejection at $0.61, FET retraced and entered a consolidation range (white, dotted), but broke below it. The drop was checked by the $0.23-support, thrusting FET into a strong recovery. The 61.8% Fib level hurdle could be cleared as AI narrative floods socials in the next few hours.</p>
          <p>Therefore, FET could surge to the bearish order block and 78.6% Fib level of $0.5423 or to overhead resistance at $0.6089 if it closes above the 50% Fib level ($0.4533). Such a move could offer two possible trades. </p>
          <p>First, a risky approach targeting the 78.6% Fib level ($0.5423). The entry will be above $0.4533 with a stop loss at 23.6% Fib level ($0.3711). The second option is a $0.6089-target with similar entry and stop loss levels. Each will offer an RR of 1:2.5 and 1:4, respectively. </p>
          <p>On the other hand, a close below 23.6% Fib level (($0.3711) would invalidate the bullish thesis. If the previous support holds again, the drop could offer new buying opportunities at $0.30. Short-sellers can short the asset if it closes below $0.3711 and repurchases it cheaply at $0.30.</p>
          <p>The RSI (Relative Strength Index) rose, but was slightly above equilibrium, showing buying pressure reduced significantly. However, rising OBV (On Balance Volume) could boost further uptrend. </p>
          <h3>FET saw rising Mean Coin Age and greater positive sentiment</h3>
          <p>FET recorded a rising 90-day Mean Coin Age, indicating a wide-network accumulation – A potential rally signal. Similarly, the weighted sentiment exhibited a highly positive elevation, showing investors were bullish on the token. </p>
          <p>Worth noting that the spike in supply on exchanges is a sign of short-term selling pressure. This, however, is unlikely to undermine a long-term recovery. </p>
        </div>
      },
      {
        id: 184,
        title: 'Can Circle [USDC] turn things around with new plan? All you need to know',
        category: 'Crypto',
        subCategory: ['USDC', 'Circle',],
        description: <div className="blog-desc">
          <p>USDC regained its peg after Circle moved quickly with new banking partners.</p>
        </div>,
        authorName: 'Michael Nderitu',
        authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 16, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-188.jpg',
        content: <div className="blog-content">
          <ul>
            <li>USDC regained its peg after Circle moved quickly with new banking partners.</li>
            <li>USDC demonstrated signs of demand and volume recovery.</li>
          </ul>
          <p>Circle faced its toughest week so far this year after USD Coin [USDC] lost its dollar peg. It has since recovered, but the stablecoin issuer just released a new update regarding its USDC operations.</p>
          <p>According to the update, Circle redeemed 2.9 billion USDC and minted 700 million USDC on 14 March. Those efforts were part of its action plan to aid the peg recovery. More importantly, Circle announced that it was securing new transaction banking partners. The company’s goal is to facilitate round-the-clock transactions that will not be limited by regular banking hours.</p>
          <p>Circle further revealed that it had limited funds held by its transaction banking partners to support redemption and minting. It also revealed that it held a cash position of its reserve at BNY Mellon. Thus, at press time, it had on-ramps for users looking to move their funds into the crypto segment.</p>
          <p>The move by Circle underscored plans to bypass regulators’ efforts to prevent banks from working with crypto companies. It also came just days after multiple banks collapsed, adding more pressure to the fiat system. As a result, more people were losing their trust in the fiat system, and this was a key factor that fueled the rally in the last three days.</p>
          <h3>USDC volumes are recovering</h3>
          <p>The aforementioned factors and the fact that USDC has regained its test have restored some confidence back into the stablecoin. The supply of USDC in smart contracts recently bounced back to a new four-month high.</p>
          <p>But what about actual market demand? Well, a look at address characteristics revealed that USDC receiving addresses were slightly higher than sending addresses. Another key observation is that both metrics dropped substantially since 11 March, as people moved to other stablecoins.</p>
          <p>However, addresses began leveling out at press time, suggesting that USDC trading activity is recovering. This is evident in the stablecoin’s exchange flows. Both exchange inflows and outflows have been on the rise for the last three days after previously tanking because of the depeg.</p>
          <p>The exchange outflows remain higher than inflows, hence confirming that USDC is yet to regain full confidence. This may also be due to the recent crypto rally, which meant that investors have been buying crypto for stablecoins.</p>
        </div>
      },
      {
        id: 183,
        title: 'MakerDAO: How RWA holdings contributed to profits amidst stablecoin saga',
        category: 'Crypto',
        subCategory: ['RWA', 'Stable coin', 'saga'],
        description: <div className="blog-desc">
          <p>MakerDAO’s RWA holdings led to profits amid stablecoin market issues.</p>
        </div>,
        authorName: 'Himalay Patel',
        authorurl: 'https://ambcrypto.com/author/himalay-patel/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 16, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-187.jpg',
        content: <div className="blog-content">
          <ul>
            <li>MakerDAO’s RWA holdings led to profits amid stablecoin market issues.</li>
            <li>Protocol maintains stability but faces a decline in unique users.</li>
          </ul>
          <p>The stablecoin market faced a significant challenge as USD Coin [USDC] came under scrutiny. MakerDAO, the protocol behind stablecoin DAI, also felt the impact. But despite this, MakerDAO saw profits, thanks to its RWA holdings.</p>
          <p>According to data provided by Delphi Digital, MakerDAO made a $3.8 million profit through its RWA holdings. These holdings contribute significantly to MakerDAO’s overall earnings, making up 11.6% of its total holdings. Real World Asset (RWA) is a type of collateral that is not cryptocurrency-based but is more traditional and tangible, such as U.S. Treasury Bills and Bonds.</p>
          <h3>Having a closer look</h3>
          <p>However, MakerDAO’s PnL statement painted a negative outlook due to high expenses on upgrades and updates. A significant portion of MakerDAO’s earnings are possibly being spent on upgrading and maintaining the protocol. These expenses outweighed their earnings, leading to a net loss for MakerDAO.</p>
          <p>Nonetheless, MakerDAO’s recent proposal to create PSM circuit breakers shows its dedication to improving its protocols, considering the market’s volatility. This proposal will allow Maker governance to disable a PSM immediately without governance delay. This step will ensure that the protocol can react quickly to market changes and maintain stability.</p>
          <p>The state of the protocol was relatively healthy as the volume on MakerDAO increased by 55% over the past month. This shows that despite the challenges faced by the stablecoin market, MakerDAO has maintained its momentum.</p>
          <p>However, the number of unique users in the same period declined by 14% according to Messari’s data. This decline has affected the overall TVL generated by MakerDAO, leading to a decline over the past few days.</p>
          <h3>MKR and DAI</h3>
          <p>Along with a decline in TVL, MKR token’s prices declined.</p>
          <p>However, whale interest in the token skyrocketed. This interest from whales could be due to the fact that the market cap of DAI increased materially over the last few days. This interest in both MKR and DAI indicated the potential for future profits and belief in the long-term viability of the protocol.</p>
        </div>
      },
      {
        id: 182,
        title: 'What Coinbase’s move to Singapore means for U.S. banking systems',
        category: 'Crypto',
        subCategory: ['Coinbase', 'Singapore', 'Banking'],
        description: <div className="blog-desc">
          <p>Coinbase enters the Singapore market through strategic partnership with Standard Chartered.</p>
        </div>,
        authorName: 'Michael Nderitu',
        authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 16, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-186.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Coinbase enters the Singapore market through strategic partnership with Standard Chartered.</li>
            <li>The launch was flagged off through regulatory approval from the Monetary Authority of Singapore.</li>
          </ul>
          <p>On 15 March, Coinbase announced that it had secured a partnership with TradFi giant Standard Chartered. This move undermined recent directives by regulators aiming to prevent the flow of liquidity into the crypto market.</p>
          <p>Crypto regulators issued a directive to U.S banks in February, requiring them not to engage in business with crypto companies. However, Coinbase moved forward nonetheless. According to a recent announcement, the partnership will provide a free on-ramp and off-ramp for crypto users in Singapore.</p>
          <p>Coinbase also revealed that it updated its help center so that it can offer learning material for over 200 crypto assets. This is a surprising change of pace for Singapore users because regulators in the country previously criticized cryptocurrencies.</p>
          <h3>Are CEXes shifting its focus outside the U.S.?</h3>
          <p>The Coinbase announcement came at a time when the U.S. market has been facing economic turmoil due to inflation and a recent banking crisis. On top of that, the U.S. appears to be taking an aggressive stance against the crypto market.</p>
          <p>This new development underscores Coinbase’s push into other promising markets outside the U.S. Singapore is one of those markets that has also been embracing opportunities in the blockchain and crypto space. The exchange plans to leverage the Singpass platform, which is native to Singapore, part of the conduit for its crypto offerings.</p>
          <h3>Coinbase secures regulatory approval from Singaporean authorities</h3>
          <p>Coinbase announced through its official blog that its foray into Singapore is just the start of its global expansion. Part of the reason the Asian country is among the first international targets for Coinbase is regulatory approval. Coinbase is now allowed to offer regulated Digital Payment Token (DPT) services through in-principal approval. The Monetary Authority of Singapore (MAS) offered the approval.</p>
          <p>The regulatory path underscores Singapore’s favorable stance for the crypto market. This theme has reverberated across multiple jurisdictions in the Asian region and even in some European countries. Numerous other countries may follow in Singapore’s footsteps, especially now that the crypto market is maturing. FOMO will be the key driver as more countries play catchup to avoid missing out on opportunities.</p>
        </div>
      },
      {
        id: 156,
        title: 'Bitcoin Slides Below $24.5K as European Banking Woes Spook Investors',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'European', 'Banking'],
        description: <div className="blog-desc">
          <p>Bitcoin slid below $24,500 as investors weighed a potential European banking meltdown triggered by concerns about often-embattled Credit Suisse.</p>
        </div>,
        authorName: 'Jocelyn Yang',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 16, 2023 - 1:51 AM',
        cover: 'https://ncx.cx/images/ncx-blog-160.jpg',
        content: <div className="blog-content">
          <p>Bitcoin slid below $24,500 as investors weighed a potential European banking meltdown triggered by concerns about often-embattled Credit Suisse.</p>
          <p>The largest cryptocurrency by market capitalization dropped to $23,946 at one point before regaining ground to recently trade at $24,502, roughly flat over the 24 hours. But that was still well off BTC's highs of the previous day when it soared past $26,000 after the release of a mildly upbeat consumer price index (CPI) inflation data for February. The 6% CPI improved on the previous month’s reading and offered investors looking for more monetary policy dovishness hope the U.S. Federal Reserve would at least temporarily stop its recent diet of interest rate hikes.</p>
          <p>On Wednesday, banking troubles trumped monetary policy considerations.</p>
          <p>Shares of the Swiss banking giant Credit Suisse (CS), which has been rocked by scandals over the past year and posted losses for five consecutive quarters, tanked on Wednesday after the bank’s largest investor, Saudi National Bank, said it wouldn’t invest capital beyond the $1.5 billion it sank into the bank last year. Saudi National Bank owns a stake of up to 9.9%.</p>
          <p>The Swiss National Bank seemed to at least temporarily halt the damage after announcing it would provide CS with liquidity “if necessary,” and rejecting the notion that contagion tied to the failure of two, large U.S. regional banks over the past week had spread overseas.</p>
          <p>Credit Suisse shares tumbled 13%, as did several European banking stocks including French banks BNP Paribas (BNP.PA) and Société Générale (GLE:FP), down by 8% and 10%, respectively. European markets tumbled as investors in riskier assets grew skittish.</p>
          <p>U.S. equity markets initially tumbled on the banking news coming from Europe before recovering some of their losses. The S&P 500 and Dow Jones Industrial Average (DJIA) dropped 0.7% and 0.8%, respectively. But the tech-heavy Nasdaq Composite rose 0.05%.</p>
          <p>Regional banking stocks also plunged, with First Republic Bank (FRC) and PacWest Bancorp (PACW) falling 21% and 13%, respectively.</p>
          <p>“Credit Suisse is a bigger story than Silicon Valley Bank (SVB) and this has Wall Street extremely nervous,” ​​Edward Moya, senior market analyst at foreign exchange market maker Oanda, wrote in a Wednesday email. “Bitcoin’s decline isn’t that bad when you consider how much pressure is hitting stocks, oil prices and the euro.”</p>
          <p>While banking turmoil could ultimately become a bullish moment for bitcoin, “for now crypto weakness is justified,” Moya added.</p>
          <p>Meanwhile, the CME FedWatch Tool showed that currently around 55% of traders believe the Fed will not raise interest rates at its next Federal Open Market Committee (FOMC) meeting starting March 22. An additional 45% expect the Fed to boost the rate by 25 basis points (bps), a stark change from a week ago when an increasing number of observers felt the Fed would increase the rate by 50 bps.</p>
          <p>Will Tamplin, a senior analyst at technical analysis-based research firm Fairlead Strategies, said BTC's resistance is strong near $25,200, while tackling “intermediate-term overbought conditions in place that have become a headwind."</p>
          <p>“This increases short-term downside risk to support from the 200-day [moving average] (~$19,800),” Tamplin told CoinDesk in an email.</p>
          <p>Ether (ETH), the second-largest cryptocurrency, was hovering around $1,656 Wednesday afternoon, down 2.4% from Tuesday, same time. HNT, the native token of the decentralized wireless communication network Helium, recently tumbled 13% to trade near $2 Wednesday, its lowest level in two months. The decline came after Binance.US said it would delist the cryptocurrency on March 21.</p>
          <p>The CoinDesk Market Index, which measures overall crypto market performance, was down roughly 3% for the day.</p>
        </div>
      },
      {
        id: 207,
        title: 'Polkadot: How should traders navigate DOT’s daily bearish breaker?',
        category: 'Crypto',
        subCategory: ['Polkadot', 'Traders', 'bearish'],
        description: <div className="blog-desc">
          <p>The release of the CPI data was just around the corner, at the time of writing. Ease in inflation is expected, but is this expectation already priced in? Does that explain the recent Bitcoin pump right into a zone of resistance at this critical moment? And, what can that mean for Polkadot traders?</p>
        </div>,
        authorName: 'Akashnath S',
        authorurl: 'https://ambcrypto.com/author/akashnath-sumukar/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-211.jpg',
        content: <div className="blog-content">
          <ul>
            <li>The daily market structure, at press time, was about to flip bullish</li>
            <li>Two levels for lower timeframe traders to watch out for as a retest can provide trading opportunities</li>
          </ul>
          <p>The release of the CPI data was just around the corner, at the time of writing. Ease in inflation is expected, but is this expectation already priced in? Does that explain the recent Bitcoin pump right into a zone of resistance at this critical moment? And, what can that mean for Polkadot traders?</p>
          <p>Traders can prepare themselves for the bullish and the bearish scenarios. Since DOT is also at a critical inflection point, its next leg is not yet certain. Evidence for the next move can be awaited before assuming positions in the market.</p>
          <h3>The daily bearish breaker is likely to oppose DOT bulls</h3>
          <p>A former bullish order block, which was flipped to a bearish breaker in early March, was highlighted by the red box. At the time of writing, the price was within this zone, testing it as a zone of supply. Moreover, this area had confluence with the $6.1-level of resistance. Bitcoin was trading just below the $25.2k resistance, which marked the highs of August 2022 and February.</p>
          <p>If BTC can break out past this resistance, it would signal bullish sentiment across the market. This could prime DOT to climb past the $6.1-level as well. However, a daily session close above the breaker would be required before buyers can look for opportunities to enter long positions. The market structure was on the verge of flipping bullish as well, as the recent lower high at $6.06 from 5 March could be beaten.</p>
          <p>On the other hand, if the price sees rejection and falls beneath the $5.75-level, it would be a sign that bears had taken control. Short sellers can thereafter look to short the asset, with a stop-loss above the $6.1-mark. To the south, targets would be the $5.6-level and the March low at $5.15.</p>
          <p>The RSI was at neutral 50 at press time, while the A/D line had climbed over the past few weeks to show strong buying pressure. This was another indication that DOT was in a significant area, but it appeared the buyers had an edge.</p>
          <ul>
            <li>OI indicated participants could be trying to fade the rally</li>
          </ul>
          <p>The Funding Rate jumped into positive territory to signal that the sentiment was bullish. However, the 1-hour chart showed a drop in Open Interest over the last 12 hours preceding the time of writing. In this period, the price continued to climb higher.</p>
          <p>This suggested that long positions were discouraged and bearish sentiment began to take hold. And yet, it could be too early to short Polkadot. The reaction of Bitcoin over the next couple of days would show the direction of the market over the coming weeks.</p>
        </div>
      },
      {
        id: 206,
        title: 'What DOT holders should know about Polkadot’s latest ‘statement’ partnership',
        category: 'Crypto',
        subCategory: ['DOT', 'Poladot', 'Partnership'],
        description: <div className="blog-desc">
          <p>Polkadot has announced the launch of an NFT marketplace by Beatport on Aventus</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-210.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Polkadot has announced the launch of an NFT marketplace by Beatport on Aventus</li>
            <li>Polkadot NFT volume fairly normal as DOT commenced recovery on price charts</li>
          </ul>
          <p>Polkadot is making a significant play in the Non-Fungible Token (NFT), and Web 3 arena as the space grows and the link gets deeper. The development of the Internet and how we interact with digital assets are tied to Web3 and NFTs. Polkadot is putting its foot down early with a recent collaboration announcement with Beatport. Needless to say, a favorable effect on DOT may be on the cards too.</p>
          <h3>The Polkadot partnership with Beatport</h3>
          <p>NFTs are a crucial application of Web3 technology, enabling the creation and ownership of unique digital assets that can be verified on a blockchain. As the Web 3 ecosystem grows, we expect to see more innovative uses of NFTs and other blockchain-based assets.</p>
          <p>Polkadot is taking action in this direction by launching an NFT marketplace. The move is part of an initiative to promote electronic music culture to Web3 and is being carried out in conjunction with global music powerhouse Beatport. With the debut of the Beatport marketplace on Polkadot’s sidechain Aventus, record labels and musicians will have a new channel to promote their work and monetize their fan bases.</p>
          <h3>Parachains, NFTs, and Polkadot (DOT)</h3>
          <p>Polkadot is a platform for many blockchains to interact with each other. As such, it seeks to address the issue of blockchain interoperability by facilitating the exchange between multiple blockchains. Polkadot’s architecture, consisting of a “relay chain” and “parachains,” allows it to be interoperable with other systems.</p>
          <p>In Polkadot, the main or relay chain serves as a hub to which all of the subchains, or parachains, connect. Parachains are a type of blockchain with enhanced capabilities, including interaction with the relay chain and inter-parachain communication.</p>
          <p>Polkadot allows developers to design their parachains or link to pre-existing ones to create and trade NFTs. In addition to Aventus, other parachains on Polkadot that support NFTs include Basilisk, Astar, and Statemint. Here, the Polkadot (DOT) token is vital in protecting all parachains, which are still linked to the relay chain.</p>
          <h3>The state of NFTs and DOT’s volume</h3>
          <p>Only once in March has the overall NFT transaction volume for Polkadot in USD on Santiment surpassed $1 million, according to an analysis of the data. In fact, the value was just $341,000, at the time of writing.</p>
          <p>With no significant spikes, this volume metric showed that NFT trades on the network were reasonable. With the opening of the new NFT marketplace on Aventus, could this situation alter itself though?</p>
          <p>Likewise, DOT’s volume on Santiment has been typical, with no noticeable spikes. This volume is similar to the NFT volume. Nonetheless, the volume has picked up and has been on an uptrend after a fall seen on March 12. The volume was already over 369 million, at the time of writing.</p>
          <p>Additionally, Polkadot (DOT) was trading at about $6.12 on the price charts following a 24-hour hike of 3%. At the time of writing, however, some bearish momentum was creeping into its price performance.</p>
          <p>Regarding NFTs, collections, and volume, Polkadot is still behind chains like Solana and Ethereum. And yet, with the popularity of its marketplaces and distinctive NFT selections on its parachains, it might emerge as a prominent force. Its entry into the NFT market may also have a favorable effect on the DOT token.</p>
        </div>
      },
      {
        id: 205,
        title: 'What next after Bitcoin Cash [BCH] reclaims $110 from riding BTC’s coattails',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'BCH', 'BTC'],
        description: <div className="blog-desc">
          <p>BCH’s correlation with BTC has led to a rally in its price</p>
        </div>,
        authorName: 'Abiodun Oladokun',
        authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-209.jpg',
        content: <div className="blog-content">
          <ul>
            <li>BCH’s correlation with BTC has led to a rally in its price</li>
            <li>If positive conviction remains, the coin’s value can be expected to appreciate even further</li>
          </ul>
          <p>Due to its statistically significant positive correlation with Bitcoin [BTC], Bitcoin Cash’s [BCH] price has also rallied in the last 24 hours. This, following the decision by the U.S. Department of the Treasury, Federal Reserve, and Federal Deposit Insurance Corporation (FDIC) to restore all customer deposits at failed Silicon Valley Bank (SVB).</p>
          <p>When SVB collapsed on 11 March, BCH slipped below $110 and oscillated between $109 and $110 for most of the weekend. However, as BTC’s price rallied in the early trading hours of 13 March following the Federal Regulators’ decision to make all SVIB depositors whole, BCH reclaimed $110 and exceeded it.</p>
          <p>Still on a rally at press time, BCH’s price had appreciated by 5% in the last 24 hours. At the time, the cryptocurrency was trading at $127.31.</p>
          <h3>Are the bulls here to stay?</h3>
          <p>An assessment of BCH’s price movement on the daily chart revealed that the latest rally was leading up to the commencement of a new bull cycle.</p>
          <p>At press time, the Moving Average Convergence Divergence (MACD) was preparing to intersect with the trend line in an upward direction. Once this happens, a new bull cycle would begin and BCH’s price will appreciate further.</p>
          <p>Thanks to improving sentiment and growing accumulation, the Relative Strength Index (RSI) reclaimed its spot above the 50-neutral line and was pegged at 52.18. Likewise, the dynamic line of BCH’s Chaikin Money Flow (CMF) returned a positive reading of 0.17.</p>
          <p>This was another piece of evidence supporting the notion that BCH’s price will appreciate further, especially if positive sentiment lingers.</p>
          <ul><li>Set-up for success on-chain</li></ul>
          <p>According to on-chain data provider Santiment, BCH has seen sustained demand over the last 24 hours. As a result, the count of daily active addresses transacting the coin has hiked by 100%. At press time, 132,370 addresses were transacting BCH.</p>
          <p>Greater network activity is a known recipe for the sustained rally of an asset’s price. With BCH’s weighted sentiment still in positive territory, if the active addresses count continues to rally, buying momentum will grow. This will eventually lead to a another significant price uptick.</p>
          <p>Consider this – In the last 24 hours alone, BCH’s Open Interest has risen by 5%. Generally, an increase in Open Interest is considered a bullish signal, especially as it suggests that there is growing optimism and confidence in the asset’s price direction.</p>
          <p>Hence, BCH’s price might continue to appreciate. However, a decline in the king coin’s price might also precipitate a fall in BCH’s value.</p>
        </div>
      },
      {
        id: 204,
        title: 'Ethereum whales accumulate $600M in ETH; Is the green still an anomaly?',
        category: 'Crypto',
        subCategory: ['Ethereum', 'Whales', 'ETH'],
        description: <div className="blog-desc">
          <p>Like a ton of assets in the crypto-market, Ethereum’s [ETH] price jumped over the weekend. Even at press time, the second-largest cryptocurrency in market value maintained a 5.11% hike in the last 24 hours. Needless to say, this has brought some calm to the market. Especially since much of it was flashing red over the weekend.</p>
        </div>,
        authorName: 'Victor Olanrewaju',
        authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-208.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Whales added 400,000 ETH despite a recent price increment</li>
            <li>More ETH is being circulated although the current vault could be overpriced</li>
          </ul>
          <p>Like a ton of assets in the crypto-market, Ethereum’s [ETH] price jumped over the weekend. Even at press time, the second-largest cryptocurrency in market value maintained a 5.11% hike in the last 24 hours. Needless to say, this has brought some calm to the market. Especially since much of it was flashing red over the weekend.</p>
          <p>However, despite the gains registered on the charts, whales did not seem to be content. As pointed out by Ali_charts, a crypto-analyst on Twitter, addresses holding 1000 to 10000 ETH added $600 million to their holdings.</p>
          <p>The information, derived from Glassnode, revealed that the whales took the action when ETH was still hovering above $1,680 on the charts.</p>
          <h3>On the opposite side of ETH</h3>
          <p>So, does the accumulation mean ETH still has a tendency to climb further? Well, according to Glassnode, the Network Value to Transaction (NVT) signal had a reading of 98.45, at press time. The metric often reflects the 90-day moving average trend of the daily transaction volume, rather than a day-to-day valuation.</p>
          <p>Compared to its value in recent times, the aforementioned NVT signal is a high one. And, the last time it was as high was back in February 2020. Hence, it seemed to confirm that investors were pricing ETH at a premium while the market cap’s growth outpaced its on-chain transaction volume.</p>
          <p>While the aforementioned metric considered ETH as being currently overpriced, more of the altcoin has been in circulation over the last seven days. The circulation shows the number of unique coins that have been used for transactions within a specific period.</p>
          <p>At press time, the seven-day circulation was 3.96 million. This implied that many units of ETH were swirling around the market, despite their numbers falling when compared to the previous day.</p>
          <p>That being said, one metric that has consistently moved north is Active Addresses. This metric measures the number of unique addresses active on a network. With 24-hour active address count of 534,000, the reading implied that many wallets had either received or sent ETH over the last 24 hours.</p>
          <h3>Almost equal exchange flow reactions</h3>
          <p>While ETH remains on whales’ radar, activities on exchanges revealed that it has been a close contest for outflows and inflows.</p>
          <p>In fact, according to Santiment, exchange outflows were as high as 28,400. This seemed to underline traders’ temporary market shortage and contribution to an asset’s appreciation.</p>
          <p>On the other hand, the exchange inflows were 24,000. With the difference in favor of the outflows, it means that ETH has a slight chance of foregoing depreciation in the short term.</p>
          <p>However, traders might still need to be cautious of projection. This, because of several negative developments and contrasting market reactions.</p>
        </div>
      },
      {
        id: 203,
        title: 'USDC’s loss was CEXs’ gain? Well, the real story is…',
        category: 'Crypto',
        subCategory: ['USDC', 'CEX'],
        description: <div className="blog-desc">
          <p>Despite the adverse effects of the USDC situation on various aspects of the cryptocurrency sector, centralized exchanges were able to reap significant gains during this time.</p>
        </div>,
        authorName: 'Himalay Patel',
        authorurl: 'https://ambcrypto.com/author/himalay-patel/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-207.jpg',
        content: <div className="blog-content">
          <ul>
            <li>CEXs registered a massive surge in usage and balance after USDC’s de-peg</li>
            <li>Sustained interest in various CEX tokens also recorded a hike</li>
          </ul>
          <p>Despite the adverse effects of the USDC situation on various aspects of the cryptocurrency sector, centralized exchanges were able to reap significant gains during this time.</p>
          <h3>CEXs reap rewards</h3>
          <p>According to data provided by Messari, activity on CEXs hit a 12-month high due to USDC’s de-pegging event. Exchanges such as Binance, OKX, and Kraken all recorded an uptick in activity over this period.</p>
          <p>One of the reasons for the high activity was the drop in Externally Owned Addresses on Ethereum. Externally Owned Addresses (EOAs) are user-controlled accounts on the Ethereum blockchain that are owned and controlled by individual users. Unlike contract accounts, which are controlled by code, EOAs are controlled by their private keys and can only be accessed by their respective owners.</p>
          <p>Most of the USDC ecosystem was integrated with the Ethereum blockchain. As the USDC stablecoin deviated from its peg, the EOAs dropped by $4 billion in value. However, centralized exchanges (CEXs) managed to accommodate this excess supply and registered a significant 49% hike in their balance as a result.</p>
          <p>At the time of writing, Binance was leading in terms of exchange balance, with $31.9 billion on its platform. In comparison, OKX and Bitfinex came in second and third place with $5.1 billion and $3.2 billion, respectively.</p>
          <ul><li>Momentum on the way?</li></ul>
          <p>Following the FTX incident, numerous long-term users found it difficult to have faith in other centralized exchanges. Overcoming this mistrust was a major challenge for these exchanges. Nonetheless, the implementation of proof of reserves helped to establish some degree of trust and data transparency for customers using centralized exchanges.</p>
          <p>Binance, KuCoin, and OKX have all provided proof of reserves for their customers to see. Proof of reserves showcase the overall percentage distribution of all the assets that have been stored by these exchanges. A majority of these exchanges currently have high amounts of ETH and USDT held as reserves.</p>
          <p>The fall of USDC, coupled with the trust gained by CEXs by showing their reserves, helped generate interest in their tokens as well. In fact, over the last few days, the market caps of BNB, OKB, and KCS have all increased.</p>
        </div>
      },
      {
        id: 202,
        title: 'XRP reacts in this manner as RippleX validates EVM Testnet',
        category: 'Crypto',
        subCategory: ['XRP', 'EVm', 'Ripplex'],
        description: <div className="blog-desc">
          <p>On 14 March, RippleX, the open platform of the Ripple [XRP] network, confirmed that its sidechain was available on its Testnet. Built on the XRP Ledger [XRPL], the Ethereum Virtual Machine [EVM] sidechain would enable 1000 transactions per second on the ledger.</p>
        </div>,
        authorName: 'Victor Olanrewaju',
        authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-206.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Ripple updated its EVM sidechain development with access to the Testnet.</li>
            <li>XRP’s network growth declined, but developers remained significantly active.</li>
          </ul>
          <p>On 14 March, RippleX, the open platform of the Ripple [XRP] network, confirmed that its sidechain was available on its Testnet. Built on the XRP Ledger [XRPL], the Ethereum Virtual Machine [EVM] sidechain would enable 1000 transactions per second on the ledger.</p>
          <p>Contextually, the XRPL is a public blockchain led by Ripple’s developer community. The ledger is part of the ecosystem responsible for aiding asset transfer globally. So, now that the sidechain EVM Mainnet is approaching, cross-chain bridging could improve the network.</p>
          <h3>The dream is edging close to reality</h3>
          <p>In September 2021, Ripple’s Chief Technology Officer David Schwartz passed the proposal of the EVM side chain to the Ripple community. He backed up the proposal by noting that developers could build their own blockchain on the XRPL Mainnet using smart contracts.</p>
          <p>Following the public introduction, blockchain technology firm Peersyst released the first phase of the EVM sidechain in October 2022. While a few compatible bridges were developed at that time, the latest update serves as the second phase of the project.</p>
          <p>The RippleX communique further mentioned:</p>
          <p><b>“Phase two of the project, on track for early 2023, will feature a permissionless EVM sidechain and bridge with a unique design that connects to the XRPL Devnet to expand participation and test scalability within a controlled environment”</b></p>
          <p>Interestingly, data available on the XRPL showed that 2,476,468 blocks had been created on the sidechain at press time. Additionally, 16,079 transactions had taken place among 179,045 addresses on the ledger.</p>
          <h3>Ripple developers are answering the call despite…</h3>
          <p>In the intervening time, the XRP development activity ditched the downside and trended higher. Moreso, the development activity measures the contribution count of developers on a project regarding upgrades. At the time of writing, the metric was 2.52, verifying that Ripple developers were actively in operation.</p>
          <p>However, the network growth did not portray similar strength. A simplification of this metric summarized the behavioral bias for adoption and the number of new addresses who have successfully transacted on the network. So, the condition down at 774 meant that XRP had been struggling despite investors who were gaining deep interest in the token.</p>
          <p>Recently, Ripple’s CEO Brad Garlinghouse admitted that the blockchain-payment firm had exposure to Silicon Valley Bank (SVB). However, he cleared the air that it would never affect its operations. Thus, this EVM development could serve as concrete proof that the Ripple network was not significantly affected.</p>
        </div>
      },
      {
        id: 201,
        title: 'What can spur Polygon’s [MATIC] bullish trend? Analyzing…',
        category: 'Crypto',
        subCategory: ['Polygons', 'MATIC', 'Bullish'],
        description: <div className="blog-desc">
          <p>There have been many recent periods of uptrend activity on Polygon [MATIC], suggesting that the cryptocurrency has emerged from the doldrums it was mired in since 18 February. With the token garnering some attention in terms of social volume, its movement on exchanges is something that investors can keep monitoring.</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-205.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Polygon [MATIC] continued on its consecutive rise at press time.</li>
            <li>Its social dominance rose with the introduction of the Polygon domain.</li>
          </ul>
          <p>There have been many recent periods of uptrend activity on Polygon [MATIC], suggesting that the cryptocurrency has emerged from the doldrums it was mired in since 18 February. With the token garnering some attention in terms of social volume, its movement on exchanges is something that investors can keep monitoring.</p>
          <h3>Polygon sees positive social volume</h3>
          <p>As per Santiment’s chart, Polygon has experienced a boost in its social volume. The observed chart indicated that the last time MATIC experienced such an uptrend was in August. According to Alicharts, this shift was also suggestive of optimistic attitudes that could benefit the asset’s price. As of this writing, the social volume metric was over 1,000, and the weighted sentiment was over 2.2.</p>
          <p>Did the price increase in tandem with the social volume?</p>
          <h3>MATIC on a daily timeframe</h3>
          <p>Until 10 March, the daily timeframe chart of MATIC had trended downward for several weeks. However, the token reversed course on the aforementioned date, having increased by over 20% and trading at about $1.24 as of this writing.</p>
          <p>Furthermore, the trend reversal was strong enough to shift the asset’s trend from bearish to bullish. According to the Relative Strength Index (RSI), MATIC crossed the neutral line. A weak bullish sentiment was also indicated by the Moving Average Convergence Divergence (MACD), which revealed that it had shifted to the upside.</p>
          <p>The volume metric has also been increasing with the recent price increase. The volume was above 979 million as of the time of writing and was getting close to one billion.</p>
          <h3>Observing the trend of the flow</h3>
          <p>The Exchange NetFlow of Polygon provided additional evidence of the strength of the present bullish trend. As per CryptoQuant, since the advent of the price increase, there have been large inflows and outflows into exchanges. With over 31 million MATICs leaving exchanges as of this writing, the outflow was the overwhelming trend.</p>
          <h3>Introducing the Polygon domain</h3>
          <p>On 14 March, Polygon announced that it would partner with Unstoppable Web to introduce the Polygon domain. In addition to enabling users to create a portable identity across over 750 dApps, games, and metaverses, Polygon domains enable the creation of a digital identity. The Polygon domain could benefit the network in a manner akin to the Ethereum Name Service (ENS).</p>
          <p>Given the outflow volume and Polygon’s bullish tendency, a further increase may be in store.</p>
        </div>
      },
      {
        id: 200,
        title: 'USDC depegging makes MakerDAO introduce new mechanism, more inside',
        category: 'Crypto',
        subCategory: ['USDC', 'Maker', 'DAO'],
        description: <div className="blog-desc">
          <p>In a new emergency executive proposal, leading DeFi platform MakerDAO [MKR] has sought community approval to introduce a debt ceiling breaker for collateral assets used to mint its DAI stablecoin.</p>
        </div>,
        authorName: 'Abiodun Oladokun',
        authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-204.jpg',
        content: <div className="blog-content">
          <ul>
            <li>MakerDAO has a new proposal to introduce a debt ceiling breaker.</li>
            <li>MKR has commenced a new bear run.</li>
          </ul>
          <p>In a new emergency executive proposal, leading DeFi platform MakerDAO [MKR] has sought community approval to introduce a debt ceiling breaker for collateral assets used to mint its DAI stablecoin.</p>
          <p>This action became necessary after USD Coin [USDC] lost its parity with the U.S. dollar last weekend due to the disclosure that Circle, the issuer, held deposits at Silicon Valley Bank (SVIB). As USDC was a significant collateral backing for DAI, its de-pegging event resulted in a temporary loss of dollar parity for DAI.</p>
          <p>With this new proposal, the DeFi protocol aims to implement a mechanism to set the debt ceiling of any collateral type to zero on the Maker protocol.</p>
          <p>MakerDAO’s debt ceiling refers to the maximum number of DAI tokens that can be generated against the value of the collateral assets locked in the Maker protocol. The debt ceiling is set individually for each type of collateral the protocol accepts. The purpose of the debt ceiling is to maintain the stability of the MakerDAO system by limiting the number of DAI that can be issued,</p>
          <p>By introducing a debt ceiling breaker, MakerDAO aims to handle scenarios where the underlying collateral asset is experiencing substantial volatility. This mechanism would enable MakerDAO to safeguard its liquidity and prevent losses in such situations.</p>
          <h3>MKR demand falters, prices suffer</h3>
          <p>Because of its statistically significant positive correlation with Bitcoin [BTC], the growth in the king coin’s price following Federal Regulators’ decision to make all SVIB depositors whole resulted in a temporary price rally for MKR.</p>
          <p>However, the lack of new liquidity caused MKR’s price to decline, and stagnated the uptick. According to data from Santiment, the alt has experienced a decrease in network activity since the beginning of the week. For example, the count of daily active addresses trading MKR has since fallen by 86%. Likewise, new demand that might aid price growth has also dropped by 95% in the last four days.</p>
          <p>The token accumulation was evident on the daily chart at press time. However, it was not enough to initiate any significant price growth. As of this writing, MKR’s RSI rested above its centerline at 54.17, while its MFI, on an uptrend, was pegged at 41.</p>
          <p>A closer look at the asset’s MACD indicator revealed why there had been a price decline since the week started. The MACD line intersected with the trend line on 11 March, ushering in a new bear run, hence the downtrend.</p>
        </div>
      },
      {
        id: 199,
        title: 'Here’s what crypto-firms are up to following the U.S Banking crisis',
        category: 'Crypto',
        subCategory: ['crypto', 'US', 'Banking'],
        description: <div className="blog-desc">
          <p>The recent turmoil in the U.S. banking industry has left crypto-companies searching high and low for new partner banks. Last week, Silvergate Capital, Silicon Valley Bank, and Signature Bank all shut down following regulatory intervention. This forced many users to move their assets elsewhere.</p>
        </div>,
        authorName: 'Ann Maria Shibu',
        authorurl: 'https://ambcrypto.com/author/ann-maria-shibu/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-203.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Companies in search for new banking partners after collapse of three in less then a week</li>
            <li>Anchorage Digital announces layoffs amid uncertain regulatory environment</li>
          </ul>
          <p>The recent turmoil in the U.S. banking industry has left crypto-companies searching high and low for new partner banks. Last week, Silvergate Capital, Silicon Valley Bank, and Signature Bank all shut down following regulatory intervention. This forced many users to move their assets elsewhere.</p>
          <p>It is difficult to find alternatives to these banks as their instant payment platforms were critical for transactions 24*7. According to many, the current regulatory environment in the U.S indicates it is not best suited for the growth of the crypto-space.</p>
          <h3>Experts share their two cents</h3>
          <p>Ark Invest’s Cathie Wood took to Twitter on 15 March to slam U.S. regulators for the banking crisis. According to the exec,</p>
          <p><b>“Instead of blocking decentralized, transparent, auditable and well-functioning financial platforms with no central points of failure, regulators should have been focused on the centralized and opaque points of failure looming in the traditional banking system.”</b></p>
          <p>Ripple CEO Brad Garlinghouse recently suggested that the crypto-industry has “already started moving outside” of the U.S. He claimed regulations put U.S. at “severe risk” of missing out on being an attractive hub for the evolution of crypto-innovation.</p>
          <h3>Crypto-firms hunt for substitute banks</h3>
          <p>Following the chaos, Circle hired Cross River Bank as its new partner and expanded ties with others.</p>
          <p>Additionally, according to reports, Digital Currency Group (DCG) is trying to find new banking partners for portfolio companies.</p>
          <p><b>“Santander (SAN), HSBC (HSBA), Deutsche Bank (DB), BankProv, Bridge Bank, Mercury, Multis and Series Financial are still willing to connect with crypto firms.”</b></p>
          <p>DCG has also reached out to international banks Revolut in UK, United Overseas Bank in Singapore, and Bank Leumi in Israel.</p>
          <p>At this point, many other crypto-firms are possibly looking to move overseas as well. As regulations in countries like Germany, Switzerland, and Singapore are crypto-friendly, they might see an influx of companies. These countries do not impose capital gains taxes on crypto as well, making it equally desirable for private investors.</p>
          <h3>Could there be a domino effect?</h3>
          <p>The latest in the series is crypto-bank Anchorage Digital announcing the layoffs of about 20% of its workforce, citing regulatory uncertainty in the United States.</p>
          <p>Anchorage further added that regulatory dynamics are creating headwinds for its business and the crypto-industry. It is likely that more banks will have to take harsh measures to prop up their businesses.</p>
        </div>
      },
      {
        id: 198,
        title: 'Voyager – Binance.US sale deal runs into new regulatory roadblock',
        category: 'Crypto',
        subCategory: ['Voyager', 'Binance', 'US'],
        description: <div className="blog-desc">
          <p>The plan by insolvent lender Voyager Digital to sell its digital assets to cryptocurrency exchange Binance.US includes a clause that would preclude U.S. authorities from bringing legal action against anyone connected to the sale.</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-202.jpg',
        content: <div className="blog-content">
          <ul>
            <li>U.S. government is arguing that $1 billion transaction made by Binance.US to purchase assets of Voyager should be placed on hold</li>
            <li>In order to file an appeal, a two-week delay on the court’s approval has been requested</li>
          </ul>
          <p>The plan by insolvent lender Voyager Digital to sell its digital assets to cryptocurrency exchange Binance.US includes a clause that would preclude U.S. authorities from bringing legal action against anyone connected to the sale.</p>
          <p>According to a motion filed by U.S. Trustee William Harrington and other government lawyers before a New York bankruptcy court, “the court improperly exceeded its statutory power” in authorizing the pardoning. In fact, they have asked for a two-week hold on the court’s approval of the transaction so that they could submit an appeal.</p>
          <h3>What does the Provision say?</h3>
          <p>The clause, which the court authorized on March 7 after finding that 97% of Voyager customers supported the idea, was designed to shield those involved in the sale from being held personally accountable for its implementation.</p>
          <p>Despite not objecting to other aspects of the proposed transaction, U.S. authorities claim the provision would make it more difficult for the government to “exercise its police and regulatory responsibilities.”</p>
          <p>The decision was made in response to an appeal from the U.S. Trustee, a division of the Department of Justice in charge of bankruptcy cases. The division is concerned that the agreement would essentially exonerate Voyager and its employees from violations of securities or tax laws.</p>
          <p>“The Court cannot tell the Government to speak now or forever hold its peace before Voyager and Binance.US are wed,” according to Damian Williams’ filing as U.S Attorney. Nothing in Bankruptcy Law allows courts to absolve parties from responsibility to the Government for actions taken in the past and in the future, it added.</p>
          <p>Williams argued that until appeals are resolved in higher courts, approval of the arrangement — or at least those portions that restrict the government’s capacity to execute the law — should be suspended.</p>
          <p>The sale was allowed last week by New York bankruptcy judge Michael Wiles. He had previously shown significant skepticism towards the Securities and Exchange Commission’s claims that Voyager’s VGX token would be unregistered securities.</p>
          <h3>What to expect?</h3>
          <p>On March 6, the Securities and Exchange Commission (SEC) objected to the plan as well, citing the “extraordinary” and “highly improper” exculpation provision. The SEC claimed that Binance.US is running an unregistered securities exchange and that the repayment token would be an unregistered security offering.</p>
          <p>According to the most recent projections, the strategy is anticipated to result in the creditors of Voyager receiving about 73% of the value of their money back.</p>
        </div>
      },
      {
        id: 197,
        title: 'FIL sees increased interest as Filecoin launches virtual machine, details inside',
        category: 'Crypto',
        subCategory: ['FIL', 'Filecoin', 'vitrual'],
        description: <div className="blog-desc">
          <p>To provide an execution environment for running the Ethereum [ETH] Virtual Machine (EVM)-compatible smart contracts on its network, leading decentralized storage network Filecoin [FIL] finally launched its Filecoin Virtual Machine [FVM] on the mainnet on 14 March.</p>
        </div>,
        authorName: 'Abiodun Oladokun',
        authorurl: 'https://ambcrypto.com/author/abiodun-oladokun/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-201.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Filecoin has launched its Filecoin Virtual Machine.</li>
            <li>The FVM has led to increased FIL trading in the last 24 hours.</li>
          </ul>
          <p>To provide an execution environment for running the Ethereum [ETH] Virtual Machine (EVM)-compatible smart contracts on its network, leading decentralized storage network Filecoin [FIL] finally launched its Filecoin Virtual Machine [FVM] on the mainnet on 14 March.</p>
          <p>The launch of the FVM formed the final step of Filecoin’s Masterplan to bring large-scale computation and the ability to power web-scale apps to the decentralized storage network.</p>
          <p>According to the press release, the Filecoin Virtual Machine will provide similar abilities to Ethereum, allowing for the development and deployment of decentralized applications and the ability to connect with other blockchains.</p>
          <p>Following the launch of the FVM, leading cryptocurrency exchange OKX announced the integration of its web3 wallet with the upgrade.</p>
          <h3>Day traders rally toward FIL</h3>
          <p>In the last 24 hours, FIL’s price rallied by 7%. With a surge in FIL trading following the launch of the FVM, trading volume has climbed by 30% during the same period, per data from CoinMarketCap. As of this writing, the altcoin exchanged hands for $6.81 per FIL token.</p>
          <p>Taking advantage of the FVM launch, data obtained from Coinglass indicates that there has been an increase in the number of FIL trading positions opened within the past 24 hours. At $177.5 million at press time, FIL’s Open Interest has climbed by 2%.</p>
          <p>A jump in an asset’s Open Interest indicated that more traders were interested in taking positions in the asset. This often reflected a growing bullish sentiment toward that asset’s price.</p>
          <p>Moreso, there were a higher number of short liquidations than long liquidations in the last 24 hours. As the positive sentiment toward FIL continued to rise, data from Coinglass revealed.</p>
          <p>Further, on a 12-hour chart, FIL accumulation rallied significantly. FIL’s Relative Strength Index (RSI) and Money Flow Index (MFI) approached the overbought region at press time. The RSI was 60.99, while the MFI was 66.58. With weighted sentiment still in the positive territory per data from Santiment, this accumulation momentum is expected to be sustained. Therefore, more price growth should be on the horizon for FIL.</p>
          <p>Also, FIL’s On-balance volume (OBV) maintained an uptrend at press time, suggesting rallying token accumulation. When an asset’s OBV is trending upwards, it means that the volume of trades is heavier on the buy side, indicating that investors are more interested in buying the asset than selling it.</p>
        </div>
      },
      {
        id: 196,
        title: 'Tether [USDT] exchange supply dwindles as whales…',
        category: 'Crypto',
        subCategory: ['USDT', 'Exchange', 'Whales'],
        description: <div className="blog-desc">
          <p>Over the weekend, stablecoins became “unstable” as fifth-ranked cryptocurrency as Circle USDC in market value lost its dollar peg. Following the event, a lot of investors switched sides and held their stables in Tether [USDT]. This led to a drop in USDC’s market capitalization and increased dominance for Tether.</p>
        </div>,
        authorName: 'Victor Olanrewaju',
        authorurl: 'https://ambcrypto.com/author/victor-olanrewaju/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-200.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Addresses with large USDT holdings are moving their assets off exchanges.</li>
            <li>Tether’s market cap dominated Circle, but earlier high sentiment has reduced.</li>
          </ul>
          <p>Over the weekend, stablecoins became “unstable” as fifth-ranked cryptocurrency as Circle USDC in market value lost its dollar peg. Following the event, a lot of investors switched sides and held their stables in Tether [USDT]. This led to a drop in USDC’s market capitalization and increased dominance for Tether.</p>
          <p>While many may have believed that USDT could act as a safe haven, not many trusted keeping them on exchanges. In fact, whales were the leading light in this group.</p>
          <h3>Whales keep stability to themselves</h3>
          <p>According to Santiment, about $1 billion USDT left exchanges and were moved into self-custody multiple times in the last ten days. This was an uncommon occurrence because the act had only happened eight times over the last 365 days before now.</p>
          <p>During the time Circle revealed its exposure, Tether’s CEO Paolo Ardoino, had assured the community that it had no links with Silicon Valley Bank (SVB). Regardless, USDC has found its way back to $1, although the price at press time was slightly below the expected value.</p>
          <p>Meanwhile, USDT’s non-zero addresses decreased around 18 February. But, according to Glassnode, the number of unique addresses holding a positive amount of the stablecoin had increased to 4.15 million. This confirmed the restoration of the USDT’s superiority as USDC’s position in this aspect only had 1.59 million unique addresses.</p>
          <p>Moreso, one part of the USDT network that caught the eye was the velocity. An explanation of this metric is that it measures how many units of a coin are circulating in a network. It is derived by calculating dividing the on-chain transaction volume by the market capitalization.</p>
          <h3>High circulation but conviction is down</h3>
          <p>At press time, USDT surpassed the highs of the last ten months, as it peaked at 0.366. This value means that there has been a rapid increase in liquid supply. Therefore, the broader market was increasingly transacting using USDT. But for those who held a large supply, keeping off exchanges was the way to go.</p>
          <p>Additionally, the USDC troubles helped improve the perception towards USDT. This was because Santiment’s data showed that the latter’s stablecoin reached 6.628 on 12 March. The weighted sentiment compiles the public opinion about an asset.</p>
          <p>Hence, the hike implied that social volume was high and the feeling towards USDT was largely positive. However, press time data from the on-chain analytic platform revealed that the metric had been suppressed a bit.</p>
          <p>The supply percentage held by top addresses also trended along the downside. At press time, the chunk of these heavy-purse investors had decreased to 29.53.</p>
        </div>
      },
      {
        id: 195,
        title: 'Dogecoin [DOGE] bulls open the floodgates: Can March losses be reversed',
        category: 'Crypto',
        subCategory: ['Dogecoin', 'DOGE', 'bulls'],
        description: <div className="blog-desc">
          <p>Measured from the swing low at $0.0625, Dogecoin [DOGE] has made gains of close to 25% within the past five days. The sentiment across the crypto sphere was bullish following Bitcoin’s [BTC] sharp breakout past the $22k region on Monday.</p>
        </div>,
        authorName: 'Akashnath S',
        authorurl: 'https://ambcrypto.com/author/akashnath-sumukar/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-199.jpg',
        content: <div className="blog-content">
          <ul>
            <li>The H4 market structure was strongly bullish.</li>
            <li>The trading volume saw an increase during the shift in structure.</li>
          </ul>
          <p>Measured from the swing low at $0.0625, Dogecoin [DOGE] has made gains of close to 25% within the past five days. The sentiment across the crypto sphere was bullish following Bitcoin’s [BTC] sharp breakout past the $22k region on Monday.</p>
          <p>Bitcoin’s surge past $25.2k resistance on Tuesday meant it, too, had a strong bullish bias going forward. Dogecoin bulls might have to be wary- such a strong pump could see a large retracement before the next leg.</p>
          <h3>Two lower highs were breached and showed a shift in bias</h3>
          <p>The bearish breaker on the daily timeframe was breached in the opposite direction as Dogecoin reversed in dramatic fashion. The trading volume was high on the way up for DOGE, and the indicators also flashed bullish signals.</p>
          <p>The Awesome Oscillator was above the zero line and climbing, and showed intense bullish momentum. The Directional Movement Index also showed an uptrend in progress. Over the past few days, the -DI (red) was forced to retreat beneath the 20 mark and the +DI replaced it. With the ADX (yellow) also above 20, they showed a significant uptrend on the 4-hour chart.</p>
          <p>The market structure also corroborated with this finding. The price has breached two of the recent lower highs and flipped the previous bearish structure to bullish. The $0.073 level of support was retested almost perfectly before DOGE climbed higher yet again.</p>
          <p>The next area of resistance was at $0.08. This was an area that had served as support since mid-January. Hence, it was likely to be full of sellers. If this area was retested as support in the coming days, it could offer a buying opportunity.</p>
          <h3>The futures market showed capital flowing in alongside Dogecoin gains</h3>
          <p>The 15-minute chart showed periods of consolidation and expansion for Dogecoin on the lower timeframes over the past few days. Each pullback on the price chart saw the Open Interest decline.</p>
          <p>Similarly, each move upward for DOGE was accompanied by a surge in OI. Taken together, it showed bullish sentiment was strong in the market.</p>
        </div>
      },
      {
        id: 194,
        title: 'Will Solana miss out on the current bullish market? The answer is…',
        category: 'Crypto',
        subCategory: ['Solana', 'bullish', 'market'],
        description: <div className="blog-desc">
          <p>Multiple dApps and DEXs on the Solana network have seen an uptick in activity over the last few days. However, this spike in activity on Solana may not be enough for the chain to take part in this bull run.</p>
        </div>,
        authorName: 'Himalay Patel',
        authorurl: 'https://ambcrypto.com/author/himalay-patel/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-198.jpg',
        content: <div className="blog-content">
          <ul>
            <li>While Solana saw growth on multiple fronts, TVL declined</li>
            <li>On-chain metrics showcased a pessimistic outlook, with some traders turning bearish</li>
          </ul>
          <p>Multiple dApps and DEXs on the Solana network have seen an uptick in activity over the last few days. However, this spike in activity on Solana may not be enough for the chain to take part in this bull run.</p>
          <p>The DEX in question would be Drift Protocol, with the same recording an ATH recently in terms of daily active users.</p>
          <p>Another dApp – Light Protocol –  witnessed an improvement in terms of activity on the Solana network.  The protocol verifies ZK SNARKs on the network. At press time, the protocol had managed to complete over 2 million transactions.</p>
          <p>Despite the success of Solana’s protocols, however, the overall TVL of the network declined. It fell from $281 million to $243.06 million over the past few weeks alone.</p>
          <p>The decline in Solana’s TVL was accompanied by a decline in interest for its NFTs. According to data from SolanaFloor, for instance, the protocol’s blue-chip NFTs struggled to grow over the last month. This contributed to a hike in the overall volume of NFTs sold on the protocol from $119,662 to $49,385.</p>
          <h3>Some more problems on the horizon?</h3>
          <p>The decline in NFT interest would be due to increasing negative sentiment that has been growing around Solana. With multiple downtimes and failures, many in the crypto-community have turned skeptical about the future of the Solana network.</p>
          <p>In fact, SOL has also suffered during this time period. According to Santiment’s data, the trading volume for SOL fell from 3.41 billion to 1.36 billion. The price volatility, however, continued to hike. Higher volatility would make many risk-averse traders shy away from buying SOL.</p>
          <p>However, development activity on the Solana network surged over the last few weeks. This spike in development activity suggested that the frequency with which developers on the Solana network have been contributing to its GitHub is high.</p>
          <p>This could imply that new updates and upgrades may be on their way for Solana in the future.</p>
          <p>However, the promise of new upgrades hasn’t been enough for traders to become optimistic about the future of Solana.</p>
          <p>In fact, according to Coinglass’ data, 52.08% of all trades against Solana have been short positions.</p>
        </div>
      },
      {
        id: 193,
        title: 'Authorities shut down Bitcoin mixer ChipMixer, seize $46.6M',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Chipmixer', 'Shutdwon'],
        description: <div className="blog-desc">
          <p>Bitcoin mixer – ChipMixer has been shut down by authorities, as per an announcement by Europol. The operation involved the collaboration of regulatory authorities from various countries. The operation also resulted in the seizure of four servers, over 1909.4 Bitcoins (BTC) – over $46.6 million (nearly EUR 44.2 million), and 7 TB of data.</p>
        </div>,
        authorName: 'Priya NV',
        authorurl: 'https://ambcrypto.com/author/priya-nv/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-197.jpg',
        content: <div className="blog-content">
          <ul>
            <li>Authorities have seized and taken down the website of ChipMixer</li>
            <li>The crypto mixer was used to launder funds stolen from Binance, Ronin Bridge, and FTX</li>
          </ul>
          <p>Bitcoin mixer – ChipMixer has been shut down by authorities, as per an announcement by Europol. The operation involved the collaboration of regulatory authorities from various countries. The operation also resulted in the seizure of four servers, over 1909.4 Bitcoins (BTC) – over $46.6 million (nearly EUR 44.2 million), and 7 TB of data.</p>
          <h3>Multiple agencies collaborate to bring down the Bitcoin mixer</h3>
          <p>United States Federal Bureau of Investigation (FBI), Homeland Security Investigation, and Department of Justice took part in taking down the platform. Germany’s Federal Criminal Police Office and General Prosecutors Office Frankfurt-Main also headed the operations. Others included Belgium’s Federal police, Poland’s Central Cybercrime Bureau, and Switzerland’s Cantonal Police of Zurich. The press release further said,</p>
          <p><b>“The ChipMixer software blocked the blockchain trail of the funds, making it attractive for cybercriminals looking to launder illegal proceeds from criminal activities such as drug trafficking, weapons trafficking, ransomware attacks, and payment card fraud.”</b></p>
          <p>Moreover, Europol claims that the Bitcoin mixer, launched in mid-2017, was used to launder over $2.87 billion in cryptocurrencies. A majority of this activity was related to markets in the darkweb, stolen cryptocurrencies, and illegal goods trafficking.</p>
          <p>Notably, ransomware groups like Mamba, Dharma, Zeppelin, and others have the Bitcoin mixer to launder illegally obtained funds. In addition, the regulatory authorities are investigating the platform’s possible association with the funds related to the now-bankrupt crypto exchange – FTX. The authorities are investigating whether the mixer was used by hackers to launder FTX’s funds, which totally amounted to $372 million. As per an on-chain sleuth, ZachXBT, ChipMixer was used to launder 360 BTC of FTX’s funds.</p>
          <p>The Bitcoin mixer was also used by hackers to launder money stolen by Ronin Bridge, according to a report by Slowmist. Hackers had sent 26,021.89 BTC to Chipmixer. The same platform was used by Binance hackers in August 2019, with over 5,300 BTC sent for mixing.</p>
          <p>The current action has resulted in the shut down of the website. However, the authority has not announced any arrests related to the case. The press release further read,</p>
          <p><b>“Europol facilitated the information exchange between national authorities and supported the coordination of the operation. Europol also provided analytical support linking available data to various criminal cases within and outside the EU, and supported the investigation through operational analysis, crypto tracing, and forensic analysis.”</b></p>
        </div>
      },
      {
        id: 192,
        title: 'Cardano [ADA] cools off into key range – Where can traders look for gains',
        category: 'Crypto',
        subCategory: ['Cardano', 'ADA', 'Traders'],
        description: <div className="blog-desc">
          <p>Cardano [ADA] rebounded strongly after hitting its recent low of $0.3020 on 12 March. It saw aggressive buying at the above lows pushing its price break key resistances as Bitcoin [BTC] surged to the $26k area. </p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-196.jpg',
        content: <div className="blog-content">
          <ul>
            <li>ADA’s four-hour chart was bullish.</li>
            <li>The bulls had minimal leverage as per long vs. short positions.</li>
          </ul>
          <p>Cardano [ADA] rebounded strongly after hitting its recent low of $0.3020 on 12 March. It saw aggressive buying at the above lows pushing its price break key resistances as Bitcoin [BTC] surged to the $26k area. </p>
          <p>However, BTC saw a sharp rejection at $26k and was operating below $25k at press time. Similarly, ADA hit the price ceiling at $0.368 and entered a retracement, pushing it into a key price range. As such, BTC’s price action will determine ADA’s price direction in the next few days/weeks.</p>
          <h3>ADA re-enters a previous short-term consolidation range</h3>
          <p>ADA had initially breached the $0.3306 – $0.3466 consolidation range during BTC’s extra rally after the February US CPI data release. The price rejection at $0.3679 set ADA back into the range. But the price action was in the upper range, suggesting a likely bullish breakout if BTC maintains the $24k level and surges.</p>
          <p>Therefore, ADA could swing between $0.3306 and $0.3466 before a possible breakout. A break of the range and a close above $0.3466 could tip near-term bulls to push the price toward the overhead resistance at $0.3679. But they must clear the hurdles at $0.3513 and $0.3605.</p>
          <p>Alternatively, ADA could witness intense selling pressure if the price closed below $0.3306. But the drop could slow down at the double bottom pattern’s neckline of $0.3200 or the previous low of $0.3020. These levels could be used as short-selling targets, especially if BTC witnessed a sharp retracement towards $20k. </p>
          <p>The Relative Strength Index (RSI) pulled back from an overbought territory and moved sideways, indicating buying pressure drop, but selling and buying pressure was almost equivalent at press time. Similarly, the Average Directional Index (ADX) sloped downwards, showing likely further retracement or consolidation for ADA. Given the RSI’s sideways movement, consolidation was highly likely. </p>
          <h3>Bulls have limited leverage in the short-term</h3>
          <p>Bulls had slight hope based on the ADA’s long/short ratio that showed slight leverage on the four-hour timeframe. It shows investors’ positive expectations in the short term. But bears and potential retracement could also not be overruled.</p>
          <p>In addition, ADA’s funding rate was positive across major exchanges, indicating the underlying bullish sentiment. But investors should track BTC’s price action to make more profitable moves.</p>
        </div>
      },
      {
        id: 191,
        title: '‘ETH is deflationary’ – Why this is important under recent circumstances',
        category: 'Crypto',
        subCategory: ['ETH', 'Deflationary',],
        description: <div className="blog-desc">
          <p>Ethereum’s EIP 1559 in August last year marked an important milestone for the network and ETH. The impact of that milestone is particularly evident in the fact that ETH is now deflationary.</p>
        </div>,
        authorName: 'Michael Nderitu',
        authorurl: 'https://ambcrypto.com/author/michael-nderitu/',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023',
        cover: 'https://ncx.cx/images/ncx-blog-195.jpg',
        content: <div className="blog-content">
          <ul>
            <li>ETH sees surge in demand which may remain in the long term courtesy of deflationary milestone</li>
            <li>ETH shorts traders suffer as price pivots while demand for leverage recovers</li>
          </ul>
          <p>Ethereum’s EIP 1559 in August last year marked an important milestone for the network and ETH. The impact of that milestone is particularly evident in the fact that ETH is now deflationary.</p>
          <p>The latest Ethereum data reveals that the network has so far burned over 3 million ETH. The total amount of ETH burned is worth over $9.09 billion, trimming the post Merge total supply by slightly over 62,000 ETH. These findings underscore the progress that the Ethereum network has achieved so far as part of its PoS transition.</p>
          <h3>ETH’s race for sound money</h3>
          <p>ETH’s current 0.42% deflationary rate puts it well within the list of sound money. Why is this important? Well, traditional fiat money is currently feeling the weight of inflation which has been gradually causing the loss of value. As such, investors are eying asset classes that may allow them to protect their wealth from inflation.</p>
          <p>A deflationary asset such as ETH is currently more preferable both in the short and long-term. This is likely one of the reasons why the demand or ETH recently surged, resulting in significant recovery from the bearish performance we saw earlier this month.</p>
          <p>In fact, Glassnode’s data confirmed a surge in demand for the cryptocurrency. For instance – ETH’s exchange outflows volume just hit a new 3-month high. This is a sign that ETH traders have been aggressively buying the recent dip.</p>
          <p>The latest ETH exchange flow data confirmed that exchange outflows are back on the rise. A consequence of the robust buying pressure observed over the last few days, a period during which its price action bounced back strongly.</p>
          <p>The aforementioned surge was strong enough to push ETH back to a new YTD high. As a consequence, the retest of its previous resistance level yielded some sell pressure. Hence, the higher exchange inflows than outflows.</p>
          <h3>The state of ETH shorts, liquidations, and leverage</h3>
          <p>Shorts traders once anticipated further downside after the events of last month. However, the pivot on 9 March triggered a surge in liquidations and a shift to long positions.</p>
          <p>Demand for leverage has been declining since the end of February, likely due to concerns over market outcome in March. This has been an unsurprising outcome, one factoring the recent market events which made it more difficult to predict the next outcome.</p>
          <p>However, we have seen an uptick in demand for leverage over the last few days, with the same corresponding with the price surge.</p>
          <p>The market will likely feature more volatility if the level of leverage continues to rise. A higher likelihood of liquidations may lead to more wild swings.</p>
        </div>
      },
      {
        id: 157,
        title: 'Germany and U.S. Seize Over $46M Crypto Tied to ChipMixer Investigation',
        category: 'Crypto',
        subCategory: ['Germany', 'US', 'Crypto'],
        description: <div className="blog-desc">
          <p>Authorities from Germany and the U.S. have seized up to 44 million euros ($46.3 million) from ChipMixer, a noted cryptocurrency mixer, according to the European Union Agency for Law Enforcement Cooperation (Europol).</p>
        </div>,
        authorName: 'Jamie Crawley',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023 - 5:52 PM',
        cover: 'https://ncx.cx/images/ncx-blog-161.jpg',
        content: <div className="blog-content">
          <p>Authorities from Germany and the U.S. have seized up to 44 million euros ($46.3 million) from ChipMixer, a noted cryptocurrency mixer, according to the European Union Agency for Law Enforcement Cooperation (Europol).</p>
          <p>The authorities took down the platform's infrastructure, seizing four servers, 7 terabytes of data and 1909.4 bitcoins (BTC) ($47.7 million), Europol said on Wednesday.</p>
          <p>The seizure was also supported by Belgium, Poland and Switzerland.</p>
          <p>ChipMixer laundered money for ransomware perpetrators, darknet markets, fraudsters and state-sponsored actors, according to the U.S. Department of Justice (DOJ).</p>
          <p>One operator of ChipMixer, Minh Quốc Nguyễn, from Hanoi, Vietnam, was charged on Wednesday in Philadelphia with money laundering, operating an unlicensed money transmitting business and identity theft, the DOJ said.</p>
          <p>The platform is believed to have enabled the laundering of 152,000 BTC – around $3.8 billion at current prices – since 2017, a large share of which was connected to ransomware groups, illicit goods trafficking and child sexual exploitation.</p>
          <p>According to the DOJ, activities enabled by ChipMixer include over $700 million in bitcoin associated with wallets designated as stolen funds, including those related to heists by North Korean cyber actors, and bitcoin used by the Russian General Staff Main Intelligence Directorate (GRU) to purchase infrastructure for Linux-based malware Drovorub.</p>
          <p>Mixing services gained increased prominence in August 2022 when Ethereum-based mixer Tornado Cash was sanctioned by the U.S. Treasury Department’s Office of Foreign Asset Control for facilitating North Korea’s money-laundering operations. Shortly after that, Tornado Cash’s web developer Alexey Pertsev was arrested.</p>
        </div>
      },
      {
        id: 158,
        title: 'Circle’s USDC bounces back after Silicon Valley Bank bust',
        category: 'Crypto',
        subCategory: ['Circle', 'USDC', 'Sillicon valley Bank'],
        description: <div className="blog-desc">
          <p>Predictions that things will get worse before they get better are commonplace among the punditocracy in their comments on virtually any subject one cares to name. But they’ve arguably become even more common in crypto than in other fields following the industry’s travails during the past year. So far, so unsurprising.</p>
        </div>,
        authorName: 'Forkast.News',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023 - 3:03 PM',
        cover: 'https://ncx.cx/images/ncx-blog-162.jpg',
        content: <div className="blog-content">
          <h3><strong>In this issue</strong></h3>
          <ol>
            <li>USDC: Level-pegging</li>
            <li>Silvergate: Closing act</li>
            <li>China finance: Reverse decentralization</li>
          </ol>
          <h3><strong>From the Editor’s Desk</strong></h3>
          <p>Dear Reader,</p>
          <p>Predictions that things will get worse before they get better are commonplace among the punditocracy in their comments on virtually any subject one cares to name. But they’ve arguably become even more common in crypto than in other fields following the industry’s travails during the past year. So far, so unsurprising.</p>
          <p>The failure of cryptocurrency-focused lender Silvergate, which had been widely expected since at least the beginning of this month, alongside the collapses of Silicon Valley Bank and Signature Bank, are very much of a piece with this narrative.</p>
          <p>The closure of the Silvergate Exchange Network and Signature Bank’s Signet, real-time payment platforms that allowed clients to move money into crypto around the clock, is already affecting crypto trade. The two services handled a huge chunk of fiat settlement for crypto, and their disappearance from the market is crimping liquidity, threatening to send transaction costs higher, at least in the short term.</p>
          <p>Where the darkest-before-the-dawn narrative starts to get interesting, though, involves what comes next. As we’ve seen, the crypto industry has an uncanny knack of finding creative ways out of tight spots, and in any case, it’s a sector that’s experiencing growing pains like any other as it moves through its teenage years and into adulthood.</p>
          <p>The banking system didn’t collapse during the global financial crisis 15 years ago when the titans of TradFi were shaken to their foundations, and neither will crypto as it loses some important players but finds others to take their place.</p>
          <p>The strife stirred up by Silvergate, SVB and Signature will pass, and, since nature abhors a vacuum, new providers of similar services will doubtless swoop in and fill the gap they leave in likely even more innovative ways.</p>
          <p>If there’s one thing that being in this industry should have taught all of us by now, it’s that nothing stays the same for long and that this frenetic pace of change is what keeps the sector at the cutting edge as it continues to shape the future of finance.</p>
          <p>We of course regret the trifecta of failures that have occurred in recent days. But long live disruption.</p>
          <p>Until the next time,</p>
          <p>Angie Lau,</p>
          <p>Founder and Editor-in-Chief</p>
          <p>Forkast</p>
          <h3><strong>1.Stable genus</strong></h3>
          <img src="https://ncx.cx/images/ncx-blog-162-1.jpg" alt="NCX Stable genus" />
          <p>By the numbers: USDC — over 5,000% increase in Google search volume.</p>
          <p>Circle Internet Financial’s USDC, the world’s second-largest stablecoin by market capitalization, regained parity with the U.S. dollar on Monday morning Asia time after the Federal Deposit Insurance Corporation (FDIC) said it had taken over failed tech- and crypto-focused lender Silicon Valley Bank (SVB) and guaranteed its deposits to prevent a broader bank run.</p>
          <ul>
            <li>USDC fell as low as US$0.8774 on Saturday before recovering to US$0.999 around mid-morning Hong Kong time on Monday, according to CoinMarketCap data. It has mostly hovered around the US$0.997 mark since.</li>
            <li>USDC regained its peg after the FDIC said it had taken control of SVB on Sunday. Circle had held US$3.3 billion of deposits, accounting for around 8% of total USDC reserves, at the troubled bank.</li>
            <li>Circle co-founder Jeremy Allaire said that USDC’s deposits held by SVB would be fully recoverable. Circle also announced a partnership with New Jersey-based Cross River Bank to enable the automated minting and redemption of USDC for customers starting on Monday.</li>
            <li>USDC started trading off its peg over the weekend after it was revealed that it held reserves at SVB, which had more than US$200 billion in assets and represents the largest bank failure since the global financial crisis of 2008.</li>
            <li>SVB and Signature Bank, two key lenders to the crypto industry, were both shut down and taken over by U.S. regulators to prevent systemic risk to the broader banking industry.</li>
            <li>“All depositors of [Signature Bank] will be made whole,” said a joint statement released by the U.S. Treasury Department, the Federal Reserve and the FDIC. “​​As with the resolution of Silicon Valley Bank, no losses will be borne by the taxpayer.”</li>
            <li>USDC is currently the fifth-largest cryptocurrency by market capitalization, with a US$38.4 billion market cap.</li>
          </ul>
          <h3><strong>Forkast.Insights | What does it mean?</strong></h3>
          <p>The collapse of Silicon Valley Bank was not due to its affiliations with crypto. But combined with the failure of Signature and Silvergate, its collapse has rattled the industry to its core.</p>
          <p>When Signature went down, it was revealed that Coinbase and Paxos, the issuer of Binance’s BUSD stablecoin, collectively had around US$500 million deposited in the bank. When Silicon Valley Bank called it a day, USDC issuer Circle had several billion dollars parked there.</p>
          <p>Although Circle acted quickly to reassure investors they wouldn’t lose their money, a bigger question arose: Is the crypto industry’s long-term viability reliant on regulators stepping in to clean up its messes?</p>
          <p>Since 2008, and before that, if we’re honest, central banks around the world have played a very visible role in backstopping the financial system and the economy more broadly when things have gone sideways. As the Covid pandemic brought much of the world to a halt, their huge injections of liquidity spared it an even worse fate than it ended up suffering. That may have been a rescue that was always guaranteed. Crypto has no such luxury.</p>
          <p>Circle may have handled its Silicon Valley problem adroitly, but would Tether fare so well in similar circumstances? Tether appears to be only skimpily regulated in the U.S., yet its market cap is nearly twice that of USDC. If it gets into trouble, there are few people it can turn to for help.</p>
          <p>When Terra collapsed last year, no central authority was willing to pick up the bill. If crypto wants to be taken seriously, its “safest” assets must be made safer than they currently are.</p>
          <h3><strong>2.Gate carsh</strong></h3>
          <img src="https://ncx.cx/images/ncx-blog-162-3.jpg" alt="NCX Gate crash" />
          <p>By the numbers: Silvergate — over 5,000% increase in Google search volume.</p>
          <p>California-based, crypto-focused lender Silvergate Bank is being wound up by its parent company, Silvergate Capital, becoming another victim of last year’s collapse of crypto exchange FTX.</p>
          <ul>
            <li>Silvergate Capital will shut down the bank’s operations and liquidate it in an orderly manner, offering full repayments to all depositors, according to a company announcement.</li>
            <li>Unlike the recent failures of Silicon Valley Bank and Signature Bank, Silvergate’s shutdown has not involved intervention by the U.S. Federal Deposit Insurance Corporation.</li>
            <li>As a federally insured bank, Silvergate provided banking services to the crypto industry. In 2017, it rolled out its Silvergate Exchange Network (SEN), a 24/7 payment network allowing instantaneous fiat transactions between crypto exchanges and their customers.</li>
            <li>The bank was hit hard by the collapse of FTX in November 2022, with its non-interest-bearing deposits slumping from US$12.1 billion on Sept. 30 to US$3.9 billion on Dec. 31, a dive of more than 67%, according to a Silvergate quarterly report.</li>
            <li>Silvergate reported a US$1 billion loss for the fourth quarter of 2022 and had laid off 40% of its staff by Jan. 5 this year. It is also being probed by U.S. prosecutors over its links to Alameda Research, FTX’s trading arm.</li>
            <li>On March 1, Silvergate Capital announced in a filing to the U.S. Securities and Exchange Commission that it was evaluating “its ability to continue as a going concern.” and it discontinued its SEN service on March 3.</li>
            <li>The crypto market has felt the absence of SEN, which Silvergate said had handled around US$563.3 billion of U.S. dollar transfers last year. On some U.S. crypto exchanges, Bitcoin-to-dollar and Bitcoin-to-Tether transactions dropped between 35% and 45% between the beginning of the month and March 11, Bloomberg reported earlier this week.</li>
            <li>At the same time, U.S.-based crypto exchanges are seeking alternatives for banking services, with Kraken set to launch its own bank in the state of Wyoming, aiming to offer “a more seamless integration between crypto and the traditional financial system”.</li>
            <li>Silvergate’s stock was trading at US$2.21 at press time, down more than 80% from its opening price on March 1, according to data from Yahoo Finance.</li>
          </ul>
          <h3><strong>Forkast.Insights | What does it mean?</strong></h3>
          <p>Crypto users could be forgiven for feeling un-banked in the U.S., especially when it comes to 24/7 payments. The loss of Silvergate’s seamless off-ramp has meant crypto companies have been forced to find alternative means of transacting with traditional finance.</p>
          <p>A stark illustration of this came in the form of Circle’s weekend announcement that it would cover any losses related to the closure of Silicon Valley Bank, but that investors would have to wait until banks opened on Monday before they could withdraw their funds.</p>
          <p>This is a profound development affecting one of crypto’s key features: markets that never close and the ability to cash out anytime. It’s a problem being felt by other companies, too.</p>
          <p>Crypto.com and Binance are having to find new off-ramps for the euro and British pound after banking partners pulled out of crypto. While businesses are exploring other options, and new companies gear up to meet their needs, cryptocurrencies look more isolated from the rest of the finance sector.</p>
          <p>This is likely to continue as regulators scrutinize the balance sheets of businesses offering services to the industry. It ought to be beneficial for consumers in the long run, but for now, it leaves crypto with fewer friends in TradFi.</p>
          <h3><strong>3.Regulatory consolidation</strong></h3>
          <img src="https://ncx.cx/images/ncx-blog-162-4.jpg" alt="NCX Regulatory consolidation" />
          <p>China has passed a plan to establish a new national financial regulator, replacing the China Banking and Insurance Regulatory Commission, according to state-run news outlet CGTN. The move was part of an overhaul of State Council institutions announced at the annual “two sessions” meetings of the National People’s Congress and the Chinese People’s Political Consultative Conference last Friday.</p>
          <ul>
            <li>The new regulatory administration will be set up directly under the State Council. Aside from replacing the China Banking and Insurance Regulatory Commission (CBIRC), it will also consolidate certain functions of the People’s Bank of China (PBOC) and the China Securities Regulatory Commission (CSRC). The new regulator’s official name has not yet been revealed.</li>
            <li>Financial regulation in China has up to now been the preserve of the CBIRC, the PBOC and the CSRC, whose functions have at times overlapped. Following the reform, the respective responsibilities of PBOC and CSRC to protect consumers and investors will be shifted to the regulator, which will supervise most of the country’s financial activities, apart from the securities sector.</li>
            <li>The reform will further centralize China’s supervision of its finance sector and comes as part of an overall consolidation of political power in the country.</li>
            <li>“China’s regulatory reforms will strengthen regulators’ capability to establish and enforce a unified regulatory framework, as well as reduce the room for regulatory arbitrage,” CNBC reported Moody’s Investors Service Vice President David Yin as having said in a note.</li>
            <li>The reform plan follows President Xi Jinping’s warning in February about three “systemic risks” in China’s economy: risks to the real estate sector, financial risks and local government debt risks.</li></ul>
          <li>The plan does not give much indication of how crypto assets will be regulated. According to a PBOC notice dated September 2021 that announced China’s blanket ban on cryptocurrency transactions, the CBIRC, the PBOC and the CSRC were together responsible for curbing crypto speculation to safeguard financial stability.</li>
          <h3><strong>Forkast.Insights | What does it mean?</strong></h3>
          <p>Beijing’s announcement of a new financial regulator shows the extent to which it is poised to charge ahead with its Digital China strategy while ramping up efforts to identify and weed out perceived risks to the country’s financial system.</p>
          <p>When China banned cryptocurrency transactions in 2021, 10 regulators — including the  China Banking and Insurance Regulatory Commission, the People’s Bank of China and the China Securities Regulatory Commission — pointed to crypto as a risk and announced a ban in a joint notice. There have been some overlaps in oversight responsibilities among the three authorities, but now, with the establishment of the new regulator, currently informally known as “the National Financial Regulatory Administration,” the reshuffle is expected to clarify regulatory power and functions.</p>
          <p>According to analysts at Citic Securities and Guotai Junan Securities, cited in a report by business media outlet Caixin Global, the top-level realignment aims to spell out the regulatory relationship between monetary policy and financial consumer protection.</p>
          <p>When it comes to consumer protection, the State Administration for Market Regulation (SAMR) on Tuesday released a report showing a growing number of complaints associated with non-fungible tokens (NFTs). Last year, NFT buyers in China flooded SAMR with gripes about scams and price manipulation, with official complaints skyrocketing 30,000% to 59,700 from just 198 the previous year.</p>
          <p>Meanwhile, China appears determined to develop and oversee the industries contributing to its digital economy by setting up a national data bureau. That makes sense, as a big chunk of the nation’s economy depends on its digital industries. A government “work report” submitted on March 5 to the country’s rubber-stamp legislature showed that the value-added output of new industries and businesses in the country’s digital economy accounted for more than 17% of gross domestic product.</p>
          <p>It remains to be seen how the new financial regulator will approach NFTs, which in China have frequently involved fraud and wild speculation, and how it and other authorities will strike a balance between encouraging digital innovation and ramped-up regulatory scrutiny. But what’s more certain following the heavily politically loaded meetings that wrapped up a few days ago is that however China’s digital future takes shape, it may well look even more centralized under the Communist Party’s control than it already is.</p>
        </div>
      },
      {
        id: 159,
        title: 'KuCoin Has Closed a $10M Funding Round Into CNHC, but Is the Offshore Yuan Right for a Stablecoin?',
        category: 'Crypto',
        subCategory: ['Kucoin', 'CNHC', 'Yaun'],
        description: <div className="blog-desc">
          <p>KuCoin Ventures, the venture arm of China-focused (but Seychelles-registered) crypto exchange KuCoin, led a $10 million funding round into the stablecoin issuer behind CNHC, a stablecoin pegged to the value of the offshore yuan.</p>
        </div>,
        authorName: 'Sam Reynolds',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023 - 2:00 PM',
        cover: 'https://ncx.cx/images/ncx-blog-163.jpg',
        content: <div className="blog-content">
          <p><strong>In this article:</strong></p>
          <p>KuCoin Ventures, the venture arm of China-focused (but Seychelles-registered) crypto exchange KuCoin, led a $10 million funding round into the stablecoin issuer behind CNHC, a stablecoin pegged to the value of the offshore yuan.</p>
          <p>CNHC is pegged 1:1 with the offshore yuan, known by its abbreviation CNH to differentiate it from China’s onshore yuan, which goes by CNY. Reserves for the CNH are kept at a Hong Kong depository institution.</p>
          <p>“This investment in CNHC is part of KuCoin Ventures' broader strategy of investing in the Web3 infrastructure in the APAC region,” Justin Chou, chief investment officer of KuCoin and lead at KuCoin Ventures, said in a statement. “Hong Kong has a well-established traditional finance ecosystem. With the regulation and new policy for next-generation digital assets, Hong Kong has a real opportunity at becoming the new crypto center of the world.”</p>
          <p>CNHC is issued on both Ethereum and Conflux. On-chain data shows that Ethereum has 23 holders of the token and 102 transfers, while Conflux has 606 holders and 1,487 transfers.</p>
          <p>Fan Long, co-founder of Conflux, said Conflux is capturing the majority of the volume here because of Conflux’s “regulatory compliance” in China.</p>
          <p>“The significance of CNHC stems from its capacity to connect traditional financial systems with the emerging Web3 landscape, especially amid heightened regulatory scrutiny in the U.S.,” he told CoinDesk in a note. “CNHC presents a practical alternative for businesses and users in search of compliant options beyond the U.S.-centric regulatory framework.”</p>
          <h3><strong>Hunting for a dollar alternative</strong></h3>
          <p>Stablecoins are overwhelmingly backed by the U.S. dollar. While there are stablecoins based on other world currencies such as the euro, pound, or other dollars like the Australian, Canadian or Singaporean variety, none come close to touching the volume of U.S. dollar-backed stablecoins including USDT and USDC.</p>
          <p>On one hand, this has only re-enforced U.S. dollar hegemony. The next frontier of finance being denominated in dollars is largely a good thing as it extends U.S. rule making, CoinDesk columnists have argued.</p>
          <p>But at the same time, not everyone wants to follow U.S. rules, especially when they have no nexus to the country. Regulators in the U.S. are also hesitant to allow crypto full-fettered access to the American banking system until stringent federal oversight is established.</p>
          <p>The market itself is not a fan of the U.S.-centric concentration risk this presents. USDC, knocked off its peg for multiple days during the crypto banking crisis, only recently regained it but the smart money is skeptical.</p>
          <p>BitMEX co-founder Arthur Hayes thinks the answer to all of this is a bitcoin derivatives-based algorithmic stablecoin called the kakaDollar, though the market might be skeptical of the concept after the collapse of Terra last year.</p>
          <p>There’s certainly been a successful use case for the CNH in China’s offshore bond market, for bwhich it was built. But crypto is something else entirely.</p>
          <p>It’s not without its skeptics.</p>
          <p>Alex Liu, CEO of Taipei-based Maicoin, said in a recent interview with CoinDesk the RMB is too tightly controlled on- or offshore for it to work out.</p>
          <p>“I think the only real challenge for the U.S. dollar is the RMB. But not through crypto, and not through China’s CBDC,” he said, referring to a central bank digital currency. He said the real focus is oil traded in RMB-denominated contracts. “Most trade between Russia and China has been de-dollarized,” he said.</p>
        </div>
      },
      {
        id: 160,
        title: 'Bitcoin, Ether continue run up; Solana, Dogecoin lead gainers; U.S. equities rebound',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Ether', 'solana'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ether continued the strong price gains for the week to move higher in Wednesday morning trading in Asia, with most of the top 10 non-stablecoin cryptocurrencies logging advances for the past seven days. Solana and Dogecoin led the Wednesday winners. The rise in cryptocurrencies accompanied a rebound in U.S. equities on Tuesday as concern about bank failures receded and February inflation data released the same day was in line with expectations.</p>
        </div>,
        authorName: 'Tom Zuo',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023 - 6:39 AM',
        cover: 'https://ncx.cx/images/ncx-blog-164.jpg',
        content: <div className="blog-content">
          <p><strong>In this article:</strong></p>
          <p>Bitcoin and Ether continued the strong price gains for the week to move higher in Wednesday morning trading in Asia, with most of the top 10 non-stablecoin cryptocurrencies logging advances for the past seven days. Solana and Dogecoin led the Wednesday winners. The rise in cryptocurrencies accompanied a rebound in U.S. equities on Tuesday as concern about bank failures receded and February inflation data released the same day was in line with expectations.</p>
          <h3><strong>Fast facts</strong></h3>
          <ul>
            <li>Bitcoin rose 2.10% in the past 24 hours to US$24,789 at 09:00 a.m. in Hong Kong, according to CoinMarketCap data, for a weekly gain of 11.39%. The world’s largest cryptocurrency briefly broke the US$26,000 ceiling on Tuesday evening in Asia and is now up almost 50% for the year to date.</li>
            <li>Ether gained 1.90% to US$1,706, rising 8.86% in the past seven days.</li>
            <li>Solana led the gainers in the top 10, adding 2.75% to US$20.93 for a rise of 3.12% for the week. The Solana Foundation introduced Ask Solana U on Tuesday, an AI-powered chat-like engine that aims to assist Web3 developers on the Solana blockchain.</li>
            <li>Dogecoin rose 2.74% to US$0.07478. The memecoin is up 0.85% for the seven-day period, but still down more than 7% from the beginning of March.</li>
            <li>XRP edged 0.54% higher to trade at US$0.3735, but is the only top 10 non-stablecoin cryptocurrency with a weekly loss, down 2.20%. Brad Garlinghouse, the CEO of Ripple Labs whose payment network is powered by XRP,  tweeted on Monday that Ripple had some exposure to the failed Silicon Valley Bank. This was accompanied by a large flow of XRP transactions on the same day, according to crypto tracker Whale Alert.</li>
            <li>Crypto exchange Binance’s BNB rose 0.51% to US$309.51, posting a weekly gain of 6.88%. The world’s largest crypto exchange on Tuesday said it will halt its U.K. pound deposits and withdrawals by May this year, after its local partner for fiat on- and off-ramps said it was ending the service due to regulatory concerns.</li>
            <li>The total crypto market capitalization rose 1.30% in the past 24 hours to US$1.09 trillion. Total trading volume over the last 24 hours gained 8.47% to US$101.54 billion.</li>
            <li>U.S. equities closed higher on Tuesday. The Dow Jones Industrial Average rose 1.06%, the S&P 500 moved up 1.65% and the Nasdaq Composite Index posted the biggest gain of 2.14%.</li>
            <li>Efforts by U.S. regulators and President Joe Biden to calm markets seemed to have the desired effect after the collapses of U.S. banks at the weekend. First Republic Bank, which slid over 60% on Monday and was briefly suspended from trading, bounced back almost 27% on Tuesday, according to CNBC.</li>
            <li>Investors in U.S. equities and cryptocurrencies also gained some reassurance from the U.S. Labor Department’s February inflation report released Tuesday, which showed the Consumer Price Index (CPI) rose 0.6% by month and 6% on year, all in line with expectations. Though still above the Federal Reserve’s stated goal to keep annual inflation below 2%, the data suggest inflation in the U.S. is moderating and may convince the Fed to ease back on interest rate hikes.</li>
            <li>After a raft of rate hikes last year and into 2023, U.S. interest rates are now between 4.5% to 4.75%, the highest since October 2007. Analysts at the CME Group expect a 79.7% chance the Fed will raise rates by 25 basis points this month. The chance of no rate increase is at 20.3%, a decrease from 35% on Tuesday.</li>
          </ul>
        </div>
      },
      {
        id: 161,
        title: 'Blockchain Firm RockX Unveils Institutional Liquid Staking Platform',
        category: 'Crypto',
        subCategory: ['blockchain', 'rockx', 'platform'],
        description: <div className="blog-desc">
          <p>RockX, a Singapore-based blockchain company, is looking to attract institutional investors to liquid staking with its new service, Bedrock.</p>
        </div>,
        authorName: 'Jamie Crawley',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023 - 5:00 AM',
        cover: 'https://ncx.cx/images/ncx-blog-165.jpg',
        content: <div className="blog-content">
          <h3><strong>In this article:</strong></h3>
          <p>RockX, a Singapore-based blockchain company, is looking to attract institutional investors to liquid staking with its new service, Bedrock.</p>
          <p>As well as offering staking services to retail customers, Bedrock offers institutional-grade know your customer (KYC) and anti-money laundering (AML) compliance to institutions looking to stake more than 32 ether (ETH)($57,000), RockX said in an email Wednesday.</p>
          <p>The company is initially targeting exchanges and wealth management platforms, with a view to attracting large funds and banking institutions further down the line, founder and CEO Chen Zhuling told CoinDesk in an interview. Crypto trading firm Amber Group, a RockX investor, will be one of Bedrock's first clients.</p>
          <p>Staking is a means of earning yield on digital assets, in which crypto holders can lock up their tokens to secure proof-of-stake blockchains in exchange for a reward. With liquid staking, investors keep their capital liquid and use their staked tokens as collateral by receiving derivatives.</p>
          <p>Last month, liquid staking became the second-largest crypto sector when its total value of assets locked reached $14.1 billion, surpassing the $13.7 billion held on decentralized lending and borrowing protocols. Liquid staking platform Lido Finance has become decentralized finance's largest protocol with about $10 billion worth of digital assets locked on the platform.</p>
          <p>The catalyst for liquid staking is the Ethereum blockchain's Shanghai upgrade, which will allow stakers to withdraw the ether (ETH) they have staked and for which they have accumulated rewards. The expectation is Shanghai will strengthen ETH by establishing a blueprint for staking protocols and give users more confidence in their sovereignty over their assets.</p>
          <p>However, there have been doubts cast on the appetite for liquid staking among financial institutions, particularly in the Asian market closest to RockX. David Cicoria, head of markets technology for digital asset custodian Hex Trust, recently said institutions are giving it a miss due to risks of depegging, hacks and lack of regulatory clarity.</p>
          <p>Zhuling agreed institutions do hold some of these concerns, but stressed the important distinction between custodial and non-custodial staking services. Bedrock falls under the latter.</p>
          <p>"We are not holding any ETH at all. All the ETH is held in a smart contract and then deployed to validators," he said.</p>
          <p>"It's very easy to tally how many coins are held in the pool and thence being passed on to validators, so there is no risk of inflation of the numbers or misappropriation of assets," he added.</p>
        </div>
      },
      {
        id: 162,
        title: 'In About-Face, Crypto Exchanges Abandon Support for STG Token Reissuance',
        category: 'Crypto',
        subCategory: ['Exchange', 'Crypto', 'STG Token'],
        description: <div className="blog-desc">
          <p>Bitfinex and Binance are walking back technical support for Stargate Finance’s plans to reissue its stargate (STG) tokens, according to several announcements that came out this week. They will now promote the use of the protocol’s original STG tokens.</p>
        </div>,
        authorName: 'Elizabeth Napolitano',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 15, 2023 - 2:36 AM',
        cover: 'https://ncx.cx/images/ncx-blog-166.jpg',
        content: <div className="blog-content">
          <h3>In this article:</h3>
          <p>Bitfinex and Binance are walking back technical support for Stargate Finance’s plans to reissue its stargate (STG) tokens, according to several announcements that came out this week. They will now promote the use of the protocol’s original STG tokens.</p>
          <p>The crypto exchanges’ decision to eliminate support for the new STG tokens follows Stargate decentralized autonomous organization’s (DAO) decision on Tuesday to pump the brakes on its plan to mint new tokens, according to a recent community proposal. At the time of that decision Stargate Finance had already begun disseminating the new STG tokens, according to data from Etherscan – a problem with which exchanges must now contend to eliminate security risks to STG token holders.</p>
          <p>StargateDAO initially voted in favor of reissuing all STG tokens by March 15 after it uncovered security risks posed by “illegitimate STG transfers from compromised Alameda wallets,” referring to the failed crypto trading firm where roughly 10% of STG tokens are held. However, the DAO abandoned those plans when liquidators of FTX, Alameda’s sister company, rebuked the plan, arguing it violated an automatic stay issued in the FTX bankruptcy case.</p>
          <p>"[The reissuance] was to try to help the safety of the tokens in question. This is now being challenged with the liquidators clearly not wanting them moved," StargateDAO’s latest proposal to unwind the token’s reissuance reads.</p>
          <p>As a result, Bitfinex and Binance are now revoking scheduled STG contract swaps on various blockchains. They have also taken steps to ascertain whether their users who have STG holdings are, in fact, holding original STG tokens and not reissued tokens.</p>
          <p>Bitfinex provided its users with a contract address on the Ethereum network against which they could check the validity of their STG tokens.</p>
          <p>"We would like to remind our customers that there are STG tokens with different contract addresses in circulation; therefore, please don't send any STG tokens to our platform other than the one with the above contract address to avoid losing funds," Bitfinex said in its post about the canceled reissuance.</p>
          <p>Meanwhile, Binance pledged to "swap [users’] new STG tokens back to old STG tokens" to mitigate security risks associated with the use of the new STG tokens.</p>
        </div>
      },
      {
        id: 163,
        title: 'U.S. could pass stablecoin legislation in a few days, says Rep. Maxine Waters',
        category: 'Crypto',
        subCategory: ['US', 'Stablecoin', 'legislation'],
        description: <div className="blog-desc">
          <p>Market turmoil resulting from Silicon Valley Bank's collapse briefly caused Circle's USDC (USDC-USD) stablecoin to break its peg to the dollar. In response, the stablecoin issuer's management team has called upon the U.S. congress to urgently pass stablecoin legislation.</p>
        </div>,
        authorName: '',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 14, 2023 - 9:35 PM',
        cover: 'https://ncx.cx/images/ncx-blog-167.jpg',
        content: <div className="blog-content">
          <h3>In this article:</h3>
          <p>Market turmoil resulting from Silicon Valley Bank's collapse briefly caused Circle's USDC (USDC-USD) stablecoin to break its peg to the dollar. In response, the stablecoin issuer's management team has called upon the U.S. congress to urgently pass stablecoin legislation.</p>
          <p>In an interview with Yahoo Finance's Jennifer Schonberger, House Committee on Financial Services Chair Rep. Maxine Waters (D-CA), says the legislation could be passed in just a few days, hinting that the delay was due to concerns over which political party would be in control of the House of Representatives.</p>
          <p>"The only thing that interfered, I think, with its passage was the anticipation that the house was going to change and it was going to be in the hands of the Republicans and they should take charge rather than those of us on the Democratic side," said Ranking Member Waters.</p>
          <p>Rep. Waters declined to give a timetable for the legislation, though she did reaffirm that she and Rep. Patrick McHenry (R-NC), are focused on stablecoin legislation and have bipartisan support to get it done.</p>
        </div>
      },
      {
        id: 164,
        title: 'Bitcoin breaks US$26,000 as inflation cools in February',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'breaks', 'inflation'],
        description: <div className="blog-desc">
          <p>Bitcoin, the world’s largest cryptocurrency, rallied past the US$26,000 mark at 09:15 p.m. in Hong Kong, shortly after the release of the U.S. Consumer Price Index (CPI), which indicated a drop in annual inflation rate to 6% in February.</p>
        </div>,
        authorName: 'Zoltan Vardai',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 14, 2023 - 7:37 PM',
        cover: 'https://ncx.cx/images/ncx-blog-168.jpg',
        content: <div className="blog-content">
          <p>Bitcoin, the world’s largest cryptocurrency, rallied past the US$26,000 mark at 09:15 p.m. in Hong Kong, shortly after the release of the U.S. Consumer Price Index (CPI), which indicated a drop in annual inflation rate to 6% in February.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>The inflation data was in line with the expectations of the market and a decrease from January’s rate of 6.4%. CPI rose 0.4% on the month, lower than 0.5% in January.</li>
            <li>Housing remains a big contributor to the index, accounting for over 70% of the monthly increase.</li>
            <li>Meanwhile, Bitcoin jumped 6.10% to soar past the US$26,000 mark, a level not seen since June 2022, according to CoinMarketCap data.  Ether also enjoyed a bump, rising 4.57% to US$1,767.</li>
            <li>This increase in cryptocurrency prices boosted the total crypto market capitalization by 12.15% in the past 24 hours to US$1.14 trillion</li>
            <li>Market analysts were previously predicting that the Federal Reserve would consider 50 basis point rate hikes at the next Federal Open Market Committee meeting scheduled for next week. However, recent events have changed expectations regarding interest rates.</li>
            <li>The failures of Silicon Valley Bank and Signature Bank over the past weekend have caused many market watchers to revise their predictions. Some analysts even believe that the Fed may decide to leave rates unchanged.</li>
            <li>“While a 50 basis point interest rate hike was seen as increasingly possible last week, U.S. bank issues have shifted expectations toward lower raises,” Ahmed Negm, head of market research at trading platform XS.com, said in a statement.</li>
          </ul>
        </div>
      },
      {
        id: 155,
        title: 'Bitcoin, Ether extend gains as U.S. regulators take charge of banking industry turmoil',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Banking Industry', 'US'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ether rose the most in the top 10 non-stablecoin cryptocurrencies by market capitalization on Tuesday afternoon in Asia. Cryptocurrencies have been on a rebound since U.S. regulators took charge of the failures in the banking industry and moved to protect deposits across crypto-linked banks. The asset class is also benefiting from market talk that turmoil in the banking industry could prompt the U.S. Federal Reserve to halt interest rate increases.</p>
        </div>,
        authorName: 'Pradipta Mukherjee',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 14, 2023 - 3:18 PM',
        cover: 'https://ncx.cx/images/ncx-blog-159.jpg',
        content: <div className="blog-content">
          <p>Bitcoin and Ether rose the most in the top 10 non-stablecoin cryptocurrencies by market capitalization on Tuesday afternoon in Asia. Cryptocurrencies have been on a rebound since U.S. regulators took charge of the failures in the banking industry and moved to protect deposits across crypto-linked banks. The asset class is also benefiting from market talk that turmoil in the banking industry could prompt the U.S. Federal Reserve to halt interest rate increases.</p>
          <h3><strong>Fast facts</strong></h3>
          <ul>
            <li>Bitcoin, world’s largest cryptocurrency, gained 8.2% to US$24,404 in 24 hours to 4 p.m. in Hong Kong, bringing its weekly gains to 8.8%, according to CoinMarketCap data. Ethereum rose 3.93% to US$1,677, after rising 6.72% on the week.</li>
            <li>Dogecoin strengthened 2.31% to US$0.07242, but lost 3.54% in the past seven days. Polygon’s Matic token gained 2.04% to US$1.16, after rising 0.83% on the week.</li>
            <li>USD Coin (USDC), the second largest stablecoin by market capitalization, rose 0.75% to US$0.999, but slipped 0.09% on the week. USDC had momentarily lost its peg to the U.S. dollar after the shutdown of Silicon Valley Bank (SVB) but recovered after Circle, its issuer, announced a new partnership with Cross River Bank.</li>
            <li>“We expect the stress in the banking sector, and the wider impact on confidence, now will give the central bank [U.S. Federal Reserve] cause for pause on its rate hike program,” said Nigel Green, chief executive of financial advisory firm deVere Group.</li>
            <li>The global crypto market capitalization gained 4.39% to US$1.07 trillion, while the total crypto market volume rose 16.93% to US$90.47 billion in the past 24 hours.</li>
            <li>All Asian equity markets dropped on Tuesday as investors braced for the biggest U.S. bank failure since 2008. Hong Kong’s Hang Seng Index dropped 2.27%, while South Korea’s Kospi fell 2.56% and Japan’s Nikkei 225 declined 2.19%.</li>
            <li>The Shanghai Composite lost 0.72% while the Shenzhen Component Index dropped 0.77%, despite China announcing it will resume issuing visas from March 15 as it eased its Covid-zero policy. Investors are also looking forward to a series of China’s economic indicators expected to be published on Wednesday.</li>
            <li>Investors also await U.S. inflation data later on Tuesday. The February consumer price index (CPI), a key inflation indicator, is expected at 6% on year, dropping from the 6.4% for the year that ended January 2023, but still well above the U.S. central bank’s 2% target.</li>
            <li>“Despite most warning signs flashing red, the U.S. has continued to defy gravity,” said Keith Wade, chief economist and strategist at Schroders. “We still think that higher interest rates, which we now expect to peak at 5.25% in the second quarter of 2023, will lead to a recession. However, it is likely to be relatively short and shallow.”</li>
            <li>The Forkast NFT 500 index rose 0.7% to 4,064.39. Planet IX – Gravity Grade rallied 51.67% as the day’s biggest gainer, followed by EGoldMiner that strengthened 44.63%.</li>
            <li>European stocks traded slightly higher after a two-day sell-off. The STOXX 600 inched up 0.1% and Germany’s DAX 40 rose 0.53%, while Britain’s FTSE 100 fell 0.32% to a two-month low, as investors assessed spillover effects from the collapse of SVB and Signature Bank.</li>
            <li>HSBC’s shares fell 1.28% during the day, following news that the bank would inject £2 billion (US$2.4 billion) of liquidity into the U.K. unit of SVB.</li>
          </ul>
        </div>
      },
      {
        id: 154,
        title: 'Circle exec Dante Disparte calls on Congress to pass legislation to regulate stablecoins',
        category: 'Crypto',
        subCategory: ['Circle', 'Stablecoin', 'legislation'],
        description: <div className="blog-desc">
          <p>Yahoo Finance’s Jennifer Schonberger joins the Live show to discuss Circle Chief Strategy Officer Dante Disparte’s call on Congress for the urgent approval of stablecoin regulation.</p>
        </div>,
        authorName: 'Yahoo Finance',
        authorurl: 'https://www.yahoo.com/author/yahoo--finance',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 13, 2023 - 7:37 PM',
        cover: 'https://ncx.cx/images/ncx-blog-158.jpg',
        content: <div className="blog-content">
          <p>Yahoo Finance’s Jennifer Schonberger joins the Live show to discuss Circle Chief Strategy Officer Dante Disparte’s call on Congress for the urgent approval of stablecoin regulation.</p>
          <p>RACHELLE AKUFFO: All right, well, turning now to Yahoo Finance's Jennifer Schonberger, who's been following this story all day and has more on this. Hey, Jennifer.</p>
          <p>JENNIFER SCHONBERGER: Hey there, Rachelle. That's right. Just spoke with Circle's Chief Policy Officer and Head of Strategy Dante Disparte, who is urgently calling on Congress to pass legislation to regulate stablecoins in the wake of Silicon Valley Bank's failure that led their stablecoin to break the buck.</p>
          <p>In a conversation just moments ago, Disparte saying that he favors legislation from House Financial Services Committee Chair and Ranking Member Patrick McHenry and Maxine Waters. Disparte telling me, quote, "I have been uploading to the internet since 2019. And since 2019, there has been an ongoing persistent conversation about risk. And we've been met with complete inaction."</p>
          <p>"I do think if this is the digital assets Dodd-Frank moment, then it is the time to act. Just as it took 2008 to get comprehensive banking reforms, I think it's well past time that the US acts. And to act is not to ban innovation, it's to encourage responsible innovation. But there is a shortfall in action right now"</p>
          <p>Now, Disparte also told me it was a run on a bank that triggered a loss of confidence in a fully reserved digital dollar. He says, that's the irony. Whereas, with the Tarra LUNA blowup, he called that financial alchemy of the very worst kind. He says, the way Circle operates is more conservative than the Paypals of the world, and it was a loss in confidence in a bank that triggered a crisis and risk in Circle's business.</p>
          <p>Now, Circle's stablecoin broke the buck after it revealed that it had a $3.3 billion in reserves tied up in Silicon Valley Bank, causing investors to dump the stablecoin. Circle pledged from its balance sheet over the weekend to make any holder whole If the bank didn't go through an orderly wind down. And that helped to restore that deep peg on the chain.</p>
          <p>Circle holds 80% of its reserves in short term treasuries and 20% in cash. As a depositor of Silicon Valley Bank, Circle will be made whole by the FDIC. Now, Rachelle, Disparte told me that there is a serious policy conversation that needs to be had here. And that regulators' intervention over the weekend was the right move to stave off contagion. I will have more on our website on finance.yahoo.com. Rachelle.</p>
        </div>
      },
      {
        id: 139,
        title: 'Circle’s USDC stablecoin regains parity as regulators act to stop bank run risk',
        category: 'Crypto',
        subCategory: ['USDC', 'Stablecoin',],
        description: <div className="blog-desc">
          <p>Circle Internet Financial’s USDC, the second-largest stablecoin by market capitalization, lost and then regained parity to the U.S. dollar after regulators took over the failed Silicon Valley Bank and guaranteed deposits, including Circle’s, to prevent a broader run on the banking industry.</p>
        </div>,
        authorName: 'Timmy Shen',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 13, 2023 - 9:33 AM',
        cover: 'https://ncx.cx/images/ncx-blog-143.jpg',
        content: <div className="blog-content">
          <p>Circle Internet Financial’s USDC, the second-largest stablecoin by market capitalization, lost and then regained parity to the U.S. dollar after regulators took over the failed Silicon Valley Bank and guaranteed deposits, including Circle’s, to prevent a broader run on the banking industry.</p>
          <p>USDC was trading at US$0.9948 at 9:30 a.m. in Hong Kong on Monday, after falling to as low as US$0.8774 on Saturday, according to data from CoinMarketCap. Circle held about US$3.3 billion in USDC reserve deposits at Silicon Valley Bank, known as SVB.</p>
          <p>Cryptocurrencies also bounced back from losses last week, with Bitcoin jumping almost 10% in early trading in Asia, after the Federal Deposit Insurance Corporation (FDIC) – a government body set up after the 1930s Great Depression to provide deposit insurance at savings and commercial banks – said on Sunday that it has taken over SVB.</p>
          <p>The move to backstop SVB, which had total deposits of more than US$175 billion, went all the way up to the White House and President Joe Biden for approval at the weekend, according to a joint statement by the Treasury, the Federal Reserve and the FDIC, indicating the seriousness with which U.S. regulators took the threat of bank failures spreading.</p>
          <p>The fall of SVB followed the earlier collapse of another California-based and crypto-linked bank Silvergate Capital last week. The FDIC said Sunday it has also taken control of New York-based Signature Bank, one of the biggest lenders in the crypto industry, due to banking industry risk.</p>
          <p>“Depositors will have access to all of their money starting Monday, March 13. No losses associated with the resolution of Silicon Valley Bank will be borne by the taxpayer,” the joint official statement said.</p>
          <p>“We are also announcing a similar systemic risk exception for Signature Bank, New York, New York, which was closed today by its state chartering authority. All depositors of this institution will be made whole. As with the resolution of Silicon Valley Bank, no losses will be borne by the taxpayer.”</p>
          <p><strong>Circle</strong></p>
          <p>Jeremy Allaire, Co-founder and CEO of Circle, said in a statement dated March 12 in Boston, U.S.: “We are heartened to see the U.S. government and financial regulators take crucial steps to mitigate risks extending from the banking system.”</p>
          <p>Allaire said the US$3.3 billion in USDC reserve deposits held at Silicon Valley Bank “will be fully available when U.S. banks open tomorrow morning. No USDC cash reserves were held at Signature Bank. As a regulated payment token, USDC remains redeemable 1:1 with the U.S. Dollar.”</p>
          <p>FDIC data shows SVB had about US$209 billion in total assets at the end of last year, making it the biggest bank failure in the U.S. since the 2008 financial crisis that included the collapse of Lehman Brothers, a 158-year old investment bank that is the largest bankruptcy in U.S. history with more than US$600 billion in liabilities.</p>
          <p>The FDIC in its Sunday statement said all insured depositors at SVB will have full access to their insured deposits no later than Monday morning in the U.S. and that the FDIC will pay uninsured depositors an advance dividend within the next week.</p>
          <p>“As the FDIC sells the assets of Silicon Valley Bank, future dividend payments may be made to uninsured depositors,” the federal agency said.</p>
          <p><strong>Crypto link?</strong></p>
          <p>New York-based Signature Bank was closed down on Sunday by its state chartering authority, with the FDIC appointed as receiver. At the end of last year, Signature Bank held total assets of around US$110.36 billion with US$88.59 billion in deposits, according to the FDIC.</p>
          <p>Crypto exchange Coinbase tweeted on Monday morning in Asia that the firm had about US$240 million in corporate cash at Signature as of Friday, and it is currently facilitating all client cash transactions with other banking partners.</p>
          <p>The U.S. bank failures could be seen as contagion from the crypto industry, but “the collapses of Silvergate and SVB didn’t appear to have direct links with cryptocurrency,” Winston Hsiao, co-founder and chief revenue officer of Taipei-based fiat-crypto exchange XREX, wrote on Sunday in a Facebook post.</p>
          <p>“Crypto firms are actually victims just like many corporates and individuals that deposited at the two banks,” he said.</p>
          <p>US President Joe Biden on Twitter said he is “firmly committed” to holding those responsible for the Silicon Valley Bank and Signature Bank collapse “fully accountable.”</p>
        </div>
      },
      {
        id: 140,
        title: 'Bitcoin jumps more than 9% as U.S. acts to protect deposits at crypto-linked banks',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'crypto', 'US'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ether led a strong price rebound in the top 10 non-stablecoin cryptocurrencies in morning trading in Asia as U.S. banking regulators took control of Silicon Valley Bank and Signature Bank, both with ties to the crypto industry, and guaranteed deposits at the institutions as well as additional backstops for the banking industry. The moves followed the failure of Silvergate Capital last week that raised the threat of a systemic run on banks. Solana led the gains.</p>
        </div>,
        authorName: 'Tom Zuo',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 13, 2023 - 7:59 AM',
        cover: 'https://ncx.cx/images/ncx-blog-144.jpg',
        content: <div className="blog-content">
          <p>Bitcoin and Ether led a strong price rebound in the top 10 non-stablecoin cryptocurrencies in morning trading in Asia as U.S. banking regulators took control of Silicon Valley Bank and Signature Bank, both with ties to the crypto industry, and guaranteed deposits at the institutions as well as additional backstops for the banking industry. The moves followed the failure of Silvergate Capital last week that raised the threat of a systemic run on banks. Solana led the gains.</p>
          <h3><strong>Fast facts</strong></h3>
          <ul>
            <li>Bitcoin jumped 9.60% in the past 24 hours to US$22,601 at 09:00 a.m. in Hong Kong, according to CoinMarketCap data. Ether gained 9.77% to US$1,621.</li>
            <li>Solana surged 12.85% to US$20.38 to lead the gainers, but still has more ground to make up as it’s down 2.93% over the past seven days.</li>
            <li>USD Coin (USDC), the second largest stablecoin by market capitalization, traded back in line with its U.S. dollar peg in the Asia Monday morning after briefly losing the peg following the failure of Silicon Valley Bank where it holds about US$3 billion in deposits, according to Circle, the issuer of USDC.</li>
            <li>USDC fell to US$0.8774 on Saturday and its market cap slumped 15% to US$36 billion from US$43 billion. Circle said the same day the company had the funds to back USDC and it would remain redeemable 1 for 1 with the U.S. dollar. USDC recently traded at US$0.9941.</li>
            <li>Circle CEO Jeremy Allaire said on Monday that all Circle deposits will be available when banks open on Monday.</li>
            <li>The total crypto market capitalization rose 6.47% in the past 24 hours to US$1.01 trillion. Total trading volume over the last 24 hours was down 34.52% to US$60.19 billion.</li>
            <li>U.S. equities slid on Friday. The Dow Jones Industrial Average dropped 1.07%, the S&P 500 fell 1.45% and the Nasdaq Composite Index was down 1.76%.</li>
            <li>The slump in equities followed the collapse of Silicon Valley Bank, which was taken over by the FDIC on Friday, in the biggest U.S. bank failure since 2008. However, U.S. stock futures were trading higher on Monday morning in Asia, reflecting the moves to backstop the U.S. banking industry.</li>
            <li>Amid the banking scares, investors had to contend with the job report from the Labor Department on Friday that showed February nonfarm payrolls came in at  311,000, beating the projected 225,000. This furthers the narrative that the Federal Reserve may raise interest rates more than previously expected to curb inflation.</li>
            <li>However, with banking failures now the center of attention and concern, analysts at the CME Group predict a 17.4% chance of a 50 basis point hike this month, a sharp drop from 60.9% last Friday. This reflects a view the Fed is unlikely to raise rates by that much in the middle of a series of bank failures.</li>
            <li>CME expect a 82.6% chance the Fed will raise rates by the forecasted 25 basis points this month, but other commentators say the Fed may postpone any hike until next month because of the banking industry jitters.</li>
            <li>The Fed meets on March 22 to make its next decision on interest rates, which are currently between 4.5% to 4.75%, the highest since October 2007.</li>
            <li>The U.S. annual inflation rate is 6.4% for the year ended January 2023, according to Labor Department data released on Feb. 14, which is well above the Fed’s long-term goal to keep inflation in a 2% band. The next inflation update is scheduled for March 14 at 8:30 a.m. Eastern Standard Time.</li>
          </ul>
        </div>
      },
      {
        id: 128,
        title: 'Stablecoin Issuer Circle Reveals $3.3 Billion SVB Exposure',
        category: 'Crypto',
        subCategory: ['SVB', 'stablecoin',],
        description: <div className="blog-desc">
          <p>(Bloomberg) -- The second-largest stablecoin in crypto fell from its intended $1 peg on Saturday, trading as low as 81.5 cents, hurt by the exposure of issuer Circle Internet Financial Ltd. to the collapsed Silicon Valley Bank.</p>
        </div>,
        authorName: 'Suvashree Ghosh and Yueqi Yang',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 11, 2023 - 1:08 PM',
        cover: 'https://ncx.cx/images/ncx-blog-130.jpg',
        content: <div className="blog-content">
          <p>(Bloomberg) -- The second-largest stablecoin in crypto fell from its intended $1 peg on Saturday, trading as low as 81.5 cents, hurt by the exposure of issuer Circle Internet Financial Ltd. to the collapsed Silicon Valley Bank.</p>
          <p>USD Coin, or USDC, is a key plank of crypto markets and is supposed to hold a constant $1 value, fully backed by reserves of cash and short-dated Treasuries. But $3.3 billion of that roughly $40 billion stockpile is with Silicon Valley Bank, which has just become one of the largest US bank failures in recent history.</p>
          <p>Regulators seized the bank on Friday and investors are awaiting more clarity on the return of deposits. In that vacuum, USDC fell below $1, trading at about 92 cents as of 9:02 a.m. in London on Saturday. Smaller stablecoins such as DAI and Pax Dollar also fell from their pegs, a sign of wider nervousness.</p>
          <p>The worries haven’t so far spread to top stablecoin Tether, which held at $1. Tether has previously faced scrutiny over its reserves. Wider crypto markets are having a painful week and were on the back foot Saturday: Bitcoin oscillated between gains and losses, while smaller tokens like Solana and Avalanche were in the red.</p>
          <p>Circle’s Chief Strategy Officer Dante Disparte described the fall of Silicon Valley Bank as a “black swan failure” in the US financial system, saying in a tweet that without a federal rescue plan there would be “broader implications for business, banking and entrepreneurs.”</p>
          <p>USDC has a circulating supply of about 41 billion tokens and a market value of roughly $37 billion, CoinGecko data shows. A net $2 billion of USDC was redeemed in the past 24 hours, according to blockchain research firm Nansen. Data compiled by Bloomberg indicated USDC traded as low as 81.5 cents.</p>
          <p>Coinbase’s Step</p>
          <p>Stablecoins like USDC are intended to hold a set value against another, highly liquid asset like the US dollar. They come in a variety of forms and some, like Circle’s, are underpinned by reserves of cash and bonds. Investors often park funds in stablecoins as they move between crypto trades.</p>
          <p>As the selloff in USDC worsened, US-based crypto exchange Coinbase Global Inc. said it would be “temporarily pausing” the conversion of USDC into US dollars during the weekend, and would resume on Monday when banks open.</p>
          <p>The fall in USDC has had a knock-on effect on decentralized finance applications which let users trade, borrow and lend coins and which tend to rely heavily on trading pairs involving the stablecoin.</p>
          <p>“Unless there’s a concrete bailout plan this weekend, I think markets will be ugly again next week,” said Teong Hng, chief executive officer at crypto investment firm Satori Research, about the failure of SVB.</p>
          <p>Crypto’s Woes</p>
          <p>The crypto sector was already reeling from a prolonged rout that’s knocked $2 trillion off the value of digital assets since November 2021, precipitating a series of implosions such as the TerraUSD stablecoin, the Three Arrows Capital hedge fund and the FTX exchange.</p>
          <p>The TerraUSD token — known as UST — tried to use a mix of algorithms and trader incentives involving a sister token, Luna, to hold its value. The $60 billion wipeout of that system intensified global regulatory scrutiny of stablecoins.</p>
          <p>“I think the market ‘panic priced’ USDC like it priced USDT around the Luna collapse,” said Haohan Xu, chief executive officer of Apifiny, an institutional trading platform. “It’s driven by Circle’s exposure at SVB plus Coinbase closing off its USDC convert function.”</p>
          <p>Trying to Reassure</p>
          <p>Crypto firms including Binance and Tether on Friday used Twitter to try to reassure their customers about any risks posed by the failed bank.</p>
          <p>Changpeng Zhao, chief executive officer at Binance, the largest digital-asset exchange, tweeted that the firm doesn’t have exposure and its funds are safe.</p>
          <p>Paxos Trust Co., issuer of Pax Dollar, and crypto exchange Gemini said they have no relationship with the bank, according to statements on their official Twitter accounts. Tether’s Chief Technology Officer Paolo Ardoino said in a tweet that the largest stablecoin doesn’t have exposure to SVB.</p>
          <p>By contrast, bankrupt crypto lender BlockFi has about $227 million in an account at the failed bank, according to a court filing.</p>
          <p>--With assistance from David Pan.</p>
          <p>(Updates market prices in the fourth paragraph. An earlier version of this story corrected the TerraUSD acronym in the 12th paragraph to UST.)</p>
        </div>
      },
      {
        id: 129,
        title: 'Circle assures market after stablecoin USDC breaks dollar peg',
        category: 'Crypto',
        subCategory: ['Market', 'USDC', 'Stablecoin'],
        description: <div className="blog-desc">
          <p>LONDON (Reuters) -Stablecoin USD Coin (USDC) lost its dollar peg and slumped to an all-time low on Saturday before recovering most of its losses after Circle, the firm behind it, assured investors it would honor the peg despite exposure to failed Silicon Valley Bank.</p>
        </div>,
        authorName: 'Elizabeth Howcroft and RISHABH JAISWAL',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 11, 2023 - 11:25 AM',
        cover: 'https://ncx.cx/images/ncx-blog-131.jpg',
        content: <div className="blog-content">
          <p>By Elizabeth Howcroft and RISHABH JAISWAL</p>
          <p>LONDON (Reuters) -Stablecoin USD Coin (USDC) lost its dollar peg and slumped to an all-time low on Saturday before recovering most of its losses after Circle, the firm behind it, assured investors it would honor the peg despite exposure to failed Silicon Valley Bank.</p>
          <p>Circle said in a tweet on Friday it has $3.3 billion of its $40 billion of USDC reserves at Silicon Valley Bank. On Saturday, the cryptocurrency company said in a blog post that USDC liquidity operations will resume as normal when banks open on Monday morning in the United States.</p>
          <p>"As a regulated payment token, USDC will remain redeemable 1 for 1 with the U.S. Dollar", the company said in a blog post.</p>
          <p>The cryptocurrency firm said that in the event the bank does not return 100% of deposits, it will cover any shortfall using corporate resources, involving external capital if necessary.</p>
          <p>The coin, which broke its 1:1 dollar peg and fell as low as $0.88 shortly after 0800 GMT (3 a.m. EST) on Saturday, according to market tracker CoinGecko, recovered to trade around $0.97 by 2100 GMT.</p>
          <p>Silicon Valley Bank collapsed on Friday in the largest U.S. bank failure since the 2008 financial crisis, roiling global markets and stranding billions of dollars belonging to companies and investors.</p>
          <p>Circle said in a tweet on Friday that the company and USDC "continue to operate normally" while the firm waits for clarity on what will happen to Silicon Valley Bank depositors.</p>
          <p>Meanwhile, U.S. crypto exchange Coinbase said in a tweet it was not allowing USDC to be exchanged for U.S. dollars over the weekend while banks are closed, citing "heightened activity", while it plans to resume swaps on Monday.</p>
          <p>Joseph Edwards, investment advisor at Enigma Securities, said the situation was "extremely serious" for USDC.</p>
          <p>"No matter how sound Circle's operations are, this sort of depeg on a stablecoin tends to fundamentally undermine confidence in it," Edwards said.</p>
          <p>"The short-term implications here are dramatic and unknowable, especially once systems start to have to be adjusted to the reality that 1 USDC isn't trading at 1 USD for the time being."</p>
          <p>CONSTANT EXCHANGE RATE</p>
          <p>Stablecoins are cryptocurrencies designed to maintain a constant exchange rate with "fiat" currencies - those backed by a central government rather than a physical commodity such as gold - for example through a 1:1 U.S. dollar peg.</p>
          <p>Used in cryptocurrency trading, they have surged in value in recent years. USDC is the second-biggest stablecoin with a market cap of $37 billion. The largest, Tether, has a market cap of $72 billion, according to CoinGecko.</p>
          <p>USDC's price usually holds close to $1, making Saturday's drop unprecedented. According to CoinGecko data, its previous all-time low was around $0.97 in 2018, though in 2022 it fell just below $0.99 when cryptocurrency markets were roiled by the collapse of crypto hedge fund Three Arrows Capital.</p>
          <p>Traders have been on guard this week for signs of contagion in the financial sector and beyond from troubles for Silicon Valley Bank and crypto-focused Silvergate, which this week disclosed plans to wind down operations and voluntarily liquidate.</p>
          <p>Boston-based Circle said last week it had moved a "small percentage" of USDC reserve deposits held at Silvergate to its other banking partners.</p>
          <p>The chief executive of cryptocurrency exchange Binance said in a tweet on Friday it had no exposure to Silicon Valley Bank, as did Tether Chief Technology Officer Paolo Ardoino.</p>
          <p>Stablecoin issuer Paxos and crypto exchange Gemini also tweeted that they do not have relationships with the bank.</p>
          <p>(Reporting by Elizabeth Howcroft in London and Rishabh Jaiswal in Bengaluru; Editing by William Mallard, David Holmes and Paul Simao)</p>
        </div>
      },
      {
        id: 130,
        title: 'Coinbase Pauses Conversions Between USDC and U.S. Dollars as Banking Crisis Roils Crypto',
        category: 'Crypto',
        subCategory: ['USDC', 'US', 'Coinbase'],
        description: <div className="blog-desc">
          <p>Coinbase (COIN) hit the brakes on conversions between USDC stablecoin and US dollars late Friday as fallout from Silicon Valley Bank's (SIVB) collapse spread into the heart of crypto trading.</p>
        </div>,
        authorName: 'Danny Nelson',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 11, 2023 - 8:14 AM',
        cover: 'https://ncx.cx/images/ncx-blog-132.jpg',
        content: <div className="blog-content">
          <p>Coinbase (COIN) hit the brakes on conversions between USDC stablecoin and US dollars late Friday as fallout from Silicon Valley Bank's (SIVB) collapse spread into the heart of crypto trading.</p>
          <p>In a tweet, the crypto exchange said it was "temporarily pausing" the conversions while banks are closed over the weekend. The exchange said it planned to restart conversions on Monday.</p>
          <p>The pause speaks to the chaos that's roiled the crypto industry's second-largest stablecoin in the wake of Silicon Valley Bank's demise on Friday. Speculating on the stability of USDC, traders redeemed $1.6 billion of USDC, lowering its total supply.</p>
          <p>Late in the day, Circle confirmed that $3.3B of the $40B backing its stablecoin was on deposit at the now-shuttered lender. The fate of that cash is now uncertain, with Silicon Valley Bank having been seized by the FDIC, and USDC – for the moment – has lost its dollar peg.</p>
          <p>"Circle is currently protecting USDC from a black swan failure in the U.S. banking system," tweeted Circle Chief Strategy Officer Dante Disparte late Friday night. "Silicon Valley Bank is a critical bank in the U.S. economy and its failure – without a Federal rescue plan – will have broader implications for business, banking and entrepreneurs."</p>
          <p>"During periods of heightened activity, conversions rely on USD transfers from the banks that clear during normal banking hours," Coinbase said in its tweet announcing the conversion pause. "When banks open on Monday, we plan to re-commence conversions."</p>
        </div>
      },
      {
        id: 131,
        title: 'Weekly Market Wrap: Bitcoin falls below US$20,000 as Silvergate collapse, rate hike concerns rise',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'silvergate', 'market'],
        description: <div className="blog-desc">
          <p>Bitcoin, the world’s largest cryptocurrency by market capitalization, fell 10.98% in the week from March 3 to March 10, to trade at US$19,941 at 9:00 p.m. on Friday in Hong Kong. Ether slid 10.88% in the same period to US$1,397.</p>
        </div>,
        authorName: 'Zoltan Vardai',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 10, 2023 - 9:10 PM',
        cover: 'https://ncx.cx/images/ncx-blog-135.jpg',
        content: <div className="blog-content">
          <p>Bitcoin,the world’s largest cryptocurrency by market capitalization, fell 10.98% in the week from March 3 to March 10, to trade at US$19,941 at 9:00 p.m. on Friday in Hong Kong. Ether slid 10.88% in the same period to US$1,397.</p>
          <p>The crypto market saw a turbulent week after New York State Attorney General Letitia James said Ether should be registered as a security in her lawsuit against cryptocurrency exchange Kucoin filed on Thursday, a day after crypto-friendly financial institution Silvergate Capital said it would cease operations and liquidate its banking unit.</p>
          <p>Investors digested hawkish comments from Federal Reserve Chair Jerome Powell, who said that the U.S. central bank may continue to raise interest rates this year to combat inflation.</p>
          <p>“Bitcoin is still highly dependent on the monetary policy of the U.S. Federal Reserve, and any words hinting an increase or decrease of the key rate can have an effect on its price,” wrote Roman Nekrasov, co-founder of blockchain software firm Encry, in a LinkedIn response to Forkast.</p>
          <p>“The depreciation of Bitcoin should not be unexpected since inflation in the U.S. and the rest of the world has not gone away. The growth of Bitcoin in January and February was expected to stop and turn into a correction. Therefore, Bitcoin was expected to move into a downtrend,” wrote Nekrasov.</p>
          <p>The Fed will announce its rate decision at the Federal Open Market Committee’s March 21-22 meeting.</p>
          <p>Alexander Politayko, the founder of MarsDAO, a decentralized autonomous organization developing deflationary products, said that he believes Bitcoin’s main support level is US$18,000.</p>
          <p>“If Bitcoin does not hold on to this level, then the market may witness another wave of panic sales, and Bitcoin could return to the levels of last fall at US$16,000,” wrote Politayko in a LinkedIn response to Forkast.</p>
          <p>Meanwhile, data from investment platform MacroMicro suggests that more Bitcoin owners are deciding against selling their assets despite turbulent market conditions. The percentage of Bitcoin supply that has been dormant for at least a year rose to an all-time high of 67.84% last weekend.</p>
          <p>“There is a growing number of investors who view Bitcoin not just as a speculative asset, but also as a store of value or tool for savings. This is significant because it indicates a shift in the perception of Bitcoin from a purely speculative asset to one that has a practical use case,” wrote Politayko.</p>
          <p>“As more people begin to use Bitcoin for its utilitarian value, the demand for the asset as a means to store value is likely to increase. This, in turn, can affect the price of Bitcoin in a positive way,” he added.</p>
          <p>The global crypto market capitalization stood at US$923 billion on Friday at 9:00 p.m. in Hong Kong, falling 10.3% from US$1.03 trillion a week ago, according to CoinMarketCap data. Bitcoin’s US$385 billion market cap dominated 41.6% of the market, while Ether’s US$170 billion accounted for 18.5%.</p>
          <p><strong>Kava tops winners</strong></p>
          <p>Kava, the governance token of a layer-1 blockchain of the same name, was this week’s biggest gainer among the top 100 coins by market capitalization listed on CoinMarketCap. Kava rose 7.18% to US$0.86, and surpassed Solana in the total value of assets locked in the network this week, according to DeFiLlama data.</p>
          <p>STX, the native token of Bitcoin smart contract layer Stacks, was the week’s worst performer among CoinMarketCap’s top 100, as it fell 33.54% to US$0.54. MINA, the token of privacy-focused blockchain Mina Protocol, followed closely as it fell 30.89% to US$0.61.</p>
          <p><strong>Next week?</strong></p>
          <p>Investors will be anticipating the release of the U.S. Consumer Price Index data, a key indicator for inflation.</p>
          <p>In addition, the European Union will likely announce a 50 basis point rate hike next week, as predicted by 60 economists polled by Reuters. European Central Bank President Christine Lagarde said the rate hike is “very, very likely.” The central bank’s interest rate is expected to rise to 3.00% as a result.</p>
          <p>The banking industry is bracing for ongoing challenges as the drop of Silicon Valley Bank’s shares, plunging over 60% on Thursday, prompted a domino effect, causing the market value of the four biggest U.S. banks to plummet by more than US$50 billion, raising widespread anxieties in equity markets.</p>
        </div>
      },
      {
        id: 104,
        title: 'Bitcoin, Ether fall amid U.S. Fed chair warning of more interest rate hikes; Forkast NFT 500 index slips 0.65%',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Crypto', 'NFTs'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ether fell in afternoon trading in Asia on Wednesday, with XRP the sole gainer among the top 10 non-stablecoin cryptocurrencies by market capitalization.</p>
        </div>,
        authorName: 'Dylan Butts',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 8, 2023 - 1:51 PM',
        cover: 'https://ncx.cx/images/ncx-blog-105.webp',
        content: <div className="blog-content">
          <p>Bitcoin and Ether fell in afternoon trading in Asia on Wednesday, with XRP the sole gainer among the top 10 non-stablecoin cryptocurrencies by market capitalization. The new Forkast NFT 500 index slipped, and Asian equity markets were mixed following declines in the U.S. as investors reacted to Tuesday’s comments from the U.S. Federal Reserve Chair Jerome Powell.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin fell 0.76% to US$22,028 in 24 hours as of 4:30 p.m. in Hong Kong on Tuesday, according to CoinMarketCap data. The biggest cryptocurrency by market capitalization is down over 7% on the week. Ether fell 1% to US$1,554, after losing about 6% in the past seven days.</li>
            <li>XRP was the day’s biggest gainer, extending gains from Tuesday. The token increased 2.42% to US$0.38 but declined 1.25% on the week.</li>
            <li>Altcoin Bone ShibaSwap was the top gainer of all cryptocurrencies, rising over 10% over 24 hours to trade hands at US$1.69.</li>
            <li>The global cryptocurrency market capitalization fell 1.82% to US$1.01 trillion, but the total crypto market volume increased 41.44% to US$46.71 billion in the last 24 hours.</li>
            <li>Asian stocks were mostly flat despite losses in the U.S. after hawkish comments from Federal Reserve Chair Jerome Powell raised the possibility of the U.S. central bank returning to significant rate hikes to settle inflation. Hong Kong’s Hang Seng saw the biggest loss of 2.35% while Japan’s Nikkei 225 gained 0.48%.</li>
            <li>Investors remained cautious after Powell’s hawkish remarks. European stocks were trading flat, as Germany’s DAX 40 inched down 0.02%, while the STOXX 600 slid 0.17%.</li>
            <li>The Forkast NFT 500 index, which went live on Tuesday, dropped 0.65% to 4,275.91 over the last 24 hours as per 4:30 p.m. on Tuesday in Hong Kong. The Forkast NFT 500 Index is a proxy measure of the performance of the global NFT market, and includes 500 eligible smart contracts on any given day.</li>
          </ul>
        </div>
      },
      {
        id: 105,
        title: 'Grayscale’s bitcoin trust shares jump 9% after ETF hearing',
        category: 'Crypto',
        subCategory: ['Grayscale', 'ETF', 'bitcoin'],
        description: <div className="blog-desc">
          <p>Grayscale Bitcoin Trust, or GBTC, saw its share price rally almost 9% Tuesday, after a panel of judges appeared to be skeptical about the U.S. Securities and Exchange Commission’s arguments during an appeals court hearing, as Grayscale continues its push to convert GBTC into an exchange-traded fund. </p>
        </div>,
        authorName: 'Frances Yue',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 7, 2023 - 1:55 PM',
        cover: 'https://ncx.cx/images/ncx-blog-106.jpg',
        content: <div className="blog-content">
          <p>Grayscale Bitcoin Trust, or GBTC, saw its share price rally almost 9% Tuesday, after a panel of judges appeared to be skeptical about the U.S. Securities and Exchange Commission’s arguments during an appeals court hearing, as Grayscale continues its push to convert GBTC into an exchange-traded fund. </p>
          <p>Judges at the District of Columbia Circuit Court of Appeals in Washington, D.C., asked SEC senior counsel Emily Parise details about the connections between bitcoin BTCUSD spot and futures and markets, and questioned the agency’s...</p>
          <p>In June, Grayscale filed a lawsuit against the SEC over the agency’s denial of an application to convert GBTC GBTC, +2.64% into an ETF. The SEC has greenlighted several bitcoin futures-backed ETFs, but has yet to approve any ETFs backed by the cryptocurrency itself, citing concerns about market manipulation. </p>
          <p>The lawsuit filed by Grayscale is under the spotlight as it may shed light on whether and when a spot bitcoin ETF might be available in the U.S. Meanwhile, a successful conversion of GBTC into an ETF would help mitigate the discount GBTC has been trading at to its net asset value, or the value of bitcoins it holds. </p>
          <p>Launched in 2013, GBTC traded at a premium to its net asset value for several years, as it was for a long time the only public-listed fund that holds bitcoin as its primary assets. The premium flipped to a discount in February 2021, partly due to the launch of the first spot bitcoin ETF in Canada. However, investors can only sell, but not redeem their shares in the fund, in part as GBTC was structured similar to a close-end fund.</p>
          <p>To close the discount, Grayscale had lobbied the SEC and finally filed a lawsuit against the agency to push for a conversion from GBTC into an ETF. For an ETF, institutional traders can keep creating and redeeming shares, thus closing the gap between the fund’s price and the value of its underlying assets through arbitrages.</p>
          <p>“We look forward to the Court’s final decision, and strongly believe that American investors deserve to have equal access to the Bitcoin ETF product that best suits their needs,” a Grayscale spokeswoman wrote to MarketWatch in an email. </p>
          <p>China Yangtze Power Co., Ltd. (600900.SS) owns the Three Gorges hydropower electricity generation unit, which has the highest power generation capacity of 22,500 MW. Overall, the company has a power generation capacity of 71,795 MW and has more than 110 other hydroelectric power units under its portfolio. Not only is China Yangtze Power Co., Ltd. (600900.SS) the biggest supplier of electricity in China, but it is also the biggest publicly listed hydroelectric power generation and transmission company in the world.</p>
        </div>
      },
      {
        id: 106,
        title: 'Tethers USDT Stablecoin Market Share Rises to Highest Level in 15 Months',
        category: 'Crypto',
        subCategory: ['USDT', 'Stablecoin', 'market'],
        description: <div className="blog-desc">
          <p>The dominance of Tethers USDT is rising among stablecoins amid an ongoing shakeup of the $136 billion stablecoin market.</p>
        </div>,
        authorName: 'Krisztian Sandor',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 7, 2023 - 12:41 AM',
        cover: 'https://ncx.cx/images/ncx-blog-107.webp',
        content: <div className="blog-content">
          <p>The dominance of Tethers USDT is rising among stablecoins amid an ongoing shakeup of the $136 billion stablecoin market.</p>
          <p>USDTs market share among stablecoins surpassed 54% on Monday, data from CoinGecko shows. This is the largest market share Tethers stablecoin has reached since late November 2021, after the crypto bull market reached its apex.</p>
          <p>Tethers gain came mostly at the expense of rival Binance USD, which has been in rapid decline since its issuer, Paxos, announced on Feb. 13 that it would halt minting new BUSD tokens due to pressure from the New York Department of Financial Services, the states top regulator. Since then, BUSD has shrunk below $9 billion from a $16 billion market capitalization.</p>
          <p>USDTs market capitalization has grown some $5.3 billion this year so far to $71.6 billion, with $3 billion of the gains after mid-February. Circles rival stablecoin, USD coin (USDC), has also gained $3 billion since the Paxos announcement; however, its $44 billion market cap is still lower than at the start of 2023.</p>
          <p>Stablecoins have become the backbone of the crypto economy in recent years, ballooning to a peak market capitalization of$188 billion in May 2022. They peg their price stable to an external asset, such as the U.S. dollar, and serve as a facilitator for trading on exchanges and making transactions between central bank-issued fiat money and the digital asset world.</p>
          <p>Tethers position as issuer of the worlds dominant stablecoin defies its historically opaque reporting about its reserves backing the value of USDT and heavy scrutiny of its internal dealings. Last week, the Wall Street Journal reported that Tether used bank accounts accessed by falsified documents in 2018. In September, a New York judge ordered Tether to present financial records on USDTs reserve assets in a lawsuit that alleges Tether conspired to issue USDT to prop up the price of bitcoin (BTC), CoinDesk reported.</p>
          <p>USDT is the most traded cryptocurrency with some $27 billion of trading volume in the past 24 hours, according to CoinGecko, larger than BTC.</p>
        </div>
      },
      {
        id: 107,
        title: 'Binance reveals plans to elude U.S. authorities amid fear of prosecution: Report',
        category: 'Crypto',
        subCategory: ['binance'],
        description: <div className="blog-desc">
          <p>Yahoo Finance’s David Hollerith joins the Live show to discuss the details around Binance’s reported plans to elude U.S. authorities and the expectations for Silvergate Capital.</p>
        </div>,
        authorName: 'Yahoo Finance',
        authorurl: 'https://www.yahoo.com/author/yahoo--finance',
        authorAvatar: 'https://ncx.cx/images/yahoo-finance.webp',
        authorContent: '',
        createdAt: 'Mar 6, 2023 - 8:06 PM',
        cover: 'https://ncx.cx/images/ncx-blog-108.jpg',
        content: <div className="blog-content">
          <p>Yahoo Finance’s David Hollerith joins the Live show to discuss the details around Binance’s reported plans to elude U.S. authorities and the expectations for Silvergate Capital.</p>
          <h3>Video Transcript</h3>
          <p>Crypto exchange giant Binance in focus this morning following a report from the Wall Street Journal that, amid the fear of prosecution, Binance had set out on a plan to neutralize US authorities. Yahoo Finance's David Hollerith has more on this story. He joins us now. David, what are some of the details here?</p>
          <p>DAVID HOLLERITH: Yeah, so Jared, just to take a step back, so Binance International is the world's largest crypto exchange. And Binance US is much smaller US regulated crypto exchange. There's been a long running discussion about the relationship between these two firms, and that's become notably a bigger deal as critics have pointed out. And since FTX has collapsed, there are a lot of similarities in terms of structure between the two firms.</p>
          <p>Things reached a new boiling point on Friday, actually, in bankruptcy court. Voyager Digital is trying to sell its assets to Binance US, and notably, the SEC, the FTC, and several state securities regulators have actively been objecting. And sort of the bankruptcy court is trying to go through all this. So Binance US licenses the Binance brand and technologies, but is technically a separate legal entity, though, it's nonetheless majority owned by Binance's founder and CEO CZ.</p>
          <p>As far back as 2020, there's actually been reports about sort of a connection to this, and even documents that have shown that Binance, at least, at one point in time, was thinking about trying to find a way to reach US investors without having to deal with US regulators. So the Wall Street Journal story from the weekend drew from chat logs from Binance employees, and this provided further evidence that Binance for some time has been potentially trying to find ways to use Binance US to reach US investors without having to deal with regulators. Reuters has also reported about this extensively, and they've also drawn-- as was in the Journal story, they've drawn a connection to Merit Peak, which was once a market maker for Binance US and is also owned by CZ, Binance's CEO.</p>
          <p>But notably from this story, I think, what was pointed out was the fact that the SEC Chair Gary Gensler was actually being recruited by Binance before he was chair of the agency when he was an MIT professor, and that's notable. If we are to believe the chat logs from Binance employees, it indicated that he was generous in offering licensing strategies to the companies. Obviously, this licensing agreement situation is the legal connection between the two firms. It's also notable as, on Friday, CZ, the founder, actually did a Twitter Space's AMA, and he sort of brought some of this to the public's attention, too, saying that he had met and previously known Gary Gensler, and that they had a good rapport. And he lectured for his MIT course. So this all just makes the connection between what's going on right now with Binance US and the SEC that much more interesting.</p>
          <p>It does, and speaking of connections, I mean, as we talk about the interconnectedness of this crypto world, as we know, one of the potential casualties that you've been watching is Silvergate. What can we expect to happen next with that sort of crypto focused bank?</p>
          <p>DAVID HOLLERITH: Yeah, Julie, you know, so for Q4, Silvergate fell into a situation, which is they were less than well capitalized or sort of on the brink of being less than well capitalized. There's a leverage ratio that is watched closely by banking regulators, and Silvergate was about 5.3%. Anything below 5% is a concern for regulators, and since then, Silvergate's come out saying they're going to be late on their annual report. Losses look worse, and obviously, last week, we saw the stock collapse by half between Thursday and Friday.</p>
          <p>So in this scenario, Silvergate is a bank. It's a state chartered bank, so it could go into something, which is receivership, which for banks, it's kind of like bankruptcy, not quite the same thing. And this would be decided by either the California State regulator or the FDIC, and in either situation, it would likely be the FDIC that would sort of take the mantle there.</p>
          <p>So we'll be watching that, but again, that's a worst case scenario. And Silvergate has fallen. It's lost so many deposits in the last couple of days. We are definitely expecting something like this to happen.</p>
          <p>Yeah, most definitely, and we'll count on you to keep watching it. Thanks so much, David. Appreciate it.</p>
        </div>
      },
      {
        id: 108,
        title: 'Bitcoin gains, all other top 10 cryptos in the red amid Silvergate fallout',
        category: 'Crypto',
        subCategory: ['bitcoin', 'silvergate', 'cryptos'],
        description: <div className="blog-desc">
          <p>Bitcoin gained in Monday afternoon trade in Asia while all other top 10 non-stablecoin cryptocurrencies by market capitalization posted losses. Investors remained concerned over California-based cryptocurrency bank Silvergate Capital Corp.’s financial woes and a potential fallout, while many crypto companies distanced themselves from the bank.</p>
        </div>,
        authorName: 'Pradipta Mukherjee',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 6, 2023 - 2:17 PM',
        cover: 'https://ncx.cx/images/ncx-blog-109.jpg',
        content: <div className="blog-content">
          <p>Bitcoin gained in Monday afternoon trade in Asia while all other top 10 non-stablecoin cryptocurrencies by market capitalization posted losses. Investors remained concerned over California-based cryptocurrency bank Silvergate Capital Corp.’s financial woes and a potential fallout, while many crypto companies distanced themselves from the bank.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin, the largest cryptocurrency by market capitalization, rose 0.03% to US$22,401 at 4 p.m. in Hong Kong on Monday, after losing 4.42% in the past seven days. Ether dropped 0.46% to US$1,561 and has lost 4.54% on the week, according to CoinMarketCap data.</li>
            <li>XRP was the biggest loser among top 10 cryptos, dropping 2.71% to US$0.3633, with weekly losses of 3.2%. San Francisco-based Ripple, the crypto payment network powered by XRP, said last week that a recent U.S. Supreme Court decision was in support of its ongoing tussle with the U.S. Securities and Exchange Commission.</li>
            <li>Solana’s native token fell 2.48% in the last 24 hours to US$20.78. Sol is down 9.35% on the weekly chart, after facing an 18-hour network outage on Feb. 25.</li>
            <li>The global crypto market cap dropped 0.39% to US$1.02 trillion, while the total crypto market volume slipped 1.68% in the last 24 hours to US$29.52 billion.</li>
            <li>Asian equity markets were mixed on Monday after China announced a modest economic growth target of 5% in 2023 – one of its lowest targets in decades. Investors are turning to Chinese trade and inflation data due for release this week, economic data from the U.S. and directions from the Federal Reserve this week on interest rates.</li>
            <li>The Shanghai Composite lost 0.19% on Monday while the Shenzhen Component Index slipped 0.08%. Hong Kong’s Hang Seng Index ended the day 0.17% higher, while Japan’s Nikkei 225 gained 1.11%.</li>
            <li>European stocks rose on Monday, after having the best weekly performance since the start of 2023. The STOXX 600 rose 0.26% and Germany’s DAX 40 gained 0.58%, both reaching their highest level since February 2022.</li>
            <li>The EU50, the benchmark index for the Euro area, also gained 13.77% since the beginning of the year.</li>
            <li>Eurozone retail trade data will be released later today. European Central Bank Chief Economist Philip Lane will also speak about the bank’s monetary policy tightening.</li>
          </ul>
        </div>
      },
      {
        id: 65,
        title: 'Dow jumps 387 points as US stocks snap weekly losing streak after rate fears ease',
        slug: 'Dow jumps 387 points as US stocks snap weekly losing streak after rate fears ease',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'US Stocks', 'Morning Star'],
        description: <div className="blog-desc">
          <p>Traders work on the floor of the New York Stock Exchange (NYSE) in New York, U.S., March 20, 2020.Lucas Jackson/Reuters</p>
        </div>,
        authorName: 'Jennifer Sor',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 4, 2023 1:16 AM',
        cover: 'https://ncx.cx/images/ncx-blog-65.webp',
        content: <div className="blog-content">
          <p>Traders work on the floor of the New York Stock Exchange (NYSE) in New York, U.S., March 20, 2020.Lucas Jackson/Reuters</p>
          <ul>
            <li>US stocks climbed higher on Friday, snapping a weekly losing streak.</li>
            <li>Fears of a move back to bigger rate hikes were eased as traders digested comments from Fed officials.</li>
            <li>Atlantic Fed President Raphael Bostic signaled support for another small hike of 25 basis-points.</li>
          </ul>
          <p>US stocks climbed higher on Friday, snapping a weekly losing streak as investors dialed back fears of aggressive rate hikes.</p>
          <p>All three indices ended the day higher, with the Dow gaining almost 400 points to break a four-week streak of losses.</p>
          <p>Investors digested comments from Federal Reserve officials on the central bank's next policy move. Though Fed Governor Christopher J. Waller said interest rates could tread higher than markets were currently expecting, Atlanta Fed President Raphael Bostic signaled for just a 25 basis-point hike in March, spurring a rally in stocks during the session on Thursday.</p>
          <p>The 10-year Treasury yield retreated from the key 4% level, falling 11 basis points on Friday to 3.96%.</p>
          <p>However, ISM manufacturing data ticked higher, from 47.4 to 47.7 last month, a sign that manufacturing activity is still expanding despite the Fed's aggressive monetary policy.</p>
          <p>"The solid ISM survey and resilience to high interest rates will reinforce the Fed's resolve that interest rates should continue to move higher. Even so, the Fed is unlikely to go back to rate hikes of more than a quarter percent per decision," Comercia Bank chief economist Bill Adams said in a statement. "The Fed wants to tread lightly to minimize the risk that they overshoot and cause an unnecessarily severe downturn."</p>
          <p><strong>Here's where US indexes stood at the 4:00 p.m. closing bell on Friday: </strong></p>
          <ul>
            <li><strong>S&P 500: 4,045.64, up 1.61% </strong></li>
            <li><strong>Dow Jones Industrial Average: 33,390.97, up 1.17% (387.40 points)</strong></li>
            <li><strong>Nasdaq Composite: 11,689.01, up 1.97% </strong></li>
          </ul>
          <p><strong>Here's what else is going on: </strong></p>
          <ul>
            <li>There are six reasons why the stock market could see its strongest rally of 2023 over the next two months, according to Fundstrat.</li>
            <li>C3.ai soared 33% after the AI software company reported a big quarterly earning beat.</li>
            <li>Bank of America said investors should capitalize on the $900 billion AI boom by picking these 20 stocks.</li>
            <li>Apple could soar to a near-$4 trillion valuation thanks to five under-the-radar drivers, Morgan Stanley said.</li>
            <li>Russia's oil and gas revenue tanked in February as western sanctions ate into its export earnings.</li>
            <li>Bitcoin has tumbled to its lowest price in weeks as the crypto market roils from Silvergate's troubles.</li>
          </ul>
          <p><strong>In commodities, bonds, and crypto: </strong></p>
          <ul>
            <li>Oil prices rose, with West Texas Intermediate up 2% to $79.73 a barrel. Brent crude, the international benchmark, rose 1.3% to $85.86 a barrel.</li>
            <li>Gold edged higher by about 1% to $1,860 per ounce.</li>
            <li>The 10-year Treasury yield ticked lower 11 basis points to 3.96%</li>
            <li>Bitcoinfell 4.71% to $22,373.12</li>
          </ul>
    
        </div>
      },
      {
        id: 109,
        title: 'Bitcoin falls to its lowest price in weeks as crypto markets tumble amid Silvergate troubles',
        category: 'Crypto',
        subCategory: ['markets', 'crypto', 'bitcoin'],
        description: <div className="blog-desc">
          <p>Cryptocurrency markets are tumbling as the industry's once go-to bank faces a slew of financial woes, potentially threatening global crypto liquidity.</p>
        </div>,
        authorName: 'Morgan Chittum',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 3, 2023 - 7:28 PM',
        cover: 'https://ncx.cx/images/ncx-blog-110.jpg',
        content: <div className="blog-content">
          <ul>
            <li>The price of bitcoin hit a two-week low as the industry's crucial banking partner faces financial troubles.</li>
            <li>Silvergate has experienced a steep downtrend in deposits, along with a 95% plunge in share price over the past year.</li>
            <li>Bitcoin saw its largest one-hour drop since the blowup of FTX in November.</li>
          </ul>
          <p>Cryptocurrency markets are tumbling as the industry's once go-to bank faces a slew of financial woes, potentially threatening global crypto liquidity.</p>
          <p>The price of bitcoin hit a two-week low early Friday and experienced its largest one-hour price drop since the blowup of bankrupt exchange FTX in November. The token is trading 4% lower at $$22,394, per Messari.</p>
          <p>Other major tokens followed suit. Ethereum shed 3%, falling to $1,573.</p>
          <p>The market moves come after several companies like Coinbase, Circle, Galaxy Digital, and Paxos cut ties with Silvergate, which is delaying its annual report due to greater losses, flagging doubts about the future of its business.</p>
          <p>Many large digital asset exchanges work with Silvergate for transactions between entities, and a dent in those relationships could impact industry liquidity.</p>
          <p>Silvergate was entangled in a number of ailing firms such as FTX, resulting in a massive decline in deposits at the bank amid broader crypto market contagion. Shares of Silvergate have dropped 95% in the past year.</p>
          <p>"Fears are growing that a crypto-focused bank failure could constitute a new hit to investors' confidence after the bankruptcies of major crypto players during the last few months," Wael Makarem, senior market strategist at investment broker Exness, told Insider. "As a result, the market could be exposed to new price corrections while investors could be concerned about a potential impact on other market players."</p>
          <p>Makarem added: "This incident could strongly dent the market's recent rebound as cryptocurrencies remain in a delicate position. Additionally, the dwindling number of companies operating in crypto could add to the concerns."</p>
          <p>Crypto's total market capitalization sits above $1 trillion, per Messari, roughly two-thirds below its record high in November of 2021. Investor sentiment in digital assets took a beating in the past year on a series of crypto company bankruptcies, along with a rise in borrowing costs from a hawkish Federal Reserve.</p>
          <p>Sentiment may have deteriorated further in cryptocurrency markets as traders brace themselves for the Fed's next move to tamp down inflation, which remains above the central bank's target.</p>
        </div>
      },
      {
        id: 64,
        title: 'Bitcoin fell nearly 5% Thursday night, its largest one-hour drop since FTX',
        slug: 'Bitcoin fell nearly 5% Thursday night, its largest one-hour drop since FTX',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'FTX', 'Morning Star'],
        description: <div className="blog-desc">
          <p>Bitcoin plunged nearly 5% over Thursday night following a Wednesday late notice filed by major industry bank Silvergate Capital that expressed doubts about the future of its business.</p>
        </div>,
        authorName: 'David Hollerith · Senior Reporter',
        authorurl: 'https://www.yahoo.com/author/david-hollerith',
        authorAvatar: 'https://ncx.cx/images/david-hollerith.webp',
        authorContent: '',
        createdAt: 'Mar 3, 2023 6:09 PM',
        cover: 'https://ncx.cx/images/ncx-blog-64.webp',
        content: <div className="blog-content">
          <p>Bitcoin plunged nearly 5% over Thursday night following a Wednesday late notice filed by major industry bank Silvergate Capital that expressed doubts about the future of its business.</p>
          <p>In line with Bitcoin, the total market capitalization for crypto assets fell 4.6% from $1.06 trillion to $1.02 trillion between 8:18 p.m. to 9:05 p.m. Thursday New York time.</p>
          <p>Bitcoin hasn’t plunged so fast and sharp since the price fell by 4% from $20,538 to $19,704 on the morning of November 8 as FTT, the token for crypto exchange FTX, faced massive withdrawals.</p>
          <p>Bitcoin BTC-USD is changing hands at $22,381, down 4.3% for the last 24 hours and 6.4% over the past week.</p>
          <p>"After today’s drop, the pullback is close to 10%, and bitcoin is on pace to close below its 50-day moving average (DMA) for the first time in nearly two months," according to a Friday note from BeSpoke.</p>
          <p>Over the past two days, the crypto market is coming to grips with the potential failure of industry-serving bank, Silvergate.</p>
          <p>“Fortunately, Silvergate is not FTX. Silvergate is more of a fiat on/off-ramp for U.S. Dollars, rather than a key source of liquidity and volume for the entire crypto ecosystem,” said Michael Safai, a co-founder and partner of crypto proprietary trading firm Dexterity Capital.</p>
          <p>The La Jolla, Calif., headquartered Silvergate said in a Wednesday late notice it would further delay its annual report due to greater losses than it showed in January preliminary results in addition to “certain regulatory and other inquiries and investigations that are pending with respect to the Company” and its "ability to continue as a going concern for the twelve months following the issuance of these financial statements," according to the filing.</p>
          <p>Through Thursday, major crypto exchanges and businesses quickly distanced themselves from Silvergate. The shares of the bank (SI) has fallen 62% from Thursday morning to Friday's open from $13.50 to $5.14.</p>
          <p>Further exacerbating Silvergate’s business challenges, Coinbase, Paxos, Galaxy Digital, Gemini, BitStamp, Crypto.com, Cboe Digital, GSR and Circle each issued statements saying they have cut ties with what was once considered a crucial banking partner for the sector.</p>
          <p>Stablecoin issuer Circle added, it is “in the process of unwinding certain services with them.”</p>
          <p>Separately, Binance US and Kraken would not comment on their exposure to Silvergate.</p>
          <p>“There is some dismay around the Silvergate news, but it's not clear it would trigger strong selling,” Noelle Acheson, author of the Crypto Is Macro Now newsletter, told Yahoo Finance Friday.</p>
          <p>Dexterity's Safal agreed: “It’s more a case of jaded traders digesting the news and not wanting to be left holding any potential ticking time bombs, but not understanding how this differs from the collapses of 2022."</p>
          <p>Safai also suggested the response may have come from Asian markets reacting to the exodus of firms from Silvergate.</p>
          <p>Over Thursday, March 2, $203 million in bitcoin long positions were liquidated or $198 million net of short liquidations — the most in a single day in three weeks — according to crypto derivatives aggregator Coinglass.</p>
          <p>As Acheson noted in her Friday newsletter, given little change of the ETH to BTC ratio, bitcoin's sharp drop hit the second largest cryptocurrency ether "in equal measure." Ether is changing hands at $1,570 per coin, down less than 4% for the past day as of Friday morning 8:30 New York time.</p>
          <p>In a worst case scenario of Silvergate's failure, confidence could be shaken, causing some firms to yank capital from the market, Dexterity's Safai said.</p>
          <p>“That in itself could impact liquidity, but that wouldn’t have a ripple effect per se, nor would it last very long,” he added.</p>
          <p>More important for Acheson are the repercussions for banking access if a traditional bank were to “go under” due to its crypto activities.</p>
          <p>“That would give regulators strong ammunition that crypto risks could become 'systemic' and any financial instiution serving the crypto industry could come up against new barriers,” Acheson explained over a messaging app.</p>
          <p>"Uncertainty is currently high," she added.</p>
        </div>
      },
      {
        id: 66,
        title: 'Ethereum core developers delay Shanghai hard fork to early April',
        slug: 'Dow jumps 387 points as US stocks snap weekly losing streak after rate fears ease',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'US Stocks', 'Morning Star'],
        description: <div className="blog-desc">
          <p>The expected launch of the Shanghai hard fork has been delayed from late March to the first two weeks of April, according to Ethereum developers at a meeting on Thursday.</p>
        </div>,
        authorName: 'Dylan Butts',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 3, 2023 5:28 PM',
        cover: 'https://ncx.cx/images/ncx-blog-66.webp',
        content: <div className="blog-content">
          <p>The expected launch of the Shanghai hard fork has been delayed from late March to the first two weeks of April, according to Ethereum developers at a meeting on Thursday.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>The Shanghai hard fork will be the first major upgrade since the “Merge” last September. A hard fork is a permanent split between a new blockchain and its latest version, often adding or fixing features of a network.</li>
            <li>The Shanghai fork will allow validators to withdraw their locked Ether for the first time since the staking feature went live in 2020. Over 17 million Ether (about US$26.7 billion) has since been staked.</li>
            <li>During the meeting, core developers agreed that the hard fork should occur at least two weeks after the “Goerli testnet launch,” which has been scheduled for March 14.</li>
            <li>The “Shanghai Capella” upgrade to Goerli, one of Ethereum’s popular testnets, will be the last chance for Ethereum clients and staking providers to ensure the Shanghai hard fork can go through smoothly when the final “mainnet” version launches.</li>
            <li>“So imagine Goerli happens on the 14th, everything goes well, and on the 16th, we agree to move forward with mainnet – I think the earliest that puts us is like the first week of April,” said Ethereum core developer and project coordinator Tim Beiko.</li>
            <li>Beiko noted in a Thursday Twitter thread that the Ethereum team did not explicitly agree to a mainnet date. Still, they will “probably” set a date during the next developers meeting slated for March 16, “assuming things go well on Goerli.”</li>
          </ul>
        </div>
      },
      {
        id: 67,
        title: 'FTX has a massive $9.5 billion shortfall in top crypto and cash needed to repay customers, the bankrupt exchanges new bosses say',
        slug: 'FTX has a massive $9.5 billion shortfall in top crypto and cash needed to repay customers, the bankrupt exchanges new bosses say',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'FTX', 'Morning Star'],
        description: <div className="blog-desc">
          <p>FTX has identified a $9.5 billion shortfall in top crypto...</p>
        </div>,
        authorName: 'Ryan Hogg',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 3, 2023 5:28 PM',
        cover: 'https://ncx.cx/images/ncx-blog-67.webp',
        content: <div className="blog-content">
          <p>Sam Bankman-Fried.AP Photo/Seth Wenig</p>
          <p>FTX has identified a $9.5 billion shortfall in top crypto and cash needed to repay customers, its new bosses have said, with just $694 million in currency that's easy to cash in.</p>
          <ul>
            <li>FTX has a $9.5 billion shortfall in top crypto and cash to repay customers, bankruptcy lawyers say.</li>
            <li>Just $694 million in assets of $2.2 billion recovered are easy to cash in.</li>
            <li>The group said it also lent $9.3 billion to Sam Bankman-Fried's Alameda Research.</li>
          </ul>
          <p>FTX has identified a $9.5 billion shortfall in top crypto and cash needed to repay customers, its new bosses have said, with just $694 million in currency that's easy to cash in.</p>
          <p>In a press release on Thursday, FTX shared the extent of the company's deficit as bankruptcy lawyers scramble to unravel a mess that led to billions of dollars' worth of customer funds getting lost.</p>
          <p>The bankrupt crypto exchange's new executives have so far identified $2.2 billion in assets in the company's wallets, of which only $694 million were in the most liquid currencies, like fiat, stablecoins, bitcoin, or ethereum.</p>
          <p>Lawyers have also identified $385 million of customer receivables, and substantial claims against Bankman-Fried's Alameda Research, the company at the center of FTX's controversial collapse. FTX lent $9.3 billion to Alameda, they said.</p>
          <p>"It has taken a huge effort to get this far. The exchanges' assets were highly commingled, and their books and records are incomplete and, in many cases, totally absent," CEO John Ray wrote.</p>
          <p>"For these reasons, it is important to emphasize that this information is still preliminary and subject to change."</p>
          <p>FTX US also has a shortfall, with $191 million in assets versus $335 million of customer claims and $283 million of related party claims payable.</p>
          <p>In January, bankruptcy lawyers said they had recovered $5.5 billion in liquid assets from FTX.</p>
          <p>But lawyers still don't know the extent of losses to customer funds, which burned famous faces like Tom Brady and Kevin O'Leary. The Commodities and Futures Trading Commission (CFTC) has estimated that $8 billion of client money had been lost by the exchange.</p>
          <p>Its latest presentation suggested there is a $9.5 billion deficit across its most liquid assets, and a $733 million surplus in category B assets — those which are harder to retrieve — including through the FTX native coin FTT.</p>
          <p>It was reported in November that FTX could have one million creditors.</p>
        </div>
      },
      {
        id: 68,
        title: 'Coinbase, Gemini and other big crypto names have rushed to ditch Silvergate after the bank warned of building troubles',
        slug: 'Coinbase, Gemini and other big crypto names have rushed to ditch Silvergate after the bank warned of building troubles',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'FTX', 'Morning Star'],
        description: <div className="blog-desc">
          <p>Coinbase, Kraken and other crypto apps.Getty Images</p>
        </div>,
        authorName: 'Zinya Salfiti',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 3, 2023 3:16 PM',
        cover: 'https://ncx.cx/images/ncx-blog-68.webp',
        content: <div className="blog-content">
          <p>Coinbase, Kraken and other crypto apps.Getty Images</p>
          <ul>
            <li>Crypto companies are cutting ties with Silvergate, once the go-to bank for digital currencies</li>
            <li>This comes after the crypto bank flagged doubts about its survival.</li>
            <li>Coinbase, Circle Internet Financial and other big names have ditched the lender.</li>
          </ul>
          <p>Some of the biggest names in crypto have backed away from lender Silvergate Capital after the key digital-currency player warned it's having to evaluate its ability to survive.</p>
          <p>Coinbase, Paxos Trust, Circle Internet Financial and Galaxy Digital Holdings all said Thursday they plan to suspend banking with Silvergate, as did the Winklevoss twins' Gemini.</p>
          <p>Crypto.com and Cboe's digital arm are also stepping away, Bloomberg reported.</p>
          <p>The exodus of crypto exchanges, stablecoin issuers and trading desks came after Silvergate flagged doubts about its future in business.</p>
          <p>It said it was delaying the filing of its annual report to regulators because it needs more time to look into how badly its finances have been hit by the events battering the crypto world.</p>
          <p>"The company is evaluating the impact that these subsequent events have on its ability to continue as a going concern for the twelve months following the issuance of its financial statements," the California-based firm said in an SEC filing Wednesday.</p>
          <p>Silvergate's stock price plunged to close 58% lower on Thursday, and was down 5.24% at $5.42 in premarket trading Friday. Shares have fallen almost 95% in the past 12 months, as more than a dozen of its crypto firm customers shut down, were fined or came under investigation.</p>
          <p>Silvergate used to be the go-to bank for the biggest names in crypto, operating a key payments network used for real-time transfers between the firms.</p>
          <p>But the lender has struggled in recent months thanks to its ties to embattled crypto players, and it is seen as having suffered during as prices of digital assets tumbled last year.</p>
          <p>It has been caught up in fallout from the implosion of now-bankrupt FTX, which was one of the lender's major clients. Its customers withdrew $8 billion in deposits late last year after the crypto exchange collapsed in November.</p>
          <p>In addition, it had relationships with other bankrupt crypto firms, like centralized lenders Celsius and BlockFi, along with digital asset brokerage Voyager Digital. It has also has worked with crypto exchange Binance's US arm, which is reportedly under criminal investigation for money laundering and criminal sanctions violations.</p>
          <p>As fears swirled that Silvergate's troubles would spread through the crypto sector, companies stepped up to underline their positions.</p>
          <p>MicroStrategy said it doesn't have any financial relationship with Silvergate, other than a loan it needs to pay back to Silvergate by 2025.</p>
          <p>"For anyone wondering, the loan wouldn't accelerate b/c of SI insolvency or bankruptcy. Our BTC collateral isn't custodied w/ SI & we have no other financial relationship w/ SI,"  the business intelligence firm tweeted.</p>
          <p>Stablecoin Tether is not exposed to Silvergate either, its chief technology officer said in a tweet Thursday.</p>
          <p>Meanwhile, JPMorgan analysts cut their rating on Silvergate stock to "underweight" from "neutral" and withdrew its $14 price target, CoinDesk reported. It also said broker Canaccord Genuity downgraded the bank's rating to "hold" from "buy" and slashed its price target to $9 from $25.</p>
        </div>
      },
      {
        id: 69,
        title: 'Bitcoin-Bridged to Avalanche Reaches Record Daily Mint of Over 2K BTC',
        slug: 'Bitcoin-Bridged to Avalanche Reaches Record Daily Mint of Over 2K BTC',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Avalanche', 'Bitcoin Bridge'],
        description: <div className="blog-desc">
          <p>Interest in bridging bitcoin (BTC) to the Avalanche smart contract blockchain (BTC.b) continues to swell as the supply of wrapped bitcoin (wBTC), the largest wrapped version of bitcoin on Ethereum, dwindles.</p>
        </div>,
        authorName: 'Omkar Godbole',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 3, 2023 3:05 PM',
        cover: 'https://ncx.cx/images/ncx-blog-69.webp',
        content: <div className="blog-content">
          <p>Interest in bridging bitcoin (BTC) to the Avalanche smart contract blockchain (BTC.b) continues to swell as the supply of wrapped bitcoin (wBTC), the largest wrapped version of bitcoin on Ethereum, dwindles.</p>
          <p>On Thursday, over 2,000 BTC ($44 million) was ported to Avalanche, the largest single-day BTC.b mint on record, according to data sourced from pseudonymous analyst 0xAcid's dashboard on Dune Analytics. That has lifted the total circulating supply of BTC.b to 8,572.</p>
          <p>According to 0xAcid, half of the bitcoin minted on Thursday has been moved to BENQI Finance. BENQI Finance is an Avalanche-based decentralized finance (DeFi) protocol, which allows users to lend, borrow and earn additional yields from digital assets.</p>
          <p>In June 2022, Avalanche added support for bitcoin on its cross-chain bridge, allowing BTC holders to deploy their coins in the Avalanche-based decentralized finance (DeFi) ecosystem to generate extra yield. BTC.b has seen impressive growth since then, surpassing the number of coins held in the Lightning Network.</p>
          <p>A crypto bridge is a tool that facilitates interactions between the two economically and technologically separate blockchains. Bridges became a target of hackers last year, accounting for about 70% of the total exploits in the crypto industry last year, according to forensics firm Chainalysis.</p>
          <p>The record BTC.b mint comes as the WBTC supply fell by 15% to 153,164 in February, reaching the lowest since March 2021. Apparently, bankrupt crypto lender Celsius Network, a well-known WBTC holder, redeemed a large amount of WBTC, causing a sharp drop in the token's supply.</p>
          <p>WBTC, issued by Bitgo, is an ERC-20 token based on the Ethereum network with its price pegged 1:1 with bitcoin. The token's supply peaked at 285,000 in April last year and has been falling ever since.</p>
          <p>The Avalanche community considers BTC.b a better option than WBTC, considering Avalanche offers users the ability to mint and redeem BTC.b anytime with the bridge in a non-custodial browser extension Core, eliminating the need for intermediaries. It provides more control to users, unlike WBTC, which relies on merchants to initiate the process of minting and burning.</p>
          <p>According to Emperor Osmo, an anonymous writer for Cosmos-based automated market maker Osmosis, the investors in AVAX, the native token of Avalanche, are yet to take notice of BTC's growing use in DeFI, as evidenced by BTC.b's rising supply.</p>
          <p>"Over $200M of BTC has been bridged to Avalanche [to date]. The BTC DeFi narrative is definitely not priced in for AVAX," Osmo tweeted early Friday.</p>
          <p>At press time, AVAX traded nearly 6% lower on the day, near $16, the lowest since Jan. 20. The cryptocurrency has seen a 50% appreciation in its market value this year, per CoinDesk data.</p>
        </div>
      },
      {
        id: 70,
        title: 'Bitcoin, Ether fall; Litecoin is biggest loser of day',
        slug: 'Bitcoin, Ether fall; Litecoin is biggest loser of day',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Avalanche', 'Bitcoin Bridge'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ether fell by the end of Asian trading hours on Friday afternoon, together with all other top 10 non-stablecoin cryptocurrencies by market capitalization, weighed by the financial woes of crypto-focused bank Silvergate. Investors saw a glimpse of relief</p>
        </div>,
        authorName: 'Zoltan Vardai',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 3, 2023 1:59 PM',
        cover: 'https://ncx.cx/images/ncx-blog-70.webp',
        content: <div className="blog-content">
          <p>Bitcoin and Ether fell by the end of Asian trading hours on Friday afternoon, together with all other top 10 non-stablecoin cryptocurrencies by market capitalization, weighed by the financial woes of crypto-focused bank Silvergate. Investors saw a glimpse of relief, following dovish remarks from the U.S. Federal Reserve, signaling a potential stop to interest rate hikes this summer.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin fell by 4.3% to US$23,402 in the 24 hours to 4:30 p.m. in Hong Kong. Ether declined 4.49% to US$1,569, according to CoinMarketCap data, pressured by financial woes at crypto-focused U.S. bank Silvergate Capital Corp.</li>
            <li>Litecoin’s Ltc token was the day’s biggest loser, losing 8.52% to US$89.05, followed by Dogecoin that fell 6.3% to trade at US$0.075.</li>
            <li>The global cryptocurrency market capitalization shrunk by 3.81% to US$1.03 trillion in the 24 hours to 4:30 p.m. in Hong Kong. The total crypto market trading volume increased by 22% to US$55.42 billion.</li>
            <li>Asian equities strengthened in line with Wall Street’s overnight rally after Atlanta Federal Reserve President Raphael Bostic said that he supported an interest rate increase of 25 basis points and that the central bank may pause rate hikes sometimes during the summer.</li>
            <li>The Shanghai Composite gained 0.54%, the Shenzhen Component Index inched up 0.02%, Japan’s Nikkei 225 rose 1.56% and Hong Kong’s Hang Seng Index strengthened 0.68%.</li>
            <li>Investors digested data suggesting that China’s service sector growth accelerated in February, following the relaxation of Covid-19 restrictions.</li>
            <li>Europe’s STOXX 600 rose 0.4% and Germany’s DAX 40 gained 0.8%, fueled by dovish commentary from the U.S. Fed.</li>
            <li>The euro stood above US$1.06, up from a two-month low of US$1.053 on Feb. 27, despite hawkish remarks from European Central bank officials signaling more incoming rate hikes.</li>
          </ul>
        </div>
      },
      {
        id: 71,
        title: 'Bitcoin Miner Marathon Digital Sold Almost All Bitcoin Mined in February',
        slug: 'Bitcoin Miner Marathon Digital Sold Almost All Bitcoin Mined in February',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Avalanche', 'Bitcoin Bridge'],
        description: <div className="blog-desc">
          <p>Marathon Digital (MARA), one of the largest publicly traded bitcoin miners, sold 650 bitcoins out of 683 it mined in February.</p>
        </div>,
        authorName: 'Aoyon Ashraf',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 3, 2023 1:51 PM',
        cover: 'https://ncx.cx/images/ncx-blog-71.webp',
        content: <div className="blog-content">
          <p>Marathon Digital (MARA), one of the largest publicly traded bitcoin miners, sold 650 bitcoins out of 683 it mined in February.</p>
          <p>The company said it sold the bitcoin to help pay for operating expenses and for general corporate purposes, according to a statement. Marathon sold 1,500 bitcoins in January, which marked the first time it had unloaded any of its holdings.</p>
          <p>Even with the February sales, said company Chairman and CEO Fred Thiel, Marathon increased its unrestricted bitcoin holdings from 8,090 bitcoins as of Jan. 31, 2023, to 8,260, valued at $191.2 million as of Feb. 28.</p>
          <p>The move to sell brings Marathon's strategy in line with some of its peers, including Riot Platforms (RIOT), which started selling some of its mined bitcoin last year. Prior to January, Marathon was among few remaining miners who continued to hold onto its mined bitcoin, even though the company in May 2022 indicated it could become a seller at some point.</p>
          <p>Turning to operations, Marathon said it had installed computing power of 14 exahash per second (EH/s) at the end of February, up from 11 EH/s a month earlier, and plans to reach 23 EH/s near the middle of 2023. The company ended the month with $219.7 million in unrestricted cash on hand, according to the statement.</p>
          <p>Earlier this week, the miner cancelled its earnings report and said that it will need to restate portions of its audited 2021 results and currently unaudited quarterly reports from 2022 after an inquiry from the U.S. Securities and Exchange Commission (SEC) flagged technical accounting matters. Riot Platforms on Thursday also said it will delay its 10-K filings with the SEC due to due to issues raised by its accounting firm about the company's impairment calculations related to its bitcoin assets.</p>
          <p>Marathon's shares were little changed in after-hours trading on Thursday, remaining higher by more than 80% in 2023. Bitcoin is down about 1% over the last 24 hours and higher by 42% year to date.</p>
        </div>
      },
      {
        id: 72,
        title: 'Bitcoin Miner Riot Delayed 10-K Filing Due to Impairment Calculation Issues',
        slug: 'Bitcoin Miner Riot Delayed 10-K Filing Due to Impairment Calculation Issues',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Minor Riot', 'Bitcoin Bridge'],
        description: <div className="blog-desc">
          <p>CORRECTION (March 3, 12:13 UTC): Corrects story to state that Riot has already filed its 10-K report after asking for an extension.</p>
        </div>,
        authorName: 'Nelson Wang',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 3, 2023 1:29 AM',
        cover: 'https://ncx.cx/images/ncx-blog-72.webp',
        content: <div className="blog-content">
          <p>CORRECTION (March 3, 12:13 UTC): Corrects story to state that Riot has already filed its 10-K report after asking for an extension.</p>
          <p>Bitcoin miner Riot Platforms (RIOT) delayed the filing of its annual 10-K report with the Securities and Exchange Commission due to issues raised by its accounting firm about the company's impairment calculations related to its bitcoin assets.</p>
          <p>“After assessing the impact of this revised impairment assessment methodology, the Registrant determined that material errors in certain of its financial statements and reports resulted,” Riot wrote in its SEC filing on Thursday.</p>
          <p>Specifically, Riot said its previously issued financial statements for 2022, 2021 and 2020 “contained material errors and should not be relied upon.”</p>
          <p>For those reasons, Riot said it needed additional time to complete its 10-K and it has since done so and filed its report for 2022.</p>
          <p>Shares of Riot were down 2.4% in after-hours trading on Thursday.</p>
          <p>Earlier this week, fellow miner Marathon Digital (MARA) said it would need to restate portions of its audited 2021 results and unaudited quarter reports from 2022 following an inquiry from the SEC that questioned its method of calculating impairment on digital assets.</p>
          <p>Marathon had previously been scheduled to release its 2022 Q4 results after the close of trading on Tuesday, but now has postponed that.</p>
        </div>
      },
      {
        id: 73,
        title: 'Regulators Are Bringing the Multichain Era to a Close',
        slug: 'Regulators Are Bringing the Multichain Era to a Close',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Multichain', 'ERA'],
        description: <div className="blog-desc">
          <p>As firms add more and more blockchains to their strategic road maps, I grow more and more frustrated. Where, I keep wondering, do firms have the money to properly understand and implement such an enormous array of blockchains in their operations?</p>
        </div>,
        authorName: 'Paul Brody',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 3, 2023 1:20 AM',
        cover: 'https://ncx.cx/images/ncx-blog-73.webp',
        content: <div className="blog-content">
          <p>As firms add more and more blockchains to their strategic road maps, I grow more and more frustrated. Where, I keep wondering, do firms have the money to properly understand and implement such an enormous array of blockchains in their operations? At EY, it costs us about $500,000 to add a new chain to our Blockchain Analyzer platform and we spend between 10%-20% of that amount each year keeping up to date.</p>
          <p>While setting up network nodes may not be too difficult, understanding how networks operate and the mechanics of transfers and payments is another matter. When we add a network, we take a close look at the transaction processing model (how transactions are initiated, recorded, processed and reported); the cryptography that underpins those transactions; and the risks that might be associated with different steps in the process. When it comes to audits, we even identify control points where we can perform verification steps.</p>
          <p><strong>Paul Brody is EY's global blockchain leader and a CoinDesk columnist.</strong></p>
          <p>Nor are these networks static entities. Ethereum has two to four hard forks a year and other smart contract-enabled chains evolve at a similar rate. The result: Staying up to date on a large number of different blockchains is costly.</p>
          <p>Cost isn’t the only factor, either. Liquidity becomes an issue as well. Polling Automated Market Makers (AMM) for their sample prices by volumes of proposed swaps on different token pairs shows how quickly liquidity drops off once you leave the Ethereum blockchain. Even within public Ethereum, trading is highly concentrated among the top tokens. Tokens without liquidity are much more vulnerable to price manipulation.</p>
          <p>Insufficient liquidity in an inadequately regulated market is one of the main reasons the Securities and Exchange Commission has rejected applications for bitcoin (BTC) exchange-traded funds (ETF). The last year has shown, time and again, that crypto asset prices are often manipulated. So these concerns are unlikely to go away and will increasingly deter firms for interacting with, or transacting upon, less-liquid ecosystems.</p>
          <p>As if it wasn’t complicated enough, just deciding which chains to add is a constantly changing mix. Over the last few years, there has been a constant stream of “Ethereum killer” chains taking up the #2 spot in the ecosystem. A good measure of “who’s on top” is the DeFiLlama scorecard showing Ethereum dominance of the decentralized finance (DeFi) ecosystem. DeFiLlama tracks nearly 140 different chains. At various times, Binance, Terra, Tron and Celo have been in second place, but none has ever represented more than about 20% of the total DeFi ecosystem value. Usually the second chain by volume is at 10% or less.</p>
          <p>Nor is Ethereum Virtual Machine (EVM) compatibility a useful factor in de-risking a chain’s adoption. EVM compatibility means that (in theory) applications written for Ethereum’s virtual machine will run on any other chain making that claim. Theory doesn’t always match reality exactly, however. As a result, EVM compatibility may actually increase risk.</p>
          <p>EVM compatibility is not perfect and the implementation on each chain is slightly different. While this can definitely make it simpler to deploy new smart contracts on a different chain, they may behave differently in a fully “compatible” environment and the claim of compatibility may increase risk that people deploy without careful consideration. We often encounter novel errors when moving smart contracts from bitcoineum testnets to the mainnet. I don’t see cross-chain deployment being easier under any conditions.</p>
          <p>All of this aligns with our own experience, which has shown that the amount of work required to qualify a new asset or service for our audit tools on Ethereum is significantly lower than other chains. That’s because we already have a strong understanding of the underlying ecosystem. Ethereum also offers far more liquidity than any other chain, all of it inside the same ecosystem with no cross-chain bridge risk.</p>
          <p>One way to anticipate how a much more regulated future looks is to examine how the biggest centralized or traditional finance firms are looking at the market for crypto assets. These firms are highly regulated and need to document their processes and work extensively to demonstrate they are not mishandling their client responsibilities. What you will see, over and over again, is that many of the biggest regulated firms have largely limited their offerings to BTC and ether (ETH) and have been very slow to grow that footprint. </p>
          <p>When the most regulated firms do start adding to the mix, my own prediction is they will begin by adding more tokens from the Ethereum ecosystem and, perhaps, adding curated sets of DeFi services from Ethereum as well. I had, in the past, expected that we would have seen this expansion during 2022. But the market turmoil and increased regulatory scrutiny in the second half of last year caused many firms to move more slowly and increase their focus on compliance.</p>
          <p>All of these challenges were much easier to ignore when every chain was growing, venture-capital funding was plentiful and regulatory scrutiny was light. All those sunny conditions are gone now and the game is different. Between slashing burn rates and regulatory scrutiny, the multichain era is drawing to a close.</p>
        </div>
      },
      {
        id: 74,
        title: 'US stocks rally as traders digest comments from Fed officials on coming rate hikes',
        slug: 'US stocks rally as traders digest comments from Fed officials on coming rate hikes',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'US Stocks', 'FED'],
        description: <div className="blog-desc">
          <p>As firms add more and more blockchains to their strategic road maps, I grow more and more frustrated. Where, I keep wondering, do firms have the money to properly understand and implement such an enormous array of blockchains in their operations?</p>
        </div>,
        authorName: 'Morgan Chittum',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 3, 2023 1:05 AM',
        cover: 'https://ncx.cx/images/ncx-blog-74.webp',
        content: <div className="blog-content">
          <p>Michael M. Santiago/Getty Images</p>
          <ul>
            <li>US equities rallied Thursday as traders mulled over coming interest rate hikes.</li>
            <li>The Dow popped more than 300 points, supported by Salesforce's gains on strong corporate earnings.</li>
            <li>Atlanta Fed President Raphael Bostic said he's "very firmly" in favor of a quarter-point rate hike.</li>
          </ul>
          <p>US stocks rallied Thursday as a Federal Reserve official's comments on coming interest rates hikes cheered investors.</p>
          <p>After opening in mixed territory, the major indexes closed in the green, even as the 2-year note yield reached levels not seen in over a decade. The Dow Jones Industrial Average popped more than 300 points to lead the gains, supported by Salesforce's strong earnings.</p>
          <p>Atlanta Fed President Raphael Bostic said that he's "very firmly" in favor of a quarter-point hike for now, adding that it's important for the central bank to move cautiously.</p>
          <p>That comes after other Fed officials have hinted at the possibility of a half-point increase as recent data have pointed to a resilient economy that needs more slowing to get inflation under control.</p>
          <p>Earlier on Thursday, jobless claims fell for a third straight week, remaining below 200,000, in a sign the labor market is still strong.</p>
          <p><strong>Here's where US indexes stood at the 4:00 p.m. ET close on Thursday:</strong></p>
          <ul>
            <li>S&P 500: 3,981.36, up 0.76%</li>
            <li>Dow Jones Industrial Average: 33,003.57, up 1.05% (341.73 points)</li>
            <li>Nasdaq Composite: 11,462.98, up 0.73%</li>
          </ul>
          <p><strong>Here's what else happened today:</strong></p>
          <ul>
            <li>There's now a convenient way to be on the opposite side of every Jim Cramer trade, with the launch of an ETF that shorts the financial pundit's stock recommendations.</li>
            <li>Economist Nouriel Roubini said a debt crisis, recession, and stagflation trifecta will hit the US economy this year.</li>
            <li>The odds of a catastrophic US debt default have more than tripled since the start of 2023, according to MSCI.</li>
            <li>China's imports of seaborne Russian oil are on track hit a record high this month.</li>
          </ul>
          <p><strong>In commodities, bonds and crypto:</strong></p>
          <ul>
            <li>West Texas Intermediate crude oil fell 0.62% to $78.12 per barrel. Brent crude, oil's international benchmark, rose 0.4% to $84.60 a barrel.</li>
            <li>Gold slipped 0.3% to $1,84o.20 per ounce.</li>
            <li>The yield on the 10-year Treasury rose six basis points to 4.06%.</li>
            <li>Bitcoin fell 0.46% to $23,683, while ether dropped 0.28% to $1,654.</li>
          </ul>
        </div>
      },
      {
        id: 75,
        title: 'Near Protocol Starts ‘Blockchain Operating System’ to Focus on User Experience',
        slug: 'Near Protocol Starts ‘Blockchain Operating System’ to Focus on User Experience',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'US Stocks', 'FED'],
        description: <div className="blog-desc">
          <p>Layer 1 blockchain network Near Protocol has launched a product-first operating system that developers can build into and users can interact on as a single platform, Illia Polosukhin, Near’s co-founder, announced at the ETHDenver industry conference.</p>
        </div>,
        authorName: 'Lyllah Ledesma',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 3, 2023 12:15 AM',
        cover: 'https://ncx.cx/images/ncx-blog-75.webp',
        content: <div className="blog-content">
          <p>Layer 1 blockchain network Near Protocol has launched a product-first operating system that developers can build into and users can interact on as a single platform, Illia Polosukhin, Near’s co-founder, announced at the ETHDenver industry conference.</p>
          <p>The platform is intended to act as a common layer for browsing and discovering Web 3 products including crypto exchanges, non-fungible token (NFT) galleries and social networks, according to Polosukhin. The framework will be compatible with all blockchains (currently supporting Near Protocol and Ethereum Virtual Machine chains) and Near will act as the common entry point, said Polusukhin, in an interview with CoinDesk.</p>
          <p><strong>Read more: What Is Near Protocol and How Does It Work?</strong></p>
          <p>For developers, decentralized and composable front ends offer a way to build and launch better apps and faster forking of existing pieces and components, while leveraging built-ins such as profiles, payments and notifications as well as searching, without the need to host anything themselves, according to the press release.</p>
          <p>“The composable decentralized front ends as a framework can work with any Web2 or Web3 back end and any wallet,” Polusukhin said. “In the future we will be offering [use of] wallets from one chain to interact with another via seamless bridging,” he added.</p>
          <p>The Near protocol currently ranks as the 35th largest blockchain in terms of market capitalization, according to data from Messari. The layer 1’s market cap was around $1.96 billion and its NEAR token was trading at around $2.28, at the time of writing.</p>
          <p>The platform is dubbed a “blockchain operating system (BOS),” according to the press release. “We are moving away from our layer 1 focus, it’s now about the user and the experience they have. We are looking at this as the frontend for blockchains in general,” said Polosukhin, in an interview with CoinDesk.</p>
          <p>Polosukhin added the platform is hoping to tackle some of the user experience issues in the Web3 space. “As a user at the moment it’s hard to find a single place to look at Web3 apps, there’s no search mechanism or way to navigate between them,” said Polosukhin. “The BOS creates a single point of entry. ”</p>
        </div>
      },
      {
        id: 45,
        title: 'Bitcoin, Ether rise along with most top 10 cryptos, amid ‘bull trap’ warning',
        slug: 'Bitcoin, Ether rise along with most top 10 cryptos, amid ‘bull trap’ warning',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'ETFs', 'Morning Star'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ether gained in Wednesday afternoon trading in Asia, along with most other top 10 non-stablecoin cryptocurrencies by market capitalization. Asian equities mostly rose on Wednesday as investors digested economic recovery indications after Hong Kong ended its mask mandate.</p>
        </div>,
        authorName: 'Pradipta Mukherjee',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 1, 2023 - 2:18 PM',
        cover: 'https://ncx.cx/images/ncx-blog-45.webp',
        content: <div className="blog-content">
          <p>Bitcoin and Ether gained in Wednesday afternoon trading in Asia, along with most other top 10 non-stablecoin cryptocurrencies by market capitalization. Asian equities mostly rose on Wednesday as investors digested economic recovery indications after Hong Kong ended its mask mandate.</p>
          <p>See related article: Bitcoin’s price gains this year bring needed relief to cash-strapped crypto mining industry</p>
          <h3>Fast Facts</h3>
          <ul>
            <li>Bitcoin rose 1.98% to US$23,723 at 4 p.m. in Hong Kong on Wednesday, according to CoinMarketCap data. The largest cryptocurrency by market capitalization has lost 1.47% in the last seven days of trade. Ether gained 1.83% to US$1,651 after posting a weekly gain of 0.47%.</li>
            <li>Litecoin (LTC) gained the most among the top 10 non-stablecoin cryptocurrencies, rising 3.98% to US$97.27, a week after the Ordinals protocol was launched on Litecoin, giving the network non-fungible token (NFT) capabilities.</li>
            <li>Polygon’s Matic token saw the day’s second biggest gain, increasing 2.14% to trade at US$1.25, but declined 7.44% on the week.</li>
            <li>Most Asian markets rose on Wednesday after Hong Kong ended its three-year mask mandate, marking an end to Covid-19 restrictions. Hong Kong’s Hang Seng Index ended the day 4.21% higher, while Japan’s Nikkei 225 inched up 0.26%. The Shanghai Composite gained 1%, and the Shenzhen Component Index rose 1.11%.</li>
            <li>European stocks started the first day of March slightly higher, fueled by stronger-than-expected manufacturing data from China, despite another potential rate hike from the European Central Bank this month. Germany’s DAX 40 gained 0.3% while France’s STOXX 600 inched up 0.8%.</li>
            <li>Investment management giant Morgan Stanley has warned that the current stock market rally is a “bull trap,” and that “March is a high-risk month for the bear market to resume,” according to a note seen by Business Insider.</li>
          </ul>
        </div>
      },
      {
        id: 41,
        title: 'Asian shares, oil advance on strong China factory data',
        slug: 'Asian shares, oil advance on strong China factory data',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'ETFs', 'Morning Star'],
        description: <div className="blog-desc">
          <p>A pedestrian passes by the Hong Kong Stock Exchange electronic screen in Hong Kong, on Wednesday, March 1, 2023. Asian shares were mixed Wednesday as investors watched for upcoming earnings reports and other indicators on how inflationary pressures might be denting global growth. (AP Photo/Louise Delmotte)</p>
        </div>,
        authorName: 'YURI KAGEYAMA',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 1, 2023 - 9:40 AM',
        cover: 'https://ncx.cx/images/ncx-blog-41.webp',
        content: <div className="blog-content">
          <h3>Hong Kong Financial Markets</h3>
          <p>A pedestrian passes by the Hong Kong Stock Exchange electronic screen in Hong Kong, on Wednesday, March 1, 2023. Asian shares were mixed Wednesday as investors watched for upcoming earnings reports and other indicators on how inflationary pressures might be denting global growth. (AP Photo/Louise Delmotte)</p>
          <p>TOKYO (AP) — Asian shares were higher Wednesday after reports on key measures of China manufacturing showed a strong recovery after anti-virus controls were lifted late last year.</p>
          <p>Hong Kong’s Hang Seng index jumped 3.3% and Shanghai gained 0.9%.</p>
          <p>Purchasing managers’ indexes issued by a business magazine, Caixin, and the official China Federation of Logistics & Purchasing showed gains in production, exports and new orders.</p>
          <p>Business activity is recovering after the ruling Communist Party ended stringent anti-virus restrictions in early December. That followed a slump in activity that dragged last year’s economic growth to 3%, its second-lowest level since at least the 1970s.</p>
          <p>That was good news in Hong Kong, where the Hang Seng gained more than 700 points to 20,486.79.</p>
          <p>Hong Kong's own outlook has improved as it also has relaxed pandemic precautions. The territory's chief executive, John Lee, announced Tuesday t hat masks will no longer be required both outdoors and indoors, but some high-risk areas including hospitals and elderly homes can still require their use.</p>
          <p>The Shanghai Composite added 28 points to 3,308.53. South Korean markets were closed for a national holiday.</p>
          <p>Japan’s benchmark Nikkei 225 picked up 0.3% to 27,523.17. Australia’s S&P/ASX 200 edged up 0.1% to 7,263.10.</p>
          <p>Wall Street closed out a frigid February with more losses on Tuesday. The S&P 500 lost 0.3%, locking in a loss of 2.6% for the month and closing at 3,970.15. The Dow fell 0.7% to 32,656.70 while the Nasdaq edged 0.1% lower to 11,455.54. Both also sank over the month.</p>
          <p>Investors are keeping an eye on the last of the earnings reports for this season. Several big-name retailers are still on the schedule for this week.</p>
          <p>“The consumer and inflation are in focus over the next few days. After last week’s drubbing among retail stocks, the worst since June last year, some upbeat earnings results from Target should buoy the group,” said Brian Overby, senior market strategist at Ally, while warning that more volatility may be in store later this week.</p>
          <p>After a strong start to the year driven by hopes inflation is abating, Wall Street shifted into reverse in February. A stream of data showed inflation and the overall economy are remaining more resilient than expected. That’s forced investors to raise their forecasts for how high the Federal Reserve will take interest rates and how long it will keep them there.</p>
          <p>High rates can drive down inflation, but they also raise the risk of a recession down the line because they hurt the economy. They also drag on prices for stocks and other investments. </p>
          <p>Heightened expectations for rates sent yields jumping in the bond market Tuesday. The yield on the 10-year Treasury held steady at 3.92%. It helps set rates for mortgages and other loans that shape the economy’s health, and still near its highest level since November. The two-year yield, which moves more on expectations for Fed action, ticked up to 4.81% from 4.78%. It’s near its highest level since 2007.</p>
          <p>Reports on the economy released Tuesday showed some slight cracks. One said that confidence among U.S. consumers fell in February. Another said that manufacturing in the Chicago region weakened by more than expected.</p>
          <p>In energy trading, benchmark U.S. crude added 48 cents to $77.53 in electronic trading on the New York Mercantile Exchange. Brent crude, the international pricing standard, rose $0.52 to $83.97 a barrel.</p>
          <p>In currency trading, the U.S. dollar inched up to $136.37 Japanese yen from $136.20 yen. The euro rose to $1.0596 from $1.0583.</p>
        </div>
      },
      {
        id: 42,
        title: 'U.S. stocks close lower, S&P 500 ends February with biggest monthly loss since December',
        slug: 'U.S. stocks close lower, S&P 500 ends February with biggest monthly loss since December',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'ETFs', 'Morning Star'],
        description: <div className="blog-desc">
          <p>MARKET PULSE U.S. stocks closed lower Tuesday, with the S&P 500 finishing February with the worst monthly performance since December amid heightened worries over sticky inflation. The Dow Jones Industrial Average (DJIA) closed 0.</p>
        </div>,
        authorName: 'Christine Idzelis',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Mar 1, 2023 - 1:04 AM',
        cover: 'https://ncx.cx/images/ncx-blog-42.webp',
        content: <div className="blog-content">
          <p>U.S. stocks close lower, S&P 500 ends February with biggest monthly loss since December</p>
          <p>MARKET PULSE U.S. stocks closed lower Tuesday, with the S&P 500 finishing February with the worst monthly performance since December amid heightened worries over sticky inflation. The Dow Jones Industrial Average (DJIA) closed 0.</p>
          <p>U.S. stocks closed lower Tuesday, with the S&P 500 finishing February with the worst monthly performance since December amid heightened worries over sticky inflation. The Dow Jones Industrial Average DJIA closed 0.7% lower Tuesday, while the S&P 500 SPX fell 0.3% and the technology-heavy Nasdaq Composite COMP edged down 0.1%, according to preliminary data from FactSet. For the month, the Dow shed 4.2%, while the S&P 500 slid 2.6% and the Nasdaq slipped 1.1%, preliminary FactSet data show. Stocks sold off in February after bond-market concerns over stubbornly high inflation sparked a rise in Treasury yields.</p>
        </div>
      },
      {
        id: 44,
        title: 'EMERGING MARKETS-Stocks set for February decline as rate hike worries dent sentiment',
        slug: 'EMERGING MARKETS-Stocks set for February decline as rate hike worries dent sentiment',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'ETFs', 'Morning Star'],
        description: <div className="blog-desc">
          <p>Feb 28 (Reuters) - Emerging market stocks edged lower on Tuesday, on track for their biggest February percentage decline in over two decades, as geopolitical risks and fears about U.S. interest rates staying higher for longer cooled demand for riskier assets.</p>
        </div>,
        authorName: 'Shubham Batra and Amruta Khandekar',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Feb 28, 2023 - 1:32 PM',
        cover: 'https://ncx.cx/images/ncx-blog-44.jpg',
        content: <div className="blog-content">
          <p>*</p>
          <p>China urban employment, per capita spending fall in 2022</p>
          <p>*</p>
          <p>Russia oil output back at pre-sanctions level</p>
          <p>*</p>
          <p>Turkey's 2022 GDP better-than-expected</p>
          <p>*</p>
          <p>Hungary's rate decision on tap</p>
          <p>Feb 28 (Reuters) - Emerging market stocks edged lower on Tuesday, on track for their biggest February percentage decline in over two decades, as geopolitical risks and fears about U.S. interest rates staying higher for longer cooled demand for riskier assets.</p>
          <p>The MSCI EM equities index slipped 0.5% and was down 6.6% this month, headed for its worst February performance since 2001. A rally in EM stocks at the beginning of 2023 has petered out in recent weeks after signs of strength in the U.S economy fueled fears of aggressive rate hikes by the Federal Reserve and geopolitical risks including heightened U.S.-China tensions also weighed on sentiment.</p>
          <p>"It's started to get more challenging for emerging markets," said William Jackson, chief emerging markets economist at Capital Economics.</p>
          <p>"We think weakness in the global economy will lead to more risk-off in the next few quarters, providing a difficult backdrop."</p>
          <p>Data on Tuesday showed China's urban employment fell for the first time in six decades last year and per capita spending also marked a rare decline, as harsh COVID-19 curbs ravaged the world's second-biggest economy.</p>
          <p>Stocks in China rose 0.6% while Hong Kong's Hang Seng index closed down 0.8%.</p>
          <p>Regional currencies were muted against a stronger dollar and are set to record their worst month since September 2022.</p>
          <p>The South African rand was down 0.2% ahead of the release of domestic money supply, budget, trade and unemployment data later in the day.</p>
          <p>Russia's rouble was stronger against the U.S. dollar at 74.39. Local media reported that Russia's oil output was back at pre-sactions levels for the first time in February.</p>
          <p>Turkey's lira was flat at 18.8860 after data showed the country's economy expanded 5.6% in 2022, more than expected with growth slowing to 3.5% in the fourth quarter as a decline in demand continued.</p>
          <p>In central and eastern Europe, Hungary's forint edged lower against the euro ahead of an interest rate decision by the country's central bank due later in the day.</p>
          <p>The National Bank of Hungary is widely expected to keep its base rate steady at 13%.</p>
          <p>Elsewhere in emerging markets, India's GDP growth likely slowed further in the October-December quarter amid weakening demand and is set to lose more momentum as a series of interest rate hikes weigh on activity, according to a Reuters poll.</p>
          <p>(Reporting by Shubham Batra and Amruta Khandekar in Bengaluru; Editing by Emelia Sithole-Matarise)</p>
        </div>
      },
      {
        id: 43,
        title: 'Wall Street and FTSE 100 lower as volatile month comes to close',
        slug: 'U.S. stocks close lower, S&P 500 ends February with biggest monthly loss since December',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'ETFs', 'Morning Star'],
        description: <div className="blog-desc">
          <p>The FTSE 100 and European stocks were mixed this Tuesday as investors reassess the global economy's outlook given expectations for higher interest rates.</p>
        </div>,
        authorName: 'Pedro Goncalves · Finance Reporter, Yahoo Finance UK',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Feb 28, 2023 - 12:44 PM',
        cover: 'https://ncx.cx/images/ncx-blog-43.webp',
        content: <div className="blog-content">
          <p>The FTSE 100 and European stocks were mixed this Tuesday as investors reassess the global economy's outlook given expectations for higher interest rates.</p>
          <p>The FTSE 100 FTSE dropped 0.74% to 7,876.28 points at close of trading on Tuesday, while the CAC 40 FCHI in Paris was muted at 7,291 points. In Germany, the DAX GDAXI was also flat at 15,383.</p>
          <p>Across the pond, US stocks fell Tuesday at the open, rounding out the last day of a volatile month of February on Wall Street.</p>
          <p>The Dow Jones (^DJI) lost 0.34% to 32,775 points. The S&P 500 (^GSPC) retreated 0.31% to 3,980 points and the tech-heavy NASDAQ (^IXIC) edged lower by 0.21% to 11,472.</p>
          <p>Traders are increasingly worried that central banks around the globe will remain hawkish in order to bring down persistent inflation.</p>
          <p>“Rishi Sunak’s new Brexit deal for Northern Ireland breathed some life back into the pound yesterday, yet the currency gave back some of these gains on Tuesday as markets took time to digest the information," said Russ Mould, investment director at AJ Bell.</p>
          <p>"While the deal may provide a reset for UK/EU relations and be good for the UK economy, reports suggest some in the DUP [Democratic Unionist Party] are not fully satisfied by what’s on the table.”</p>
          <p>Inflation figures in Spain and France both rose in February while the latest grocery price survey from Kantar in the UK showed food price inflation hit a record high, up 17.1%.</p>
          <p>Read more: Spring budget: Chancellor Hunt won’t have enough money to cut taxes or raise pay</p>
          <p>Back in London, shares in Ocado (OCDO.L) dropped by 7.81%, the top faller on the FTSE 100 index.</p>
          <p>The online supermarket saw its pre-tax loss balloon to nearly £501m last year, nearly three times as high as in 2021.</p>
          <p>The FTSE 100 slipped 0.4% to 7,902, pulled down by poorly received results from Ocado and Croda (CRDA.L), and housebuilders falling in response to Travis Perkins’ cautious outlook in its latest figures.</p>
          <p>The builders’ merchant said it was preparing for ‘more challenged’ private domestic new-build and repair, maintenance and improvement markets.</p>
          <p>Moving in the opposite direction, online electricals retailer AO World (AO.L) was up by 23.38% as it boosted it annual earnings outlook for the third time since November as it said cost-cutting efforts and targeted price rises were paying off.</p>
          <p>Read more: Pound hits high as Northern Ireland protocol deal is done</p>
          <p>Meanwhile, Brent crude (BZ=F) rose and was trading at around $83/barrel as demand surged ahead of the release of fresh economic data from China, the world’s largest oil importer and second largest consumer.</p>
          <p>In Asia, Tokyo’s Nikkei 225 N225 rose 0.1% to 27,445 points, while the Hang Seng HSI in Hong Kong slipped 0.78% to 19,044. The Shanghai Composite edged higher, rising 0.66% to 3,279 points.</p>
        </div>
      },
      {
        id: 32,
        title: 'TU.S. stock futures rise as data shows Chinese economy bouncing back',
        slug: 'U.S. stock futures rise as data shows Chinese economy bouncing back',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'US Stocks', 'Chinese'],
        description: <div className="blog-desc">
          <p>U.S. stock index futures rose on Wednesday as traders began the new month on a more optimistic note following signs of a recovery in the Chinese economy.</p>
        </div>,
        authorName: 'Jamie Chisholm',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Feb 28, 2023 - 7:23 AM',
        cover: 'https://ncx.cx/images/ncx-blog-32.webp',
        content: <div className="blog-content">
          <p>U.S. stock index futures rose on Wednesday as traders began the new month on a more optimistic note following signs of a recovery in the Chinese economy.</p>
          <h3>How are stock-index futures trading</h3>
          <ul>
            <li>S&P 500 futures ES00, -0.36% rose 9 points, or 0.2%, to 3985</li>
            <li>Dow Jones Industrial Average futures YM00, -0.28% gained 54 points, or 0.2%, to 32736</li>
            <li>Nasdaq 100 futures NQ00, -0.37% advanced 42 points, or 0.3%, to 12114</li>
          </ul>
          <p>On Tuesday, the Dow Jones Industrial Average DJIA, -0.71% fell 232 points, or 0.71%, to 32657, the S&P 500 SPX, -0.30% declined 12 points, or 0.3%, to 3970, and the Nasdaq Composite COMP, -0.10% dropped 11 points, or 0.1%, to 11456. The Dow Jones Industrial Average fell 4.2% in February and is now down 1.5% for 2023.</p>
        </div>
      },
      {
        id: 31,
        title: 'There is still a ‘delta’ between what the market is pricing in amid rate hikes, strategist says',
        slug: 'There is still a ‘delta’ between what the market is pricing in amid rate hikes, strategist says',
        category: 'Crypto',
        subCategory: ['Market', 'Polygon', 'Strategy'],
        description: <div className="blog-desc">
          <p>Allianz Investment Management Head ETF Market Strategist Johan Grahn sits down with Yahoo Finance Live to discuss volatility and market performances amid inflation and the Fed's interest rate hike cycle.</p>
        </div>,
        authorName: 'Yahoo Finance',
        authorurl: 'https://www.yahoo.com/author/yahoo--finance',
        authorAvatar: 'https://ncx.cx/images/yahoo-fi.webp',
        authorContent: '',
        createdAt: 'Feb 28, 2023 - 7:23 AM',
        cover: 'https://ncx.cx/images/ncx-blog-31.jpg',
        content: <div className="blog-content">
          <p>Allianz Investment Management Head ETF Market Strategist Johan Grahn sits down with Yahoo Finance Live to discuss volatility and market performances amid inflation and the Fed's interest rate hike cycle.</p>
          <h3>Video Transcript</h3>
          <p>DAVE BRIGGS: All right, welcome back to "Yahoo Finance Live." We're going to check on the markets with just a bit before trading closes. Let's see, the Dow falling now 222 points, and check out the S&P and the NASDAQ hovering around the level point. Down just a bit on the S&P, and the NASDAQ just barely in the positive.</p>
          <p>What is ahead for this economy and equities? Let's talk about all of it with Johan Grahn, Allianz Investment Management head ETF market strategist. Nice to see you, sir. Seems like the markets, relatively speaking, holding their breath. What could be the next catalyst around the corner?</p>
          <p>JOHAN GRAHN: Well, Dave, it's good to see you. Thank you.</p>
          <p>Catalysts? There could be all kinds of catalysts around the corner for you just lurking in the background here. The one thing that we've seen is that we have an environment of increased volatility. And even if it's been coming down a little bit this past week, we're still in a pretty high-volatility environment by historical standards, so to speak.</p>
          <p>So basically it tells you that all the known factors are baked in and that the known factors are bound to swing one way or another, or something new will pop up at any point in time. So it's a good question with a very weak answer for you because it could be a myriad of things.</p>
          <p>SEANA SMITH: Well, Johan, when you take into account what's happening today, where we are at current levels here, I think a lot of people are trying to figure out how exactly to play this market given all of that uncertainty that you were just talking about. So what's your advice to those who are trying to decide what now is-- I guess what is best to do right now?</p>
          <p>JOHAN GRAHN: Yeah, it really depends on what kind of time you have. And I mean, frankly, most of the assets are sitting in retirement accounts, as you know. So it's really those investors and Americans that have the least amount of time left in their investing careers, so to speak.</p>
          <p>And what it leads to is a fight against this wealth destruction that the Fed has set out to navigate for us, if you will. And what it comes down to is that you have to be more conservative. You've got to think about how you can keep that wealth as opposed to rallying for the highest potential return.</p>
          <p>And there are various ways you can do it, and I think one of the benefits of the current environment, even though it's a silver lining given the volatility that we're in, is that when you look at this market with higher equity volatility and also higher fixed-income volatility, higher rates, it gives you an opportunity to invest in structured products where you can actually set the definition of what you're looking for, and it gives you more upside given the unit of risk that you're mitigating on the downside in an environment like this.</p>
          <p>DAVE BRIGGS: Johan, many just feel that the 10 year is really in control and closing their eyes to the equities right now to the highest we've seen since November, 50-plus basis points this month alone. What does it tell you is around the corner?</p>
          <p>JOHAN GRAHN: Well, the proverbial Fed tactics are always around the corner, as you know. And we're still looking at this environment where you have the Fed consistently telling the market that you are going to have to be more patient and the market reacting and responding consecutively to all this communication. And it's happened over and over and over again. Every time the Fed comes out to have this conversation, the market will try to call the bluff, and then they have to move aside and say, OK, fine. We'll adjust accordingly, and it keeps happening.</p>
          <p>And I think that right now we might be very much closer to the point where the market is, frankly, catching up to what the Fed has been saying all along, and now we're talking about the duration of how long the Fed is going to keep the rates high and how many more hikes they're going to pursue to get there.</p>
          <p>DAVE BRIGGS: And you see no pivot until '24, presumably?</p>
          <p>JOHAN GRAHN: No pivot until '24. The market is still-- there is a little bit of a delta between what the market is pricing in and what the Fed is speaking to the market. But in terms of a real pivot, nothing until 2024 for sure.</p>
        </div>
      },
      {
        id: 30,
        title: 'Bitcoin, Ether little changed; Polygon’s Matic token leads losers in top ten',
        slug: 'Bitcoin, Ether little changed; Polygon’s Matic token leads losers in top ten',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Polygon', 'Token Leads'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ether traded little-changed on Tuesday morning in Asia, while most other top 10 non-stablecoin cryptocurrencies fell. Polygon led the losers, along with Solana and Shiba Inu. U.S. equities gained ground on Monday after last week’s slump.</p>
        </div>,
        authorName: 'Tom Zuo',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Feb 28, 2023 - 7:23 AM',
        cover: 'https://ncx.cx/images/ncx-blog-27.webp',
        content: <div className="blog-content">
          <p>Bitcoin and Ether traded little-changed on Tuesday morning in Asia, while most other top 10 non-stablecoin cryptocurrencies fell. Polygon led the losers, along with Solana and Shiba Inu. U.S. equities gained ground on Monday after last week’s slump.</p>
          <h3>Fast Facts</h3>
          <ul>
            <li>Bitcoin dipped 0.23% in the past 24 hours to trade little changed at US$23,509 as of 8 a.m. in Hong Kong. The leading token is holding a seven day loss of 5.34%, according to CoinMarketCap data. Ethereum also treaded water at US$1,634, off 0.41%. The token is down 4.05% in the past seven days.</li>
            <li>Polygon’s Matic token posed the biggest drop among the top ten cryptocurrencies by market capitalization, falling 3.63% to US$1.24, and extending its weekly loss to 16.27%. Polygon Labs, the developer of the Polygon blockchain, said last week it would lay off 20% of its workforce, which was followed by a reported glitch on Polygon’s network.</li>
            <li>Solana lost 2.43% to US$22.69, posting a weekly loss of 13.41%. Solana developers are investigating a network failure on the Solana Mainet Beta last Saturday, which forced it to restart the network and downgrade it from a recent update.</li>
            <li>Shiba Inu dropped 2.53% after analytics firm Lookonchain reported a wallet moving 182 billion Shiba Inu tokens to crypto exchanges on Monday, usually the sign of preparation for selling that can drive prices lower. Last time the same wallet transferred over 200 billion Shiba Inu in December 2022 the price fell more than 7% on the day, according to Lookonchain. The token is down 7.21% for the seven day period.</li>
            <li>The total crypto market capitalization edged 0.15% lower to US$1.07 trillion, while the total trading volume jumped 34.55% over the past 24 hours to US$45.70 billion.</li>
            <li>U.S. equities edged higher on Monday after last week’s tumble on concerns about indicators showing inflation remains entrenched. The Dow Jones Industrial Average rose 0.22%, the S&P 500 gained 0.31% and the Nasdaq Composite Index added 0.63%.</li>
            <li>Janet Yellen, the U.S. Treasury Secretary, told CNN on Monday that U.S. inflation remains too high, but the economy can achieve a soft landing. She said the Federal Reserve’s attempt to curb inflation through higher interest rates while maintaining a strong labor market appears achievable.</li>
            <li>U.S. interest rates are now between 4.5% to 4.75%, the highest since October 2007. The January Federal Reserve meeting minutes released last Wednesday showed policymakers agreed to slow down the pace of interest rate hikes, but warned the tightening cycle is not over.</li>
            <li>The U.S. Census Bureau announced its latest monthly report on durable goods on Monday, with orders for manufactured goods – excluding transportation – rising 0.7% in January, or below expectations in a sign of a slowdown in the growth that has been feeding inflation.</li>
            <li>Analysts at the CME Group predict a 76.7% likelihood the Fed will raise rates by another 25 basis points in March. They also predict a 23.3% chance for a raise of 50 basis points, a decrease from 27.7% as reported on Monday.</li>
          </ul>
        </div>
      },
      {
        id: 29,
        title: 'Bitcoin, Ether gain; Polygon, XRP biggest losers',
        slug: 'Bitcoin, Ether gain; Polygon, XRP biggest losers',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Polygon', 'XRP'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ethereum rose in Monday afternoon trade in Asia along with most of the top 10 non-stablecoin cryptocurrencies by market capitalization. Asian markets dropped on Monday, following last Friday’s Wall Street selloff, as investors brace for more interest rate hikes in the U.S. due to persistently high inflation.</p>
        </div>,
        authorName: 'Pradipta Mukherjee',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Feb 27, 2023 - 3:28 AM',
        cover: 'https://ncx.cx/images/blog_28_ncx.webp',
        content: <div className="blog-content">
          <p>Bitcoin and Ethereum rose in Monday afternoon trade in Asia along with most of the top 10 non-stablecoin cryptocurrencies by market capitalization. Asian markets dropped on Monday, following last Friday’s Wall Street selloff, as investors brace for more interest rate hikes in the U.S. due to persistently high inflation.</p>
          <h3>Fast Facts</h3>
          <ul>
            <li>Bitcoin, the biggest cryptocurrency by market capitalization, rose 1.18% to US$23,441 in the 24 hours to 4 p.m. on Monday in Hong Kong, although it dropped 4.4% on the week. Ethereum gained the most among the top 10 cryptos at 2.28% to US$1,637, but lost 3.54% in the last seven days, according to CoinMarketCap data.</li>
            <li>Polygon’s Matic slipped 0.74% in 24 hours to US$1.26 and has dropped 15.74% over the past seven days. XRP fell 0.71% to US$0.3754, after posting a weekly loss of 3.9%.</li>
            <li>The global cryptocurrency market capitalization rose 1.05% to US$1.07 trillion while total crypto market volume gained 0.57% to US$36.09 billion.</li>
            <li>Asian equity markets ended lower on Monday, following the release of the U.S. personal consumption expenditures January price index last Friday, which rose 4.7% on the year.</li>
            <li>The Shenzhen Component Index dropped 0.73% while the Shanghai Composite slipped 0.28%.</li>
            <li>Japan’s Nikkei 225 dropped 0.11%, while South Korea’s Kospi ended the day 0.87% lower.</li>
            <li>Hang Seng Index slipped 0.33% after Hong Kong announced that its exports dropped 36.7% in January compared to a year ago. The decline in exports is the largest monthly fall in 70 years.</li>
            <li>The U.S. Federal Reserve is expected to increase interest rates further to tackle inflation at its next meeting on Mar. 21 and 22. Currently, interest rates in the world’s largest economy are at 4.5% to 4.75%, the highest since October 2007. The central bank has said interest rates could go as high as 5% to tame inflation.</li>
            <li>U.S. Treasury Secretary Janet Yellen said on Saturday that the fight against inflation “is not a straight line” and that more work is necessary.</li>
          </ul>
        </div>
      },
      {
        id: 40,
        title: 'Intel Plunges on Earnings Miss, Weak Guidance: ETFs in Focus',
        slug: 'Intel Plunges on Earnings Miss, Weak Guidance: ETFs in Focus',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'ETFs', 'Morning Star'],
        description: <div className="blog-desc">
          <p>Intel INTC reported Q4 results after market close yesterday. The world’s largest chipmaker missed estimates for both earnings and revenues and offered a weak outlook for 2023, citing cooling demand for its chips used in personal computers.</p>
        </div>,
        authorName: 'Sweta Killa',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Feb 22, 2022 - 11:23 PM',
        cover: 'https://ncx.cx/images/ncx-blog-40.webp',
        content: <div className="blog-content">
          <p>Intel INTC reported Q4 results after market close yesterday. The world’s largest chipmaker missed estimates for both earnings and revenues and offered a weak outlook for 2023, citing cooling demand for its chips used in personal computers.</p>
          <p>As such, shares of INTC plunged about 10% in after-market hours. Given this, ETFs like First Trust NASDAQ Technology Dividend Index Fund TDIV, First Trust Nasdaq Semiconductor ETF FTXL, Strive U.S. Semiconductor ETF SHOC, Pacer Data and Digital Revolution ETF TRFK, and Invesco Dow Jones Industrial Average Dividend ETF DJD having the highest allocation to the world’s biggest semiconductor maker, will be in focus.</p>
          <h3>Q4 Earnings in Focus</h3>
          <p>Earnings of 10 cents per share came much below the Zacks Consensus Estimate of 20 cents and the year-ago earnings of $1.09. Revenues declined 32% year over year to $14 billion and came in above the estimated $14.5 billion.</p>
          <p>Client computing, data-center and AI, and network and edge revenues declined 36%, 33% and 1%, respectively. However, accelerated computing systems and graphics, Intel Foundry Services and Mobileye revenues were up 1%, 30% and 59%, respectively (see: all the Technology ETFs here).</p>
          <p>The chipmaker continued to suffer from dismal PC and data-center markets. Shoppers choose to hold on to the laptops and desktops they purchased at the peak of the pandemic. According to Gartner, Q4 worldwide PC shipments declined 28.5%, the biggest decline since the firm started following shipments in the mid-1990s.</p>
          <p>As such, Intel projects another big revenue decline for the current quarter, along with deeper-than-expected losses. Revenues are expected in the range of $10.5 billion to $11.5 billion, while loss will likely be about 15 cents per share. Both revenue and earnings per share guidance are well below the current Zacks Consensus Estimate of $14.05 billion and 26 cents per share, respectively.</p>
          <h3>ETFs to Tap</h3>
          <h3>First Trust NASDAQ Technology Dividend Index Fund (TDIV)</h3>
          <p>First Trust NASDAQ Technology Dividend Index Fund provides exposure to dividend payers within the technology sector by tracking the Nasdaq Technology Dividend Index. It holds about 93 securities in its basket. Of these firms, Intel takes the second spot, making up for 7.8% of the assets. From a sector look, about 38.6% of the portfolio is dominated by semiconductors & semiconductor equipment, followed by software, and technology hardware, storage & peripherals.</p>
          <p>First Trust NASDAQ Technology Dividend Index Fund has amassed $1.6 billion in its asset base while trading in a volume of around 125,000 shares per day. It charges 50 bps in annual fees (read: 5 Stocks Powering Nasdaq ETF to Start 2023).</p>
          <h3>First Trust Nasdaq Semiconductor ETF (FTXL)</h3>
          <p>First Trust Nasdaq Semiconductor ETF offers exposure to the most-liquid U.S. semiconductor securities based on volatility, value and growth by tracking the Nasdaq US Smart Semiconductor Index. FTXL holds 30 stocks in its basket, with Intel taking the second spot at 7.6% share.</p>
          <p>First Trust Nasdaq Semiconductor ETF has accumulated $90.7 million in AUM. The average trading volume is light at around 10,000 shares and the expense ratio is 0.60%. FTXL has a Zacks ETF Rank #2 (Buy).</p>
          <h3>Strive U.S. Semiconductor ETF (SHOC)</h3>
          <p>Strive U.S. Semiconductor ETF seeks broad market exposure to the U.S. semiconductor sector. It follows the Solactive United States Semiconductors 30 Capped Index and holds 31 stocks in its basket, with Intel taking the third spot at 7.2% of assets.</p>
          <p>Strive U.S. Semiconductor ETF has AUM of $16.5 million and charges 40 bps in annual fees. It trades in a volume of 11,000 shares per day on average.</p>
          <h3>Pacer Data and Digital Revolution ETF (TRFK)</h3>
          <p>Pacer Data and Digital Revolution ETF aims to offer investors exposure to the globally listed stocks and depositary receipts of data and digital revolution companies. It follows the Pacer Data Transmission and Communication Revolution Index, holding 83 stocks in its basket. Out of these, Nvidia is the top firm, accounting for an 11.6% share.</p>
          <p>Pacer Data and Digital Revolution ETF debuted in the space in June and has accumulated $0.9 million in its asset base. It has an expense ratio of 0.60%. TRFK trades in a meager volume of under 100 shares per day on average.</p>
          <h3>Invesco Dow Jones Industrial Average Dividend ETF (DJD)</h3>
          <p>Invesco Dow Jones Industrial Average Dividend ETF offers exposure to dividend-paying companies included in the Dow Jones Industrial Average by their 12-month dividend yield over the prior 12 months. It holds 28 stocks in its basket, with Intel making up for the sixth position in the basket for 5.2% of assets (read: After Winning in 2022, What Awaits Dow Jones ETFs in 2023?).</p>
          <p>Invesco Dow Jones Industrial Average Dividend ETF has been able to manage assets worth $298.8 million, while trading in a volume of 64,000 shares a day on average. It charges 7 bps in annual fees and has a Zacks ETF Rank #3.</p>
        </div>
      },
      {
        id: 39,
        title: 'Semiconductor ETFs Shining Amid Mixed Numbers in Q4 Earnings',
        slug: 'Semiconductor ETFs Shining Amid Mixed Numbers in Q4 Earnings',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'ETFs', 'Morning Star'],
        description: <div className="blog-desc">
          <p>The semiconductor space has been surging over the past three months on improving demand from chips suppliers, data centers, PC manufacturers, and mobile phone producers, as well as reopening in China. However, mixed quarterly results from well-known players slowed down the rally in the sector lately (read: Is the Worst Over for Semiconductor Stocks & ETFs?).</p>
        </div>,
        authorName: 'Sweta Killa',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Feb 22, 2022 - 11:23 PM',
        cover: 'https://ncx.cx/images/ncx-blog-39.webp',
        content: <div className="blog-content">
          <p>The semiconductor space has been surging over the past three months on improving demand from chips suppliers, data centers, PC manufacturers, and mobile phone producers, as well as reopening in China. However, mixed quarterly results from well-known players slowed down the rally in the sector lately (read: Is the Worst Over for Semiconductor Stocks & ETFs?).</p>
          <p>While Intel INTC and Qualcomm QCOM disappointed investors, Texas Instruments TXN, Lam Research Corporation LRCX and Advanced Micro Devices AMD came up with solid earnings reports. As such, semiconductor ETFs remained strong over the past month with VanEck Vectors Semiconductor ETF SMH, iShares Semiconductor ETF SOXX, Invesco PHLX Semiconductor ETF SOXQ, Invesco Dynamic Semiconductors ETF PSI and First Trust NASDAQ Semiconductor ETF FTXL rising 12.8%, 12.7%, 12.4%, 10.4% and 8.7%, respectively.</p>
          <p>Let’s dig into the individual performances:</p>
          <h3>Semiconductor Earnings in Focus</h3>
          <p>Intel, the world’s largest chipmaker, missed estimates for both earnings and revenues and offered a weak outlook for 2023, citing cooling demand for its chips used in personal computers. Earnings of 10 cents per share came much below the Zacks Consensus Estimate of 20 cents and revenues of $14 billion also came in below the estimated $14.5 billion. Intel projects another big revenue decline for the current quarter, along with deeper-than-expected losses. Revenues are expected in the range of $10.5 billion to $11.5 billion, while loss will likely be about 15 cents per share (read: Intel Plunges on Earnings Miss, Weak Guidance: ETFs in Focus).</p>
          <p>Texas Instruments reported solid Q4 results topping our earnings estimate by 17 cents and revenue estimate by $84 million. For first-quarter 2023, the company expects revenues in the range of $4.17-$4.53 billion and earnings per share of $1.64-$1.90.</p>
          <p>Lam Research also topped on both revenue and earnings for second-quarter fiscal 2023. Earnings per share of $10.71 strongly surpassed the Zacks Consensus Estimate by 76 cents, while revenues of $5.28 billion edged past the estimated $5.08 billion. For third-quarter fiscal 2023, the company expects revenues of $3.8 billion (+/- $300 million) and earnings per share of $6.50 (+/- 75 cents).</p>
          <p>Advanced Micro Devices beat the Zacks Consensus Estimate on earnings by three cents and on revenues by $58 million. It expects first-quarter 2023 revenues of $5.3 billion (+/-$300 million), which indicates a year-over-year decline of 10%.</p>
          <p>At Qualcomm, earnings per share of $2.37 for first-quarter fiscal 2023 surpassed the Zacks Consensus Estimate by a couple of cents, while revenues of $9.46 billion fell short of the estimate of $9.56 billion. The company projects revenues of $8.7-$9.5 billion and earnings per share of $2.05-$2.25 for the second quarter of fiscal 2023.</p>
          <h3>ETFs in Focus</h3>
          <h3>VanEck Vectors Semiconductor ETF (SMH)</h3>
          <p>VanEck Vectors Semiconductor ETF provides exposure to 25 companies involved in semiconductor production and equipment by tracking the MVIS US Listed Semiconductor 25 Index. It has managed assets worth $7.7 billion and charges 35 bps in annual fees and expenses. The in-focus five firms account for 23.2% share in the basket.</p>
          <p>VanEck Vectors Semiconductor ETF is heavily traded with a volume of around 3.7 million shares per day and has a Zacks ETF Rank #1 (Strong Buy) with a High risk outlook.</p>
          <h3>iShares Semiconductor ETF (SOXX)</h3>
          <p>iShares Semiconductor ETF follows the ICE Semiconductor Index and offers exposure to U.S. companies that design, manufacture and distribute semiconductors. It holds 30 securities in its basket, with the in-focus five firms making up for 26.6% of assets.</p>
          <p>iShares Semiconductor ETF has amassed $7.4 billion in its asset base and trades in a volume of about 1.1 million shares a day. The product charges a fee of 35 bps a year from investors and has a Zacks ETF Rank #2 (Buy) with a High risk outlook (read: U.S.-China Aerial Conflict Intensifies: ETF Areas in Tight Spot).</p>
          <h3>Invesco PHLX Semiconductor ETF (SOXQ)</h3>
          <p>Invesco PHLX Semiconductor ETF tracks the PHLX Semiconductor Sector Index, holding 30 stocks in its basket. The in-focus five firms collectively make up for a combined 30.4% share in the basket.</p>
          <p>Invesco PHLX Semiconductor ETF has accumulated $101.7 million in its asset base. It charges 19 bps in annual fees and trades in an average daily volume of 70,000 shares.</p>
          <h3>First Trust Nasdaq Semiconductor ETF (FTXL)</h3>
          <p>First Trust Nasdaq Semiconductor ETF offers exposure to the most-liquid U.S. semiconductor securities based on volatility, value and growth by tracking the Nasdaq US Smart Semiconductor Index. FTXL holds 30 stocks in its basket, with in-focus five firms accounting for a combined 23.1% share.</p>
          <p>First Trust Nasdaq Semiconductor ETF has accumulated $87.1 million in AUM. The average trading volume is light at around 10,000 shares and the expense ratio is 0.60%. FTXL has a Zacks ETF Rank #2.</p>
        </div>
      },
      {
        id: 38,
        title: 'We’re not paying countries enough to save trees from deforestation. Here’s the price that would actually make a dent in climate change.',
        slug: 'We’re not paying countries enough to save trees from deforestation. Here’s the price that would actually make a dent in climate change.',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'ETFs', 'Morning Star'],
        description: <div className="blog-desc">
          <p>We’re not paying countries enough to save trees from deforestation. Here’s the price that would actually make a dent in climate change.</p>
        </div>,
        authorName: 'Rachel Koning Beals',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Feb 22, 2022 - 11:23 PM',
        cover: 'https://ncx.cx/images/ncx-blog-38.webp',
        content: <div className="blog-content">
          <p>We’re not paying countries enough to save trees from deforestation. Here’s the price that would actually make a dent in climate change.</p>
          <p>A U.N. policy arm has a price target in mind to pay industry and agriculture to stop deforestation speeding up climate change, it exclusively tells MarketWatch.</p>
        </div>
      },
      {
        id: 24,
        title: 'Bitcoin gains, returns to US$25,000; XRP biggest winner in top 10 cryptos',
        slug: 'Bitcoin gains, returns to US$25,000; XRP biggest winner in top 10 cryptos',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Dollars', 'Crypto'],
        description: <div className="blog-desc">
          <p>Bitcoin and Ether prices rose in Tuesday afternoon trade in Asia, while all other top 10 non-stablecoin cryptocurrencies were mixed. XRP led gains at 2.37%, while Solana and Polygon dropped saw the largest declines.</p>
        </div>,
        authorName: 'Pradipta Mukherjee',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Feb 21, 2023 - 9:15 AM',
        cover: 'https://ncx.cx/images/pizza-bitcoin.png',
        content: <div className="blog-content">
          <p>Bitcoin and Ether prices rose in Tuesday afternoon trade in Asia, while all other top 10 non-stablecoin cryptocurrencies were mixed. XRP led gains at 2.37%, while Solana and Polygon dropped saw the largest declines. Bitcoin returned to the US$25,000 price level a day after it became the 11th largest asset in the world, surpassing payments giant Visa’s market cap.</p>
          <h3>Fast facts</h3>
          <ul>
            <li>Bitcoin (BTC) gained 1.97% to US$25,002 in 24 hours to 4 p.m. in Hong Kong, and has gained 14.79% on the week. Ether inched up 0.46% to US$1,704, gaining 13.2% over the last seven days, according to CoinMarketCap data.</li>
            <li>“BTC repeated its March 2020 downside wicking depth below the 200-day MA (moving average),” wrote pseudonymous crypto analyst Rekt Capital, adding that Bitcoin will rally once it reclaims the 200-day MA as support, which is around US$25,000.</li>
            <li>XRP rose the most, gaining 2.37% to US$0.3998, and strengthened 7.85% on the week. Ripple Labs, which developed the Ripple payment network powered by XRP, said on Monday it is hiring engineers and has about 75 open positions.</li>
            <li>Solana dropped the most among top 10 non-stablecoin cryptos, by 1.78% to US$26, but gained 23.84% in the last seven days. Polygon’s Matic fell 1.46% to US$1.47, but strengthened 24.54% on the week.</li>
            <li>Asian markets were mixed on Tuesday. The Shenzhen Component Index gained 0.12% while the Shanghai Composite strengthened by 0.49%.</li>
            <li>“While the current macroeconomic environment may cast a shadow over global exports, and the Fed may still continue with its rate hikes, China’s domestic demand remains robust,” Jack Lee, China A-shares fund manager of Schroders, said.</li>
            <li>Hong Kong’s Hang Seng Index dropped 1.71% at Tuesday’s close while Japan’s Nikkei 225 lost 0.21%.</li>
            <li>European shares declined in anticipation of Eurozone manufacturing and services activity data and the ZEW economic sentiment index. Germany’s DAX 40 retreated 0.2% while Stoxx 600 fell 0.3%.</li>
            <li>Investors are anticipating the minutes of the meeting of U.S. Federal Reserve, which would shed light on the direction of the economy and interest rates. The central bank has been consistently increasing interest rates since last year and is expected to raise rates by 25 basis points next month. Currently, interest rates in the world’s largest economy stand at 4.5% to 4.75%, and Fed officials had earlier said that may increase to as high as 5%.</li>
          </ul>
        </div>
      },
      {
        id: 21,
        title: 'Bitcoin dips below US$24,000 to start the trading week in Asia',
        slug: 'Bitcoin dips below US$24,000 to start the trading week in Asia',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'Trading', 'Asia'],
        description: <div className="blog-desc">
          <p>Bitcoin fell below US$24,000 in a mixed morning for the top 10 non-stablecoin cryptocurrencies by market capitalization on Monday in Asia. Solana posted the most gains in that list while leading memecoin Dogecoin dropped the most.</p>
        </div>,
        authorName: 'Lachlan Keller',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Feb 20, 2023 - 8:39 AM',
        cover: 'https://ncx.cx/images/ncx-blog-23.webp',
        content: <div className="blog-content">
          <p>Bitcoin fell below US$24,000 in a mixed morning for the top 10 non-stablecoin cryptocurrencies by market capitalization on Monday in Asia. Solana posted the most gains in that list while leading memecoin Dogecoin dropped the most.</p>
          <h3>Fast Facts</h3>
          <ul>
            <li>Bitcoin fell 2.76% in the last 24 hours to trade at US$23,927 as of 9:15 a.m. in Hong Kong, but was still up 11.9% over the past week, according to CoinMarketCap. Ethereum lost 0.97% to US$1,661, and has gained 11.2% over the past seven days.</li>
            <li>Solana was up 4.6% to US$24.62, a weekly gain of 15.6%. The network has been holding its “Build through the Bear” hackathon this month, which invites community members to build on the blockchain, offering US$50,000 in USDC for the top prize. The hackathon ends on March 14.</li>
            <li>Dogecoin fell 2% to US$0.087, but was still trading up 6.6% over the past seven days. XRP slipped 1.7% to US$0.38, though was still trading 3% higher for the past week.</li>
            <li>The total crypto market capitalization reached US$1.13 trillion overnight, the highest since August 2022, before falling to US$1.12 trillion at 9:15 a.m. in Hong Kong. The total trading volume over the past 24 hours was up by 14.4% to US$54.9 billion.</li>
            <li>U.S. equities had a mixed day of trading on Friday. The Dow Jones Industrial Average rose 0.4%, but the S&P 500 Index fell 0.3%, and the Nasdaq Composite Index closed the day down 0.6%. Wall Street will be closed on Monday for Presidents’ Day.</li>
            <li>Many major U.S. retailers are slated to release their holiday season earnings this week, starting with Walmart Inc. and Home Depot Inc. on Tuesday.</li>
            <li>The earning reports are set to come amid growing recessionary concerns in the U.S., as the Federal Reserve has raised interest rates from near zero to 4.5% to 4.75% to tamp down on inflation. While some have criticized the Fed for raising rates too sharply, the central bank maintains it will be able to achieve a soft landing” to bring down inflation without triggering a recession.</li>
            <li>The latest Consumer Price Index data showed that inflation was up 6.4% in January from a year ago, down from 6.5% in December and 7.1% in November.</li>
            <li>Analysts at the CME Group predict an 85% chance that the Fed will raise rates by another 25 basis points next month.</li>
          </ul>
        </div>
      },
      {
        id: 15,
        title: 'BNB steady after a 7% plunge – bulls must now handle this fresh hurdle',
        slug: 'BNB steady after a 7% plunge – bulls must now handle this fresh hurdle',
        category: 'Crypto',
        subCategory: ['Binanve Coin', 'BNB', 'Crypto'],
        description: <div className="blog-desc">
          <p>Disclaimer: The information presented does not constitute financial, investment, trading, or other types of advice and is solely the writer’s opinion.</p>
        </div>,
        authorName: 'Suzuki Shillsalot',
        authorurl: 'https://ambcrypto.com/author/suzuki-shillsalot/',
        authorAvatar: 'https://ncx.cx/images/suzuki.jpeg',
        authorContent: 'Ser Suzuki Shillsalot has 8 years of experience working as a Senior Investigative journalist at The SpamBot Times. He completed a two-hour course in journalism from a popular YouTube video and was one of the few to give it a positive rating. Shillsalot writings mainly focus on shilling his favourite cryptos and trolling anyone who disagrees with him. P.S - There is a slight possibility the profile pic is AI-generated.',
        createdAt: 'Jan 25, 2023 - 12:54 AM',
        cover: 'https://ncx.cx/images/ncx-news6.png',
        content: <div className="blog-content">
          <p>Disclaimer: The information presented does not constitute financial, investment, trading, or other types of advice and is solely the writer’s opinion.</p>
          <p>BNB could oscillate in a range in the next few hours/days.</p>
          <p>Short-term holders saw a sharp decline in profits after Tuesday’s sudden drop.</p>
          <p>Bitcoin [BTC] dropped from $23k to below $22.5k on Tuesday, pulling the altcoin market into a short-term plunge. Binance Coin [BNB] declined by 9% in the same period, dropping from $323 to $293. </p>
          <p>However, bulls found steady support of around $297 and launched a price recovery. At press time, BNB’s value was $303.6o and could oscillate in the range below for the next few hours/days. </p>
          <h3>BNB remained steady at the $300 – $310 range</h3>
          <img src="https://ncx.cx/images/bnb_steady1.png" alt="Ethereum_metrics" />
          <p>On the three-hour chart, the Relative Strength Index (RSI) rebounded from the 40 level and showed that buying pressure increased after dropping to the 40-mark.</p>
          <p>In addition, the Chaikin Money Flow (CMF) was rejected at the zero mark, forcing it into an uptick on the positive side, which indicated that the BNB market was strengthening.</p>
          <p>Therefore, BNB could oscillate between the demand zone ($293 – $300) and $310 in the next couple of hours. BNB has traded within this range since 21 January, only to witness a false breakout on 24 January. A retest of the $319 could also be likely if BTC reclaims the $23k zone.</p>
          <p>However, BNB could drop below the demand zone if BTC breaks below $22.5k, invalidating the bias described above. But such a drop could settle on the 100-period EMA (exponential moving average) or $286.9 level.</p>
          <p>Swing traders should therefore track RSI rejection at the 50-mark, CMF crossover, and BTC price action to minimize risk exposure.</p>
          <h3>Short-term holders’ profits tanked and sentiment flipped into negative</h3>
          <img src="https://ncx.cx/images/bnb_steady2.png" alt="Ethereum_metrics" />
          <p>According to Santiment, BNB’s 30-day MVRV (market value to realized value) dropped from 36% to 6% at press time. Short-term holders’ profit tanked by 30% as BNB’s price correction wiped out recent gains.</p>
          <p>The price decline was also followed by an elevated negative weighted sentiment and a drop in demand in the derivatives market, as evidenced by the drop in the Funding Rate. However, the Funding Rate rested on the neutral line and any increment could boost BNB’s recovery.</p>
          <p>Alternatively, a further drop in the Funding Rate could set BNB to retest the demand zone below $300. Moreover, the active address in the past 24 hours also dropped, but there was a slight uptick at press time. Any further increase in active addresses could boost the recovery momentum.</p>
          <img src="https://ncx.cx/images/bnb_steady3.png" alt="Ethereum_metrics" />
        </div>
      },
      {
        id: 12,
        title: 'S&P 500: On The Verge Of A Bullish Breakout - Week Starting Jan. 16th (Technical Analysis)',
        slug: 'rthereum-ETH-metric-sees-correction-Hopes-of-a-bull-run-rise',
        category: 'Crypto',
        subCategory: ['Breakout', 'graphics', 'Crypto'],
        description: <div className="blog-desc">
          <p>This article is the first in what I hope will be a regular weekend series. A variety of technical analysis techniques will be used to look at probable moves for the S&P 500 (SPY) in the week ahead. No personal bias will be shown and no predictions will be made either.</p>
        </div>,
        authorName: 'Andrew McElroy',
        authorurl: 'https://seekingalpha.com/author/andrew-mcelroy/?source=content_type%3Areact%7Csection%3Aauthor%7Cbutton%3Aname%7Cfirst_level_url%3Aarticle',
        authorAvatar: 'https://ncx.cx/images/andrew_mc.png',
        authorContent: 'Chief Analyst at Matrixtrade.com. Author of the ebook Fractal Market Mastery. Trend follower and market timer. All time frames, all instruments. Wherever I see an edge, I trade it.',
        createdAt: 'Jan 15, 2023 - 3:32 AM',
        cover: 'https://ncx.cx/images/ncx-news2.png',
        content: <div className="blog-content">
          <h3>Summary</h3>
          <ul>
            <li>A technical guide to the week ahead for the S&P 500.</li>
            <li>The daily chart is breaking out of the downtrend following last week's positive CPI release.</li>
            <li>Price is also now above the daily 200MA.</li>
            <li>Resistance on the weekly chart still needs to be cleared.</li>
          </ul>
          <img src="https://ncx.cx/images/sp.png" alt="bitcoin s&p" />
          <p>This article is the first in what I hope will be a regular weekend series. A variety of technical analysis techniques will be used to look at probable moves for the S&P 500 (SPY) in the week ahead. No personal bias will be shown and no predictions will be made either. Rather, the market will be viewed in probable outcomes using inflection points - if it is going down, where is it likely to go? If it is going up, where is it likely to test? Likewise, if it breaks a key level to the downside, what does that tell us about the market and where is it likely to go?</p>
          <p>The technical analysis itself will be kept fairly simple. After all, one line drawn from the early 2022 top and through the peaks has been the main technical barrier all last year and continues to be important. Tried and tested techniques such as classic patterns, trendlines, major moving averages and horizontal support form the body of the analysis. Demark systems are also used to detect exhaustion and volume profile shows additional areas of interest.</p>
          <p>The S&P 500 chart will be analyzed on monthly, weekly and daily timeframes, then the information collated into an actionable summary at the end.</p>
          <h3>The Market Narrative</h3>
          <p>While the focus of this article is primarily on technical analysis, a more complete picture combines it with fundamental and sentiment studies.</p>
          <p>Last week saw the sixth consecutive drop in inflation and there is now plenty evidence it should continue to fall. The Fed won this particular battle. However, the labor market, and particularly wage growth, remain a risk to any further shift away from the hawkish stance. For now, the message from the Fed is that rates will stay high for an extended period.</p>
          <p>But is this warning just a bluff to keep markets from getting too bullish too quickly? The market seems to think so - the expectation is that the Fed are going to slow the pace of hikes to a halt over the coming months and then start cutting in the second half of the year.</p>
          <p>The question next week and beyond is whether this situation merits the bullish breakout brewing on the charts. Sentiment has improved drastically in recent months and is reflected already in today's price. A Fed pivot has been a primary driver for months. What is left to continue the rally? Easing in the labor market and confirmed dovish signals from the Fed would complete the bullish narrative, but neither are going to happen next week. Personally, I wouldn't trust the rally until a weekly close above 4050.</p>
          <h3>S&P 500 Monthly</h3>
          <img src="https://ncx.cx/images/s&p-1.png" alt="Ethereum_metrics" />
          <p>The monthly chart shows the SPX trading inside the range set in December. Resistance is at the range high of 4101 and beyond that, 4325 at the high of August. Support is at December's low of 3764 and the 3491 low.</p>
          <p>There are no exhaustion signals in either direction (using Demark methods).</p>
          <h3>S&P 500 Weekly</h3>
          <img src="https://ncx.cx/images/s&p-2.png" alt="Ethereum_metrics" />
          <p>Last week's bar closed near the highs. This gives an upside bias into early next week to at least take out last week's 4003 high.</p>
          <p>Resistance comes in at 4048.5 at the 50ma, which will drop to around 4045 next week. There is also trendline resistance (or the neckline of an inverse head and shoulders pattern) near the same level at 4040. If this area is broken, 4101 is the next resistance, then 4203 at the large weekly gap. Support is at the 3764 pivot low and the 200ma at 3680.</p>
          <p>Again, there are no exhaustion signals in either direction.</p>
          <p>The trend is down (lower lows and lower highs).</p>
          <h3>S&P 500 Daily</h3>
          <img src="https://ncx.cx/images/s&p-3.png" alt="Ethereum_metrics" />
          <p>The daily chart is at a very important juncture. Friday's session closed at 3999 which was above the 200dma at 3981 and above the trendline at the same level.</p>
          <p>Resistance has been cleared and now only comes from higher timeframe references. Initial support is at 3928 and the gap, then last Tuesday's low of 3877.</p>
          <p>There are no exhaustion signals but a sell set up is now on bar 6. This could become active on Tuesday and Wednesday (bars 8 and 9) and suggests a pause/pullback could materialize.</p>
          <p>The trend is neutral/up. Price is above all major MAs and the bearish trendline.</p>
          <h3>Scenarios for Next Week</h3>
          <strong>Bullish</strong>
          <p>As mentioned earlier, there is a bullish bias due to the strong weekly close and the close above the daily 200ma and trendline. A test of weekly resistance at 4040-45 therefore looks likely. If this breaks, it should bring the monthly resistance of 4101 into play, with 4203 a stretched target above that.</p>
          <strong>Bearish</strong>
          <p>Exhaustion signals could trigger on Tuesday/Wednesday should there be a rally early in the week. Failure to break 4040-45 could flip the view bearish/neutral if it leads to a drop back under 3975-80 and the 200dma/trendline which is declining by around 3 points a day. Daily references of 3928 and 3877 could then be tested, with 3764 good weekly support and unlikely to be broken.</p>
          <strong>Conclusion</strong>
          <p>The S&P 500 is testing very important technical resistance and looks to be making a bullish breakout following the positive CPI report last week. One thing to note is that this is a rather obvious and closely watched move and it still needs to clear weekly resistance at 4040-45 and hold its gains to cement a bullish look.</p>
        </div>
      },
      {
        id: 33,
        title: 'Steel ETF Surged as Market Tumbled',
        slug: 'Steel ETF Surged as Market Tumbled',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'ETFs', 'Morning Star'],
        description: <div className="blog-desc">
          <p>One of last year’s top-performing equity exchange-traded funds, the VanEck Steel ETF (SLX), stealthily outperformed broader markets. After 2022’s 14% gain, investors may wonder, can it do so again?</p>
        </div>,
        authorName: 'Heather Bell',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Jan 6, 2023 - 9:03 AM',
        cover: 'https://ncx.cx/images/ncx-blog-33.webp',
        content: <div className="blog-content">
          <p>One of last year’s top-performing equity exchange-traded funds, the VanEck Steel ETF (SLX), stealthily outperformed broader markets. After 2022’s 14% gain, investors may wonder, can it do so again?</p>
          <p>The ETF is the only one covering steel specifically, investing in global steel producers. It manages $101.3 million in assets, only one-fourth of what it managed in 2008. Still, SLX is about the only way to access the performance of steel directly.</p>
          <p>Those outflows may seem surprising. SLX has performed well in in previous years, with three-and five-year annualized returns of 21.2% and 10.2%, respectively. Still, it’s lost nearly $19 million last year despite its positive performance and its indirect exposure to a key global commodity.</p>
          <p>Although nearly half of the portfolio is invested in U.S. equities, the fund also offers exposure to countries like Brazil (19.4%), the U.K. (10.7%) and Italy (5.6%). Top holdings include Rio Tinto plc (12.4%), Vale, S.A. (12.1%) and Nucor Corp. (6.8%).</p>
          <p>SLX’s liquidity isn’t great, with FactSet rating it at the lowest end of its scale, but SLX’s volumes and spreads are within the normal range relative to other similarly sized equity funds. It’s also not likely to close given it has more than $100 million in assets and has been trading for more than 14 years. Its 0.55% expense ratio is at the low end for a specialty equity ETF.</p>
          <p>It should also be noted that SLX has a dividend yield for the past 12 months of 4.26% versus 1.65% for the SPDR S&P 500 ETF Trust (SPY).</p>
          <h3>What’s Ahead for Steel</h3>
          <p>Steel price gains may be fairly muted this year after having been driven up by supply chain disruptions and the Russia-Ukraine war, but nothing too extreme in either direction, according to Christos Rigoutsos, a senior economist with S&P Global.</p>
          <p>“It's not a matter of weakness in pricing—it's the fact that prices will tend [toward] normal rather than actually being weak or lower than that,” he told ETF.com, pointing out that steel prices over the past year have been “sky high” and that the situation is simply normalizing.</p>
          <p>He notes that steel producers will idle production when surpluses of the commodity build up. Add that to the fact that prices are already only slightly above cost, there’s not much downside, according to Rigoutsos. However, he also emphasizes steel is not recession-proof, as manufacturing tends to fall off in that kind of environment.</p>
          <p>Given all that, there may be some upside remaining for SLX, while a similar level of outperformance is unlikely to continue very far into 2023. However, its specific focus and global nature could provide additional diversification to a portfolio with little added risk.</p>
          <p>With recession fears high, a fund focused on companies producing a crucial commodity could be seen as an attractive prospect.</p>
        </div>
      },
      {
        id: 34,
        title: 'Spotlight: Insurance ETF Beats Market as Rates Jump',
        slug: 'Spotlight: Insurance ETF Beats Market as Rates Jump',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'ETFs', 'Morning Star'],
        description: <div className="blog-desc">
          <p>While actuarial tables and claims adjustments sound boring, market-beating returns are always a good time.</p>
        </div>,
        authorName: 'Heather Bell',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Dec 13, 2022 - 4:43 AM',
        cover: 'https://ncx.cx/images/ncx-blog-34.webp',
        content: <div className="blog-content">
          <p>While actuarial tables and claims adjustments sound boring, market-beating returns are always a good time.</p>
          <p>This year, the iShares U.S. Insurance ETF (IAK) has been one of the few stock exchange-traded funds outside of energy that handily topped broader markets like the S&P 500.</p>
          <p>The fund is up about 12% and recently hit its all-time high. It did this in one of the worst equity market environments in years, and without exposure to energy stocks. Energy is the only major sector with positive returns year to date, while the SPDR S&P 500 ETF Trust (SPY) has dropped 16%.</p>
          <p>Three ETFs trading on U.S. exchanges provide exposure to insurance stocks. With 58 holdings, IAK is the broadest that covers this category in addition to being the top performer year to date. With $558 million in assets under management, it’s also the largest compared with the $525 million SPDR S&P Insurance ETF (KIE) and the $347 million Invesco KBW Property & Casualty Insurance ETF (KBWP). Those two funds are up 4.3% and 11.2%, respectively.</p>
          <p>All but one of IAK’s top 10 holdings—including names like Chubb Corp., Progressive Corp. and MetLife Inc.—have notched positive returns during 2022, the majority of them in the double digits. The top firms in the fund have mostly reported strong earnings and revenues this year, boosting their performance in a tough market, and spurring the ETFs gains.</p>
          <p>IAK is probably doing well for a number of reasons, one being that insurance companies do well in unstable economic times because as more or less steady companies, they aren’t affected by the downturn in the economy like other industries. They also provide essential services whose demand profile doesn’t fluctuate with economic cycles.</p>
          <p>Rising interest rates also boost insurance companies’ “float,” the pool of money, collected as premiums, which they invest conservatively and use to make claims payments. The Federal Reserve boosting rates from around zero to 4% Monday has helped IAK’s companies, and with more hikes expected this year and next, the insurance industry may be positioned well for strong profits for some time.</p>
          <p>So far this year, IAK has seen strong inflows, gaining $422.3 million year to date, while KBWP pulled in $248.5 million, and KIE—a broadly based insurance fund like IAK—gained just $62.8 million. With more room to run, those funds could pull in even more.</p>
          <h3>An Analyst’s View</h3>
          <p>The Deloitte Center for Financial Services’ report on its 2023 outlook the insurance industry in September 2022 noted that while insurers are “facing a host of macroeconomic and geopolitical challenges,” they exhibited agility during the pandemic in terms of adopting remote work and adapting to customer needs.</p>
          <p>They should continue to maintain that momentum, Deloitte advises, by investing in technology and their workforce while focusing on innovation and their customers.</p>
          <p>The report also notes that ESG considerations will become increasingly important to stakeholders, and that those could be major differentiators in competing within the insurance peer group. The firm declined to comment further when contacted.</p>
          <p>Deloitte U.S. Insurance Leader Karl Hersch said in a video in its outlook that the sector is at a “post-pandemic inflection point” during which it could “reset the narrative” around it.</p>
          <p>While the report cites inflation as cutting into profitability, it notes several opportunities for growth. Those include small businesses, in particular when it comes to cybersecurity-related mishaps.</p>
          <p>The push toward green energy could result in $125 billion in spending on such matters projected by 2030. In the digital realm, including cryptocurrency, NFTs and the metaverse, the report notes that only 17% of related assets are insured.</p>
        </div>
      },
      {
        id: 35,
        title: 'Semiconductor ETFs Jump After Warren Buffett’s Purchase',
        slug: 'Semiconductor ETFs Jump After Warren Buffett’s Purchase',
        category: 'Crypto',
        subCategory: ['Bitcoin', 'ETFs', 'Morning Star'],
        description: <div className="blog-desc">
          <p>Semiconductor exchange-traded funds surged after Warren Buffett’s Berkshire Hathaway Inc. disclosed Monday it bought more than $4 billion worth of Taiwan Semiconductor Manufacturing Co. in the previous quarter.</p>
        </div>,
        authorName: 'Ron Day',
        authorurl: '',
        authorAvatar: 'https://ncx.cx/images/avatar-placeholder.svg',
        authorContent: '',
        createdAt: 'Nov 15, 2022 - 7:43 PM',
        cover: 'https://ncx.cx/images/ncx-blog-35.webp',
        content: <div className="blog-content">
          <p>Semiconductor exchange-traded funds surged after Warren Buffett’s Berkshire Hathaway Inc. disclosed Monday it bought more than $4 billion worth of Taiwan Semiconductor Manufacturing Co. in the previous quarter.</p>
          <p>ETFs including the SPDR S&P Semiconductor ETF (XSD), the VanEck Semiconductor ETF (SMH) and the Invesco Dynamic Semiconductors ETF (PSI) gained more than 4% and were among the five biggest ETF movers on Tuesday. A handful of Taiwan and semiconductor funds rounded out the top 10 ETF movers.</p>
          <p>Berkshire’s Securities and Exchange Commission filing disclosed 4.12 million shares of Taiwan Semiconductor, the world’s biggest contract chipmaker and supplier to Apple Inc., purchased in the third calendar quarter. Apple is the top holding in Berkshire’s portfolio.</p>
          <p>The company’s shares have been among the most beaten-down in what has been a declining tech market this year. Even with today’s more than 12% gain, TSMC—as it’s also known—has dropped 32% this year, more than the 27% decline in the tech-heavy Invesco QQQ Trust (QQQ).</p>
          <p>That decline may have been seen as a buying opportunity for Buffett, who, along with business partner Charlie Munger, are the world’s most famous value investors. Semiconductors are generally priced low at present: XSD’s price-to-earnings ratio of 17 times earnings falls beneath QQQ’s 22 times.</p>
          <p>In addition to the Berkshire purchases, geopolitical tensions in the region may have taken a breather after President Joe Biden met with Chinese leader Xi Jinping in Indonesia Monday and committed to working together to solve economic and security issues.</p>
          <p>China has promised to reunify Taiwan with the Chinese mainland, which the U.S. opposes. House Speaker Nancy Pelosi’s July trip to Taiwan in support of the country infuriated China and led the country to cut off some diplomatic channels with the U.S.</p>
        </div>
      },
]
export default data;